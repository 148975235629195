import { connect } from "react-redux";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, EMPTY_VRAAG_ANTWOORDBLOKKEN } from "reducers/collections-reducer";
import { State } from "store";
import { CollectionTableBody } from "./collection-table-body.component";
import { CollectionTableBodyOwnProps, CollectionTableBodyStateProps } from "./collection-table-body.component.interfaces";

const mapStateToProps = (state: State, props: CollectionTableBodyOwnProps): CollectionTableBodyStateProps => ({
  currentPage: state.collections.list[props.collectionKey]?.paging.currentPage || DEFAULT_PAGE_NUMBER,
  gridPageSize: state.collections.list[props.collectionKey]?.paging.pageSize ?? DEFAULT_PAGE_SIZE,
  pageStartIndex: state.collections.list[props.collectionKey]?.paging.firstItemIndex ?? 0,
  processtapType: state.proces.activeItem?.type,
  vraagBlokAntwoordIndexes:
    state.collections.list[props.collectionKey]?.vraagBlokkenWithAntwoordIndexes ?? EMPTY_VRAAG_ANTWOORDBLOKKEN
});

export default connect(mapStateToProps)(CollectionTableBody);
