import {
  AntwoordBlokken,
  MotivatieAntwoorden,
  VluchtigeOrganischeStoffenAanvraagAntwoorden,
  WerkzaamhedenOpAdresBedrijfLocatieAntwoorden
} from "../blok";
import { VluchtigeOrganischeStoffenMelderAntwoorden } from "../blok/vluchtige-organische-stoffen-melder-antwoorden";
import { User } from "../security/user";
import { HasMotivatieAntwoorden } from "./has-motivatie-antwoorden";
import { HasVluchtigeOrganischeStoffenAanvraagAntwoorden } from "./has-vluchtige-organische-stoffen-aanvraag-antwoorden";
import { HasVluchtigeOrganischeStoffenAanvragerAntwoorden } from "./has-vluchtige-organische-stoffen-aanvrager-antwoorden";
import { HasWerkzaamhedenOpAdresBedrijfLocatieAntwoorden } from "./has-werkzaamheden-op-adres-bedrijf-locatie-antwoorden";

export class VluchtigeOrganischeStoffenMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasVluchtigeOrganischeStoffenAanvragerAntwoorden,
    HasWerkzaamhedenOpAdresBedrijfLocatieAntwoorden,
    HasVluchtigeOrganischeStoffenAanvraagAntwoorden,
    HasMotivatieAntwoorden
{
  constructor(
    public aanvrager: VluchtigeOrganischeStoffenMelderAntwoorden,
    public locatie: WerkzaamhedenOpAdresBedrijfLocatieAntwoorden,
    public aanvraag: VluchtigeOrganischeStoffenAanvraagAntwoorden,
    public motivatie: MotivatieAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): VluchtigeOrganischeStoffenMeldingAntwoordBlokken {
    return new VluchtigeOrganischeStoffenMeldingAntwoordBlokken(
      VluchtigeOrganischeStoffenMelderAntwoorden.initialize(user),
      WerkzaamhedenOpAdresBedrijfLocatieAntwoorden.initialize(),
      VluchtigeOrganischeStoffenAanvraagAntwoorden.initialize(),
      MotivatieAntwoorden.initialize()
    );
  }

  static fromJson(json: any): VluchtigeOrganischeStoffenMeldingAntwoordBlokken {
    return new VluchtigeOrganischeStoffenMeldingAntwoordBlokken(
      VluchtigeOrganischeStoffenMelderAntwoorden.fromJson(json.aanvrager),
      WerkzaamhedenOpAdresBedrijfLocatieAntwoorden.fromJson(json.locatie),
      VluchtigeOrganischeStoffenAanvraagAntwoorden.fromJson(json.aanvraag),
      MotivatieAntwoorden.fromJson(json.motivatie)
    );
  }
}
