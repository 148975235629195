import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class AlgemeenVerbindendVerklarenCaoAntwoorden extends AntwoordBlok {
  constructor(public aanvullendeInformatie: Antwoord<string>) {
    super();
  }

  static initialize(): AlgemeenVerbindendVerklarenCaoAntwoorden {
    return new AlgemeenVerbindendVerklarenCaoAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): AlgemeenVerbindendVerklarenCaoAntwoorden {
    return json
      ? new AlgemeenVerbindendVerklarenCaoAntwoorden(Antwoord.fromJson<string>(json.aanvullendeInformatie))
      : this.initialize();
  }
}
