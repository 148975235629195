import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class AanvullendeRisicoInventarisatieAntwoorden extends AntwoordBlok {
  constructor(public aanvullendeInformatie: Antwoord<string>) {
    super();
  }

  static initialize(): AanvullendeRisicoInventarisatieAntwoorden {
    return new AanvullendeRisicoInventarisatieAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): AanvullendeRisicoInventarisatieAntwoorden {
    return json
      ? new AanvullendeRisicoInventarisatieAntwoorden(Antwoord.fromJson<string>(json.aanvullendeInformatie))
      : this.initialize();
  }
}
