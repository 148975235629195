import { ProductveiligheidFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getProductveiligheidFormulierDefinitie = (formulier: ProductveiligheidFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.fabrikant.titelVerkort,
    type: ProcesstapType.fabrikant
  },
  {
    title: formulier.blokken.leverancier.titelVerkort,
    type: ProcesstapType.leverancier
  },
  {
    title: formulier.blokken.locatie.titelVerkort,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.productgegevens.titel,
    type: ProcesstapType.productgegevens
  }
];
