import {
  AntwoordBlok,
  GegevensIllegaleWerknemerAntwoorden,
  GegevensIllegaleWerknemerVragen,
  VraagBlok,
  VraagBlokCollection
} from "../../../../models/api";
import { VraagBlokCollectionType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";

export class IllegaleWerknemers {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.illegaleWerknemers;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => (
    <FormulierInputControls.CollectionAccordion
      itemComponent={GeneriekBlok.VraagBlok}
      getVraagBlokFromTemplate={GegevensIllegaleWerknemerVragen.fromJson}
      initializeAntwoorden={GegevensIllegaleWerknemerAntwoorden.initialize}
      vraagBlokCollection={vraagBlokCollection}
      getCollectionItemSummary={(antwoordBlok: AntwoordBlok) =>
        (antwoordBlok as GegevensIllegaleWerknemerAntwoorden).naam?.waarde ||
        (antwoordBlok as GegevensIllegaleWerknemerAntwoorden).beschrijving?.waarde
      }
    />
  );
}
