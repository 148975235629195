import { useTranslation } from "react-i18next";
import { DownloadBijlageLink } from "../../download-bijlage-link";
import { SamenvattingBijlagenProps } from "./samenvatting-bijlagen.component.interfaces";

export const SamenvattingBijlagen = (props: SamenvattingBijlagenProps) => {
  const { t } = useTranslation();

  return (
    <div className="samenvatting-bijlagen">
      {props.bestanden && props.bestanden?.waarde?.length > 0 ? (
        <div>
          <ol>
            {props.bestanden?.waarde.map((b) => (
              <li key={b.naam}>
                {!props.confirmNavigationRequired && b.id ? (
                  <DownloadBijlageLink bijlageId={b.id} bijlageNaam={b.naam}>
                    {b.naam}
                  </DownloadBijlageLink>
                ) : (
                  <span>{b.naam}</span>
                )}
              </li>
            ))}
          </ol>
        </div>
      ) : (
        <span>{t(props.noFilesTranslationKey || "geenBijlagen")}</span>
      )}
    </div>
  );
};
