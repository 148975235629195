import { HasKlachtAntwoorden } from ".";
import { AntwoordBlokken, KlachtAntwoorden } from "../blok";
import { KlachtMelderAntwoorden } from "../blok/klacht-melder-antwoorden";
import { User } from "../security/user";
import { HasKlachtMelderAntwoorden } from "./has-klacht-melder-antwoorden";

export class KlachtMeldingAntwoordBlokken extends AntwoordBlokken implements HasKlachtMelderAntwoorden, HasKlachtAntwoorden {
  constructor(public melder: KlachtMelderAntwoorden, public klacht: KlachtAntwoorden) {
    super();
  }

  static initialize(user: User | null = null): KlachtMeldingAntwoordBlokken {
    return new KlachtMeldingAntwoordBlokken(KlachtMelderAntwoorden.initialize(user), KlachtAntwoorden.initialize());
  }

  static fromJson(json: any): KlachtMeldingAntwoordBlokken {
    return new KlachtMeldingAntwoordBlokken(
      KlachtMelderAntwoorden.fromJson(json.melder),
      KlachtAntwoorden.fromJson(json.klacht)
    );
  }
}
