import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class AsbestWerkzaamhedenLavsV2Antwoorden extends AntwoordBlok {
  constructor(
    public aantalAsbestwerkersDta: Antwoord<number | null>,
    public aantalAsbestwerkersDav: Antwoord<number | null>,
    public asbestWerkzaamhedenAan: Antwoord<string>,
    public bouwwerk: Antwoord<string>,
    public objectomschrijving: Antwoord<string>,
    public bewoondeLocatie: Antwoord<string>,
    public verwijderingAsbestdaken: Antwoord<string>,
    public hoeveelheidAsbestdaken: Antwoord<string>,
    public overigeOpmerkingen: Antwoord<string>,
    public uitvoerValidatiemetingen: Antwoord<string>,
    public aanpassingRisicoklasse: Antwoord<string>
  ) {
    super();
  }

  static initialize() {
    return new AsbestWerkzaamhedenLavsV2Antwoorden(
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new AsbestWerkzaamhedenLavsV2Antwoorden(
      Antwoord.fromJson(json.aantalAsbestwerkersDta),
      Antwoord.fromJson(json.aantalAsbestwerkersDav),
      Antwoord.fromJson(json.asbestWerkzaamhedenAan),
      Antwoord.fromJson(json.bouwwerk),
      Antwoord.fromJson(json.objectomschrijving),
      Antwoord.fromJson(json.bewoondeLocatie),
      Antwoord.fromJson(json.verwijderingAsbestdaken),
      Antwoord.fromJson(json.hoeveelheidAsbestdaken),
      Antwoord.fromJson(json.overigeOpmerkingen),
      Antwoord.fromJson(json.uitvoerValidatiemetingen),
      Antwoord.fromJson(json.aanpassingRisicoklasse)
    );
  }
}
