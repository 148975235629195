import { Paragraaf } from "components/blok/generiek-samenvatting-blok/supported-tekst-typen/paragraaf";
import { displayAntwoordHelpers, jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { CollectionTypeaheadVraag } from "../collection-typeahead-vraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { AntwoordBlok } from "./antwoord-blok";
import { KindGegevensAntwoorden } from "./kind-gegevens-antwoorden";
import { LocatieWerkzaamhedenAntwoorden } from "./locatie-werkzaamheden-antwoorden";

export class WerkmomentGegevensVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public datumWerkmoment: Vraag,
    public aanvangAanwezig: Vraag,
    public eindeAanwezig: Vraag,
    public kind: CollectionTypeaheadVraag,
    public locatieWerkmoment: CollectionTypeaheadVraag,
    public repetitie: Vraag,
    public schooldag: Vraag,
    public schoolweek: Vraag,
    public uitsluitenWerkmomentControle: Vraag,
    public uitsluitenWerkmomentControleToelichting: Paragraaf
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): WerkmomentGegevensVragen {
    return new WerkmomentGegevensVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toDatumVraag(`${key}.datumWerkmoment`, json.datumWerkmoment),
      jsonHelpers.toTijdVraag(`${key}.aanvangAanwezig`, json.aanvangAanwezig),
      jsonHelpers.toTijdVraag(`${key}.eindeAanwezig`, json.eindeAanwezig),
      jsonHelpers.toCollectionTypeaheadVraag(`${key}.kind`, json.kind, (antwoordBlok: AntwoordBlok) => {
        const antwoorden = antwoordBlok as KindGegevensAntwoorden;
        return {
          label: displayAntwoordHelpers.getDisplayAntwoord(antwoorden),
          value: antwoorden.id
        };
      }),
      jsonHelpers.toCollectionTypeaheadVraag(
        `${key}.locatieWerkmoment`,
        json.locatieWerkmoment,
        (antwoordBlok: AntwoordBlok) => {
          const antwoorden = antwoordBlok as LocatieWerkzaamhedenAntwoorden;
          return {
            label: displayAntwoordHelpers.getDisplayAntwoord(antwoorden),
            value: antwoorden.id
          };
        }
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.repetitie`, json.repetitie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.schooldag`, json.schooldag),
      jsonHelpers.toMeerkeuzeVraag(`${key}.schoolweek`, json.schoolweek),
      jsonHelpers.toMeerkeuzeVraag(`${key}.uitsluitenWerkmomentControle`, json.uitsluitenWerkmomentControle),
      jsonHelpers.toParagraaf(`${key}.uitsluitenWerkmomentControleToelichting`, json.uitsluitenWerkmomentControleToelichting)
    );
  }
}
