import { AutorisatiesZoeken } from "../components/blok";
import { AutorisatiesZoekenFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getAutorisatiesZoekenFormulierDefinitie = (formulier: AutorisatiesZoekenFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.filter.titel,
    type: ProcesstapType.autorisatiesZoeken,
    vraagBlok: AutorisatiesZoeken,
    hasNoSamenvatting: true
  }
];
