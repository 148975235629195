import { Antwoord } from "../antwoord";
import { AntwoordBlokCollectionItem } from "./antwoord-blok-collection-item";
import { LocatieAntwoorden } from "./locatie-antwoorden";

export class LocatieWerkzaamhedenAntwoorden extends AntwoordBlokCollectionItem {
  static get type(): string {
    return "LocatieWerkzaamhedenAntwoorden";
  }

  constructor(public locatieNaam: Antwoord<string>, public locatie: LocatieAntwoorden | null, json?: any) {
    super(LocatieWerkzaamhedenAntwoorden.type, json);
  }

  static initialize(): LocatieWerkzaamhedenAntwoorden {
    return new LocatieWerkzaamhedenAntwoorden(new Antwoord<string>(""), LocatieAntwoorden.initialize());
  }

  static fromJson(json: any): LocatieWerkzaamhedenAntwoorden {
    return new LocatieWerkzaamhedenAntwoorden(
      Antwoord.fromJson<string>(json.locatieNaam),
      LocatieAntwoorden.fromJson(json.locatie),
      json
    );
  }

  static copyFromJson(json: any) {
    return LocatieWerkzaamhedenAntwoorden.fromJson({
      ...json,
      ...AntwoordBlokCollectionItem.copyAsNewInstance(json)
    });
  }
}
