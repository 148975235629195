import { connect } from "react-redux";
import { setRequestedPage } from "../../../../actions/proces-actions";
import { Processtap } from "../../../../models/application";
import { State } from "../../../../store";
import { SamenvattingBarItem } from "./samenvatting-bar-item.component";
import {
  SamenvattingBarItemDispatchProps,
  SamenvattingBarItemOwnProps,
  SamenvattingBarItemStateProps
} from "./samenvatting-bar-item.component.interfaces";

const mapStateToProps = (state: State, ownProps: SamenvattingBarItemOwnProps): SamenvattingBarItemStateProps => ({
  isVisible: state.proces.items.some((i) => i.type === ownProps.type && i.isComplete),
  isSubmitting: state.antwoorden.isSubmitting,
  item: state.proces.items.find((i) => i.type === ownProps.type)
});

const mapDispatchToProps = (dispatch: any): SamenvattingBarItemDispatchProps => ({
  onEdit: (item: Processtap) => dispatch(setRequestedPage(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(SamenvattingBarItem);
