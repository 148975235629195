import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensLavsAntwoorden } from "./bedrijfsgegevens-lavs-antwoorden";
import { TeVerwijderenAsbestMateriaalLavsAntwoorden } from "./te-verwijderen-asbest-materiaal-lavs-antwoorden";

export class InventarisatieLavsAntwoorden extends AntwoordBlok {
  constructor(
    public bedrijfsgegevens: BedrijfsgegevensLavsAntwoorden,
    public scaCode: Antwoord<string>,
    public rapportdatum: Antwoord<string>,
    public teVerwijderenAsbestMateriaal: Antwoord<TeVerwijderenAsbestMateriaalLavsAntwoorden[]>,
    public aantalPakdagen: Antwoord<string>,
    public gedeeltelijkeVerwijdering: Antwoord<TeVerwijderenAsbestMateriaalLavsAntwoorden[]>,
    public aantalPakdagenGedeeltelijkeVerwijdering: Antwoord<string>
  ) {
    super();
  }

  static initialize() {
    return new InventarisatieLavsAntwoorden(
      BedrijfsgegevensLavsAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<TeVerwijderenAsbestMateriaalLavsAntwoorden[]>([]),
      new Antwoord<string>(""),
      new Antwoord<TeVerwijderenAsbestMateriaalLavsAntwoorden[]>([]),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new InventarisatieLavsAntwoorden(
      BedrijfsgegevensLavsAntwoorden.fromJson(json.bedrijfsgegevens),
      Antwoord.fromJson(json.scaCode),
      Antwoord.fromJson(json.rapportdatum),
      Antwoord.fromJsonArray<TeVerwijderenAsbestMateriaalLavsAntwoorden>(
        json.teVerwijderenAsbestMateriaal,
        TeVerwijderenAsbestMateriaalLavsAntwoorden.fromJson
      ),
      Antwoord.fromJson(json.aantalPakdagen),
      Antwoord.fromJsonArray<TeVerwijderenAsbestMateriaalLavsAntwoorden>(
        json.gedeeltelijkeVerwijdering,
        TeVerwijderenAsbestMateriaalLavsAntwoorden.fromJson
      ),
      Antwoord.fromJson(json.aantalPakdagenGedeeltelijkeVerwijdering)
    );
  }
}
