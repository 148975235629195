import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";
import { ParticuliereOpdrachtgeverAntwoorden } from "./particuliere-opdrachtgever-antwoorden";

export class OpdrachtgeverOntheffingLiftenAntwoorden extends AntwoordBlok {
  constructor(
    public gegevensGelijk: Antwoord<string>,
    public bedrijfOfParticulier: Antwoord<string>,
    public opdrachtgever: OpdrachtgeverAntwoorden | null,
    public particuliereOpdrachtgever: ParticuliereOpdrachtgeverAntwoorden | null
  ) {
    super();
  }

  static initialize(): OpdrachtgeverOntheffingLiftenAntwoorden {
    return new OpdrachtgeverOntheffingLiftenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      OpdrachtgeverAntwoorden.initialize(),
      ParticuliereOpdrachtgeverAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OpdrachtgeverOntheffingLiftenAntwoorden {
    return new OpdrachtgeverOntheffingLiftenAntwoorden(
      Antwoord.fromJson<string>(json.gegevensGelijk),
      Antwoord.fromJson<string>(json.bedrijfOfParticulier),
      OpdrachtgeverAntwoorden.fromJson(json.opdrachtgever),
      ParticuliereOpdrachtgeverAntwoorden.fromJson(json.particuliereOpdrachtgever)
    );
  }
}
