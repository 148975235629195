import { ExpressionContext } from "../blok";
import { VraagBlok } from "../vraag-blok";
import { Expression } from "./expression";

export class IsMeldingGewijzigd extends Expression {
  get operator(): string {
    return IsMeldingGewijzigd.operator();
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, _vraagProperty?: string | undefined): boolean =>
    context.meldingContext?.isMeldingGewijzigd ?? false;

  static fromJson = (): IsMeldingGewijzigd => new IsMeldingGewijzigd();

  static operator = (): string => "isMeldingGewijzigd";

  static matches = (json: any): boolean => json?.operator === IsMeldingGewijzigd.operator();
}
