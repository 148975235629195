import { ConfirmDialog } from "components/confirm-dialog";
import { translationKeys } from "constants/translation-keys";
import { useFormikContext } from "formik";
import { vraagBlokCollectionHelpers } from "helpers";
import { AntwoordBlok, AntwoordBlokken } from "models/api";
import { Fragment } from "react";
import { Accordion as BSAccordion, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NO_SELECTED_ITEM_INDEX } from "reducers/collections-reducer";
import { CollectionTableBody } from "./collection-table-body";
import { CollectionTableHeader } from "./collection-table-header";
import { CollectionTablePager } from "./collection-table-pager";
import { CollectionTableProps } from "./collection-table.component.interfaces";

export const CollectionTable = (props: CollectionTableProps) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<AntwoordBlokken>();

  let itemSummary = "";
  if (props.showConfirmRemoveCollectionItemDialog && props.removeCollectionItemIndex !== NO_SELECTED_ITEM_INDEX) {
    const antwoordBlok = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemByIndex(
      props.removeCollectionItemIndex,
      props.collectionKey,
      formikContext.values
    ) as AntwoordBlok;

    itemSummary = props.getCollectionItemSummary(antwoordBlok);
  }

  return (
    <Fragment>
      <BSAccordion activeKey={props.selectedCollectionItemKey}>
        <Table className="lijst">
          <CollectionTableHeader collectionKey={props.collectionKey} columnSettings={props.columnSettings} />
          <CollectionTableBody
            itemComponent={props.itemComponent}
            collectionKey={props.collectionKey}
            collectionVerplichtType={props.collectionVerplichtType}
            columnSettings={props.columnSettings}
            removeCollectionItem={props.requestRemoveCollectionItem}
            template={props.template}
            copyAntwoorden={props.copyAntwoorden}
            getVraagBlokFromTemplate={props.getVraagBlokFromTemplate}
          />
        </Table>
        <CollectionTablePager collectionKey={props.collectionKey} pagingSettings={props.pagingSettings} />
      </BSAccordion>
      <ConfirmDialog
        name="vraagblok-verwijderen-dialog"
        showDialog={props.showConfirmRemoveCollectionItemDialog}
        titelTranslationKey={translationKeys.dialoog.vraagBlokVerwijderen.titel}
        tekstTranslation={t(translationKeys.dialoog.vraagBlokVerwijderen.tekst, {
          item: itemSummary
        })}
        confirmTranslationKey={translationKeys.dialoog.vraagBlokVerwijderen.confirm}
        cancelTranslationKey={translationKeys.dialoog.vraagBlokVerwijderen.cancel}
        onConfirm={() => props.onConfirmRemoveCollectionItem(props.removeCollectionItemIndex, formikContext)}
        onCancel={props.onCancelRemoveCollectionItem}
      />
    </Fragment>
  );
};
