export const httpStatusCodes = {
  ok: 200,
  noContent: 204,
  multipleChoices: 300,
  unauthorized: 401,
  forbidden: 403,
  notfound: 404,
  preconditionFailed: 412,
  internalServerError: 500,
  serviceUnavailable: 503
};
