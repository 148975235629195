export enum ProcesstapType {
  aanlevering = "aanlevering",
  aanvraag = "aanvraag",
  aanvrager = "aanvrager",
  profiel = "profiel",
  autorisatieProfiel = "autorisatieProfiel",
  autorisatieaanvraag = "autorisatieaanvraag",
  autorisatiesZoeken = "autorisatiesZoeken",
  contactVraag = "contactVraag",
  contactMelder = "contactMelder",
  klacht = "klacht",
  eigenaar = "eigenaar",
  getuigen = "getuigen",
  slachtoffer = "getuigen",
  inventarisatie = "inventarisatie",
  locatie = "locatie",
  melder = "melder",
  meldingOmschrijving = "meldingOmschrijving",
  opdrachtgever = "opdrachtgever",
  opdrachtnemer = "opdrachtnemer",
  periode = "periode",
  samenvatting = "samenvatting",
  slachtoffers = "slachtoffers",
  werkzaamheden = "werkzaamheden",
  werkgever = "werkgever",
  tankschip = "tankschip",
  verklaringen = "verklaringen",
  bouwwerk = "bouwwerk",
  ontwerpendePartij = "ontwerpendePartij",
  uitvoerendePartij = "uitvoerendePartij",
  aannemers = "aannemers",
  motivatie = "motivatie",
  bedrijfsgegevensWerklocatie = "bedrijfsgegevensWerklocatie",
  werklocatie = "werklocatie",
  melding = "melding",
  bijlage = "bijlage",
  inleiding = "inleiding",
  omstandigheden = "omstandigheden",
  fabrikant = "fabrikant",
  leverancier = "leverancier",
  productgegevens = "productgegevens",
  reactie = "reactie",
  digipass = "digipass",
  upload = "upload",
  productie = "productie",
  locaties = "locaties",
  kinderen = "kinderen",
  werkmomenten = "werkmomenten",
  voorwaarden = "voorwaarden",
  werkgeverLocatie = "werkgeverLocatie",
  certificerendeInstelling = "certificerendeInstelling",
  laboratorium = "laboratorium",
  sanering = "sanering",
  sloopmelding = "sloopmelding",
  kennisgeving = "kennisgeving",
  importerenKinderarbeid = "importerenKinderarbeid",
  meldingsplichtToedracht = "meldingsplichtToedracht",
  locatieProduct = "locatieProduct",
  aangever = "aangever",
  geadresseerde = "geadresseerde",
  verzoek = "verzoek",
  verzoeker = "verzoeker"
}
