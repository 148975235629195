import { Fragment } from "react";
import { translationKeys } from "../../../constants/translation-keys";
import { Breadcrumb } from "../breadcrumb";
import { useTranslation } from "react-i18next";

export const MijnMeldingenBreadcrumbs = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
      />
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.mijnMeldingen.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.mijnMeldingen.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.mijnMeldingen.titel)}
      />
    </Fragment>
  );
};
