import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { AutorisatieslistColumnName, AutorisatiesListItem, AutorisatiesSortOption } from "../../../models/api";
import { Pager } from "../../grid/pager";
import { Spinner } from "../../spinner";
import { AutorisatiesZoekbalk } from "../autorisaties-zoekbalk";
import { AutorisatiesZoekenProps } from "./autorisaties-zoeken.component.interfaces";
import { Link, useNavigate } from "react-router-dom";

const TableHeader = (props: {
  sortOption: AutorisatiesSortOption;
  propertyName: AutorisatieslistColumnName;
  sortOptionChange: (propertyName: AutorisatieslistColumnName) => void;
}) => {
  const { t } = useTranslation();

  const columnName = {
    kolom: t(translationKeys.beheer.autorisaties.tabelkop[props.propertyName])
  };

  const sortHeaderTitle = t(
    translationKeys.beheer.autorisaties.sorteerKolom[props.sortOption.sortAscending ? "aflopend" : "oplopend"],
    columnName
  );

  const sortIconTitle = t(
    translationKeys.beheer.autorisaties.sorteerKolom[
      props.sortOption.sortAscending ? "isOplopendGesorteerd" : "isAflopendGesorteerd"
    ],
    columnName
  );

  return (
    <th>
      <div
        className="aligner"
        role="button"
        aria-label={sortHeaderTitle}
        onClick={() => props.sortOptionChange(props.propertyName)}
      >
        {t(translationKeys.beheer.autorisaties.tabelkop[props.propertyName])}
        <SortIcon sortOption={props.sortOption} propertyName={props.propertyName} title={sortIconTitle} />
      </div>
    </th>
  );
};

const TableHeaders = (props: {
  sortOption: AutorisatiesSortOption;
  sortOptionChange: (propertyNames: AutorisatieslistColumnName) => void;
  propertyNames: AutorisatieslistColumnName[];
}) => {
  return (
    <Fragment>
      {props.propertyNames.map((p) => (
        <TableHeader
          key={`table-header-${p}`}
          sortOption={props.sortOption}
          sortOptionChange={props.sortOptionChange}
          propertyName={p}
        />
      ))}
    </Fragment>
  );
};

const SortIcon = (props: {
  sortOption: AutorisatiesSortOption;
  propertyName: AutorisatieslistColumnName;
  title: string;
}) => {
  const sortAscending = props.sortOption.propertyName === props.propertyName ? props.sortOption.sortAscending : null;

  const sortIcon = sortAscending ? faChevronUp : faChevronDown;

  return sortAscending == null ? null : <FontAwesomeIcon icon={sortIcon} aria-label={props.title} />;
};

export const AutorisatiesZoeken = (props: AutorisatiesZoekenProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRowClick = (profielId: string) => {
    navigate(`/beheer/autorisaties/${profielId}`);
  };

  const sortOptionChange = (propertyName: AutorisatieslistColumnName) => {
    if (props.sortOption.propertyName === propertyName) {
      props.onSearchOptionChange(
        {
          ...props.sortOption,
          sortAscending: !props.sortOption.sortAscending
        },
        props.pageNumber
      );
    } else {
      props.onSearchOptionChange(new AutorisatiesSortOption(propertyName, true), 1);
    }
  };

  const pageChange = (pageNumber: number) => {
    props.onSearchOptionChange(props.sortOption, pageNumber);
  };

  return (
    <div className="autorisaties-zoeken">
      <Row className="main-container" role="main">
        <Col xs={12} className="main-container-title">
          <h1>{t(translationKeys.beheer.titel)}</h1>
          <h2>{t(translationKeys.beheer.autorisaties.titel)}</h2>
        </Col>
      </Row>
      <AutorisatiesZoekbalk onSubmit={props.onSubmit} />
      <Row className="main-container">
        <Col xs={12}>
          {props.isLoading && <Spinner>{t(translationKeys.bezigMetLaden)}</Spinner>}
          {props.hasApiError && <Alert variant="danger">{t(translationKeys.oepsErGingIetsFout)}</Alert>}
          {props.hasSearched && !props.isLoading && !props.hasApiError && props.autorisaties.length === 0 && (
            <div>{t(translationKeys.beheer.autorisaties.geenResultaten)}</div>
          )}
          {!props.isLoading && !props.hasApiError && props.autorisaties.length > 0 && (
            <div className="lijst-container">
              <h2>{t(translationKeys.beheer.autorisaties.resultaten)}</h2>
              <Table className="lijst">
                <thead>
                  <tr>
                    <TableHeaders
                      sortOption={props.sortOption}
                      sortOptionChange={sortOptionChange}
                      propertyNames={["bedrijfsnaam", "gebruikersnaam", "achternaam", "kvknummer", "vestigingsnummer"]}
                    />
                  </tr>
                </thead>
                <tbody>
                  {props.autorisaties.map((item: AutorisatiesListItem) => (
                    <tr key={item.id} className="clickable" onClick={() => handleRowClick(item.id)}>
                      <td data-label="bedrijfsnaam">
                        <label>{t(translationKeys.beheer.autorisaties.tabelkop.bedrijfsnaam)}</label>
                        {item.bedrijfsnaam}
                      </td>
                      <td data-label="gebruikersnaam">
                        <label>{t(translationKeys.beheer.autorisaties.tabelkop.gebruikersnaam)}</label>
                        <Link to={`/beheer/autorisaties/${item.id}`}>{item.gebruikersnaam}</Link>
                      </td>
                      <td data-label="achternaam">
                        <label>{t(translationKeys.beheer.autorisaties.tabelkop.achternaam)}</label>
                        {item.achternaam}
                      </td>
                      <td data-label="kvknummer">
                        <label>{t(translationKeys.beheer.autorisaties.tabelkop.kvknummer)}</label>
                        {item.kvknummer}
                      </td>
                      <td data-label="vestigingsnummer">
                        <label>{t(translationKeys.beheer.autorisaties.tabelkop.vestigingsnummer)}</label>
                        {item.vestigingsnummer}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pager
                pageCount={Math.ceil(props.totaalAantalAutorisaties / props.pageSize)}
                currentPage={props.pageNumber}
                onPageChange={pageChange}
              ></Pager>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
