import React from "react";
import { VraagType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";
import { Vraag } from "./../../../../models/api";

export class MeerkeuzeMeerdereAntwoordmogelijkheden {
  static getType = (): VraagType => VraagType.meerkeuzeMeerdereAntwoordmogelijkheden;

  static create = (vraag: Vraag, readOnly = false): React.JSX.Element => (
    <FormulierInputControls.Check vraag={vraag} readOnly={readOnly} />
  );
}
