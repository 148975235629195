import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { WerkzaamhedenVragen } from "./werkzaamheden-vragen";

export class WerkbakWerkzaamhedenVragen extends WerkzaamhedenVragen {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public betrokkenPersonenTitel: Tekst,
    public betrokkenPersonenWerkbakToelichting: Tekst,
    public aantal: Vraag,
    public producttypeTitel: Tekst,
    public producttype: Meerkeuzevraag,
    public asbestsanering: Meerkeuzevraag,
    public werkzaamhedenTitel: Tekst,
    public werkzaamhedenWerkbakToelichting: Tekst,
    public omschrijving: Vraag,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public bestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): WerkbakWerkzaamhedenVragen {
    return new WerkbakWerkzaamhedenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.betrokkenPersonenTitel`, json.betrokkenPersonenTitel),
      jsonHelpers.toParagraaf(`${key}.betrokkenPersonenWerkbakToelichting`, json.betrokkenPersonenWerkbakToelichting),
      jsonHelpers.toNumeriekeVraag(`${key}.aantal`, json.aantal),
      jsonHelpers.toTitel(`${key}.producttypeTitel`, json.producttypeTitel),
      jsonHelpers.toChecklistvraag(`${key}.producttype`, json.producttype),
      jsonHelpers.toMeerkeuzeVraag(`${key}.asbestsanering`, json.asbestsanering),
      jsonHelpers.toTitel(`${key}.werkzaamhedenTitel`, json.werkzaamhedenTitel),
      jsonHelpers.toParagraaf(`${key}.werkzaamhedenWerkbakToelichting`, json.werkzaamhedenWerkbakToelichting),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijving`, json.omschrijving),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden)
    );
  }
}
