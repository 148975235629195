export const SET_SEARCH_ISVALID = "SET_SEARCH_ISVALID";

interface SetSearchIsValidAction {
  type: typeof SET_SEARCH_ISVALID;
  data: boolean;
  key: string;
}

export type ValidationActions = SetSearchIsValidAction;

export const setSearchIsValid = (isValid: boolean, key: string): SetSearchIsValidAction => {
  return {
    type: SET_SEARCH_ISVALID,
    data: isValid,
    key: key
  };
};
