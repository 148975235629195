import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class PeriodeEerdereOntheffingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public welkePeriodeTitel: Tekst,
    public begindatum: Vraag,
    public einddatum: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): PeriodeEerdereOntheffingVragen {
    return new PeriodeEerdereOntheffingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.welkePeriodeTitel`, json.welkePeriodeTitel),
      jsonHelpers.toDatumVraag(`${key}.begindatum`, json.begindatum),
      jsonHelpers.toDatumVraag(`${key}.einddatum`, json.einddatum)
    );
  }
}
