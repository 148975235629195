import { AntwoordBlok } from "./antwoord-blok";

export interface IAntwoordBlokCollection {
  list: any[];
}

export class AntwoordBlokCollection<TAntwoordBlokType extends AntwoordBlok>
  extends AntwoordBlok
  implements IAntwoordBlokCollection
{
  constructor(public list: TAntwoordBlokType[]) {
    super();
  }

  static initialize<TAntwoordBlok extends AntwoordBlok>(blokken: TAntwoordBlok[]): AntwoordBlokCollection<TAntwoordBlok> {
    return new AntwoordBlokCollection(blokken);
  }

  static fromJson<TAntwoordBlok extends AntwoordBlok>(
    antwoordBlokFromJson: (json: any) => TAntwoordBlok,
    json: any
  ): AntwoordBlokCollection<TAntwoordBlok> | null {
    return new AntwoordBlokCollection(json?.list ? json.list.map((itemJson: any) => antwoordBlokFromJson(itemJson)) : []);
  }
}
