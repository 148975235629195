import { DebouncedFormControl } from "../debounce-input";
import { Field } from "../field";

export const InputTextArea = (props: any) => {
  const { name, touched, error, serverError, onBlur, ...propsRest } = props;

  const isInvalid = (touched && error) || serverError;

  return (
    <Field name={name} {...propsRest}>
      <DebouncedFormControl
        as="textarea"
        rows="3"
        name={name}
        id={name}
        isInvalid={isInvalid}
        onBlur={onBlur}
        {...propsRest}
      />
    </Field>
  );
};
