import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Configuration } from "../../../configuration";
import { displayValues } from "../../../constants";
import { meldingHelpers } from "../../../helpers";
import { SamenvattingFieldOwnProps } from "./samenvatting-field.component.interfaces";

export const SamenvattingField: React.FC<SamenvattingFieldOwnProps> = (props: SamenvattingFieldOwnProps) =>
  props.label.length > Configuration.maxLabelLength ? (
    <Fragment>
      <Row className="field">
        <LabelColumn {...props} />
      </Row>
      <Row className="field">
        <ValueColumn {...props} />
      </Row>
    </Fragment>
  ) : (
    <Row className="field">
      <LabelColumn {...props} />
      <ValueColumn {...props} />
    </Row>
  );

export const ValueColumn = (props: SamenvattingFieldOwnProps) => {
  return (
    <Col className="waarde-container">
      {props.children ? (
        props.children
      ) : (
        <span id={meldingHelpers.getFieldNameFromVraagKey(props.id)} aria-labelledby={`${props.id}.waarde--label`}>
          {props.waarde || displayValues.noValue}
        </span>
      )}
    </Col>
  );
};

const LabelColumn = (props: SamenvattingFieldOwnProps) => {
  return (
    <Col className="label-container">
      <label id={`${props.id}.waarde--label`}>{props.label}</label>
    </Col>
  );
};
