import {
  AntwoordenActions,
  INIT_FORM,
  NavigationActions,
  REINIT_FORM,
  REINIT_FORM_COMPLETE,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SAVE_FORM,
  SET_STATE,
  SET_SUBMITTING,
  SET_SUBMITTING_COMPLETE,
  SET_SUBMIT_PROGRESS
} from "../actions";
import { AntwoordenState } from "../store";
import { SharedActions } from "./../actions/shared-actions";

const initialState: AntwoordenState = {
  isInitialized: false,
  isSubmitting: false,
  hasSubmitError: false,
  submitProgress: 0,
  blokken: {},
  formuliertype: undefined,
  meldingId: undefined,
  reinitializeForm: false
};

const antwoordenReducer = (
  state: AntwoordenState = initialState,
  action: AntwoordenActions | SharedActions | NavigationActions
): AntwoordenState => {
  switch (action.type) {
    case SET_STATE: {
      return { ...state, reinitializeForm: true };
    }
    case REINIT_FORM: {
      return { ...state, reinitializeForm: true };
    }
    case REINIT_FORM_COMPLETE: {
      return { ...state, reinitializeForm: false };
    }
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM:
      return initialState;
    case INIT_FORM:
      return {
        ...state,
        blokken: action.data,
        formuliertype: action.formuliertype,
        meldingId: action.meldingId,
        isInitialized: true
      };
    case SAVE_FORM:
      return { ...state, blokken: action.data, hasSubmitError: false };
    case SET_SUBMITTING:
      return {
        ...state,
        isSubmitting: true,
        hasSubmitError: false,
        submitProgress: 0
      };
    case SET_SUBMITTING_COMPLETE:
      return {
        ...state,
        isSubmitting: false,
        hasSubmitError: !action.isSuccessful,
        submitProgress: 100
      };
    case SET_SUBMIT_PROGRESS:
      return {
        ...state,
        submitProgress: action.progress
      };
    default:
      return state;
  }
};

export default antwoordenReducer;
