import { Antwoord, AntwoordBlokCollection, OpdrachtgeverAntwoorden } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class AannemersAntwoorden extends AntwoordBlok {
  constructor(
    public aantalOnderaannemers: Antwoord<number | null>,
    public aantalZzpers: Antwoord<number | null>,
    public aannemers: AntwoordBlokCollection<OpdrachtgeverAntwoorden> | null
  ) {
    super();
  }

  static initialize(): AannemersAntwoorden {
    return new AannemersAntwoorden(
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      AntwoordBlokCollection.initialize([])
    );
  }

  static fromJson(json: any): AannemersAntwoorden {
    return new AannemersAntwoorden(
      Antwoord.fromJson<number | null>(json.aantalOnderaannemers),
      Antwoord.fromJson<number | null>(json.aantalZzpers),
      AntwoordBlokCollection.fromJson(OpdrachtgeverAntwoorden.fromJson, json.aannemers)
    );
  }
}
