import { useFormikContext } from "formik";
import { objectHelpers, validationHelpers } from "helpers";
import _ from "lodash";
import { AntwoordBlok, AntwoordBlokken, MeldingContext, VraagBlok } from "models/api";
import { useCallback, useEffect } from "react";
import { CollectionValidatorProps } from "./collection-validator.component.interfaces";

export const CollectionValidator = (props: CollectionValidatorProps) => {
  const { values, setErrors } = useFormikContext<AntwoordBlokken>();

  const {
    vragen,
    processtapType,
    collectionValidationFailures,
    setValidationFailures,
    collectionKey,
    user,
    autorisatiegegevens,
    meldingStatus,
    wordtMeldingGewijzigd
  } = props;

  const getValidationFailures = useCallback(() => {
    const vraagBlokken = objectHelpers.getVraagBlokCollectionValues<VraagBlok>(vragen, collectionKey);
    const antwoordBlokken = objectHelpers.getVraagBlokCollectionValues<AntwoordBlok>(values, collectionKey);

    if (vragen && vraagBlokken && antwoordBlokken && vraagBlokken.length === antwoordBlokken.length && processtapType) {
      const validationResponse = validationHelpers.validateGenericClientSide(
        vragen,
        values,
        user,
        autorisatiegegevens,
        new MeldingContext(wordtMeldingGewijzigd, meldingStatus),
        processtapType
      );

      const collectionErrors = validationHelpers.validationFailuresForNestedProperty(
        validationResponse.validationFailures,
        collectionKey
      );

      const allErrors = validationHelpers.convertToErrorObject(validationResponse);

      return { collectionErrors, allErrors };
    }

    return { collectionErrors: [], allErrors: {} };
  }, [vragen, collectionKey, values, processtapType, user, autorisatiegegevens, wordtMeldingGewijzigd, meldingStatus]);

  /* eslint-disable */
  /* Disabled eslint because collectionValidationFailures is set inside this useEffect */
  useEffect(() => {
    const currentFailures = getValidationFailures();
    if (!_.isEqual(currentFailures.collectionErrors, collectionValidationFailures)) {
      setErrors(currentFailures.allErrors);
      setValidationFailures(currentFailures.collectionErrors);
    }
  });
  /* eslint-enable */

  return null;
};
