import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const loadi18n = () => {
  i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(detector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      returnNull: false,
      //lng: "nl",
      preload: ["nl", "en", "de", "pl", "ro"],
      fallbackLng: "nl",
      supportedLngs: ["nl", "en", "de", "pl", "ro"],
      debug: true,
      react: {
        useSuspense: true
      },
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      detection: {
        order: ["querystring"],
        lookupQuerystring: "lng"
      }
    });
};

export default loadi18n;
