import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class ArbeidsomstandighedenAntwoorden extends AntwoordBlok {
  constructor(
    public welkeArbeidsomstandighedenMeldingBetrekking: Antwoord<string[]>,
    public overigeArbeidsomstandigheden: Antwoord<string>
  ) {
    super();
  }

  static initialize(): ArbeidsomstandighedenAntwoorden {
    return new ArbeidsomstandighedenAntwoorden(new Antwoord<string[]>([]), new Antwoord<string>(""));
  }

  static fromJson(json: any): ArbeidsomstandighedenAntwoorden {
    return json
      ? new ArbeidsomstandighedenAntwoorden(
          Antwoord.fromJson<string[]>(json.welkeArbeidsomstandighedenMeldingBetrekking),
          Antwoord.fromJson<string>(json.overigeArbeidsomstandigheden)
        )
      : this.initialize();
  }
}
