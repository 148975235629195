import { fileHelpers } from "../../../helpers";
import { Bestand } from "../blok/bestand";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class HasInvalidFileExtensions extends VraagExpression {
  get operator(): string {
    return HasInvalidFileExtensions.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly allowedExtensions: string[]) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;
    return (
      antwoord && (antwoord as Bestand[]).some((x) => !this.allowedExtensions.includes(fileHelpers.getExtension(x.naam)))
    );
  };

  protected clone = (instance: HasInvalidFileExtensions): HasInvalidFileExtensions =>
    new HasInvalidFileExtensions(instance.vraagProperty, instance.allowedExtensions);

  static fromJson = (json: any): HasInvalidFileExtensions =>
    new HasInvalidFileExtensions(json.vraag, json.allowedExtensions);

  static operator = (): string => "hasInvalidFileExtensions";

  static matches = (json: any): boolean => json?.operator === HasInvalidFileExtensions.operator();
}
