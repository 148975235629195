import { VraagBlokWithAntwoordIndex } from "components/blok/vraag-blokken-collection/model";
import { ValidationFailure } from "models/api";
import {
  CollectionActions,
  INITIALIZE_COLLECTION_STATE,
  NavigationActions,
  ProcesActions,
  RESET_COLLECTION_CURRENTPAGE,
  RESET_COLLECTION_PAGESIZE,
  RESET_COLLECTION_SORTING,
  RESET_DEFAULT_COLLECTION_SORTING,
  RESET_FOCUS_ON_FIRST_VRAAG,
  RESET_FORM,
  RESET_REMOVE_COLLECTION_ITEM_INDEX,
  RESET_SELECTED_COLLECTION_ITEM,
  ROUTER_LOCATION_CHANGE,
  SET_COLLECTION_CURRENTPAGE,
  SET_COLLECTION_PAGESIZE,
  SET_COLLECTION_SORTING,
  SET_COLLECTION_VALIDATION_FAILURES,
  SET_COLLECTION_VESTIGINGEN_KEYS,
  SET_DEFAULT_COLLECTION_SORTING,
  SET_REMOVE_COLLECTION_ITEM_INDEX,
  SET_SELECTED_COLLECTION_ITEM,
  SET_VRAAG_BLOK_ANTWOORD_INDEXES,
  SharedActions,
  TOGGLE_SELECTED_COLLECTION_ITEM
} from "../actions";
import { CollectionPagingState, CollectionsState, CollectionState, Order } from "../store";

const initialState: CollectionsState = {
  list: {}
};

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_ASCENDING = Order.ascending;
export const NO_SELECTED_ITEM = "";
export const NO_SELECTED_ITEM_INDEX = null;
export const EMPTY_VRAAG_ANTWOORDBLOKKEN: VraagBlokWithAntwoordIndex[] = [];
export const EMPTY_COLLECTION_VALIDATION_FAILURES: ValidationFailure[] = [];
export const EMPTY_COLLECTION_VESTIGINGEN_KEYS: string[] = [];

const initialCollectionPaging: CollectionPagingState = {
  pageSize: DEFAULT_PAGE_SIZE,
  currentPage: DEFAULT_PAGE_NUMBER,
  firstItemIndex: 0
};

const initialCollectionState = (): CollectionState => ({
  vraagBlokkenWithAntwoordIndexes: EMPTY_VRAAG_ANTWOORDBLOKKEN,
  unfilteredTotalRecords: 0,
  selectedItemKey: NO_SELECTED_ITEM,
  focusOnFirstVraag: false,
  validationFailures: EMPTY_COLLECTION_VALIDATION_FAILURES,
  vestigingenKeys: EMPTY_COLLECTION_VESTIGINGEN_KEYS,
  paging: initialCollectionPaging,
  sorting: null,
  defaultSorting: null,
  defaultSorted: false,
  removeCollectionItemIndex: NO_SELECTED_ITEM_INDEX
});

function updateCollectionsState(
  state: CollectionsState,
  action: CollectionActions,
  getUpdatedCollectionState: (collection: CollectionState) => CollectionState
) {
  const collectionState = state.list[action.collectionKey] ?? initialCollectionState();

  return {
    ...state,
    list: {
      ...state.list,
      [action.collectionKey]: getUpdatedCollectionState(collectionState)
    }
  };
}

const updatePaging = (pagingState: CollectionPagingState, pageNumber: number): CollectionPagingState => {
  const firstItem = (pageNumber - 1) * pagingState.pageSize;

  return {
    ...pagingState,
    currentPage: pageNumber,
    firstItemIndex: firstItem
  };
};

const collectionsReducer = (
  state: CollectionsState = initialState,
  action: CollectionActions | SharedActions | ProcesActions | NavigationActions
): CollectionsState => {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM:
      return initialState;
    case INITIALIZE_COLLECTION_STATE:
      return updateCollectionsState(state, action, (collection) => collection);
    case SET_VRAAG_BLOK_ANTWOORD_INDEXES:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        vraagBlokkenWithAntwoordIndexes:
          action.vraagBlokAntwoordIndexes.length > 0 ? action.vraagBlokAntwoordIndexes : EMPTY_VRAAG_ANTWOORDBLOKKEN,
        unfilteredTotalRecords: action.unfilteredTotalRecords
      }));
    case TOGGLE_SELECTED_COLLECTION_ITEM:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        selectedItemKey: collection.selectedItemKey === action.itemKey ? NO_SELECTED_ITEM : action.itemKey,
        focusOnFirstVraag: false
      }));
    case SET_SELECTED_COLLECTION_ITEM:
      return updateCollectionsState(state, action, (collection) => {
        return {
          ...collection,
          selectedItemKey: action.itemKey,
          focusOnFirstVraag: action.focusOnFirstVraag
        };
      });
    case RESET_SELECTED_COLLECTION_ITEM:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        selectedItemKey: NO_SELECTED_ITEM,
        focusOnFirstVraag: false
      }));
    case SET_COLLECTION_VALIDATION_FAILURES:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        validationFailures:
          action.validationFailures.length > 0 ? action.validationFailures : EMPTY_COLLECTION_VALIDATION_FAILURES
      }));
    case SET_COLLECTION_VESTIGINGEN_KEYS:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        vestigingenKeys: action.vestigingenKeys.length > 0 ? action.vestigingenKeys : EMPTY_COLLECTION_VESTIGINGEN_KEYS
      }));
    case SET_COLLECTION_CURRENTPAGE:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        paging: updatePaging(collection.paging, action.pageNumber)
      }));
    case RESET_COLLECTION_CURRENTPAGE:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        paging: updatePaging(collection.paging, DEFAULT_PAGE_NUMBER)
      }));
    case SET_COLLECTION_PAGESIZE:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        paging: { ...collection.paging, pageSize: action.pageSize }
      }));
    case RESET_COLLECTION_PAGESIZE:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        paging: { ...collection.paging, pageSize: DEFAULT_PAGE_SIZE }
      }));
    case SET_COLLECTION_SORTING:
      return updateCollectionsState(state, action, (collection) => {
        let sortOrder = action.sortOrder;
        if (sortOrder === undefined) {
          sortOrder =
            collection.sorting && collection.sorting.columnName === action.sortProperty
              ? collection.sorting.order === Order.ascending
                ? Order.descending
                : Order.ascending
              : DEFAULT_ASCENDING;
        }

        return {
          ...collection,
          sorting: {
            columnName: action.sortProperty,
            order: sortOrder
          }
        };
      });
    case RESET_COLLECTION_SORTING:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        sorting: collection.defaultSorting
      }));
    case SET_DEFAULT_COLLECTION_SORTING:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        defaultSorting: {
          columnName: action.sortProperty,
          order: action.sortOrder
        },
        defaultSorted: true
      }));
    case RESET_DEFAULT_COLLECTION_SORTING:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        defaultSorting: null
      }));
    case SET_REMOVE_COLLECTION_ITEM_INDEX:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        removeCollectionItemIndex: action.itemIndex
      }));
    case RESET_REMOVE_COLLECTION_ITEM_INDEX:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        removeCollectionItemIndex: NO_SELECTED_ITEM_INDEX
      }));
    case RESET_FOCUS_ON_FIRST_VRAAG:
      return updateCollectionsState(state, action, (collection) => ({
        ...collection,
        focusOnFirstVraag: false
      }));
    default:
      return state;
  }
};

export default collectionsReducer;
