import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../constants/translation-keys";
import { ButtonWithLoader } from "../button-with-loader";
import { ExtendSessionDialogProps } from "./extend-session-dialog.component.interfaces";

export const ExtendSessionDialog = (props: ExtendSessionDialogProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={props.isExtendSessionDialogShown}
      aria-labelledby="extend-session-dialog-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="dialog-title" id="extend-session-dialog-title">
          {t(translationKeys.dialoog.sessieVerloopt.titel)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>{t(translationKeys.dialoog.sessieVerloopt.tekst)}</>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoader
          variant="secondary"
          onClick={props.onSignout}
          isVisible={true}
          disabled={props.isExtendingSession}
          isLoading={props.isSigningOut}
        >
          {t(translationKeys.dialoog.sessieVerloopt.uitloggen)}
        </ButtonWithLoader>
        <ButtonWithLoader
          variant="primary"
          onClick={props.onExtendSession}
          isVisible={true}
          disabled={props.isSigningOut}
          isLoading={props.isExtendingSession}
        >
          {t(translationKeys.dialoog.sessieVerloopt.sessieVerlengen)}
        </ButtonWithLoader>
      </Modal.Footer>
    </Modal>
  );
};
