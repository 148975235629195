import { resetRemoveCollectionItemIndex, setRemoveCollectionItemIndex } from "actions";
import { FormikContextType } from "formik";
import { AntwoordBlokken } from "models/api";
import { connect } from "react-redux";
import { NO_SELECTED_ITEM, NO_SELECTED_ITEM_INDEX } from "reducers/collections-reducer";
import { removeItemFromCollection } from "thunks";
import { State } from "../../../../../store";
import { VraagBlokWithAntwoordIndex } from "../../model";
import { CollectionTable } from "./collection-table.component";
import {
  CollectionTableDispatchProps,
  CollectionTableOwnProps,
  CollectionTableStateProps
} from "./collection-table.component.interfaces";

const mapStateToProps = (state: State, props: CollectionTableOwnProps): CollectionTableStateProps => ({
  selectedCollectionItemKey: state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM,
  showConfirmRemoveCollectionItemDialog:
    state.collections.list[props.collectionKey]?.removeCollectionItemIndex !== NO_SELECTED_ITEM_INDEX,
  removeCollectionItemIndex: state.collections.list[props.collectionKey]?.removeCollectionItemIndex
});

const mapDispatchToProps = (dispatch: any, props: CollectionTableOwnProps): CollectionTableDispatchProps => ({
  requestRemoveCollectionItem: (vraagAntwoordBlok: VraagBlokWithAntwoordIndex) => {
    dispatch(setRemoveCollectionItemIndex(props.collectionKey, vraagAntwoordBlok.antwoordBlokIndex));
  },
  onConfirmRemoveCollectionItem: (
    vraagAntwoordBlokIndex: number | null,
    antwoordBlokkenContext: FormikContextType<AntwoordBlokken>
  ) => {
    dispatch(resetRemoveCollectionItemIndex(props.collectionKey));
    if (vraagAntwoordBlokIndex !== NO_SELECTED_ITEM_INDEX) {
      dispatch(removeItemFromCollection(props.collectionKey, vraagAntwoordBlokIndex, antwoordBlokkenContext));
    }
  },
  onCancelRemoveCollectionItem: () => {
    dispatch(resetRemoveCollectionItemIndex(props.collectionKey));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTable);
