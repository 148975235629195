import { Fragment, useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formulier } from "..";
import { translationKeys } from "../../../constants";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { DigipassMelding, MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { formulierHelpers } from "./../../../helpers";
import { DigipassFormulierComponentProps } from "./digipass-formulier.component.interfaces";

export const DigipassFormulierComponent = (props: DigipassFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;
  const { t } = useTranslation();

  useEffect(() => {
    onInit();
  }, [onInit]);

  const formuliertype = Formuliertype.digipass;

  const renderAlert = () => {
    if (props.digipassResponse) {
      if (props.digipassResponse.hasExternalServiceError) {
        return <Alert variant="danger">{t(translationKeys.blok.digipass.formulier.nietBeschikbaar)}</Alert>;
      } else {
        return props.digipassResponse.pincodeGeldig ? (
          <Alert variant="success">
            {`${props.digipassResponse.medewerkerNaam} ${t(translationKeys.blok.digipass.formulier.success)}`}
          </Alert>
        ) : (
          <Alert variant="danger">{t(translationKeys.blok.digipass.formulier.failure)}</Alert>
        );
      }
    }
    return null;
  };

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      undefined,
      props.antwoordenMeldingId
    )
  ) {
    const formulier = props.formulier;
    const blokDefinitie = FormulierDefinitie.getDigipassFormulierDefinitie(formulier);

    return (
      <Fragment>
        <Formulier
          type={Formuliertype.digipass}
          {...propsRest}
          blokDefinitie={blokDefinitie}
          validate={MeldingValidator.createValidate(props.formulier.blokken)}
          createMelding={MeldingBase.ofType(DigipassMelding)}
          verzendenButtonText={t(translationKeys.blok.digipass.formulier.controleer)}
          submitMelding={props.submitDigipass}
        ></Formulier>

        <Row className="main-container">
          <Col xs={12}>{renderAlert()}</Col>
        </Row>
      </Fragment>
    );
  } else {
    return null;
  }
};
