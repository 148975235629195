import { connect } from "react-redux";
import { BergplaatsRadioactieveStoffenFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getBergplaatsRadioactieveStoffenMelding,
  initBergplaatsRadioactieveStoffenFormulier,
  initBergplaatsRadioactieveStoffenFormulierFromCopy
} from "../../../thunks";
import { BergplaatsRadioactieveStoffenFormulierComponent } from "./bergplaats-radioactieve-stoffen-formulier.component";
import {
  BergplaatsRadioactieveStoffenFormulierComponentDispatchProps,
  BergplaatsRadioactieveStoffenFormulierComponentStateProps
} from "./bergplaats-radioactieve-stoffen-formulier.component.interfaces";

const mapStateToProps = (state: State): BergplaatsRadioactieveStoffenFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.bergplaatsRadioactieveStoffen
      ? (state.vragen.formulier as BergplaatsRadioactieveStoffenFormulier)
      : undefined;
  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): BergplaatsRadioactieveStoffenFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initBergplaatsRadioactieveStoffenFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getBergplaatsRadioactieveStoffenMelding(meldingId));
    } else {
      dispatch(initBergplaatsRadioactieveStoffenFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BergplaatsRadioactieveStoffenFormulierComponent);
