import { BedrijfsgegevensVragen, BuitenlandsAdresVragen, ContactpersoonVragen } from ".";
import { Meerkeuzevraag, Tekst, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { ContactpersoonVragenDisplayDefinition, CorrespondentieAdresVragenDisplayDefinition } from "../display-definition";
import { VraagBlok } from "../vraag-blok";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";

export class MelderVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public namensBedrijfMelden: Meerkeuzevraag,
    public bedrijfIngeschreven: Meerkeuzevraag,
    public nietKvkIngeschrevenParagraaf: Tekst,
    public bedrijfsgegevensTitel: Tekst,
    public bedrijfsgegevens: BedrijfsgegevensVragen,
    public naamBedrijf: Vraag,
    public buitenlandsAdres: BuitenlandsAdresVragen,
    public meldergegevensBekend: Vraag,
    public melderTitel: Tekst,
    public melder: ContactpersoonVragen,
    public kopieMeldingNaarEmailadres: Tekst,
    public melderAdres: CorrespondentieadresVragen,
    public functie: Vraag,
    public correspondentieadresGelijkAanVestigingsadres: Meerkeuzevraag,
    public afwijkendCorrespondentieadres: CorrespondentieadresVragen,
    public contactpersoonGelijkAanMelder: Meerkeuzevraag,
    public contactpersoonTitel: Tekst,
    public afwijkendContactpersoon: ContactpersoonVragen,
    public afwijkendContactpersoonAdres: CorrespondentieadresVragen,
    public scaCode: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): MelderVragen {
    return new MelderVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.namensBedrijfMelden`, json.namensBedrijfMelden),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bedrijfIngeschreven`, json.bedrijfIngeschreven),
      jsonHelpers.toParagraaf(`${key}.nietKvkIngeschrevenParagraaf`, json.nietKvkIngeschrevenParagraaf),
      jsonHelpers.toTitel(`${key}.bedrijfsgegevensTitel`, json.bedrijfsgegevensTitel),
      BedrijfsgegevensVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens),
      jsonHelpers.toTekstVraag(`${key}.naamBedrijf`, json.naamBedrijf),
      BuitenlandsAdresVragen.fromJson(`${key}.buitenlandsAdres`, json.buitenlandsAdres),
      jsonHelpers.toMeerkeuzeVraag(`${key}.meldergegevensBekend`, json.meldergegevensBekend),
      jsonHelpers.toTitel(`${key}.melderTitel`, json.melderTitel),
      ContactpersoonVragen.fromJson(`${key}.melder`, json.melder, new ContactpersoonVragenDisplayDefinition()),
      jsonHelpers.toParagraaf(`${key}.kopieMeldingNaarEmailadres`, json.kopieMeldingNaarEmailadres),
      CorrespondentieadresVragen.fromJson(
        `${key}.melderAdres`,
        json.melderAdres,
        new CorrespondentieAdresVragenDisplayDefinition()
      ),
      jsonHelpers.toTekstVraag(`${key}.functie`, json.functie),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.correspondentieadresGelijkAanVestigingsadres`,
        json.correspondentieadresGelijkAanVestigingsadres
      ),
      CorrespondentieadresVragen.fromJson(
        `${key}.afwijkendCorrespondentieadres`,
        json.afwijkendCorrespondentieadres,
        new CorrespondentieAdresVragenDisplayDefinition()
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.contactpersoonGelijkAanMelder`, json.contactpersoonGelijkAanMelder),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.afwijkendContactpersoon`, json.afwijkendContactpersoon),
      CorrespondentieadresVragen.fromJson(
        `${key}.afwijkendContactpersoonAdres`,
        json.afwijkendContactpersoonAdres,
        new CorrespondentieAdresVragenDisplayDefinition()
      ),
      jsonHelpers.toTekstVraag(`${key}.scaCode`, json.scaCode)
    );
  }
}
