import { TaalSelectie } from "components/taal-selectie";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import logoSmall from "../../assets/logo-nla-small.svg";
import logo from "../../assets/logo-nla.svg";
import { translationKeys } from "../../constants/translation-keys";
import { Breadcrumb } from "../breadcrumbs";
import { Breadcrumbs } from "../breadcrumbs/breadcrumbs";
import { Mededeling } from "../mededeling";
import { SecurityMenu } from "../security/security-menu";
import { UserMenu } from "../user-menu";
import { LayoutProps } from "./layout.component.interfaces";

export const Layout: React.FC<LayoutProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="app-container">
      <header className="app-header">
        <Row className="security-menu-container">
          <Col className="flex-end">
            <TaalSelectie />
            <SecurityMenu />
          </Col>
        </Row>
        <Row className="logo-container">
          <Col>
            <img className="logo-large" alt="" role="presentation" src={logo} />
            <img className="logo-small" alt="" role="presentation" src={logoSmall} />
          </Col>
        </Row>
        <UserMenu.Container key={`user-menu-container`}>
          <Row className="nav-container">
            <Col>
              <Breadcrumbs />
            </Col>
            <Col xs={1}>
              <UserMenu.Toggle />
            </Col>
          </Row>
          <UserMenu.Menu />
        </UserMenu.Container>
      </header>
      <div>
        <Breadcrumb
          text={t(translationKeys.breadcrumbs.inspectieSzw.titel)}
          url={t(translationKeys.breadcrumbs.inspectieSzw.url)}
          titel={t(translationKeys.breadcrumbs.inspectieSzw.titel)}
        />
        <Mededeling />
        {props.children}
      </div>
    </Container>
  );
};
