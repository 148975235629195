import {
  AntwoordBlokken,
  BedrijfslocatiegegevensAntwoorden,
  ProductgegevensAntwoorden,
  ProductveiligheidMelderAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasProductveiligheidMelderAntwoorden } from "./has-productveiligheid-melder-antwoorden";

export class ProductveiligheidMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasProductveiligheidMelderAntwoorden
{
  constructor(
    public melder: ProductveiligheidMelderAntwoorden,
    public fabrikant: BedrijfslocatiegegevensAntwoorden,
    public leverancier: BedrijfslocatiegegevensAntwoorden,
    public locatie: BedrijfslocatiegegevensAntwoorden,
    public productgegevens: ProductgegevensAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): ProductveiligheidMeldingAntwoordBlokken {
    return new ProductveiligheidMeldingAntwoordBlokken(
      ProductveiligheidMelderAntwoorden.initialize(user),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      ProductgegevensAntwoorden.initialize()
    );
  }

  static fromJson(json: any): ProductveiligheidMeldingAntwoordBlokken {
    return new ProductveiligheidMeldingAntwoordBlokken(
      ProductveiligheidMelderAntwoorden.fromJson(json.melder),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.fabrikant),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.leverancier),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.locatie),
      ProductgegevensAntwoorden.fromJson(json.productgegevens)
    );
  }
}
