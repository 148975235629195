import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";

export class OntheffingVuurOpTankschipAanvraagAntwoorden extends AntwoordBlok {
  constructor(
    public omschrijvingAanvraag: Antwoord<string>,
    public locatieWerkzaamhedenDezelfde: Antwoord<string>,
    public locatie: BedrijfslocatiegegevensAntwoorden | null
  ) {
    super();
  }

  static initialize(): OntheffingVuurOpTankschipAanvraagAntwoorden {
    return new OntheffingVuurOpTankschipAanvraagAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OntheffingVuurOpTankschipAanvraagAntwoorden {
    return new OntheffingVuurOpTankschipAanvraagAntwoorden(
      Antwoord.fromJson<string>(json.omschrijvingAanvraag),
      Antwoord.fromJson<string>(json.locatieWerkzaamhedenDezelfde),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.locatie)
    );
  }
}
