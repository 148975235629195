import { useFormikContext } from "formik";
import { User } from "models/api/security/user";

import React, { Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { expressionHelpers, formulierHelpers, meldingHelpers } from "../../helpers";
import { AntwoordBlokken, MeldingContext, Vraag, VraagBlok, VraagBlokken } from "../../models/api";
import { ExpressionContext } from "../../models/api/blok/expression-context";
import { Autorisatiegegevens } from "../../models/api/security/autorisatiegegevens";
import { State } from "../../store";

class VraagConditieParameters {
  constructor(
    public currentUser: User | null,
    public autorisatiegegevens: Autorisatiegegevens,
    public melding: MeldingContext,
    public vraagBlokken: VraagBlokken,
    public antwoordBlokken: AntwoordBlokken
  ) {}
}

const isConditioneelGesteldeVraagZichtbaar = (child: any, vraagConditieParameters: VraagConditieParameters): boolean => {
  let result = false;

  if (child.props?.vraag != null) {
    const vraag = child.props.vraag as Vraag;

    const [bloknaam] = meldingHelpers.getKeyPartsFromItem(vraag);
    const context = new ExpressionContext(
      vraagConditieParameters.currentUser,
      vraagConditieParameters.autorisatiegegevens,
      vraagConditieParameters.melding,
      vraagConditieParameters.antwoordBlokken[bloknaam],
      vraagConditieParameters.antwoordBlokken,
      vraagConditieParameters.vraagBlokken
    );

    result = expressionHelpers.wordtItemGesteld(vraag, vraagConditieParameters.vraagBlokken[bloknaam], context);
  } else if (child.props?.vragen != null) {
    if (formulierHelpers.isVraagBlok(child.props.vragen)) {
      const vragenAsBlok = child.props.vragen as VraagBlok;

      const [bloknaam] = meldingHelpers.getKeyPartsFromKey(vragenAsBlok.key);

      const vraagBlok = vraagConditieParameters.vraagBlokken[bloknaam];
      const context = new ExpressionContext(
        vraagConditieParameters.currentUser,
        vraagConditieParameters.autorisatiegegevens,
        vraagConditieParameters.melding,
        vraagConditieParameters.antwoordBlokken[bloknaam],
        vraagConditieParameters.antwoordBlokken,
        vraagConditieParameters.vraagBlokken
      );

      result =
        expressionHelpers.wordtItemGesteld(vragenAsBlok, vraagBlok, context) &&
        formulierHelpers
          .getVragen(vragenAsBlok, true)
          .some((v) => expressionHelpers.wordtItemGesteld(v, vraagBlok, context));
    }
  }

  return result;
};

const isAnyConditioneelGesteldeVraagZichtbaar = (
  children: any,
  vraagConditieParameters: VraagConditieParameters
): boolean => {
  const childrenArray = React.Children.toArray(children);

  return childrenArray.some(
    (child: any) =>
      isConditioneelGesteldeVraagZichtbaar(child, vraagConditieParameters) ||
      (child.props?.children && isAnyConditioneelGesteldeVraagZichtbaar(child.props.children, vraagConditieParameters))
  );
};

export const AutoHiddenVragenblok: React.FC<any> = (props: any) => {
  const { values } = useFormikContext<AntwoordBlokken>();

  const { user, autorisatiegegevens, meldingStatus, wordtMeldingGewijzigd, vraagBlokken } = useSelector<
    State,
    {
      user: User | null;
      autorisatiegegevens: Autorisatiegegevens;
      meldingStatus?: string;
      wordtMeldingGewijzigd: boolean;
      vraagBlokken?: VraagBlokken;
    }
  >(
    (state: State) => ({
      user: state.security.user,
      autorisatiegegevens: state.autorisatie.autorisatiegegevens,
      meldingStatus: state.melding?.status,
      wordtMeldingGewijzigd: state.melding?.wordtGewijzigd,
      vraagBlokken: state.vragen.formulier ? state.vragen.formulier.blokken : undefined
    }),
    shallowEqual
  );

  const showVragen =
    vraagBlokken &&
    isAnyConditioneelGesteldeVraagZichtbaar(
      props.children,
      new VraagConditieParameters(
        user,
        autorisatiegegevens,
        new MeldingContext(wordtMeldingGewijzigd, meldingStatus),
        vraagBlokken,
        values
      )
    );

  return showVragen ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    <div className="hidden" aria-hidden="true">
      {props.children}
    </div>
  );
};

export const AutoHiddenSamenvattingblok: React.FC<any> = (props: any) => {
  const { user, autorisatiegegevens, meldingStatus, wordtMeldingGewijzigd, vraagBlokken, antwoordBlokken } = useSelector<
    State,
    {
      user: User | null;
      autorisatiegegevens: Autorisatiegegevens;
      meldingStatus?: string;
      wordtMeldingGewijzigd: boolean;
      vraagBlokken?: VraagBlokken;
      antwoordBlokken: AntwoordBlokken;
    }
  >(
    (state: State) => ({
      user: state.security.user,
      autorisatiegegevens: state.autorisatie.autorisatiegegevens,
      meldingStatus: state.melding?.status,
      wordtMeldingGewijzigd: state.melding?.wordtGewijzigd,
      vraagBlokken: state.vragen.formulier ? state.vragen.formulier.blokken : undefined,
      antwoordBlokken: state.antwoorden.blokken
    }),
    shallowEqual
  );

  const showVragen =
    vraagBlokken &&
    isAnyConditioneelGesteldeVraagZichtbaar(
      props.children,
      new VraagConditieParameters(
        user,
        autorisatiegegevens,
        new MeldingContext(wordtMeldingGewijzigd, meldingStatus),
        vraagBlokken,
        antwoordBlokken
      )
    );

  return showVragen ? <Fragment>{props.children}</Fragment> : null;
};
