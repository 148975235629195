import { connect } from "react-redux";
import { VuurOpTankschipFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getVuurOpTankschipMelding,
  initVuurOpTankschipFormulier,
  initVuurOpTankschipFormulierFromCopy
} from "../../../thunks";
import { VuurOpTankschipFormulierComponent } from "./vuur-op-tankschip-formulier.component";
import {
  VuurOpTankschipFormulierComponentDispatchProps,
  VuurOpTankschipFormulierComponentStateProps
} from "./vuur-op-tankschip-formulier.component.interfaces";

const mapStateToProps = (state: State): VuurOpTankschipFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.vuurOpTankschip
      ? (state.vragen.formulier as VuurOpTankschipFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): VuurOpTankschipFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initVuurOpTankschipFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getVuurOpTankschipMelding(meldingId));
    } else {
      dispatch(initVuurOpTankschipFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VuurOpTankschipFormulierComponent);
