export class User {
  constructor(
    public readonly expiresIn: number,
    public readonly username?: string,
    public readonly voorletters?: string,
    public readonly tussenvoegsels?: string,
    public readonly acthternaam?: string,
    public readonly geslacht?: string,
    public readonly emailadres?: string,
    public readonly telefoonnummer?: string,
    public readonly straat?: string,
    public readonly postcode?: string,
    public readonly huisnummer?: number,
    public readonly toevoeging?: string,
    public readonly plaats?: string,
    public readonly land?: string,
    public readonly kvkNummer?: string,
    public readonly magKvkNummerWijzigen?: string,
    public readonly vestigingsnummer?: string,
    public readonly isVestigingsnummerFixed?: string,
    public readonly vestigingId?: number,
    public readonly bedrijfNaam?: string,
    public readonly bedrijfStraat?: string,
    public readonly bedrijfPostcode?: string,
    public readonly bedrijfHuisnummer?: number,
    public readonly bedrijfToevoeging?: string,
    public readonly bedrijfPlaats?: string,
    public readonly bedrijfLand?: string,
    public readonly geautoriseerdAls?: string,
    public readonly idp?: string,
    public readonly role?: any
  ) {}

  static fromJson(json: any): User {
    return new User(
      json?.expires_in ?? 0,
      json?.name,
      json?.initials,
      json?.prefixes,
      json?.family_name,
      json?.gender,
      json?.email,
      json?.phone_number,
      json?.straat,
      json?.postcode,
      json?.huisnummer,
      json?.toevoeging,
      json?.plaats,
      json?.land,
      json?.kvk_nummer,
      json?.mag_kvk_nummer_wijzigen,
      json?.vestigingsnummer,
      json?.is_vestigingsnummer_fixed,
      json?.vestiging_id,
      json?.bedrijf_naam,
      json?.bedrijf_straat,
      json?.bedrijf_postcode,
      json?.bedrijf_huisnummer,
      json?.bedrijf_toevoeging,
      json?.bedrijf_plaats,
      json?.bedrijf_land,
      json?.geautoriseerd_als,
      json?.identity_provider,
      json?.role
    );
  }
}
