import { SamenvattingFieldBijlagenvraag } from "components/samenvatting";
import { translationKeys } from "constants/translation-keys";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";

export class BestandImport {
  static getType = (): VraagType => VraagType.bestandImport;

  static create = (vraag: Vraag): React.JSX.Element => (
    <SamenvattingFieldBijlagenvraag vraag={vraag} noFilesTranslationKey={translationKeys.geenBestand} />
  );
}
