import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";
import { MedischeOmstandighedenVragen } from "./medische-omstandigheden-vragen";
import { SociaalMaatschappelijkeOmstandighedenVragen } from "./sociaal-maatschappelijke-omstandigheden-vragen";

export class OmstandighedenNachtarbeidVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public sociaalMaatschappelijkeOmstandigheden: SociaalMaatschappelijkeOmstandighedenVragen,
    public medischeOmstandigheden: MedischeOmstandighedenVragen,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public geenMedischeInformatieToevoegenParagraaf: Tekst,
    public bijlage: Bestandenvraag,
    public formulierNaarWaarheidIngevuld: Meerkeuzevraag,
    public permanenteNachtarbeidVerwerkingParagraaf: Tekst
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): OmstandighedenNachtarbeidVragen {
    return new OmstandighedenNachtarbeidVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      SociaalMaatschappelijkeOmstandighedenVragen.fromJson(
        `${key}.sociaalMaatschappelijkeOmstandigheden`,
        json.sociaalMaatschappelijkeOmstandigheden
      ),
      MedischeOmstandighedenVragen.fromJson(`${key}.medischeOmstandigheden`, json.medischeOmstandigheden),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toWaarschuwing(
        `${key}.geenMedischeInformatieToevoegenParagraaf`,
        json.geenMedischeInformatieToevoegenParagraaf
      ),
      jsonHelpers.toBestandenVraag(`${key}.bijlage`, json.bijlage),
      jsonHelpers.toChecklistvraag(`${key}.formulierNaarWaarheidIngevuld`, json.formulierNaarWaarheidIngevuld),
      jsonHelpers.toInfo(`${key}.permanenteNachtarbeidVerwerkingParagraaf`, json.permanenteNachtarbeidVerwerkingParagraaf)
    );
  }
}
