export enum InputPurpose {
  addressLevel2 = "address-level2",
  countryName = "country-name",
  email = "email",
  familyName = "family-name",
  gender = "sex",
  postalCode = "postal-code",
  streetAddress = "street-address",
  telephone = "tel"
}
