import { OntheffingVuurOpTankschipFormulier } from "models/api/formulier/ontheffing-vuur-op-tankschip-formulier";
import { connect } from "react-redux";
import {
  getOntheffingVuurOpTankschipformulierMelding,
  initOntheffingVuurOpTankschipFormulier,
  initOntheffingVuurOpTankschipFormulierFromCopy
} from "thunks";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { OntheffingVuurOpTankschipFormulierComponent } from "./ontheffing-vuur-op-tankschip-formulier.component";
import {
  OntheffingVuurOpTankschipFormulierComponentDispatchProps,
  OntheffingVuurOpTankschipFormulierComponentStateProps
} from "./ontheffing-vuur-op-tankschip-formulier.component.interfaces";

const mapStateToProps = (state: State): OntheffingVuurOpTankschipFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.ontheffingVuurOpTankschip
      ? (state.vragen.formulier as OntheffingVuurOpTankschipFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): OntheffingVuurOpTankschipFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initOntheffingVuurOpTankschipFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getOntheffingVuurOpTankschipformulierMelding(meldingId));
    } else {
      dispatch(initOntheffingVuurOpTankschipFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OntheffingVuurOpTankschipFormulierComponent);
