import { Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class AsbestWerkzaamhedenLavsVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public aantalAsbestwerkersDta: Vraag,
    public aantalAsbestwerkersDav: Vraag,
    public asbestWerkzaamhedenAan: Vraag,
    public bouwwerk: Vraag,
    public objectomschrijving: Vraag,
    public bewoondeLocatie: Vraag,
    public verwijderingAsbestdaken: Vraag,
    public hoeveelheidAsbestdaken: Vraag,
    public overigeOpmerkingen: Vraag,
    public uitvoerValidatiemetingen: Vraag,
    public validatieUitgevoerd: Vraag,
    public terugschalen: Vraag,
    public afwijkendeInventarisatie: Vraag,
    public beoordeeld: Vraag,
    public deskundige: Vraag,
    public certificaatnummerDeskundige: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AsbestWerkzaamhedenLavsVragen {
    return new AsbestWerkzaamhedenLavsVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toNumeriekeVraag(`${key}.aantalAsbestwerkersDta`, json.aantalAsbestwerkersDta),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalAsbestwerkersDav`, json.aantalAsbestwerkersDav),
      jsonHelpers.toChecklistvraag(`${key}.asbestWerkzaamhedenAan`, json.asbestWerkzaamhedenAan),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bouwwerk`, json.bouwwerk),
      jsonHelpers.toTekstVraag(`${key}.objectomschrijving`, json.objectomschrijving),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bewoondeLocatie`, json.bewoondeLocatie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.verwijderingAsbestdaken`, json.verwijderingAsbestdaken),
      jsonHelpers.toNumeriekeVraag(`${key}.hoeveelheidAsbestdaken`, json.hoeveelheidAsbestdaken),
      jsonHelpers.toTekstVraag(`${key}.overigeOpmerkingen`, json.overigeOpmerkingen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.uitvoerValidatiemetingen`, json.uitvoerValidatiemetingen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.validatieUitgevoerd`, json.validatieUitgevoerd),
      jsonHelpers.toMeerkeuzeVraag(`${key}.terugschalen`, json.terugschalen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.afwijkendeInventarisatie`, json.afwijkendeInventarisatie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.beoordeeld`, json.beoordeeld),
      jsonHelpers.toTekstVraag(`${key}.deskundige`, json.deskundige),
      jsonHelpers.toTekstVraag(`${key}.certificaatnummerDeskundige`, json.certificaatnummerDeskundige)
    );
  }
}
