import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { ProductveiligheidFormulierVraagBlokken } from "./productveiligheid-formulier-vraag-blokken";

export class ProductveiligheidFormulier extends FormulierBase {
  constructor(public blokken: ProductveiligheidFormulierVraagBlokken, json: any) {
    super(Formuliertype.productveiligheid, blokken, json);
  }

  static fromJson(json: any): ProductveiligheidFormulier {
    return new ProductveiligheidFormulier(ProductveiligheidFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
