import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { NederlandsAdresVragen } from "./nederlands-adres-vragen";

export class LocatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public nederlandsAdres: NederlandsAdresVragen,
    public omschrijving: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): LocatieVragen {
    return new LocatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      NederlandsAdresVragen.fromJson(`${key}.nederlandsAdres`, json.nederlandsAdres),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijving`, json.omschrijving)
    );
  }
}
