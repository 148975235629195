import { Tekst } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class PeriodeVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public toelichting: Tekst,
    public begindatum: Vraag,
    public begintijd: Vraag,
    public einddatum: Vraag,
    public eindtijd: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): PeriodeVragen {
    return new PeriodeVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.toelichting`, json.toelichting),
      jsonHelpers.toDatumVraag(`${key}.begindatum`, json.begindatum),
      jsonHelpers.toTijdVraag(`${key}.begintijd`, json.begintijd),
      jsonHelpers.toDatumVraag(`${key}.einddatum`, json.einddatum),
      jsonHelpers.toTijdVraag(`${key}.eindtijd`, json.eindtijd)
    );
  }
}
