import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { ProfielFormulierVraagBlokken } from "./profiel-formulier-vraag-blokken";

export class ProfielFormulier extends FormulierBase {
  constructor(public blokken: ProfielFormulierVraagBlokken, json: any) {
    super(Formuliertype.profiel, blokken, json);
  }

  static fromJson(json: any): ProfielFormulier {
    return new ProfielFormulier(ProfielFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
