import { Meldingstatus } from "./melding/meldingstatus";
import { MetadataGegevens } from "./melding/metadata-gegevens";

export class MeldingStatusResponse {
  constructor(
    public meldingId: string,
    public meldingNummer: number,
    public meldingVersie: number,
    public status: Meldingstatus,
    public metadata: MetadataGegevens
  ) {}

  static fromJson(json: any): MeldingStatusResponse {
    return new MeldingStatusResponse(
      json.meldingId,
      json.meldingNummer,
      json.meldingVersie,
      json.status,
      MetadataGegevens.fromJson(json.metadata)
    );
  }
}
