import { formulierHelpers } from ".";
import {
  HasConditioneelGesteld,
  HasKey,
  HasVolgnummer,
  SamengesteldeVraag,
  Tekst,
  Vraag,
  VraagBlok,
  VraagBlokCollection,
  VraagBlokken
} from "../models/api";
import { Formuliertype } from "../models/application";

const getGeneriekComponentHelper = (
  componenten: any,
  item: HasKey,
  itemType: any,
  NietOndersteund: (type: string, key: string) => any,
  readOnly = false
) => {
  for (const componentKey in componenten) {
    const component = componenten[componentKey];

    if (component.getType() === itemType) {
      return component.create(item, readOnly);
    }
  }

  return NietOndersteund(itemType, item.key);
};

const getGeneriekComponent = (
  vraagComponenten: any,
  tekstComponenten: any,
  vraagBlokCollectionComponenten: any,
  samengesteldeVraagComponenten: any,
  item: any,
  NietOndersteund: (type: string, key: string) => any,
  readOnly = false
): any => {
  if (formulierHelpers.isSamengesteldeVraag(item)) {
    const samengesteldeVraag = item as SamengesteldeVraag<VraagBlok>;

    return getGeneriekComponentHelper(
      samengesteldeVraagComponenten,
      samengesteldeVraag,
      samengesteldeVraag.samengesteldeVraagType,
      NietOndersteund,
      readOnly
    );
  } else if (formulierHelpers.isVraag(item)) {
    const vraag = item as Vraag;
    return getGeneriekComponentHelper(vraagComponenten, vraag, vraag.type, NietOndersteund, readOnly);
  } else if (formulierHelpers.isTekst(item)) {
    const tekst = item as Tekst;

    return getGeneriekComponentHelper(tekstComponenten, tekst, tekst.type, NietOndersteund);
  } else if (formulierHelpers.isVraagBlokCollection(item)) {
    const vraagBlokCollection = item as VraagBlokCollection<VraagBlok>;

    return getGeneriekComponentHelper(
      vraagBlokCollectionComponenten,
      vraagBlokCollection,
      vraagBlokCollection.collectionType,
      NietOndersteund,
      readOnly
    );
  } else {
    return null;
  }
};

const areVragenEnAntwoordenInitialized = (
  requestedFormuliertype: Formuliertype,
  loadedFormuliertype?: Formuliertype,
  loadedAntwoordenFormuliertype?: Formuliertype,
  requestedMeldingId?: string,
  loadedAntwoordenMeldingId?: string,
  isKopie = false
): boolean =>
  loadedFormuliertype === requestedFormuliertype &&
  loadedAntwoordenFormuliertype === requestedFormuliertype &&
  (isKopie || loadedAntwoordenMeldingId?.toLowerCase() === requestedMeldingId?.toLowerCase());

const vraagBlokIgnoredProperties = [
  "key",
  "volgnummer",
  "isVerplicht",
  "verplichtType",
  "isVerplichtValidationMessage",
  "gebruikInfoVan",
  "automatischZoeken",
  "berekendeWaarde",
  "conditioneelReadonly",
  "conditioneelGesteld",
  "titel",
  "titelVerkort",
  "isVraagBlok",
  "isVraagBlokCollection",
  "helptekst",
  "template",
  "collectionType",
  "type",
  "showLabel",
  "itemTekst"
];

const getVragen = (vraagBlok: VraagBlok, recursive = false): Vraag[] => {
  const result: Vraag[] = [];

  for (const property in vraagBlok) {
    if (!vraagBlokIgnoredProperties.includes(property)) {
      const propertyValue: any = vraagBlok[property];

      if (isVraag(propertyValue)) {
        // It is a vraag.
        result.push(propertyValue as Vraag);
      } else if (recursive && isVraagBlok(propertyValue)) {
        result.push(...formulierHelpers.getVragen(propertyValue as VraagBlok, true));
      }
    }
  }

  return result;
};

const getVragenWithFilter = (vraagblokken: VraagBlokken, filter: (vraag: Vraag) => boolean): Vraag[] => {
  const vraagblokkenAsArray = Object.keys(vraagblokken).map((k) => vraagblokken[k]);
  return vraagblokkenAsArray.reduce(
    (result: Vraag[], currentBlok: VraagBlok) => [...result, ...getVragen(currentBlok, true).filter((v) => filter(v))],
    []
  );
};

const getBlokken = (vraagBlok: VraagBlok): VraagBlok[] => {
  const result: VraagBlok[] = [];

  for (const property in vraagBlok) {
    if (!vraagBlokIgnoredProperties.includes(property)) {
      const propertyValue: any = vraagBlok[property];

      if (isVraagBlok(propertyValue)) {
        // It is a vraagblok.
        result.push(propertyValue as VraagBlok);
      } else if (Array.isArray(propertyValue)) {
        result.push(...propertyValue.filter((vb) => isVraagBlok(vb)));
      }
    }
  }

  return result;
};

const getRootBlokken = (vraagBlokken: VraagBlokken): VraagBlok[] => {
  const result: VraagBlok[] = [];

  for (const property in vraagBlokken) {
    const propertyValue: any = vraagBlokken[property];

    if (isVraagBlok(propertyValue)) {
      result.push(propertyValue as VraagBlok);
    }
  }

  return result;
};

type volgnummerItem = HasVolgnummer & HasKey & HasConditioneelGesteld;

const getVolgnummerItems = (vraagBlok: VraagBlok): volgnummerItem[] => {
  const result: volgnummerItem[] = [];

  for (const property in vraagBlok) {
    if (!vraagBlokIgnoredProperties.includes(property)) {
      const propertyValue: any = vraagBlok[property];

      if (isVolgnummerItem(propertyValue)) {
        result.push(propertyValue as volgnummerItem);
      }
    }
  }

  result.sort((a, b) => a.volgnummer - b.volgnummer);

  return result;
};

const isSamengesteldeVraag = (object: any): boolean => "isSamengesteldeVraag" in object;
const isVraag = (object: any): boolean => "isVraag" in object;
const isVraagBlok = (object: any): boolean => "isVraagBlok" in object;
const isVraagBlokCollection = (object: any): boolean => "isVraagBlokCollection" in object;
const isTekst = (object: any): boolean => "isTekst" in object;
const isVolgnummerItem = (object: any): boolean => "volgnummer" in object;

const container = {
  areVragenEnAntwoordenInitialized,
  getVragen,
  getVragenWithFilter,
  getBlokken,
  getRootBlokken,
  getVolgnummerItems,
  isSamengesteldeVraag,
  isTekst,
  isVraag,
  isVraagBlok,
  isVraagBlokCollection,
  getGeneriekComponent
};

export { container as formulierHelpers };
