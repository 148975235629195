import { Paragraaf } from "components/blok/generiek-samenvatting-blok/supported-tekst-typen/paragraaf";
import { jsonHelpers } from "helpers";
import { Meerkeuzevraag } from "..";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class VoorwaardenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public aardArbeidGewijzigd: Meerkeuzevraag,
    public ontheffingKinderarbeidVoorwaardenParagraaf: Paragraaf,
    public voorwaardenBekend: Meerkeuzevraag,
    public ontheffingKinderarbeidMoetIkDocumentenMeesturenParagraaf: Paragraaf,
    public formulierNaarWaarheidIngevuld: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): VoorwaardenVragen {
    return new VoorwaardenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.aardArbeidGewijzigd`, json.aardArbeidGewijzigd),
      jsonHelpers.toParagraaf(
        `${key}.ontheffingKinderarbeidVoorwaardenParagraaf`,
        json.ontheffingKinderarbeidVoorwaardenParagraaf
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.voorwaardenBekend`, json.voorwaardenBekend),
      jsonHelpers.toParagraaf(
        `${key}.ontheffingKinderarbeidMoetIkDocumentenMeesturenParagraaf`,
        json.ontheffingKinderarbeidMoetIkDocumentenMeesturenParagraaf
      ),
      jsonHelpers.toChecklistvraag(`${key}.formulierNaarWaarheidIngevuld`, json.formulierNaarWaarheidIngevuld)
    );
  }
}
