import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";

export class BibobVerzoekAntwoorden extends AntwoordBlok {
  constructor(
    public omschrijvingVerzoek: Antwoord<string>,
    public vestigingen: AntwoordBlokCollection<OpdrachtgeverAntwoorden> | null,
    public externeReferentie: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): BibobVerzoekAntwoorden {
    return new BibobVerzoekAntwoorden(
      new Antwoord<string>(""),
      AntwoordBlokCollection.initialize([]),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): BibobVerzoekAntwoorden {
    return new BibobVerzoekAntwoorden(
      Antwoord.fromJson<string>(json.omschrijvingVerzoek),
      AntwoordBlokCollection.fromJson(OpdrachtgeverAntwoorden.fromJson, json.vestigingen),
      Antwoord.fromJson<string>(json.externeReferentie)
    );
  }
}
