import { Antwoord, AntwoordType } from "./../antwoord";
import { Autorisatiegegevens } from "./../security/autorisatiegegevens";

export type AntwoordCombinedType = AntwoordType | AntwoordType[] | null;
export type AntwoordOtherType = string | undefined | ((instance: any) => string);
export class AntwoordBlok {
  [key: string]: AntwoordCombinedType | Autorisatiegegevens | AntwoordOtherType;

  public isIngediend?: Antwoord<boolean>;

  constructor(json?: any) {
    this.isIngediend = json && json?.isIngediend ? Antwoord.fromJson(json.isIngediend) : new Antwoord(false);
  }
}
