import { Meerkeuzevraag, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class AanleveringLavsVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public meldingnummer: Vraag,
    public opdrachtnummer: Vraag,
    public projectnummer: Vraag,
    public meldingstatus: Vraag,
    public datumGemeld: Vraag,
    public tussentijdseMelding: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AanleveringLavsVragen {
    return new AanleveringLavsVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.meldingnummer`, json.meldingnummer),
      jsonHelpers.toTekstVraag(`${key}.opdrachtnummer`, json.opdrachtnummer),
      jsonHelpers.toTekstVraag(`${key}.projectnummer`, json.projectnummer),
      jsonHelpers.toTekstVraag(`${key}.meldingstatus`, json.meldingstatus),
      jsonHelpers.toDatumTijdVraag(`${key}.datumGemeld`, json.datumGemeld),
      jsonHelpers.toMeerkeuzeVraag(`${key}.tussentijdseMelding`, json.tussentijdseMelding)
    );
  }
}
