import { User } from "models/api/security/user";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { expressionHelpers, meldingHelpers } from "../../../helpers";
import { AntwoordBlokken, MeldingContext, VraagBlokken } from "../../../models/api";
import { ExpressionContext } from "../../../models/api/blok/expression-context";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";
import { State } from "../../../store";
import { WithConditioneelGesteldeSamenvattingItemProps } from "./with-conditioneel-gestelde-samenvatting-item.interfaces";

export const withConditioneelGesteldeSamenvattingItem = <P extends object>(
  Component: React.ComponentType<P & WithConditioneelGesteldeSamenvattingItemProps>
) => {
  return (props: P & WithConditioneelGesteldeSamenvattingItemProps) => {
    const { item, ...rest } = props;

    const { vraagBlokken, antwoordBlokken, user, autorisatiegegevens, meldingStatus, wordtMeldingGewijzigd } = useSelector<
      State,
      {
        vraagBlokken?: VraagBlokken;
        antwoordBlokken: AntwoordBlokken;
        user: User | null;
        autorisatiegegevens: Autorisatiegegevens;
        meldingStatus?: string;
        wordtMeldingGewijzigd: boolean;
      }
    >(
      (state: State) => ({
        vraagBlokken: state.vragen.formulier?.blokken,
        antwoordBlokken: state.antwoorden.blokken,
        user: state.security.user,
        autorisatiegegevens: state.autorisatie.autorisatiegegevens,
        meldingStatus: state.melding?.status,
        wordtMeldingGewijzigd: state.melding?.wordtGewijzigd
      }),
      shallowEqual
    );

    const [bloknaam] = meldingHelpers.getKeyPartsFromItem(item);

    const wordtItemGesteld =
      vraagBlokken &&
      expressionHelpers.wordtItemGesteld(
        item,
        vraagBlokken[bloknaam],
        new ExpressionContext(
          user,
          autorisatiegegevens,
          new MeldingContext(wordtMeldingGewijzigd, meldingStatus),
          antwoordBlokken[bloknaam],
          antwoordBlokken,
          vraagBlokken
        )
      );

    return wordtItemGesteld ? <Component item={item} {...(rest as P)} /> : null;
  };
};
