import { DigipassFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getDigipassFormulierDefinitie = (formulier: DigipassFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.digipass.titel,
      type: ProcesstapType.digipass,
      hasNoSamenvatting: true
    }
  ];
};
