import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { MelderVragen } from "./melder-vragen";

export class AnoniemMelderVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public ingelogdDusNietAnoniemParagraaf: Tekst,
    public anoniemMelden: Meerkeuzevraag,
    public anoniemMeldenReden: Vraag,
    public common: MelderVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AnoniemMelderVragen {
    return new AnoniemMelderVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.ingelogdDusNietAnoniemParagraaf`, json.ingelogdDusNietAnoniemParagraaf),
      jsonHelpers.toMeerkeuzeVraag(`${key}.anoniemMelden`, json.anoniemMelden),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.anoniemMeldenReden`, json.anoniemMeldenReden),
      MelderVragen.fromJson(`${key}.common`, json.common)
    );
  }
}
