import { translationKeys } from "../../constants";
import { FormulierOpgeslagenBevestiging } from "../formulier-opgeslagen-bevestiging";
import { FormulierIngetrokkenProps } from "./formulier-ingetrokken.component.interfaces";

export const FormulierIngetrokken = (props: FormulierIngetrokkenProps) => {
  return (
    <FormulierOpgeslagenBevestiging
      opgeslagenBevestigingTranslationKey={
        props.isInterneMedewerker
          ? translationKeys["formulier-ingetrokken-interne-medewerker"]
          : translationKeys["formulier-ingetrokken"]
      }
    />
  );
};
