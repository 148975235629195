import { IAntwoord } from "..";
import { objectHelpers } from "../../../helpers";
import { VraagType } from "../../application";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { IVraagBlokCollection } from "../vraag-blok-collection";
import { IPreparedContainsExpression } from "./collection-contains.interfaces";
import { Expression } from "./expression";
import { expressionParser } from "./expression-parser";
import { VraagExpression } from "./vraag-expression";

export class CollectionContains extends Expression {
  get operator(): string {
    return CollectionContains.operator();
  }

  constructor(private readonly vraagBlokCollectionProperty: string, private readonly containsExpressions: Expression[]) {
    super();
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    let result = false;

    const collection = context.getAntwoordBlokCollection(this.vraagBlokCollectionProperty);
    const vraagBlokCollection = context.getVraagBlokCollection(this.vraagBlokCollectionProperty);

    if (collection?.list && vraagBlokCollection?.template && this.containsExpressions.length > 0) {
      const preparedExpressions = this.getPreparedContainsExpressions(context, vraagBlokCollection);

      result = collection.list.some((collectionItem) => {
        return preparedExpressions.every((preparedExpression: IPreparedContainsExpression) => {
          if (
            preparedExpression.targetsLinkedCollection &&
            preparedExpression.currentAntwoordVraagProperty &&
            preparedExpression.linkedAntwoordBlokCollectionProperty &&
            preparedExpression.linkedCollectionTemplate
          ) {
            const linkId = (
              objectHelpers.getValue(collectionItem, preparedExpression.currentAntwoordVraagProperty) as IAntwoord
            )?.waarde;
            const linkedAntwoordBlokCollection = context.getAntwoordBlokCollection(
              preparedExpression.linkedAntwoordBlokCollectionProperty
            );
            const linkedCollectionItem = linkedAntwoordBlokCollection?.list.find((item) => item.id === linkId);

            return preparedExpression.expression.execute(
              preparedExpression.linkedCollectionTemplate,
              new ExpressionContext(
                context.currentUser,
                context.autorisatiegegevens,
                context.meldingContext,
                linkedCollectionItem,
                context.antwoordBlokken,
                context.vraagBlokken,
                collectionItem
              )
            );
          }

          return preparedExpression.expression.execute(
            vraagBlokCollection.template,
            new ExpressionContext(
              context.currentUser,
              context.autorisatiegegevens,
              context.meldingContext,
              context.currentAntwoordBlok,
              context.antwoordBlokken,
              context.vraagBlokken,
              collectionItem
            )
          );
        });
      });
    }

    return result;
  };

  private preparedContainsExpressions: IPreparedContainsExpression[] = [];
  getPreparedContainsExpressions = (
    context: ExpressionContext,
    vraagBlokCollection: IVraagBlokCollection
  ): IPreparedContainsExpression[] => {
    if (this.preparedContainsExpressions.length === 0) {
      const collectionTemplate = vraagBlokCollection.template;

      this.preparedContainsExpressions = this.containsExpressions.map((expression) => {
        if (expression instanceof VraagExpression) {
          const [currentAntwoordVraagProperty, ...linkedCollectionItemVraagProperties] = expression.vraagProperty.split(".");

          if (linkedCollectionItemVraagProperties.length > 0) {
            const expressionVraag = collectionTemplate[currentAntwoordVraagProperty];
            if (expressionVraag?.type === VraagType.collectionTypeahead) {
              const linkedCollectionTemplate = context.getVraagBlokCollection(
                expressionVraag.vraagBlokCollectionProperty
              )?.template;
              const copyExpression = VraagExpression.fromInstance({
                ...expression,
                vraagProperty: linkedCollectionItemVraagProperties.join(".")
              } as VraagExpression);

              return {
                expression: copyExpression,
                targetsLinkedCollection: true,
                currentAntwoordVraagProperty: currentAntwoordVraagProperty,
                linkedCollectionTemplate: linkedCollectionTemplate,
                linkedAntwoordBlokCollectionProperty: expressionVraag.vraagBlokCollectionProperty
              };
            }
          }
        }

        return {
          expression: expression,
          targetsLinkedCollection: false
        };
      });
    }
    return this.preparedContainsExpressions;
  };

  static fromJson = (json: any): CollectionContains =>
    new CollectionContains(json.vraagBlokCollection, expressionParser.fromJsonArray(json.containsExpressions));

  static operator = (): string => "collectionContains";

  static matches = (json: any): boolean => json?.operator === CollectionContains.operator();
}
