import { TaalState } from "../store";
import { SET_TAAL_WIJZIG_MODE, TaalActions } from "./../actions";

const initialState: TaalState = {
  requestedLanguage: "nl",
  taalWijzigDialogMode: 0
};

const taalReducer = (state: TaalState = initialState, action: TaalActions): TaalState => {
  switch (action.type) {
    case SET_TAAL_WIJZIG_MODE:
      return {
        ...state,
        requestedLanguage: action.requestedLanguage,
        taalWijzigDialogMode: action.taalWijzigDialogMode
      };
    default:
      return state;
  }
};

export default taalReducer;
