import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { KennisgevingMeldingAntwoordBlokken } from "models/api";
import { AppThunk } from "thunks/app-thunk-type";
import { initKennisgevingMeldingAntwoordenFromCopy, initializeFormulierProces } from "thunks/thunk-helpers";

export const initGevaarlijkeLiftFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getGevaarlijkeLiftFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getGevaarlijkeLiftFormulierDefinitie,
      KennisgevingMeldingAntwoordBlokken.initialize
    );
  };

export const initGevaarlijkeLiftFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getGevaarlijkeLiftFormulier(),
      meldingApi.getGevaarlijkeLiftMelding(meldingId)
    ]);

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getGevaarlijkeLiftFormulierDefinitie,
      initKennisgevingMeldingAntwoordenFromCopy(melding.blokken)
    );
  };
