import { LandenActions, NavigationActions, SET_LANDEN, SET_LANDEN_ISLOADING, SharedActions } from "../../actions";
import { LandenState } from "../../store";

const initialState: LandenState = {
  landenIsLoading: true,
  landen: []
};

const landenReducer = (
  state: LandenState = initialState,
  action: LandenActions | SharedActions | NavigationActions
): LandenState => {
  switch (action.type) {
    case SET_LANDEN_ISLOADING:
      return { ...state, landenIsLoading: action.data };
    case SET_LANDEN:
      return { ...state, landen: action.data };
    default:
      return state;
  }
};

export default landenReducer;
