import { Fragment, ReactNode } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ColumnSortOption, Order } from "store";
import { Pager } from "../../../components/grid/pager";
import { translationKeys } from "../../../constants/translation-keys";
import { dateHelpers } from "../../../helpers";
import { MeldingListItem, MeldinglistColumnName } from "../../../models/api";
import { TableHeader } from "../../grid/headers";

const TableHeaders = (props: {
  currentSorting: ColumnSortOption | null;
  onSortOptionChange: (columnName: string) => void;
  propertyNames: MeldinglistColumnName[];
  columnTitleTranslationBase: any;
}) => {
  return (
    <Fragment>
      {props.propertyNames.map((p) => (
        <TableHeader
          key={`table-header-${p}`}
          columnName={p}
          columnTitleTranslationBase={props.columnTitleTranslationBase}
          currentSorting={props.currentSorting}
          onSortOptionChange={props.onSortOptionChange}
          disabled={p === "gewijzigdDoor"}
        />
      ))}
    </Fragment>
  );
};

const TableRow = (props: { columnNames: MeldinglistColumnName[]; melding: MeldingListItem }) => {
  return (
    <tr>
      {props.columnNames.map((columnName) => {
        let value: any;

        switch (columnName) {
          case "aangemaaktOp":
          case "aanvang":
          case "beeindiging":
          case "gewijzigdOp":
            value = props.melding[columnName]?.format(dateHelpers.dateDisplayFormat);
            break;
          case "meldingnummer":
            value = (
              <Link to={`/${props.melding.formuliertype.toLowerCase()}/${props.melding.id}`}>
                {props.melding.meldingnummer}
              </Link>
            );
            break;
          default:
            value = props.melding[columnName];
            break;
        }

        return (
          <TableCell columnName={columnName} key={`${props.melding.id}-${columnName}`}>
            {value}
          </TableCell>
        );
      })}
    </tr>
  );
};

const TableCell = (props: { columnName: MeldinglistColumnName; children?: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <td data-label={props.columnName}>
      <label>{t(translationKeys.melding.meldingen.tabelkop[props.columnName].title)}</label>
      {props.children}
    </td>
  );
};

export const MeldingGrid = (props: {
  columns: MeldinglistColumnName[];
  sortOption: ColumnSortOption;
  pageNumber: number;
  pageSize: number;
  meldingen: MeldingListItem[];
  totaalAantalMeldingen: number;
  onSearchOptionChange: (sortOption: ColumnSortOption, pageNumber: number) => void;
}) => {
  const onSortOptionChange = (columnName: string) => {
    if (props.sortOption.columnName === columnName) {
      props.onSearchOptionChange(
        { columnName, order: props.sortOption.order === Order.ascending ? Order.descending : Order.ascending },
        props.pageNumber
      );
    } else {
      props.onSearchOptionChange({ columnName, order: Order.ascending }, 1);
    }
  };

  const pageChange = (targetPageNumber: number) => {
    props.onSearchOptionChange(props.sortOption, targetPageNumber);
  };

  return (
    <div className="lijst-container">
      <div className="lijst-horizontal-scroll">
        <Table className="lijst">
          <thead>
            <tr>
              <TableHeaders
                currentSorting={props.sortOption}
                onSortOptionChange={onSortOptionChange}
                propertyNames={props.columns}
                columnTitleTranslationBase={translationKeys.melding.meldingen.tabelkop}
              />
            </tr>
          </thead>
          <tbody>
            {props.meldingen.map((m: MeldingListItem) => (
              <TableRow columnNames={props.columns} melding={m} key={m.id} />
            ))}
          </tbody>
        </Table>
      </div>
      <Pager
        pageCount={Math.ceil(props.totaalAantalMeldingen / props.pageSize)}
        currentPage={props.pageNumber}
        onPageChange={pageChange}
      ></Pager>
    </div>
  );
};
