import { SecurityActions, SET_USER_REQUESTED_FINISHED } from "../actions/security-actions";
import { SecurityState } from "../store";
import {
  CLOSE_EXTEND_SESSION_DIALOG,
  EXTEND_SESSION,
  EXTEND_SESSION_COMPLETE,
  LOAD_USER,
  OPEN_EXTEND_SESSION_DIALOG,
  SET_PROFILE_COMPLETE,
  SET_PROFILE_SAVED,
  SET_USER_INFO_CHANGE,
  SIGN_OUT,
  UNLOAD_USER
} from "./../actions/security-actions";
import { SharedActions } from "./../actions/shared-actions";

const initialState: SecurityState = {
  profileSaved: false,
  profileIsComplete: null,
  profileNotCompleteOriginalUri: null,
  userInfoChanges: 0,
  userLoaded: false,
  userRequestFinished: false,
  isExtendSessionDialogShown: false,
  isExtendingSession: false,
  isSigningOut: false,
  user: null
};

const securityReducer = (state: SecurityState = initialState, action: SecurityActions | SharedActions): SecurityState => {
  switch (action.type) {
    case SET_PROFILE_SAVED:
      return { ...state, profileSaved: action.saved };
    case SET_PROFILE_COMPLETE:
      return {
        ...state,
        profileIsComplete: action.data.isComplete,
        profileNotCompleteOriginalUri: action.data.originalUri
      };
    case SET_USER_INFO_CHANGE:
      return { ...state, userInfoChanges: state.userInfoChanges + 1 };
    case SET_USER_REQUESTED_FINISHED:
      return { ...state, userRequestFinished: true };
    case OPEN_EXTEND_SESSION_DIALOG:
      return { ...state, isExtendSessionDialogShown: true };
    case CLOSE_EXTEND_SESSION_DIALOG:
      return { ...state, isExtendSessionDialogShown: false };
    case EXTEND_SESSION:
      return { ...state, isExtendingSession: true };
    case EXTEND_SESSION_COMPLETE:
      return {
        ...state,
        isExtendingSession: false
      };
    case SIGN_OUT:
      return { ...state, isSigningOut: true };
    case LOAD_USER:
      return { ...state, user: action.user, userLoaded: true };
    case UNLOAD_USER:
      return { ...state, user: null, userLoaded: false };
    default:
      return state;
  }
};

export default securityReducer;
