import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";
import { LocatieAntwoorden } from "./locatie-antwoorden";

export class LocatieWerkzaamhedenBijBedrijfAntwoorden extends AntwoordBlok {
  constructor(
    public werkzaamhedenLocatieBedrijf: Antwoord<string>,
    public kvkNummerGebruiken: Antwoord<string>,
    public bedrijfsgegevens: BedrijfsgegevensAntwoorden | null,
    public locatie: LocatieAntwoorden | null
  ) {
    super();
  }

  static initialize(): LocatieWerkzaamhedenBijBedrijfAntwoorden {
    return new LocatieWerkzaamhedenBijBedrijfAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfsgegevensAntwoorden.initialize(),
      LocatieAntwoorden.initialize()
    );
  }

  static fromJson(json: any): LocatieWerkzaamhedenBijBedrijfAntwoorden {
    return new LocatieWerkzaamhedenBijBedrijfAntwoorden(
      Antwoord.fromJson<string>(json.werkzaamhedenLocatieBedrijf),
      Antwoord.fromJson<string>(json.kvkNummerGebruiken),
      BedrijfsgegevensAntwoorden.fromJson(json.bedrijfsgegevens),
      LocatieAntwoorden.fromJson(json.locatie)
    );
  }
}
