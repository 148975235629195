import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { CollectionTableRowToggle } from "..";
import { CollectionTableRowToggleEditProps } from "./collection-table-row-toggle-edit.component.interfaces";

export const CollectionTableRowToggleEdit = (props: CollectionTableRowToggleEditProps) => {
  return (
    <Fragment>
      <CollectionTableRowToggle {...props}>
        {(title, currentItem, toggleEvent) => (
          <button
            id={`${props.collectionKey}.list.${props.rowIndex}.icon.edit`}
            type="button"
            className="icon-button"
            aria-label={title}
            onClick={toggleEvent}
            title={title}
          >
            <span className="visually-hidden">{title}</span>
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </button>
        )}
      </CollectionTableRowToggle>
    </Fragment>
  );
};
