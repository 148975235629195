import { ExternReferentienummerType, Meldingstatus, Ontvangstwijze } from ".";

export class MeldingBasisgegevens {
  public constructor(
    public readonly id: string | undefined,
    public readonly nummer?: number,
    public readonly aanmaakDateTime?: Date,
    public readonly status?: Meldingstatus,
    public readonly datumIntrekking?: Date,
    public readonly ontvangstwijze?: Ontvangstwijze,
    public readonly externReferentienummer?: string,
    public readonly externReferentienummerType?: ExternReferentienummerType
  ) {}

  static fromJson(json: any): MeldingBasisgegevens {
    return new MeldingBasisgegevens(
      json.id,
      json.nummer,
      json.aanmaakDateTime,
      json.status,
      json.intrekkingDateTime,
      json.ontvangstwijze,
      json.externReferentienummer,
      json.externReferentienummerType
    );
  }
}
