import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, KlachtenMelderAntwoorden, KlachtenMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initKlachtenFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getKlachtenFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getKlachtenFormulierDefinitie,
      KlachtenMeldingAntwoordBlokken.initialize
    );
  };

export const initKlachtenFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getKlachtenFormulier(),
      meldingApi.getKlachtenMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): KlachtenMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: KlachtenMelderAntwoorden.initialize(user),
        bijlage: {
          ...melding.blokken.bijlage,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getKlachtenFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
