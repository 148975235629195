import { MelderVragen } from "./melder-vragen";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class KlachtMelderVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public common: MelderVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): KlachtMelderVragen {
    return new KlachtMelderVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      MelderVragen.fromJson(`${key}.common`, json.common)
    );
  }
}
