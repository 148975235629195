import { BrpAchternaamSearchResponse } from "./brp-achternaam-search-response";

export class BrpAchternaamBatchSearchResponseItem {
  constructor(
    public bsn: string,
    public isFound: boolean,
    public response: BrpAchternaamSearchResponse | null
  ) {}

  static fromJson = (json: any) => {
    return new BrpAchternaamBatchSearchResponseItem(
      json.bsn,
      json.isFound,
      json.response ? BrpAchternaamSearchResponse.fromJson(json.response) : null
    );
  };
}
