import { Tekst } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfslocatiegegevensVragen } from "./bedrijfslocatiegegevens-vragen";

export class OntheffingVuurOpTankschipAanvraagVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public omschrijvingAanvraag: Vraag,
    public locatieTitel: Tekst,
    public locatieWerkzaamhedenDezelfde: Vraag,
    public locatie: BedrijfslocatiegegevensVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): OntheffingVuurOpTankschipAanvraagVragen {
    return new OntheffingVuurOpTankschipAanvraagVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijvingAanvraag`, json.omschrijvingAanvraag),
      jsonHelpers.toTitel(`${key}.locatieTitel`, json.locatieTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.locatieWerkzaamhedenDezelfde`, json.locatieWerkzaamhedenDezelfde),
      BedrijfslocatiegegevensVragen.fromJson(`${key}.locatie`, json.locatie)
    );
  }
}
