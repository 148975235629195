import i18n from "i18next";
import { connect } from "react-redux";
import { handleTaalOnChange } from "thunks";
import { TaalSelectie } from "./taal-selectie.component";
import { TaalSelectieDispatchProps } from "./taal-selectie.component.interfaces";

const mapDispatchToProps = (dispatch: any): TaalSelectieDispatchProps => ({
  handleTaalSelect: (requestedLanguage: string) => {
    if (requestedLanguage !== i18n.language) {
      dispatch(handleTaalOnChange(requestedLanguage));
    }
  }
});

export default connect(undefined, mapDispatchToProps)(TaalSelectie);
