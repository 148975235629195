import { connect } from "react-redux";
import { GevaarlijkeGassenImportcontainersFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getGevaarlijkeGassenImportcontainersMelding,
  initGevaarlijkeGassenImportcontainersFormulier,
  initGevaarlijkeGassenImportcontainersFormulierFromCopy
} from "../../../thunks";
import { GevaarlijkeGassenImportcontainersFormulierComponent } from "./gevaarlijke-gassen-importcontainers-formulier.component";
import {
  GevaarlijkeGassenImportcontainersFormulierComponentDispatchProps,
  GevaarlijkeGassenImportcontainersFormulierComponentStateProps
} from "./gevaarlijke-gassen-importcontainers-formulier.component.interfaces";

const mapStateToProps = (state: State): GevaarlijkeGassenImportcontainersFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.gevaarlijkeGassenImportcontainers
      ? (state.vragen.formulier as GevaarlijkeGassenImportcontainersFormulier)
      : undefined;
  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): GevaarlijkeGassenImportcontainersFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initGevaarlijkeGassenImportcontainersFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getGevaarlijkeGassenImportcontainersMelding(meldingId));
    } else {
      dispatch(initGevaarlijkeGassenImportcontainersFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GevaarlijkeGassenImportcontainersFormulierComponent);
