import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";
import { PeriodeMetRedenAntwoorden } from "./periode-met-reden-antwoorden";

export class AsbestverwijderingWerkzaamhedenAntwoorden extends AntwoordBlok {
  constructor(
    public periode: PeriodeMetRedenAntwoorden,
    public toelichting: Antwoord<string>,
    public aantalWerknemers: Antwoord<number | null>,
    public bijlage: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): AsbestverwijderingWerkzaamhedenAntwoorden {
    return new AsbestverwijderingWerkzaamhedenAntwoorden(
      PeriodeMetRedenAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): AsbestverwijderingWerkzaamhedenAntwoorden {
    return new AsbestverwijderingWerkzaamhedenAntwoorden(
      PeriodeMetRedenAntwoorden.fromJson(json.periode),
      Antwoord.fromJson<string>(json.toelichting),
      Antwoord.fromJson<number | null>(json.aantalWerknemers),
      Antwoord.fromJsonArray<Bestand>(json.bijlage, Bestand.fromJson)
    );
  }
}
