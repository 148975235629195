import { Dictionary } from "../application";
import { Antwoord } from "./antwoord";

export class MogelijkAntwoord implements Antwoord<string> {
  public initialValue: string;

  constructor(public waarde: string, public tekst: Dictionary<string>, public toelichting: Dictionary<string>) {
    this.initialValue = waarde;
  }

  static fromJson(json: any): MogelijkAntwoord {
    return new MogelijkAntwoord(json.waarde, json.tekst, json.toelichting);
  }
}
