import { TableHeader } from "components/grid/headers";
import { CollectionTableHeaderProps } from "./collection-table-header.component.interfaces";

export const CollectionTableHeader = (props: CollectionTableHeaderProps) => {
  return (
    <thead>
      <tr>
        {Object.keys(props.columnSettings.columns).map(
          (columnName: string, columnIndex: number) =>
            props.columnSettings.columns[columnName].visible !== false && (
              <TableHeader
                disabled={props.isCollectionItemSelected}
                key={`grid-column-${columnName}-${columnIndex}`}
                currentSorting={props.currentSorting}
                columnName={columnName}
                columnTitleTranslationBase={props.columnSettings.translationBase}
                onSortOptionChange={props.onSortChange}
              ></TableHeader>
            )
        )}
        <th className="last-cell"></th>
      </tr>
    </thead>
  );
};
