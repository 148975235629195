import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { Breadcrumb } from "../breadcrumb";

export const ZoekMeldingenBreadcrumbs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
      />
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.zoek.kennisgevingen.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.zoek.kennisgevingen.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.zoek.kennisgevingen.titel)}
      />
    </>
  );
};
