import { connect } from "react-redux";
import { userHelpers } from "../../helpers";
import { State } from "../../store/state";
import { UserMenu } from "./user-menu.component";
import { UserMenuStateProps } from "./user-menu.component.interfaces";

const mapStateToProps = (state: State): UserMenuStateProps => ({
  user: state.security.user,
  isBeheerderOrServicedesk:
    userHelpers.getIsBeheerderUser(state.security.user) || userHelpers.getIsServicedeskUser(state.security.user),
  isInterneMedewerker: userHelpers.getIsInternalUser(state.security.user)
});

export default connect(mapStateToProps)(UserMenu);
