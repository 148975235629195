import { Fragment } from "react";
import { Accordion as BSAccordion } from "react-bootstrap";
import { CollectionAccordionBody } from "./collection-accordion-body";
import { CollectionAccordionContainerProps } from "./collection-accordion-container.component.interfaces";

export const CollectionAccordionContainer = (props: CollectionAccordionContainerProps) => {
  return (
    <Fragment>
      <BSAccordion activeKey={props.seletedCollectionItemKey}>
        <CollectionAccordionBody
          getCollectionItemSummary={props.getCollectionItemSummary}
          itemComponent={props.itemComponent}
          collectionKey={props.collectionKey}
          collectionVerplichtType={props.collectionVerplichtType}
          removeCollectionItem={props.removeCollectionItem}
        />
      </BSAccordion>
    </Fragment>
  );
};
