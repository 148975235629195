import { connect } from "react-redux";
import { State } from "../../../store/state";
import { getAutorisatieProfielMelding, setAutorisatieProfielIsInEditMode } from "../../../thunks";
import { AutorisatieDetail } from "./autorisatie-detail.component";
import { AutorisatieDetailDispatchProps, AutorisatieDetailStateProps } from "./autorisatie-detail.component.interfaces";

const mapStateToProps = (state: State): AutorisatieDetailStateProps => ({
  meldingrechten: state.melding?.meldingrechten,
  isLoading: state.profiel.isLoading,
  isInEditMode: state.profiel.isInEditMode
});

const mapDispatchToProps = (dispatch: any): AutorisatieDetailDispatchProps => ({
  onInit: async (profielId: string, isEditPage: boolean) => {
    await dispatch(setAutorisatieProfielIsInEditMode(isEditPage));
    await dispatch(getAutorisatieProfielMelding(profielId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AutorisatieDetail);
