import { useEffect } from "react";
import { Formulier } from "..";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { MeldingBase, WerkbakMelding, WerkbakMeldingAntwoordBlokken } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { FormulierDefinitie } from "./../../../formulier-definitie";
import { formulierHelpers } from "./../../../helpers";
import { WerkbakFormulierComponentProps } from "./werkbak-formulier.component.interfaces";
import { useParams } from "react-router-dom";

export const WerkbakFormulierComponent = (props: WerkbakFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.werkbak;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const blokDefinitie = FormulierDefinitie.getWerkbakFormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(WerkbakMelding)}
        getEmailadres={(blokken: WerkbakMeldingAntwoordBlokken) => blokken.melder.melder?.emailadres.waarde}
      ></Formulier>
    );
  } else {
    return null;
  }
};
