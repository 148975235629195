import { connect } from "react-redux";
import { EMPTY_COLLECTION_VALIDATION_FAILURES, NO_SELECTED_ITEM } from "reducers/collections-reducer";
import { State } from "store";
import { toggleSelectedCollectionItem } from "../../../../../../actions";
import { CollectionTableRowSummary } from "./collection-table-row-summary.component";
import {
  CollectionTableRowSummaryDispatchProps,
  CollectionTableRowSummaryOwnProps,
  CollectionTableRowSummaryStateProps
} from "./collection-table-row-summary.component.interfaces";

const mapStateToProps = (state: State, props: CollectionTableRowSummaryOwnProps): CollectionTableRowSummaryStateProps => ({
  collectionValidationFailures:
    state.collections.list[props.collectionKey]?.validationFailures ?? EMPTY_COLLECTION_VALIDATION_FAILURES,
  selectedCollectionItemKey: state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM
});

const mapDispatchToProps = (
  dispatch: any,
  props: CollectionTableRowSummaryOwnProps
): CollectionTableRowSummaryDispatchProps => ({
  setSelectedCollectionItem: (itemKey: string) => {
    dispatch(toggleSelectedCollectionItem(props.collectionKey, itemKey));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTableRowSummary);
