import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { ContactpersoonVragen } from "./contactpersoon-vragen";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";
import { PeriodeEerdereOntheffingVragen } from "./periode-eerdere-ontheffing-vragen";

export class GegevensAanvragerOntheffingNachtarbeidVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public gegevensAanvragerTitel: Tekst,
    public aanvrager: ContactpersoonVragen,
    public geboortedatum: Vraag,
    public aanvragerAdres: CorrespondentieadresVragen,
    public burgerservicenummer: Vraag,
    public functie: Vraag,
    public periodeWerkuren: Meerkeuzevraag,
    public aantalWerkuren: Vraag,
    public functieKanOverdagWordenVervuld: Meerkeuzevraag,
    public eerdereAanvraagIngediend: Meerkeuzevraag,
    public welkeWerkgeverEerdereAanvraag: Vraag,
    public datumEerdereAanvraag: Vraag,
    public nummerLaatstIngediendeAanvraag: Vraag,
    public eerdereOntheffingVerbodNachtarbeid: Meerkeuzevraag,
    public welkeWerkgeverEerdereOntheffingVerbodNachtarbeid: Vraag,
    public datumEerdereOntheffing: Vraag,
    public kenmerkLaatstOntvangenBeschikking: Vraag,
    public periodeEerdereOntheffing: PeriodeEerdereOntheffingVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): GegevensAanvragerOntheffingNachtarbeidVragen {
    return new GegevensAanvragerOntheffingNachtarbeidVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.gegevensAanvragerTitel`, json.gegevensAanvragerTitel),
      ContactpersoonVragen.fromJson(`${key}.aanvrager`, json.aanvrager),
      jsonHelpers.toDatumVraag(`${key}.geboortedatum`, json.geboortedatum),
      CorrespondentieadresVragen.fromJson(`${key}.aanvragerAdres`, json.aanvragerAdres),
      jsonHelpers.toTekstVraag(`${key}.burgerservicenummer`, json.burgerservicenummer),
      jsonHelpers.toTekstVraag(`${key}.functie`, json.functie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.periodeWerkuren`, json.periodeWerkuren),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalWerkuren`, json.aantalWerkuren),
      jsonHelpers.toMeerkeuzeVraag(`${key}.functieKanOverdagWordenVervuld`, json.functieKanOverdagWordenVervuld),
      jsonHelpers.toMeerkeuzeVraag(`${key}.eerdereAanvraagIngediend`, json.eerdereAanvraagIngediend),
      jsonHelpers.toTekstVraag(`${key}.welkeWerkgeverEerdereAanvraag`, json.welkeWerkgeverEerdereAanvraag),
      jsonHelpers.toDatumVraag(`${key}.datumEerdereAanvraag`, json.datumEerdereAanvraag),
      jsonHelpers.toNumeriekeVraag(`${key}.nummerLaatstIngediendeAanvraag`, json.nummerLaatstIngediendeAanvraag),
      jsonHelpers.toMeerkeuzeVraag(`${key}.eerdereOntheffingVerbodNachtarbeid`, json.eerdereOntheffingVerbodNachtarbeid),
      jsonHelpers.toTekstVraag(
        `${key}.welkeWerkgeverEerdereOntheffingVerbodNachtarbeid`,
        json.welkeWerkgeverEerdereOntheffingVerbodNachtarbeid
      ),
      jsonHelpers.toDatumVraag(`${key}.datumEerdereOntheffing`, json.datumEerdereOntheffing),
      jsonHelpers.toNumeriekeVraag(`${key}.kenmerkLaatstOntvangenBeschikking`, json.kenmerkLaatstOntvangenBeschikking),
      PeriodeEerdereOntheffingVragen.fromJson(`${key}.periodeEerdereOntheffing`, json.periodeEerdereOntheffing)
    );
  }
}
