import { userHelpers } from "helpers";
import { User } from "../security/user";
import { UserExpression } from "./user-expression";

export class IsVestigingsNummerFixedForCurrentUser extends UserExpression {
  get operator(): string {
    return IsVestigingsNummerFixedForCurrentUser.operator();
  }

  executeUser(user: User): boolean {
    return userHelpers.getIsVestigingsnummerFixed(user);
  }

  static fromJson = (): IsVestigingsNummerFixedForCurrentUser => new IsVestigingsNummerFixedForCurrentUser();

  static operator = (): string => "isVestigingsNummerFixedForCurrentUser";

  static matches = (json: any): boolean => json?.operator === IsVestigingsNummerFixedForCurrentUser.operator();
}
