import { connect } from "react-redux";
import { ProfielFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getProfielMelding, sessieVerversen, setProfielOpgeslagen } from "../../../thunks";
import { ProfielFormulierComponent } from "./profiel-formulier.component";
import {
  ProfielFormulierComponentDispatchProps,
  ProfielFormulierComponentOwnProps,
  ProfielFormulierComponentStateProps
} from "./profiel-formulier.component.interfaces";

const mapStateToProps = (state: State): ProfielFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.profiel ? (state.vragen.formulier as ProfielFormulier) : undefined;

  return {
    formulier,
    antwoordenFormuliertype: state.antwoorden.formuliertype,
    isSigningOut: state.security.isSigningOut
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ProfielFormulierComponentOwnProps
): ProfielFormulierComponentDispatchProps => ({
  onInit: async () => {
    await dispatch(getProfielMelding(ownProps.enableEdit));
  },
  beforeCompleted: async () => {
    // Profiel updating
    await dispatch(sessieVerversen(true));
  },
  afterCompleted: async () => {
    // Profiel updated
    await dispatch(setProfielOpgeslagen());
    await dispatch(getProfielMelding(ownProps.enableEdit));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfielFormulierComponent);
