import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { WerkbakFormulierVraagBlokken } from "./werkbak-formulier-vraag-blokken";

export class WerkbakFormulier extends FormulierBase {
  constructor(public blokken: WerkbakFormulierVraagBlokken, json: any) {
    super(Formuliertype.werkbak, blokken, json);
  }

  static fromJson(json: any): WerkbakFormulier {
    return new WerkbakFormulier(WerkbakFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
