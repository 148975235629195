export const REQUEST_URL_NAVIGATION = "REQUEST_URL_NAVIGATION";
export const REQUEST_ACTION_NAVIGATION = "REQUEST_ACTION_NAVIGATION";
export const CONFIRM_NAVIGATION = "CONFIRM_NAVIGATION";
export const CANCEL_NAVIGATION = "CANCEL_NAVIGATION";
export const REQUIRE_NAVIGATION_CONFIRMATION = "REQUIRE_NAVIGATION_CONFIRMATION";
export const SET_DIRTY = "SET_DIRTY";
export const SET_NOT_DIRTY = "SET_NOT_DIRTY";
export const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

interface RequestUrlNavigationAction {
  type: typeof REQUEST_URL_NAVIGATION;
  url: string;
  skipRouter: boolean;
}

interface RequestActionNavigationAction {
  type: typeof REQUEST_ACTION_NAVIGATION;
  action: () => void;
}

interface ConfirmNavigationAction {
  type: typeof CONFIRM_NAVIGATION;
}

interface CancelNavigationAction {
  type: typeof CANCEL_NAVIGATION;
}

interface RequireNavigationConfirmationAction {
  type: typeof REQUIRE_NAVIGATION_CONFIRMATION;
  requireNavigationConfirmation: boolean;
}

interface SetDirtyAction {
  type: typeof SET_DIRTY;
}

interface SetNotDirtyAction {
  type: typeof SET_NOT_DIRTY;
}

interface RouterLocationChangeAction {
  type: typeof ROUTER_LOCATION_CHANGE;
}

export type NavigationActions =
  | RequestUrlNavigationAction
  | RequestActionNavigationAction
  | ConfirmNavigationAction
  | CancelNavigationAction
  | RequireNavigationConfirmationAction
  | SetDirtyAction
  | SetNotDirtyAction
  | RouterLocationChangeAction;

export const requestUrlNavigation = (url: string, skipRouter: boolean): NavigationActions => {
  return {
    type: REQUEST_URL_NAVIGATION,
    url,
    skipRouter
  };
};

export const requestActionNavigation = (action: () => void): NavigationActions => {
  return {
    type: REQUEST_ACTION_NAVIGATION,
    action
  };
};

export const confirmNavigation = (): NavigationActions => {
  return {
    type: CONFIRM_NAVIGATION
  };
};

export const cancelNavigation = (): NavigationActions => {
  return {
    type: CANCEL_NAVIGATION
  };
};

export const requireNavigationConfirmation = (require: boolean): NavigationActions => {
  return {
    type: REQUIRE_NAVIGATION_CONFIRMATION,
    requireNavigationConfirmation: require
  };
};

export const setDirty = (): NavigationActions => {
  return {
    type: SET_DIRTY
  };
};

export const setNotDirty = (): NavigationActions => {
  return {
    type: SET_NOT_DIRTY
  };
};
