import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";

export class GeadresseerdeAntwoorden extends AntwoordBlok {
  constructor(
    public isGeadresseerdeHetzelfdeAlsAangever: Antwoord<string>,
    public geadresseerdeLocatie: BedrijfslocatiegegevensAntwoorden,
    public contactpersoon: ContactpersoonAntwoorden | null
  ) {
    super();
  }

  static initialize(): GeadresseerdeAntwoorden {
    return new GeadresseerdeAntwoorden(
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      ContactpersoonAntwoorden.initialize()
    );
  }

  static fromJson(json: any): GeadresseerdeAntwoorden {
    return new GeadresseerdeAntwoorden(
      Antwoord.fromJson(json.isGeadresseerdeHetzelfdeAlsAangever),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.geadresseerdeLocatie),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon)
    );
  }
}
