import { ContactFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getContactFormulierDefinitie = (formulier: ContactFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.contactMelder.titel,
    type: ProcesstapType.contactMelder
  },
  {
    title: formulier.blokken.contactVraag.titel,
    type: ProcesstapType.contactVraag
  }
];
