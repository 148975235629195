import { Formuliertype } from "../../application";
import { BouwprocesFormulierVraagBlokken } from "./bouwproces-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class BouwprocesFormulier extends FormulierBase {
  constructor(public blokken: BouwprocesFormulierVraagBlokken, json: any) {
    super(Formuliertype.bouwproces, blokken, json);
  }

  static fromJson(json: any): BouwprocesFormulier {
    return new BouwprocesFormulier(BouwprocesFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
