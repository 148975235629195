import { connect } from "react-redux";
import { requestUrlNavigation } from "../../thunks";
import { GuardedLink } from "./guarded-link.component";
import { GuardedLinkDispatchProps, GuardedLinkOwnProps } from "./guarded-link.component.interfaces";

const mapDispatchToProps = (dispatch: any, ownProps: GuardedLinkOwnProps): GuardedLinkDispatchProps => ({
  navigate: () => {
    dispatch(requestUrlNavigation(ownProps.url, ownProps.skipRouter ?? false));
  }
});

export default connect(null, mapDispatchToProps)(GuardedLink);
