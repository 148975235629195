import {
  AntwoordBlokken,
  DuikarbeidWerkzaamhedenAntwoorden,
  LocatieMetToelichtingAntwoorden,
  MelderAntwoorden,
  PeriodeMetRedenAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasDuikarbeidwerkzaamhedenAntwoorden } from "./has-duikarbeidwerkzaamheden-antwoorden";
import { HasLocatieMetToelichtingAntwoorden } from "./has-locatie-met-toelichting-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";
import { HasPeriodeMetRedenAntwoorden } from "./has-periode-met-reden-antwoorden";

export class DuikarbeidMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasMelderAntwoorden,
    HasPeriodeMetRedenAntwoorden,
    HasLocatieMetToelichtingAntwoorden,
    HasDuikarbeidwerkzaamhedenAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public locatie: LocatieMetToelichtingAntwoorden,
    public periode: PeriodeMetRedenAntwoorden,
    public werkzaamheden: DuikarbeidWerkzaamhedenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): DuikarbeidMeldingAntwoordBlokken {
    return new DuikarbeidMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      LocatieMetToelichtingAntwoorden.initialize(),
      PeriodeMetRedenAntwoorden.initialize(),
      DuikarbeidWerkzaamhedenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): DuikarbeidMeldingAntwoordBlokken {
    return new DuikarbeidMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      LocatieMetToelichtingAntwoorden.fromJson(json.locatie),
      PeriodeMetRedenAntwoorden.fromJson(json.periode),
      DuikarbeidWerkzaamhedenAntwoorden.fromJson(json.werkzaamheden)
    );
  }
}
