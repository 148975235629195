import { connect } from "react-redux";
import { AanwijzingRisicovolleBedrijvenFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getAanwijzingRisicovolleBedrijvenMelding,
  initAanwijzingRisicovolleBedrijvenFormulier,
  initAanwijzingRisicovolleBedrijvenFormulierFromCopy
} from "../../../thunks";
import { AanwijzingRisicovolleBedrijvenFormulierComponent } from "./aanwijzing-risicovolle-bedrijven-formulier.component";
import {
  AanwijzingRisicovolleBedrijvenFormulierComponentDispatchProps,
  AanwijzingRisicovolleBedrijvenFormulierComponentStateProps
} from "./aanwijzing-risicovolle-bedrijven-formulier.component.interfaces";

const mapStateToProps = (state: State): AanwijzingRisicovolleBedrijvenFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.aanwijzingRisicovolleBedrijven
      ? (state.vragen.formulier as AanwijzingRisicovolleBedrijvenFormulier)
      : undefined;
  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AanwijzingRisicovolleBedrijvenFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initAanwijzingRisicovolleBedrijvenFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getAanwijzingRisicovolleBedrijvenMelding(meldingId));
    } else {
      dispatch(initAanwijzingRisicovolleBedrijvenFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AanwijzingRisicovolleBedrijvenFormulierComponent);
