import { AsbestverwijderingFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getAsbestverwijderingFormulierDefinitie = (formulier: AsbestverwijderingFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.sanering.titel,
      type: ProcesstapType.sanering
    },
    {
      title: formulier.blokken.melder.titel,
      type: ProcesstapType.melder
    },
    {
      title: formulier.blokken.sloopmelding.titelVerkort,
      type: ProcesstapType.sloopmelding
    },
    {
      title: formulier.blokken.inventarisatie.titelVerkort,
      type: ProcesstapType.inventarisatie
    },
    {
      title: formulier.blokken.locatie.titelVerkort,
      type: ProcesstapType.locatie
    },
    {
      title: formulier.blokken.werkzaamheden.titelVerkort,
      type: ProcesstapType.werkzaamheden
    }
  ];
};
