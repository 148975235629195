import { connect } from "react-redux";
import { State } from "../../../store";
import { VestigingSamenvatting } from "./vestiging-samenvatting.component";
import {
  VestigingSamenvattingOwnProps,
  VestigingSamenvattingStateProps
} from "./vestiging-samenvatting.component.interfaces";

const mapStateToProps = (state: State, ownProps: VestigingSamenvattingOwnProps): VestigingSamenvattingStateProps => ({
  antwoordBlokken: state.antwoorden.blokken,
  user: state.security.user,
  autorisatiegegevens: state.autorisatie.autorisatiegegevens,
  vestiging: ownProps.useVestiging ?? state.vestigingen.searches[ownProps.vragen.key].committedVestiging,
  vraagBlokken: state.vragen.formulier?.blokken,
  meldingStatus: state.melding?.status,
  wordtMeldingGewijzigd: state.melding?.wordtGewijzigd
});

export default connect(mapStateToProps)(VestigingSamenvatting);
