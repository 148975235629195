import { setDigipassResponse, setSubmitRequested, setSubmitting, setSubmittingComplete } from "../actions";
import { digipassApi } from "../api";
import { DigipassAntwoorden, DigipassResponse } from "../models/api";
import { AppThunk } from "./app-thunk-type";

export const submitDigipass =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setSubmitting());

    const state = getState();

    const antwoorden = state.antwoorden.blokken.digipass as DigipassAntwoorden;
    const pincode = antwoorden.controlenummer.waarde;

    dispatch(checkDigipass(pincode));

    dispatch(setSubmittingComplete(true));
    dispatch(setSubmitRequested(false));
  };

export const checkDigipass =
  (pincode: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await digipassApi.checkDigipass(pincode).then((response: DigipassResponse) => {
      dispatch(setDigipassResponse(response));
    });    
  };
