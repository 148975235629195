import { useTranslation } from "react-i18next";
import { MogelijkAntwoord } from "../../../models/api";
import { DebouncedFormControl } from "../debounce-input";
import { Field } from "../field";
import { InputDropdownOption } from "./input-dropdown-option/input-dropdown-option";

export const InputDropdown = (props: any) => {
  const { name, touched, error, serverError, onBlur, validate, loading, mogelijkeAntwoorden, ...propsRest } = props;
  const { labelOnly, inputOnly, label, ...propsControl } = propsRest;
  const { i18n } = useTranslation();

  const lang: string = i18n.language;

  const toRadioOption = (ma: MogelijkAntwoord) => {
    return (
      <InputDropdownOption id={`${name}.${ma.waarde}`} key={ma.waarde} label={ma.tekst[lang]} optionValue={ma.waarde} />
    );
  };

  const isInvalid = (touched && error) || serverError;

  return (
    <Field as="select" name={name} {...propsRest}>
      <DebouncedFormControl
        name={name}
        id={name}
        isInvalid={isInvalid}
        onBlur={onBlur}
        as="select"
        {...(props.inputOnly ? { "aria-label": props.label } : {})}
        {...propsControl}
      >
        <option value={undefined}></option>
        {props.children ?? mogelijkeAntwoorden.map(toRadioOption)}
      </DebouncedFormControl>
    </Field>
  );
};
