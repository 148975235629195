import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { WerkmomentGegevensVragen } from "./werkmoment-gegevens-vragen";

export class WerkmomentenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public werkmomentenRegistrerenNietMogelijk: Tekst,
    public werkmomentenRegistrerenNietMogelijkRisicoOnbekend: Tekst,
    public werkmomentenRegistrerenToelichting: Tekst,
    public werkmomentenGegevensLijst: VraagBlokCollection<WerkmomentGegevensVragen>,
    public verklaringNoodzaakLaatWerken: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): WerkmomentenVragen {
    return new WerkmomentenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toWaarschuwing(`${key}.werkmomentenRegistrerenNietMogelijk`, json.werkmomentenRegistrerenNietMogelijk),
      jsonHelpers.toWaarschuwing(
        `${key}.werkmomentenRegistrerenNietMogelijkRisicoOnbekend`,
        json.werkmomentenRegistrerenNietMogelijkRisicoOnbekend
      ),
      jsonHelpers.toParagraaf(`${key}.werkmomentenRegistrerenToelichting`, json.werkmomentenRegistrerenToelichting),
      VraagBlokCollection.fromJson<WerkmomentGegevensVragen>(
        WerkmomentGegevensVragen.fromJson,
        `${key}.werkmomentenGegevensLijst`,
        json.werkmomentenGegevensLijst,
        VraagBlokCollectionType.werkmomentGegevens,
        false
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.verklaringNoodzaakLaatWerken`, json.verklaringNoodzaakLaatWerken)
    );
  }
}
