import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { MelderAntwoorden } from "./melder-antwoorden";

export class OngevalMelderAntwoorden extends AntwoordBlok {
  constructor(
    public common: MelderAntwoorden | null,
    public relatieTovBedrijfOngeval: Antwoord<string>,
    public andereHandhavingsinstantie: Antwoord<string>,
    public andereRelatie: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): OngevalMelderAntwoorden {
    return new OngevalMelderAntwoorden(
      MelderAntwoorden.initialize(user),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): OngevalMelderAntwoorden {
    return new OngevalMelderAntwoorden(
      MelderAntwoorden.fromJson(json.common),
      Antwoord.fromJson<string>(json.relatieTovBedrijfOngeval),
      Antwoord.fromJson<string>(json.andereHandhavingsinstantie),
      Antwoord.fromJson<string>(json.andereRelatie)
    );
  }
}
