import { connect } from "react-redux";
import { userHelpers } from "../../helpers";
import { State } from "../../store";
import { FormulierIngetrokken } from "./formulier-ingetrokken.component";
import { FormulierIngetrokkenStateProps } from "./formulier-ingetrokken.component.interfaces";

const mapStateToProps = (state: State): FormulierIngetrokkenStateProps => ({
  isInterneMedewerker: userHelpers.getIsInternalUser(state.security.user)
});

export default connect(mapStateToProps)(FormulierIngetrokken);
