import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldDatumvraag } from "../../../samenvatting";

export class Datum {
  static getType = (): VraagType => VraagType.datum;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldDatumvraag vraag={vraag} />;
}
