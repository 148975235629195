import {
  AndereWerkgeverAntwoorden,
  AndereWerkgeverVragen,
  AntwoordBlokCollectionItem,
  AvvArtikel10AVerzoekPeriodeAntwoorden,
  KindGegevensAntwoorden,
  LocatieWerkzaamhedenAntwoorden,
  OpdrachtgeverAntwoorden,
  OpdrachtgeverVragen,
  PeriodeMeldingAvvArtikel10Antwoorden,
  WerkmomentGegevensAntwoorden
} from "models/api";
import { Dictionary } from "models/application";
import { VestigingenSearchesState } from "store";
import { dateHelpers } from "./date-helpers";
import { nameHelpers } from "./name-helpers";

const getKindgegevensDisplayAntwoord = (instance: KindGegevensAntwoorden): string => {
  const nummer = instance.bsn.waarde ? instance.bsn.waarde : instance.kindVolgnummer.waarde;
  const naam = nameHelpers.getVolledigeNaamMetRoepnaam({
    roepnaam: instance.roepnaam?.waarde,
    tussenvoegsels: instance.tussenvoegsels?.waarde,
    achternaam: instance.achternaam?.waarde
  });
  return `${nummer} ${naam}`;
};

const getLocatieWerkzaamhedenDisplayAntwoord = (instance: LocatieWerkzaamhedenAntwoorden): string => {
  return instance.locatieNaam.waarde;
};

const getWerkmomentGegevensDisplayAntwoord = (instance: WerkmomentGegevensAntwoorden): string => {
  return `${dateHelpers.formatDate(instance.datumWerkmoment?.waarde)} ${instance.aanvangAanwezig.waarde}-${
    instance.eindeAanwezig.waarde
  }`;
};

const getPeriodeMeldingAvvArtikel10DisplayAntwoord = (instance: PeriodeMeldingAvvArtikel10Antwoorden): string => {
  return dateHelpers.isValidDate(instance.begindatum?.waarde) ? dateHelpers.formatDate(instance.begindatum.waarde) : "-";
};

const getAndereWerkgeverDisplayAntwoord = (
  instance: AndereWerkgeverAntwoorden,
  vraagBlok: AndereWerkgeverVragen,
  vestigingSearches: Dictionary<VestigingenSearchesState>
): string => {
  let result: string | undefined;

  if (instance) {
    const ja = "ja";

    if (
      instance.andereWerkgever?.kvkNummerGebruiken.waarde === ja &&
      instance.andereWerkgever?.bedrijfsgegevens?.geselecteerdeVestiging?.waarde
    ) {
      result = vestigingSearches[vraagBlok.andereWerkgever.bedrijfsgegevens.key].selectedVestiging?.ves_naam;
    } else {
      result = instance.andereWerkgever?.bedrijfsnaam.waarde;
    }
  }

  return result ?? "";
};

const getOpdrachtgeverDisplayAntwoord = (
  instance: OpdrachtgeverAntwoorden,
  vraagBlok: OpdrachtgeverVragen,
  vestigingSearches: Dictionary<VestigingenSearchesState>
): string => {
  let result: string | undefined;

  if (instance) {
    const ja = "ja";

    if (instance.bedrijfIngeschreven.waarde === ja && instance.kvkgegevens?.geselecteerdeVestiging?.waarde) {
      result = vestigingSearches[vraagBlok.kvkgegevens.key].selectedVestiging?.ves_naam;
    } else {
      result = instance.bedrijfsnaam.waarde;
    }
  }

  return result ?? "";
};

const getPeriodeDisplayAntwoord = (instance: AvvArtikel10AVerzoekPeriodeAntwoorden): string => {
  if (!instance.startdatum?.waarde && !instance.einddatum?.waarde) {
    return "-";
  }

  const startDate = instance.startdatum?.waarde ? dateHelpers.formatDate(instance.startdatum.waarde) : "";
  const endDate = instance.einddatum?.waarde ? dateHelpers.formatDate(instance.einddatum.waarde) : "";

  return `${startDate} - ${endDate}`;
};

const getDisplayAntwoord = (instance: AntwoordBlokCollectionItem): string => {
  switch (instance.type) {
    case KindGegevensAntwoorden.type: {
      return getKindgegevensDisplayAntwoord(instance as KindGegevensAntwoorden);
    }
    case LocatieWerkzaamhedenAntwoorden.type: {
      return getLocatieWerkzaamhedenDisplayAntwoord(instance as LocatieWerkzaamhedenAntwoorden);
    }
    case WerkmomentGegevensAntwoorden.type: {
      return getWerkmomentGegevensDisplayAntwoord(instance as WerkmomentGegevensAntwoorden);
    }
    case PeriodeMeldingAvvArtikel10Antwoorden.type: {
      return getPeriodeMeldingAvvArtikel10DisplayAntwoord(instance as PeriodeMeldingAvvArtikel10Antwoorden);
    }
    case AvvArtikel10AVerzoekPeriodeAntwoorden.type: {
      return getPeriodeDisplayAntwoord(instance as AvvArtikel10AVerzoekPeriodeAntwoorden);
    }
    default: {
      return "";
    }
  }
};

const container = {
  getDisplayAntwoord,
  getOpdrachtgeverDisplayAntwoord,
  getAndereWerkgeverDisplayAntwoord
};

export { container as displayAntwoordHelpers };
