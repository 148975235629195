import {
  AanleveringLavsV2Vragen,
  AsbestWerkzaamhedenLavsV2Vragen,
  CertificerendeInstellingVragen,
  InventarisatieLavsV2Vragen,
  LaboratoriumVragen,
  LocatieLavsVragen,
  MelderLavsVragen,
  OpdrachtgeverLavsVragen,
  OpdrachtnemerLavsVragen,
  PeriodeDatumTijdVragen,
  VraagBlokken
} from "../blok";

export class AsbestverwijderingLavsV2FormulierVraagBlokken extends VraagBlokken {
  [key: string]: any;

  constructor(
    public aanlevering: AanleveringLavsV2Vragen,
    public melder: MelderLavsVragen,
    public certificerendeInstelling: CertificerendeInstellingVragen,
    public opdrachtgever: OpdrachtgeverLavsVragen,
    public opdrachtnemer: OpdrachtnemerLavsVragen,
    public inventarisatie: InventarisatieLavsV2Vragen,
    public locatie: LocatieLavsVragen,
    public periode: PeriodeDatumTijdVragen,
    public werkzaamheden: AsbestWerkzaamhedenLavsV2Vragen,
    public laboratorium: LaboratoriumVragen
  ) {
    super();
  }

  static fromJson(json: any): AsbestverwijderingLavsV2FormulierVraagBlokken {
    return new AsbestverwijderingLavsV2FormulierVraagBlokken(
      AanleveringLavsV2Vragen.fromJson("aanlevering", json.aanlevering),
      MelderLavsVragen.fromJson("melder", json.melder),
      CertificerendeInstellingVragen.fromJson("certificerendeInstelling", json.certificerendeInstelling),
      OpdrachtgeverLavsVragen.fromJson("opdrachtgever", json.opdrachtgever),
      OpdrachtnemerLavsVragen.fromJson("opdrachtnemer", json.opdrachtnemer),
      InventarisatieLavsV2Vragen.fromJson("inventarisatie", json.inventarisatie),
      LocatieLavsVragen.fromJson("locatie", json.locatie),
      PeriodeDatumTijdVragen.fromJson("periode", json.periode),
      AsbestWerkzaamhedenLavsV2Vragen.fromJson("werkzaamheden", json.werkzaamheden),
      LaboratoriumVragen.fromJson("laboratorium", json.laboratorium)
    );
  }
}
