import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsElfproef extends VraagExpression {
  get operator(): string {
    return IsElfproef.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    let result = true;

    const elf = 11;

    const waarde = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;

    if (waarde) {
      const chars = Array.from(waarde as string);

      chars.reverse();

      const total = chars.reduce<number>(
        (previous, currentChar, currentIndex) =>
          previous + +currentChar * (currentIndex + 1) * (currentIndex === 0 ? -1 : 1),
        0
      );

      result = total % elf === 0;
    }

    return result;
  };

  protected clone = (instance: IsElfproef): IsElfproef => new IsElfproef(instance.vraagProperty);

  static fromJson = (json: any): IsElfproef => new IsElfproef(json.vraag);

  static operator = (): string => "isElfproef";

  static matches = (json: any): boolean => json?.operator === IsElfproef.operator();
}
