import { useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import { meldingHelpers, objectHelpers } from "../../../helpers";
import { Antwoord, AntwoordBlokken } from "../../../models/api";
import { AutoHiddenVragenblok } from "../../auto-hidden-blok";
import { FormulierInputControls } from "../../formulier";
import { NederlandsAdresProps } from "./nederlands-adres.component.interfaces";

export const NederlandsAdres = (props: NederlandsAdresProps) => {
  const [adresFound, setAdresFound] = useState(false);

  const { setFieldValue, setFieldError, validateForm, values } = useFormikContext<AntwoordBlokken>();

  const straatnaamKey = meldingHelpers.getFieldNameFromVraag(props.vragen.straatnaam);
  const plaatsKey = meldingHelpers.getFieldNameFromVraag(props.vragen.plaats);

  useEffect(() => {
    const updateAdresFields = () => {
      const addressFound = props.adressen.length > 0;

      setAdresFound(addressFound);

      if (addressFound) {
        const firstAddress = props.adressen[0];
        setFieldValue(straatnaamKey, firstAddress.straat);
        setFieldValue(plaatsKey, firstAddress.plaats);
        setFieldError(plaatsKey, "");
      }
    };

    updateAdresFields();
  }, [props.adressen, props.vragen, setFieldValue, setFieldError, straatnaamKey, plaatsKey]);

  const onAdresLookup = () => {
    const huisnummer = objectHelpers.getValue<Antwoord<number | null>>(values, props.vragen.huisnummer.key)?.waarde;
    const postcode = objectHelpers.getValue<Antwoord<string>>(values, props.vragen.postcode.key)?.waarde;

    if (
      props.adressen.length === 0 ||
      props.adressen[0].postcode.toUpperCase() !== postcode?.toUpperCase() ||
      props.adressen[0].huisnummer !== huisnummer
    ) {
      if (postcode) {
        invalidateCurrentAddress();
      }
      props.onSearchAdres({
        postcode: postcode ?? null,
        huisnummer: huisnummer ?? null
      });
    }
  };

  const invalidateCurrentAddress = useCallback(() => {
    setAdresFound(false);
    setFieldValue(straatnaamKey, "");
    setFieldValue(plaatsKey, "");
  }, [plaatsKey, setFieldValue, straatnaamKey]);

  useEffect(() => {
    async function validateAsync() {
      if (!props.plaatsenIsLoading) {
        await validateForm();
      }
    }

    validateAsync();
  }, [props.plaatsenIsLoading, validateForm]);

  return (
    <AutoHiddenVragenblok>
      <FormulierInputControls.Text vraag={props.vragen.postcode} onBlur={onAdresLookup} readOnly={props.readOnly} />
      <FormulierInputControls.Number vraag={props.vragen.huisnummer} onBlur={onAdresLookup} readOnly={props.readOnly} />
      <FormulierInputControls.Text vraag={props.vragen.toevoeging} readOnly={props.readOnly} />
      <FormulierInputControls.Text
        vraag={props.vragen.straatnaam}
        disabled={adresFound || props.adressenIsLoading || props.readOnly}
        loading={props.adressenIsLoading}
      />
      <FormulierInputControls.AsyncTypeahead
        vraag={props.vragen.plaats}
        isLoading={props.plaatsenIsLoading}
        options={props.plaatsen}
        onSearch={props.onSearchPlaatsen}
        onSelect={props.onSelectPlaats}
        disabled={adresFound || props.adressenIsLoading || props.readOnly}
        loading={props.adressenIsLoading}
      />
    </AutoHiddenVragenblok>
  );
};
