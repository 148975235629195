import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VisibleWrapper } from "components/visible-wrapper";
import { translationKeys } from "constants/translation-keys";
import { useFormikContext } from "formik";
import { objectHelpers } from "helpers";
import { AntwoordBlokken, VraagBlokCollectionVerplichtType } from "models/api";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CollectionItemCanBeRemovedResult, useCollectionEvents } from "../context";
import { CollectionRemoveProps } from "./collection-remove.component.interfaces";

export const CollectionRemove = (props: CollectionRemoveProps) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext<AntwoordBlokken>();
  const { values } = formikContext;
  const collectionEvents = useCollectionEvents();
  const [canBeRemoved, setCanBeRemoved] = useState<CollectionItemCanBeRemovedResult | undefined>(undefined);

  const collectionAntwoorden = objectHelpers.getVraagBlokCollectionValues(values, props.collectionKey);
  const disableRemoveLastItem =
    collectionAntwoorden?.length === 1 &&
    props.collectionVerplichtType === VraagBlokCollectionVerplichtType.VerplichtEnGetoond;
  const enableRemove = canBeRemoved?.canBeRemoved ?? true;

  const handleCanBeRemoved = useCallback(() => {
    if (collectionEvents.canBeRemoved) {
      const canBeRemovedResult = collectionEvents.canBeRemoved(
        props.vraagAntwoordBlok.vraagBlok.key,
        props.vraagAntwoordBlok.antwoordBlokIndex
      );

      setCanBeRemoved(canBeRemovedResult);
      return canBeRemovedResult?.canBeRemoved ?? true;
    }
    return true;
  }, [props.vraagAntwoordBlok.vraagBlok.key, props.vraagAntwoordBlok.antwoordBlokIndex, collectionEvents]);

  useEffect(() => {
    handleCanBeRemoved();
  }, [handleCanBeRemoved]);

  const onRemovingItem = async (e: any) => {
    e.stopPropagation();
    props.removeCollectionItem(props.vraagAntwoordBlok, formikContext);
  };

  const title = !enableRemove && !!canBeRemoved?.reason ? canBeRemoved?.reason : t(translationKeys.verwijderen);

  const displayButton = () => {
    return (
      <Fragment>
        <Button
          disabled={!enableRemove || disableRemoveLastItem}
          id={`${props.collectionKey}.list.${props.rowIndex}.button.remove`}
          className="button-with-icon-start"
          onClick={onRemovingItem}
          title={title}
        >
          <FontAwesomeIcon icon={faTrash} size="1x" />
          {t(translationKeys.verwijderen)}
        </Button>
        <VisibleWrapper isVisible={!enableRemove && !!canBeRemoved?.reason}>
          <Alert id={`${props.collectionKey}.list.${props.rowIndex}.noremove-reason`} className="mt-2" variant={"warning"}>
            {canBeRemoved?.reason}
          </Alert>
        </VisibleWrapper>
      </Fragment>
    );
  };

  const displayIcon = () => {
    return (
      <Fragment>
        <Button
          disabled={!enableRemove || disableRemoveLastItem}
          id={`${props.collectionKey}.list.${props.rowIndex}.icon.remove`}
          className="icon-button"
          variant="link"
          onClick={onRemovingItem}
          title={title}
        >
          <span className="visually-hidden">{title}</span>
          <FontAwesomeIcon icon={faTrash} size="1x" />
        </Button>
      </Fragment>
    );
  };

  return props.displayIconOnly ? displayIcon() : displayButton();
};
