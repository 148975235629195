import { SamenvattingContext } from "components/samenvatting";
import { SamenvattingField } from "components/samenvatting/samenvatting-field";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { VraagBlok } from "../../../models/api";

const MAX_SAMENVATTING_ITEMS_COUNT = 10;
export const VraagBlokCollectionSamenvatting = <TVraagBlok extends VraagBlok>(props: any) => {
  const { i18n } = useTranslation();
  const totaalAantal = props.vraagBlokCollection.list.length;

  const samenvattingContext = React.useContext(SamenvattingContext);

  return totaalAantal <= MAX_SAMENVATTING_ITEMS_COUNT ? (
    <Fragment key={props.vraagBlokCollection.key}>
      {props.vraagBlokCollection.list.map((vb: TVraagBlok, index: number) => {
        const antwoordBlok = props.antwoordBlokCollection?.list[index];
        if (antwoordBlok) {
          return React.createElement(props.component, {
            vragen: vb,
            antwoorden: antwoordBlok,
            processtap: props.processtap,
            index: index,
            key: vb.key
          });
        }
        return null;
      })}
    </Fragment>
  ) : (
    <SamenvattingField
      label={props.vraagBlokCollection.titel[i18n.language]}
      waarde={`${totaalAantal} ${props.vraagBlokCollection.collectieTekst[i18n.language].toLowerCase()}`}
      id={`${samenvattingContext.idPrefix}__${props.vraagBlokCollection.key}`}
    ></SamenvattingField>
  );
};
