import { ErrorMessageControl } from "components/error";
import { useField, useFormikContext } from "formik";
import { meldingHelpers } from "helpers";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BrpSearchResult } from "store/brp-search-result";
import { translationKeys } from "../../../../constants";
import { AntwoordBlokken, VraagBlok } from "../../../../models/api";
import { FormulierInputControls } from "../../../formulier";
import { BrpSearchProps } from "./brp-search.component.interfaces";

const BrpEditComponent = (props: {
  vragen: VraagBlok;
  brpSearchResult: BrpSearchResult;
  hasBrpServerError: boolean;
  onInputChange: () => void;
  onInputBlur: () => void;
  brpIsLoading: boolean;
  bsnIsGeldig: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FormulierInputControls.Text
        vraag={props.vragen.bsn}
        key={props.vragen.bsn.key}
        onChange={props.onInputChange}
        onBlur={props.onInputBlur}
        loading={props.brpIsLoading}
        inputValidated={props.bsnIsGeldig}
        disabled={props.brpIsLoading || props.bsnIsGeldig}
      />
      <FormulierInputControls.Date
        vraag={props.vragen.geboorteDatum}
        key={props.vragen.geboorteDatum.key}
        onChange={props.onInputChange}
        onBlur={props.onInputBlur}
        loading={props.brpIsLoading}
        inputValidated={props.bsnIsGeldig}
        disabled={props.brpIsLoading || props.bsnIsGeldig}
      />
      <FormulierInputControls.Text
        vraag={props.vragen.achternaam}
        key={props.vragen.achternaam.key}
        onChange={props.onInputChange}
        onBlur={props.onInputBlur}
        loading={props.brpIsLoading}
        inputValidated={props.bsnIsGeldig}
        disabled={props.brpIsLoading || props.bsnIsGeldig}
      />
      {props.hasBrpServerError && (
        <ErrorMessageControl name="vraagblok-collection-error" scrollIntoViewCondition={() => !!props.hasBrpServerError}>
          <div>{t(translationKeys.brpSearch.connectieError)}</div>
        </ErrorMessageControl>
      )}
    </>
  );
};

export const BrpSearch = (props: BrpSearchProps) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<AntwoordBlokken>();
  const bsnAntwoord = meldingHelpers.getAntwoordOrDefault<string>(values, props.vragen.bsn, "");
  const geboorteDatumAntwoord = meldingHelpers.getAntwoordOrDefault<string>(values, props.vragen.geboorteDatum, "");
  const achternaamAntwoord = meldingHelpers.getAntwoordOrDefault<string>(values, props.vragen.achternaam, "");
  const bsnIsGeldig = meldingHelpers.getAntwoordOrDefault<boolean | undefined>(values, props.vragen.bsnIsGeldig, undefined);

  const [previousBsn, setPreviousBsn] = useState("");
  const [previousGeboortedatum, setPreviousGeboortedatum] = useState("");
  const [previousAchternaam, setPreviousAchternaam] = useState("");

  const [, metaBsn] = useField(meldingHelpers.getFieldNameFromVraag(props.vragen.bsn));
  const [, metaGeboortedatum] = useField(meldingHelpers.getFieldNameFromVraag(props.vragen.geboorteDatum));
  const [, metaAchternaam] = useField(meldingHelpers.getFieldNameFromVraag(props.vragen.achternaam));

  const brpFieldsAreChanged = (): boolean => {
    return (
      previousBsn !== bsnAntwoord ||
      previousGeboortedatum !== geboorteDatumAntwoord ||
      previousAchternaam !== achternaamAntwoord
    );
  };

  const brpFieldsAreNotEmpty = (): boolean => {
    return !!(bsnAntwoord && geboorteDatumAntwoord && achternaamAntwoord);
  };

  const brpFieldsHaveNoErrors = (): boolean => {
    return !metaBsn.error && !metaGeboortedatum.error && !metaAchternaam.error;
  };

  const brpFieldsAreChangedAndNotEmptyAndHaveNoErrors = (): boolean => {
    return brpFieldsAreChanged() && brpFieldsAreNotEmpty() && brpFieldsHaveNoErrors();
  };

  const onInputChange = () => {
    const vraagKey = meldingHelpers.getFieldNameFromVraag(props.vragen.bsnIsGeldig);
    if (brpFieldsAreChanged()) {
      setFieldValue(vraagKey, false, true);
    }
    setFieldTouched(vraagKey, false);
  };

  const onInputBlur = () => {
    if (brpFieldsAreChangedAndNotEmptyAndHaveNoErrors()) {
      setPreviousBsn(bsnAntwoord);
      setPreviousGeboortedatum(geboorteDatumAntwoord);
      setPreviousAchternaam(achternaamAntwoord);
      props.searchBrp({
        bsn: bsnAntwoord,
        geboortedatum: new Date(geboorteDatumAntwoord),
        achternaam: achternaamAntwoord
      });
    }
  };

  const checkBrpResult = useCallback(async () => {
    if (!props.brpSearchResult || props.brpSearchResult.busy || props.brpSearchResult.error) {
      // effect gaat af bij initiële render, dan is er nog geen 'cached' brp result.
      // controle is dan niet nodig, pas bij changes van de relevante kindgegevens.
      return;
    }

    if (props.brpSearchResult.achternaam) {
      setFieldValue(meldingHelpers.getFieldNameFromVraag(props.vragen.bsnIsGeldig), "true", true);

      setFieldValue(meldingHelpers.getFieldNameFromVraag(props.vragen.achternaam), props.brpSearchResult.achternaam, true);
      setFieldValue(
        meldingHelpers.getFieldNameFromVraag(props.vragen.tussenvoegsels),
        props.brpSearchResult.tussenvoegsels ?? "",
        true
      );
    } else {
      setFieldValue(meldingHelpers.getFieldNameFromVraag(props.vragen.bsnIsGeldig), false, true);
    }

    setFieldTouched(meldingHelpers.getFieldNameFromVraag(props.vragen.bsnIsGeldig));
  }, [
    setFieldValue,
    setFieldTouched,
    props.brpSearchResult,
    props.vragen.achternaam,
    props.vragen.bsnIsGeldig,
    props.vragen.tussenvoegsels
  ]);

  useEffect(() => {
    checkBrpResult();
  }, [checkBrpResult]);

  return (
    <>
      <BrpEditComponent
        vragen={props.vragen}
        brpSearchResult={props.brpSearchResult}
        hasBrpServerError={props.hasBrpServerError}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        brpIsLoading={props.brpIsLoading}
        bsnIsGeldig={!!bsnIsGeldig}
      />
      <FormulierInputControls.Text
        vraag={props.vragen.tussenvoegsels}
        key={props.vragen.tussenvoegsels.key}
        loading={props.brpIsLoading}
        inputValidated={!!bsnIsGeldig}
        disabled={props.brpIsLoading || !!bsnIsGeldig}
      />
    </>
  );
};
