import _ from "lodash";
import { AntwoordBlok } from "./blok/antwoord-blok";

export interface IAntwoord {
  initialValue: any;
  waarde: any;
}

export class Antwoord<TAntwoordType> implements IAntwoord, IVertaalbaarAntwoord {
  public initialValue: TAntwoordType;

  constructor(public waarde: TAntwoordType, public vertaaldeWaarde?: TAntwoordType | undefined) {
    this.initialValue = waarde;

    if (!_.isString(vertaaldeWaarde)) {
      // reset vertaalde waarde als het geen string is
      this.vertaaldeWaarde = undefined;
    }
  }

  static fromJson<T>(json: any): Antwoord<T> {
    return new Antwoord<T>(json?.waarde, json?.vertaaldeWaarde);
  }

  static fromJsonOrDefault<T>(json: any, defaultValue?: T): Antwoord<T> {
    return new Antwoord<T>(json?.waarde || defaultValue);
  }

  static fromJsonArray<T>(json: any, itemFromJson: (item: any) => T): Antwoord<T[]> {
    return new Antwoord<T[]>(json?.waarde?.map((item: any) => itemFromJson(item)) ?? []);
  }
}

export type AntwoordType = AntwoordBlok | IAntwoord;

export interface IVertaalbaarAntwoord extends IAntwoord {
  vertaaldeWaarde?: any;
}
