import { DigipassState } from "../store";
import { DigipassActions, SET_DIGIPASS } from "./../actions";

const initialState: DigipassState = {
  digipassResponse: undefined
};

const digipassReducer = (state: DigipassState = initialState, action: DigipassActions): DigipassState => {
  if (action && action.type === SET_DIGIPASS) {
    return {
      ...state,
      digipassResponse: action.digipassResponse
    };
  } else {
    return state;
  }
};

export default digipassReducer;
