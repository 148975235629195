import { connect } from "react-redux";
import { State } from "store";
import { addBreadcrumb, clearBreadcrumb } from "./../../../actions";
import { Configuration } from "./../../../configuration";
import { Breadcrumb as BreadcrumbItem } from "./../../../models/application";
import { Breadcrumb as BreadcrumbCmp } from "./breadcrumb.component";
import { BreadcrumbDispatchProps, BreadcrumbOwnProps, BreadcrumbStateProps } from "./breadcrumb.component.interface";

const mapStateToProps = (state: State, ownProps: BreadcrumbOwnProps): BreadcrumbStateProps => ({
  shouldRender: Configuration.showNlArbeidsinspectieBreadcrumbs || !ownProps.url.includes("nlarbeidsinspectie.nl")
});

const mapDispatchToProps = (dispatch: any): BreadcrumbDispatchProps => ({
  onAdd: (breadcrumb: BreadcrumbItem) => dispatch(addBreadcrumb(breadcrumb)),
  onClearBreadcrumb: (url: string) => dispatch(clearBreadcrumb(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(BreadcrumbCmp);
