import { connect } from "react-redux";
import { State } from "../../store";
import { getMededeling } from "../../thunks/beheer-thunks";
import { Mededeling } from "./mededeling.component";
import { MededelingDispatchProps, MededelingStateProps } from "./mededeling.component.interfaces";

const mapStateToProps = (state: State): MededelingStateProps => ({
  mededelingen: state.mededeling.mededelingen || undefined
});

const mapDispatchToProps = (dispatch: any): MededelingDispatchProps => ({
  onLoad: () => {
    dispatch(getMededeling());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Mededeling);
