import { expressionHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsAsked extends VraagExpression {
  get operator(): string {
    return IsAsked.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean =>
    expressionHelpers.wordtItemByKeyGesteld(this.vraagProperty ?? vraagProperty, vraagBlok, context);

  protected clone = (instance: IsAsked): IsAsked => new IsAsked(instance.vraagProperty);

  static fromJson = (json: any): IsAsked => new IsAsked(json.vraag);

  static operator = (): string => "isAsked";

  static matches = (json: any): boolean => json?.operator === IsAsked.operator();
}
