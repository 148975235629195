import { Formuliertype } from "../../application";
import { AsbestverwijderingLavsFormulierVraagBlokken } from "./asbestverwijdering-lavs-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AsbestverwijderingLavsFormulier extends FormulierBase {
  constructor(public blokken: AsbestverwijderingLavsFormulierVraagBlokken, json: any) {
    super(Formuliertype.asbestverwijderingLavs, blokken, json);
  }

  static fromJson(json: any): AsbestverwijderingLavsFormulier {
    return new AsbestverwijderingLavsFormulier(AsbestverwijderingLavsFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
