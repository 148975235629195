import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { VisibleWrapper } from "../visible-wrapper";
import { ButtonWithLoaderProps } from "./button-with-loader.component.interfaces";

export const ButtonWithLoader = (props: ButtonWithLoaderProps) => {
  const { isVisible, isLoading, ...buttonProps } = { ...props };

  return (
    <VisibleWrapper isVisible={isVisible}>
      <Button aria-disabled={isLoading} disabled={isLoading} {...buttonProps}>
        {buttonProps.children} {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      </Button>
    </VisibleWrapper>
  );
};
