import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { OntheffingLiftenFormulierVraagBlokken } from "./ontheffing-liften-formulier-vraag-blokken";

export class OntheffingLiftenFormulier extends FormulierBase {
  constructor(public blokken: OntheffingLiftenFormulierVraagBlokken, json: any) {
    super(Formuliertype.ontheffingLiften, blokken, json);
  }

  static fromJson(json: any): OntheffingLiftenFormulier {
    return new OntheffingLiftenFormulier(OntheffingLiftenFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
