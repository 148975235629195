import { Antwoord } from "..";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { Bestand } from "./bestand";

export class ContactVraagAntwoorden extends AntwoordBlok {
  constructor(
    public onderwerp: Antwoord<string>,
    public referentieIszw: Antwoord<string>,
    public toelichting: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): ContactVraagAntwoorden {
    return new ContactVraagAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): ContactVraagAntwoorden {
    return new ContactVraagAntwoorden(
      Antwoord.fromJson<string>(json.onderwerp),
      Antwoord.fromJson<string>(json.referentieIszw),
      Antwoord.fromJson<string>(json.toelichting),
      Antwoord.fromJson<Bestand[]>({
        waarde: json.bestanden.waarde.map((b: any) => Bestand.fromJson(b))
      })
    );
  }
}
