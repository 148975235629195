import { InputValidated } from "components/input-validated";
import { useFormikContext } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { dateHelpers, objectHelpers } from "../../../helpers";
import { DebouncedFormControl } from "../debounce-input";
import { Field } from "../field";
import { Spinner } from "./../../spinner";

const dayMonthYearRegex = /^(0[1-9]|[1-2]\d|3[0-1]|[1-9])\x2d((0[1-9]|1[0-2])|[1-9])\x2d((19|20)\d{2})$/;
const yearMonthDayRegex = /^(19|20)\d{2}\x2d((0[1-9]|1[0-2])|([1-9]))\x2d((0[1-9]|[1-2]\d|3[0-1])|[1-9])$/;
const invalidDateValue = "??-??-????";

export const InputDate = (props: any) => {
  const {
    name,
    touched,
    error,
    serverError,
    onBlur,
    onChange,
    onValueChanged,
    loading,
    inputValidated,
    placeholder,
    ...propsRest
  } = props;
  const { value, ...propsInput } = propsRest;
  const { setFieldTouched, values, setValues } = useFormikContext();

  const { t } = useTranslation();

  const [initialValue] = useState(props.value);

  useEffect(() => {
    if (dateHelpers.isDateInputSupported) {
      const htmlElement: any = document.getElementById(name);
      htmlElement.value = initialValue;
    }
  }, [name, initialValue]);

  const updateFieldValue = async (date: string) => {
    // issue in formik: https://github.com/jaredpalmer/formik/issues/529
    const oldValues: any = values;
    const newValues = { ...oldValues };
    objectHelpers.setValue(newValues, date, name);
    setValues(newValues, true);
    setFieldTouched(name, true);
    if (onValueChanged) {
      onValueChanged(date);
    }
  };

  const handleTextBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    }

    const isValid = dayMonthYearRegex.test(e.target?.value);
    updateFieldValue(
      isValid ? moment(e.target?.value, dateHelpers.dateDisplayFormat).format(dateHelpers.dateInputFormat) : e.target?.value
    );
  };

  const handleDateBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    }

    const isValid = e.target?.checkValidity();
    if (onValueChanged) {
      onValueChanged(isValid ? e.target?.value : invalidDateValue);
    }

    updateFieldValue(isValid ? e.target?.value : invalidDateValue);
  };

  // In case input type date is not supported use an internal backing field to bind
  // the input (of type text) to, so it can be formatted correctly
  let internalValue = "";

  const yearMonthDayLength = 10;

  if (!dateHelpers.isDateInputSupported) {
    if (props.value?.length === yearMonthDayLength && yearMonthDayRegex.test(props.value)) {
      internalValue = moment(props.value, dateHelpers.dateInputFormat).format(dateHelpers.dateDisplayFormat);
    } else {
      internalValue = props.value;
    }
  }

  let valueAttribute = {};
  if (value !== invalidDateValue) {
    // When value is different from the invalidDate string set the value as attribute otherwise
    // ommit value to keep invalid date being displayed.
    valueAttribute = { value: value };
  }

  const isInvalid = (touched && error) || serverError;
  return dateHelpers.isDateInputSupported ? (
    <Field name={name} {...propsRest}>
      <DebouncedFormControl
        type="date"
        name={name}
        id={name}
        isInvalid={isInvalid}
        min="1900-01-01"
        max="2099-12-31"
        {...propsInput}
        {...valueAttribute}
        onBlur={handleDateBlur}
        onChange={onChange}
      />
      {loading && <Spinner />}
      {inputValidated && <InputValidated />}
    </Field>
  ) : (
    <Field name={name} {...propsRest}>
      <DebouncedFormControl
        name={name}
        placeholder={t(translationKeys.placeholders.datum)}
        isInvalid={isInvalid}
        {...{ "aria-label": props.label }}
        {...propsInput}
        onChange={onChange}
        onBlur={handleTextBlur}
        value={internalValue}
      />
      {loading && <Spinner />}
      {inputValidated && <InputValidated />}
    </Field>
  );
};
