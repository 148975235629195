const getVraagBlokCollectionValues = <T>(object: any, vraagBlokCollectionKey: string): T[] | null => {
  return objectHelpers.getValue<T[]>(object, `${vraagBlokCollectionKey}.list`);
};

const removeLastPropertyFromKey = (vraagProperyKey: string, includeLastDot: boolean = false) => {
  return `${vraagProperyKey.substring(0, vraagProperyKey.lastIndexOf("."))}${includeLastDot ? "." : ""}`;
};

const getValue = <T>(object: any, propertyPath: string): T | null => {
  const [firstProperty, ...remainingProperties] = propertyPath.split(".");

  const firstPropertyCamelCased = toCamelCase(firstProperty);

  if (!object) {
    return null;
  } else {
    if (remainingProperties.length === 0) {
      return object[firstPropertyCamelCased];
    } else {
      return getValue<T>(object[firstPropertyCamelCased], remainingProperties.join("."));
    }
  }
};

const setValue = <T>(object: any, value: T, propertyPath: string) => {
  if (!object) {
    return;
  }

  const [firstProperty, ...remainingProperties] = propertyPath.split(".");

  const firstPropertyAsArrayIndex = Number(firstProperty);

  const firstPropertyCamelCased = !isNaN(firstPropertyAsArrayIndex) ? firstPropertyAsArrayIndex : toCamelCase(firstProperty);

  if (remainingProperties.length === 0) {
    object[firstPropertyCamelCased] = value;
  } else {
    if (!object[firstPropertyCamelCased]) {
      const firstRemainingProperty = remainingProperties[0];

      object[firstPropertyCamelCased] = !isNaN(Number(firstRemainingProperty)) ? [] : {};
    }

    setValue(object[firstPropertyCamelCased], value, remainingProperties.join("."));
  }
};

const toCamelCase = (s: string): string => {
  const [first, ...rest] = Array.from(s);

  return `${first.toLowerCase()}${rest.join("")}`;
};

const toPascalCase = (s: string): string => {
  const [first, ...rest] = Array.from(s);

  return `${first.toUpperCase()}${rest.join("")}`;
};

export const objectHelpers = {
  getValue,
  getVraagBlokCollectionValues,
  setValue,
  toPascalCase,
  toCamelCase,
  removeLastPropertyFromKey
};
