import { httpStatusCodes } from "./../constants";
import { xhrReadyState } from "./../constants/xhr-ready-state";
import { FetchOptions } from "./fetch-options";
import { getXsrfToken } from "./xsrf";

const MAX_PROCENT = 100;
const fetchBase = async <T>(options: FetchOptions, method: string): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    const xhr = new XMLHttpRequest();

    const progressHandler = options.progress;
    if (progressHandler) {
      xhr.upload.addEventListener("progress", function (event) {
        if (event.lengthComputable) {
          const progress = ((event.loaded / event.total) * MAX_PROCENT) | 0;
          progressHandler(progress);
        }
      });
    }

    xhr.onreadystatechange = function () {
      // Only run if the request is complete
      if (xhr.readyState !== xhrReadyState.done) {
        return;
      }

      // Process the response
      if (xhr.status >= httpStatusCodes.ok && xhr.status < httpStatusCodes.multipleChoices) {
        // If successful
        resolve(options.success(xhr.response));
      } else {
        // If failed
        const error = {
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.status === httpStatusCodes.preconditionFailed ? JSON.parse(xhr.response) : xhr.responseText
        };
        options.error && options.error(error);
        reject(error);
      }
    };

    xhr.open(method, `${options.uri}`);

    const token = getXsrfToken();
    if (token) {
      xhr.setRequestHeader("X-XSRF-TOKEN", token);
    }

    xhr.send(options.data);
  });
};

export const xhrPost = async <T>(options: FetchOptions): Promise<T> => {
  return fetchBase(options, "POST");
};

export const xhrPut = async <T>(options: FetchOptions): Promise<T> => {
  return fetchBase(options, "PUT");
};
