import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class VerklaringenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public situatieTitel: Tekst,
    public tankschipSituatie: Meerkeuzevraag,
    public gevolgenWerkzaamhedenTitel: Tekst,
    public plaatsaanduidingVonken: Vraag,
    public plaatsaanduidingTemperatuursverhoging: Vraag,
    public eerdereOntheffingTitel: Tekst,
    public briefkenmerkEerdereOntheffing: Vraag,
    public verklaringenTitel: Tekst,
    public uitlegEerdereOntheffing: Tekst,
    public uitlegArbeidsomstandighedenArtikel411: Tekst,
    public uitlegArbeidsomstandighedenArtikel412: Tekst,
    public bijlageTitel: Tekst,
    public bijlageVerplichtParagraaf: Tekst,
    public bestanden: Bestandenvraag,
    public overigeOpmerkingenTitel: Tekst,
    public omschrijving: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): VerklaringenVragen {
    return new VerklaringenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.situatieTitel`, json.situatieTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.tankschipSituatie`, json.tankschipSituatie),
      jsonHelpers.toTitel(`${key}.gevolgenWerkzaamhedenTitel`, json.gevolgenWerkzaamhedenTitel),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.plaatsaanduidingVonken`, json.plaatsaanduidingVonken),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.plaatsaanduidingTemperatuursverhoging`,
        json.plaatsaanduidingTemperatuursverhoging
      ),
      jsonHelpers.toTitel(`${key}.eerdereOntheffingTitel`, json.eerdereOntheffingTitel),
      jsonHelpers.toTekstVraag(`${key}.briefkenmerkEerdereOntheffing`, json.briefkenmerkEerdereOntheffing),
      jsonHelpers.toTitel(`${key}.verklaringenTitel`, json.verklaringenTitel),
      jsonHelpers.toParagraaf(`${key}.uitlegEerdereOntheffing`, json.uitlegEerdereOntheffing),
      jsonHelpers.toParagraaf(`${key}.uitlegArbeidsomstandighedenArtikel411`, json.uitlegArbeidsomstandighedenArtikel411),
      jsonHelpers.toParagraaf(`${key}.uitlegArbeidsomstandighedenArtikel412`, json.uitlegArbeidsomstandighedenArtikel412),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageVerplichtParagraaf`, json.bijlageVerplichtParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden),
      jsonHelpers.toTitel(`${key}.overigeOpmerkingenTitel`, json.overigeOpmerkingenTitel),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijving`, json.omschrijving)
    );
  }
}
