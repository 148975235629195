import { withVraag } from "components/formulier/with-vraag";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";

const hiddenComponent = (_props: { vraag: Vraag } & { readOnly: boolean }) => <></>;

export class Berekend {
  static getType = (): VraagType => VraagType.berekend;

  static create = (vraag: Vraag, readOnly = false): React.JSX.Element =>
    withVraag(hiddenComponent)({ vraag: vraag, readOnly: readOnly, placeholder: "", useVertaling: false });
}
