import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class SloopmeldingAntwoorden extends AntwoordBlok {
  constructor(
    public sloopmeldingsplicht: Antwoord<string>,
    public sloopmeldingBijBevoegdGezag: Antwoord<string>,
    public datumSloopmelding: Antwoord<string>,
    public codeSloopmelding: Antwoord<string>
  ) {
    super();
  }

  static initialize(): SloopmeldingAntwoorden {
    return new SloopmeldingAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): SloopmeldingAntwoorden {
    return new SloopmeldingAntwoorden(
      Antwoord.fromJson(json.sloopmeldingsplicht),
      Antwoord.fromJson(json.sloopmeldingBijBevoegdGezag),
      Antwoord.fromJson(json.datumSloopmelding),
      Antwoord.fromJson(json.codeSloopmelding)
    );
  }
}
