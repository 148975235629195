import { Tekst } from "../../../../models/api";
import { TekstType } from "../../../../models/application";
import { SamenvattingTitle } from "../../../samenvatting";

export class Titel {
  static getType = (): TekstType => TekstType.titel;

  static create = (tekst: Tekst): React.JSX.Element =>
    tekst.isHiddenFromSamenvatting ? <></> : <SamenvattingTitle item={tekst} />;
}
