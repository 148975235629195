import { connect } from "react-redux";
import { EMPTY_VRAAG_ANTWOORDBLOKKEN } from "reducers/collections-reducer";
import { resetFocusOnFirstVraag, resetSelectedCollectionItem } from "../../../../../../actions";
import { State } from "../../../../../../store";
import { CollectionTableRow } from "./collection-table-row.component";
import {
  CollectionTableRowDispatchProps,
  CollectionTableRowOwnProps,
  CollectionTableRowStateProps
} from "./collection-table-row.component.interfaces";

const mapStateToProps = (state: State, props: CollectionTableRowOwnProps): CollectionTableRowStateProps => ({
  selectedCollectionItemKey: state.collections.list[props.collectionKey]?.selectedItemKey ?? EMPTY_VRAAG_ANTWOORDBLOKKEN,
  focusOnFirstVraag:
    (state.collections.list[props.collectionKey]?.focusOnFirstVraag &&
      state.proces.activeItem?.hasValidationErrors !== true) ??
    false
});

const mapDispatchToProps = (dispatch: any, props: CollectionTableRowOwnProps): CollectionTableRowDispatchProps => ({
  resetSelectedCollectionItem: () => {
    dispatch(resetSelectedCollectionItem(props.collectionKey));
  },
  onFirstVraagFocused: () => {
    dispatch(resetFocusOnFirstVraag(props.collectionKey));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTableRow);
