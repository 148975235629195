import { connect } from "react-redux";
import { setRequestedPage } from "../../actions/proces-actions";
import { Processtap } from "../../models/application";
import { State } from "../../store";
import { EditableWrapper } from "./editable-wrapper.component";
import { EditableDispatchProps, EditableOwnProps, EditableStateProps } from "./editable-wrapper.component.interfaces";

const mapStateToProps = (state: State, ownProps: EditableOwnProps): EditableStateProps => ({
  item: state.proces.items.find((i) => i.type === ownProps.type)
});

const mapDispatchToProps = (dispatch: any): EditableDispatchProps => ({
  onEdit: (item: Processtap) => dispatch(setRequestedPage(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditableWrapper);
