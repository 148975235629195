import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { MelderAntwoorden } from "./melder-antwoorden";

export class VerzoekTotGezamenlijkeInterventieMelderAntwoorden extends AntwoordBlok {
  constructor(public welkeInstantieDoetHetVerzoek: Antwoord<string>, public common: MelderAntwoorden) {
    super();
  }

  static initialize(user: User | null = null): VerzoekTotGezamenlijkeInterventieMelderAntwoorden {
    return new VerzoekTotGezamenlijkeInterventieMelderAntwoorden(
      new Antwoord<string>(""),
      MelderAntwoorden.initialize(user)
    );
  }

  static fromJson(json: any): VerzoekTotGezamenlijkeInterventieMelderAntwoorden {
    return new VerzoekTotGezamenlijkeInterventieMelderAntwoorden(
      Antwoord.fromJson<string>(json.welkeInstantieDoetHetVerzoek),
      MelderAntwoorden.fromJson(json.common)
    );
  }
}
