import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { AvvArtikel10AVerzoekPeriodeAntwoorden } from "./avv-artikel10a-verzoek-periode-antwoorden";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";

export class AvvArtikel10AVerzoekAntwoorden extends AntwoordBlok {
  constructor(
    public verzoekGedaanDoor: Antwoord<string>,
    public welkeCaoVanToepassing: Antwoord<string>,
    public redenAanvraag: Antwoord<string>,
    public perioden: AntwoordBlokCollection<AvvArtikel10AVerzoekPeriodeAntwoorden> | null,
    public welkeOnderneming: Antwoord<string>,
    public kvkgegevens: BedrijfsgegevensAntwoorden | null
  ) {
    super();
  }

  static initialize(): AvvArtikel10AVerzoekAntwoorden {
    return new AvvArtikel10AVerzoekAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      AntwoordBlokCollection.initialize([]),
      new Antwoord<string>(""),
      BedrijfsgegevensAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AvvArtikel10AVerzoekAntwoorden {
    return json
      ? new AvvArtikel10AVerzoekAntwoorden(
          Antwoord.fromJson<string>(json.verzoekGedaanDoor),
          Antwoord.fromJson<string>(json.welkeCaoVanToepassing),
          Antwoord.fromJson<string>(json.redenAanvraag),
          AntwoordBlokCollection.fromJson(AvvArtikel10AVerzoekPeriodeAntwoorden.fromJson, json.perioden),
          Antwoord.fromJson<string>(json.welkeOnderneming),
          BedrijfsgegevensAntwoorden.fromJson(json.kvkgegevens)
        )
      : this.initialize();
  }
}
