import i18n from "i18next";
import { MogelijkAntwoord } from "../models/api";
import { Language } from "./../models/application";

export const getLanguage = (): Language => (Language as any)[i18n.language];

const getWaardeFromMeerkeuzevraag = (mogelijkeAntwoorden: MogelijkAntwoord[], antwoord: string, language: string) => {
  const gevondenAntwoord = mogelijkeAntwoorden.find((ma) => ma.waarde === antwoord);
  return gevondenAntwoord?.tekst[language] ?? antwoord;
};

const getWaardesFromChecklistvraag = (mogelijkeAntwoorden: MogelijkAntwoord[], antwoorden: string[], language: string) => {
  if (antwoorden) {
    return antwoorden.map((a) => getWaardeFromMeerkeuzevraag(mogelijkeAntwoorden, a, language)).join(", ");
  }
  return "";
};

export const languageHelpers = {
  getWaardeFromMeerkeuzevraag,
  getWaardesFromChecklistvraag
};
