import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldLandvraag } from "../../../samenvatting";

export class Buitenland {
  static getType = (): VraagType => VraagType.buitenland;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldLandvraag vraag={vraag} />;
}
