import {
  ADD_FILES,
  BestandenActions,
  CLEAR_FILES_ERROR,
  DELETE_FILES,
  NavigationActions,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SET_FILES,
  SET_FILES_ERROR,
  SET_FILE_CONFLICTS,
  SharedActions
} from "../actions";
import { MeldingFile, MeldingFileState } from "../models/application";
import { BestandenState } from "../store/bestanden-state";

const initialState: BestandenState = {
  files: [],
  conflictingFiles: [],
  fileErrors: null
};

const bestandenBlokReducer = (
  state: BestandenState = initialState,
  action: BestandenActions | SharedActions | NavigationActions
): BestandenState => {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM:
      return initialState;
    case ADD_FILES: {
      const newBestanden = [
        ...state.files,
        ...action.data.map((f) => new MeldingFile(MeldingFileState.Added, f.name, f.size, f, null))
      ];
      return { ...state, files: newBestanden };
    }
    case DELETE_FILES: {
      const newBestanden = state.files.filter(
        (f) => !action.data.some((fileToDelete) => fileToDelete.name === f.name && fileToDelete.size === f.size)
      );
      return { ...state, files: newBestanden };
    }
    case SET_FILES: {
      return {
        ...state,
        files: action.data
      };
    }
    case SET_FILE_CONFLICTS: {
      return {
        ...state,
        conflictingFiles: action.conflictingFiles
      };
    }
    case SET_FILES_ERROR: {
      return {
        ...state,
        fileErrors: action.data
      };
    }
    case CLEAR_FILES_ERROR: {
      return {
        ...state,
        fileErrors: null
      };
    }
    default:
      return state;
  }
};

export default bestandenBlokReducer;
