import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldGebrokenAantalvraag } from "../../../samenvatting";

export class GebrokenAantal {
  static getType = (): VraagType => VraagType.gebrokenAantal;

  static create = (vraag: Vraag): React.JSX.Element => (
    <SamenvattingFieldGebrokenAantalvraag vraag={vraag} fractionDigits={1} />
  );
}
