import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class BedrijfslocatiegegevensAntwoorden extends AntwoordBlok {
  constructor(
    public kvkNummerGebruiken: Antwoord<string>,
    public bedrijfsgegevens: BedrijfsgegevensAntwoorden | null,
    public bedrijfsnaam: Antwoord<string>,
    public adresBedrijf: CorrespondentieadresAntwoorden | null,
    public telefoonnummer: Antwoord<string>,
    public emailadres: Antwoord<string>,
    public website: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): BedrijfslocatiegegevensAntwoorden {
    return new BedrijfslocatiegegevensAntwoorden(
      new Antwoord<string>(""),
      BedrijfsgegevensAntwoorden.initialize(user),
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): BedrijfslocatiegegevensAntwoorden {
    return new BedrijfslocatiegegevensAntwoorden(
      Antwoord.fromJson<string>(json.kvkNummerGebruiken),
      BedrijfsgegevensAntwoorden.fromJson(json.bedrijfsgegevens),
      Antwoord.fromJson<string>(json.bedrijfsnaam),
      CorrespondentieadresAntwoorden.fromJson(json.adresBedrijf),
      Antwoord.fromJson<string>(json.telefoonnummer),
      Antwoord.fromJson<string>(json.emailadres),
      Antwoord.fromJson<string>(json.website)
    );
  }

  static tryGetFromJson(json: any): BedrijfslocatiegegevensAntwoorden | null {
    return json ? this.fromJson(json) : null;
  }
}
