import { connect } from "react-redux";
import { setMeldingenFilter } from "../../../actions/meldingen-actions";
import { ColumnSortOption, State } from "../../../store";
import { getMijnMeldingen } from "../../../thunks/meldingen-thunks";
import { MijnMeldingen } from "./mijn-meldingen.component";
import { MijnMeldingenDispatchProps, MijnMeldingenStateProps } from "./mijn-meldingen.component.interfaces";

const mapStateToProps = (state: State): MijnMeldingenStateProps => ({
  meldingen: state.meldingen.meldingen,
  totaalAantalMeldingen: state.meldingen.totaalAantalMeldingen,
  isLoading: state.meldingen.isLoading,
  hasApiError: state.meldingen.hasApiError,
  sortOption: state.meldingen.sortOption,
  pageNumber: state.meldingen.pageNumber,
  pageSize: state.meldingen.pageSize,
  isUserLoaded: state.security.userLoaded
});

const mapDispatchToProps = (dispatch: any): MijnMeldingenDispatchProps => ({
  onLoad: (sortOption: ColumnSortOption, pageNumber: number) => {
    dispatch(getMijnMeldingen(sortOption, pageNumber));
  },
  onSearchOptionChange: (sortOption: ColumnSortOption, pageNumber: number) => {
    dispatch(setMeldingenFilter(sortOption, pageNumber));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MijnMeldingen);
