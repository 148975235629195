import { initializeCollectionState, setCollectionVestigingenKeys } from "actions";
import { commitBlok } from "../../actions/blok";
import { initializeVestigingenSearchesState, setSelectedVestiging } from "../../actions/blok/vestigingen-actions";
import { objectHelpers } from "../../helpers/object-helpers";
import {
  AndereWerkgeverVragen,
  AntwoordBlok,
  AntwoordBlokCollection,
  AsbestinventarisatieMateriaalVragen,
  AvvArtikel10AVerzoekPeriodeVragen,
  Formulier,
  GegevensIllegaleWerknemerVragen,
  GegevensInstallatieVragen,
  GetuigenInformatieVragen,
  KindGegevensVragen,
  LocatieWerkzaamhedenVragen,
  OpdrachtgeverVragen,
  PeriodeMeldingAvvArtikel10Vragen,
  SlachtofferInformatieVragen,
  VraagBlok,
  VraagBlokCollection,
  WerkmomentGegevensVragen
} from "../../models/api";
import { Formuliertype, ProcesstapType } from "../../models/application";
import { AppThunk } from "../app-thunk-type";
import { BedrijfsgegevensAntwoorden } from "./../../models/api/blok/bedrijfsgegevens-antwoorden";

export interface VestigingProcessStapgegevens {
  bedrijfsgegevens?: BedrijfsgegevensAntwoorden | null;
  processtapType: ProcesstapType;
  key: string;
}

const getVestigingenFromCollection = <TVraagBlok extends VraagBlok, TAntwoordBlok extends AntwoordBlok>(
  vraagBlokCollection: VraagBlokCollection<TVraagBlok>,
  antwoordBlokCollection: AntwoordBlokCollection<TAntwoordBlok> | null,
  bedrijfsgegevensKey: string,
  processtap: ProcesstapType
): VestigingProcessStapgegevens[] => {
  const vestigingen: Array<any> = [];
  if (antwoordBlokCollection) {
    antwoordBlokCollection.list.forEach((item, index) => {
      const formulierVraagBlok = objectHelpers.getValue<any>(vraagBlokCollection.list[index], bedrijfsgegevensKey);
      if (formulierVraagBlok) {
        vestigingen.push({
          bedrijfsgegevens: objectHelpers.getValue<any>(item, bedrijfsgegevensKey),
          processtapType: processtap,
          key: formulierVraagBlok.key
        });
      }
    });
  }

  return vestigingen;
};

const updateAntwoordVraagCollections = <TVraagBlok extends VraagBlok, TAntwoordBlok extends AntwoordBlok>(
  vraagBlokCollection: VraagBlokCollection<TVraagBlok>,
  antwoordBlokCollection: AntwoordBlokCollection<TAntwoordBlok> | null,
  fromJson: (key: string, json: any, volgnummer?: number) => TVraagBlok,
  dispatch: any
) => {
  if (antwoordBlokCollection) {
    antwoordBlokCollection.list.forEach((_item, index) => {
      vraagBlokCollection.list.push(
        fromJson(`${vraagBlokCollection.key}.list.${index}`, vraagBlokCollection.template, index)
      );
    });

    dispatch(initializeCollectionState(vraagBlokCollection.key));
  }
};

export const setAntwoordVraagCollectionsState =
  <TVraagBlok extends VraagBlok, TAntwoordBlok extends AntwoordBlok>(
    vraagBlokCollection: VraagBlokCollection<TVraagBlok>,
    antwoordBlokCollection: AntwoordBlokCollection<TAntwoordBlok>,
    fromJson: (key: string, json: any, volgnummer?: number) => TVraagBlok
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    vraagBlokCollection.list.splice(0);
    updateAntwoordVraagCollections(vraagBlokCollection, antwoordBlokCollection, fromJson, dispatch);
  };

const initializeVestiging =
  (vestiging: VestigingProcessStapgegevens): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(initializeVestigingenSearchesState(vestiging.key));

    if (vestiging.bedrijfsgegevens?.vestiging?.waarde) {
      dispatch(setSelectedVestiging(vestiging.key, vestiging.bedrijfsgegevens.vestiging.waarde));
    }
    dispatch(commitBlok(vestiging.processtapType));
  };

export const initializeVestigingenForFormulier =
  (formulier: Formulier, blokken: any): AppThunk =>
  async (dispatch): Promise<void> => {
    let vestigingen: Array<VestigingProcessStapgegevens> = determineVestigingen(blokken, formulier);

    switch (formulier.type) {
      case Formuliertype.avvartikel10:
        updateAntwoordVraagCollections(
          formulier.blokken.melding.periode,
          blokken.melding.periode,
          PeriodeMeldingAvvArtikel10Vragen.fromJson,
          dispatch
        );
        break;

      case Formuliertype.avvArtikel10AVerzoek:
        updateAntwoordVraagCollections(
          formulier.blokken.verzoek.perioden,
          blokken.verzoek.perioden,
          AvvArtikel10AVerzoekPeriodeVragen.fromJson,
          dispatch
        );
        break;

      case Formuliertype.bouwproces:
        updateAntwoordVraagCollections(
          formulier.blokken.aannemers.aannemers,
          blokken.aannemers.aannemers,
          OpdrachtgeverVragen.fromJson,
          dispatch
        );

        dispatch(setCollectionVestigingenKeys(formulier.blokken.aannemers.aannemers.key, ["kvkgegevens"]));

        // Add vestiging for each aannemer
        vestigingen = [
          ...vestigingen,
          ...getVestigingenFromCollection(
            formulier.blokken.aannemers.aannemers,
            blokken.aannemers.aannemers,
            "kvkgegevens",
            ProcesstapType.aannemers
          )
        ];
        break;

      case Formuliertype.ongeval:
        updateAntwoordVraagCollections(
          formulier.blokken.getuigen.getuigenInformatieLijst,
          blokken.getuigen.getuigenInformatieLijst,
          GetuigenInformatieVragen.fromJson,
          dispatch
        );

        updateAntwoordVraagCollections(
          formulier.blokken.slachtoffers.slachtofferInformatieLijst,
          blokken.slachtoffers.slachtofferInformatieLijst,
          SlachtofferInformatieVragen.fromJson,
          dispatch
        );
        break;

      case Formuliertype.klachten:
        updateAntwoordVraagCollections(
          formulier.blokken.melding.illegaleTewerkstelling.gegevensIllegaleWerknemerLijst,
          blokken.melding.illegaleTewerkstelling?.gegevensIllegaleWerknemerLijst ?? AntwoordBlokCollection.initialize([]),
          GegevensIllegaleWerknemerVragen.fromJson,
          dispatch
        );
        break;

      case Formuliertype.ontheffingNachtarbeid:
        updateAntwoordVraagCollections(
          formulier.blokken.werkgever.andereWerkgevers,
          blokken.werkgever.andereWerkgevers,
          AndereWerkgeverVragen.fromJson,
          dispatch
        );

        dispatch(
          setCollectionVestigingenKeys(formulier.blokken.werkgever.andereWerkgevers.key, [
            "andereWerkgever.bedrijfsgegevens"
          ])
        );

        // Add vestiging for each andere werkgever
        vestigingen = [
          ...vestigingen,
          ...getVestigingenFromCollection(
            formulier.blokken.werkgever.andereWerkgevers,
            blokken.werkgever.andereWerkgevers,
            "andereWerkgever.bedrijfsgegevens",
            ProcesstapType.werkgever
          )
        ];
        break;

      case Formuliertype.ontheffingKinderarbeid:
        updateAntwoordVraagCollections(
          formulier.blokken.locaties.locaties,
          blokken.locaties.locaties,
          LocatieWerkzaamhedenVragen.fromJson,
          dispatch
        );
        updateAntwoordVraagCollections(
          formulier.blokken.kinderen.kinderenGegevensLijst,
          blokken.kinderen.kinderenGegevensLijst,
          KindGegevensVragen.fromJson,
          dispatch
        );
        updateAntwoordVraagCollections(
          formulier.blokken.werkmomenten.werkmomentenGegevensLijst,
          blokken.werkmomenten.werkmomentenGegevensLijst,
          WerkmomentGegevensVragen.fromJson,
          dispatch
        );
        break;

      case Formuliertype.asbestverwijdering:
        updateAntwoordVraagCollections(
          formulier.blokken.inventarisatie.materiaal,
          blokken.inventarisatie.materiaal,
          AsbestinventarisatieMateriaalVragen.fromJson,
          dispatch
        );
        break;

      case Formuliertype.arie:
        updateAntwoordVraagCollections(
          formulier.blokken.locatie.gegevensInstallaties,
          blokken.locatie.gegevensInstallaties,
          GegevensInstallatieVragen.fromJson,
          dispatch
        );
        break;

      case Formuliertype.bibob:
        updateAntwoordVraagCollections(
          formulier.blokken.verzoek.vestigingen,
          blokken.verzoek.vestigingen,
          OpdrachtgeverVragen.fromJson,
          dispatch
        );

        dispatch(setCollectionVestigingenKeys(formulier.blokken.verzoek.vestigingen.key, ["kvkgegevens"]));

        // Add vestiging for each vestiging
        vestigingen = [
          ...vestigingen,
          ...getVestigingenFromCollection(
            formulier.blokken.verzoek.vestigingen,
            blokken.verzoek.vestigingen,
            "kvkgegevens",
            ProcesstapType.verzoek
          )
        ];
        break;
    }

    vestigingen.forEach((v) => {
      dispatch(initializeVestiging(v));
    });
  };

function determineVestigingen(blokken: any, formulier: Formulier) {
  const vestigingen: Array<VestigingProcessStapgegevens> = [];
  for (const propertyPath of getPropertyPaths(
    blokken,
    undefined,
    "bedrijfsgegevens",
    "kvkgegevens",
    "werklocatieBedrijfsgegevens"
  )) {
    const formulierVraagblok = objectHelpers.getValue<any>(formulier.blokken, propertyPath);
    if (formulierVraagblok) {
      const newVestiging: VestigingProcessStapgegevens = {
        bedrijfsgegevens: objectHelpers.getValue<any>(blokken, propertyPath),
        processtapType: propertyPath.substring(0, propertyPath.indexOf(".")) as ProcesstapType,
        key: formulierVraagblok.key
      };
      vestigingen.push(newVestiging);
    }
  }
  return vestigingen;
}

const getPropertyPaths = (someObject: any, currentKey = "", ...keys: string[]): string[] => {
  const propertyPaths = [];
  const currentKeyString = !!currentKey ? `${currentKey}.` : "";
  for (const prop in someObject) {
    if (someObject[prop] instanceof Object) {
      for (const key of keys) {
        if (key in someObject[prop.toString()]) {
          propertyPaths.push(`${currentKeyString}${prop}.${key}`);
        }
      }
      const newCurrentKey = `${currentKeyString}${prop}`;
      propertyPaths.push(...getPropertyPaths(someObject[prop.toString()], newCurrentKey, ...keys));
    }
  }
  return propertyPaths;
};
