import { AntwoordBlokken, ArieLocatieAntwoorden, ArieMeldingAntwoorden, BijlageAntwoorden, MelderAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasBijlageAntwoorden } from "./has-bijlage-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";

export class ArieMeldingAntwoordBlokken extends AntwoordBlokken implements HasMelderAntwoorden, HasBijlageAntwoorden {
  constructor(
    public melding: ArieMeldingAntwoorden,
    public melder: MelderAntwoorden,
    public locatie: ArieLocatieAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): ArieMeldingAntwoordBlokken {
    return new ArieMeldingAntwoordBlokken(
      ArieMeldingAntwoorden.initialize(),
      MelderAntwoorden.initialize(user),
      ArieLocatieAntwoorden.initialize(),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): ArieMeldingAntwoordBlokken {
    return new ArieMeldingAntwoordBlokken(
      ArieMeldingAntwoorden.fromJson(json.melding),
      MelderAntwoorden.fromJson(json.melder),
      ArieLocatieAntwoorden.fromJson(json.locatie),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
