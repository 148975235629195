import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { BinaryExpression } from "./binary-expression";
import { expressionParser } from "./expression-parser";

export class Or extends BinaryExpression {
  get operator(): string {
    return Or.operator();
  }

  execute = (vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean =>
    this.left.execute(vraagBlok, context, vraagProperty) || this.right.execute(vraagBlok, context, vraagProperty);

  static fromJson = (json: any): Or => {
    const leftExpression = expressionParser.fromJson(json.left);
    const rightExpression = expressionParser.fromJson(json.right);

    if (!leftExpression) {
      throw Error("Left expression cannot be null.");
    }

    if (!rightExpression) {
      throw Error("Right expression cannot be null.");
    }

    return new Or(leftExpression, rightExpression);
  };

  static operator = (): string => "or";

  static matches = (json: any): boolean => json?.operator === Or.operator();
}
