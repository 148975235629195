export class MeldingFile {
  constructor(
    public state: MeldingFileState,
    public name: string,
    public size: number,
    public file: File | null,
    public id: string | null
  ) {}
}

export enum MeldingFileState {
  Untouched,
  Added
}
