import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { AvvArtikel10AVerzoekFormulierVraagBlokken } from "./avv-artikel10a-verzoek-formulier-vraag-blokken";

export class AvvArtikel10AVerzoekFormulier extends FormulierBase {
  constructor(
    public blokken: AvvArtikel10AVerzoekFormulierVraagBlokken,
    json: any
  ) {
    super(Formuliertype.avvArtikel10AVerzoek, blokken, json);
  }

  static fromJson(json: any): AvvArtikel10AVerzoekFormulier {
    return new AvvArtikel10AVerzoekFormulier(AvvArtikel10AVerzoekFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
