import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class AsbestinventarisatieMateriaalVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public risicoklasse: Meerkeuzevraag,
    public hoeveelheid: Vraag,
    public eenheid: Meerkeuzevraag,
    public soortMateriaal: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any, volgnummer?: number): AsbestinventarisatieMateriaalVragen {
    return new AsbestinventarisatieMateriaalVragen(
      key,
      volgnummer ?? json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.risicoklasse`, json.risicoklasse),
      jsonHelpers.toGebrokenAantalVraag(`${key}.hoeveelheid`, json.hoeveelheid),
      jsonHelpers.toGelinkteMeerkeuzeVraag(`${key}.eenheid`, json.eenheid),
      jsonHelpers.toGelinkteMeerkeuzeVraag(`${key}.soortMateriaal`, json.soortMateriaal)
    );
  }
}
