import {
  AsbestWerkzaamhedenVragen,
  InventarisatieVragen,
  LocatieMetToelichtingVragen,
  MelderVragen,
  OpdrachtgeverAsbestVragen,
  PeriodeMetRedenVragen,
  VraagBlokken
} from "../blok";
import { HasInventarisatieVragen } from "./has-inventarisatie-vragen";
import { HasLocatieMetToelichtingVragen } from "./has-locatie-met-toelichting-vragen";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasOpdrachtgeverAsbestVragen } from "./has-opdrachtgever-asbest-vragen";
import { HasPeriodeMetRedenVragen } from "./has-periode-met-reden-vragen";
import { HasWerkzaamhedenVragen } from "./has-werkzaamheden-vragen";

export class AsbestRisicoklasse1FormulierVraagBlokken
  extends VraagBlokken
  implements
    HasMelderVragen,
    HasOpdrachtgeverAsbestVragen,
    HasInventarisatieVragen,
    HasLocatieMetToelichtingVragen,
    HasPeriodeMetRedenVragen,
    HasWerkzaamhedenVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public opdrachtgever: OpdrachtgeverAsbestVragen,
    public inventarisatie: InventarisatieVragen,
    public locatie: LocatieMetToelichtingVragen,
    public periode: PeriodeMetRedenVragen,
    public werkzaamheden: AsbestWerkzaamhedenVragen
  ) {
    super();
  }

  static fromJson(json: any): AsbestRisicoklasse1FormulierVraagBlokken {
    return new AsbestRisicoklasse1FormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      OpdrachtgeverAsbestVragen.fromJson("opdrachtgever", json.opdrachtgever),
      InventarisatieVragen.fromJson("inventarisatie", json.inventarisatie),
      LocatieMetToelichtingVragen.fromJson("locatie", json.locatie),
      PeriodeMetRedenVragen.fromJson("periode", json.periode),
      AsbestWerkzaamhedenVragen.fromJson("werkzaamheden", json.werkzaamheden)
    );
  }
}
