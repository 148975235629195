import { jsonHelpers } from "../../../helpers";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { AdresVragen } from "./adres-vragen";

export class BuitenlandsAdresVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public adres: AdresVragen,
    public land: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly);
  }

  static fromJson(key: string, json: any): BuitenlandsAdresVragen {
    return new BuitenlandsAdresVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      AdresVragen.fromJson(`${key}.adres`, json.adres),
      jsonHelpers.toBuitenlandVraag(`${key}.land`, json.land)
    );
  }
}
