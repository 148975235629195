import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensLavsVragen } from "./bedrijfsgegevens-lavs-vragen";

export class CertificerendeInstellingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public bedrijfsgegevens: BedrijfsgegevensLavsVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): CertificerendeInstellingVragen {
    return new CertificerendeInstellingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      BedrijfsgegevensLavsVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens)
    );
  }
}
