import { BijlageVragen, MelderVragen, OntheffingVuurOpTankschipAanvraagVragen, VraagBlokken } from "../blok";
import { HasAanvragerVragen } from "./has-aanvrager-vragen";
import { HasBijlageVragen } from "./has-bijlage-vragen";
import { HasOntheffingVuurOpTankschipAanvraagVragen } from "./has-ontheffing-vuur-op-tankschip-aanvraag-vragen";

export class OntheffingVuurOpTankschipFormulierVraagBlokken
  extends VraagBlokken
  implements HasOntheffingVuurOpTankschipAanvraagVragen, HasAanvragerVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(
    public aanvraag: OntheffingVuurOpTankschipAanvraagVragen,
    public aanvrager: MelderVragen,
    public bijlage: BijlageVragen
  ) {
    super();
  }

  static fromJson(json: any): OntheffingVuurOpTankschipFormulierVraagBlokken {
    return new OntheffingVuurOpTankschipFormulierVraagBlokken(
      OntheffingVuurOpTankschipAanvraagVragen.fromJson("aanvraag", json.aanvraag),
      MelderVragen.fromJson("aanvrager", json.aanvrager),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
