import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class GegevensIllegaleWerknemerVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public naam: Vraag,
    public beschrijving: Vraag,
    public land: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): GegevensIllegaleWerknemerVragen {
    return new GegevensIllegaleWerknemerVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.naam`, json.naam),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.beschrijving`, json.beschrijving),
      jsonHelpers.toLandVraag(`${key}.land`, json.land)
    );
  }
}
