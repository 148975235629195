import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";

export class AangeverAntwoorden extends AntwoordBlok {
  constructor(
    public aangeverLocatie: BedrijfslocatiegegevensAntwoorden,
    public contactpersoon: ContactpersoonAntwoorden | null
  ) {
    super();
  }

  static initialize(): AangeverAntwoorden {
    return new AangeverAntwoorden(BedrijfslocatiegegevensAntwoorden.initialize(), ContactpersoonAntwoorden.initialize());
  }

  static fromJson(json: any): AangeverAntwoorden {
    return new AangeverAntwoorden(
      BedrijfslocatiegegevensAntwoorden.fromJson(json.aangeverLocatie),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon)
    );
  }
}
