import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class DigipassVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public controlenummer: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly);
  }

  static fromJson(key: string, json: any): DigipassVragen {
    return new DigipassVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.controlenummer`, json.controlenummer)
    );
  }
}
