import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { meldingApi } from "api";
import { Formuliertype } from "models/application";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { VertaalOpdrachtButtonProps } from "./vertaalopdracht-button.component.interfaces";

export const VertaalOpdrachtButton = (props: VertaalOpdrachtButtonProps) => {
  const { t } = useTranslation();

  const getVertaalopdrachtDownloadUrl = (formuliertype: Formuliertype, meldingId: string) => {
    return meldingApi.getDownloadVertaalopdrachtUrl(formuliertype, meldingId);
  };

  return (
    <Button
      id="vertaalOpdrachtButton"
      className="actie-button"
      as="a"
      href={getVertaalopdrachtDownloadUrl(props.formuliertype, props.meldingId)}
      role="button"
      download
    >
      <FontAwesomeIcon icon={faLanguage} /> {t(translationKeys.melding.mijnMeldingen.vertaalopdracht)}
    </Button>
  );
};
