import { UploadVragen, VraagBlokken } from "../blok";
import { HasUploadVragen } from "./has-upload-vragen";

export class UploadGeneriekFormulierVraagBlokken extends VraagBlokken implements HasUploadVragen {
  [key: string]: any;

  constructor(public upload: UploadVragen) {
    super();
  }

  static fromJson(json: any): UploadGeneriekFormulierVraagBlokken {
    return new UploadGeneriekFormulierVraagBlokken(UploadVragen.fromJson("upload", json.upload));
  }
}
