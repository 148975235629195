import { connect } from "react-redux";
import { setRequestedPage } from "../../../actions/proces-actions";
import { State } from "./../../../store";
import { FormulierProcesBalk } from "./formulier-proces-balk.component";
import {
  FormulierProcesBalkDispatchProps,
  FormulierProcesBalkStateProps
} from "./formulier-proces-balk.component.interfaces";

const mapStateToProps = (state: State): FormulierProcesBalkStateProps => ({
  items: state.proces.items,
  isSubmitting: state.antwoorden.isSubmitting,
  isNavigating: state.proces.requestedItem !== null
});

const mapDispatchToProps = (dispatch: any): FormulierProcesBalkDispatchProps => ({
  onSetRequestedPage: (item) => {
    dispatch(setRequestedPage(item));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FormulierProcesBalk);
