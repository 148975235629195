import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldDatumTijdvraag } from "../../../samenvatting";

export class DatumTijd {
  static getType = (): VraagType => VraagType.datumTijd;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldDatumTijdvraag vraag={vraag} />;
}
