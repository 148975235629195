import { push } from "redux-first-history";
import {
  confirmNavigation as confirmNavigationAction,
  requestActionNavigation as requestActionNavigationAction,
  requestUrlNavigation as requestUrlNavigationAction
} from "../actions";
import { AppThunk } from "./app-thunk-type";

export const requestActionNavigation =
  (action: () => void): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const navigationState = getState().navigation;

    if (navigationState.confirmNavigationRequired && navigationState.isDirty) {
      dispatch(requestActionNavigationAction(action));
    } else {
      action();
    }
  };

export const requestUrlNavigation =
  (url: string, skipRouter: boolean = false): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const navigationState = getState().navigation;

    if (navigationState.confirmNavigationRequired && navigationState.isDirty) {
      dispatch(requestUrlNavigationAction(url, skipRouter));
    } else {
      dispatch(executeUrlNavigation(url, skipRouter));
    }
  };

export const confirmNavigation =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const navigationState = getState().navigation;

    const url = navigationState.requestedUrl;
    const skipRouter = navigationState.skipRouterForUrl;
    const action = navigationState.requestedAction;

    dispatch(confirmNavigationAction());

    if (url) {
      dispatch(executeUrlNavigation(url, skipRouter));
    } else if (action) {
      action();
    }
  };

export const executeUrlNavigation =
  (url: string, skipRouter: boolean = false): AppThunk =>
  async (dispatch): Promise<void> => {
    if (url.startsWith("/") && !skipRouter) {
      dispatch(push(url));
    } else {
      window.location.href = url;
    }
  };

export const showNietGevonden =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(push("/nietgevonden"));
  };

export const showInOnderhoud =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(push("/onderhoud"));
  };
