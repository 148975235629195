import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class OntheffingLiftenAanvraagAntwoorden extends AntwoordBlok {
  constructor(
    public waaromGeenAnderePlaatsLift: Antwoord<string>,
    public watNodigVoorSchacht: Antwoord<string>,
    public redenNietUitvoerbaar: Antwoord<string>,
    public overigeOpmerkingen: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): OntheffingLiftenAanvraagAntwoorden {
    return new OntheffingLiftenAanvraagAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): OntheffingLiftenAanvraagAntwoorden {
    return new OntheffingLiftenAanvraagAntwoorden(
      Antwoord.fromJson<string>(json.waaromGeenAnderePlaatsLift),
      Antwoord.fromJson<string>(json.watNodigVoorSchacht),
      Antwoord.fromJson<string>(json.redenNietUitvoerbaar),
      Antwoord.fromJson<string>(json.overigeOpmerkingen),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson)
    );
  }
}
