import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../constants/translation-keys";
import { VisibleWrapper } from "../visible-wrapper";
import { UserMenuContext } from "./user-menu-context";
import { UserMenuToggleStateProps } from "./user-menu-toggle.component.interfaces";

const ToggleIcon = (props: { title: string; onClick: () => void; "data-test": string }) => (
  <button
    data-test={props["data-test"]}
    type="button"
    aria-label={props.title}
    className="toggle"
    onClick={(e) => {
      props.onClick();
      e.stopPropagation();
    }}
  >
    <FontAwesomeIcon icon={faBars} size="2x" className="toggle" title={props.title} />
  </button>
);

const UserMenuToggle: React.FC<any> = (props: UserMenuToggleStateProps) => {
  const context = React.useContext(UserMenuContext);

  const { t } = useTranslation();

  const title = context.isVisible ? t(translationKeys.gebruikersmenu.verbergen) : t(translationKeys.gebruikersmenu.tonen);

  const toggleMenu = () => {
    context.toggleIsVisible();
  };

  return (
    <VisibleWrapper key="user-menu-toggle" isVisible={props.isVisible}>
      <ToggleIcon title={title} onClick={toggleMenu} data-test="gebruikersmenu-toggle" />
    </VisibleWrapper>
  );
};

export { UserMenuToggle };
