import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class PeriodeAntwoorden extends AntwoordBlok {
  constructor(
    public begindatum: Antwoord<string>,
    public begintijd: Antwoord<string>,
    public einddatum: Antwoord<string>,
    public eindtijd: Antwoord<string>
  ) {
    super();
  }

  static initialize(): PeriodeAntwoorden {
    return new PeriodeAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }
}
