import { Antwoord } from "..";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { Bestand } from "./bestand";

export class KlachtAntwoorden extends AntwoordBlok {
  constructor(
    public onderwerpKlacht: Antwoord<string>,
    public referentieIszw: Antwoord<string>,
    public toelichting: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): KlachtAntwoorden {
    return new KlachtAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): KlachtAntwoorden {
    return new KlachtAntwoorden(
      Antwoord.fromJson<string>(json.onderwerpKlacht),
      Antwoord.fromJson<string>(json.referentieIszw),
      Antwoord.fromJson<string>(json.toelichting),
      Antwoord.fromJson<Bestand[]>({
        waarde: json.bestanden.waarde.map((b: any) => Bestand.fromJson(b))
      })
    );
  }
}
