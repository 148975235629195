import { Meerkeuzevraag, SamengesteldeVraagVraagBlokTemplate, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class TeVerwijderenAsbestMateriaalLavsV2Vragen extends VraagBlok implements SamengesteldeVraagVraagBlokTemplate {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public risicoklasse: Vraag,
    public risicoklasseInventariseerder: Vraag,
    public certificatieplichtig: Meerkeuzevraag,
    public werkmethodeAangepast: Meerkeuzevraag,
    public hoeveelheid: Vraag,
    public eenheid: Meerkeuzevraag,
    public soortMateriaal: Vraag,
    public broncode: Vraag,
    public brontype: Vraag,
    public binding: Vraag,
    public bevestigingsmethode: Vraag,
    public verwijderingsmethode: Vraag,
    public aanbevolenMaatregel: Vraag,
    public toelichting: Vraag,
    public hoeveelheidType: Vraag,
    public afmetingPerStuk: Vraag,
    public bouwkundigeEenheid: Vraag,
    public asbesthoudend: Vraag,
    public conditie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  createInstance(key: string): VraagBlok {
    return TeVerwijderenAsbestMateriaalLavsV2Vragen.fromJson(key, this);
  }

  static fromJson(key: string, json: any): TeVerwijderenAsbestMateriaalLavsV2Vragen {
    return new TeVerwijderenAsbestMateriaalLavsV2Vragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.risicoklasse`, json.risicoklasse),
      jsonHelpers.toTekstVraag(`${key}.risicoklasseInventariseerder`, json.risicoklasseInventariseerder),
      jsonHelpers.toMeerkeuzeVraag(`${key}.certificatieplichtig`, json.certificatieplichtig),
      jsonHelpers.toMeerkeuzeVraag(`${key}.werkmethodeAangepast`, json.werkmethodeAangepast),
      jsonHelpers.toTekstVraag(`${key}.hoeveelheid`, json.hoeveelheid),
      jsonHelpers.toMeerkeuzeVraag(`${key}.eenheid`, json.eenheid),
      jsonHelpers.toTekstVraag(`${key}.soortMateriaal`, json.soortMateriaal),
      jsonHelpers.toTekstVraag(`${key}.broncode`, json.broncode),
      jsonHelpers.toTekstVraag(`${key}.brontype`, json.brontype),
      jsonHelpers.toTekstVraag(`${key}.binding`, json.binding),
      jsonHelpers.toTekstVraag(`${key}.bevestigingsmethode`, json.bevestigingsmethode),
      jsonHelpers.toTekstVraag(`${key}.verwijderingsmethode`, json.verwijderingsmethode),
      jsonHelpers.toTekstVraag(`${key}.aanbevolenMaatregel`, json.aanbevolenMaatregel),
      jsonHelpers.toTekstVraag(`${key}.toelichting`, json.toelichting),
      jsonHelpers.toTekstVraag(`${key}.hoeveelheidType`, json.hoeveelheidType),
      jsonHelpers.toTekstVraag(`${key}.afmetingPerStuk`, json.afmetingPerStuk),
      jsonHelpers.toTekstVraag(`${key}.bouwkundigeEenheid`, json.bouwkundigeEenheid),
      jsonHelpers.toTekstVraag(`${key}.asbesthoudend`, json.asbesthoudend),
      jsonHelpers.toTekstVraag(`${key}.conditie`, json.conditie)
    );
  }
}
