import { VerzoekTotGezamenlijkeInterventieFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getVerzoekTotGezamenlijkeInterventieFormulierDefinitie = (
  formulier: VerzoekTotGezamenlijkeInterventieFormulier
): BlokDefinitie[] => [
  {
    title: formulier.blokken.melding.titel,
    type: ProcesstapType.melding
  },
  {
    title: formulier.blokken.locatie.titel,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.bijlage.titel,
    type: ProcesstapType.bijlage
  }
];
