import { MeldingFile, MeldingFileState } from "models/application";
import { BestandImportState } from "store/bestand-import-state";
import { BestandImportActions, CLEAR_IMPORT_FILE_STATE, SET_FILE, SET_IMPORT_FILE_RESULT } from "../actions";

const initialState: BestandImportState = {
  file: null,
  errors: [],
  result: null
};

const bestandImportBlokReducer = (
  state: BestandImportState = initialState,
  action: BestandImportActions
): BestandImportState => {
  switch (action.type) {
    case SET_FILE:
      return {
        ...state,
        file: new MeldingFile(MeldingFileState.Added, action.data.name, action.data.size, action.data, null)
      };
    case SET_IMPORT_FILE_RESULT:
      return {
        ...state,
        errors: action.errors,
        result: action.result
      };
    case CLEAR_IMPORT_FILE_STATE:
      return {
        ...state,
        errors: [],
        result: null,
        file: null
      };
    default:
      return state;
  }
};

export default bestandImportBlokReducer;
