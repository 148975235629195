import { connect } from "react-redux";
import { uitloggen } from "../../../thunks/security-thunks";
import { Logout } from "./logout.component";
import { LogoutDispatchProps } from "./logout.component.interfaces";

const mapDispatchToProps = (dispatch: any): LogoutDispatchProps => ({
  uitloggen: () => {
    dispatch(uitloggen());
  }
});

export default connect(null, mapDispatchToProps)(Logout);
