import { Antwoord, LocatieMetToelichtingAntwoorden } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class WerkzaamhedenOpAdresBedrijfLocatieAntwoorden extends AntwoordBlok {
  constructor(public werkzaamhedenLocatieBedrijf: Antwoord<string>, public locatie: LocatieMetToelichtingAntwoorden | null) {
    super();
  }

  static initialize(): WerkzaamhedenOpAdresBedrijfLocatieAntwoorden {
    return new WerkzaamhedenOpAdresBedrijfLocatieAntwoorden(
      new Antwoord<string>(""),
      LocatieMetToelichtingAntwoorden.initialize()
    );
  }

  static fromJson(json: any): WerkzaamhedenOpAdresBedrijfLocatieAntwoorden {
    return new WerkzaamhedenOpAdresBedrijfLocatieAntwoorden(
      Antwoord.fromJson<string>(json.werkzaamhedenLocatieBedrijf),
      LocatieMetToelichtingAntwoorden.fromJson(json.locatie)
    );
  }
}
