import { hideValidationErrorsDialog } from "actions";
import { AntwoordBlokken } from "models/api";
import { connect } from "react-redux";
import { onCancelNavigateOrSubmit, onNavigate } from "thunks";
import { State } from "../../../store/state";
import { NavigateWithValidationErrorsDialog } from "./navigate-with-validation-errors-dialog.component";
import {
  NavigateWithValidationErrorsDialogDispatchProps,
  NavigateWithValidationErrorsDialogStateProps
} from "./navigate-with-validation-errors-dialog.component.interfaces";

const mapStateToProps = (state: State): NavigateWithValidationErrorsDialogStateProps => ({
  isShown: state.proces.showValidationErrorsDialog
});

const mapDispatchToProps = (dispatch: any): NavigateWithValidationErrorsDialogDispatchProps => ({
  onNavigate: (antwoordBlokken: AntwoordBlokken) => {
    dispatch(onNavigate(antwoordBlokken));
  },
  onCancel: () => {
    dispatch(onCancelNavigateOrSubmit());
  },
  onBeforeClose: () => {
    dispatch(hideValidationErrorsDialog());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigateWithValidationErrorsDialog);
