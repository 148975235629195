import { jsonHelpers } from "../../../helpers";
import { VraagBlokType } from "../../application";
import { AdresVragenDisplayDefinition } from "../display-definition";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class AdresVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public postcode: Vraag,
    public huisnummer: Vraag,
    public toevoeging: Vraag,
    public straatnaam: Vraag,
    public plaats: Vraag,
    public type?: VraagBlokType
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, undefined, undefined, type);
  }

  static fromJson(key: string, json: any, displayDefinition?: AdresVragenDisplayDefinition): AdresVragen {
    return new AdresVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTekstVraag(`${key}.postcode`, json.postcode, displayDefinition?.postcode),
      jsonHelpers.toNumeriekeVraag(`${key}.huisnummer`, json.huisnummer),
      jsonHelpers.toTekstVraag(`${key}.toevoeging`, json.toevoeging),
      jsonHelpers.toTekstVraag(`${key}.straatnaam`, json.straatnaam),
      jsonHelpers.toTekstVraag(`${key}.plaats`, json.plaats, displayDefinition?.plaats),
      undefined
    );
  }
}
