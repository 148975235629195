import { MaintenanceBreadcrumbs } from "components/breadcrumbs";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export const Maintenance: React.FC<any> = () => {
  return (
    <Row className="main-container">
      <Col xs={12} className="main-container-content">
        <MaintenanceBreadcrumbs />
        <h1>Onderhoud</h1>
        <p>
          Op dit moment wordt er onderhoud gepleegd aan het Webportaal van de Nederlandse Arbeidsinspectie. Probeert u het
          later nog een keer!
        </p>
        <h2>Spoed?</h2>
        <p>
          Neem dan contact op met het Meldingen Informatiecentrum van de Nederlandse Arbeidsinspectie:
          <br />
          Bel 0800 - 5151 (optie 5) of mail naar{" "}
          <a href="mailto:contact@nlarbeidsinspectie.nl">contact@nlarbeidsinspectie.nl</a>.
        </p>
        <h2>Technische vragen?</h2>
        <p>
          Neem dan contact op met de Servicedesk Informatievoorziening van de Nederlandse Arbeidsinspectie:
          <br />
          Bel 0800 - 5151 (optie 4) of mail naar:{" "}
          <a href="mailto:servicedeskiv@nlarbeidsinspectie.nl">servicedeskiv@nlarbeidsinspectie.nl</a>.
        </p>
        <h1>Maintenance</h1>
        <p>The Webportal of the Netherlands Labour Authority is currently down for maintenance. Please try again later!</p>
        <h2>Do you have an emergency?</h2>
        <p>
          Please contact the Notification Information Centre of the Netherlands Labour Authority:
          <br />
          <br />
          Calling from within the Netherlands: 0800 - 5151 (option 5)
          <br />
          Calling from outside the Netherlands: +31 70 - 333 44 44
          <br />
          Email address: <a href="mailto:contact@nlarbeidsinspectie.nl">contact@nlarbeidsinspectie.nl</a>
        </p>
        <h2>Technical questions?</h2>
        <p>
          Please contact the Servicedesk Information Services of the Netherlands Labour Authority:
          <br />
          <br />
          Calling from within the Netherlands: 0800 - 5151 (option 4)
          <br />
          Calling from outside the Netherlands: +31 70 - 333 68 11
          <br />
          Email address: <a href="mailto:servicedeskiv@nlarbeidsinspectie.nl">servicedeskiv@nlarbeidsinspectie.nl</a>
        </p>
      </Col>
    </Row>
  );
};
