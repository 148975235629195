import { CbiFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getCbiFormulierDefinitie = (formulier: CbiFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.bijlage.titelVerkort,
    type: ProcesstapType.bijlage
  }
];
