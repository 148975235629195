import { AntwoordBlok } from "./antwoord-blok";
import { Antwoord } from "../antwoord";
import { Bestand } from "./bestand";

export class VerklaringenAntwoorden extends AntwoordBlok {
  constructor(
    public tankschipSituatie: Antwoord<string>,
    public plaatsaanduidingVonken: Antwoord<string>,
    public plaatsaanduidingTemperatuursverhoging: Antwoord<string>,
    public briefkenmerkEerdereOntheffing: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>,
    public omschrijving: Antwoord<string>
  ) {
    super();
  }

  static initialize(): VerklaringenAntwoorden {
    return new VerklaringenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([]),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): VerklaringenAntwoorden {
    return new VerklaringenAntwoorden(
      Antwoord.fromJson<string>(json.tankschipSituatie),
      Antwoord.fromJson<string>(json.plaatsaanduidingVonken),
      Antwoord.fromJson<string>(json.plaatsaanduidingTemperatuursverhoging),
      Antwoord.fromJson<string>(json.briefkenmerkEerdereOntheffing),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson),
      Antwoord.fromJson<string>(json.omschrijving)
    );
  }
}
