import { AntwoordBlok } from "./antwoord-blok";
import { LocatieAntwoorden } from "./locatie-antwoorden";

export class LocatieMetToelichtingAntwoorden extends AntwoordBlok {
  constructor(public locatie: LocatieAntwoorden | null) {
    super();
  }

  static initialize(): LocatieMetToelichtingAntwoorden {
    return new LocatieMetToelichtingAntwoorden(LocatieAntwoorden.initialize());
  }

  static fromJson(json: any): LocatieMetToelichtingAntwoorden {
    return new LocatieMetToelichtingAntwoorden(LocatieAntwoorden.fromJson(json.locatie));
  }
}
