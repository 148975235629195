import i18n from "i18next";
import { Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { objectHelpers } from "../../../../helpers";
import { AntwoordBlokken, SamengesteldeVraagVraagBlokTemplate, Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { State } from "../../../../store";
import { GeneriekSamenvattingBlok } from "../generiek-samenvatting-blok.component";

export class SamengesteldeVraagComponent {
  static getType = (): VraagType => VraagType.samengesteldeVraag;

  static create = (vraag: Vraag): React.JSX.Element => {
    const { antwoordBlokken } = useSelector<State, { antwoordBlokken: AntwoordBlokken }>(
      (state: State) => ({
        antwoordBlokken: state.antwoorden.blokken
      }),
      shallowEqual
    );

    const samengesteldeAntwoorden = objectHelpers.getValue<{ waarde: [] }>(antwoordBlokken, vraag.key);

    const vragenTemplate = (vraag as any).subVragen as SamengesteldeVraagVraagBlokTemplate;

    return (
      <Fragment>
        {vraag.tekst && <h3>{vraag.tekst[i18n.language]}</h3>}
        {samengesteldeAntwoorden &&
          samengesteldeAntwoorden.waarde.map((_, index) => {
            const actualBlok = vragenTemplate.createInstance(`${vraag.key}.waarde.${index}`);

            return (
              <div key={actualBlok.key}>
                <GeneriekSamenvattingBlok vragen={actualBlok} />
                <div>-</div>
              </div>
            );
          })}
      </Fragment>
    );
  };
}
