import { displayAntwoordHelpers } from "helpers";
import { AntwoordBlok, OpdrachtgeverAntwoorden, OpdrachtgeverVragen, VraagBlok, VraagBlokCollection } from "models/api";
import { shallowEqual, useSelector } from "react-redux";
import { State, VestigingenSearchesState } from "store";
import { Dictionary, VraagBlokCollectionType } from "../../../../models/application";
import { VraagBlokCollectionSamenvattingBase } from "./vraag-blok-collection-samenvatting-base";

export class Vestigingen {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.vestigingen;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => {
    const { vestigingSearches } = useSelector<State, { vestigingSearches: Dictionary<VestigingenSearchesState> }>(
      (state: State) => ({
        vestigingSearches: state.vestigingen.searches
      }),
      shallowEqual
    );

    return VraagBlokCollectionSamenvattingBase.create(vraagBlokCollection, (antwoordBlok: AntwoordBlok, index: number) =>
      displayAntwoordHelpers.getOpdrachtgeverDisplayAntwoord(
        antwoordBlok as OpdrachtgeverAntwoorden,
        vraagBlokCollection.list[index] as OpdrachtgeverVragen,
        vestigingSearches
      )
    );
  };
}
