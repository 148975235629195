import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { Tekst } from "../tekst";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { AvvArtikel10AVerzoekPeriodeVragen } from "./avv-artikel10a-verzoek-periode-vragen";

export class AvvArtikel10AVerzoekVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public verzoekGedaanDoor: Vraag,
    public welkeCaoVanToepassing: Vraag,
    public redenAanvraag: Vraag,
    public periode: Tekst,
    public perioden: VraagBlokCollection<AvvArtikel10AVerzoekPeriodeVragen>,
    public vestiging: Tekst,
    public welkeOnderneming: Tekst,
    public kvkgegevens: BedrijfsgegevensVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AvvArtikel10AVerzoekVragen {
    return new AvvArtikel10AVerzoekVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.verzoekGedaanDoor`, json.verzoekGedaanDoor),
      jsonHelpers.toTekstVraag(`${key}.welkeCaoVanToepassing`, json.welkeCaoVanToepassing),
      jsonHelpers.toTekstVraag(`${key}.redenAanvraag`, json.redenAanvraag),
      jsonHelpers.toTitel(`${key}.periode`, json.periode),
      VraagBlokCollection.fromJson<AvvArtikel10AVerzoekPeriodeVragen>(
        AvvArtikel10AVerzoekPeriodeVragen.fromJson,
        `${key}.perioden`,
        json.perioden,
        VraagBlokCollectionType.perioden
      ),
      jsonHelpers.toTitel(`${key}.vestiging`, json.vestiging),
      jsonHelpers.toParagraaf(`${key}.welkeOnderneming`, json.welkeOnderneming),
      BedrijfsgegevensVragen.fromJson(`${key}.kvkgegevens`, json.kvkgegevens)
    );
  }
}
