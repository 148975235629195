import { landHelpers } from "helpers";
import { Land, MeldingContext } from "models/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { State } from "store";
import { translationKeys } from "../../../constants/translation-keys";
import { wordtGesteld } from "../../formulier/with-conditioneel-gesteld/conditioneel-gesteld-helper";
import { SamenvattingContext } from "../../samenvatting";
import { SamenvattingField } from "../../samenvatting/samenvatting-field";
import { VisibleWrapper } from "../../visible-wrapper";
import { VestigingSamenvattingProps } from "./vestiging-samenvatting.component.interfaces";

export const VestigingSamenvatting = (props: VestigingSamenvattingProps) => {
  const { t } = useTranslation();

  const samenvattingContext = React.useContext(SamenvattingContext);

  const wordtGesteldValue =
    (props.vraagBlokken &&
      wordtGesteld(
        props.vragen,
        props.vraagBlokken,
        props.antwoordBlokken,
        props.user,
        props.autorisatiegegevens,
        new MeldingContext(props.wordtMeldingGewijzigd, props.meldingStatus)
      )) ??
    false;

  const { landen } = useSelector<State, { landen: Land[] }>(
    (state: State) => ({
      landen: state.landen.landen
    }),
    shallowEqual
  );

  return (
    <VisibleWrapper isVisible={wordtGesteldValue}>
      <section>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.kvknummer)}
          waarde={props.vestiging?.ves_kvknr ?? ""}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.kvkNummer`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.vestigingsnummer)}
          waarde={props.vestiging?.ves_vest_nr ?? ""}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.vestigingsnummer`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.naamBedrijfInstelling)}
          waarde={props.vestiging?.ves_naam}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.naamBedrijfInstelling`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.postcode)}
          waarde={props.vestiging?.ves_postcode}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.postcode`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.huisnummer)}
          waarde={props.vestiging?.ves_huisnr?.toString()}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.huisnummer`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.huisnummertoevoeging)}
          waarde={props.vestiging?.ves_toev_huisnr}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.huisnummertoevoeging`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.straatnaam)}
          waarde={props.vestiging?.ves_straat}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.straatnaam`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.plaats)}
          waarde={props.vestiging?.ves_plaats}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.plaats`}
        ></SamenvattingField>
        <SamenvattingField
          label={t(translationKeys.blok.vestiging.land)}
          waarde={landHelpers.getLandNaam(landen, props.vestiging?.LAN_NAAM ?? "")}
          id={`${samenvattingContext.idPrefix}__${props.vragen.key}.land`}
        ></SamenvattingField>
      </section>
    </VisibleWrapper>
  );
};
