import React from "react";
import { languageHelpers } from "../../../helpers";
import { SamenvattingFieldOwnProps } from "../samenvatting-field/samenvatting-field.component.interfaces";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { withSamenvattingVraag } from "../with-samenvatting-vraag";
import { WithSamenvattingChecklistvraagProps } from "./with-samenvatting-checklistvraag.interfaces";

export const withSamenvattingChecklistvraag = <P extends object>(
  Component: React.ComponentType<P & SamenvattingFieldOwnProps>
) => {
  return (props: P & WithSamenvattingChecklistvraagProps) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(withSamenvattingVraag(Component));

    return (
      <SamenvattingVraagComponent
        {...props}
        item={props.vraag}
        getAntwoord={(antwoordWaarde: string[], language) =>
          languageHelpers.getWaardesFromChecklistvraag(props.vraag.mogelijkeAntwoorden, antwoordWaarde, language)
        }
      />
    );
  };
};
