import {
  AntwoordBlokken,
  KennisgevingAntwoorden,
  LocatieWerkzaamhedenBijBedrijfAntwoorden,
  MelderAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasKennisgevingAntwoorden } from "./has-kennisgeving-antwoorden";
import { HasLocatieWerkzaamhedenBijBedrijfAntwoorden } from "./has-locatie-werkzaamheden-bij-bedrijf-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";

export class KennisgevingMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasMelderAntwoorden, HasLocatieWerkzaamhedenBijBedrijfAntwoorden, HasKennisgevingAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public locatie: LocatieWerkzaamhedenBijBedrijfAntwoorden,
    public kennisgeving: KennisgevingAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): KennisgevingMeldingAntwoordBlokken {
    return new KennisgevingMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      LocatieWerkzaamhedenBijBedrijfAntwoorden.initialize(),
      KennisgevingAntwoorden.initialize()
    );
  }

  static fromJson(json: any): KennisgevingMeldingAntwoordBlokken {
    return new KennisgevingMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      LocatieWerkzaamhedenBijBedrijfAntwoorden.fromJson(json.locatie),
      KennisgevingAntwoorden.fromJson(json.kennisgeving)
    );
  }
}
