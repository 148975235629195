import { MeldingsplichtVragen, ToedrachtVragen } from "..";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class MeldingsplichtToedrachtVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public meldingsplicht: MeldingsplichtVragen,
    public toedracht: ToedrachtVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): MeldingsplichtToedrachtVragen {
    return new MeldingsplichtToedrachtVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      MeldingsplichtVragen.fromJson(`${key}.meldingsplicht`, json.meldingsplicht),
      ToedrachtVragen.fromJson(`${key}.toedracht`, json.toedracht)
    );
  }
}
