import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";
import { BuitenlandsAdresAntwoorden } from "./buitenlands-adres-antwoorden";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class OpdrachtgeverAntwoorden extends AntwoordBlok {
  constructor(
    public bedrijfIngeschreven: Antwoord<string>,
    public kvkgegevens: BedrijfsgegevensAntwoorden | null,
    public bedrijfsnaam: Antwoord<string>,
    public buitenlandsAdres: BuitenlandsAdresAntwoorden | null,
    public telefoonnummer: Antwoord<string>,
    public emailadres: Antwoord<string>,
    public website: Antwoord<string>,
    public correspondentieadresGelijkAanVestigingsadres: Antwoord<string>,
    public afwijkendCorrespondentieadres: CorrespondentieadresAntwoorden | null,
    public scaCode: Antwoord<string>,
    public contactpersoon: ContactpersoonAntwoorden | null,
    json?: any
  ) {
    super(json);
  }

  static initialize(): OpdrachtgeverAntwoorden {
    return new OpdrachtgeverAntwoorden(
      new Antwoord<string>(""),
      BedrijfsgegevensAntwoorden.initialize(),
      new Antwoord<string>(""),
      BuitenlandsAdresAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>(""),
      ContactpersoonAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OpdrachtgeverAntwoorden {
    return new OpdrachtgeverAntwoorden(
      Antwoord.fromJson<string>(json.bedrijfIngeschreven),
      BedrijfsgegevensAntwoorden.fromJson(json.kvkgegevens),
      Antwoord.fromJson(json.bedrijfsnaam),
      BuitenlandsAdresAntwoorden.fromJson(json.buitenlandsAdres),
      Antwoord.fromJson(json.telefoonnummer),
      Antwoord.fromJson(json.emailadres),
      Antwoord.fromJson(json.website),
      Antwoord.fromJson<string>(json.correspondentieadresGelijkAanVestigingsadres),
      CorrespondentieadresAntwoorden.fromJson(json.afwijkendCorrespondentieadres),
      Antwoord.fromJson(json.scaCode),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      json
    );
  }
}
