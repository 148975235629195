import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsInCollection extends VraagExpression {
  get operator(): string {
    return IsInCollection.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly vraagBlokCollectionProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;
    const collection = context.getAntwoordBlokCollection(this.vraagBlokCollectionProperty);
    return collection?.list?.some((item) => item.id === antwoord) ?? false;
  };

  protected clone = (instance: IsInCollection): IsInCollection =>
    new IsInCollection(instance.vraagProperty, instance.vraagBlokCollectionProperty);

  static fromJson = (json: any): IsInCollection => new IsInCollection(json.vraag, json.vraagBlokCollection);

  static operator = (): string => "isInCollection";

  static matches = (json: any): boolean => json?.operator === IsInCollection.operator();
}
