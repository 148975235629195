import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { KennisgevingFormulierVraagBlokken } from "./kennisgeving-formulier-vraag-blokken";

export class BergplaatsRadioactieveStoffenFormulier extends FormulierBase {
  constructor(public blokken: KennisgevingFormulierVraagBlokken, json: any) {
    super(Formuliertype.bergplaatsRadioactieveStoffen, blokken, json);
  }

  static fromJson(json: any): BergplaatsRadioactieveStoffenFormulier {
    return new BergplaatsRadioactieveStoffenFormulier(KennisgevingFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
