import {
  TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden,
  TeVerwijderenAsbestMateriaalRisicoklasse1Vragen
} from "../models/api";
import { InventarisatieValidator } from "./inventarisatie-validator";

export class InventarisatieTeVerwijderenAsbestMateriaalValidator extends InventarisatieValidator {
  static createValidate = (
    vraagBlok: TeVerwijderenAsbestMateriaalRisicoklasse1Vragen
  ): ((antwoordBlok: TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden) => any) => {
    const validator = new InventarisatieTeVerwijderenAsbestMateriaalValidator();

    return (antwoordBlok) => validator.validate(vraagBlok, antwoordBlok);
  };
}
