import { OntheffingLiftenFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getOntheffingLiftenFormulierDefinitie = (formulier: OntheffingLiftenFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.aanvrager.titel,
    type: ProcesstapType.aanvrager
  },
  {
    title: formulier.blokken.eigenaar.titelVerkort,
    type: ProcesstapType.eigenaar
  },
  {
    title: formulier.blokken.locatie.titelVerkort,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.aanvraag.titelVerkort,
    type: ProcesstapType.aanvraag
  }
];
