import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "../../../components/progress-bar";
import { translationKeys } from "../../../constants/translation-keys";
import { FormulierVerzendenDialogProps } from "./formulier-verzenden-dialog.component.interfaces";

export const FormulierVerzendenDialog = (props: FormulierVerzendenDialogProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={props.isVisible}
      aria-labelledby="formulier-verzenden-dialog-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="dialog-title" id="formulier-verzenden-dialog-title">
          {t(translationKeys.verzenden)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar
          className={props.className}
          isVisible={true}
          value={props.progressPercentage}
          label={t(translationKeys["verzenden-bezig"])}
        />
      </Modal.Body>
    </Modal>
  );
};
