import { addBestanden, clearFilesError, deleteBestanden, setBestanden, setBestandenConflicts } from "../actions";
import { MeldingFile, MeldingFileState } from "../models/application";
import { AppThunk } from "./app-thunk-type";

export const addFiles =
  (addedFiles: File[]): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const currentFiles = getState().bestanden.files;

    const conflictingFiles = Array.from<File>(addedFiles)
      .filter((af) => currentFiles.some((f) => f.name === af.name))
      .map((f) => new MeldingFile(MeldingFileState.Added, f.name, f.size, f, null));

    const filteredAddedFiles = Array.from<File>(addedFiles).filter((af) => currentFiles.every((f) => f.name !== af.name));

    dispatch(addBestanden(filteredAddedFiles));
    if (conflictingFiles.length > 0) {
      dispatch(setConflictingFiles(conflictingFiles));
    }

    dispatch(clearFilesError());
  };

export const deleteFiles =
  (filesToDelete: File[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(deleteBestanden(filesToDelete));
    dispatch(clearFilesError());
  };

export const setConflictingFiles =
  (conflictingFiles: MeldingFile[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setBestandenConflicts(conflictingFiles));
  };

export const removeConflictingFile =
  (file: MeldingFile): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const currentConflictingFiles = getState().bestanden.conflictingFiles;
    const newConflictingFiles = currentConflictingFiles.filter((f) => f !== file);
    dispatch(setBestandenConflicts(newConflictingFiles));
  };

export const skipConflictingFile =
  (file: MeldingFile): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(removeConflictingFile(file));
  };

export const overwriteConflictingFile =
  (file: MeldingFile): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const currentFilesWithoutConflictingFile = getState().bestanden.files.filter((f) => f.name !== file.name);
    const newFiles = [
      ...currentFilesWithoutConflictingFile,
      new MeldingFile(MeldingFileState.Added, file.name, file.size, file.file, file.id)
    ];

    dispatch(setBestanden(newFiles));
    dispatch(removeConflictingFile(file));
  };
