import moment from "moment";
import { dateHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export abstract class TimeExpressionBase extends VraagExpression {
  constructor(public readonly vraagProperty: string, protected readonly tijd: string) {
    super(vraagProperty);
  }

  protected abstract DateCompare(tijdAntwoord: moment.Moment, tijdCompare: moment.Moment): boolean;

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    let result = false;

    const antwoord = context.getAntwoord(this.vraagProperty)?.waarde;

    if (antwoord && this.tijd && dateHelpers.isValidTime(`${antwoord}`) && dateHelpers.isValidTime(`${this.tijd}`)) {
      const tijdAntwoord = dateHelpers.getMomentFromTimeString(`${antwoord}`);
      const tijdCompare = dateHelpers.getMomentFromTimeString(`${this.tijd}`);

      result = this.DateCompare(tijdAntwoord, tijdCompare);
    }

    return result;
  };
}
