import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class MotivatieAntwoorden extends AntwoordBlok {
  constructor(
    public specificatieOndergrond: Antwoord<string>,
    public vetteOndergrond: Antwoord<string>,
    public behandelingOndergrondMogelijk: Antwoord<string>,
    public toelichtingBehandelingOndergrond: Antwoord<string>,
    public redenVosProductenBenodigdApplicatiefase: Antwoord<string>,
    public redenVosProductenBenodigdGebruiksfase: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>,
    public maatregelenBeschermingBlootstellingOplosmiddelen: Antwoord<string>,
    public toelichtingAanvraag: Antwoord<string>
  ) {
    super();
  }

  static initialize(): MotivatieAntwoorden {
    return new MotivatieAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): MotivatieAntwoorden {
    return new MotivatieAntwoorden(
      Antwoord.fromJson<string>(json.specificatieOndergrond),
      Antwoord.fromJson<string>(json.vetteOndergrond),
      Antwoord.fromJson<string>(json.behandelingOndergrondMogelijk),
      Antwoord.fromJson<string>(json.toelichtingBehandelingOndergrond),
      Antwoord.fromJson<string>(json.redenVosProductenBenodigdApplicatiefase),
      Antwoord.fromJson<string>(json.redenVosProductenBenodigdGebruiksfase),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson),
      Antwoord.fromJson<string>(json.maatregelenBeschermingBlootstellingOplosmiddelen),
      Antwoord.fromJson<string>(json.toelichtingAanvraag)
    );
  }
}
