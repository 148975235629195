import { Configuration } from "../configuration";
import { PlaatsenSearchResponse } from "../models/api/plaatsen-search-response";
import { get } from "./api";

const searchPlaatsen = (naam: string): Promise<PlaatsenSearchResponse> => {
  const isWhitespaceString = (str: string) => !str.replace(/\s/g, "").length;
  if (isWhitespaceString(naam)) {
    return Promise.resolve(new PlaatsenSearchResponse([]));
  }
  return get({
    uri: `${Configuration.apiUrl}/plaatsen?naam=${naam}`,
    success: (r) => PlaatsenSearchResponse.fromJson(r)
  });
};

export const plaatsenApi = {
  searchPlaatsen
};
