import { Configuration } from "../configuration";
import { httpStatusCodes } from "../constants";
import {
  BrpAchternaamBatchSearchRequest,
  BrpAchternaamBatchSearchResponse,
  BrpAchternaamSearchRequest,
  BrpAchternaamSearchResponse
} from "../models/api";
import { get, post } from "./api";

const defaultStatusHandlers = new Map([
  [
    httpStatusCodes.notfound,
    () => {
      // No specific actions necessary
    }
  ]
]);

const getAchternaam = async (request: BrpAchternaamSearchRequest): Promise<BrpAchternaamSearchResponse> => {
  return get({
    uri: `${Configuration.apiUrl}/brp/achternaam?bsn=${
      request.bsn
    }&geboortedatum=${request.geboortedatum.toISOString()}&achternaam=${request.achternaam}`,
    success: (r) => BrpAchternaamSearchResponse.fromJson(r),
    statusHandlers: defaultStatusHandlers
  });
};

const getAchternamen = async (request: BrpAchternaamBatchSearchRequest): Promise<BrpAchternaamBatchSearchResponse> => {
  return post({
    uri: `${Configuration.apiUrl}/brp/achternaam/actions/search`,
    data: request,
    success: (r) => BrpAchternaamBatchSearchResponse.fromJson(r)
  });
};

export const brpApi = {
  getAchternaam,
  getAchternamen
};
