import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { OntheffingNachtarbeidFormulierVraagBlokken } from "./ontheffing-nachtarbeid-formulier-vraag-blokken";

export class OntheffingNachtarbeidFormulier extends FormulierBase {
  constructor(public blokken: OntheffingNachtarbeidFormulierVraagBlokken, json: any) {
    super(Formuliertype.ontheffingNachtarbeid, blokken, json);
  }

  static fromJson(json: any): OntheffingNachtarbeidFormulier {
    return new OntheffingNachtarbeidFormulier(OntheffingNachtarbeidFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
