import { UserMenuContainer } from "./user-menu-container.component";
import UserMenuToggle from "./user-menu-toggle.container";
import UserMenu from "./user-menu.container";

const container = {
  Container: UserMenuContainer,
  Toggle: UserMenuToggle,
  Menu: UserMenu
};

export { container as UserMenu };
