import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { MelderAntwoorden, VluchtigeOrganischeStoffenMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initVluchtigeOrganischeStoffenFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getVluchtigeOrganischeStoffenFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getVluchtigeOrganischeStoffenFormulierDefinitie,
      VluchtigeOrganischeStoffenMeldingAntwoordBlokken.initialize
    );
  };

export const initVluchtigeOrganischeStoffenFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getVluchtigeOrganischeStoffenFormulier(),
      meldingApi.getVluchtigeOrganischeStoffenMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): VluchtigeOrganischeStoffenMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        aanvrager: {
          ...melding.blokken.aanvrager,
          common: MelderAntwoorden.initialize(user)
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getVluchtigeOrganischeStoffenFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
