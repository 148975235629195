import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { translationKeys } from "../../../constants/translation-keys";
import { AutorisatieProfielFormulier } from "../../formulier/autorisatie-profiel";
import { GuardedLink } from "../../guarded-link";
import { Spinner } from "../../spinner";
import { VisibleWrapper } from "../../visible-wrapper";
import { AutorisatiesDetailProps } from "./autorisatie-detail.component.interfaces";

export const AutorisatieDetail = (props: AutorisatiesDetailProps) => {
  const { t } = useTranslation();

  const { onInit } = props;

  const wijzigenPath = "wijzigen";

  const location = useLocation();
  const { profielId } = useParams();
  const isEditPage = location.pathname.endsWith(wijzigenPath);

  useEffect(() => {
    onInit(profielId || "", isEditPage);
  }, [onInit, profielId, isEditPage]);

  return (
    <Row className="main-container" role="main">
      <Col xs={12} className="main-container-title">
        <h1>{t(translationKeys.beheer.autorisatiedetail.titel)}</h1>
        <Row>
          {props.meldingrechten != null && (
            <Col xs={12} sm={3}>
              <div className="profiel-menu-item" id="profiel-bekijken">
                <GuardedLink url={`/beheer/autorisaties/${profielId}`}>
                  {t(translationKeys.blok.profiel.profielGegevensBekijken)}
                </GuardedLink>
              </div>
              <VisibleWrapper isVisible={props.meldingrechten?.kanWijzigen === true}>
                <div className="profiel-menu-item" id="profiel-wijzigen">
                  <GuardedLink url={`/beheer/autorisaties/${profielId}/${wijzigenPath}`}>
                    {t(translationKeys.blok.profiel.profielGegevensWijzigen)}
                  </GuardedLink>
                </div>
              </VisibleWrapper>
              <div className="profiel-menu-item" id="terug-naar-overzicht">
                <GuardedLink url={`/beheer/autorisaties`}>{t(translationKeys.beheer.autorisaties.terug)}</GuardedLink>
              </div>
            </Col>
          )}
          <Col xs={12} sm={9} className="main-container-title">
            {props.isLoading && <Spinner>{t(translationKeys.bezigMetLaden)}</Spinner>}
            <Routes>
              <Route path={"*"} element={<AutorisatieProfielFormulier isInEditMode={props.isInEditMode} />} />
            </Routes>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
