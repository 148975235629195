import { Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokType } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class BedrijfsgegevensLavsVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public kvkNummer: Vraag,
    public vestigingsnummer: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, undefined, VraagBlokType.vestiging);
  }

  static fromJson(key: string, json: any): BedrijfsgegevensLavsVragen {
    return new BedrijfsgegevensLavsVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.kvkNummer`, json.kvkNummer),
      jsonHelpers.toTekstVraag(`${key}.vestigingsnummer`, json.vestigingsnummer)
    );
  }
}
