import { Tekst } from "../../../components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class BestrijdingsmiddelenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public bestrijdingsmiddelenTitel: Tekst,
    public aanvullendeInformatie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): BestrijdingsmiddelenVragen {
    return new BestrijdingsmiddelenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.bestrijdingsmiddelenTitel`, json.bestrijdingsmiddelenTitel),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.aanvullendeInformatie`, json.aanvullendeInformatie)
    );
  }
}
