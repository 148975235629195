import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { ReactieOpenbaarmakingFormulierVraagBlokken } from "./reactie-openbaarmaking-formulier-vraag-blokken";

export class ReactieOpenbaarmakingFormulier extends FormulierBase {
  constructor(public blokken: ReactieOpenbaarmakingFormulierVraagBlokken, json: any) {
    super(Formuliertype.reactieOpenbaarmaking, blokken, json);
  }

  static fromJson(json: any): ReactieOpenbaarmakingFormulier {
    return new ReactieOpenbaarmakingFormulier(ReactieOpenbaarmakingFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
