import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class LandAntwoorden extends AntwoordBlok {
  constructor(public land: Antwoord<string>) {
    super();
  }

  static initialize(): LandAntwoorden {
    return new LandAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): LandAntwoorden | null {
    return json ? new LandAntwoorden(Antwoord.fromJson<string>(json.land)) : null;
  }
}
