import { ContactpersoonAntwoorden } from ".";
import { Antwoord } from "..";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensLavsAntwoorden } from "./bedrijfsgegevens-lavs-antwoorden";

export class OpdrachtgeverLavsAntwoorden extends AntwoordBlok {
  constructor(
    public datumSloopmelding: Antwoord<string>,
    public codeSloopmelding: Antwoord<string>,
    public bedrijfsgegevens: BedrijfsgegevensLavsAntwoorden,
    public contactpersoon: ContactpersoonAntwoorden | null,
    public scaCode: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null) {
    return new OpdrachtgeverLavsAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfsgegevensLavsAntwoorden.initialize(),
      ContactpersoonAntwoorden.initialize(user),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new OpdrachtgeverLavsAntwoorden(
      Antwoord.fromJson(json.datumSloopmelding),
      Antwoord.fromJson(json.codeSloopmelding),
      BedrijfsgegevensLavsAntwoorden.fromJson(json.bedrijfsgegevens),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      Antwoord.fromJson(json.scaCode)
    );
  }
}
