import { Tekst } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { WerkzaamhedenVragen } from "./werkzaamheden-vragen";

export class AsbestWerkzaamhedenVragen extends WerkzaamhedenVragen {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public asbestWerkzaamhedenAan: Meerkeuzevraag,
    public bouwwerk: Meerkeuzevraag,
    public asbestObject: Meerkeuzevraag,
    public bewoondeLocatie: Meerkeuzevraag,
    public terugschalen: Meerkeuzevraag,
    public afwijkendeInventarisatie: Meerkeuzevraag,
    public beoordeeld: Meerkeuzevraag,
    public deskundige: Vraag,
    public certificaatnummerDeskundige: Vraag,
    public dagelijkseWerktijdenTitel: Tekst,
    public dagelijkseWerktijdAanvang: Vraag,
    public dagelijkseWerktijdGereed: Vraag,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public bestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AsbestWerkzaamhedenVragen {
    return new AsbestWerkzaamhedenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toChecklistvraag(`${key}.asbestWerkzaamhedenAan`, json.asbestWerkzaamhedenAan),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bouwwerk`, json.bouwwerk),
      jsonHelpers.toMeerkeuzeVraag(`${key}.asbestObject`, json.asbestObject),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bewoondeLocatie`, json.bewoondeLocatie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.terugschalen`, json.terugschalen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.afwijkendeInventarisatie`, json.afwijkendeInventarisatie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.beoordeeld`, json.beoordeeld),
      jsonHelpers.toTekstVraag(`${key}.deskundige`, json.deskundige),
      jsonHelpers.toTekstVraag(`${key}.certificaatnummerDeskundige`, json.certificaatnummerDeskundige),
      jsonHelpers.toTitel(`${key}.dagelijkseWerktijdenTitel`, json.dagelijkseWerktijdenTitel),
      jsonHelpers.toTijdVraag(`${key}.dagelijkseWerktijdAanvang`, json.dagelijkseWerktijdAanvang),
      jsonHelpers.toTijdVraag(`${key}.dagelijkseWerktijdGereed`, json.dagelijkseWerktijdGereed),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden)
    );
  }
}
