import { DuikarbeidFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getDuikarbeidFormulierDefinitie = (formulier: DuikarbeidFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.locatie.titelVerkort,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.periode.titel,
    type: ProcesstapType.periode
  },
  {
    title: formulier.blokken.werkzaamheden.titelVerkort,
    type: ProcesstapType.werkzaamheden
  }
];
