import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { Expression } from "./expression";
import { expressionParser } from "./expression-parser";

export class Not extends Expression {
  get operator(): string {
    return Not.operator();
  }

  constructor(public expression: Expression) {
    super();
  }

  execute = (vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean =>
    !this.expression.execute(vraagBlok, context, vraagProperty);

  static fromJson = (json: any): Not => {
    const expression = expressionParser.fromJson(json.expression);

    if (!expression) {
      throw Error("Expression cannot be null.");
    }

    return new Not(expression);
  };

  static operator = (): string => "not";

  static matches = (json: any): boolean => json?.operator === Not.operator();
}
