import { connect } from "react-redux";
import { ContactFormulier } from "../../../models/api/formulier/contact-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getContactformulierMelding, initContactFormulier, initContactFormulierFromCopy } from "../../../thunks";
import { ContactFormulierComponent } from "./contact-formulier.component";
import {
  ContactFormulierComponentDispatchProps,
  ContactFormulierComponentStateProps
} from "./contact-formulier.component.interfaces";

const mapStateToProps = (state: State): ContactFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.contact ? (state.vragen.formulier as ContactFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): ContactFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initContactFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getContactformulierMelding(meldingId));
    } else {
      dispatch(initContactFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormulierComponent);
