import { translationKeys } from "constants/translation-keys";
import { useFormikContext } from "formik";
import i18n from "i18next";
import { User } from "models/api/security/user";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { expressionHelpers, meldingHelpers, objectHelpers, validationHelpers } from "../../../helpers";
import {
  Antwoord,
  AntwoordBlok,
  AntwoordBlokken,
  MeldingContext,
  ValidationResponse,
  Vraag,
  VraagBlokken
} from "../../../models/api";
import { ExpressionContext } from "../../../models/api/blok/expression-context";
import { Meerkeuzevraag } from "../../../models/api/meerkeuzevraag";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";
import { State } from "../../../store";
import { InputProps } from "../../input-controls/input.interfaces/input.interfaces";
import { VraagBlokContext } from "../../vraag-blok-context";
import { WithVraagProps } from "./with-vraag.interfaces";

const getVraagLabel = (vraag: Vraag, taal: string, isVertaling: boolean) => {
  const label = `${vraag.tekst[taal]}`;
  if (isVertaling) {
    return `${label} ${i18n.t(translationKeys.melding.mijnMeldingen.vertalingVeldLabelToevoeging)}`;
  }

  return vraag.isVerplicht ? `${label} *` : `${label}`;
};

const getVraagToelichting = (vraag: Vraag, taal: string) => (vraag.toelichting ? vraag.toelichting[taal] : null);

const getVraagPlaceholder = (vraag: Vraag, taal: string, placeholder: string) =>
  vraag.placeholder && vraag.placeholder[taal] ? vraag.placeholder[taal] : placeholder;

const getVraagKey = (vraag: Vraag, propertyPath: string): string =>
  vraag.parent ? `${vraag.parent.key}.${propertyPath}` : vraag.key;

const getExpressionContext = (
  user: User | null,
  antwoordBlokForFieldValidation: AntwoordBlok | null,
  autorisatiegegevens: Autorisatiegegevens,
  melding: MeldingContext,
  values: AntwoordBlokken,
  vragen: VraagBlokken
): ExpressionContext | null =>
  antwoordBlokForFieldValidation
    ? new ExpressionContext(user, autorisatiegegevens, melding, antwoordBlokForFieldValidation, values, vragen)
    : null;

export const withVraag = <P extends object>(Component: React.ComponentType<P & InputProps>) => {
  return (props: P & WithVraagProps) => {
    const { vraag, placeholder, useVertaling, readOnly, ...rest } = props;
    const lang = i18n.language;

    const { values, setFieldValue } = useFormikContext<AntwoordBlokken>();

    const {
      user,
      shouldValidate,
      vragen,
      submitValidationResults,
      autorisatiegegevens,
      meldingStatus,
      wordtMeldingGewijzigd
    } = useSelector<
      State,
      {
        user: User | null;
        shouldValidate: boolean;
        vragen?: VraagBlokken;
        submitValidationResults: ValidationResponse | null;
        autorisatiegegevens: Autorisatiegegevens;
        meldingStatus?: string;
        wordtMeldingGewijzigd: boolean;
      }
    >(
      (state: State) => ({
        user: state.security.user,
        shouldValidate: !state.proces.requestedItem || state.proces.requestedItem.shouldValidate,
        vragen: state.vragen.formulier?.blokken,
        submitValidationResults: state.proces.submitValidationResults,
        autorisatiegegevens: state.autorisatie.autorisatiegegevens,
        meldingStatus: state.melding?.status,
        wordtMeldingGewijzigd: state.melding?.wordtGewijzigd
      }),
      shallowEqual
    );

    const [, propertyPath] = meldingHelpers.getKeyPartsFromItem(vraag);

    let inputProps: InputProps | null = null;

    const vraagKey = getVraagKey(vraag, propertyPath);

    const antwoord = objectHelpers.getValue<Antwoord<any>>(values, vraag.key);

    const vraagBlokContext = React.useContext(VraagBlokContext);
    let berekendeWaarde: boolean | null = null;
    let isReadonly = readOnly;
    let validateVraag: any = undefined;

    const vraagBlok = meldingHelpers.getVraagBlok(vraag, vragen);

    if (vraagBlok && vragen) {
      const antwoordBlokForFieldValidation = meldingHelpers.getAntwoordBlok(vraag, values);

      const contextForFieldValidation = getExpressionContext(
        user,
        antwoordBlokForFieldValidation,
        autorisatiegegevens,
        new MeldingContext(wordtMeldingGewijzigd, meldingStatus),
        values,
        vragen
      );

      if (contextForFieldValidation) {
        berekendeWaarde = expressionHelpers.executeBerekendeWaarde(vraag, vraagBlok, contextForFieldValidation);
        isReadonly = isReadonly || expressionHelpers.isItemReadonly(vraag, vraagBlok, contextForFieldValidation);
        validateVraag = validationHelpers.createValidateVraag(
          vraag,
          vraagBlok,
          contextForFieldValidation,
          shouldValidate,
          submitValidationResults
        );
      }

      inputProps = {
        name: meldingHelpers.getFieldNameFromVraagKey(vraagKey, useVertaling),
        label: getVraagLabel(vraag, lang, useVertaling),
        placeholder: getVraagPlaceholder(vraag, lang, placeholder),
        validate: useVertaling ? undefined : validateVraag,
        maxLength: vraag.maxLength,
        toelichting: getVraagToelichting(vraag, lang),
        readOnly: isReadonly,
        autoComplete: vraag.displayDefinition?.inputPurpose
      };

      vraagBlokContext.isFirstVraag = false;
    }

    const setDefaultValue: boolean = !!vraag.defaultAntwoord && !vraag.berekendeWaarde && !antwoord?.waarde;

    // Set answer to question's default value initially.
    useEffect(
      () => {
        if (setDefaultValue) {
          setFieldValue(meldingHelpers.getFieldNameFromVraag(vraag, useVertaling), vraag.defaultAntwoord, true);
        }
      },
      // eslint-disable-next-line
      [setFieldValue, vraag]
    );

    useEffect(() => {
      if (!isReadonly && vraag.berekendeWaarde) {
        setFieldValue(meldingHelpers.getFieldNameFromVraag(vraag, useVertaling), berekendeWaarde);
      }
    }, [berekendeWaarde, isReadonly, setFieldValue, vraag, useVertaling]);

    const mogelijkeAntwoorden = (vraag as Meerkeuzevraag).mogelijkeAntwoorden
      ? { mogelijkeAntwoorden: (vraag as Meerkeuzevraag).mogelijkeAntwoorden }
      : {};

    return <Component {...(rest as P)} {...(inputProps as InputProps)} {...mogelijkeAntwoorden} />;
  };
};
