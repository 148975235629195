import { Processtap } from "../models/application";
import { ValidationResponse } from "./../models/api/validation-response";

export const INIT_PROCES = "INIT_PROCES";
export const SET_NEXT_PAGE = "SET_NEXT_PAGE";
export const SET_PREVIOUS_PAGE = "SET_PREVIOUS_PAGE";
export const SET_PAGE = "SET_PAGE";
export const SET_REQUESTED_PAGE = "SET_REQUESTED_PAGE";
export const COMMIT_REQUESTED_PAGE = "COMMIT_REQUESTED_PAGE";
export const SET_SUBMIT_REQUESTED = "SET_SUBMIT_REQUESTED";
export const CLEAR_SUBMIT_VALIDATION_RESULT = "CLEAR_SUBMIT_VALIDATION_RESULT";
export const SET_SUBMIT_VALIDATION_RESULTS = "SET_SUBMIT_VALIDATION_RESULTS";
export const SET_ALL_PAGES_COMPLETE = "SET_ALL_PAGES_COMPLETE";
export const SHOW_VALIDATION_ERRORS_DIALOG = "SHOW_VALIDATION_ERRORS_DIALOG";
export const HIDE_VALIDATION_ERRORS_DIALOG = "HIDE_VALIDATION_ERRORS_DIALOG";
export const SET_HAS_VALIDATION_ERRORS = "SET_HAS_VALIDATION_ERRORS";

export interface InitProcesAction {
  type: typeof INIT_PROCES;
  data: Processtap[];
}

export interface SetNextPageAction {
  type: typeof SET_NEXT_PAGE;
}

export interface SetPreviousPageAction {
  type: typeof SET_PREVIOUS_PAGE;
}

export interface SetRequestedPageAction {
  type: typeof SET_REQUESTED_PAGE;
  item: Processtap | null;
}

export interface CommitRequestedPageAction {
  type: typeof COMMIT_REQUESTED_PAGE;
}

export interface SetSubmitRequested {
  type: typeof SET_SUBMIT_REQUESTED;
  isRequested: boolean;
}

export interface SetSubmitValidationResults {
  type: typeof SET_SUBMIT_VALIDATION_RESULTS;
  data: ValidationResponse;
}

export interface ClearSubmitValidationResult {
  type: typeof CLEAR_SUBMIT_VALIDATION_RESULT;
  data: string;
}

export interface SetAllPagesComplete {
  type: typeof SET_ALL_PAGES_COMPLETE;
}

export interface SetHasValidationErrors {
  type: typeof SET_HAS_VALIDATION_ERRORS;
  hasValidationErrors: boolean;
  firstElementWithErrorId?: string;
}

export interface ShowValidationErrorsDialog {
  type: typeof SHOW_VALIDATION_ERRORS_DIALOG;
}

export interface HideValidationErrorsDialog {
  type: typeof HIDE_VALIDATION_ERRORS_DIALOG;
}

export type ProcesActions =
  | InitProcesAction
  | SetNextPageAction
  | SetPreviousPageAction
  | SetRequestedPageAction
  | CommitRequestedPageAction
  | SetSubmitRequested
  | SetSubmitValidationResults
  | ClearSubmitValidationResult
  | SetAllPagesComplete
  | SetHasValidationErrors
  | ShowValidationErrorsDialog
  | HideValidationErrorsDialog;

export const initProces = (data: Processtap[]): ProcesActions => {
  return {
    type: INIT_PROCES,
    data
  };
};

export const setNextPage = (): ProcesActions => {
  return {
    type: SET_NEXT_PAGE
  };
};

export const setPreviousPage = (): ProcesActions => {
  return {
    type: SET_PREVIOUS_PAGE
  };
};

export const setRequestedPage = (item: Processtap | null): ProcesActions => {
  return {
    type: SET_REQUESTED_PAGE,
    item
  };
};

export const commitRequestedPage = (): ProcesActions => {
  return {
    type: COMMIT_REQUESTED_PAGE
  };
};

export const setSubmitRequested = (isRequested: boolean): ProcesActions => {
  return {
    type: SET_SUBMIT_REQUESTED,
    isRequested
  };
};

export const setSubmitValidationResults = (data: ValidationResponse): ProcesActions => {
  return {
    type: SET_SUBMIT_VALIDATION_RESULTS,
    data
  };
};

export const clearSubmitValidationResult = (data: string): ProcesActions => {
  return {
    type: CLEAR_SUBMIT_VALIDATION_RESULT,
    data
  };
};

export const setAllPagesComplete = (): ProcesActions => {
  return {
    type: SET_ALL_PAGES_COMPLETE
  };
};

export const showValidationErrorsDialog = (): ProcesActions => {
  return {
    type: SHOW_VALIDATION_ERRORS_DIALOG
  };
};

export const hideValidationErrorsDialog = (): ProcesActions => {
  return {
    type: HIDE_VALIDATION_ERRORS_DIALOG
  };
};

export const setHasValidationErrors = (
  hasValidationErrors: boolean,
  firstElementWithErrorId?: string | null
): ProcesActions => {
  return {
    type: SET_HAS_VALIDATION_ERRORS,
    hasValidationErrors: hasValidationErrors,
    firstElementWithErrorId: firstElementWithErrorId ?? undefined
  };
};
