import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class ProductieAntwoorden extends AntwoordBlok {
  constructor(
    public soortProductie: Antwoord<string>,
    public productietitel: Antwoord<string>,
    public productieStartdatum: Antwoord<string>,
    public productieEinddatum: Antwoord<string>,
    public isActualiteitenprogramma: Antwoord<string>,
    public naamActualiteitenprogramma: Antwoord<string>,
    public beschrijvingActiviteitenEnOmstandighedenKinderen: Antwoord<string>,
    public beschrijvingOrganisatieArbeid: Antwoord<string>,
    public beschrijvingWerklocatiesEnRisicos: Antwoord<string>,
    public productieRisicos: Antwoord<string[]>,
    public maatregelenGevaarlijkeStoffen: Antwoord<string>,
    public maatregelenGeluidsniveau: Antwoord<string>,
    public maatregelenBedienenArbeidsmiddel: Antwoord<string>,
    public maatregelenEmotioneleOverbelasting: Antwoord<string>,
    public maatregelenFysiekeOverbelasting: Antwoord<string>,
    public maatregelenBrandenElectrocutieOfBevriezing: Antwoord<string>,
    public maatregelenKnellenOfPletten: Antwoord<string>,
    public maatregelenSnijden: Antwoord<string>,
    public maatregelenVallen: Antwoord<string>,
    public maatregelenBedwelmingOfVerstikking: Antwoord<string>,
    public gebruikPersoonlijkeBeschermingsmiddelen: Antwoord<string>,
    public isRisicovol: Antwoord<boolean>,
    public isRisicovolOnbekend: Antwoord<boolean>,
    public oordeelGoedkeuringArbeidAanwezig: Antwoord<boolean>,
    json?: any
  ) {
    super(json);
  }

  static initialize(): ProductieAntwoorden {
    return new ProductieAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<boolean>(false),
      new Antwoord<boolean>(true),
      new Antwoord<boolean>(false)
    );
  }

  static fromJson(json: any): ProductieAntwoorden {
    return new ProductieAntwoorden(
      Antwoord.fromJson(json.soortProductie),
      Antwoord.fromJson(json.productietitel),
      Antwoord.fromJson(json.productieStartdatum),
      Antwoord.fromJson(json.productieEinddatum),
      Antwoord.fromJson(json.isActualiteitenprogramma),
      Antwoord.fromJson(json.naamActualiteitenprogramma),
      Antwoord.fromJson(json.beschrijvingActiviteitenEnOmstandighedenKinderen),
      Antwoord.fromJson(json.beschrijvingOrganisatieArbeid),
      Antwoord.fromJson(json.beschrijvingWerklocatiesEnRisicos),
      Antwoord.fromJson(json.productieRisicos),
      Antwoord.fromJson(json.maatregelenGevaarlijkeStoffen),
      Antwoord.fromJson(json.maatregelenGeluidsniveau),
      Antwoord.fromJson(json.maatregelenBedienenArbeidsmiddel),
      Antwoord.fromJson(json.maatregelenEmotioneleOverbelasting),
      Antwoord.fromJson(json.maatregelenFysiekeOverbelasting),
      Antwoord.fromJson(json.maatregelenBrandenElectrocutieOfBevriezing),
      Antwoord.fromJson(json.maatregelenKnellenOfPletten),
      Antwoord.fromJson(json.maatregelenSnijden),
      Antwoord.fromJson(json.maatregelenVallen),
      Antwoord.fromJson(json.maatregelenBedwelmingOfVerstikking),
      Antwoord.fromJson(json.gebruikPersoonlijkeBeschermingsmiddelen),
      Antwoord.fromJson(json.isRisicovol),
      Antwoord.fromJson(json.isRisicovolOnbekend),
      Antwoord.fromJson(json.oordeelGoedkeuringArbeidAanwezig),
      json
    );
  }
}
