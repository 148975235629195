import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class BesluitRisicoZwareOngevallenAntwoorden extends AntwoordBlok {
  constructor(public aanvullendeInformatie: Antwoord<string>) {
    super();
  }

  static initialize(): BesluitRisicoZwareOngevallenAntwoorden {
    return new BesluitRisicoZwareOngevallenAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): BesluitRisicoZwareOngevallenAntwoorden {
    return json
      ? new BesluitRisicoZwareOngevallenAntwoorden(Antwoord.fromJson<string>(json.aanvullendeInformatie))
      : this.initialize();
  }
}
