import { TaalWijzigDialogMode } from "store";

export const SET_TAAL_WIJZIG_MODE = "SET_TAAL_WIJZIG_MODE";

interface SetTaalWijzigModeAction {
  type: typeof SET_TAAL_WIJZIG_MODE;
  requestedLanguage: string;
  taalWijzigDialogMode: TaalWijzigDialogMode;
}

export type TaalActions = SetTaalWijzigModeAction;

export const setTaalWijzigModeAction = (
  requestedLanguage: string,
  taalWijzigDialogMode: TaalWijzigDialogMode
): SetTaalWijzigModeAction => {
  return {
    type: SET_TAAL_WIJZIG_MODE,
    requestedLanguage,
    taalWijzigDialogMode
  };
};
