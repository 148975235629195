import { FormikContextType } from "formik";
import { AntwoordBlokken } from "models/api";
import { connect } from "react-redux";
import { NO_SELECTED_ITEM } from "reducers/collections-reducer";
import { removeItemFromCollection } from "thunks";
import { State } from "../../../../../store";
import { VraagBlokWithAntwoordIndex } from "../../model";
import { CollectionAccordionContainer } from "./collection-accordion-container.component";
import {
  CollectionAccordionContainerDispatchProps,
  CollectionAccordionContainerOwnProps,
  CollectionAccordionContainerStateProps
} from "./collection-accordion-container.component.interfaces";

const mapStateToProps = (
  state: State,
  props: CollectionAccordionContainerOwnProps
): CollectionAccordionContainerStateProps => ({
  seletedCollectionItemKey: state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM
});

const mapDispatchToProps = (
  dispatch: any,
  props: CollectionAccordionContainerOwnProps
): CollectionAccordionContainerDispatchProps => ({
  removeCollectionItem: (
    vraagAntwoordBlok: VraagBlokWithAntwoordIndex,
    antwoordBlokkenContext: FormikContextType<AntwoordBlokken>
  ) => {
    dispatch(removeItemFromCollection(props.collectionKey, vraagAntwoordBlok.antwoordBlokIndex, antwoordBlokkenContext));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionAccordionContainer);
