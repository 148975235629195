import { ErrorMessageControl } from "components/error";
import { VisibleWrapper } from "components/visible-wrapper";
import { translationKeys } from "constants/translation-keys";
import { useFormikContext } from "formik";
import { stringHelpers } from "helpers";
import { objectHelpers } from "helpers/object-helpers";
import { AntwoordBlok, AntwoordBlokken, VraagBlok } from "models/api";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CollectionErrorsOnInactiveItemsProps } from "./collection-errors-on-inactive-items.component.interfaces";

export const CollectionErrorsOnInactiveItems = (props: CollectionErrorsOnInactiveItemsProps) => {
  const { t, i18n } = useTranslation();
  const { values, errors } = useFormikContext<AntwoordBlokken>();

  const [inactiveItemWithErrors, setInactiveItemWithErrors] = useState<VraagBlok[]>([]);

  const { vraagBlokCollection, getCollectionItemSummary } = props;

  useEffect(() => {
    setInactiveItemWithErrors(
      vraagBlokCollection.list.filter(
        (vb: VraagBlok) =>
          vb.key !== props.selectedCollectionItemKey &&
          props.collectionValidationFailures.some((vf) => stringHelpers.includesDeaccent(vf.property, `${vb.key}.`))
      )
    );
  }, [props.selectedCollectionItemKey, errors, vraagBlokCollection.list, props.collectionValidationFailures]);

  const hasInactiveItemsWithErrors = useCallback(() => inactiveItemWithErrors.length > 0, [inactiveItemWithErrors]);

  const getSummary = useCallback(
    (vraagBlok: VraagBlok) => {
      const antwoordBlok = objectHelpers.getValue(values, vraagBlok.key) as AntwoordBlok;

      const vraagBlokIndex = vraagBlokCollection.list.indexOf(vraagBlok);

      if (antwoordBlok) {
        return getCollectionItemSummary(antwoordBlok, vraagBlokIndex);
      }

      return `${props.vraagBlokCollection.collectieTekst[i18n.language]} #${vraagBlok.volgnummer}`;
    },
    [getCollectionItemSummary, values, props.vraagBlokCollection.collectieTekst, i18n.language, vraagBlokCollection.list]
  );

  return (
    <VisibleWrapper isVisible={hasInactiveItemsWithErrors()}>
      <div className="mt-2">
        <ErrorMessageControl
          name="vraagblok-collection-error"
          scrollIntoViewCondition={() => !!hasInactiveItemsWithErrors()}
        >
          <div>
            <div>
              {t(translationKeys.validatie.geslotenVraagblokItemsBevattenFouten, {
                collectienaam: props.vraagBlokCollection.collectieTekst[i18n.language]
              })}
            </div>
            <ul>
              {inactiveItemWithErrors.map((vraagBlokWithError, index) => (
                <li key={index}>{getSummary(vraagBlokWithError)}</li>
              ))}
            </ul>
          </div>
        </ErrorMessageControl>
      </div>
    </VisibleWrapper>
  );
};
