import { ConfirmDialog } from "components/confirm-dialog";
import { translationKeys } from "../../../constants/translation-keys";
import { InvoertaalWijzigingDialogProps } from "./invoertaal-wijziging-dialog.component.interfaces";

export const InvoertaalWijzigingDialog = (props: InvoertaalWijzigingDialogProps) => {
  return (
    <ConfirmDialog
      name="taal-wijzigen-dialog"
      showDialog={props.showDialog}
      showCancelButton={props.showCancelButton}
      titelTranslationKey={translationKeys.dialoog.taalWijzigenDialoog.titel}
      tekstTranslationKey={props.dialogTekstTranslationKey}
      confirmTranslationKey={props.dialogConfirmButtonTranslationKey}
      cancelTranslationKey={translationKeys.dialoog.annuleren}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      language={props.requestedLanguage}
    />
  );
};
