import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { User } from "../security/user";
import { GegevensAanvragerOntheffingNachtarbeidAntwoorden } from "./gegevens-aanvrager-ontheffing-nachtarbeid-antwoorden";

export class InleidingNachtarbeidAntwoorden extends AntwoordBlok {
  constructor(
    public invullenVoorZichzelfEnOuderDan18: Antwoord<string>,
    public werkgeverOpDeHoogteVanVerzoekOntheffing: Antwoord<string>,
    public aanvraagOmBijzondereSociaalMaatschappelijkePriveOmstandigheden: Antwoord<string>,
    public gegevensAanvragerOntheffingNachtarbeid: GegevensAanvragerOntheffingNachtarbeidAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): InleidingNachtarbeidAntwoorden {
    return new InleidingNachtarbeidAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      GegevensAanvragerOntheffingNachtarbeidAntwoorden.initialize(user)
    );
  }

  static fromJson(json: any): InleidingNachtarbeidAntwoorden {
    return new InleidingNachtarbeidAntwoorden(
      Antwoord.fromJson<string>(json.invullenVoorZichzelfEnOuderDan18),
      Antwoord.fromJson<string>(json.werkgeverOpDeHoogteVanVerzoekOntheffing),
      Antwoord.fromJson<string>(json.aanvraagOmBijzondereSociaalMaatschappelijkePriveOmstandigheden),
      GegevensAanvragerOntheffingNachtarbeidAntwoorden.fromJson(json.gegevensAanvragerOntheffingNachtarbeid)
    );
  }
}
