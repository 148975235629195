import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../constants/translation-keys";
import { MainContent } from "../main-content";

export const FormulierTaalNietBeschikbaar: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Row className="main-container">
      <Col xs={12} className="main-container-title"></Col>
      <Col xs={12} className="main-container-content">
        <MainContent>
          <div className="main-form">
            <h2>{t(translationKeys["formulier-niet-in-deze-taal-beschikbaar"])}</h2>
          </div>
        </MainContent>
      </Col>
    </Row>
  );
};
