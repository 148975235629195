import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { meldingApi } from "../../../api";
import { translationKeys } from "../../../constants/translation-keys";
import { Formuliertype } from "../../../models/application";
import { DownloadSamenvattingButtonProps } from "./download-samenvatting-button.component.interfaces";

export const DownloadSamenvattingButton = (props: DownloadSamenvattingButtonProps) => {
  const { t } = useTranslation();

  const getDownloadUrl = (formuliertype: Formuliertype, meldingId: string) => {
    return meldingApi.getDownloadSamenvattingUrl(formuliertype, meldingId);
  };

  return (
    <Button
      id="downloadPdfButton"
      className={props.className}
      as="a"
      href={getDownloadUrl(props.formuliertype, props.meldingId)}
      target="_blank"
      role="button"
      aria-label={t(translationKeys["formulier-verstuurd"].samenvattingPdfBekijken)}
    >
      <FontAwesomeIcon icon={faFilePdf} />
    </Button>
  );
};
