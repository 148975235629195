import {
  LocatieMetToelichtingVragen,
  MelderVragen,
  PeriodeMetRedenVragen,
  VraagBlokken,
  WerkbakWerkzaamhedenVragen
} from "../blok";
import { HasLocatieMetToelichtingVragen } from "./has-locatie-met-toelichting-vragen";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasPeriodeMetRedenVragen } from "./has-periode-met-reden-vragen";
import { HasWerkzaamhedenVragen } from "./has-werkzaamheden-vragen";

export class WerkbakFormulierVraagBlokken
  extends VraagBlokken
  implements HasMelderVragen, HasLocatieMetToelichtingVragen, HasPeriodeMetRedenVragen, HasWerkzaamhedenVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public locatie: LocatieMetToelichtingVragen,
    public periode: PeriodeMetRedenVragen,
    public werkzaamheden: WerkbakWerkzaamhedenVragen
  ) {
    super();
  }

  static fromJson(json: any): WerkbakFormulierVraagBlokken {
    return new WerkbakFormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      LocatieMetToelichtingVragen.fromJson("locatie", json.locatie),
      PeriodeMetRedenVragen.fromJson("periode", json.periode),
      WerkbakWerkzaamhedenVragen.fromJson("werkzaamheden", json.werkzaamheden)
    );
  }
}
