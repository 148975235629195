import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";

export class UitvoerendePartijAntwoorden extends AntwoordBlok {
  constructor(
    public gegevensGelijk: Antwoord<string>,
    public opdrachtgever: OpdrachtgeverAntwoorden | null,
    public coordinatorGegevensGelijk: Antwoord<string>,
    public coordinator: ContactpersoonAntwoorden | null,
    public werkzaamBij: Antwoord<string>
  ) {
    super();
  }

  static initialize(): UitvoerendePartijAntwoorden {
    return new UitvoerendePartijAntwoorden(
      new Antwoord<string>(""),
      OpdrachtgeverAntwoorden.initialize(),
      new Antwoord<string>(""),
      ContactpersoonAntwoorden.initialize(),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): UitvoerendePartijAntwoorden {
    return new UitvoerendePartijAntwoorden(
      Antwoord.fromJson<string>(json.gegevensGelijk),
      OpdrachtgeverAntwoorden.fromJson(json.opdrachtgever),
      Antwoord.fromJson<string>(json.coordinatorGegevensGelijk),
      ContactpersoonAntwoorden.fromJson(json.coordinator),
      Antwoord.fromJson<string>(json.werkzaamBij)
    );
  }
}
