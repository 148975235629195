import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class BiologischeAgentiaWerkzaamhedenAntwoorden extends AntwoordBlok {
  constructor(
    public startDatum: Antwoord<string>,
    public eersteKennisgeving: Antwoord<string>,
    public diagnostischeAard: Antwoord<string>,
    public humaanPathogeen: Antwoord<string>,
    public soortenBiologischeAgentia: Antwoord<string[]>,
    public risicoInventarisatieUitgevoerd: Antwoord<string>,
    public wanneerRieUitgevoerd: Antwoord<string>,
    public preventieveBeheersmaatregelen: Antwoord<string>,
    public overigeOpmerkingen: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): BiologischeAgentiaWerkzaamhedenAntwoorden {
    return new BiologischeAgentiaWerkzaamhedenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): BiologischeAgentiaWerkzaamhedenAntwoorden {
    return new BiologischeAgentiaWerkzaamhedenAntwoorden(
      Antwoord.fromJson<string>(json.startDatum),
      Antwoord.fromJson<string>(json.eersteKennisgeving),
      Antwoord.fromJson<string>(json.diagnostischeAard),
      Antwoord.fromJson<string>(json.humaanPathogeen),
      Antwoord.fromJson<string[]>(json.soortenBiologischeAgentia),
      Antwoord.fromJson<string>(json.risicoInventarisatieUitgevoerd),
      Antwoord.fromJson<string>(json.wanneerRieUitgevoerd),
      Antwoord.fromJson<string>(json.preventieveBeheersmaatregelen),
      Antwoord.fromJson<string>(json.overigeOpmerkingen),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson)
    );
  }
}
