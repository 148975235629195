import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MainContent } from "../../main-content";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";

export const GeenAutorisatie: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <Row className="main-container">
      <Col xs={12} className="main-container-title"></Col>
      <Col xs={12} className="main-container-content">
        <MainContent>
          <div className="main-form">
            <h2>{t(translationKeys.auth.geenAutorisatie)}</h2>
          </div>
        </MainContent>
      </Col>
    </Row>
  );
};
