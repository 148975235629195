import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class ArieMeldingAntwoorden extends AntwoordBlok {
  constructor(
    public ariePlichtigMelding: Antwoord<string>,
    public ariePlichtigRedenAfmelding: Antwoord<string>,
    public ariePlichtigAfmeldingOverigeReden: Antwoord<string>
  ) {
    super();
  }

  static initialize(): ArieMeldingAntwoorden {
    return new ArieMeldingAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): ArieMeldingAntwoorden {
    return new ArieMeldingAntwoorden(
      Antwoord.fromJson<string>(json.ariePlichtigMelding),
      Antwoord.fromJson<string>(json.ariePlichtigRedenAfmelding),
      Antwoord.fromJson<string>(json.ariePlichtigAfmeldingOverigeReden)
    );
  }
}
