export enum Formuliertype {
  autorisatieaanvraag = "autorisatieaanvraag",
  autorisatiesZoeken = "autorisatiesZoeken",
  autorisatieProfiel = "autorisatieProfiel",
  werkbak = "werkbak",
  ontheffingLiften = "ontheffingLiften",
  duikarbeid = "duikarbeid",
  vuurOpTankschip = "vuurOpTankschip",
  asbestRisicoklasse1 = "asbestRisicoklasse1",
  contact = "contact",
  klacht = "klacht",
  klachten = "klachten",
  bouwproces = "bouwproces",
  ongeval = "ongeval",
  profiel = "profiel",
  biologischeAgentia = "biologischeAgentia",
  vluchtigeOrganischeStoffen = "vluchtigeOrganischeStoffen",
  ontheffingNachtarbeid = "ontheffingNachtarbeid",
  productveiligheid = "productveiligheid",
  reactieOpenbaarmaking = "reactieOpenbaarmaking",
  digipass = "digipass",
  uploadGeneriek = "uploadGeneriek",
  uploadRie = "uploadRie",
  cbi = "cbi",
  ontheffingKinderarbeid = "ontheffingKinderarbeid",
  asbestverwijderingLavs = "asbestverwijderingLavs",
  asbestverwijderingLavsV2 = "asbestverwijderingLavsV2",
  asbestverwijdering = "asbestverwijdering",
  aanwijzingRisicovolleBedrijven = "aanwijzingRisicovolleBedrijven",
  bergplaatsRadioactieveStoffen = "bergplaatsRadioactieveStoffen",
  gevaarlijkeGassenImportcontainers = "gevaarlijkeGassenImportcontainers",
  gevaarlijkeLift = "gevaarlijkeLift",
  handelingenIoniserendeStraling = "handelingenIoniserendeStraling",
  ongewoneVoorvallen = "ongewoneVoorvallen",
  arie = "arie",
  douane = "douane",
  bibob = "bibob",
  informatieverzoek = "informatieverzoek",
  woo = "woo",
  verzoekTotGezamenlijkeInterventie = "verzoekTotGezamenlijkeInterventie",
  predicaatKoninklijk = "predicaatKoninklijk",
  ontheffingVuurOpTankschip = "ontheffingVuurOpTankschip",
  avvartikel10 = "avvArtikel10",
  avvArtikel10AVerzoek = "avvArtikel10AVerzoek"
}
