import { AntwoordBlok } from ".";
import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AdresAntwoorden } from "./adres-antwoorden";

export class BuitenlandsAdresAntwoorden extends AntwoordBlok {
  constructor(public adres: AdresAntwoorden | null, public land: Antwoord<string>, json?: any) {
    super(json);
  }
  static initialize(user: User | null = null): BuitenlandsAdresAntwoorden {
    return new BuitenlandsAdresAntwoorden(AdresAntwoorden.initialize(user), new Antwoord<string>(user?.bedrijfLand ?? ""));
  }
  static fromJson(json: any): BuitenlandsAdresAntwoorden | null {
    return json
      ? new BuitenlandsAdresAntwoorden(AdresAntwoorden.fromJson(json.adres), Antwoord.fromJson<string>(json.land), json)
      : null;
  }
}
