import { TeVerwijderenAsbestMateriaalLavs } from "./te-verwijderen-asbest-materiaal-lavs";
import { TeVerwijderenAsbestMateriaalLavsV2 } from "./te-verwijderen-asbest-materiaal-lavs-v2";
import { TeVerwijderenAsbestMateriaalRisicoklasse1 } from "./te-verwijderen-asbest-materiaal-risicoklasse1";

const samengesteldeVraagComponenten = {
  TeVerwijderenAsbestMateriaalRisicoklasse1,
  TeVerwijderenAsbestMateriaalLavs,
  TeVerwijderenAsbestMateriaalLavsV2
};

export { samengesteldeVraagComponenten as SamengesteldeVraagComponenten };
