import { Kindgegevens } from "components/blok/kindgegevens/";
import {
  CollectionEventsProvider,
  CollectionItemCanBeCopiedResult,
  CollectionItemCanBeRemovedResult
} from "components/blok/vraag-blokken-collection/";
import { FormulierInputControls } from "components/formulier";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { displayValues, translationKeys } from "../../../../constants";
import { dateHelpers, nameHelpers, vraagBlokCollectionHelpers } from "../../../../helpers";
import {
  AntwoordBlok,
  AntwoordBlokCollection,
  KinderenVragen,
  KindGegevensAntwoorden,
  KindGegevensVragen,
  VraagBlok,
  VraagBlokCollection,
  WerkmomentenVragen,
  WerkmomentGegevensAntwoorden
} from "../../../../models/api";
import { ProcesstapType, VraagBlokCollectionType } from "../../../../models/application";
import { State } from "../../../../store";
import { KinderenGegevensStateProps } from "./kinderen-gegevens.interfaces";

const formatGeboorteDatum = (antwoordBlok: AntwoordBlok) => {
  const antwoorden = antwoordBlok as KindGegevensAntwoorden;

  return antwoorden.geboorteDatum?.waarde && dateHelpers.isValidDate(antwoorden.geboorteDatum.waarde)
    ? dateHelpers.formatDate(antwoorden.geboorteDatum?.waarde)
    : displayValues.noValue;
};

const formatNummer = (antwoordBlok: AntwoordBlok) => {
  const antwoorden = antwoordBlok as KindGegevensAntwoorden;
  return antwoorden.bsn?.waarde || antwoorden.kindVolgnummer?.waarde;
};

const formatAchternaam = (antwoordBlok: AntwoordBlok) => {
  const antwoorden = antwoordBlok as KindGegevensAntwoorden;
  const achternaam = antwoorden.achternaam?.waarde;
  const tussenvoegsels = antwoorden.tussenvoegsels?.waarde;

  return (
    nameHelpers.getSamengesteldAchternaam({
      tussenvoegsels,
      achternaam
    }) ?? ""
  );
};

const mapStateToProps = (state: State): KinderenGegevensStateProps => {
  const kinderenVragen = state.vragen.formulier?.blokken[ProcesstapType.kinderen] as KinderenVragen;
  const werkmomentenVragen = state.vragen.formulier?.blokken[ProcesstapType.werkmomenten] as WerkmomentenVragen;

  return {
    antwoordBlokken: state.antwoorden.blokken,
    kindVraagBlokCollectionProperty: kinderenVragen.kinderenGegevensLijst.key,
    werkmomentVraagBlokCollectionProperty: werkmomentenVragen.werkmomentenGegevensLijst.key
  };
};

export class KinderenGegevens {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.kindGegevens;

  static createKinderenGegevens =
    (vraagBlokCollection: VraagBlokCollection<VraagBlok>) => (props: KinderenGegevensStateProps) => {
      const { t } = useTranslation();

      const canKindBeRemoved = (_key: string, index: number): CollectionItemCanBeRemovedResult => {
        let canBeRemoved = true;
        const reason = t(translationKeys.blok.kinderen.kinderenLijst.validatie.verwijderen);

        const kind = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemByIndex(
          index,
          props.kindVraagBlokCollectionProperty,
          props.antwoordBlokken
        ) as KindGegevensAntwoorden;
        const werkmomenten = vraagBlokCollectionHelpers.getAntwoordBlokCollection(
          props.werkmomentVraagBlokCollectionProperty,
          props.antwoordBlokken
        ) as AntwoordBlokCollection<WerkmomentGegevensAntwoorden>;

        if (kind && werkmomenten) {
          canBeRemoved = !werkmomenten.list.some((x) => x.kind.waarde === kind.id);
        }

        return { canBeRemoved: canBeRemoved, reason: reason };
      };

      const canKindBeCopied = (
        _key: string,
        _index: number,
        kind: KindGegevensAntwoorden
      ): CollectionItemCanBeCopiedResult => {
        let canBeCopied = true;
        const nee = "nee";
        const reason = t(translationKeys.blok.kinderen.kinderenLijst.validatie.kopieren);

        if (kind) {
          canBeCopied = kind.heeftKindBsn.waarde === nee;
        }

        return { canBeCopied, reason: reason };
      };

      return (
        <Fragment>
          <CollectionEventsProvider canBeRemoved={canKindBeRemoved} canBeCopied={canKindBeCopied}>
            <FormulierInputControls.CollectionGrid
              itemComponent={Kindgegevens}
              getVraagBlokFromTemplate={KindGegevensVragen.fromJson}
              initializeAntwoorden={KindGegevensAntwoorden.initialize}
              copyAntwoorden={KindGegevensAntwoorden.copyFromJson}
              vraagBlokCollection={vraagBlokCollection}
              getCollectionItemSummary={(antwoordBlok: AntwoordBlok) => {
                const antwoorden = antwoordBlok as KindGegevensAntwoorden;
                return t(translationKeys.blok.kinderen.kind.samengesteldeWaarde, {
                  nummer: formatNummer(antwoorden) ?? "",
                  achternaam: antwoorden.achternaam?.waarde ?? "",
                  geboorteDatum: formatGeboorteDatum(antwoorden) ?? ""
                });
              }}
              pagingSettings={{
                numberOfItemsPerPage: 10,
                getTotalsDescription: (counters) =>
                  counters.totalItemsInGrid > counters.filteredItemsInGrid
                    ? t(translationKeys.blok.kinderen.kinderenLijst.paginering.totaalAantalGefilterd, counters)
                    : t(translationKeys.blok.kinderen.kinderenLijst.paginering.totaalAantal, counters)
              }}
              columnSettings={{
                columns: {
                  nummer: { columnFormatter: formatNummer },
                  geboorteDatum: {
                    columnFormatter: formatGeboorteDatum,
                    columnComparer: dateHelpers.displayDateCompare
                  },
                  roepnaam: {},
                  achternaam: { columnFormatter: formatAchternaam }
                },
                translationBase: translationKeys.blok.kinderen.kinderenLijst.tabelkop
              }}
              defaultNewestOnTop={true}
            />
          </CollectionEventsProvider>
        </Fragment>
      );
    };

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): any =>
    React.createElement(connect(mapStateToProps)(KinderenGegevens.createKinderenGegevens(vraagBlokCollection)));
}
