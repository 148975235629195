import { connect } from "react-redux";
import { HasAutorisatiesZoekenVragen } from "../../../models/api";
import { State } from "../../../store";
import { AutorisatiesZoeken } from "./autorisaties-zoeken.component";
import { AutorisatiesZoekenStateProps } from "./autorisaties-zoeken.component.interfaces";

const mapStateToProps = (state: State): AutorisatiesZoekenStateProps => ({
  filter: (state.vragen.formulier?.blokken as unknown as HasAutorisatiesZoekenVragen)?.filter
});

export default connect(mapStateToProps)(AutorisatiesZoeken);
