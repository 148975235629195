import { Bestandenvraag, Tekst } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class ToedrachtVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public toedrachtTitel: Tekst,
    public ongevalWerkzaamhedenToelichtingParagraaf: Tekst,
    public toedrachtZwaarOngeval: Vraag,
    public werkzaamhedenArbeidsongeval: Vraag,
    public arbeidsmiddel: Meerkeuzevraag,
    public arbeidsmiddelWatVoor: Vraag,
    public arbeidsmiddelMerkType: Vraag,
    public gevaarlijkeStof: Meerkeuzevraag,
    public gevaarlijkeStofWelke: Vraag,
    public brzoGevaarlijkeStofWelke: Vraag,
    public brzoGevaarlijkeStofHoeveelheid: Vraag,
    public brzoGevaarlijkeStofGevolgen: Vraag,
    public brzoGevaarlijkeStofMaatregelen: Vraag,
    public brzoGevaarlijkeStofMaatregelenHerhaling: Vraag,
    public brzoGevaarlijkeStofSchadeBedrag: Vraag,
    public watIsErGebeurd: Vraag,
    public veranderingenAangebracht: Meerkeuzevraag,
    public ongevalReconstrueren: Meerkeuzevraag,
    public beeldmateriaal: Meerkeuzevraag,
    public beeldmateriaalBewarenParagraaf: Tekst,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public beeldmateriaalBestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ToedrachtVragen {
    return new ToedrachtVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.toedrachtTitel`, json.toedrachtTitel),
      jsonHelpers.toParagraaf(
        `${key}.ongevalWerkzaamhedenToelichtingParagraaf`,
        json.ongevalWerkzaamhedenToelichtingParagraaf
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.toedrachtZwaarOngeval`, json.toedrachtZwaarOngeval),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.werkzaamhedenArbeidsongeval`, json.werkzaamhedenArbeidsongeval),
      jsonHelpers.toMeerkeuzeVraag(`${key}.arbeidsmiddel`, json.arbeidsmiddel),
      jsonHelpers.toTekstVraag(`${key}.arbeidsmiddelWatVoor`, json.arbeidsmiddelWatVoor),
      jsonHelpers.toTekstVraag(`${key}.arbeidsmiddelMerkType`, json.arbeidsmiddelMerkType),
      jsonHelpers.toMeerkeuzeVraag(`${key}.gevaarlijkeStof`, json.gevaarlijkeStof),
      jsonHelpers.toTekstVraag(`${key}.gevaarlijkeStofWelke`, json.gevaarlijkeStofWelke),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.brzoGevaarlijkeStofWelke`, json.brzoGevaarlijkeStofWelke),
      jsonHelpers.toTekstVraag(`${key}.brzoGevaarlijkeStofHoeveelheid`, json.brzoGevaarlijkeStofHoeveelheid),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.brzoGevaarlijkeStofGevolgen`, json.brzoGevaarlijkeStofGevolgen),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.brzoGevaarlijkeStofMaatregelen`, json.brzoGevaarlijkeStofMaatregelen),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.brzoGevaarlijkeStofMaatregelenHerhaling`,
        json.brzoGevaarlijkeStofMaatregelenHerhaling
      ),
      jsonHelpers.toTekstVraag(`${key}.brzoGevaarlijkeStofSchadeBedrag`, json.brzoGevaarlijkeStofSchadeBedrag),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.watIsErGebeurd`, json.watIsErGebeurd),
      jsonHelpers.toMeerkeuzeVraag(`${key}.veranderingenAangebracht`, json.veranderingenAangebracht),
      jsonHelpers.toMeerkeuzeVraag(`${key}.ongevalReconstrueren`, json.ongevalReconstrueren),
      jsonHelpers.toChecklistvraag(`${key}.beeldmateriaal`, json.beeldmateriaal),
      jsonHelpers.toWaarschuwing(`${key}.beeldmateriaalBewarenParagraaf`, json.beeldmateriaalBewarenParagraaf),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.beeldmateriaalBestanden`, json.beeldmateriaalBestanden)
    );
  }
}
