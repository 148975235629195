import { InputCheck, InputCheckOption } from "components/input-controls/input-check";
import { InputText } from "components/input-controls/input-text";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { FormulierMetadata } from "../../../models/api/formulier-metadata";
import { MeldingFilters } from "../../../models/api/zoek";
import { Formuliercategorie } from "../../../models/application/formulier";
import { ButtonWithLoader } from "../../button-with-loader";
import { InputDate } from "../../input-controls/input-date";
import { InputNumber } from "../../input-controls/input-number";
import { InputAsyncTypeahead } from "../../input-controls/input-typeahead";

const FormuliertypenPresetSelector = (props: {
  formulierTypes: FormulierMetadata[];
  formulierTypeFilter: string;
  buttonText: string;
  setFieldValue: any;
}) => (
  <Button
    id={`presetButton-${props.formulierTypeFilter}`}
    className="me-2"
    variant="outline-primary"
    onClick={() => {
      props.setFieldValue(
        "formuliertypen",
        props.formulierTypes.filter((f) => f.formuliertype.includes(props.formulierTypeFilter)).map((f) => f.formuliertype)
      );
    }}
  >
    {props.buttonText}
  </Button>
);

export const ZoekMeldingenFilter = (props: {
  formulierTypes: FormulierMetadata[];
  isLoading: boolean;
  onSearch: (filter: MeldingFilters) => void;
  onReset: () => void;
  plaatsen: string[];
  onSearchPlaatsen: (naam: string) => void;
}) => {
  const { t, i18n } = useTranslation();

  const onSubmit = (values: any) => {
    props.onSearch(
      new MeldingFilters(
        values.formuliercategorie,
        values.formuliertypen,
        values.meldingnummer,
        values.postcode,
        values.plaats,
        values.ingediendVanaf,
        values.ingediendTotEnMet,
        values.activiteitVanaf,
        values.activiteitTotEnMet
      )
    );
  };

  return (
    <Formik
      initialValues={{
        formuliertypen: [],
        formuliercategorie: Formuliercategorie.Kennisgeving,
        meldingnummer: "",
        plaats: "",
        postcode: "",
        ingediendVanaf: "",
        ingediendTotEnMet: "",
        activiteitVanaf: "",
        activiteitTotEnMet: ""
      }}
      enableReinitialize={false}
      onSubmit={onSubmit}
      onReset={props.onReset}
    >
      {({ handleSubmit, handleReset, setFieldValue }) => (
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <FormGroup className="form-group">
            <div className="label-container">
              <label className="form-label">{t(translationKeys.zoek.meldingen.criteria.snelfilter)}</label>
            </div>
            <div className="input-container">
              <FormuliertypenPresetSelector
                formulierTypes={props.formulierTypes}
                formulierTypeFilter="asbest"
                buttonText={t(translationKeys.zoek.meldingen.criteria.snelfilterasbest)}
                setFieldValue={setFieldValue}
              ></FormuliertypenPresetSelector>
            </div>
          </FormGroup>

          <Field
            name="formuliertypen"
            as={InputCheck}
            label={t(translationKeys.zoek.meldingen.criteria.meldingtype)}
            containerClass="columns-md-2"
          >
            {props.formulierTypes.map((ftMeta) => (
              <InputCheckOption
                optionValue={ftMeta.formuliertype}
                label={ftMeta.titel[i18n.language]}
                id={`option-${ftMeta.formuliertype}`}
                name="formuliertypen"
                key={`option-${ftMeta.formuliertype}`}
              />
            ))}
          </Field>

          <Field name="meldingnummer" as={InputNumber} label={t(translationKeys.zoek.meldingen.criteria.meldingnummer)} />
          <Field
            name="plaats"
            as={InputAsyncTypeahead}
            label={t(translationKeys.zoek.meldingen.criteria.plaatsLocatie)}
            options={props.plaatsen}
            isLoading={props.isLoading}
            onSearch={props.onSearchPlaatsen}
          />
          <Field name="postcode" as={InputText} label={t(translationKeys.zoek.meldingen.criteria.postcodeLocatie)} />
          <Field name="ingediendVanaf" as={InputDate} label={t(translationKeys.zoek.meldingen.criteria.ingediendVanaf)} />
          <Field
            name="ingediendTotEnMet"
            as={InputDate}
            label={t(translationKeys.zoek.meldingen.criteria.ingediendTotEnMet)}
          />
          <Field name="activiteitVanaf" as={InputDate} label={t(translationKeys.zoek.meldingen.criteria.activiteitVanaf)} />
          <Field
            name="activiteitTotEnMet"
            as={InputDate}
            label={t(translationKeys.zoek.meldingen.criteria.activiteitTotEnMet)}
          />

          <div className="mb-3 button-bar">
            <ButtonWithLoader id="resetButton" className="me-2" isVisible={true} isLoading={props.isLoading} type="reset">
              {t(translationKeys.zoek.meldingen.reset)}
            </ButtonWithLoader>
            <ButtonWithLoader id="zoekButton" isVisible={true} isLoading={props.isLoading} type="submit">
              {t(translationKeys.zoekenButton)}
            </ButtonWithLoader>
          </div>
        </Form>
      )}
    </Formik>
  );
};
