import { Autorisatiegegevens } from "../security/autorisatiegegevens";
import { AutorisatiegegevensExpression } from "./autorisatiegegevens-expression";

export class IsBedrijfIngeschreven extends AutorisatiegegevensExpression {
  get operator(): string {
    return IsBedrijfIngeschreven.operator();
  }

  executeAutorisation(autorisatiegegevens: Autorisatiegegevens): boolean {
    return autorisatiegegevens.isBedrijfIngeschreven;
  }

  static fromJson = (): IsBedrijfIngeschreven => new IsBedrijfIngeschreven();

  static operator = (): string => "isBedrijfIngeschreven";

  static matches = (json: any): boolean => json?.operator === IsBedrijfIngeschreven.operator();
}
