export const xhrReadyState = {
  // Client has been created. open() not called yet.
  unsent: 0,
  // open() has been called.
  opened: 1,
  // send() has been called, and headers and status are available.
  headersReceived: 2,
  // Downloading; responseText holds partial data.
  loading: 3,
  // The operation is complete.
  done: 4
};
