import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { MogelijkAntwoord } from "../../../models/api";
import { Field } from "../field";
import { InputOptionWithToelichting } from "../input-option-with-toelichting";
import { InputRadioOption } from "./input-radio-option/input-radio-option";

const RadioOption = (props: { ma: MogelijkAntwoord; readOnly: boolean; name: string; onChange?: (e: any) => void }) => {
  const { i18n } = useTranslation();

  const lang: string = i18n.language;

  return (
    <InputRadioOption
      id={`${props.name}.${props.ma.waarde}`}
      name={props.name}
      key={props.ma.waarde}
      label={props.ma.tekst[lang] ?? props.ma.waarde}
      optionValue={props.ma.waarde}
      readOnly={props.readOnly}
      disabled={props.readOnly}
      className={props.ma.toelichting?.[lang] ? "radio-option-with-toelichting" : null}
      onChange={props.onChange}
    />
  );
};

const RadioOptionWithToelichting = (props: {
  ma: MogelijkAntwoord;
  readOnly: boolean;
  name: string;
  onChange?: (e: any) => void;
}) => {
  return (
    <InputOptionWithToelichting mogelijkAntwoord={props.ma} key={`${props.ma.waarde}--option-with-toelichting`}>
      <RadioOption ma={props.ma} readOnly={props.readOnly} name={props.name} onChange={props.onChange} />
    </InputOptionWithToelichting>
  );
};

export const InputRadio = (props: any) => {
  const { name, readOnly, disabled, mogelijkeAntwoorden, onBlur, onChange, ...propsRest } = props;

  const { i18n } = useTranslation();

  const lang: string = i18n.language;

  return (
    <Field {...propsRest} name={name} groupId={name}>
      <div className="radio-container">
        {props.children
          ? props.children.map((c: ReactElement) =>
              React.cloneElement(c, {
                name: c.props.name ?? name,
                readOnly: c.props.readOnly ?? readOnly
              })
            )
          : mogelijkeAntwoorden.map((ma: MogelijkAntwoord, i: number) =>
              ma.toelichting?.[lang] ? (
                <RadioOptionWithToelichting key={i} ma={ma} readOnly={readOnly} name={name} />
              ) : (
                <RadioOption key={i} ma={ma} onChange={onChange} readOnly={readOnly} name={name} />
              )
            )}
      </div>
    </Field>
  );
};
