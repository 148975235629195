import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { GetuigenInformatieVragen } from "./getuigen-informatie-vragen";

export class GetuigenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public getuigenAanwezig: Meerkeuzevraag,
    public aantalGetuigen: Vraag,
    public gegevensGetuigenBekend: Vraag,
    public getuigenInformatieLijst: VraagBlokCollection<GetuigenInformatieVragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): GetuigenVragen {
    return new GetuigenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.getuigenAanwezig`, json.getuigenAanwezig),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalGetuigen`, json.aantalGetuigen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.gegevensGetuigenBekend`, json.gegevensGetuigenBekend),
      VraagBlokCollection.fromJson<GetuigenInformatieVragen>(
        GetuigenInformatieVragen.fromJson,
        `${key}.getuigenInformatieLijst`,
        json.getuigenInformatieLijst,
        VraagBlokCollectionType.getuigen
      )
    );
  }
}
