import { Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants";
import { AutorisatiesZoekenFormulier } from "../../formulier/autorisaties-zoeken";
import { AutorisatiesZoekbalkOwnProps } from "./autorisaties-zoekbalk.component.interfaces";

export const AutorisatiesZoekbalk = (props: AutorisatiesZoekbalkOwnProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Row className="main-container zoekbalk">
        <Col xs={12} className="main-container-content">
          <div className="main-content">
            <Button as="a" href="/autorisatieaanvraag" role="button">
              {t(translationKeys.beheer.autorisaties.nieuw)}
            </Button>
          </div>
        </Col>
      </Row>
      <AutorisatiesZoekenFormulier submitMelding={props.onSubmit} />
    </Fragment>
  );
};
