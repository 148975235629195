import { Antwoord } from "../antwoord";
import { AntwoordBlokCollectionItem } from "./antwoord-blok-collection-item";

export class AsbestinventarisatieMateriaalAntwoorden extends AntwoordBlokCollectionItem {
  static get type(): string {
    return "AsbestinventarisatieMateriaalAntwoorden";
  }

  constructor(
    public risicoklasse: Antwoord<string>,
    public hoeveelheid: Antwoord<string>,
    public eenheid: Antwoord<string>,
    public soortMateriaal: Antwoord<string>,
    json?: any
  ) {
    super(AsbestinventarisatieMateriaalAntwoorden.type, json);
  }

  static initialize(): AsbestinventarisatieMateriaalAntwoorden {
    return new AsbestinventarisatieMateriaalAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): AsbestinventarisatieMateriaalAntwoorden {
    return new AsbestinventarisatieMateriaalAntwoorden(
      Antwoord.fromJson<string>(json.risicoklasse),
      Antwoord.fromJson<string>(json.hoeveelheid),
      Antwoord.fromJson<string>(json.eenheid),
      Antwoord.fromJson<string>(json.soortMateriaal),
      json
    );
  }

  static copyFromJson(json: any) {
    return AsbestinventarisatieMateriaalAntwoorden.fromJson({
      ...json,
      ...AntwoordBlokCollectionItem.copyAsNewInstance(json)
    });
  }
}
