import { Antwoord } from "./../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { NederlandsAdresAntwoorden } from "./nederlands-adres-antwoorden";

export class LocatieAntwoorden extends AntwoordBlok {
  constructor(public nederlandsAdres: NederlandsAdresAntwoorden | null, public omschrijving: Antwoord<string>) {
    super();
  }

  static initialize(): LocatieAntwoorden {
    return new LocatieAntwoorden(NederlandsAdresAntwoorden.initialize(), new Antwoord<string>(""));
  }

  static fromJson(json: any): LocatieAntwoorden | null {
    return json
      ? new LocatieAntwoorden(
          NederlandsAdresAntwoorden.fromJson(json.nederlandsAdres),
          Antwoord.fromJson<string>(json.omschrijving)
        )
      : null;
  }
}
