import { Tekst } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class AutorisatieGeldigheidVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public accountGeldigheidTitel: Tekst,
    public geldig: Meerkeuzevraag,
    public redenOngeldig: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AutorisatieGeldigheidVragen {
    return new AutorisatieGeldigheidVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.accountGeldigheidTitel`, json.accountGeldigheidTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.geldig`, json.geldig),
      jsonHelpers.toTekstVraag(`${key}.redenOngeldig`, json.redenOngeldig)
    );
  }
}
