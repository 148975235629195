import { BedrijfslocatiegegevensVragen, ProductgegevensVragen, ProductveiligheidMelderVragen, VraagBlokken } from "../blok";
import { HasFabrikantBedrijfsgegevensVragen } from "./has-fabrikant-bedrijfsgegevens-vragen";
import { HasLeverancierBedrijfsgegevensVragen } from "./has-leverancier-bedrijfsgegevens-vragen";
import { HasLocatieBedrijfsgegevensVragen } from "./has-locatie-bedrijfsgegevens-vragen";
import { HasProductveiligheidMelderVragen } from "./has-productveiligheid-melder-vragen";

export class ProductveiligheidFormulierVraagBlokken
  extends VraagBlokken
  implements
    HasProductveiligheidMelderVragen,
    HasFabrikantBedrijfsgegevensVragen,
    HasLeverancierBedrijfsgegevensVragen,
    HasLocatieBedrijfsgegevensVragen
{
  [key: string]: any;

  constructor(
    public melder: ProductveiligheidMelderVragen,
    public fabrikant: BedrijfslocatiegegevensVragen,
    public leverancier: BedrijfslocatiegegevensVragen,
    public locatie: BedrijfslocatiegegevensVragen,
    public productgegevens: ProductgegevensVragen
  ) {
    super();
  }

  static fromJson(json: any): ProductveiligheidFormulierVraagBlokken {
    return new ProductveiligheidFormulierVraagBlokken(
      ProductveiligheidMelderVragen.fromJson("melder", json.melder),
      BedrijfslocatiegegevensVragen.fromJson("fabrikant", json.fabrikant),
      BedrijfslocatiegegevensVragen.fromJson("leverancier", json.leverancier),
      BedrijfslocatiegegevensVragen.fromJson("locatie", json.locatie),
      ProductgegevensVragen.fromJson("productgegevens", json.productgegevens)
    );
  }
}
