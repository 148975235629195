import { Formuliertype } from "../../application";
import { AvvArtikel10FormulierVraagBlokken } from "./avv-artikel10-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AvvArtikel10Formulier extends FormulierBase {
  constructor(public blokken: AvvArtikel10FormulierVraagBlokken, json: any) {
    super(Formuliertype.avvartikel10, blokken, json);
  }

  static fromJson(json: any): AvvArtikel10Formulier {
    return new AvvArtikel10Formulier(AvvArtikel10FormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
