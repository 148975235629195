import { Configuration } from "configuration";
import { MILLISECONDS_PER_SECOND } from "constants/timing";
import { MutableRefObject, useEffect, useRef } from "react";
import { SecurityExpirationHandlerProps } from "./security-expiration-handler.component.interfaces";

const setTimer = (
  timerRef: MutableRefObject<NodeJS.Timeout | null>,
  executeAfter: number,
  excecuteFunction: () => void
): NodeJS.Timeout | null => {
  if (executeAfter > 0) {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      excecuteFunction();
    }, executeAfter * MILLISECONDS_PER_SECOND);
  }
  return timerRef.current;
};

export const SecurityExpirationHandler: React.FC<SecurityExpirationHandlerProps> = (props) => {
  const { onEndSession, onExtendSession, user } = props;
  let expiresTimeout = useRef<NodeJS.Timeout | null>(null);
  let renewTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let renewTimer: NodeJS.Timeout | null;
    let expireTimer: NodeJS.Timeout | null;
    if (user != null) {
      let expiresIn = user.expiresIn;
      const renewIn = user.expiresIn - Configuration.refreshCookieWhenExpiringInSeconds;
      if (renewIn <= 0) {
        expiresIn = 0;
      }

      renewTimer = setTimer(renewTimeout, renewIn, onExtendSession);
      expireTimer = setTimer(expiresTimeout, expiresIn, onEndSession);
    }

    return () => {
      if (renewTimer) {
        clearInterval(renewTimer);
      }
      if (expireTimer) {
        clearInterval(expireTimer);
      }
    };
  }, [user, expiresTimeout, renewTimeout, onEndSession, onExtendSession]);

  return <></>;
};
