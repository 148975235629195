import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class PeriodeDatumTijdAntwoorden extends AntwoordBlok {
  constructor(public begindatumtijd: Antwoord<string>, public einddatumtijd: Antwoord<string>) {
    super();
  }

  static initialize() {
    return new PeriodeDatumTijdAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any) {
    return new PeriodeDatumTijdAntwoorden(Antwoord.fromJson(json.begindatumtijd), Antwoord.fromJson(json.einddatumtijd));
  }
}
