import {
  GetuigenVragen,
  MeldingsplichtToedrachtVragen,
  OngevalMelderVragen,
  SlachtoffersVragen,
  VraagBlokken,
  WerkgeverLocatieVragen
} from "../blok";
import { HasOngevalMelderVragen } from "./has-ongeval-melder-vragen";

export class OngevalFormulierVraagBlokken extends VraagBlokken implements HasOngevalMelderVragen {
  [key: string]: any;

  constructor(
    public meldingsplichtToedracht: MeldingsplichtToedrachtVragen,
    public slachtoffers: SlachtoffersVragen,
    public getuigen: GetuigenVragen,
    public melder: OngevalMelderVragen,
    public werkgeverLocatie: WerkgeverLocatieVragen
  ) {
    super();
  }

  static fromJson(json: any): OngevalFormulierVraagBlokken {
    return new OngevalFormulierVraagBlokken(
      MeldingsplichtToedrachtVragen.fromJson("meldingsplichtToedracht", json.meldingsplichtToedracht),
      SlachtoffersVragen.fromJson("slachtoffers", json.slachtoffers),
      GetuigenVragen.fromJson("getuigen", json.getuigen),
      OngevalMelderVragen.fromJson("melder", json.melder),
      WerkgeverLocatieVragen.fromJson("werkgeverLocatie", json.werkgeverLocatie)
    );
  }
}
