import { Autorisatiegegevens } from "../security/autorisatiegegevens";
import { AutorisatiegegevensExpression } from "./autorisatiegegevens-expression";

export class IsIngevoerdDoorInterneMedewerker extends AutorisatiegegevensExpression {
  get operator(): string {
    return IsIngevoerdDoorInterneMedewerker.operator();
  }

  executeAutorisation(autorisatiegegevens: Autorisatiegegevens): boolean {
    return autorisatiegegevens.isInterneMedewerker;
  }

  static fromJson = (): IsIngevoerdDoorInterneMedewerker => new IsIngevoerdDoorInterneMedewerker();

  static operator = (): string => "isIngevoerdDoorInterneMedewerker";

  static matches = (json: any): boolean => json?.operator === IsIngevoerdDoorInterneMedewerker.operator();
}
