import { connect } from "react-redux";
import { EMPTY_VRAAG_ANTWOORDBLOKKEN } from "reducers/collections-reducer";
import { State } from "store";
import { CollectionAccordionBody } from "./collection-accordion-body.component";
import {
  CollectionAccordionBodyOwnProps,
  CollectionAccordionBodyStateProps
} from "./collection-accordion-body.component.interfaces";

const mapStateToProps = (state: State, props: CollectionAccordionBodyOwnProps): CollectionAccordionBodyStateProps => ({
  processtapType: state.proces.activeItem?.type,
  vraagBlokAntwoordIndexes:
    state.collections.list[props.collectionKey]?.vraagBlokkenWithAntwoordIndexes ?? EMPTY_VRAAG_ANTWOORDBLOKKEN
});

export default connect(mapStateToProps)(CollectionAccordionBody);
