import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { VuurOpTankschipFormulierVraagBlokken } from "./vuur-op-tankschip-formulier-vraag-blokken";

export class VuurOpTankschipFormulier extends FormulierBase {
  constructor(public blokken: VuurOpTankschipFormulierVraagBlokken, json: any) {
    super(Formuliertype.vuurOpTankschip, blokken, json);
  }

  static fromJson(json: any): VuurOpTankschipFormulier {
    return new VuurOpTankschipFormulier(VuurOpTankschipFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
