import { Formuliertype } from "../../application";
import { AutorisatiesZoekenFormulierVraagBlokken } from "./autorisaties-zoeken-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AutorisatiesZoekenFormulier extends FormulierBase {
  constructor(public blokken: AutorisatiesZoekenFormulierVraagBlokken, json: any) {
    super(Formuliertype.autorisatiesZoeken, blokken, json);
  }

  static fromJson(json: any): AutorisatiesZoekenFormulier {
    return new AutorisatiesZoekenFormulier(AutorisatiesZoekenFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
