import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { Breadcrumb } from "../breadcrumb";

export const MaintenanceBreadcrumbs = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
      />
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.onderhoud.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.onderhoud.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.onderhoud.titel)}
      />
    </Fragment>
  );
};
