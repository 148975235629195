import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsEmpty extends VraagExpression {
  get operator(): string {
    return IsEmpty.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const item = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;
    if (Array.isArray(item)) {
      return item.length === 0;
    }

    return item ? false : true;
  };

  protected clone = (instance: IsEmpty): IsEmpty => new IsEmpty(instance.vraagProperty);

  static fromJson = (json: any): IsEmpty => new IsEmpty(json.vraag);

  static operator = (): string => "isEmpty";

  static matches = (json: any): boolean => json?.operator === IsEmpty.operator();
}
