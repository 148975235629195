import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, KlachtMelderAntwoorden, KlachtMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initKlachtFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getKlachtFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getKlachtFormulierDefinitie,
      KlachtMeldingAntwoordBlokken.initialize
    );
  };

export const initKlachtFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getKlachtFormulier(),
      meldingApi.getKlachtMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): KlachtMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: KlachtMelderAntwoorden.initialize(user),
        klacht: {
          ...melding.blokken.klacht,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getKlachtFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
