import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { KennisgevingMeldingAntwoordBlokken } from "models/api";
import { AppThunk } from "thunks/app-thunk-type";
import { initKennisgevingMeldingAntwoordenFromCopy, initializeFormulierProces } from "thunks/thunk-helpers";

export const initBergplaatsRadioactieveStoffenFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getBergplaatsRadioactieveStoffenFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getBergplaatsRadioactieveStoffenFormulierDefinitie,
      KennisgevingMeldingAntwoordBlokken.initialize
    );
  };

export const initBergplaatsRadioactieveStoffenFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getBergplaatsRadioactieveStoffenFormulier(),
      meldingApi.getBergplaatsRadioactieveStoffenMelding(meldingId)
    ]);

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getBergplaatsRadioactieveStoffenFormulierDefinitie,
      initKennisgevingMeldingAntwoordenFromCopy(melding.blokken)
    );
  };
