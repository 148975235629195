import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { BinaryExpression } from "./binary-expression";
import { expressionParser } from "./expression-parser";

export class And extends BinaryExpression {
  get operator(): string {
    return And.operator();
  }

  execute = (vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    return this.left.execute(vraagBlok, context, vraagProperty) && this.right.execute(vraagBlok, context, vraagProperty);
  };

  static fromJson = (json: any): And => {
    const leftExpression = expressionParser.fromJson(json.left);
    const rightExpression = expressionParser.fromJson(json.right);

    if (!leftExpression) {
      throw Error("Left expression cannot be null.");
    }

    if (!rightExpression) {
      throw Error("Right expression cannot be null.");
    }

    return new And(leftExpression, rightExpression);
  };

  static operator = (): string => "and";

  static matches = (json: any): boolean => json?.operator === And.operator();
}
