import { jsonHelpers } from "helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { VraagBlokCollectionType } from "./../../application/vraag-blok-collection-type";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";
import { GegevensInstallatieVragen } from "./gegevens-installatie-vragen";
import { PersoonsgegevensVragen } from "./persoonsgegevens-vragen";

export class ArieLocatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public arieLocatieWijzigingDoorgeven: Meerkeuzevraag,
    public arieLocatieMeldingBedoeldVoorBrzo: Meerkeuzevraag,
    public naamInrichting: Vraag,
    public correspondentieadres: CorrespondentieadresVragen,
    public feitelijkLeidinggevende: PersoonsgegevensVragen,
    public medewerkingVerlenendeDeskundige: PersoonsgegevensVragen,
    public gegevensInstallatiesTitel: Tekst,
    public gegevensInstallaties: VraagBlokCollection<GegevensInstallatieVragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ArieLocatieVragen {
    return new ArieLocatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toChecklistvraag(`${key}.arieLocatieWijzigingDoorgeven`, json.arieLocatieWijzigingDoorgeven),
      jsonHelpers.toMeerkeuzeVraag(`${key}.arieLocatieMeldingBedoeldVoorBrzo`, json.arieLocatieMeldingBedoeldVoorBrzo),
      jsonHelpers.toTekstVraag(`${key}.naamInrichting`, json.naamInrichting),
      CorrespondentieadresVragen.fromJson(`${key}.correspondentieadres`, json.correspondentieadres),
      PersoonsgegevensVragen.fromJson(`${key}.feitelijkLeidinggevende`, json.feitelijkLeidinggevende),
      PersoonsgegevensVragen.fromJson(`${key}.medewerkingVerlenendeDeskundige`, json.medewerkingVerlenendeDeskundige),
      jsonHelpers.toTitel(`${key}.gegevensInstallatiesTitel`, json.gegevensInstallatiesTitel),
      VraagBlokCollection.fromJson<GegevensInstallatieVragen>(
        GegevensInstallatieVragen.fromJson,
        `${key}.gegevensInstallaties`,
        json.gegevensInstallaties,
        VraagBlokCollectionType.gegevensInstallaties
      )
    );
  }
}
