import { displayAntwoordHelpers } from "helpers";
import {
  AntwoordBlok,
  PeriodeMeldingAvvArtikel10Antwoorden,
  PeriodeMeldingAvvArtikel10Vragen,
  VraagBlok,
  VraagBlokCollection
} from "../../../../models/api";
import { VraagBlokCollectionType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";

export class Periode {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.periode;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => {
    return (
      <FormulierInputControls.CollectionAccordion
        itemComponent={GeneriekBlok.VraagBlok}
        getVraagBlokFromTemplate={PeriodeMeldingAvvArtikel10Vragen.fromJson}
        initializeAntwoorden={PeriodeMeldingAvvArtikel10Antwoorden.initialize}
        vraagBlokCollection={vraagBlokCollection}
        getCollectionItemSummary={(antwoordBlok: AntwoordBlok, index: number) =>
          displayAntwoordHelpers.getDisplayAntwoord(antwoordBlok as PeriodeMeldingAvvArtikel10Antwoorden)
        }
      />
    );
  };
}
