import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensLavsAntwoorden } from "./bedrijfsgegevens-lavs-antwoorden";
import { TeVerwijderenAsbestMateriaalLavsV2Antwoorden } from "./te-verwijderen-asbest-materiaal-lavs-v2-antwoorden";

export class InventarisatieLavsV2Antwoorden extends AntwoordBlok {
  constructor(
    public bedrijfsgegevens: BedrijfsgegevensLavsAntwoorden,
    public scaCode: Antwoord<string>,
    public rapportdatum: Antwoord<string>,
    public teVerwijderenAsbestMateriaal: Antwoord<TeVerwijderenAsbestMateriaalLavsV2Antwoorden[]>,
    public aantalPakdagen: Antwoord<string>,
    public gedeeltelijkeVerwijdering: Antwoord<TeVerwijderenAsbestMateriaalLavsV2Antwoorden[]>,
    public aantalPakdagenGedeeltelijkeVerwijdering: Antwoord<string>
  ) {
    super();
  }

  static initialize() {
    return new InventarisatieLavsV2Antwoorden(
      BedrijfsgegevensLavsAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<TeVerwijderenAsbestMateriaalLavsV2Antwoorden[]>([]),
      new Antwoord<string>(""),
      new Antwoord<TeVerwijderenAsbestMateriaalLavsV2Antwoorden[]>([]),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new InventarisatieLavsV2Antwoorden(
      BedrijfsgegevensLavsAntwoorden.fromJson(json.bedrijfsgegevens),
      Antwoord.fromJson(json.scaCode),
      Antwoord.fromJson(json.rapportdatum),
      Antwoord.fromJsonArray<TeVerwijderenAsbestMateriaalLavsV2Antwoorden>(
        json.teVerwijderenAsbestMateriaal,
        TeVerwijderenAsbestMateriaalLavsV2Antwoorden.fromJson
      ),
      Antwoord.fromJson(json.aantalPakdagen),
      Antwoord.fromJsonArray<TeVerwijderenAsbestMateriaalLavsV2Antwoorden>(
        json.gedeeltelijkeVerwijdering,
        TeVerwijderenAsbestMateriaalLavsV2Antwoorden.fromJson
      ),
      Antwoord.fromJson(json.aantalPakdagenGedeeltelijkeVerwijdering)
    );
  }
}
