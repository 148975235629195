import { Autorisatiegegevens } from "../security/autorisatiegegevens";
import { AutorisatiegegevensExpression } from "./autorisatiegegevens-expression";

export class IsIngelogd extends AutorisatiegegevensExpression {
  get operator(): string {
    return IsIngelogd.operator();
  }

  executeAutorisation(autorisatiegegevens: Autorisatiegegevens): boolean {
    return autorisatiegegevens.isIngelogd;
  }

  static fromJson = (): IsIngelogd => new IsIngelogd();

  static operator = (): string => "isIngelogd";

  static matches = (json: any): boolean => json?.operator === IsIngelogd.operator();
}
