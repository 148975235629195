import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";

export class LocatieProductAntwoorden extends AntwoordBlok {
  constructor(
    public bevindtProductZichOpLocatieVanMelder: Antwoord<string>,
    public productLocatie: BedrijfslocatiegegevensAntwoorden,
    public contactpersoon: ContactpersoonAntwoorden | null
  ) {
    super();
  }

  static initialize(): LocatieProductAntwoorden {
    return new LocatieProductAntwoorden(
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      ContactpersoonAntwoorden.initialize()
    );
  }

  static fromJson(json: any): LocatieProductAntwoorden {
    return new LocatieProductAntwoorden(
      Antwoord.fromJson(json.bevindtProductZichOpLocatieVanMelder),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.productLocatie),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon)
    );
  }
}
