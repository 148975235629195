import { connect } from "react-redux";
import { EMPTY_VRAAG_ANTWOORDBLOKKEN } from "reducers/collections-reducer";
import { resetFocusOnFirstVraag, resetSelectedCollectionItem } from "../../../../../../actions";
import { State } from "../../../../../../store";
import { CollectionAccordionCard } from "./collection-accordion-card.component";
import {
  CollectionAccordionCardDispatchProps,
  CollectionAccordionCardOwnProps,
  CollectionAccordionCardStateProps
} from "./collection-accordion-card.component.interfaces";

const mapStateToProps = (state: State, props: CollectionAccordionCardOwnProps): CollectionAccordionCardStateProps => ({
  selectedCollectionItemKey: state.collections.list[props.collectionKey]?.selectedItemKey ?? EMPTY_VRAAG_ANTWOORDBLOKKEN,
  focusOnFirstVraag:
    (state.collections.list[props.collectionKey]?.focusOnFirstVraag &&
      state.proces.activeItem?.hasValidationErrors !== true) ??
    false,
  wordtVertaald: state.melding?.wordtVertaald
});

const mapDispatchToProps = (
  dispatch: any,
  props: CollectionAccordionCardOwnProps
): CollectionAccordionCardDispatchProps => ({
  resetSelectedCollectionItem: () => {
    dispatch(resetSelectedCollectionItem(props.collectionKey));
  },
  onFirstVraagFocused: () => {
    dispatch(resetFocusOnFirstVraag(props.collectionKey));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionAccordionCard);
