import { AntwoordBlok } from "./antwoord-blok";
import { Antwoord } from "../antwoord";
import { MelderAntwoorden } from "./melder-antwoorden";
import { User } from "../security/user";

export class ContactMelderAntwoorden extends AntwoordBlok {
  constructor(
    public anoniemeMelder: Antwoord<string>,
    public melder: MelderAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): ContactMelderAntwoorden {
    return new ContactMelderAntwoorden(new Antwoord<string>(""), MelderAntwoorden.initialize(user));
  }

  static fromJson(json: any): ContactMelderAntwoorden {
    return new ContactMelderAntwoorden(
      Antwoord.fromJson<string>(json.anoniemeMelder),
      MelderAntwoorden.fromJson(json.melder)
    );
  }
}
