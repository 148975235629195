import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { ContactpersoonVragen } from "./contactpersoon-vragen";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";
import { ZiekenhuisVragen } from "./ziekenhuis-vragen";

export class SlachtofferInformatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public contactpersoon: ContactpersoonVragen,
    public adresgegevens: CorrespondentieadresVragen,
    public geboorteDatum: Vraag,
    public datumInDienst: Vraag,
    public arbeidsverhouding: Meerkeuzevraag,
    public aardDienstverband: Meerkeuzevraag,
    public anderDienstverband: Vraag,
    public aardVanLetselOmschrijving: Vraag,
    public slachtofferNaarZiekenhuis: Meerkeuzevraag,
    public ziekenhuis: ZiekenhuisVragen,
    public slachtofferBlijvendLetsel: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): SlachtofferInformatieVragen {
    return new SlachtofferInformatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon),
      CorrespondentieadresVragen.fromJson(`${key}.adresgegevens`, json.adresgegevens),
      jsonHelpers.toDatumVraag(`${key}.geboorteDatum`, json.geboorteDatum),
      jsonHelpers.toDatumVraag(`${key}.datumInDienst`, json.datumInDienst),
      jsonHelpers.toMeerkeuzeVraag(`${key}.arbeidsverhouding`, json.arbeidsverhouding),
      jsonHelpers.toMeerkeuzeVraag(`${key}.aardDienstverband`, json.aardDienstverband),
      jsonHelpers.toTekstVraag(`${key}.anderDienstverband`, json.anderDienstverband),
      jsonHelpers.toTekstVraag(`${key}.aardVanLetselOmschrijving`, json.aardVanLetselOmschrijving),
      jsonHelpers.toMeerkeuzeVraag(`${key}.slachtofferNaarZiekenhuis`, json.slachtofferNaarZiekenhuis),
      ZiekenhuisVragen.fromJson(`${key}.ziekenhuis`, json.ziekenhuis),
      jsonHelpers.toMeerkeuzeVraag(`${key}.slachtofferBlijvendLetsel`, json.slachtofferBlijvendLetsel)
    );
  }
}
