import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class BouwprocesWerkzaamhedenAntwoorden extends AntwoordBlok {
  constructor(
    public vermoedelijkeAanvangsdatumWerkzaamheden: Antwoord<string>,
    public vermoedelijkeEinddatumWerkzaamheden: Antwoord<string>,
    public vermoedelijkeAantalBouwdagen: Antwoord<number | null>,
    public vermoedelijkeAantalWerknemers: Antwoord<number | null>,
    public vermoedelijkeAantalOndernemingen: Antwoord<number | null>,
    public vermoedelijkeAantalZelfstandigen: Antwoord<number | null>
  ) {
    super();
  }

  static initialize(): BouwprocesWerkzaamhedenAntwoorden {
    return new BouwprocesWerkzaamhedenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null)
    );
  }

  static fromJson(json: any): BouwprocesWerkzaamhedenAntwoorden {
    return new BouwprocesWerkzaamhedenAntwoorden(
      Antwoord.fromJson<string>(json.vermoedelijkeAanvangsdatumWerkzaamheden),
      Antwoord.fromJson<string>(json.vermoedelijkeEinddatumWerkzaamheden),
      Antwoord.fromJson<number | null>(json.vermoedelijkeAantalBouwdagen),
      Antwoord.fromJson<number | null>(json.vermoedelijkeAantalWerknemers),
      Antwoord.fromJson<number | null>(json.vermoedelijkeAantalOndernemingen),
      Antwoord.fromJson<number | null>(json.vermoedelijkeAantalZelfstandigen)
    );
  }
}
