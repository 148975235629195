import React from "react";
import { ValidationResponse } from "../models/api";
import { meldingHelpers } from "./melding-helpers";

const getFirstErrorElementId = (validationResult: ValidationResponse): string | null => {
  const elements = validationResult.validationFailures.flatMap((vf) => {
    const key = meldingHelpers.getFieldNameFromVraagKey(vf.property);
    const element = document.getElementById(key);
    return element ? [element] : [];
  });

  const sortedElements = sortByDocumentPosition(elements);

  return sortedElements.length > 0 ? sortedElements[0].id : null;
};

const sortByDocumentPosition = (elements: HTMLElement[]) => {
  return elements.sort((a, b) => {
    if (a && b) {
      const pos = a.compareDocumentPosition(b);
      if (pos & Node.DOCUMENT_POSITION_PRECEDING) {
        return 1;
      }
      if (pos & Node.DOCUMENT_POSITION_FOLLOWING) {
        return -1;
      }
    }
    return 0;
  });
};

const setFocusById = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    setFocus(element);
  }
};

const setFocus = (element: HTMLElement) => {
  if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement || element.tabIndex === -1) {
    element.focus();
  }

  if (!scrollElementLabelIntoView(element)) {
    element.scrollIntoView();
  }
};

const scrollElementLabelIntoView = (element: HTMLElement): boolean => {
  let elementId = element.id;
  if (elementId) {
    // Radio and checkbox antwoordvelden hebben zelf een label (het label achter de checkbox of radio),
    // maar we willen het label van de vraag om naar te scrollen. Haal daarom het laatste stuk van het
    // id af om het juiste label element te kunnen zoeken.
    if (["radio", "checkbox"].includes((element as HTMLInputElement)?.type)) {
      // Scroll naar het label wat bij de vraag hoort, niet bij de antwoordoptie
      elementId = elementId.substring(0, elementId.lastIndexOf("."));
    }
    const label = document.querySelector(`label[for='${elementId}']`);
    label?.scrollIntoView();
    return !!label;
  }

  return false;
};

const setFocusOnFirstInput = <T>(ref: React.MutableRefObject<T>) => {
  const firstInput = (ref.current as HTMLElement).querySelector("input:first-of-type") as HTMLElement;

  if (firstInput) {
    setFocus(firstInput);
  }
};

const setFocusOnFirstHeading = (containerClass: string) => {
  const firstHeading = document.querySelector(`${containerClass} h1,h2,h3`) as HTMLElement;
  if (firstHeading) {
    firstHeading.scrollIntoView({ block: "nearest", inline: "nearest" });
    firstHeading.tabIndex = -1;
    firstHeading.focus();
  }
};

const container = {
  getFirstErrorElementId,
  setFocusOnFirstInput,
  setFocus,
  setFocusById,
  setFocusOnFirstHeading
};

export { container as focusHelpers };
