import { AutorisatiesSortOption } from "../models/api/autorisaties";
import { BeheerState } from "../store/beheer-state";
import { AutorisatiesActions } from "./../actions";
import { SET_AUTORISATIES, SET_AUTORISATIES_FILTER, SET_AUTORISATIES_LOADING } from "./../actions/autorisaties-actions";

const initialState: BeheerState = {
  autorisaties: [],
  totaalAantalAutorisaties: 0,
  hasApiError: false,
  isLoading: false,
  sortOption: new AutorisatiesSortOption("bedrijfsnaam", true),
  pageNumber: 1,
  pageSize: 30,
  zoekFilter: null
};

const beheerReducer = (state: BeheerState = initialState, action: AutorisatiesActions): BeheerState => {
  switch (action.type) {
    case SET_AUTORISATIES_FILTER:
      return {
        ...state,
        sortOption: action.sortOption,
        pageNumber: action.pageNumber,
        zoekFilter: action.zoekFilter ?? state.zoekFilter
      };
    case SET_AUTORISATIES_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasApiError: action.hasApiError
      };
    case SET_AUTORISATIES:
      return {
        ...state,
        autorisaties: action.autorisaties,
        totaalAantalAutorisaties: action.totaalAantalAutorisaties
      };
    default:
      return state;
  }
};

export default beheerReducer;
