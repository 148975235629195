import { connect } from "react-redux";
import { CbiFormulier } from "../../../models/api/formulier/cbi-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getCbiformulierMelding, initCbiFormulier, initCbiFormulierFromCopy } from "../../../thunks";
import { CbiFormulierComponent } from "./cbi-formulier.component";
import { CbiFormulierComponentDispatchProps, CbiFormulierComponentStateProps } from "./cbi-formulier.component.interfaces";

const mapStateToProps = (state: State): CbiFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.cbi ? (state.vragen.formulier as CbiFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): CbiFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initCbiFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getCbiformulierMelding(meldingId));
    } else {
      dispatch(initCbiFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CbiFormulierComponent);
