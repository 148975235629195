import React from "react";
import { Tekst } from "../../../../models/api";
import { TekstType } from "../../../../models/application";
import { FormulierReadOnlyControls } from "../../../formulier";

export class Titel {
  static getType = (): TekstType => TekstType.titel;

  static create = (tekst: Tekst): React.JSX.Element => <FormulierReadOnlyControls.Title item={tekst} />;
}
