import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { AsbestinventarisatieMateriaalVragen } from "./asbestinventarisatie-materiaal-vragen";

export class AsbestinventarisatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public toelichting: Tekst,
    public rapportdatum: Vraag,
    public projectnummer: Vraag,
    public smartNs: Meerkeuzevraag,
    public smartNsBestandenVerzoek: Tekst,
    public uitvoerValidatiemetingen: Meerkeuzevraag,
    public validatieUitgevoerd: Meerkeuzevraag,
    public materiaalTitel: Tekst,
    public geenMateriaalToelichting: Tekst,
    public materiaalToelichting: Tekst,
    public materiaal: VraagBlokCollection<AsbestinventarisatieMateriaalVragen>,
    public saneringObject: Meerkeuzevraag,
    public objectomschrijving: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): AsbestinventarisatieVragen {
    return new AsbestinventarisatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(`${key}.toelichting`, json.toelichting),
      jsonHelpers.toDatumVraag(`${key}.rapportdatum`, json.rapportdatum),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.projectnummer`, json.projectnummer),
      jsonHelpers.toMeerkeuzeVraag(`${key}.smartNs`, json.smartNs),
      jsonHelpers.toParagraaf(`${key}.smartNsBestandenVerzoek`, json.smartNsBestandenVerzoek),
      jsonHelpers.toMeerkeuzeVraag(`${key}.uitvoerValidatiemetingen`, json.uitvoerValidatiemetingen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.validatieUitgevoerd`, json.validatieUitgevoerd),
      jsonHelpers.toTitel(`${key}.materiaalTitel`, json.materiaalTitel),
      jsonHelpers.toWaarschuwing(`${key}.geenMateriaalToelichting`, json.geenMateriaalToelichting),
      jsonHelpers.toParagraaf(`${key}.materiaalToelichting`, json.materiaalToelichting),
      VraagBlokCollection.fromJson<AsbestinventarisatieMateriaalVragen>(
        AsbestinventarisatieMateriaalVragen.fromJson,
        `${key}.materiaal`,
        json.materiaal,
        VraagBlokCollectionType.materiaal,
        false
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.saneringObject`, json.saneringObject),
      jsonHelpers.toMeerkeuzeVraag(`${key}.objectomschrijving`, json.objectomschrijving)
    );
  }
}
