import { Formulier, VraagBlok } from "../models/api";

export const GET_FORMULIER = "GET_FORMULIER";
export const ADD_VRAAGBLOK_TO_COLLECTION = "ADD_VRAAGBLOK_TO_COLLECTION";
export const REMOVE_VRAAGBLOK_FROM_COLLECTION = "REMOVE_VRAAGBLOK_FROM_COLLECTION";
export const CLEAR_VRAAGBLOK_FROM_COLLECTION = "CLEAR_VRAAGBLOK_FROM_COLLECTION";
interface GetFormulierAction {
  type: typeof GET_FORMULIER;
  data: Formulier;
}

interface AddVraagBlokToCollectionAction {
  type: typeof ADD_VRAAGBLOK_TO_COLLECTION;
  vraagBlok: VraagBlok;
  collectionKey: string;
}

interface RemoveVraagBlokFromCollectionAction {
  type: typeof REMOVE_VRAAGBLOK_FROM_COLLECTION;
  collectionIndex: number;
  collectionKey: string;
}

interface ClearVraagBlokFromCollectionAction {
  type: typeof CLEAR_VRAAGBLOK_FROM_COLLECTION;
  collectionKey: string;
}

export type VragenActions =
  | GetFormulierAction
  | AddVraagBlokToCollectionAction
  | RemoveVraagBlokFromCollectionAction
  | ClearVraagBlokFromCollectionAction;

export const getFormulier = (formulier: Formulier): GetFormulierAction => {
  return {
    type: GET_FORMULIER,
    data: formulier
  };
};

export const addVraagBlokToCollection = (collectionKey: string, vraagBlok: VraagBlok) => {
  return {
    type: ADD_VRAAGBLOK_TO_COLLECTION,
    vraagBlok,
    collectionKey
  };
};

export const removeVraagBlokFromCollection = (collectionKey: string, collectionIndex: number) => {
  return {
    type: REMOVE_VRAAGBLOK_FROM_COLLECTION,
    collectionIndex,
    collectionKey
  };
};

export const clearVraagBlokCollection = (collectionKey: string) => {
  return {
    type: CLEAR_VRAAGBLOK_FROM_COLLECTION,
    collectionKey
  };
};
