import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { GegevensIllegaleWerknemerAntwoorden } from "./gegevens-illegale-werknemer-antwoorden";

export class IllegaleTewerkstellingAntwoorden extends AntwoordBlok {
  constructor(
    public welkeIllegaleTewerkstelling: Antwoord<string[]>,
    public hoeveelheidIllegaleWerknemers: Antwoord<number | null>,
    public identiteitVanIllegaleWerknemersBekend: Antwoord<string>,
    public gegevensIllegaleWerknemerLijst: AntwoordBlokCollection<GegevensIllegaleWerknemerAntwoorden> | null,
    public beschrijvingIllegaleWerknemers: Antwoord<string>,
    public werkzaamhedenVanIllegaleWerknemers: Antwoord<string>,
    public dagenEnTijdenVanWerkzaamheden: Antwoord<string>,
    public aanvullendeInformatie: Antwoord<string>
  ) {
    super();
  }

  static initialize(): IllegaleTewerkstellingAntwoorden {
    return new IllegaleTewerkstellingAntwoorden(
      new Antwoord<string[]>([]),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      AntwoordBlokCollection.initialize([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): IllegaleTewerkstellingAntwoorden {
    return json
      ? new IllegaleTewerkstellingAntwoorden(
          Antwoord.fromJson<string[]>(json.welkeIllegaleTewerkstelling),
          Antwoord.fromJson<number | null>(json.hoeveelheidIllegaleWerknemers),
          Antwoord.fromJson<string>(json.identiteitVanIllegaleWerknemersBekend),
          AntwoordBlokCollection.fromJson(GegevensIllegaleWerknemerAntwoorden.fromJson, json.gegevensIllegaleWerknemerLijst),
          Antwoord.fromJson<string>(json.beschrijvingIllegaleWerknemers),
          Antwoord.fromJson<string>(json.werkzaamhedenVanIllegaleWerknemers),
          Antwoord.fromJson<string>(json.dagenEnTijdenVanWerkzaamheden),
          Antwoord.fromJson<string>(json.aanvullendeInformatie)
        )
      : this.initialize();
  }
}
