import React, { useMemo } from "react";
import { HelpContext } from "./help-context";

const HelpContainer: React.FC<any> = (props) => {
  const [isHelpVisible, setIsHelpVisible] = React.useState(props.isHelpVisible);
  const defaultContext = useMemo(() => {
    return {
      isHelpVisible: isHelpVisible,
      setIsHelpVisible: (value: boolean) => {
        setIsHelpVisible(value);
      }
    };
  }, [isHelpVisible]);

  return <HelpContext.Provider value={defaultContext}>{props.children}</HelpContext.Provider>;
};

export { HelpContainer };
