import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";
import { LandAntwoorden } from "./land-antwoorden";

export class ProductgegevensAntwoorden extends AntwoordBlok {
  constructor(
    public soortProduct: Antwoord<string>,
    public soortProductOverig: Antwoord<string>,
    public omschrijvingProduct: Antwoord<string>,
    public merkOfNaamProduct: Antwoord<string>,
    public typenummer: Antwoord<string>,
    public landVanHerkomst: LandAntwoorden | null,
    public omschrijvingKlacht: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): ProductgegevensAntwoorden {
    return new ProductgegevensAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      LandAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): ProductgegevensAntwoorden {
    return new ProductgegevensAntwoorden(
      Antwoord.fromJson<string>(json.soortProduct),
      Antwoord.fromJson<string>(json.soortProductOverig),
      Antwoord.fromJson<string>(json.omschrijvingProduct),
      Antwoord.fromJson<string>(json.merkOfNaamProduct),
      Antwoord.fromJson<string>(json.typenummer),
      LandAntwoorden.fromJson(json.landVanHerkomst),
      Antwoord.fromJson<string>(json.omschrijvingKlacht),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson)
    );
  }
}
