import { ErrorMessageControl } from "components/error";
import { VisibleWrapper } from "components/visible-wrapper";
import { useFormikContext } from "formik";
import { objectHelpers } from "helpers";
import { AntwoordBlokken } from "models/api";

export const CollectionError = (props: { collectionKey: string }) => {
  const { errors } = useFormikContext<AntwoordBlokken>();

  const collectionError = objectHelpers.getValue<any>(errors, props.collectionKey);

  const hasError = collectionError && !!collectionError?.waarde;

  return (
    <VisibleWrapper isVisible={hasError}>
      <div className="mt-2">
        <ErrorMessageControl name="vraagblok-collection-error" scrollIntoViewCondition={() => !!hasError}>
          <div>{collectionError?.waarde}</div>
        </ErrorMessageControl>
      </div>
    </VisibleWrapper>
  );
};
