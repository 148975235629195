import { Configuration } from "configuration";
import { connect } from "react-redux";
import { State } from "../../../store";
import { getFormulieren } from "./../../../thunks/meldingen-thunks";
import { FormulierDropdown } from "./formulier-dropdown.component";
import { FormulierDropdownDispatchProps, FormulierDropdownStateProps } from "./formulier-dropdown.component.interfaces";

const mapStateToProps = (state: State): FormulierDropdownStateProps => ({
  formulierTypes: state.meldingen.formulierTypes.filter((ft) =>
    Configuration.mijnMeldingenToevoegenButtons.some(
      (config: string) => config.toLowerCase() === ft.formuliertype.toLowerCase()
    )
  )
});

const mapDispatchToProps = (dispatch: any): FormulierDropdownDispatchProps => ({
  onLoad: () => {
    dispatch(getFormulieren());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FormulierDropdown);
