import i18n from "i18next";
import React from "react";
import { Tekst } from "../../../models/api";
import { TextProps } from "../../read-only-controls/text/text.component.interfaces";
import { WithTekstProps } from "./with-tekst.interfaces";

const getTekstToelichting = (tekst: Tekst, taal: string) => (tekst.toelichting ? tekst.toelichting[taal] : null);

export const withTekst = <P extends object>(Component: React.ComponentType<P & TextProps>) => {
  return (props: P & WithTekstProps) => {
    const { item, ...rest } = props;
    const lang = i18n.language;
    const toelichting = getTekstToelichting(item, lang);

    let textWaarde = item.waarde[lang];
    if (item.variables && item.variables.size > 0) {
      item.variables.forEach((value, key) => {
        textWaarde = textWaarde.replace(key, value);
      });
    }

    return <Component {...(rest as P)} id={item.key} text={textWaarde} toelichting={toelichting} />;
  };
};
