import { Meerkeuzevraag, SamengesteldeVraagVraagBlokTemplate, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class TeVerwijderenAsbestMateriaalLavsVragen extends VraagBlok implements SamengesteldeVraagVraagBlokTemplate {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public risicoklasse: Vraag,
    public hoeveelheid: Vraag,
    public eenheid: Meerkeuzevraag,
    public soortMateriaal: Vraag,
    public broncode: Vraag,
    public brontype: Vraag,
    public binding: Vraag,
    public bevestigingsmethode: Vraag,
    public verwijderingsmethode: Vraag,
    public aanbevolenMaatregel: Vraag,
    public toelichting: Vraag,
    public hoeveelheidType: Vraag,
    public afmetingPerStuk: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  createInstance(key: string): VraagBlok {
    return TeVerwijderenAsbestMateriaalLavsVragen.fromJson(key, this);
  }

  static fromJson(key: string, json: any): TeVerwijderenAsbestMateriaalLavsVragen {
    return new TeVerwijderenAsbestMateriaalLavsVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.risicoklasse`, json.risicoklasse),
      jsonHelpers.toTekstVraag(`${key}.hoeveelheid`, json.hoeveelheid),
      jsonHelpers.toMeerkeuzeVraag(`${key}.eenheid`, json.eenheid),
      jsonHelpers.toTekstVraag(`${key}.soortMateriaal`, json.soortMateriaal),
      jsonHelpers.toTekstVraag(`${key}.broncode`, json.broncode),
      jsonHelpers.toTekstVraag(`${key}.brontype`, json.brontype),
      jsonHelpers.toTekstVraag(`${key}.binding`, json.binding),
      jsonHelpers.toTekstVraag(`${key}.bevestigingsmethode`, json.bevestigingsmethode),
      jsonHelpers.toTekstVraag(`${key}.verwijderingsmethode`, json.verwijderingsmethode),
      jsonHelpers.toTekstVraag(`${key}.aanbevolenMaatregel`, json.aanbevolenMaatregel),
      jsonHelpers.toTekstVraag(`${key}.toelichting`, json.toelichting),
      jsonHelpers.toTekstVraag(`${key}.hoeveelheidType`, json.hoeveelheidType),
      jsonHelpers.toTekstVraag(`${key}.afmetingPerStuk`, json.afmetingPerStuk)
    );
  }
}
