import { useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { MeldingFilters } from "../../../models/api/zoek";
import { ZoekMeldingenBreadcrumbs } from "../../breadcrumbs/zoek-meldingen-breadcrumbs";
import { MeldingGrid } from "../../melding/melding-grid";
import { Spinner } from "../../spinner";
import { ZoekMeldingenFilter } from "./zoek-meldingen-filter.component";
import { ZoekMeldingenProps } from "./zoek-meldingen.component.interfaces";

export const ZoekMeldingen = (props: ZoekMeldingenProps) => {
  const { t } = useTranslation();

  const onLoad = props.onLoad;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const search = (filter: MeldingFilters) => {
    props.onSearch({ filter: filter });
  };

  const resultaatTitel = (): string => {
    switch (props.totaalAantalMeldingen) {
      case 0:
        return t(translationKeys.zoek.meldingen.resultaten.geen);
      case 1:
        return t(translationKeys.zoek.meldingen.resultaten.een);
      default:
        return t(translationKeys.zoek.meldingen.resultaten.meerdere, {
          aantalResultaten: props.totaalAantalMeldingen
        });
    }
  };

  return (
    <Row className="main-container" role="main">
      <Col xs={12} className="main-container-title">
        <ZoekMeldingenBreadcrumbs />
        <h1>{t(translationKeys.zoek.titel)}</h1>
        <h2>{t(translationKeys.zoek.meldingen.titel)}</h2>

        <ZoekMeldingenFilter
          formulierTypes={props.formulierTypes}
          isLoading={props.isLoading}
          onReset={props.onReset}
          onSearch={search}
          plaatsen={props.plaatsen}
          onSearchPlaatsen={props.onSearchPlaatsen}
        />

        {props.isLoading ? (
          <Spinner>
            <h2>{t(translationKeys.bezigMetLaden)}</h2>
          </Spinner>
        ) : null}
        {props.hasApiError ? <Alert variant="danger">{t(translationKeys.oepsErGingIetsFout)}</Alert> : null}
        {props.hasApiError === false && !props.isLoading ? <h2>{resultaatTitel()}</h2> : null}
        {!props.hasApiError && props.meldingen.length > 0 ? (
          <MeldingGrid
            columns={[
              "meldingnummer",
              "vestigingsnaam",
              "locatieAdres",
              "locatiePostcode",
              "locatiePlaats",
              "status",
              "formulier",
              "aangemaaktOp",
              "aanvang",
              "beeindiging"
            ]}
            meldingen={props.meldingen}
            onSearchOptionChange={props.onSearchOptionChange}
            pageNumber={props.pageNumber}
            pageSize={props.pageSize}
            sortOption={props.sortOption}
            totaalAantalMeldingen={props.totaalAantalMeldingen}
          />
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};
