import { httpStatusCodes } from "constants/http-status-codes";
import { Configuration } from "../configuration";
import { UrlBuilder } from "../helpers";
import { GetVestigingenResponse } from "../models/api";
import { Vestiging } from "../models/api/extern/vestiging";
import { VestigingenPagingState, VestigingenSearchingState } from "../store/blok/vestigingen";
import { get } from "./api";

const getVestiging = (vestigingId: number): Promise<Vestiging | undefined> => {
  const statusHandlers = new Map([
    [
      httpStatusCodes.notfound,
      () => {
        // No specific actions necessary
      }
    ]
  ]);

  return get({
    uri: `${Configuration.apiUrl}/vestigingen/${vestigingId}`,
    success: (response) => {
      if (!response || response.status === httpStatusCodes.notfound) {
        return undefined;
      }
      return Vestiging.fromJson(response);
    },
    statusHandlers
  });
};

const searchVestigingen = (
  searching: VestigingenSearchingState,
  pageing: VestigingenPagingState,
  isInternalUser: boolean,
  signal?: AbortSignal
): Promise<GetVestigingenResponse> => {
  const urlHelper = new UrlBuilder(`${Configuration.apiUrl}/vestigingen`)
    .withQueryParam("kvknummer", searching.criteria.kvkNummer)
    .withQueryParam("vestigingsnummer", searching.criteria.vestigingsnummer)
    .withQueryParam("plaats", searching.criteria.plaats)
    .withQueryParam("naam", searching.criteria.naam)
    .withQueryParam("land", searching.criteria.land)
    .withQueryParam("straat", searching.criteria.straat)
    .withQueryParam("huisnummer", searching.criteria.huisnummer?.toString())
    .withQueryParam("postcode", searching.criteria.postcode)
    .withQueryParam("gestaakt", "false")
    .withQueryParam("ingeschreven", "true")
    .withQueryParam("pagesize", pageing.pageSize?.toString())
    .withQueryParam("pagenumber", pageing.page?.toString())
    .withQueryParam("InclusiefRiec", isInternalUser.toString());

  return get({
    uri: urlHelper.toString(),
    signal,
    success: (r) => GetVestigingenResponse.fromJson(r)
  });
};

export const vestigingApi = {
  getVestiging,
  searchVestigingen
};
