import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useRef, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { ErrorMessageProps } from "./error-message.component.interfaces";

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

export const ErrorMessage = (props: ErrorMessageProps) => {
  const errorMessageControlRef = useRef(null);

  const [isScrolledIntoView, setIsScrolledIntoView] = useState(false);

  useEffect(() => {
    if (props.scrollIntoViewCondition && props.scrollIntoViewCondition() && errorMessageControlRef && !isScrolledIntoView) {
      scrollToRef(errorMessageControlRef);

      setIsScrolledIntoView(true);
    }
  }, [isScrolledIntoView, props]);

  if (props.children) {
    const errorIsString = props.children && typeof props.children === "string";
    const errorElement = errorIsString ? (
      <ReactMarkdown children={props.children as string} className="validation-error-md" />
    ) : (
      <Fragment>{props.children}</Fragment>
    );

    return (
      <Alert
        variant="danger"
        id={`${props.name}.errors`}
        className={`error-message ${props.className}`}
        ref={errorMessageControlRef}
      >
        <Row>
          <Col className="icon">
            <FontAwesomeIcon icon={faTimesCircle} className="error-icon" />
          </Col>
          <Col>{errorElement}</Col>
        </Row>
      </Alert>
    );
  } else {
    return null;
  }
};
