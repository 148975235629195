import { Configuration } from "configuration";
import { BedrijfsgegevensVragen, GebruikInfoVanType } from "models/api";
import { meldingHelpers, userHelpers } from "../../../helpers";
import { State, store } from "../../../store";

export interface VestigingProperties {
  disableKvkSearch?: boolean;
  disableVestigingsnummerSearch?: boolean;
  readOnly?: boolean;
  userKvkNummer?: string;
  userVestigingId?: number;
  userVestigingsnummer?: string;
  autoSearch?: boolean;
}

export const abortAndCreateNewAbortController = (abortController: AbortController | undefined): AbortController => {
  if (abortController) {
    abortController.abort();
  }

  return new AbortController();
};

export const getVestigingProperties = (bedrijfsgegevensVragen: BedrijfsgegevensVragen): VestigingProperties => {
  const state = store.getState() as State;

  // Check if current melding autorisatie has external kvk and vestigingsnummer
  const isExternalFixedVestigingsnummer = getIsExternalFixedVestigingsnummer(state);

  // Check if current melding is an admin editing a Profiel
  const isProfielInEditMode = state.profiel.isInEditMode;
  const isInternalUser = userHelpers.getIsInternalUser(state.security.user);

  // Use vestiging properties from user
  let userKvkNummer, userVestigingId, userVestigingsnummer;
  if (bedrijfsgegevensVragen.gebruikInfoVan === GebruikInfoVanType.VanGebruiker) {
    userKvkNummer = isInternalUser ? undefined : state.security.user?.kvkNummer;

    if (isExternalFixedVestigingsnummer) {
      userVestigingsnummer = state.security.user?.vestigingsnummer;
    }

    userVestigingId = state.security.user?.vestigingId;
  } else if (bedrijfsgegevensVragen.gebruikInfoVan === GebruikInfoVanType.VanNla && isInternalUser) {
    const hasAntwoord = meldingHelpers.hasAntwoord(state.antwoorden.blokken, bedrijfsgegevensVragen.geselecteerdeVestiging);
    if (!hasAntwoord) {
      // Only set vestiging when not already a value
      userVestigingId = Configuration.nlaVestiging;
    }
  }

  let disableKvkSearch =
    (!!userKvkNummer || isProfielInEditMode) &&
    !(isProfielInEditMode && userHelpers.getMagKvkNummerWijzigen(state.security.user));

  return {
    userKvkNummer,
    userVestigingId,
    userVestigingsnummer,
    disableKvkSearch,
    disableVestigingsnummerSearch: !!userVestigingsnummer,
    autoSearch: bedrijfsgegevensVragen.automatischZoeken
  };
};

const getIsExternalFixedVestigingsnummer = (state: any): boolean => {
  const isExternalLogin = !!state.autorisatie.autorisatiegegevens.isExternalLogin;
  return isExternalLogin && userHelpers.getIsVestigingsnummerFixed(state.security.user);
};
