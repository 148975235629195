import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";
import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { UitgeoefendeActiviteitenAntwoorden } from "./uitgeoefende-activiteiten-antwoorden";

export class GegevensInstallatieAntwoorden extends AntwoordBlok {
  constructor(
    public naamBedrijf: Antwoord<string>,
    public bedrijfsterrein: Antwoord<string>,
    public correspondentieadres: CorrespondentieadresAntwoorden | null,
    public uitgeoefendeActiviteiten: UitgeoefendeActiviteitenAntwoorden
  ) {
    super();
  }

  static initialize(): GegevensInstallatieAntwoorden {
    return new GegevensInstallatieAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(),
      UitgeoefendeActiviteitenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): GegevensInstallatieAntwoorden {
    return new GegevensInstallatieAntwoorden(
      Antwoord.fromJson<string>(json.naamBedrijf),
      Antwoord.fromJson<string>(json.bedrijfsterrein),
      CorrespondentieadresAntwoorden.fromJson(json.correspondentieadres),
      UitgeoefendeActiviteitenAntwoorden.fromJson(json.uitgeoefendeActiviteiten)
    );
  }
}
