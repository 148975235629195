import { Pager } from "components/grid/pager";
import { Fragment, useEffect, useMemo, useState } from "react";
import { DEFAULT_PAGE_SIZE } from "reducers/collections-reducer";
import { CollectionTablePagerProps } from "./collection-table-pager.component.interfaces";

export const CollectionTablePager = (props: CollectionTablePagerProps) => {
  const numberOfPages = Math.ceil(props.filteredRecords / props.gridPageSize);

  const [pagerDescription, setPagerDescription] = useState("");
  const { onPageSizeChange, pagingSettings } = props;
  const getTotalsDescription = useMemo(
    () => pagingSettings?.getTotalsDescription ?? (() => ""),
    [pagingSettings?.getTotalsDescription]
  );

  useEffect(() => {
    onPageSizeChange(pagingSettings?.numberOfItemsPerPage ?? DEFAULT_PAGE_SIZE);
  }, [pagingSettings, onPageSizeChange]);

  useEffect(() => {
    setPagerDescription(
      getTotalsDescription({
        indexOfFirstItemOnPage: props.pageStartIndex,
        indexOfLastItemOnPage: props.pageStartIndex ?? props.gridPageSize,
        numberOfPages: numberOfPages,
        filteredItemsInGrid: props.filteredRecords,
        totalItemsInGrid: props.totalRecords
      }) ?? ""
    );
  }, [
    getTotalsDescription,
    numberOfPages,
    props.pageStartIndex,
    props.gridPageSize,
    props.filteredRecords,
    props.totalRecords
  ]);

  return (
    <Fragment>
      {props.pagingSettings && (
        <Pager
          disabled={props.disabled || props.isCollectionItemSelected}
          pageCount={numberOfPages}
          currentPage={props.currentPage}
          onPageChange={props.onPageChange}
          totalsDescription={pagerDescription}
        ></Pager>
      )}
    </Fragment>
  );
};
