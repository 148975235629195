import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { WooFormulierVraagBlokken } from "./woo-formulier-vraag-blokken";

export class WooFormulier extends FormulierBase {
  constructor(public blokken: WooFormulierVraagBlokken, json: any) {
    super(Formuliertype.woo, blokken, json);
  }

  static fromJson(json: any): WooFormulier {
    return new WooFormulier(WooFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
