import {
  AanleveringLavsAntwoorden,
  AntwoordBlokken,
  AsbestWerkzaamhedenLavsAntwoorden,
  CertificerendeInstellingAntwoorden,
  InventarisatieLavsAntwoorden,
  LaboratoriumAntwoorden,
  LocatieLavsAntwoorden,
  MelderLavsAntwoorden,
  OpdrachtgeverLavsAntwoorden,
  OpdrachtnemerLavsAntwoorden,
  PeriodeDatumTijdAntwoorden
} from "../blok";
import { User } from "../security/user";

export class AsbestverwijderingLavsMeldingAntwoordBlokken extends AntwoordBlokken {
  constructor(
    public aanlevering: AanleveringLavsAntwoorden,
    public melder: MelderLavsAntwoorden,
    public certificerendeInstelling: CertificerendeInstellingAntwoorden,
    public opdrachtgever: OpdrachtgeverLavsAntwoorden,
    public opdrachtnemer: OpdrachtnemerLavsAntwoorden,
    public inventarisatie: InventarisatieLavsAntwoorden,
    public locatie: LocatieLavsAntwoorden,
    public periode: PeriodeDatumTijdAntwoorden,
    public werkzaamheden: AsbestWerkzaamhedenLavsAntwoorden,
    public laboratorium: LaboratoriumAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): AsbestverwijderingLavsMeldingAntwoordBlokken {
    return new AsbestverwijderingLavsMeldingAntwoordBlokken(
      AanleveringLavsAntwoorden.initialize(),
      MelderLavsAntwoorden.initialize(user),
      CertificerendeInstellingAntwoorden.initialize(),
      OpdrachtgeverLavsAntwoorden.initialize(),
      OpdrachtnemerLavsAntwoorden.initialize(),
      InventarisatieLavsAntwoorden.initialize(),
      LocatieLavsAntwoorden.initialize(),
      PeriodeDatumTijdAntwoorden.initialize(),
      AsbestWerkzaamhedenLavsAntwoorden.initialize(),
      LaboratoriumAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AsbestverwijderingLavsMeldingAntwoordBlokken {
    return new AsbestverwijderingLavsMeldingAntwoordBlokken(
      AanleveringLavsAntwoorden.fromJson(json.aanlevering),
      MelderLavsAntwoorden.fromJson(json.melder),
      CertificerendeInstellingAntwoorden.fromJson(json.certificerendeInstelling),
      OpdrachtgeverLavsAntwoorden.fromJson(json.opdrachtgever),
      OpdrachtnemerLavsAntwoorden.fromJson(json.opdrachtnemer),
      InventarisatieLavsAntwoorden.fromJson(json.inventarisatie),
      LocatieLavsAntwoorden.fromJson(json.locatie),
      PeriodeDatumTijdAntwoorden.fromJson(json.periode),
      AsbestWerkzaamhedenLavsAntwoorden.fromJson(json.werkzaamheden),
      LaboratoriumAntwoorden.fromJson(json.laboratorium)
    );
  }
}
