import { Bestandenvraag, Tekst, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class ContactVraagVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public onderwerp: Vraag,
    public referentieIszw: Vraag,
    public toelichting: Vraag,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public bestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ContactVraagVragen {
    return new ContactVraagVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.onderwerp`, json.onderwerp),
      jsonHelpers.toTekstVraag(`${key}.referentieIszw`, json.referentieIszw),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.toelichting`, json.toelichting),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden)
    );
  }
}
