import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faFileUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField, useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { Bestand } from "../../../models/api/blok/bestand";
import { MeldingFile } from "../../../models/application";
import { ErrorMessageControl } from "../../error";

export const InputFiles = (props: any) => {
  const [, meta] = useField(props);

  const { error, touched } = meta;

  const files: MeldingFile[] = props.files;

  const { name, onAddFiles, onDeleteFiles, onChange } = props;

  const { setFieldValue, validateField } = useFormikContext();

  const { t } = useTranslation();

  const addFiles = (addedFiles: File[]) => {
    onAddFiles(addedFiles);

    updateFieldValues([...files, ...addedFiles]);
    validateField(name);

    if (onChange) {
      onChange();
    }
  };

  const deleteFile = (f: any) => {
    onDeleteFiles([f]);

    updateFieldValues([...files.filter((file) => file.name !== f.name)]);
    validateField(name);

    if (onChange) {
      onChange();
    }
  };

  const updateFieldValues = (updateFiles: (File | MeldingFile)[]) => {
    setFieldValue(
      name,
      updateFiles.map((f) => {
        return new Bestand(f.name, f.size, (f as MeldingFile).id !== undefined ? (f as MeldingFile).id : null);
      })
    );
  };

  return (
    <div>
      <Dropzone onDrop={(acceptedFiles) => addFiles(acceptedFiles)}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} className={isDragActive ? "files-drag-area drag-active" : "files-drag-area"}>
            <Row>
              <Col className="text-center">
                <FontAwesomeIcon icon={faFileUpload} size="4x" />
                <div className="explanation py-2">{t(isDragActive ? "bijlageLoslaten" : "bijlageSlepen")}</div>
                <input {...getInputProps()} name={name} multiple type="file" />
                <div className="btn btn-primary" id="files-upload-button">
                  {t(translationKeys.bestandenToevoegen)}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Dropzone>

      <div>
        {files.map((f: any) => (
          <Row key={f.name} className="file-list-row">
            <Col className="ms-3 file-name-col" key={`${f.name}_1`}>
              <div className="file-name" title={f.name}>
                <FontAwesomeIcon icon={faFileAlt} size="1x" />
                <span className="ms-2">{f.name}</span>
              </div>
            </Col>
            <Col className="ms-1 me-3 col-auto" key={`${f.name}_2`}>
              <button
                className="remove-file-button"
                title={t(translationKeys.bestandVerwijderen, { naam: f.name })}
                onClick={() => {
                  deleteFile(f);
                }}
              >
                <FontAwesomeIcon className="remove-file" icon={faTimes} size="1x" />
              </button>
            </Col>
          </Row>
        ))}
      </div>

      {touched && <ErrorMessageControl name={name}>{error}</ErrorMessageControl>}
    </div>
  );
};
