import { HasBijlageAntwoorden, HasVerzoekMelderAntwoorden, HasWooVerzoekAntwoorden } from "..";
import { AntwoordBlokken, BijlageAntwoorden, VerzoekMelderAntwoorden, WooVerzoekAntwoorden } from "../blok";
import { User } from "../security/user";

export class WooMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasWooVerzoekAntwoorden, HasVerzoekMelderAntwoorden, HasBijlageAntwoorden
{
  constructor(
    public verzoek: WooVerzoekAntwoorden,
    public melder: VerzoekMelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): WooMeldingAntwoordBlokken {
    return new WooMeldingAntwoordBlokken(
      WooVerzoekAntwoorden.initialize(),
      VerzoekMelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): WooMeldingAntwoordBlokken {
    return new WooMeldingAntwoordBlokken(
      WooVerzoekAntwoorden.fromJson(json.verzoek),
      VerzoekMelderAntwoorden.fromJson(json.melder),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
