import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";
import { PeriodeEerdereOntheffingAntwoorden } from "./periode-eerdere-ontheffing-antwoorden";

export class GegevensAanvragerOntheffingNachtarbeidAntwoorden extends AntwoordBlok {
  constructor(
    public aanvrager: ContactpersoonAntwoorden | null,
    public geboortedatum: Antwoord<string>,
    public aanvragerAdres: CorrespondentieadresAntwoorden | null,
    public burgerservicenummer: Antwoord<string>,
    public functie: Antwoord<string>,
    public periodeWerkuren: Antwoord<string>,
    public aantalWerkuren: Antwoord<number | null>,
    public functieKanOverdagWordenVervuld: Antwoord<string>,
    public eerdereAanvraagIngediend: Antwoord<string>,
    public welkeWerkgeverEerdereAanvraag: Antwoord<string>,
    public datumEerdereAanvraag: Antwoord<string>,
    public nummerLaatstIngediendeAanvraag: Antwoord<number | null>,
    public eerdereOntheffingVerbodNachtarbeid: Antwoord<string>,
    public welkeWerkgeverEerdereOntheffingVerbodNachtarbeid: Antwoord<string>,
    public datumEerdereOntheffing: Antwoord<string>,
    public kenmerkLaatstOntvangenBeschikking: Antwoord<number | null>,
    public periodeEerdereOntheffing: PeriodeEerdereOntheffingAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): GegevensAanvragerOntheffingNachtarbeidAntwoorden {
    return new GegevensAanvragerOntheffingNachtarbeidAntwoorden(
      ContactpersoonAntwoorden.initialize(user),
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(user),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      PeriodeEerdereOntheffingAntwoorden.initialize()
    );
  }

  static fromJson(json: any): GegevensAanvragerOntheffingNachtarbeidAntwoorden {
    return new GegevensAanvragerOntheffingNachtarbeidAntwoorden(
      ContactpersoonAntwoorden.fromJson(json.aanvrager),
      Antwoord.fromJson<string>(json.geboortedatum),
      CorrespondentieadresAntwoorden.fromJson(json.aanvragerAdres),
      Antwoord.fromJson<string>(json.burgerservicenummer),
      Antwoord.fromJson<string>(json.functie),
      Antwoord.fromJson<string>(json.periodeWerkuren),
      Antwoord.fromJson<number | null>(json.aantalWerkuren),
      Antwoord.fromJson<string>(json.functieKanOverdagWordenVervuld),
      Antwoord.fromJson<string>(json.eerdereAanvraagIngediend),
      Antwoord.fromJson<string>(json.welkeWerkgeverEerdereAanvraag),
      Antwoord.fromJson<string>(json.datumEerdereAanvraag),
      Antwoord.fromJson<number | null>(json.nummerLaatstIngediendeAanvraag),
      Antwoord.fromJson<string>(json.eerdereOntheffingVerbodNachtarbeid),
      Antwoord.fromJson<string>(json.welkeWerkgeverEerdereOntheffingVerbodNachtarbeid),
      Antwoord.fromJson<string>(json.datumEerdereOntheffing),
      Antwoord.fromJson<number | null>(json.kenmerkLaatstOntvangenBeschikking),
      PeriodeEerdereOntheffingAntwoorden.fromJson(json.periodeEerdereOntheffing)
    );
  }
}
