import { withLandenTypeahead } from "components/formulier/with-landen-typeahead";
import { ReadOnlyControls } from "components/read-only-controls";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { Alert, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { InputControls } from "../../../../components/input-controls";
import { translationKeys } from "../../../../constants";
import { ButtonWithLoader } from "../../../button-with-loader";
import { VestigingFormSearchProps } from "./vestiging-form-search.component.interfaces";

const VestigingSearchLandenTypeahead = withLandenTypeahead(InputControls.AsyncTypeahead);

export const VestigingFormSearch = (props: VestigingFormSearchProps) => {
  const { t } = useTranslation();

  const { name } = props;
  const { autoSearch, disableKvkSearch, disableVestigingsnummerSearch, onSearch } = props;
  const [initialSearch, setInitialSearch] = React.useState(true);

  const originalCriteria = props.criteria;
  const currentCriteria = { ...originalCriteria };

  useEffect(() => {
    if (autoSearch && initialSearch) {
      onSearch(currentCriteria);
      setInitialSearch(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <h3>{t(translationKeys.vestigingenSearch.zoekTitel)}</h3>
      <Alert variant="info">{t(translationKeys.vestigingenSearch.toelichting)}</Alert>
      <Formik
        initialValues={currentCriteria}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          onSearch(values);
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleReset, submitForm }) => (
          <div>
            <InputControls.Text
              label={t(translationKeys.vestigingenSearch.kvknummer)}
              name="kvkNummer"
              readOnly={disableKvkSearch}
            />
            <InputControls.Text
              label={t(translationKeys.vestigingenSearch.vestigingsnummer)}
              name="vestigingsnummer"
              readOnly={disableVestigingsnummerSearch}
            />
            <ReadOnlyControls.Text text={t(translationKeys.vestigingenSearch.of)} />
            <InputControls.Text label={t(translationKeys.vestigingenSearch.naam)} name="naam" />            
            <VestigingSearchLandenTypeahead
              key={name}
              label={t(translationKeys.vestigingenSearch.land)}
              name="land"
              includeNederland={true}
            />
            <InputControls.Text label={t(translationKeys.vestigingenSearch.postcode)} name="postcode" />
            <InputControls.Number label={t(translationKeys.vestigingenSearch.huisnummer)} name="huisnummer" />
            <InputControls.Text label={t(translationKeys.vestigingenSearch.straat)} name="straat" />
            <InputControls.Text label={t(translationKeys.vestigingenSearch.plaats)} name="plaats" />
            <Form.Group className="form-group">
              <div className="input-container">
                <ButtonWithLoader
                  name={`${name}.vestiging-form-search`}
                  className="float-end"
                  type="submit"
                  isVisible={true}
                  isLoading={isSubmitting || props.busy}
                  onClick={() => submitForm()}
                >
                  <span className="visually-hidden">
                    {t(translationKeys.vestigingenSearch.zoekenScreenReaderAanvulling)}
                  </span>
                  {t(translationKeys.vestigingenSearch.zoeken)}
                </ButtonWithLoader>
                <ButtonWithLoader
                  variant="secondary"
                  name={`${name}.vestiging-form-clear`}
                  className="float-end me-1"
                  isVisible={true}
                  isLoading={isSubmitting}
                  onClick={() => {
                    handleReset();
                    props.onClearSearch();
                  }}
                >
                  <span className="visually-hidden">
                    {t(translationKeys.vestigingenSearch.wissenScreenReaderAanvulling)}
                  </span>
                  {t(translationKeys.vestigingenSearch.wissen)}
                </ButtonWithLoader>
              </div>
            </Form.Group>
          </div>
        )}
      </Formik>
    </section>
  );
};
