import moment, { Moment } from "moment";
import { Order } from "store";

const dateInputFormat = "YYYY-MM-DD";
const dateTimeInputFormat = "YYYY-MM-DDTHH:mm:ss";
const timeNoLeadingZeroInputFormat = "H:mm";
const timeLeadingZeroInputFormat = "HH:mm";
const timeInputFormatNonStrict = [timeNoLeadingZeroInputFormat, timeLeadingZeroInputFormat];
const dateDisplayFormat = "DD-MM-YYYY";
const timeDisplayFormat = "HH:mm";
const dateTimeDisplayFormat = `${dateDisplayFormat} ${timeDisplayFormat}`;
const dateTimeInputFormatNonStrict = [
  `${dateInputFormat} ${timeNoLeadingZeroInputFormat}`,
  `${dateInputFormat} ${timeLeadingZeroInputFormat}`
];

const formatDate = (date: string, inputFormat = dateInputFormat, displayFormat = dateDisplayFormat): string => {
  return moment(date, inputFormat).format(displayFormat);
};

const formatDateTime = (
  date: string,
  inputFormat: moment.MomentFormatSpecification | undefined = dateTimeInputFormatNonStrict,
  displayFormat = dateTimeDisplayFormat
): string => {
  return moment(date, inputFormat).format(displayFormat);
};

const getMomentFromDate = (date?: Date) => {
  return date ? moment(date) : undefined;
};

const getMomentFromDateString = (date: string, format: moment.MomentFormatSpecification | undefined = dateInputFormat) => {
  return moment(date, format, true);
};

const getMomentFromDateTimeString = (
  date: string,
  format: moment.MomentFormatSpecification | undefined = dateTimeInputFormatNonStrict
) => {
  return moment(date, format, true);
};

const getMomentFromTimeString = (
  time: string,
  format: moment.MomentFormatSpecification | undefined = timeInputFormatNonStrict
) => {
  return moment(time, format, true);
};

const getDaysFromNow = (datum: Moment) => {
  const now = moment().startOf("day");
  return datum.diff(now, "days");
};

const isValidDate = (
  datumAsString: string,
  format: moment.MomentFormatSpecification | undefined = dateInputFormat
): boolean => {
  const datumToCheck = getMomentFromDateString(datumAsString, format);
  return datumToCheck.isValid();
};

const isValidDateTime = (
  datumAsString: string,
  format: moment.MomentFormatSpecification | undefined = dateTimeInputFormatNonStrict
): boolean => {
  const datumToCheck = getMomentFromDateString(datumAsString, format);
  return datumToCheck.isValid();
};

const isValidTime = (
  tijdAsString: string,
  format: moment.MomentFormatSpecification | undefined = timeInputFormatNonStrict
): boolean => {
  const datumToCheck = getMomentFromDateString(tijdAsString, format);
  return datumToCheck.isValid();
};

const isDateInputSupported = () => {
  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
};

const dateCompare = (
  left: string,
  right: string,
  order = Order.ascending,
  format: moment.MomentFormatSpecification | undefined = dateInputFormat
): number => {
  const leftMoment = getMomentFromDateString(left, format);
  const rightMoment = getMomentFromDateString(right, format);
  let result = 0;
  if (leftMoment.isBefore(rightMoment)) {
    result = 1;
  } else if (leftMoment.isAfter(rightMoment)) {
    result = -1;
  }

  if (order === Order.ascending) {
    return result;
  } else {
    return result * -1;
  }
};

const displayDateCompare = (left: string, right: string, order = Order.ascending) =>
  dateCompare(left, right, order, dateDisplayFormat);

export const dateHelpers = {
  formatDate,
  formatDateTime,
  getMomentFromDate,
  getMomentFromDateString,
  getMomentFromDateTimeString,
  getMomentFromTimeString,
  getDaysFromNow,
  isValidDate,
  isValidDateTime,
  isValidTime,
  isDateInputSupported: isDateInputSupported(),
  dateDisplayFormat,
  dateInputFormat,
  dateCompare,
  dateTimeInputFormat,
  displayDateCompare
};
