import { Dictionary, VraagBlokCollectionType } from "../application";
import { VraagBlok } from "./vraag-blok";
import { VraagBlokCollectionVerplichtType } from "./vraag-blok-collection-verplicht-type";

export interface IVraagBlokCollection {
  template: VraagBlok;
}

export class VraagBlokCollection<TVraagBlokType extends VraagBlok> extends VraagBlok implements IVraagBlokCollection {
  public readonly isVraagBlokCollection = true;

  constructor(
    public key: string,
    public volgnummer: number,
    public verplichtType: VraagBlokCollectionVerplichtType,
    public isVerplichtValidationMessage: Dictionary<string> | null,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public helptekst: Dictionary<string>,
    public toevoegenTekst: Dictionary<string>,
    public extraToevoegenTekst: Dictionary<string>,
    public collectieTekst: Dictionary<string>,
    public itemTekst: Dictionary<string>,
    public template: TVraagBlokType,
    public list: TVraagBlokType[],
    public collectionType: VraagBlokCollectionType,
    public showLabel: boolean
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson<TVraagBlok extends VraagBlok>(
    vraagBlokFromJson: (key: string, json: any) => TVraagBlok,
    key: string,
    json: any,
    type: VraagBlokCollectionType,
    showLabel = true
  ): VraagBlokCollection<TVraagBlok> {
    return new VraagBlokCollection<TVraagBlok>(
      key,
      json.volgnummer,
      json.verplichtType,
      json.isVerplichtValidationMessage,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.helptekst,
      json.toevoegenTekst,
      json.extraToevoegenTekst,
      json.collectieTekst,
      json.itemTekst,
      vraagBlokFromJson(`${key}.template`, json.template),
      [],
      type,
      showLabel
    );
  }
}
