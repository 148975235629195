import { TeVerwijderenAsbestMateriaalInventarisatie } from "components/blok/te-verwijderen-asbest-materiaal-inventarisatie";
import { SamengesteldeVraag, TeVerwijderenAsbestMateriaalRisicoklasse1Vragen } from "../../../../models/api";
import { SamengesteldeVraagType } from "../../../../models/application";

export class TeVerwijderenAsbestMateriaalRisicoklasse1 {
  static getType = (): SamengesteldeVraagType => SamengesteldeVraagType.teVerwijderenAsbestMateriaalRisicoklasse1;

  static create = (vraag: SamengesteldeVraag<TeVerwijderenAsbestMateriaalRisicoklasse1Vragen>): React.JSX.Element => (
    <TeVerwijderenAsbestMateriaalInventarisatie vraag={vraag} />
  );
}
