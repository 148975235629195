import { HasVolgnummer } from "../models/api";

const getVolgnummer = (element: React.JSX.Element): number => {
  const vraagOrBlok = getVraagOrBlok(element);

  if (vraagOrBlok) {
    return vraagOrBlok.volgnummer;
  } else {
    const getVolgnummerFunction = element.props["getVolgnummer"] as () => number;

    return getVolgnummerFunction ? getVolgnummerFunction() : 0;
  }
};

const getVraagOrBlok = (element: React.JSX.Element): HasVolgnummer | null => {
  const vraagProp = element.props["vraag"];

  if (vraagProp) {
    return vraagProp as HasVolgnummer;
  } else {
    const vragenProp = element.props["vragen"];

    if (vragenProp) {
      return vragenProp as HasVolgnummer;
    } else {
      const itemProp = element.props["item"];

      if (itemProp) {
        return itemProp as HasVolgnummer;
      } else {
        return null;
      }
    }
  }
};

const sortOnVolgnummer = (components: React.JSX.Element[]): React.JSX.Element[] => {
  components.sort((a, b) => getVolgnummer(a) - getVolgnummer(b));
  return components;
};

export const componentHelpers = {
  sortOnVolgnummer
};
