import { connect } from "react-redux";
import { KlachtenFormulier } from "../../../models/api/formulier/klachten-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getKlachtenformulierMelding, initKlachtenFormulier, initKlachtenFormulierFromCopy } from "../../../thunks";
import { KlachtenFormulierComponent } from "./klachten-formulier.component";
import {
  KlachtenFormulierComponentDispatchProps,
  KlachtenFormulierComponentStateProps
} from "./klachten-formulier.component.interfaces";

const mapStateToProps = (state: State): KlachtenFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.klachten ? (state.vragen.formulier as KlachtenFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): KlachtenFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initKlachtenFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getKlachtenformulierMelding(meldingId));
    } else {
      dispatch(initKlachtenFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(KlachtenFormulierComponent);
