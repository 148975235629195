const translationKeys = {
  "samenvatting-bar": { beschrijving: "samenvatting-bar.beschrijving", "bewerk-titel": "samenvatting-bar.bewerk-titel" },
  blok: {
    aannemers: { titel: "blok.aannemers.titel" },
    aanvullendevragen: {
      titel: "blok.aanvullendevragen.titel",
      illegaleTewerkstelling: {
        gegevensIllegaleWerknemer: {
          titel: "blok.aanvullendevragen.illegaleTewerkstelling.gegevensIllegaleWerknemer.titel"
        },
        gegevensIllegaleWerknemers: {
          lijst: { titel: "blok.aanvullendevragen.illegaleTewerkstelling.gegevensIllegaleWerknemers.lijst.titel" }
        }
      }
    },
    asbestinventarisatie: {
      materiaal: {
        tabelkop: {
          risicoklasse: { title: "blok.asbestinventarisatie.materiaal.tabelkop.risicoklasse.title" },
          hoeveelheid: { title: "blok.asbestinventarisatie.materiaal.tabelkop.hoeveelheid.title" },
          eenheid: { title: "blok.asbestinventarisatie.materiaal.tabelkop.eenheid.title" },
          soortMateriaal: { title: "blok.asbestinventarisatie.materiaal.tabelkop.soortMateriaal.title" }
        },
        paginering: {
          totaalAantal: "blok.asbestinventarisatie.materiaal.paginering.totaalAantal",
          totaalAantalGefilterd: "blok.asbestinventarisatie.materiaal.paginering.totaalAantalGefilterd"
        },
        samengesteldeWaarde: "blok.asbestinventarisatie.materiaal.samengesteldeWaarde"
      }
    },
    autorisatieaanvraag: { gegevensGebruiker: "blok.autorisatieaanvraag.gegevensGebruiker" },
    autorisatieprofiel: { geldigheidTitel: "blok.autorisatieprofiel.geldigheidTitel" },
    bouwwerk: {
      titel: "blok.bouwwerk.titel",
      aardBouwwerk: "blok.bouwwerk.aardBouwwerk",
      typeBouwwerk: "blok.bouwwerk.typeBouwwerk",
      omgevingsvergunning: "blok.bouwwerk.omgevingsvergunning",
      geoLocatie: "blok.bouwwerk.geoLocatie",
      geoLocatieHelptekst: "blok.bouwwerk.geoLocatieHelptekst",
      keuzen: "blok.bouwwerk.keuzen",
      bestanden: {
        titel: "blok.bouwwerk.bestanden.titel",
        uitleg: "blok.bouwwerk.bestanden.uitleg",
        titelSamenvatting: "blok.bouwwerk.bestanden.titelSamenvatting"
      }
    },
    kinderen: {
      kinderenLijst: {
        tabelkop: {
          nummer: {
            title: "blok.kinderen.kinderenLijst.tabelkop.nummer.title",
            hint: "blok.kinderen.kinderenLijst.tabelkop.nummer.hint"
          },
          geboorteDatum: { title: "blok.kinderen.kinderenLijst.tabelkop.geboorteDatum.title" },
          roepnaam: { title: "blok.kinderen.kinderenLijst.tabelkop.roepnaam.title" },
          achternaam: { title: "blok.kinderen.kinderenLijst.tabelkop.achternaam.title" }
        },
        paginering: {
          totaalAantal: "blok.kinderen.kinderenLijst.paginering.totaalAantal",
          totaalAantalGefilterd: "blok.kinderen.kinderenLijst.paginering.totaalAantalGefilterd"
        },
        validatie: {
          verwijderen: "blok.kinderen.kinderenLijst.validatie.verwijderen",
          kopieren: "blok.kinderen.kinderenLijst.validatie.kopieren",
          kindNummersNietUniek: "blok.kinderen.kinderenLijst.validatie.kindNummersNietUniek"
        }
      },
      kind: { samengesteldeWaarde: "blok.kinderen.kind.samengesteldeWaarde" }
    },
    profiel: {
      profielGegevensTitel: "blok.profiel.profielGegevensTitel",
      profielGegevensBekijken: "blok.profiel.profielGegevensBekijken",
      profielGegevensWijzigen: "blok.profiel.profielGegevensWijzigen",
      profielWachtwoordWijzigen: "blok.profiel.profielWachtwoordWijzigen",
      formulier: {
        gebruikersnaam: "blok.profiel.formulier.gebruikersnaam",
        opslaan: "blok.profiel.formulier.opslaan",
        gegevensGewijzigd: "blok.profiel.formulier.gegevensGewijzigd"
      }
    },
    contactvraag: { titel: "blok.contactvraag.titel" },
    digipass: {
      formulier: {
        controleer: "blok.digipass.formulier.controleer",
        success: "blok.digipass.formulier.success",
        failure: "blok.digipass.formulier.failure",
        nietBeschikbaar: "blok.digipass.formulier.nietBeschikbaar"
      }
    },
    getuigen: { titel: "blok.getuigen.titel", helptekst: "blok.getuigen.helptekst" },
    inventarisatie: { inventarisatieHelptekst: "blok.inventarisatie.inventarisatieHelptekst" },
    klacht: { titel: "blok.klacht.titel" },
    klachtenBedrijfsgegevens: { helptekst: "blok.klachtenBedrijfsgegevens.helptekst" },
    locatie: {
      locatieWerkzaamhedenKoptekst: "blok.locatie.locatieWerkzaamhedenKoptekst",
      locatieWerkzaamhedenHelptekst: "blok.locatie.locatieWerkzaamhedenHelptekst",
      samengesteldeWaarde: "blok.locatie.samengesteldeWaarde",
      validatie: { verwijderen: "blok.locatie.validatie.verwijderen" },
      locatieLijst: {
        tabelkop: {
          locatieNaam: { title: "blok.locatie.locatieLijst.tabelkop.locatieNaam.title" },
          postcode: { title: "blok.locatie.locatieLijst.tabelkop.postcode.title" },
          huisnummer: { title: "blok.locatie.locatieLijst.tabelkop.huisnummer.title" },
          toevoeging: { title: "blok.locatie.locatieLijst.tabelkop.toevoeging.title" },
          straatnaam: { title: "blok.locatie.locatieLijst.tabelkop.straatnaam.title" },
          plaats: { title: "blok.locatie.locatieLijst.tabelkop.plaats.title" },
          omschrijving: { title: "blok.locatie.locatieLijst.tabelkop.omschrijving.title" }
        },
        paginering: {
          totaalAantal: "blok.locatie.locatieLijst.paginering.totaalAantal",
          totaalAantalGefilterd: "blok.locatie.locatieLijst.paginering.totaalAantalGefilterd"
        },
        validatie: { verwijderen: "blok.locatie.locatieLijst.validatie.verwijderen" }
      }
    },
    melder: { gegevensContactpersoon: "blok.melder.gegevensContactpersoon" },
    meldingsplicht: { titel: "blok.meldingsplicht.titel" },
    ontwerpendePartij: { titel: "blok.ontwerpendePartij.titel", gegevensGelijk: "blok.ontwerpendePartij.gegevensGelijk" },
    opdrachtgeverBouwproces: { gegevensGelijk: "blok.opdrachtgeverBouwproces.gegevensGelijk" },
    opdrachtgeverVuurOpTankschip: {
      titel: "blok.opdrachtgeverVuurOpTankschip.titel",
      gegevensGelijk: "blok.opdrachtgeverVuurOpTankschip.gegevensGelijk"
    },
    samenvatting: {
      bloknaam: "blok.samenvatting.bloknaam",
      uitleg: "blok.samenvatting.uitleg",
      zaken: "blok.samenvatting.zaken",
      wijzigenNietBeschikbaar: "blok.samenvatting.wijzigenNietBeschikbaar",
      zaaklinksNietBeschikbaar: "blok.samenvatting.zaaklinksNietBeschikbaar",
      documentenNietBeschikbaar: "blok.samenvatting.documentenNietBeschikbaar"
    },
    slachtoffers: { titel: "blok.slachtoffers.titel" },
    uitvoerendePartij: {
      titel: "blok.uitvoerendePartij.titel",
      gegevensGelijk: "blok.uitvoerendePartij.gegevensGelijk",
      coordinatorGegevensGelijk: "blok.uitvoerendePartij.coordinatorGegevensGelijk"
    },
    vestiging: {
      kvknummer: "blok.vestiging.kvknummer",
      vestiging: "blok.vestiging.vestiging",
      vestigingsnummer: "blok.vestiging.vestigingsnummer",
      naamBedrijfInstelling: "blok.vestiging.naamBedrijfInstelling",
      postcode: "blok.vestiging.postcode",
      huisnummer: "blok.vestiging.huisnummer",
      huisnummertoevoeging: "blok.vestiging.huisnummertoevoeging",
      straatnaam: "blok.vestiging.straatnaam",
      plaats: "blok.vestiging.plaats",
      land: "blok.vestiging.land",
      validatie: {
        kvkNummerOfVestigingsnummerNogNietIngevuld: "blok.vestiging.validatie.kvkNummerOfVestigingsnummerNogNietIngevuld",
        combinatieKvkNummerEnVestigingsnummerOnjuist:
          "blok.vestiging.validatie.combinatieKvkNummerEnVestigingsnummerOnjuist",
        meerdereVestigingenGevonden: "blok.vestiging.validatie.meerdereVestigingenGevonden",
        vulOokVestigingsnummerIn: "blok.vestiging.validatie.vulOokVestigingsnummerIn",
        vulOokKvkNummerIn: "blok.vestiging.validatie.vulOokKvkNummerIn"
      }
    },
    vluchtigeOrganischeStoffen: {
      aanvraag: {
        werkzaamheden: { ontheffingsUitleg: "blok.vluchtigeOrganischeStoffen.aanvraag.werkzaamheden.ontheffingsUitleg" }
      }
    },
    werkgever: {
      titel: "blok.werkgever.titel",
      werkgevergegevens: "blok.werkgever.werkgevergegevens",
      werklocatie: "blok.werkgever.werklocatie",
      correspondentieadres: "blok.werkgever.correspondentieadres",
      gegevensWerkgever: "blok.werkgever.gegevensWerkgever"
    },
    werkmomenten: {
      werkmomentenLijst: {
        tabelkop: {
          datumWerkmoment: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.datumWerkmoment.title" },
          aanvangAanwezig: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.aanvangAanwezig.title" },
          eindeAanwezig: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.eindeAanwezig.title" },
          kind: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.kind.title" },
          locatieWerkmoment: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.locatieWerkmoment.title" },
          repetitie: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.repetitie.title" },
          schooldag: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.schooldag.title" },
          schoolweek: { title: "blok.werkmomenten.werkmomentenLijst.tabelkop.schoolweek.title" },
          uitsluitenWerkmomentControle: {
            title: "blok.werkmomenten.werkmomentenLijst.tabelkop.uitsluitenWerkmomentControle.title"
          }
        },
        paginering: {
          totaalAantal: "blok.werkmomenten.werkmomentenLijst.paginering.totaalAantal",
          totaalAantalGefilterd: "blok.werkmomenten.werkmomentenLijst.paginering.totaalAantalGefilterd"
        },
        validatie: {
          verwijderen: "blok.werkmomenten.werkmomentenLijst.validatie.verwijderen",
          beleidsregels: "blok.werkmomenten.werkmomentenLijst.validatie.beleidsregels",
          nietGekoppeldeKinderen: "blok.werkmomenten.werkmomentenLijst.validatie.nietGekoppeldeKinderen",
          kindNummersNietUniek: "blok.werkmomenten.werkmomentenLijst.validatie.kindNummersNietUniek"
        }
      },
      werkmoment: { samengesteldeWaarde: "blok.werkmomenten.werkmoment.samengesteldeWaarde" }
    }
  },
  correspondentieadres: "correspondentieadres",
  placeholders: { tijd: "placeholders.tijd", datum: "placeholders.datum" },
  validatie: {
    geenValideWaarde: "validatie.geenValideWaarde",
    geenValideDatum: "validatie.geenValideDatum",
    nogNietIngevuld: "validatie.nogNietIngevuld",
    nogNietIngevuld_plural: "validatie.nogNietIngevuld_plural",
    periode: { begindatumNaEinddatum: "validatie.periode.begindatumNaEinddatum" },
    geslotenVraagblokItemsBevattenFouten: "validatie.geslotenVraagblokItemsBevattenFouten"
  },
  verplichteVelden: "verplichteVelden",
  "autorisatieaanvraag-formulier-verstuurd": {
    titel: "autorisatieaanvraag-formulier-verstuurd.titel",
    melding: "autorisatieaanvraag-formulier-verstuurd.melding"
  },
  "formulier-niet-in-deze-taal-beschikbaar": "formulier-niet-in-deze-taal-beschikbaar",
  "formulier-verstuurd": {
    titel: "formulier-verstuurd.titel",
    melding: "formulier-verstuurd.melding",
    samenvattingPdfBekijken: "formulier-verstuurd.samenvattingPdfBekijken",
    mijnMeldingen: "formulier-verstuurd.mijnMeldingen"
  },
  "formulier-verstuurd-interne-medewerker": {
    titel: "formulier-verstuurd-interne-medewerker.titel",
    melding: "formulier-verstuurd-interne-medewerker.melding",
    samenvattingPdfBekijken: "formulier-verstuurd-interne-medewerker.samenvattingPdfBekijken",
    mijnMeldingen: "formulier-verstuurd-interne-medewerker.mijnMeldingen"
  },
  "formulier-gewijzigd": {
    titel: "formulier-gewijzigd.titel",
    melding: "formulier-gewijzigd.melding",
    mijnMeldingen: "formulier-gewijzigd.mijnMeldingen"
  },
  "formulier-gewijzigd-interne-medewerker": {
    titel: "formulier-gewijzigd-interne-medewerker.titel",
    melding: "formulier-gewijzigd-interne-medewerker.melding",
    mijnMeldingen: "formulier-gewijzigd-interne-medewerker.mijnMeldingen"
  },
  "formulier-ingetrokken": {
    titel: "formulier-ingetrokken.titel",
    melding: "formulier-ingetrokken.melding",
    mijnMeldingen: "formulier-ingetrokken.mijnMeldingen"
  },
  "formulier-ingetrokken-interne-medewerker": {
    titel: "formulier-ingetrokken-interne-medewerker.titel",
    melding: "formulier-ingetrokken-interne-medewerker.melding",
    mijnMeldingen: "formulier-ingetrokken-interne-medewerker.mijnMeldingen"
  },
  webportaal: "webportaal",
  meldformulier: "meldformulier",
  meldinggegevens: "meldinggegevens",
  "formulier-versturen-mislukt": "formulier-versturen-mislukt",
  "formulier-versturen-mislukt-bestand-fout": "formulier-versturen-mislukt-bestand-fout",
  "formulier-versturen-mislukt-bestand-fouten": "formulier-versturen-mislukt-bestand-fouten",
  "formulier-versturen-mislukt-validatie-fouten": "formulier-versturen-mislukt-validatie-fouten",
  "formulier-versturen-mislukt-herstel-validatie-fouten": "formulier-versturen-mislukt-herstel-validatie-fouten",
  verzenden: "verzenden",
  "verzenden-bezig": "verzenden-bezig",
  volgendeStap: "volgendeStap",
  vorigeStap: "vorigeStap",
  zoeken: "zoeken",
  invoerTekst: "invoerTekst",
  geenResultatenGevonden: "geenResultatenGevonden",
  importeren: "importeren",
  importeerFouten: "importeerFouten",
  importeerOntheffingKinderarbeidResultaat: "importeerOntheffingKinderarbeidResultaat",
  bestandToevoegen: "bestandToevoegen",
  bestandSelecteren: "bestandSelecteren",
  bestandenToevoegen: "bestandenToevoegen",
  bestandVerwijderen: "bestandVerwijderen",
  bijlageSlepen: "bijlageSlepen",
  bijlageLoslaten: "bijlageLoslaten",
  importBestandSlepen: "importBestandSlepen",
  importBestandLoslaten: "importBestandLoslaten",
  geenBijlagen: "geenBijlagen",
  geenBestand: "geenBestand",
  tonen: "tonen",
  verbergen: "verbergen",
  toevoegen: "toevoegen",
  verwijderen: "verwijderen",
  kopieren: "kopieren",
  verwijderItem: "verwijderItem",
  wijzigen: "wijzigen",
  "help-toggle": "help-toggle",
  taalselectie: {
    taalKiezen: "taalselectie.taalKiezen",
    nl: "taalselectie.nl",
    en: "taalselectie.en",
    ro: "taalselectie.ro",
    pl: "taalselectie.pl",
    de: "taalselectie.de"
  },
  auth: {
    gegevensControleren: "auth.gegevensControleren",
    inloggen: "auth.inloggen",
    uitloggen: "auth.uitloggen",
    ingelogdAls: "auth.ingelogdAls",
    doorverwezenNaarLogin: "auth.doorverwezenNaarLogin",
    doorverwezenNaarLogout: "auth.doorverwezenNaarLogout",
    geenAutorisatie: "auth.geenAutorisatie",
    geenAvgAutorisatie: "auth.geenAvgAutorisatie"
  },
  breadcrumbs: {
    inspectieSzw: {
      url: "breadcrumbs.inspectieSzw.url",
      titel: "breadcrumbs.inspectieSzw.titel",
      melden: {
        url: "breadcrumbs.inspectieSzw.melden.url",
        titel: "breadcrumbs.inspectieSzw.melden.titel",
        formulierInformatie: {
          titel: "breadcrumbs.inspectieSzw.melden.formulierInformatie.titel",
          url: {
            werkbak: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.werkbak",
            duikarbeid: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.duikarbeid",
            contact: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.contact",
            klacht: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.klacht",
            klachten: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.klachten",
            ongeval: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.ongeval",
            ontheffingLiften: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.ontheffingLiften",
            asbestRisicoklasse1: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.asbestRisicoklasse1",
            vluchtigeOrganischeStoffen: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.vluchtigeOrganischeStoffen",
            vuurOpTankschip: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.vuurOpTankschip",
            bouwproces: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.bouwproces",
            biologischeAgentia: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.biologischeAgentia",
            ontheffingNachtarbeid: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.ontheffingNachtarbeid",
            productveiligheid: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.productveiligheid",
            cbi: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.cbi",
            ontheffingKinderarbeid: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.ontheffingKinderarbeid",
            asbestverwijderingLavs: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.asbestverwijderingLavs",
            asbestverwijdering: "breadcrumbs.inspectieSzw.melden.formulierInformatie.url.asbestverwijdering"
          }
        },
        meldingToevoegen: {
          url: "breadcrumbs.inspectieSzw.melden.meldingToevoegen.url",
          titel: "breadcrumbs.inspectieSzw.melden.meldingToevoegen.titel"
        },
        mijnMeldingen: {
          url: "breadcrumbs.inspectieSzw.melden.mijnMeldingen.url",
          titel: "breadcrumbs.inspectieSzw.melden.mijnMeldingen.titel"
        },
        mijnProfiel: {
          url: "breadcrumbs.inspectieSzw.melden.mijnProfiel.url",
          titel: "breadcrumbs.inspectieSzw.melden.mijnProfiel.titel"
        },
        onderhoud: {
          url: "breadcrumbs.inspectieSzw.melden.onderhoud.url",
          titel: "breadcrumbs.inspectieSzw.melden.onderhoud.titel"
        },
        zoek: {
          kennisgevingen: {
            url: "breadcrumbs.inspectieSzw.melden.zoek.kennisgevingen.url",
            titel: "breadcrumbs.inspectieSzw.melden.zoek.kennisgevingen.titel"
          }
        },
        beheer: {
          autorisaties: {
            url: "breadcrumbs.inspectieSzw.melden.beheer.autorisaties.url",
            titel: "breadcrumbs.inspectieSzw.melden.beheer.autorisaties.titel"
          },
          mededeling: {
            url: "breadcrumbs.inspectieSzw.melden.beheer.mededeling.url",
            titel: "breadcrumbs.inspectieSzw.melden.beheer.mededeling.titel"
          }
        }
      }
    }
  },
  profiel: {
    mijnProfiel: {
      titel: "profiel.mijnProfiel.titel",
      helptekst: "profiel.mijnProfiel.helptekst",
      profielgegevensAanvullen: "profiel.mijnProfiel.profielgegevensAanvullen"
    }
  },
  collection: {
    opslaan: "collection.opslaan",
    toonDetails: "collection.toonDetails",
    sluitDetails: "collection.sluitDetails"
  },
  melding: {
    status: {
      Concept: "melding.status.Concept",
      Ingediend: "melding.status.Ingediend",
      Gewijzigd: "melding.status.Gewijzigd",
      Ingetrokken: "melding.status.Ingetrokken",
      Afgemeld: "melding.status.Afgemeld"
    },
    meldingen: {
      geenResultaten: "melding.meldingen.geenResultaten",
      sorteerKolom: {
        oplopend: "melding.meldingen.sorteerKolom.oplopend",
        aflopend: "melding.meldingen.sorteerKolom.aflopend",
        isOplopendGesorteerd: "melding.meldingen.sorteerKolom.isOplopendGesorteerd",
        isAflopendGesorteerd: "melding.meldingen.sorteerKolom.isAflopendGesorteerd"
      },
      tabelkop: {
        meldingnummer: { title: "melding.meldingen.tabelkop.meldingnummer.title" },
        vestigingsnummer: { title: "melding.meldingen.tabelkop.vestigingsnummer.title" },
        vestigingsnaam: { title: "melding.meldingen.tabelkop.vestigingsnaam.title" },
        formulier: { title: "melding.meldingen.tabelkop.formulier.title" },
        aangemaaktOp: { title: "melding.meldingen.tabelkop.aangemaaktOp.title" },
        ingetrokkenOp: { title: "melding.meldingen.tabelkop.ingetrokkenOp.title" },
        gewijzigdDoor: { title: "melding.meldingen.tabelkop.gewijzigdDoor.title" },
        laatstGewijzigdDoor: { title: "melding.meldingen.tabelkop.laatstGewijzigdDoor.title" },
        gewijzigdOp: { title: "melding.meldingen.tabelkop.gewijzigdOp.title" },
        locatiePlaats: { title: "melding.meldingen.tabelkop.locatiePlaats.title" },
        locatieAdres: { title: "melding.meldingen.tabelkop.locatieAdres.title" },
        locatiePostcode: { title: "melding.meldingen.tabelkop.locatiePostcode.title" },
        aanvang: { title: "melding.meldingen.tabelkop.aanvang.title" },
        beeindiging: { title: "melding.meldingen.tabelkop.beeindiging.title" },
        status: { title: "melding.meldingen.tabelkop.status.title" },
        contactpersoon: { title: "melding.meldingen.tabelkop.contactpersoon.title" }
      }
    },
    mijnMeldingen: {
      titel: "melding.mijnMeldingen.titel",
      subTitel: "melding.mijnMeldingen.subTitel",
      nieuweMelding: "melding.mijnMeldingen.nieuweMelding",
      kopieren: "melding.mijnMeldingen.kopieren",
      wijzigen: "melding.mijnMeldingen.wijzigen",
      intrekken: "melding.mijnMeldingen.intrekken",
      vertaalopdracht: "melding.mijnMeldingen.vertaalopdracht",
      vertalingInvoeren: "melding.mijnMeldingen.vertalingInvoeren",
      vertalingVeldLabelToevoeging: "melding.mijnMeldingen.vertalingVeldLabelToevoeging",
      missendeVertalingen: "melding.mijnMeldingen.missendeVertalingen",
      gekozenTaalIsNietGelijkAanOorspronkelijkeTaal: "melding.mijnMeldingen.gekozenTaalIsNietGelijkAanOorspronkelijkeTaal",
      meldingKanNietGewijzigdWordenInAndereTaal: "melding.mijnMeldingen.meldingKanNietGewijzigdWordenInAndereTaal",
      koppelZaak: {
        title: "melding.mijnMeldingen.koppelZaak.title",
        toelichting: "melding.mijnMeldingen.koppelZaak.toelichting"
      },
      wijzigenAanvraag: {
        titel: "melding.mijnMeldingen.wijzigenAanvraag.titel",
        tekst: "melding.mijnMeldingen.wijzigenAanvraag.tekst",
        confirm: "melding.mijnMeldingen.wijzigenAanvraag.confirm",
        cancel: "melding.mijnMeldingen.wijzigenAanvraag.cancel"
      },
      wijzigenVerzoek: {
        titel: "melding.mijnMeldingen.wijzigenVerzoek.titel",
        tekst: "melding.mijnMeldingen.wijzigenVerzoek.tekst",
        confirm: "melding.mijnMeldingen.wijzigenVerzoek.confirm",
        cancel: "melding.mijnMeldingen.wijzigenVerzoek.cancel"
      }
    },
    toevoegen: {
      titel: "melding.toevoegen.titel",
      meldingen: "melding.toevoegen.meldingen",
      aanvragen: "melding.toevoegen.aanvragen",
      verzoeken: "melding.toevoegen.verzoeken"
    }
  },
  zoek: {
    titel: "zoek.titel",
    meldingen: {
      titel: "zoek.meldingen.titel",
      resultaten: {
        geen: "zoek.meldingen.resultaten.geen",
        een: "zoek.meldingen.resultaten.een",
        meerdere: "zoek.meldingen.resultaten.meerdere"
      },
      reset: "zoek.meldingen.reset",
      criteria: {
        snelfilter: "zoek.meldingen.criteria.snelfilter",
        snelfilterasbest: "zoek.meldingen.criteria.snelfilterasbest",
        meldingtype: "zoek.meldingen.criteria.meldingtype",
        meldingnummer: "zoek.meldingen.criteria.meldingnummer",
        plaatsLocatie: "zoek.meldingen.criteria.plaatsLocatie",
        postcodeLocatie: "zoek.meldingen.criteria.postcodeLocatie",
        ingediendVanaf: "zoek.meldingen.criteria.ingediendVanaf",
        ingediendTotEnMet: "zoek.meldingen.criteria.ingediendTotEnMet",
        activiteitVanaf: "zoek.meldingen.criteria.activiteitVanaf",
        activiteitTotEnMet: "zoek.meldingen.criteria.activiteitTotEnMet"
      }
    }
  },
  beheer: {
    titel: "beheer.titel",
    autorisaties: {
      titel: "beheer.autorisaties.titel",
      zoeken: "beheer.autorisaties.zoeken",
      nieuw: "beheer.autorisaties.nieuw",
      terug: "beheer.autorisaties.terug",
      resultaten: "beheer.autorisaties.resultaten",
      geenResultaten: "beheer.autorisaties.geenResultaten",
      sorteerKolom: {
        oplopend: "beheer.autorisaties.sorteerKolom.oplopend",
        aflopend: "beheer.autorisaties.sorteerKolom.aflopend",
        isOplopendGesorteerd: "beheer.autorisaties.sorteerKolom.isOplopendGesorteerd",
        isAflopendGesorteerd: "beheer.autorisaties.sorteerKolom.isAflopendGesorteerd"
      },
      tabelkop: {
        bedrijfsnaam: "beheer.autorisaties.tabelkop.bedrijfsnaam",
        gebruikersnaam: "beheer.autorisaties.tabelkop.gebruikersnaam",
        achternaam: "beheer.autorisaties.tabelkop.achternaam",
        kvknummer: "beheer.autorisaties.tabelkop.kvknummer",
        vestigingsnummer: "beheer.autorisaties.tabelkop.vestigingsnummer",
        geldig: "beheer.autorisaties.tabelkop.geldig"
      }
    },
    autorisatiedetail: { titel: "beheer.autorisatiedetail.titel", alleenLezen: "beheer.autorisatiedetail.alleenLezen" },
    mededeling: {
      titel: "beheer.mededeling.titel",
      inhoud: "beheer.mededeling.inhoud",
      voorbeeld: "beheer.mededeling.voorbeeld",
      actief: "beheer.mededeling.actief"
    }
  },
  gebruikersmenu: { tonen: "gebruikersmenu.tonen", verbergen: "gebruikersmenu.verbergen" },
  dialoog: {
    annuleren: "dialoog.annuleren",
    verderGaan: "dialoog.verderGaan",
    formulierVerlaten: {
      titel: "dialoog.formulierVerlaten.titel",
      tekst: "dialoog.formulierVerlaten.tekst",
      verlaten: "dialoog.formulierVerlaten.verlaten",
      doorgaan: "dialoog.formulierVerlaten.doorgaan"
    },
    meldingIntrekken: {
      titel: "dialoog.meldingIntrekken.titel",
      tekst: "dialoog.meldingIntrekken.tekst",
      intrekken: "dialoog.meldingIntrekken.intrekken",
      annuleren: "dialoog.meldingIntrekken.annuleren"
    },
    sessieVerloopt: {
      titel: "dialoog.sessieVerloopt.titel",
      tekst: "dialoog.sessieVerloopt.tekst",
      uitloggen: "dialoog.sessieVerloopt.uitloggen",
      sessieVerlengen: "dialoog.sessieVerloopt.sessieVerlengen"
    },
    bestandOverschrijven: {
      titel: "dialoog.bestandOverschrijven.titel",
      tekst: "dialoog.bestandOverschrijven.tekst",
      overschrijven: "dialoog.bestandOverschrijven.overschrijven",
      overslaan: "dialoog.bestandOverschrijven.overslaan"
    },
    navigerenMetValidatiefouten: {
      titel: "dialoog.navigerenMetValidatiefouten.titel",
      tekst: "dialoog.navigerenMetValidatiefouten.tekst",
      corrigeren: "dialoog.navigerenMetValidatiefouten.corrigeren",
      verderGaan: "dialoog.navigerenMetValidatiefouten.verderGaan"
    },
    vraagBlokVerwijderen: {
      titel: "dialoog.vraagBlokVerwijderen.titel",
      tekst: "dialoog.vraagBlokVerwijderen.tekst",
      cancel: "dialoog.vraagBlokVerwijderen.cancel",
      confirm: "dialoog.vraagBlokVerwijderen.confirm"
    },
    taalWijzigenDialoog: {
      titel: "dialoog.taalWijzigenDialoog.titel",
      tekstMeldingKanNietgewijzigdWorden: "dialoog.taalWijzigenDialoog.tekstMeldingKanNietgewijzigdWorden",
      doorgaanInTaalIndiener: "dialoog.taalWijzigenDialoog.doorgaanInTaalIndiener"
    }
  },
  tabel: {
    kolom: { sortering: { oplopend: "tabel.kolom.sortering.oplopend", aflopend: "tabel.kolom.sortering.aflopend" } }
  },
  vorige: "vorige",
  volgende: "volgende",
  bezigMetLaden: "bezigMetLaden",
  oepsErGingIetsFout: "oepsErGingIetsFout",
  opslaan: "opslaan",
  zoekenPlaceholder: "zoekenPlaceholder",
  zoekenButton: "zoekenButton",
  vestigingenSearch: {
    zoekTitel: "vestigingenSearch.zoekTitel",
    zoeken: "vestigingenSearch.zoeken",
    zoekenScreenReaderAanvulling: "vestigingenSearch.zoekenScreenReaderAanvulling",
    wissen: "vestigingenSearch.wissen",
    wissenScreenReaderAanvulling: "vestigingenSearch.wissenScreenReaderAanvulling",
    selecteer: "vestigingenSearch.selecteer",
    selecteerScreenReaderAanvulling: "vestigingenSearch.selecteerScreenReaderAanvulling",
    wijzigen: "vestigingenSearch.wijzigen",
    vestigingGegevens: "vestigingenSearch.vestigingGegevens",
    naam: "vestigingenSearch.naam",
    kvknummer: "vestigingenSearch.kvknummer",
    vestigingsnummer: "vestigingenSearch.vestigingsnummer",
    plaats: "vestigingenSearch.plaats",
    land: "vestigingenSearch.land",
    postcode: "vestigingenSearch.postcode",
    straat: "vestigingenSearch.straat",
    huisnummer: "vestigingenSearch.huisnummer",
    adres: "vestigingenSearch.adres",
    toelichting: "vestigingenSearch.toelichting",
    of: "vestigingenSearch.of",
    zoekresultaten: {
      geen: "vestigingenSearch.zoekresultaten.geen",
      een: "vestigingenSearch.zoekresultaten.een",
      meerdere: "vestigingenSearch.zoekresultaten.meerdere"
    }
  },
  brpSearch: { controleer: "brpSearch.controleer", connectieError: "brpSearch.connectieError" },
  captcha: { tekst: "captcha.tekst", validatie: { aangevinkt: "captcha.validatie.aangevinkt" } },
  document: { naam: "document.naam", datumTijd: "document.datumTijd" }
};

export { translationKeys };
