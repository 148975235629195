import { MededelingState } from "../store";
import { MededelingActions, SET_MEDEDELINGEN, SET_MEDEDELINGEN_LOADING } from "./../actions";

const initialState: MededelingState = {
  hasApiError: false,
  isLoading: true,
  mededelingen: []
};

const mededelingReducer = (state: MededelingState = initialState, action: MededelingActions): MededelingState => {
  switch (action.type) {
    case SET_MEDEDELINGEN_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasApiError: action.hasApiError
      };
    case SET_MEDEDELINGEN:
      return {
        ...state,
        mededelingen: action.mededelingen
      };
    default:
      return state;
  }
};

export default mededelingReducer;
