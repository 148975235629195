import { Fragment } from "react";
import { Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { PagerProps } from "./pager.component.interfaces";

const HALF = 0.5;
const getPagingRange = (current: number, min: number, total: number, length: number) => {
  if (length > total) {
    length = total;
  }

  let start = current - Math.floor(length * HALF);
  start = Math.max(start, min);
  start = Math.min(start, min + total - length);

  return Array.from({ length: length }, (_, i) => start + i);
};

const PageItems = (props: PagerProps) => {
  const minPage = 1;
  const pageLength = 5;
  const pageRange = getPagingRange(props.currentPage, minPage, props.pageCount, pageLength);

  if (props.currentPage > props.pageCount && props.pageCount !== 0) {
    setTimeout(() => props.onPageChange(props.pageCount), 0);
  } else if (props.currentPage === 0 && props.pageCount > 0) {
    setTimeout(() => props.onPageChange(1), 0);
  }

  return pageRange.map((number) => {
    return (
      <Pagination.Item
        key={number}
        active={number === props.currentPage}
        onClick={() => props.onPageChange(number)}
        disabled={props.disabled}
      >
        {number}
      </Pagination.Item>
    );
  });
};

export const Pager = (props: PagerProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Pagination>
        <Pagination.Item
          disabled={1 === props.currentPage || props.disabled}
          onClick={() => props.onPageChange(Math.max(0, props.currentPage - 1))}
        >
          ‹ {t(translationKeys.vorige)}
        </Pagination.Item>
        {PageItems(props)}
        <Pagination.Item
          disabled={props.pageCount === props.currentPage || props.disabled}
          onClick={() => props.onPageChange(Math.min(props.pageCount, props.currentPage + 1))}
        >
          {t(translationKeys.volgende)} ›
        </Pagination.Item>
        {props.totalsDescription && (
          <Pagination.Item disabled={true} className="total">
            {props.totalsDescription}
          </Pagination.Item>
        )}
      </Pagination>
    </Fragment>
  );
};
