import { State } from "store/state";
import { store } from "store/store";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsImportBestandGeimporteerd extends VraagExpression {
  get operator(): string {
    return IsImportBestandGeimporteerd.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, _context: ExpressionContext, _vraagProperty?: string): boolean => {
    const bestandImportState = (store.getState() as State).bestandImport;
    return bestandImportState.result != null;
  };

  protected clone = (instance: IsImportBestandGeimporteerd): IsImportBestandGeimporteerd =>
    new IsImportBestandGeimporteerd(instance.vraagProperty);

  static fromJson = (json: any): IsImportBestandGeimporteerd => new IsImportBestandGeimporteerd(json.vraag);

  static operator = (): string => "isImportBestandGeimporteerd";

  static matches = (json: any): boolean => json?.operator === IsImportBestandGeimporteerd.operator();
}
