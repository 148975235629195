import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BijlageVragen } from "./bijlage-vragen";
import { ContactpersoonVragen } from "./contactpersoon-vragen";
import { ZaakPincodeVragen } from "./zaak-pincode-vragen";

export class UploadRieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public aanleverenDocumenten: Tekst,
    public zaakPincode: ZaakPincodeVragen,
    public contactpersoonTitel: Tekst,
    public contactpersoon: ContactpersoonVragen,
    public aantalWerknemersWerkzaam: Vraag,
    public lidVanBranchevereniging: Vraag,
    public welkeBranchevereniging: Vraag,
    public bestandenTitel: Tekst,
    public bestanden: BijlageVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): UploadRieVragen {
    return new UploadRieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.aanleverenDocumenten`, json.aanleverenDocumenten),
      ZaakPincodeVragen.fromJson(`${key}.zaakPincode`, json.zaakPincode),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalWerknemersWerkzaam`, json.aantalWerknemersWerkzaam),
      jsonHelpers.toMeerkeuzeVraag(`${key}.lidVanBranchevereniging`, json.lidVanBranchevereniging),
      jsonHelpers.toTekstVraag(`${key}.welkeBranchevereniging`, json.welkeBranchevereniging),
      jsonHelpers.toTitel(`${key}.bestandenTitel`, json.bestandenTitel),
      BijlageVragen.fromJson(`${key}.bestanden`, json.bestanden)
    );
  }
}
