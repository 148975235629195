import { Dictionary, Formuliercategorie, Formuliertype, Language } from "../../application";
import { VraagBlokken } from "../blok/vraag-blokken";
import { Formulier } from "./formulier";

export abstract class FormulierBase implements Formulier {
  public ondersteundeTalen: Language[];
  public titel: Dictionary<string>;
  public helptekst: Dictionary<string>;
  public captchaRequired: boolean;
  public categorie?: Formuliercategorie | null;

  constructor(public type: Formuliertype, public blokken: VraagBlokken, json: any) {
    this.ondersteundeTalen = json.ondersteundeTalen;
    this.titel = json.titel;
    this.helptekst = json.helptekst;
    this.captchaRequired = json.captchaRequired;
    this.categorie = json.formuliercategorie;
  }
}
