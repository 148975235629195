import { landHelpers } from "helpers";
import { Land } from "models/api";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { State } from "store/state";
import { SamenvattingFieldOwnProps } from "../samenvatting-field/samenvatting-field.component.interfaces";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { withSamenvattingVraag } from "./../with-samenvatting-vraag";
import { WithSamenvattingLandvraagProps } from "./with-samenvatting-landvraag.interfaces";

export const withSamenvattingLandvraag = <P extends object>(
  Component: React.ComponentType<P & SamenvattingFieldOwnProps>
) => {
  return (props: P & WithSamenvattingLandvraagProps) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(withSamenvattingVraag(Component));

    const { landen } = useSelector<State, { landen: Land[] }>(
      (state: State) => ({
        landen: state.landen.landen
      }),
      shallowEqual
    );

    return (
      <SamenvattingVraagComponent
        {...props}
        item={props.vraag}
        getAntwoord={(antwoordWaarde: string) => {
          return landHelpers.getLandNaam(landen, antwoordWaarde);
        }}
      />
    );
  };
};
