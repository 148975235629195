import { Configuration } from "../configuration";
import { DigipassResponse } from "../models/api";
import { post } from "./api";

const digipassUri = `${Configuration.apiUrl}/melding/digipass`;

const checkDigipass = (pincode: string): Promise<DigipassResponse> =>
  post({
    uri: digipassUri,
    data: pincode,
    success: (r) => DigipassResponse.fromJson(r)
  });

export const digipassApi = {
  checkDigipass
};
