import { AvvArtikel10Formulier } from "models/api";
import { connect } from "react-redux";
import { getAvvArtikel10formulierMelding, initAvvArtikel10Formulier, initAvvArtikel10FormulierFromCopy } from "thunks";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { AvvArtikel10FormulierComponent } from "./avv-artikel10-formulier.component";
import {
  AvvArtikel10FormulierComponentDispatchProps,
  AvvArtikel10FormulierComponentStateProps
} from "./avv-artikel10-formulier.component.interfaces";

const mapStateToProps = (state: State): AvvArtikel10FormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.avvartikel10
      ? (state.vragen.formulier as AvvArtikel10Formulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AvvArtikel10FormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initAvvArtikel10FormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getAvvArtikel10formulierMelding(meldingId));
    } else {
      dispatch(initAvvArtikel10Formulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AvvArtikel10FormulierComponent);
