import { useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { ReactComponent as Info } from "../../assets/icons/rijksoverheid/2002-info.svg";
import { MededelingProps } from "./mededeling.component.interfaces";
import i18n from 'i18next';

export const Mededeling = (props: MededelingProps) => {
  const { onLoad } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const currentLanguage = i18n.language;
  const currentMededeling = props.mededelingen?.find(m => m.language.toLowerCase() === currentLanguage.toLowerCase());
  const isEnabled = currentMededeling?.isEnabled ?? false; 
  return isEnabled ? (
    <div role="complementary">
      <Alert variant="info" className="mededeling-bar">
        <Row>
          <Col className="icon">
            <Info role="presentation" />
          </Col>
          <Col>
            <ReactMarkdown children={currentMededeling?.content} className="mededeling" />
          </Col>
        </Row>
      </Alert>
    </div>
  ) : null;
};
