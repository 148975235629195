import { Formuliertype } from "../../application";
import { BibobFormulierVraagBlokken } from "./bibob-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class BibobFormulier extends FormulierBase {
  constructor(public blokken: BibobFormulierVraagBlokken, json: any) {
    super(Formuliertype.bibob, blokken, json);
  }

  static fromJson(json: any): BibobFormulier {
    return new BibobFormulier(BibobFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
