import { Formuliertype } from "../../application";
import { AsbestverwijderingLavsV2FormulierVraagBlokken } from "./asbestverwijdering-lavs-v2-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AsbestverwijderingLavsV2Formulier extends FormulierBase {
  constructor(public blokken: AsbestverwijderingLavsV2FormulierVraagBlokken, json: any) {
    super(Formuliertype.asbestverwijderingLavsV2, blokken, json);
  }

  static fromJson(json: any): AsbestverwijderingLavsV2Formulier {
    return new AsbestverwijderingLavsV2Formulier(AsbestverwijderingLavsV2FormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
