import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class AutorisatiesZoekenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public titel: Dictionary<string>,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public bedrijfsnaam: Vraag,
    public gebruikersnaam: Vraag,
    public achternaam: Vraag,
    public kvkNummer: Vraag,
    public vestigingsnummer: Vraag,
    public geldig: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AutorisatiesZoekenVragen {
    return new AutorisatiesZoekenVragen(
      key,
      json.volgnummer,
      json.titel,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTekstVraag(`${key}.bedrijfsnaam`, json.bedrijfsnaam),
      jsonHelpers.toTekstVraag(`${key}.gebruikersnaam`, json.gebruikersnaam),
      jsonHelpers.toTekstVraag(`${key}.achternaam`, json.achternaam),
      jsonHelpers.toTekstVraag(`${key}.kvkNummer`, json.kvkNummer),
      jsonHelpers.toTekstVraag(`${key}.vestigingsnummer`, json.vestigingsnummer),
      jsonHelpers.toMeerkeuzeVraag(`${key}.geldig`, json.geldig)
    );
  }
}
