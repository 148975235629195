import { Formuliertype } from "../../application";
import { AutorisatieaanvraagFormulierVraagBlokken } from "./autorisatieaanvraag-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AutorisatieaanvraagFormulier extends FormulierBase {
  constructor(public blokken: AutorisatieaanvraagFormulierVraagBlokken, json: any) {
    super(Formuliertype.autorisatieaanvraag, blokken, json);
  }

  static fromJson(json: any): AutorisatieaanvraagFormulier {
    return new AutorisatieaanvraagFormulier(AutorisatieaanvraagFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
