import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class TankschipWerkzaamhedenAntwoorden extends AntwoordBlok {
  constructor(public aardWerkzaamheden: Antwoord<string>, public plaatsWerkzaamheden: Antwoord<string>) {
    super();
  }

  static initialize(): TankschipWerkzaamhedenAntwoorden {
    return new TankschipWerkzaamhedenAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): TankschipWerkzaamhedenAntwoorden {
    return new TankschipWerkzaamhedenAntwoorden(
      Antwoord.fromJson<string>(json.aardWerkzaamheden),
      Antwoord.fromJson<string>(json.plaatsWerkzaamheden)
    );
  }
}
