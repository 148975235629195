import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class DwangDoorWerkgeverAntwoorden extends AntwoordBlok {
  constructor(public watIsErAanDeHand: Antwoord<string[]>) {
    super();
  }

  static initialize(): DwangDoorWerkgeverAntwoorden {
    return new DwangDoorWerkgeverAntwoorden(new Antwoord<string[]>([]));
  }

  static fromJson(json: any): DwangDoorWerkgeverAntwoorden {
    return json ? new DwangDoorWerkgeverAntwoorden(Antwoord.fromJson<string[]>(json.watIsErAanDeHand)) : this.initialize();
  }
}
