import { jsonHelpers } from "../../../helpers";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class UitgeoefendeActiviteitenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public uitgeoefendeActiviteitenEnOmgevingsfactoren: Tekst,
    public hoofdactiviteit: Vraag,
    public doel: Vraag,
    public activiteitenMetGevaarlijkeStoffen: Vraag,
    public informatieOverDeOnmiddellijkeOmgeving: Vraag,
    public factorenDieEenZwaarOngevalKunnenVeroorzaken: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly);
  }

  static fromJson(key: string, json: any): UitgeoefendeActiviteitenVragen {
    return new UitgeoefendeActiviteitenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTitel(
        `${key}.uitgeoefendeActiviteitenEnOmgevingsfactoren`,
        json.uitgeoefendeActiviteitenEnOmgevingsfactoren
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.hoofdactiviteit`, json.hoofdactiviteit),
      jsonHelpers.toTekstVraag(`${key}.doel`, json.doel),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.activiteitenMetGevaarlijkeStoffen`,
        json.activiteitenMetGevaarlijkeStoffen
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.informatieOverDeOnmiddellijkeOmgeving`,
        json.informatieOverDeOnmiddellijkeOmgeving
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.factorenDieEenZwaarOngevalKunnenVeroorzaken`,
        json.factorenDieEenZwaarOngevalKunnenVeroorzaken
      )
    );
  }
}
