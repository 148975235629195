import { Dictionary, VraagBlokCollectionType } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { LocatieWerkzaamhedenVragen } from "./locatie-werkzaamheden-vragen";
import { Tekst } from "../tekst";
import { jsonHelpers } from "../../../helpers";

export class LocatiesVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public locatieWerkzaamhedenToelichting: Tekst,
    public locaties: VraagBlokCollection<LocatieWerkzaamhedenVragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): LocatiesVragen {
    return new LocatiesVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(`${key}.locatieWerkzaamhedenToelichting`, json.locatieWerkzaamhedenToelichting),
      VraagBlokCollection.fromJson<LocatieWerkzaamhedenVragen>(
        LocatieWerkzaamhedenVragen.fromJson,
        `${key}.locaties`,
        json.locaties,
        VraagBlokCollectionType.locatiesWerkzaamheden,
        false
      )
    );
  }
}
