import { Configuration } from "../configuration";
import {
  AutorisatieProfielMelding,
  AutorisatieProfielMeldingAntwoordBlokken,
  MeldingBase,
  Meldingrechten,
  ProfielMelding,
  ProfielMeldingAntwoordBlokken
} from "../models/api";
import { Formuliertype } from "../models/application";
import { get } from "./api";
import { meldingApi } from "./melding-api";

const getMijnProfielMelding = (): Promise<ProfielMelding> => {
  const formulierType = Formuliertype.profiel;
  const toJson = MeldingBase.fromJson(ProfielMelding, ProfielMeldingAntwoordBlokken.fromJson);
  return get({
    uri: `${Configuration.apiUrl}/${meldingApi.determineUrlSegment(formulierType)}/${formulierType}`,
    success: (r) => toJson(r)
  });
};

const isProfielCompleet = (): Promise<boolean> => {
  return get({
    uri: `${Configuration.apiUrl}/${meldingApi.determineUrlSegment(Formuliertype.profiel)}/${
      Formuliertype.profiel
    }/isComplete`,
    success: (r) => r
  });
};

const getAutorisatieProfielMelding = (profielId: string) =>
  meldingApi.getMelding(
    Formuliertype.autorisatieProfiel,
    profielId,
    MeldingBase.fromJson(AutorisatieProfielMelding, AutorisatieProfielMeldingAntwoordBlokken.fromJson)
  );

const getAutorisatieProfielMeldingrechten = (profielId: string) =>
  meldingApi.getMeldingrechten(Formuliertype.autorisatieProfiel, profielId, Meldingrechten.fromJson);

export const profielApi = {
  isProfielCompleet,
  getAutorisatieProfielMelding,
  getAutorisatieProfielMeldingrechten,
  getMijnProfielMelding
};
