import { NederlandsAdresAntwoorden } from ".";
import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class LocatieLavsAntwoorden extends AntwoordBlok {
  constructor(
    public perceelcode: Antwoord<string>,
    public omschrijving: Antwoord<string>,
    public nederlandsAdres: NederlandsAdresAntwoorden | null,
    public coordinaatX: Antwoord<string>,
    public coordinaatY: Antwoord<string>,
    public latitude: Antwoord<string>,
    public longitude: Antwoord<string>
  ) {
    super();
  }

  static initialize() {
    return new LocatieLavsAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      NederlandsAdresAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new LocatieLavsAntwoorden(
      Antwoord.fromJson(json.perceelcode),
      Antwoord.fromJson(json.omschrijving),
      NederlandsAdresAntwoorden.fromJson(json.nederlandsAdres),
      Antwoord.fromJson(json.coordinaatX),
      Antwoord.fromJson(json.coordinaatY),
      Antwoord.fromJson(json.latitude),
      Antwoord.fromJson(json.longitude)
    );
  }
}
