import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { SlachtofferInformatieAntwoorden } from "./slachtoffer-informatie-antwoorden";

export class SlachtoffersAntwoorden extends AntwoordBlok {
  constructor(
    public aantalSlachtoffersGevaarlijkeStoffen: Antwoord<number | null>,
    public aantalSlachtoffers: Antwoord<number | null>,
    public aantalDerdenSlachtoffers: Antwoord<number | null>,
    public gegevensSlachtoffersBekend: Antwoord<string>,
    public slachtofferInformatieLijst: AntwoordBlokCollection<SlachtofferInformatieAntwoorden> | null
  ) {
    super();
  }

  static initialize(): SlachtoffersAntwoorden {
    return new SlachtoffersAntwoorden(
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      AntwoordBlokCollection.initialize([])
    );
  }

  static fromJson(json: any): SlachtoffersAntwoorden {
    return new SlachtoffersAntwoorden(
      Antwoord.fromJson(json.aantalSlachtoffersGevaarlijkeStoffen),
      Antwoord.fromJson(json.aantalSlachtoffers),
      Antwoord.fromJson(json.aantalDerdenSlachtoffers),
      Antwoord.fromJson(json.gegevensSlachtoffersBekend),
      AntwoordBlokCollection.fromJson(SlachtofferInformatieAntwoorden.fromJson, json.slachtofferInformatieLijst)
    );
  }
}
