import { dateHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { Expression } from "./expression";

export class IsBefore extends Expression {
  get operator(): string {
    return IsBefore.operator();
  }

  private readonly DEFAULT_TIME_ANTWOORD_WAARDE = "00:00";

  constructor(
    private readonly vraagDatumProperty: string,
    private readonly vraagTijdProperty: string,
    private readonly otherVraagDatumProperty: string,
    private readonly otherVraagTijdProperty: string
  ) {
    super();
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    let result = false;

    const datum = context.getAntwoord(this.vraagDatumProperty)?.waarde;
    const tijd = this.vraagTijdProperty
      ? context.getAntwoord(this.vraagTijdProperty)?.waarde
      : this.DEFAULT_TIME_ANTWOORD_WAARDE;
    const otherDatum = context.getAntwoord(this.otherVraagDatumProperty)?.waarde;
    const otherTijd = this.otherVraagTijdProperty
      ? context.getAntwoord(this.otherVraagTijdProperty)?.waarde
      : this.DEFAULT_TIME_ANTWOORD_WAARDE;

    if (
      datum &&
      tijd &&
      otherDatum &&
      otherTijd &&
      dateHelpers.isValidDateTime(`${datum} ${tijd}`) &&
      dateHelpers.isValidDateTime(`${otherDatum} ${otherTijd}`)
    ) {
      const datumEnTijd = dateHelpers.getMomentFromDateTimeString(`${datum} ${tijd}`);
      const otherDatumEnTijd = dateHelpers.getMomentFromDateTimeString(`${otherDatum} ${otherTijd}`);

      result = datumEnTijd < otherDatumEnTijd;
    }

    return result;
  };

  static fromJson = (json: any): IsBefore =>
    new IsBefore(json.vraagDatum, json.vraagTijd, json.otherVraagDatum, json.otherVraagTijd);

  static operator = (): string => "isBefore";

  static matches = (json: any): boolean => json?.operator === IsBefore.operator();
}
