import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { translationKeys } from "../../../constants/translation-keys";
import { FormulierDropdownProps } from "./formulier-dropdown.component.interfaces";

export const FormulierDropdown = (props: FormulierDropdownProps) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { onLoad } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  props.formulierTypes.sort(function (a, b) {
    return a.titel[lang].localeCompare(b.titel[lang]);
  });

  return props.formulierTypes.length > 0 ? (
    <Dropdown>
      <Dropdown.Toggle id="nieuwe-melding" className="formulier-dropdown--toggle">
        {t(translationKeys.melding.mijnMeldingen.nieuweMelding)}
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="formulier-dropdown--menu">
        {props.formulierTypes.map((ft) => (
          <Dropdown.Item as={Link} key={ft.formuliertype} to={`/${ft.formuliertype.toLowerCase()}`}>
            {ft.titel[lang]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};
