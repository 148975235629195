import { VisibleWrapper } from "components/visible-wrapper";
import { useFormikContext } from "formik";
import { objectHelpers } from "helpers";
import { AntwoordBlokken } from "models/api";
import { ErrorMessage } from "./error-message.component";

export const ServerErrorMessages = (props: { collectionKey: string; title: string; withCode?: string }) => {
  const { status } = useFormikContext<AntwoordBlokken>();

  const collectionServerErrors = objectHelpers.getValue<any>(status, props.collectionKey);

  const errorMsgs = collectionServerErrors?.waarde?.allMessages?.filter((error: any) =>
    props.withCode ? error.code === props.withCode : true
  );
  const hasErrors = !!errorMsgs && errorMsgs.length > 0;

  return (
    <VisibleWrapper isVisible={hasErrors}>
      <div className="mt-2">
        <ErrorMessage
          name={`${props.collectionKey}-${props.withCode}-vraagblok-collection-error`}
          scrollIntoViewCondition={() => !!hasErrors}
        >
          <div>
            <div className="mb-2">{props.title}</div>
            <ul>
              {errorMsgs?.map((error: any, index: number) => {
                return <li key={`${props.collectionKey}-serverError-${index}`}>{error.message}</li>;
              })}
            </ul>
          </div>
        </ErrorMessage>
      </div>
    </VisibleWrapper>
  );
};
