import { objectHelpers } from "../../../helpers";
import { IAntwoord } from "../antwoord";
import { MeldingContext } from "../melding";
import { Autorisatiegegevens } from "../security/autorisatiegegevens";
import { User } from "../security/user";
import { IVraagBlokCollection } from "../vraag-blok-collection";
import { AntwoordBlok } from "./antwoord-blok";
import { IAntwoordBlokCollection } from "./antwoord-blok-collection";
import { AntwoordBlokken } from "./antwoord-blokken";
import { VraagBlokken } from "./vraag-blokken";

export class ExpressionContext {
  constructor(
    public currentUser: User | null,
    public autorisatiegegevens: Autorisatiegegevens,
    public meldingContext: MeldingContext | null,
    public currentAntwoordBlok: AntwoordBlok,
    public antwoordBlokken: AntwoordBlokken,
    public vraagBlokken: VraagBlokken,
    public sourceAntwoordBlok?: AntwoordBlok
  ) {}

  public getAntwoord = (name: string): IAntwoord | null => this.getAntwoordInternal<IAntwoord>(name);

  public getAntwoordBlokCollection = (name: string): IAntwoordBlokCollection | null =>
    this.getAntwoordInternal<IAntwoordBlokCollection>(name);

  public getAntwoordInternal = <TAntwoord>(name: string): TAntwoord | null => {
    if (!this.currentAntwoordBlok) {
      return null;
    }

    // Take answer from specific CollectionItem
    const collectionPrefix = "#.";
    if (name.startsWith(collectionPrefix) && this.sourceAntwoordBlok) {
      return objectHelpers.getValue<TAntwoord>(this.sourceAntwoordBlok, name.substring(collectionPrefix.length));
    }

    const antwoord = objectHelpers.getValue<TAntwoord>(this.currentAntwoordBlok, name);

    if (antwoord !== null) {
      return antwoord;
    }

    // search from root (all Antwoordblokken)
    const dotIndex = name.indexOf(".");
    const blokName = name.substring(0, dotIndex);
    const propertyName = name.substring(dotIndex + 1);

    return objectHelpers.getValue<TAntwoord>(this.antwoordBlokken[blokName], propertyName);
  };

  public getVraagBlokCollection = (name: string): IVraagBlokCollection | null =>
    this.getVraagInternal<IVraagBlokCollection>(name);

  public getVraagInternal = <TVraag>(name: string): TVraag | null => {
    if (!this.vraagBlokken) {
      return null;
    }

    const dotIndex = name.indexOf(".");
    const blokName = name.substring(0, dotIndex);
    const propertyName = name.substring(dotIndex + 1);

    return objectHelpers.getValue<TVraag>(this.vraagBlokken[blokName], propertyName);
  };
}
