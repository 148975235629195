import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application/dictionary";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";

export class BedrijfslocatiegegevensVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public kvkNummerGebruiken: Meerkeuzevraag,
    public bedrijfsgegevens: BedrijfsgegevensVragen,
    public nietKvkIngeschrevenParagraaf: Tekst,
    public bedrijfsnaam: Vraag,
    public adresBedrijf: CorrespondentieadresVragen,
    public telefoonnummer: Vraag,
    public emailadres: Vraag,
    public website: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): BedrijfslocatiegegevensVragen {
    return new BedrijfslocatiegegevensVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toMeerkeuzeVraag(`${key}.kvkNummerGebruiken`, json.kvkNummerGebruiken),
      BedrijfsgegevensVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens),
      jsonHelpers.toParagraaf(`${key}.nietKvkIngeschrevenParagraaf`, json.nietKvkIngeschrevenParagraaf),
      jsonHelpers.toTekstVraag(`${key}.bedrijfsnaam`, json.bedrijfsnaam),
      CorrespondentieadresVragen.fromJson(`${key}.adresBedrijf`, json.adresBedrijf),
      jsonHelpers.toTekstVraag(`${key}.telefoonnummer`, json.telefoonnummer),
      jsonHelpers.toTekstVraag(`${key}.emailadres`, json.emailadres),
      jsonHelpers.toTekstVraag(`${key}.website`, json.website)
    );
  }
}
