export const SET_PROFIEL_LOADING = "SET_PROFIEL_LOADING";
export const SET_IS_IN_EDIT_MODE = "SET_IS_IN_EDIT_MODE";

interface SetProfielLoading {
  type: typeof SET_PROFIEL_LOADING;
  isLoading: boolean;
}

interface SetEditEnabled {
  type: typeof SET_IS_IN_EDIT_MODE;
  isInEditMode: boolean;
}

export type ProfielActions = SetProfielLoading | SetEditEnabled;

export const setProfielLoading = (isLoading: boolean): ProfielActions => {
  return {
    type: SET_PROFIEL_LOADING,
    isLoading: isLoading
  };
};

export const setIsInEditMode = (isInEditMode: boolean): ProfielActions => {
  return {
    type: SET_IS_IN_EDIT_MODE,
    isInEditMode: isInEditMode
  };
};
