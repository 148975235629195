import React from "react";
import { ReactComponent as Info } from "../../assets/icons/rijksoverheid/2002-info.svg";
import { HelpContext } from "./help-context";

const HelpToggle: React.FC<any> = (props: any) => {
  const context = React.useContext(HelpContext);

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter") {
      toggleHelp();
    }
  }

  function toggleHelp() {
    context.setIsHelpVisible(!context.isHelpVisible);
  }

  return (
    <Info
      role="button"
      focusable="true"
      tabIndex="0"
      onKeyDown={handleKeyDown}
      {...props}
      aria-pressed={context.isHelpVisible || false}
      onClick={toggleHelp}
    />
  );
};

export { HelpToggle };
