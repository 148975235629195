import { AvvArtikel10AVerzoekVragen, BijlageVragen, MelderVragen, VraagBlokken } from "../blok";

export class AvvArtikel10AVerzoekFormulierVraagBlokken extends VraagBlokken {
  [key: string]: any;

  constructor(
    public verzoek: AvvArtikel10AVerzoekVragen,
    public verzoeker: MelderVragen,
    public bijlage: BijlageVragen
  ) {
    super();
  }

  static fromJson(json: any): AvvArtikel10AVerzoekFormulierVraagBlokken {
    return new AvvArtikel10AVerzoekFormulierVraagBlokken(
      AvvArtikel10AVerzoekVragen.fromJson("verzoek", json.verzoek),
      MelderVragen.fromJson("verzoeker", json.verzoeker),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
