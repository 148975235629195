import { ProfielActions, SET_IS_IN_EDIT_MODE, SET_PROFIEL_LOADING } from "../actions";
import { ProfielState } from "../store/profiel-state";

const initialState: ProfielState = {
  isLoading: false,
  isInEditMode: false
};

const profielReducer = (state: ProfielState = initialState, action: ProfielActions): ProfielState => {
  switch (action.type) {
    case SET_PROFIEL_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_IS_IN_EDIT_MODE:
      return { ...state, isInEditMode: action.isInEditMode };
    default:
      return state;
  }
};

export default profielReducer;
