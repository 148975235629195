import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { WijzigButtonProps } from "./wijzig-button.component.interfaces";

export const WijzigButton = (props: WijzigButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button id="wijzigButton" className="actie-button" as="a" href={undefined} role="button" onClick={props.onClick}>
      <FontAwesomeIcon icon={faEdit} /> {t(translationKeys.melding.mijnMeldingen.wijzigen)}
    </Button>
  );
};
