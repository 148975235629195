import { Adres } from "models/api/extern";
import { setAdressen, setAdressenIsLoading, setPlaatsen, setPlaatsenIsLoading, setSearchIsValid } from "../../actions";
import { adressenApi } from "../../api/adressen-api";
import { plaatsenApi } from "../../api/plaatsen-api";
import { AppThunk } from "../app-thunk-type";

let abortController = new AbortController();

export const zoekPlaatsen =
  (naam: string, key: string): AppThunk =>
  async (dispatch) => {
    dispatch(setPlaatsenIsLoading(true, key));

    const plaatsen = await plaatsenApi.searchPlaatsen(naam).then((res) => res.plaatsen.map((p) => p.naam));

    dispatch(setSearchIsValid(plaatsen.includes(naam), key));
    dispatch(setPlaatsen(plaatsen, key));
    dispatch(setPlaatsenIsLoading(false, key));
  };

export const setPlaatsGevonden =
  (key: string): AppThunk =>
  async (dispatch) => {
    dispatch(setSearchIsValid(true, key));
  };

export const zoekAdressen =
  (
    data: {
      postcode: string | null;
      huisnummer: number | null;
    },
    key: string,
    cancelCurrentSearch: boolean = true
  ): AppThunk<Promise<Adres[]>> =>
  async (dispatch) => {
    if (cancelCurrentSearch) {
      abortController.abort();
    }
    abortController = new AbortController();

    const signal = abortController.signal;

    dispatch(setAdressenIsLoading(true, key));

    try {
      const adressen =
        data.postcode && data.huisnummer
          ? await adressenApi
              .searchAdressen(
                {
                  postcode: data.postcode,
                  huisnummer: data.huisnummer
                },
                signal
              )
              .then((res) => res.adressen)
              .catch(() => [])
          : [];

      if (!signal.aborted) {
        dispatch(setAdressen(adressen, key));
        return adressen;
      }
    } catch (ex) {
      if (!signal.aborted) {
        throw ex;
      }
    } finally {
      if (!signal.aborted) {
        dispatch(setAdressenIsLoading(false, key));
      }
    }

    return [];
  };
