import { MetadataGegevens } from ".";
import { ExterneReferentie, Language } from "../../application";
import { AntwoordBlokken } from "../blok";
import { Melding } from "./melding";
import { MeldingBasisgegevens } from "./melding-basisgegevens";

export abstract class MeldingBase<TAntwoordBlokken extends AntwoordBlokken> implements Melding {
  public constructor(
    basisgegevens: MeldingBasisgegevens,
    blokken: TAntwoordBlokken,
    taal: Language,
    id: string | undefined,
    metadata: MetadataGegevens | undefined
  );
  public constructor(
    public readonly basisgegevens: MeldingBasisgegevens,
    public readonly blokken: TAntwoordBlokken,
    public readonly taal: Language,
    public readonly id: string | undefined,
    public readonly metadata: MetadataGegevens,
    public readonly verzenddatum?: Date,
    public readonly versie?: number
  ) {}

  static fromJson<TMelding extends MeldingBase<TAntwBlokken>, TAntwBlokken extends AntwoordBlokken>(
    meldingCtor: new (
      basisgegevens: MeldingBasisgegevens,
      blokken: TAntwBlokken,
      taal: Language,
      id: string | undefined,
      metadata: MetadataGegevens,
      verzenddatum?: Date,
      versie?: number
    ) => TMelding,
    blokkenFromJson: (json: any) => TAntwBlokken
  ) {
    return (json: any) => {
      return new meldingCtor(
        MeldingBasisgegevens.fromJson(json.basisgegevens),
        blokkenFromJson(json.blokken),
        json.taal,
        json.id,
        MetadataGegevens.fromJson(json.metadata),
        json.verzendDateTime,
        json.versie
      );
    };
  }

  static ofType<TMelding extends MeldingBase<TAntwBlokken>, TAntwBlokken extends AntwoordBlokken>(
    meldingCtor: new (
      basisgegevens: MeldingBasisgegevens,
      blokken: TAntwBlokken,
      taal: Language,
      id: string | undefined,
      metadata: MetadataGegevens
    ) => TMelding
  ) {
    return (
      blokken: TAntwBlokken,
      taal: Language,
      id: string | undefined,
      metadata: MetadataGegevens,
      externeReferentie: ExterneReferentie | undefined
    ) => {
      return new meldingCtor(
        new MeldingBasisgegevens(
          id,
          undefined,
          externeReferentie?.datumAangemaakt,
          undefined,
          undefined,
          externeReferentie?.ontvangstwijze,
          externeReferentie?.externReferentienummer,
          externeReferentie?.externReferentienummerType
        ),
        blokken,
        taal,
        id,
        metadata
      );
    };
  }
}
