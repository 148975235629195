const getVolledigeNaam = (voorlettersOfNaam?: string, tussenvoegsels?: string, achternaam?: string): string | undefined => {
  return [voorlettersOfNaam, tussenvoegsels, achternaam].filter((x) => x).join(" ");
};

const getVolledigeNaamMetVoorletters = (name: {
  voorletters?: string;
  tussenvoegsels?: string;
  achternaam?: string;
}): string | undefined => {
  return getVolledigeNaam(name.voorletters, name.tussenvoegsels, name.achternaam);
};

const getVolledigeNaamMetRoepnaam = (name: {
  roepnaam?: string;
  tussenvoegsels?: string;
  achternaam?: string;
}): string | undefined => {
  return getVolledigeNaam(name.roepnaam, name.tussenvoegsels, name.achternaam);
};

const getSamengesteldAchternaam = (name: { tussenvoegsels?: string; achternaam?: string }): string | undefined => {
  return [name.achternaam, name.tussenvoegsels].filter((x) => x).join(", ");
};

const container = {
  getVolledigeNaamMetVoorletters,
  getVolledigeNaamMetRoepnaam,
  getSamengesteldAchternaam
};

export { container as nameHelpers };
