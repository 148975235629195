import { ConfirmDialog } from "components/confirm-dialog";
import { useFormikContext } from "formik";
import { useState } from "react";
import { translationKeys } from "../../../constants/translation-keys";
import { NavigateWithValidationErrorsDialogProps } from "./navigate-with-validation-errors-dialog.component.interfaces";

export const NavigateWithValidationErrorsDialog = (props: NavigateWithValidationErrorsDialogProps) => {
  const { setTouched } = useFormikContext();
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const onConfirm = () => {
    setTouched({});
    setIsConfirmed(true);
    props.onBeforeClose();
  };

  const onClosed = () => {
    // Call onConfirm or onCancel here so we're sure the dialog is actually closed before
    // and will not interfere with any actions that run after confirming or cancelling
    if (isConfirmed) {
      props.onConfirm();
      props.onNavigate(props.antwoordBlokken);
    } else {
      props.onCancel();
    }

    props.onClosed();
  };

  const onCancel = () => {
    setIsConfirmed(false);
    props.onBeforeClose();
  };

  return (
    <ConfirmDialog
      name="navigate-with-validation-errors-dialog"
      showDialog={props.isShown}
      titelTranslationKey={translationKeys.dialoog.navigerenMetValidatiefouten.titel}
      tekstTranslationKey={translationKeys.dialoog.navigerenMetValidatiefouten.tekst}
      confirmTranslationKey={translationKeys.dialoog.navigerenMetValidatiefouten.verderGaan}
      cancelTranslationKey={translationKeys.dialoog.navigerenMetValidatiefouten.corrigeren}
      onConfirm={onConfirm}
      onCancel={onCancel}
      onClosed={onClosed}
    />
  );
};
