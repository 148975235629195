import { jsonHelpers } from "../../../helpers";
import { Dictionary, SamengesteldeVraagType } from "../../application";
import { SamengesteldeVraag } from "../samengestelde-vraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { TeVerwijderenAsbestMateriaalRisicoklasse1Vragen } from "./te-verwijderen-asbest-materiaal-risicoklasse1-vragen";

export class InventarisatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public asbestinventarisatieToelichtingAsbestrisicoklasse1Paragraaf: Tekst,
    public bedrijfsgegevensTitel: Tekst,
    public bedrijfsgegevens: BedrijfsgegevensVragen,
    public scaCode: Vraag,
    public rapportdatum: Vraag,
    public teVerwijderenAsbestMateriaalRisicoklasse1: SamengesteldeVraag<TeVerwijderenAsbestMateriaalRisicoklasse1Vragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): InventarisatieVragen {
    return new InventarisatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(
        `${key}.asbestinventarisatieToelichtingAsbestrisicoklasse1Paragraaf`,
        json.asbestinventarisatieToelichtingAsbestrisicoklasse1Paragraaf
      ),
      jsonHelpers.toTitel(`${key}.bedrijfsgegevensTitel`, json.bedrijfsgegevensTitel),
      BedrijfsgegevensVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens),
      jsonHelpers.toTekstVraag(`${key}.scaCode`, json.scaCode),
      jsonHelpers.toDatumVraag(`${key}.rapportdatum`, json.rapportdatum),
      jsonHelpers.toSamengesteldeVraag<TeVerwijderenAsbestMateriaalRisicoklasse1Vragen>(
        `${key}.teVerwijderenAsbestMateriaalRisicoklasse1`,
        json.teVerwijderenAsbestMateriaalRisicoklasse1,
        (teVerwijderenAsbestMateriaalRisicoklasse1Json) =>
          TeVerwijderenAsbestMateriaalRisicoklasse1Vragen.fromJson(
            "teVerwijderenAsbestMateriaalRisicoklasse1",
            teVerwijderenAsbestMateriaalRisicoklasse1Json
          ),
        SamengesteldeVraagType.teVerwijderenAsbestMateriaalRisicoklasse1
      )
    );
  }
}
