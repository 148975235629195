import { ZaakLink } from "../models/api";
import { Document } from "../models/api/document";
import { MeldingState } from "../store/melding-state";

export const SET_MELDING = "SET_MELDING";
export const CLEAR_MELDING = "CLEAR_MELDING";
export const INTREKKEN_MELDING = "INTREKKEN_MELDING";
export const CANCEL_INTREKKEN = "CANCEL_INTREKKEN";
export const CONFIRM_INTREKKEN = "CONFIRM_INTREKKEN";
export const WIJZIGEN_MELDING = "WIJZIGEN_MELDING";
export const CANCEL_WIJZIGEN = "CANCEL_WIJZIGEN";
export const CONFIRM_WIJZIGEN = "CONFIRM_WIJZIGEN";
export const VERTALEN_MELDING = "VERTALEN_MELDING";
export const SET_ZAAK_LINKS = "SET_ZAAK_LINKS";
export const SET_DOCUMENTEN = "SET_DOCUMENTEN";

interface SetMeldingAction {
  type: typeof SET_MELDING;
  data: MeldingState;
}

interface ClearMeldingAction {
  type: typeof CLEAR_MELDING;
}

interface IntrekkenMeldingAction {
  type: typeof INTREKKEN_MELDING;
}

interface CancelIntrekkenAction {
  type: typeof CANCEL_INTREKKEN;
}

interface ConfirmIntrekkenAction {
  type: typeof CONFIRM_INTREKKEN;
}

interface WijzigenMeldingAction {
  type: typeof WIJZIGEN_MELDING;
}

interface CancelWijzigenAction {
  type: typeof CANCEL_WIJZIGEN;
}

interface ConfirmWijzigenAction {
  type: typeof CONFIRM_WIJZIGEN;
}

interface VertalenMeldingAction {
  type: typeof VERTALEN_MELDING;
}

interface SetZaakLinksAction {
  type: typeof SET_ZAAK_LINKS;
  zaakLinks: ZaakLink[];
}

interface SetDocumentenAction {
  type: typeof SET_DOCUMENTEN;
  documenten: Document[];
}

export type MeldingActions =
  | SetMeldingAction
  | ClearMeldingAction
  | IntrekkenMeldingAction
  | CancelIntrekkenAction
  | ConfirmIntrekkenAction
  | WijzigenMeldingAction
  | CancelWijzigenAction
  | ConfirmWijzigenAction
  | SetZaakLinksAction
  | SetDocumentenAction
  | VertalenMeldingAction;

export const setMelding = (data: MeldingState): SetMeldingAction => {
  return {
    type: SET_MELDING,
    data: data
  };
};

export const clearMelding = (): ClearMeldingAction => {
  return {
    type: CLEAR_MELDING
  };
};

export const intrekkenMelding = (): IntrekkenMeldingAction => {
  return {
    type: INTREKKEN_MELDING
  };
};

export const confirmIntrekkenAction = (): ConfirmIntrekkenAction => {
  return {
    type: CONFIRM_INTREKKEN
  };
};

export const cancelIntrekken = (): CancelIntrekkenAction => {
  return {
    type: CANCEL_INTREKKEN
  };
};

export const wijzigenMelding = (): WijzigenMeldingAction => {
  return {
    type: WIJZIGEN_MELDING
  };
};

export const confirmWijzigenAction = (): ConfirmWijzigenAction => {
  return {
    type: CONFIRM_WIJZIGEN
  };
};

export const cancelWijzigen = (): CancelWijzigenAction => {
  return {
    type: CANCEL_WIJZIGEN
  };
};

export const vertalenMelding = (): VertalenMeldingAction => {
  return {
    type: VERTALEN_MELDING
  };
};

export const setZaakLinks = (zaakLinks: ZaakLink[]): SetZaakLinksAction => {
  return {
    type: SET_ZAAK_LINKS,
    zaakLinks: zaakLinks
  };
};

export const setDocumenten = (documenten: Document[]): SetDocumentenAction => {
  return {
    type: SET_DOCUMENTEN,
    documenten: documenten
  };
};
