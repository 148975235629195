import { AvvArtikel10MeldingVragen, BijlageVragen, MelderVragen, VraagBlokken } from "../blok";
import { HasAvvArtikel10MeldingVragen } from "./has-avv-artikel10-melding-vragen";
import { HasBijlageVragen } from "./has-bijlage-vragen";
import { HasMelderVragen } from "./has-melder-vragen";

export class AvvArtikel10FormulierVraagBlokken
  extends VraagBlokken
  implements HasAvvArtikel10MeldingVragen, HasMelderVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(public melding: AvvArtikel10MeldingVragen, public melder: MelderVragen, public bijlage: BijlageVragen) {
    super();
  }

  static fromJson(json: any): AvvArtikel10FormulierVraagBlokken {
    return new AvvArtikel10FormulierVraagBlokken(
      AvvArtikel10MeldingVragen.fromJson("melding", json.melding),
      MelderVragen.fromJson("melder", json.melder),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
