import { connect } from "react-redux";
import { State } from "../../store";
import { FormulierOpgeslagenBevestiging } from "./formulier-opgeslagen-bevestiging.component";
import { FormulierOpgeslagenBevestigingStateProps } from "./formulier-opgeslagen-bevestiging.component.interfaces";

const mapStateToProps = (state: State): FormulierOpgeslagenBevestigingStateProps => ({
  melding: state.melding,
  formulier: state.vragen.formulier,
  emailadres: state.security.user?.emailadres ?? state.melding.emailadres
});

export default connect(mapStateToProps)(FormulierOpgeslagenBevestiging);
