import { connect } from "react-redux";
import { State } from "../../../store";
import { GeneriekSamenvattingBlokRoot } from "./generiek-samenvatting-blok-root.component";
import {
  GeneriekSamenvattingBlokRootOwnProps,
  GeneriekSamenvattingBlokRootStateProps
} from "./generiek-samenvatting-blok-root.component.interfaces";

const mapStateToProps = (
  state: State,
  ownProps: GeneriekSamenvattingBlokRootOwnProps
): GeneriekSamenvattingBlokRootStateProps => ({
  vragen: state.vragen.formulier?.blokken[ownProps.processtap.toString()]
});

export default connect(mapStateToProps)(GeneriekSamenvattingBlokRoot);
