import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class VoorwaardenAntwoorden extends AntwoordBlok {
  constructor(
    public aardArbeidGewijzigd: Antwoord<string>,
    public voorwaardenBekend: Antwoord<string>,
    public formulierNaarWaarheidIngevuld: Antwoord<string[]>,
    json?: any
  ) {
    super(json);
  }

  static initialize(): VoorwaardenAntwoorden {
    return new VoorwaardenAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""), new Antwoord<string[]>([]));
  }

  static fromJson(json: any): VoorwaardenAntwoorden {
    return new VoorwaardenAntwoorden(
      Antwoord.fromJson<string>(json.aardArbeidGewijzigd),
      Antwoord.fromJson<string>(json.voorwaardenBekend),
      Antwoord.fromJson<string[]>(json.formulierNaarWaarheidIngevuld),
      json
    );
  }
}
