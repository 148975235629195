import { Formuliertype } from "../../application";
import { DouaneFormulierVraagBlokken } from "./douane-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class DouaneFormulier extends FormulierBase {
  constructor(public blokken: DouaneFormulierVraagBlokken, json: any) {
    super(Formuliertype.douane, blokken, json);
  }

  static fromJson(json: any): DouaneFormulier {
    return new DouaneFormulier(DouaneFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
