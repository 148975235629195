import { MeldingState } from "../store/melding-state";
import {
  CANCEL_INTREKKEN,
  CANCEL_WIJZIGEN,
  CLEAR_MELDING,
  CONFIRM_INTREKKEN,
  CONFIRM_WIJZIGEN,
  INTREKKEN_MELDING,
  MeldingActions,
  SET_DOCUMENTEN,
  SET_MELDING,
  SET_ZAAK_LINKS,
  VERTALEN_MELDING,
  WIJZIGEN_MELDING
} from "./../actions";

const initialState: MeldingState | null = null;

const meldingReducer = (state: MeldingState | null = initialState, action: MeldingActions): MeldingState | null => {
  switch (action.type) {
    case SET_MELDING:
      return { ...state, ...action.data };
    case CLEAR_MELDING:
      return initialState;
    case INTREKKEN_MELDING:
      return getNewState(state, { intrekkenRequested: true });
    case CONFIRM_INTREKKEN:
      return getNewState(state, { intrekkenRequested: false });
    case CANCEL_INTREKKEN:
      return getNewState(state, { intrekkenRequested: false });
    case WIJZIGEN_MELDING:
      return getNewState(state, { wijzigenRequested: true });
    case CONFIRM_WIJZIGEN:
      return getNewState(state, { wijzigenRequested: false, wordtGewijzigd: true });
    case CANCEL_WIJZIGEN:
      return getNewState(state, { wijzigenRequested: false });
    case VERTALEN_MELDING:
      return getNewState(state, { wordtVertaald: true });
    case SET_ZAAK_LINKS:
      return getNewState(state, { zaakLinks: action.zaakLinks });
    case SET_DOCUMENTEN:
      return getNewState(state, { documenten: action.documenten });
    default:
      return state;
  }
};

const getNewState = (currentState: MeldingState | null, newState: Partial<MeldingState>) =>
  currentState == null ? null : { ...currentState, ...newState };

export default meldingReducer;
