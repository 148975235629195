import { GevaarlijkeGassenImportcontainersFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getGevaarlijkeGassenImportcontainersFormulierDefinitie = (
  formulier: GevaarlijkeGassenImportcontainersFormulier
): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.melder.titel,
      type: ProcesstapType.melder
    },
    {
      title: formulier.blokken.locatie.titelVerkort,
      type: ProcesstapType.locatie
    },
    {
      title: formulier.blokken.meldingOmschrijving.titel,
      type: ProcesstapType.meldingOmschrijving
    }
  ];
};
