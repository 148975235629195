import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { GegevensIllegaleWerknemerVragen } from "./gegevens-illegale-werknemer-vragen";

export class IllegaleTewerkstellingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public illegaleTewerkstellingTitel: Tekst,
    public welkeIllegaleTewerkstelling: Meerkeuzevraag,
    public hoeveelheidIllegaleWerknemers: Vraag,
    public identiteitVanIllegaleWerknemersBekend: Meerkeuzevraag,
    public gegevensIllegaleWerknemerLijst: VraagBlokCollection<GegevensIllegaleWerknemerVragen>,
    public beschrijvingIllegaleWerknemers: Vraag,
    public werkzaamhedenVanIllegaleWerknemers: Vraag,
    public dagenEnTijdenVanWerkzaamheden: Vraag,
    public aanvullendeInformatie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): IllegaleTewerkstellingVragen {
    return new IllegaleTewerkstellingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.illegaleTewerkstellingTitel`, json.illegaleTewerkstellingTitel),
      jsonHelpers.toChecklistvraag(`${key}.welkeIllegaleTewerkstelling`, json.welkeIllegaleTewerkstelling),
      jsonHelpers.toNumeriekeVraag(`${key}.hoeveelheidIllegaleWerknemers`, json.hoeveelheidIllegaleWerknemers),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.identiteitVanIllegaleWerknemersBekend`,
        json.identiteitVanIllegaleWerknemersBekend
      ),
      VraagBlokCollection.fromJson<GegevensIllegaleWerknemerVragen>(
        GegevensIllegaleWerknemerVragen.fromJson,
        `${key}.gegevensIllegaleWerknemerLijst`,
        json.gegevensIllegaleWerknemerLijst,
        VraagBlokCollectionType.illegaleWerknemers
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.beschrijvingIllegaleWerknemers`, json.beschrijvingIllegaleWerknemers),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.werkzaamhedenVanIllegaleWerknemers`,
        json.werkzaamhedenVanIllegaleWerknemers
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.dagenEnTijdenVanWerkzaamheden`, json.dagenEnTijdenVanWerkzaamheden),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.aanvullendeInformatie`, json.aanvullendeInformatie)
    );
  }
}
