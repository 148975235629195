import { AntwoordBlokken, BijlageAntwoorden, MelderAntwoorden, PredicaatKoninklijkAanvraagAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasAanvragerAntwoorden } from "./has-aanvrager-antwoorden";
import { HasBijlageAntwoorden } from "./has-bijlage-antwoorden";
import { HasPredicaatKoninklijkAanvraagAntwoorden } from "./has-predicaat-koninklijk-aanvraag-antwoorden";

export class PredicaatKoninklijkMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasAanvragerAntwoorden, HasBijlageAntwoorden, HasPredicaatKoninklijkAanvraagAntwoorden
{
  constructor(
    public aanvraag: PredicaatKoninklijkAanvraagAntwoorden,
    public aanvrager: MelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): PredicaatKoninklijkMeldingAntwoordBlokken {
    return new PredicaatKoninklijkMeldingAntwoordBlokken(
      PredicaatKoninklijkAanvraagAntwoorden.initialize(),
      MelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): PredicaatKoninklijkMeldingAntwoordBlokken {
    return new PredicaatKoninklijkMeldingAntwoordBlokken(
      PredicaatKoninklijkAanvraagAntwoorden.fromJson(json.aanvraag),
      MelderAntwoorden.fromJson(json.aanvrager),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
