import { SuccessMessageControl } from "components/success-message";
import { VisibleWrapper } from "components/visible-wrapper";
import { translationKeys } from "constants/translation-keys";
import { useTranslation } from "react-i18next";
import { GeneriekBlok } from "../generiek-vraag-blok";
import { ProfielProps } from "./profiel.component.interfaces";

export const Profiel = (props: ProfielProps) => {
  const { t } = useTranslation();

  return (
    <VisibleWrapper isVisible={props.isActive}>
      <div>
        <section>
          <VisibleWrapper isVisible={props.profielOpgeslagen}>
            <SuccessMessageControl id="profiel-opgeslagen-success" scrollIntoViewCondition={() => props.profielOpgeslagen}>
              {t(translationKeys.blok.profiel.formulier.gegevensGewijzigd)}
            </SuccessMessageControl>
          </VisibleWrapper>
        </section>
      </div>
      <div>
        <GeneriekBlok.VraagBlok vragen={props.vragen} processtap={props.processtap} />
      </div>
    </VisibleWrapper>
  );
};
