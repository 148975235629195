import { Formuliertype } from "../../application";
import { AsbestverwijderingFormulierVraagBlokken } from "./asbestverwijdering-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AsbestverwijderingFormulier extends FormulierBase {
  constructor(public blokken: AsbestverwijderingFormulierVraagBlokken, json: any) {
    super(Formuliertype.asbestverwijdering, blokken, json);
  }

  static fromJson(json: any): AsbestverwijderingFormulier {
    return new AsbestverwijderingFormulier(AsbestverwijderingFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
