import { BeheerStatusGuard } from "components/beheer-status-guard";
import { FormulierTaalNietBeschikbaar } from "components/formulier-taal-niet-beschikbaar";
import { ArieFormulier } from "components/formulier/arie";
import { AvvArtikel10Formulier } from "components/formulier/avv-artikel10";
import { BergplaatsRadioactieveStoffenFormulier } from "components/formulier/bergplaats-radioactieve-stoffen";
import { BibobFormulier } from "components/formulier/bibob";
import { GevaarlijkeGassenImportcontainersFormulier } from "components/formulier/gevaarlijke-gassen-importcontainers";
import { GevaarlijkeLiftFormulier } from "components/formulier/gevaarlijke-lift";
import { HandelingenIoniserendeStralingFormulier } from "components/formulier/handelingen-ioniserende-straling";
import { InformatieverzoekFormulier } from "components/formulier/informatieverzoek";
import { InvoertaalWijzigingDialog } from "components/formulier/invoertaal-wijziging-dialog";
import { OngewoneVoorvallenFormulier } from "components/formulier/ongewone-voorvallen";
import { OntheffingVuurOpTankschipFormulier } from "components/formulier/ontheffing-vuur-op-tankschip";
import { PredicaatKoninklijkFormulier } from "components/formulier/predicaat-koninklijk";
import { VerzoekTotGezamenlijkeInterventieFormulier } from "components/formulier/verzoek-tot-gezamenlijke-interventie";
import { WooFormulier } from "components/formulier/woo";
import { IdleTimer } from "components/idle-timer/idle-timer";
import { Maintenance } from "components/maintenance";
import { Toevoegen } from "components/melding/toevoegen";
import { SecurityExpirationHandler } from "components/security-expiration-handler";
import { Configuration } from "configuration";
import { MILLISECONDS_PER_SECOND } from "constants/timing";
import React, { useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router";
import { HistoryRouter } from "redux-first-history/rr6";
import { AppProps } from "./App.interfaces";
import { AutorisatieaanvraagFormulierVerstuurd } from "./components/autorisatieaanvraag-formulier-verstuurd";
import { AutorisatieDetail } from "./components/beheer/autorisatie-detail";
import { AutorisatiesZoeken } from "./components/beheer/autorisaties-zoeken";
import { BeheerMededeling } from "./components/beheer/mededeling";
import { ExtendSessionDialog } from "./components/extend-session-dialog";
import { FormulierGewijzigd } from "./components/formulier-gewijzigd";
import { FormulierIngetrokken } from "./components/formulier-ingetrokken";
import { FormulierVerstuurd } from "./components/formulier-verstuurd";
import { AanwijzingRisicovolleBedrijvenFormulier } from "./components/formulier/aanwijzing-risicovolle-bedrijven";
import { AsbestRisicoklasse1Formulier } from "./components/formulier/asbest-risicoklasse1";
import { AsbestverwijderingFormulier } from "./components/formulier/asbestverwijdering";
import { AsbestverwijderingLavsFormulier } from "./components/formulier/asbestverwijdering-lavs";
import { AsbestverwijderingLavsV2Formulier } from "./components/formulier/asbestverwijdering-lavs-v2";
import { AutorisatieaanvraagFormulier } from "./components/formulier/autorisatieaanvraag";
import { AvvArtikel10AVerzoekFormulier } from "./components/formulier/avv-artikel10a-verzoek";
import { BiologischeAgentiaFormulier } from "./components/formulier/biologische-agentia";
import { BouwprocesFormulier } from "./components/formulier/bouwproces";
import { CbiFormulier } from "./components/formulier/cbi";
import { ContactFormulier } from "./components/formulier/contact";
import { DigipassFormulier } from "./components/formulier/digipass";
import { DouaneFormulier } from "./components/formulier/douane";
import { DuikarbeidFormulier } from "./components/formulier/duikarbeid";
import { KlachtFormulier } from "./components/formulier/klacht";
import { KlachtenFormulier } from "./components/formulier/klachten";
import { OngevalFormulier } from "./components/formulier/ongeval";
import { OntheffingKinderarbeidFormulier } from "./components/formulier/ontheffing-kinderarbeid";
import { OntheffingLiftenFormulier } from "./components/formulier/ontheffing-liften";
import { OntheffingNachtarbeidFormulier } from "./components/formulier/ontheffing-nachtarbeid";
import { ProductveiligheidFormulier } from "./components/formulier/productveiligheid";
import { ReactieOpenbaarmakingFormulier } from "./components/formulier/reactie-openbaarmaking";
import { UploadGeneriekFormulier } from "./components/formulier/upload-generiek";
import { UploadRieFormulier } from "./components/formulier/upload-rie";
import { VluchtigeOrganischeStoffenFormulier } from "./components/formulier/vluchtige-organische-stoffen";
import { VuurOpTankschipFormulier } from "./components/formulier/vuur-op-tankschip";
import { WerkbakFormulier } from "./components/formulier/werkbak";
import { Layout } from "./components/layout";
import { MijnMeldingen } from "./components/melding/mijn-meldingen";
import { Meldingen } from "./components/meldingen";
import { NietGevonden } from "./components/niet-gevonden";
import { PageTitle } from "./components/page-title";
import { MijnProfiel } from "./components/profiel/mijn-profiel";
import { GeenAutorisatie } from "./components/security/geen-autorisatie";
import { GeenAvgAutorisatie } from "./components/security/geen-avg-autorisatie";
import { Login } from "./components/security/login";
import { Logout } from "./components/security/logout";
import { ZoekMeldingen } from "./components/zoek/meldingen";
import { State, reduxHistory, store } from "./store";

const App: React.FC<AppProps> = (props: AppProps) => {
  const { i18n } = useTranslation();
  const { onLoad, mandatoryInfoLoaded } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useBeforeunload((event) => {
    let state = store.getState() as State;
    if (
      state.navigation.isDirty &&
      state.navigation.requestedUrl === undefined &&
      state.navigation.requestedAction === undefined
    ) {
      event.preventDefault();
    }
  });

  return (
    <>
      {mandatoryInfoLoaded && (
        <HelmetProvider>
          <PageTitle />
          <Helmet>
            <html lang={i18n.language}></html>
          </Helmet>
          <IdleTimer
            onIdle={props.onUserIdle}
            onActive={props.onUserActive}
            timeout={MILLISECONDS_PER_SECOND * Configuration.userInactiveThresholdTimeInSeconds}
          />
          <ExtendSessionDialog />
          <InvoertaalWijzigingDialog />
          <SecurityExpirationHandler />
          <HistoryRouter history={reduxHistory}>
            <Layout path="/">
              <Routes>
                <Route path="*" element={<MijnMeldingen />} />
                <Route path="mijnmeldingen" element={<MijnMeldingen />} />
                <Route path="profiel/*" element={<MijnProfiel />} />
                <Route path="meldingen/:meldingNummer" element={<Meldingen />} />
                <Route path="arie" element={<ArieFormulier />} />
                <Route path="arie/:meldingId" element={<ArieFormulier />} />

                <Route path="autorisatieaanvraag" element={<AutorisatieaanvraagFormulier />} />
                <Route path="autorisatieaanvraagverstuurd" element={<AutorisatieaanvraagFormulierVerstuurd />} />
                <Route path="zoek/kennisgevingen" element={<ZoekMeldingen />} />
                <Route path="melding/toevoegen" element={<Toevoegen />} />
                <Route path="beheer" element={<BeheerStatusGuard />}>
                  <Route path="mededeling" element={<BeheerMededeling />} />
                  <Route path="autorisaties" element={<AutorisatiesZoeken />} />
                  <Route path="autorisaties/:profielId/*" element={<AutorisatieDetail />} />
                </Route>
                <Route path="werkbak" element={<WerkbakFormulier />} />
                <Route path="werkbak/:meldingId" element={<WerkbakFormulier />} />
                <Route path="werkbak/:meldingId/kopie" element={<WerkbakFormulier isKopie={true} />} />
                <Route path="douane" element={<DouaneFormulier />} />
                <Route path="douane/:meldingId" element={<DouaneFormulier />} />
                <Route path="douane/:meldingId/kopie" element={<DouaneFormulier isKopie={true} />} />
                <Route path="duikarbeid" element={<DuikarbeidFormulier />} />
                <Route path="duikarbeid/:meldingId" element={<DuikarbeidFormulier />} />
                <Route path="duikarbeid/:meldingId/kopie" element={<DuikarbeidFormulier isKopie={true} />} />
                <Route path="contact" element={<ContactFormulier />} />
                <Route path="contact/:meldingId" element={<ContactFormulier />} />
                <Route path="contact/:meldingId/kopie" element={<ContactFormulier isKopie={true} />} />
                <Route path="klacht" element={<KlachtFormulier />} />
                <Route path="klacht/:meldingId" element={<KlachtFormulier />} />
                <Route path="klacht/:meldingId/kopie" element={<KlachtFormulier isKopie={true} />} />
                <Route path="klachten" element={<KlachtenFormulier />} />
                <Route path="klachten/:meldingId" element={<KlachtenFormulier />} />
                <Route path="klachten/:meldingId/kopie" element={<KlachtenFormulier isKopie={true} />} />
                <Route path="vluchtigeorganischestoffen" element={<VluchtigeOrganischeStoffenFormulier />} />
                <Route path="vluchtigeorganischestoffen/:meldingId" element={<VluchtigeOrganischeStoffenFormulier />} />
                <Route
                  path="vluchtigeorganischestoffen/:meldingId/kopie"
                  element={<VluchtigeOrganischeStoffenFormulier isKopie={true} />}
                />
                <Route path="vuuroptankschip" element={<VuurOpTankschipFormulier />} />
                <Route path="vuuroptankschip/:meldingId" element={<VuurOpTankschipFormulier />} />
                <Route path="vuuroptankschip/:meldingId/kopie" element={<VuurOpTankschipFormulier isKopie={true} />} />
                <Route path="ontheffingkinderarbeid" element={<OntheffingKinderarbeidFormulier />} />
                <Route path="ontheffingkinderarbeid/:meldingId" element={<OntheffingKinderarbeidFormulier />} />
                <Route
                  path="ontheffingkinderarbeid/:meldingId/kopie"
                  element={<OntheffingKinderarbeidFormulier isKopie={true} />}
                />
                <Route path="ontheffingliften" element={<OntheffingLiftenFormulier />} />
                <Route path="ontheffingliften/:meldingId" element={<OntheffingLiftenFormulier />} />
                <Route path="ontheffingliften/:meldingId/kopie" element={<OntheffingLiftenFormulier isKopie={true} />} />
                <Route path="ontheffingnachtarbeid" element={<OntheffingNachtarbeidFormulier />} />
                <Route path="ontheffingnachtarbeid/:meldingId" element={<OntheffingNachtarbeidFormulier />} />
                <Route
                  path="ontheffingnachtarbeid/:meldingId/kopie"
                  element={<OntheffingNachtarbeidFormulier isKopie={true} />}
                />
                <Route path="ontheffingnachtarbeid" element={<OntheffingNachtarbeidFormulier />} />
                <Route path="ontheffingnachtarbeid/:meldingId" element={<OntheffingNachtarbeidFormulier />} />
                <Route
                  path="ontheffingnachtarbeid/:meldingId/kopie"
                  element={<OntheffingNachtarbeidFormulier isKopie={true} />}
                />
                <Route path="asbestrisicoklasse1" element={<AsbestRisicoklasse1Formulier />} />
                <Route path="asbestrisicoklasse1/:meldingId" element={<AsbestRisicoklasse1Formulier />} />
                <Route
                  path="asbestrisicoklasse1/:meldingId/kopie"
                  element={<AsbestRisicoklasse1Formulier isKopie={true} />}
                />
                <Route path="asbestverwijdering" element={<AsbestverwijderingFormulier />} />
                <Route path="asbestverwijdering/:meldingId" element={<AsbestverwijderingFormulier />} />
                <Route path="asbestverwijdering/:meldingId/kopie" element={<AsbestverwijderingFormulier isKopie={true} />} />
                <Route path="bouwproces" element={<BouwprocesFormulier />} />
                <Route path="bouwproces/:meldingId" element={<BouwprocesFormulier />} />
                <Route path="bouwproces/:meldingId/kopie" element={<BouwprocesFormulier isKopie={true} />} />
                <Route path="ongeval" element={<OngevalFormulier />} />
                <Route path="ongeval/:meldingId" element={<OngevalFormulier />} />
                <Route path="ongeval/:meldingId/kopie" element={<OngevalFormulier isKopie={true} />} />
                <Route path="biologischeagentia" element={<BiologischeAgentiaFormulier />} />
                <Route path="biologischeagentia/:meldingId" element={<BiologischeAgentiaFormulier />} />
                <Route path="biologischeagentia/:meldingId/kopie" element={<BiologischeAgentiaFormulier isKopie={true} />} />
                <Route path="productveiligheid" element={<ProductveiligheidFormulier />} />
                <Route path="productveiligheid/:meldingId" element={<ProductveiligheidFormulier />} />
                <Route path="productveiligheid/:meldingId/kopie" element={<ProductveiligheidFormulier isKopie={true} />} />
                <Route path="reactieopenbaarmaking" element={<ReactieOpenbaarmakingFormulier />} />
                <Route path="reactieopenbaarmaking/:meldingId" element={<ReactieOpenbaarmakingFormulier />} />
                <Route
                  path="reactieopenbaarmaking/:meldingId/kopie"
                  element={<ReactieOpenbaarmakingFormulier isKopie={true} />}
                />
                <Route path="digipass" element={<DigipassFormulier />} />
                <Route path="uploadgeneriek" element={<UploadGeneriekFormulier />} />
                <Route path="uploadgeneriek/:meldingId" element={<UploadGeneriekFormulier />} />
                <Route path="uploadgeneriek/:meldingId/kopie" element={<UploadGeneriekFormulier isKopie={true} />} />
                <Route path="uploadrie" element={<UploadRieFormulier />} />
                <Route path="uploadrie/:meldingId" element={<UploadRieFormulier />} />
                <Route path="uploadrie/:meldingId/kopie" element={<UploadRieFormulier isKopie={true} />} />
                <Route path="cbi" element={<CbiFormulier />} />
                <Route path="cbi/:meldingId" element={<CbiFormulier />} />
                <Route path="cbi/:meldingId/kopie" element={<CbiFormulier isKopie={true} />} />
                <Route path="asbestverwijderinglavs/:meldingId" element={<AsbestverwijderingLavsFormulier />} />
                <Route path="asbestverwijderinglavsv2/:meldingId" element={<AsbestverwijderingLavsV2Formulier />} />
                <Route path="aanwijzingrisicovollebedrijven" element={<AanwijzingRisicovolleBedrijvenFormulier />} />
                <Route
                  path="aanwijzingrisicovollebedrijven/:meldingId"
                  element={<AanwijzingRisicovolleBedrijvenFormulier />}
                />
                <Route
                  path="aanwijzingrisicovollebedrijven/:meldingId/kopie"
                  element={<AanwijzingRisicovolleBedrijvenFormulier isKopie={true} />}
                />
                <Route path="bergplaatsradioactievestoffen" element={<BergplaatsRadioactieveStoffenFormulier />} />
                <Route
                  path="bergplaatsradioactievestoffen/:meldingId"
                  element={<BergplaatsRadioactieveStoffenFormulier />}
                />
                <Route
                  path="bergplaatsradioactievestoffen/:meldingId/kopie"
                  element={<BergplaatsRadioactieveStoffenFormulier isKopie={true} />}
                />
                <Route path="gevaarlijkegassenimportcontainers" element={<GevaarlijkeGassenImportcontainersFormulier />} />
                <Route
                  path="gevaarlijkegassenimportcontainers/:meldingId"
                  element={<GevaarlijkeGassenImportcontainersFormulier />}
                />
                <Route
                  path="gevaarlijkegassenimportcontainers/:meldingId/kopie"
                  element={<GevaarlijkeGassenImportcontainersFormulier isKopie={true} />}
                />
                <Route path="gevaarlijkelift" element={<GevaarlijkeLiftFormulier />} />
                <Route path="gevaarlijkelift/:meldingId" element={<GevaarlijkeLiftFormulier />} />
                <Route path="gevaarlijkelift/:meldingId/kopie" element={<GevaarlijkeLiftFormulier isKopie={true} />} />
                <Route path="handelingenioniserendestraling" element={<HandelingenIoniserendeStralingFormulier />} />
                <Route
                  path="handelingenioniserendestraling/:meldingId"
                  element={<HandelingenIoniserendeStralingFormulier />}
                />
                <Route
                  path="handelingenioniserendestraling/:meldingId/kopie"
                  element={<HandelingenIoniserendeStralingFormulier isKopie={true} />}
                />
                <Route path="ongewonevoorvallen" element={<OngewoneVoorvallenFormulier />} />
                <Route path="ongewonevoorvallen/:meldingId" element={<OngewoneVoorvallenFormulier />} />
                <Route path="ongewonevoorvallen/:meldingId/kopie" element={<OngewoneVoorvallenFormulier isKopie={true} />} />
                <Route path="bibob" element={<BibobFormulier />} />
                <Route path="bibob/:meldingId" element={<BibobFormulier />} />
                <Route path="bibob/:meldingId/kopie" element={<BibobFormulier isKopie={true} />} />
                <Route path="informatieverzoek" element={<InformatieverzoekFormulier />} />
                <Route path="informatieverzoek/:meldingId" element={<InformatieverzoekFormulier />} />
                <Route path="informatieverzoek/:meldingId/kopie" element={<InformatieverzoekFormulier isKopie={true} />} />
                <Route path="woo" element={<WooFormulier />} />
                <Route path="woo/:meldingId" element={<WooFormulier />} />
                <Route path="woo/:meldingId/kopie" element={<WooFormulier isKopie={true} />} />
                <Route path="verzoektotgezamenlijkeinterventie" element={<VerzoekTotGezamenlijkeInterventieFormulier />} />
                <Route
                  path="verzoektotgezamenlijkeinterventie/:meldingId"
                  element={<VerzoekTotGezamenlijkeInterventieFormulier />}
                />
                <Route
                  path="verzoektotgezamenlijkeinterventie/:meldingId/kopie"
                  element={<VerzoekTotGezamenlijkeInterventieFormulier isKopie={true} />}
                />
                <Route path="predicaatkoninklijk" element={<PredicaatKoninklijkFormulier />} />
                <Route path="predicaatkoninklijk/:meldingId" element={<PredicaatKoninklijkFormulier />} />
                <Route
                  path="predicaatkoninklijk/:meldingId/kopie"
                  element={<PredicaatKoninklijkFormulier isKopie={true} />}
                />
                <Route path="ontheffingvuuroptankschip" element={<OntheffingVuurOpTankschipFormulier />} />
                <Route path="ontheffingvuuroptankschip/:meldingId" element={<OntheffingVuurOpTankschipFormulier />} />
                <Route
                  path="ontheffingvuuroptankschip/:meldingId/kopie"
                  element={<OntheffingVuurOpTankschipFormulier isKopie={true} />}
                />
                <Route path="avvartikel10" element={<AvvArtikel10Formulier />} />
                <Route path="avvartikel10/:meldingId" element={<AvvArtikel10Formulier />} />
                <Route path="avvartikel10/:meldingId/kopie" element={<AvvArtikel10Formulier isKopie={true} />} />
                <Route path="avvartikel10averzoek" element={<AvvArtikel10AVerzoekFormulier />} />
                <Route path="avvartikel10averzoek/:meldingId" element={<AvvArtikel10AVerzoekFormulier />} />
                <Route
                  path="avvartikel10averzoek/:meldingId/kopie"
                  element={<AvvArtikel10AVerzoekFormulier isKopie={true} />}
                />
                <Route path="verstuurd" element={<FormulierVerstuurd />} />
                <Route path="gewijzigd" element={<FormulierGewijzigd />} />
                <Route path="ingetrokken" element={<FormulierIngetrokken />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="geenautorisatie" element={<GeenAutorisatie />} />
                <Route path="geenavgautorisatie" element={<GeenAvgAutorisatie />} />
                <Route path="formuliertaalnietbeschikbaar" element={<FormulierTaalNietBeschikbaar />} />
                <Route path="nietgevonden" element={<NietGevonden />} />
                <Route path="onderhoud" element={<Maintenance />} />
              </Routes>
            </Layout>
          </HistoryRouter>
        </HelmetProvider>
      )}
    </>
  );
};

export default App;
