import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { KennisgevingFormulierVraagBlokken } from "./kennisgeving-formulier-vraag-blokken";

export class OngewoneVoorvallenFormulier extends FormulierBase {
  constructor(public blokken: KennisgevingFormulierVraagBlokken, json: any) {
    super(Formuliertype.ongewoneVoorvallen, blokken, json);
  }

  static fromJson(json: any): OngewoneVoorvallenFormulier {
    return new OngewoneVoorvallenFormulier(KennisgevingFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
