import { getAanwijzingRisicovolleBedrijvenFormulierDefinitie } from "./formulier-definitie-aanwijzing-risicovolle-bedrijven";
import { getArieFormulierDefinitie } from "./formulier-definitie-arie";
import { getAsbestRisicoklasse1FormulierDefinitie } from "./formulier-definitie-asbest-risicoklasse1";
import { getAsbestverwijderingFormulierDefinitie } from "./formulier-definitie-asbestverwijdering";
import { getAsbestverwijderingLavsFormulierDefinitie } from "./formulier-definitie-asbestverwijdering-lavs";
import { getAsbestverwijderingLavsV2FormulierDefinitie } from "./formulier-definitie-asbestverwijdering-lavs-v2";
import { getAutorisatieProfielFormulierDefinitie } from "./formulier-definitie-autorisatie-profiel";
import { getAutorisatieaanvraagFormulierDefinitie } from "./formulier-definitie-autorisatieaanvraag";
import { getAutorisatiesZoekenFormulierDefinitie } from "./formulier-definitie-autorisaties-zoeken";
import { getAvvArtikel10FormulierDefinitie } from "./formulier-definitie-avv-artikel10";
import { getAvvArtikel10AVerzoekFormulierDefinitie } from "./formulier-definitie-avv-artikel10a-verzoek";
import { getBergplaatsRadioactieveStoffenFormulierDefinitie } from "./formulier-definitie-bergplaats-radioactieve-stoffen";
import { getBibobFormulierDefinitie } from "./formulier-definitie-bibob";
import { getBiologischeAgentiaFormulierDefinitie } from "./formulier-definitie-biologische-agentia";
import { getBouwprocesFormulierDefinitie } from "./formulier-definitie-bouwproces";
import { getCbiFormulierDefinitie } from "./formulier-definitie-cbi";
import { getContactFormulierDefinitie } from "./formulier-definitie-contact";
import { getDigipassFormulierDefinitie } from "./formulier-definitie-digipass";
import { getDouaneFormulierDefinitie } from "./formulier-definitie-douane";
import { getDuikarbeidFormulierDefinitie } from "./formulier-definitie-duikarbeid";
import { getGevaarlijkeGassenImportcontainersFormulierDefinitie } from "./formulier-definitie-gevaarlijke-gassen-importcontainers";
import { getGevaarlijkeLiftFormulierDefinitie } from "./formulier-definitie-gevaarlijke-lift";
import { getHandelingenIoniserendeStralingFormulierDefinitie } from "./formulier-definitie-handelingen-ioniserende-straling";
import { getInformatieverzoekFormulierDefinitie } from "./formulier-definitie-informatieverzoek";
import { getKlachtFormulierDefinitie } from "./formulier-definitie-klacht";
import { getKlachtenFormulierDefinitie } from "./formulier-definitie-klachten";
import { getOngevalFormulierDefinitie } from "./formulier-definitie-ongeval";
import { getOngewoneVoorvallenFormulierDefinitie } from "./formulier-definitie-ongewone-voorvallen";
import { getOntheffingKinderarbeidFormulierDefinitie } from "./formulier-definitie-ontheffing-kinderarbeid";
import { getOntheffingLiftenFormulierDefinitie } from "./formulier-definitie-ontheffing-liften";
import { getOntheffingNachtarbeidFormulierDefinitie } from "./formulier-definitie-ontheffing-nachtarbeid";
import { getOntheffingVuurOpTankschipFormulierDefinitie } from "./formulier-definitie-ontheffing-vuur-op-tankschip";
import { getPredicaatKoninklijkFormulierDefinitie } from "./formulier-definitie-predicaat-koninklijk";
import { getProductveiligheidFormulierDefinitie } from "./formulier-definitie-productveiligheid";
import { getProfielFormulierDefinitie } from "./formulier-definitie-profiel";
import { getReactieOpenbaarmakingFormulierDefinitie } from "./formulier-definitie-reactie-openbaarmaking";
import { getUploadGeneriekFormulierDefinitie } from "./formulier-definitie-upload-generiek";
import { getUploadRieFormulierDefinitie } from "./formulier-definitie-upload-rie";
import { getVerzoekTotGezamenlijkeInterventieFormulierDefinitie } from "./formulier-definitie-verzoek-tot-gezamenlijke-interventie";
import { getVluchtigeOrganischeStoffenFormulierDefinitie } from "./formulier-definitie-vluchtige-organische-stoffen";
import { getVuurOpTankschipFormulierDefinitie } from "./formulier-definitie-vuur-op-tankschip";
import { getWerkbakFormulierDefinitie } from "./formulier-definitie-werkbak";
import { getWooFormulierDefinitie } from "./formulier-definitie-woo";

const container = {
  getAanwijzingRisicovolleBedrijvenFormulierDefinitie,
  getAsbestRisicoklasse1FormulierDefinitie,
  getAsbestverwijderingFormulierDefinitie,
  getAsbestverwijderingLavsFormulierDefinitie,
  getAsbestverwijderingLavsV2FormulierDefinitie,
  getAutorisatieaanvraagFormulierDefinitie,
  getAutorisatiesZoekenFormulierDefinitie,
  getAvvArtikel10FormulierDefinitie,
  getAvvArtikel10AVerzoekFormulierDefinitie,
  getBergplaatsRadioactieveStoffenFormulierDefinitie,
  getBiologischeAgentiaFormulierDefinitie,
  getDouaneFormulierDefinitie,
  getDuikarbeidFormulierDefinitie,
  getContactFormulierDefinitie,
  getGevaarlijkeGassenImportcontainersFormulierDefinitie,
  getGevaarlijkeLiftFormulierDefinitie,
  getHandelingenIoniserendeStralingFormulierDefinitie,
  getKlachtFormulierDefinitie,
  getKlachtenFormulierDefinitie,
  getOntheffingLiftenFormulierDefinitie,
  getOntheffingNachtarbeidFormulierDefinitie,
  getOntheffingVuurOpTankschipFormulierDefinitie,
  getVuurOpTankschipFormulierDefinitie,
  getWerkbakFormulierDefinitie,
  getBouwprocesFormulierDefinitie,
  getOngevalFormulierDefinitie,
  getOngewoneVoorvallenFormulierDefinitie,
  getOntheffingKinderarbeidFormulierDefinitie,
  getProductveiligheidFormulierDefinitie,
  getProfielFormulierDefinitie,
  getAutorisatieProfielFormulierDefinitie,
  getVluchtigeOrganischeStoffenFormulierDefinitie,
  getReactieOpenbaarmakingFormulierDefinitie,
  getDigipassFormulierDefinitie,
  getUploadGeneriekFormulierDefinitie,
  getUploadRieFormulierDefinitie,
  getCbiFormulierDefinitie,
  getArieFormulierDefinitie,
  getBibobFormulierDefinitie,
  getInformatieverzoekFormulierDefinitie,
  getWooFormulierDefinitie,
  getVerzoekTotGezamenlijkeInterventieFormulierDefinitie,
  getPredicaatKoninklijkFormulierDefinitie
};

export { container as FormulierDefinitie };
