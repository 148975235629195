import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { WerkzaamhedenVragen } from "./werkzaamheden-vragen";

export class DuikarbeidWerkzaamhedenVragen extends WerkzaamhedenVragen {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public duikwerkzaamhedenToelichting: Tekst,
    public omschrijving: Vraag,
    public specifiekMateriaal: Meerkeuzevraag,
    public toelichtingSpecifiekMateriaal: Vraag,
    public samenstellingDuikploegenTitel: Tekst,
    public samenstellingDuikploegenToelichting: Tekst,
    public aantalDuikploegleiders: Vraag,
    public aantalDuikers: Vraag,
    public aantalDuikmedischeBegeleiders: Vraag,
    public redenMeldingTitel: Tekst,
    public redenMeldingDuikarbeid: Meerkeuzevraag,
    public uitlegOverigeRedenMeldingDuikarbeid: Vraag,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public bestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): DuikarbeidWerkzaamhedenVragen {
    return new DuikarbeidWerkzaamhedenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(`${key}.duikwerkzaamhedenToelichting`, json.duikwerkzaamhedenToelichting),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijving`, json.omschrijving),
      jsonHelpers.toMeerkeuzeVraag(`${key}.specifiekMateriaal`, json.specifiekMateriaal),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.toelichtingSpecifiekMateriaal`, json.toelichtingSpecifiekMateriaal),
      jsonHelpers.toTitel(`${key}.samenstellingDuikploegenTitel`, json.samenstellingDuikploegenTitel),
      jsonHelpers.toParagraaf(`${key}.samenstellingDuikploegenToelichting`, json.samenstellingDuikploegenToelichting),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalDuikploegleiders`, json.aantalDuikploegleiders),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalDuikers`, json.aantalDuikers),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalDuikmedischeBegeleiders`, json.aantalDuikmedischeBegeleiders),
      jsonHelpers.toTitel(`${key}.redenMeldingTitel`, json.redenMeldingTitel),
      jsonHelpers.toChecklistvraag(`${key}.redenMeldingDuikarbeid`, json.redenMeldingDuikarbeid),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.uitlegOverigeRedenMeldingDuikarbeid`,
        json.uitlegOverigeRedenMeldingDuikarbeid
      ),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden)
    );
  }
}
