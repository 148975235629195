import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { MeldingBase, PredicaatKoninklijkMelding, PredicaatKoninklijkMeldingAntwoordBlokken } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { PredicaatKoninklijkFormulierComponentProps } from "./predicaat-koninklijk-formulier.component.interfaces";

export const PredicaatKoninklijkFormulierComponent = (props: PredicaatKoninklijkFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.predicaatKoninklijk;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const formulier = props.formulier;
    const blokDefinitie = FormulierDefinitie.getPredicaatKoninklijkFormulierDefinitie(formulier);

    return (
      <Formulier
        type={Formuliertype.predicaatKoninklijk}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(PredicaatKoninklijkMelding)}
        getEmailadres={(blokken: PredicaatKoninklijkMeldingAntwoordBlokken) => blokken.aanvrager.melder?.emailadres.waarde}
      ></Formulier>
    );
  } else {
    return null;
  }
};
