import { connect } from "react-redux";
import { State } from "../../../store";
import { MijnProfiel } from "./mijn-profiel.component";
import { MijnProfielStateProps } from "./mijn-profiel.component.interfaces";

const mapStateToProps = (state: State): MijnProfielStateProps => ({
  isLoading: state.meldingen.isLoading,
  hasApiError: state.meldingen.hasApiError,
  user: state.security.user,
  showProfileIncompleteWarning: state.security.profileIsComplete === false
});

export default connect(mapStateToProps)(MijnProfiel);
