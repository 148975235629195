import moment, { Moment } from "moment";
import { Formuliertype } from "../../application";
import { Meldingstatus } from "../melding/meldingstatus";

export class MeldingListItem {
  constructor(
    public id: string,
    public meldingnummer: number,
    public vestigingsnummer: number,
    public vestigingsnaam: string,
    public formulier: string,
    public aangemaaktOp: Moment,
    public gewijzigdOp: Moment | null,
    public gewijzigdDoor: string,
    public locatieAdres: string,
    public locatiePlaats: string,
    public locatiePostcode: string,
    public aanvang: Moment | null,
    public beeindiging: Moment | null,
    public status: Meldingstatus,
    public contactpersoon: string,
    public formuliertype: Formuliertype
  ) {}

  static fromJson(json: any): MeldingListItem {
    return new MeldingListItem(
      json.id,
      json.meldingnummer,
      json.vestigingsnummer,
      json.vestigingsnaam,
      json.formulier,
      moment(json.aangemaaktOp),
      json.gewijzigdOp ? moment(json.gewijzigdOp) : null,
      json.gewijzigdDoor,
      json.locatieAdres,
      json.locatiePlaats,
      json.locatiePostcode,
      json.aanvang ? moment(json.aanvang) : null,
      json.beeindiging ? moment(json.beeindiging) : null,
      json.status,
      json.contactpersoon,
      json.formuliertype
    );
  }
}
