import { GetMijnMeldingenRequest, GetMijnMeldingenResponse, MeldingSortOption } from "../models/api";
import { ColumnSortOption, Order } from "../store";
import { setMeldingen, setMeldingenFormulieren, setMeldingenLoading } from "./../actions/meldingen-actions";
import { formulierApi, meldingApi } from "./../api";
import { getLanguage } from "./../helpers/language-helpers";
import { AppThunk } from "./app-thunk-type";
import { requestUrlNavigation } from "./navigation-thunks";

export const getFormulieren =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    await formulierApi.getFormulieren().then((response) => {
      dispatch(setMeldingenFormulieren(response.formulieren));
    });
  };

export const getMijnMeldingen =
  (sortOption: ColumnSortOption, pageNumber: number): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setMeldingenLoading(true, false));

    const meldingSortOption = new MeldingSortOption(sortOption.columnName, sortOption.order === Order.ascending);

    await meldingApi
      .getMijnMeldingen(
        new GetMijnMeldingenRequest(getLanguage(), meldingSortOption, pageNumber, getState().meldingen.pageSize)
      )
      .then((json) => {
        const response = GetMijnMeldingenResponse.fromJson(json);
        dispatch(setMeldingen(response.meldingen, response.totalItemCount));
        dispatch(setMeldingenLoading(false, false));
      })
      .catch(() => {
        dispatch(setMeldingenLoading(false, true));
      });
  };

export const gotoMelding =
  (meldingNummer: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setMeldingenLoading(true, false));

    await meldingApi
      .getMeldingUri(meldingNummer)
      .then((uri) => {
        dispatch(requestUrlNavigation(uri));
      })
      .catch(() => {
        dispatch(setMeldingenLoading(false, true));
      });
  };
