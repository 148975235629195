import { connect } from "react-redux";
import { NO_SELECTED_ITEM } from "reducers/collections-reducer";
import { toggleSelectedCollectionItem } from "../../../../../../actions";
import { State } from "../../../../../../store";
import { CollectionTableRowToggle } from "./collection-table-row-toggle.component";
import {
  CollectionTableRowToggleDispatchProps,
  CollectionTableRowToggleOwnProps,
  CollectionTableRowToggleStateProps
} from "./collection-table-row-toggle.component.interfaces";

const mapStateToProps = (state: State, props: CollectionTableRowToggleOwnProps): CollectionTableRowToggleStateProps => ({
  selectedCollectionItemKey: state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM
});

const mapDispatchToProps = (
  dispatch: any,
  props: CollectionTableRowToggleOwnProps
): CollectionTableRowToggleDispatchProps => ({
  setSelectedCollectionItem: (itemKey: string) => {
    dispatch(toggleSelectedCollectionItem(props.collectionKey, itemKey));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTableRowToggle);
