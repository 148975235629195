import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class TeVerwijderenAsbestMateriaalLavsAntwoorden extends AntwoordBlok {
  constructor(
    public risicoklasse: Antwoord<string>,
    public hoeveelheid: Antwoord<string>,
    public eenheid: Antwoord<string>,
    public soortMateriaal: Antwoord<string>,
    public broncode: Antwoord<string>,
    public brontype: Antwoord<string>,
    public binding: Antwoord<string>,
    public bevestigingsmethode: Antwoord<string>,
    public verwijderingsmethode: Antwoord<string>,
    public aanbevolenMaatregel: Antwoord<string>,
    public toelichting: Antwoord<string>,
    public hoeveelheidType: Antwoord<string>,
    public afmetingPerStuk: Antwoord<string>
  ) {
    super();
  }

  static initialize() {
    return new TeVerwijderenAsbestMateriaalLavsAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new TeVerwijderenAsbestMateriaalLavsAntwoorden(
      Antwoord.fromJson(json.risicoklasse),
      Antwoord.fromJson(json.hoeveelheid),
      Antwoord.fromJson(json.eenheid),
      Antwoord.fromJson(json.soortMateriaal),
      Antwoord.fromJson(json.broncode),
      Antwoord.fromJson(json.brontype),
      Antwoord.fromJson(json.binding),
      Antwoord.fromJson(json.bevestigingsmethode),
      Antwoord.fromJson(json.verwijderingsmethode),
      Antwoord.fromJson(json.aanbevolenMaatregel),
      Antwoord.fromJson(json.toelichting),
      Antwoord.fromJson(json.hoeveelheidType),
      Antwoord.fromJson(json.afmetingPerStuk)
    );
  }
}
