import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formulier } from "..";
import { translationKeys } from "../../../constants";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { AutorisatiesZoekenMelding, MeldingBase } from "../../../models/api";
import { BlokDefinitie, Formuliertype } from "../../../models/application";
import { AutorisatiesZoekenFormulierComponentProps } from "./autorisaties-zoeken-formulier.component.interfaces";

export const AutorisatiesZoekenFormulierComponent = (props: AutorisatiesZoekenFormulierComponentProps) => {
  const { t } = useTranslation();
  const { onInit, ...propsRest } = props;

  useEffect(() => {
    onInit();
  }, [onInit]);

  const formuliertype = Formuliertype.autorisatiesZoeken;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(formuliertype, props.formulier.type, props.antwoordenFormuliertype)
  ) {
    const blokDefinitie: BlokDefinitie[] = FormulierDefinitie.getAutorisatiesZoekenFormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(AutorisatiesZoekenMelding)}
        verzendenButtonText={t(translationKeys.beheer.autorisaties.zoeken)}
        submitMelding={props.submitMelding}
        hideHeaders={true}
        hideLeaveFormulier={true}
      ></Formulier>
    );
  } else {
    return null;
  }
};
