import { useFormikContext } from "formik";
import { User } from "models/api/security/user";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AntwoordBlokken, MeldingContext, VraagBlokken } from "../../../models/api";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";
import { State } from "../../../store";
import { isVraagBlokReadonly } from "./conditioneel-readonly-helper";
import { WithConditioneelReadonlyVraagBlokProps } from "./with-conditioneel-readonly-vraag-blok.interfaces";

export const withConditioneelReadonlyVraagBlok = <P extends object>(
  Component: React.ComponentType<P & WithConditioneelReadonlyVraagBlokProps>
) => {
  return (props: P & WithConditioneelReadonlyVraagBlokProps) => {
    const { vragen, readOnly } = props;

    const { vraagBlokken, user, autorisatiegegevens, meldingStatus, wordtMeldingGewijzigd } = useSelector<
      State,
      {
        vraagBlokken?: VraagBlokken;
        user: User | null;
        autorisatiegegevens: Autorisatiegegevens;
        meldingStatus?: string;
        wordtMeldingGewijzigd: boolean;
      }
    >(
      (state: State) => ({
        vraagBlokken: state.vragen.formulier?.blokken,
        user: state.security.user,
        autorisatiegegevens: state.autorisatie.autorisatiegegevens,
        meldingStatus: state.melding?.status,
        wordtMeldingGewijzigd: state.melding?.wordtGewijzigd
      }),
      shallowEqual
    );

    const { values } = useFormikContext<AntwoordBlokken>();

    let isReadonly = false;

    if (vraagBlokken) {
      isReadonly =
        readOnly ||
        isVraagBlokReadonly(
          vragen,
          vraagBlokken,
          values,
          user,
          autorisatiegegevens,
          new MeldingContext(wordtMeldingGewijzigd, meldingStatus)
        );
    }

    const componentProps = { ...props, readOnly: isReadonly };
    return <Component {...componentProps} />;
  };
};
