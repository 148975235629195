import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";
import { ParticuliereOpdrachtgeverAntwoorden } from "./particuliere-opdrachtgever-antwoorden";

export class OpdrachtgeverBouwprocesAntwoorden extends AntwoordBlok {
  constructor(
    public gegevensGelijk: Antwoord<string>,
    public bedrijfOfParticulier: Antwoord<string>,
    public opdrachtgever: OpdrachtgeverAntwoorden | null,
    public particuliereOpdrachtgever: ParticuliereOpdrachtgeverAntwoorden | null,
    public coordinator: ContactpersoonAntwoorden | null,
    public werkzaamBij: Antwoord<string>
  ) {
    super();
  }

  static initialize(): OpdrachtgeverBouwprocesAntwoorden {
    return new OpdrachtgeverBouwprocesAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      OpdrachtgeverAntwoorden.initialize(),
      ParticuliereOpdrachtgeverAntwoorden.initialize(),
      ContactpersoonAntwoorden.initialize(),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): OpdrachtgeverBouwprocesAntwoorden {
    return new OpdrachtgeverBouwprocesAntwoorden(
      Antwoord.fromJson<string>(json.gegevensGelijk),
      Antwoord.fromJson<string>(json.bedrijfOfParticulier),
      OpdrachtgeverAntwoorden.fromJson(json.opdrachtgever),
      ParticuliereOpdrachtgeverAntwoorden.fromJson(json.particuliereOpdrachtgever),
      ContactpersoonAntwoorden.fromJson(json.coordinator),
      Antwoord.fromJson<string>(json.werkzaamBij)
    );
  }
}
