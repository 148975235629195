import { Antwoord } from "../antwoord";
import { AntwoordBlokCollectionItem } from "./antwoord-blok-collection-item";

export class AvvArtikel10AVerzoekPeriodeAntwoorden extends AntwoordBlokCollectionItem {
  static get type(): string {
    return "AvvArtikel10AVerzoekPeriodeAntwoorden";
  }

  constructor(
    public startdatum: Antwoord<string>,
    public einddatum: Antwoord<string>,
    public json?: any
  ) {
    super(AvvArtikel10AVerzoekPeriodeAntwoorden.type, json);
  }

  static initialize(): AvvArtikel10AVerzoekPeriodeAntwoorden {
    return new AvvArtikel10AVerzoekPeriodeAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): AvvArtikel10AVerzoekPeriodeAntwoorden {
    return new AvvArtikel10AVerzoekPeriodeAntwoorden(
      Antwoord.fromJson<string>(json.startdatum),
      Antwoord.fromJson<string>(json.einddatum),
      json
    );
  }

  static copyFromJson(json: any) {
    return AvvArtikel10AVerzoekPeriodeAntwoorden.fromJson({
      ...json,
      ...AntwoordBlokCollectionItem.copyAsNewInstance(json)
    });
  }
}
