import { connect } from "react-redux";
import { cancelNavigation, requireNavigationConfirmation } from "../../../actions";
import { State } from "../../../store/state";
import { confirmNavigation } from "../../../thunks";
import { LeaveFormulierDialog } from "./leave-formulier-dialog.component";
import {
  LeaveFormulierDialogDispatchProps,
  LeaveFormulierDialogStateProps
} from "./leave-formulier-dialog.component.interfaces";

const mapStateToProps = (state: State): LeaveFormulierDialogStateProps => ({
  isLeaveFormulierDialogShown: state.navigation.requestedUrl !== undefined || state.navigation.requestedAction !== undefined
});

const mapDispatchToProps = (dispatch: any): LeaveFormulierDialogDispatchProps => ({
  onInit: () => {
    dispatch(requireNavigationConfirmation(true));
  },
  onDestroy: () => {
    dispatch(requireNavigationConfirmation(false));
  },
  onLeaveFormulier: () => {
    dispatch(confirmNavigation());
  },
  onCancel: () => {
    dispatch(cancelNavigation());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveFormulierDialog);
