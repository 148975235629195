import { AutorisatieaanvraagAntwoorden, AntwoordBlokken } from "../blok";
import { HasAutorisatieaanvraagAntwoorden } from "./has-autorisatieaanvraag-antwoorden";

export class AutorisatieaanvraagMeldingAntwoordBlokken extends AntwoordBlokken implements HasAutorisatieaanvraagAntwoorden {
  constructor(public autorisatieaanvraag: AutorisatieaanvraagAntwoorden) {
    super();
  }

  static initialize(): AutorisatieaanvraagMeldingAntwoordBlokken {
    return new AutorisatieaanvraagMeldingAntwoordBlokken(AutorisatieaanvraagAntwoorden.initialize());
  }
}
