import { Configuration } from "configuration";
import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { translationKeys } from "../../constants/translation-keys";
import { asyncHelpers, focusHelpers, processtapHelpers, validationHelpers } from "../../helpers";
import { AntwoordBlokken, MeldingContext, ValidationFailure, ValidationResponse } from "../../models/api";
import { BlokDefinitie, ProcesstapType, RequestedProcesstap, isFormulierTypeMelding } from "../../models/application";
import { State } from "../../store";
import { GeneriekSamenvattingBlokRoot } from "../blok/generiek-samenvatting-blok-root";
import { GeneriekVraagBlokRoot } from "../blok/generiek-vraag-blok-root";
import { Samenvatting } from "../blok/samenvatting";
import { FormulierBreadcrumbs } from "../breadcrumbs";
import { FormulierHeader } from "../formulier-header";
import { MainContent } from "../main-content";
import { VisibleWrapper } from "../visible-wrapper";
import { FormulierFormik } from "./formulier-formik";
import { FormulierProcesBalk } from "./formulier-proces-balk";
import { FormulierProps } from "./formulier.component.interfaces";
import { LeaveFormulierDialog } from "./leave-formulier-dialog";
import { NavigateWithValidationErrorsDialog } from "./navigate-with-validation-errors-dialog";
import { SamenvattingBar } from "./samenvatting-bar";

const checkValidate = (
  props: FormulierProps,
  isSubmitRequested: boolean,
  requestedProcesstap: RequestedProcesstap | null,
  doBackendValidation: boolean
) => {
  const activeIsSamenvatting = props.activeProcesstapType === ProcesstapType.samenvatting;

  const isSubmitRequestedForSingleBlokFormulier =
    isSubmitRequested && props.blokDefinitie.length === 1 && !activeIsSamenvatting;

  const shouldValidate =
    requestedProcesstap?.shouldValidate === true ||
    (!requestedProcesstap && props.activeProcesstapHasValidationErrors === true) ||
    isSubmitRequestedForSingleBlokFormulier;

  const validateOnServer =
    ((requestedProcesstap != null && !activeIsSamenvatting) || isSubmitRequestedForSingleBlokFormulier) &&
    doBackendValidation === true;

  return {
    shouldValidate,
    validateOnServer
  };
};

const MainForm = (props: FormulierProps) => {
  const [firstElementWithErrorId, setFirstElementWithErrorId] = useState<string | null>(null);
  const [isNavigatingWithErrors, setIsNavigatingWithErrors] = useState<boolean>(false);

  const focusDelayMs = 100;

  const { isSubmitRequested, requestedProcesstap } = useSelector<
    State,
    {
      isSubmitRequested: boolean;
      requestedProcesstap: RequestedProcesstap | null;
    }
  >(
    (state: State) => ({
      isSubmitRequested: state.proces.isSubmitRequested,
      requestedProcesstap: state.proces.requestedItem
    }),
    shallowEqual
  );

  const validate = async (values: any, doBackendValidation = false): Promise<ValidationResponse> => {
    const checkValidateResult = checkValidate(props, isSubmitRequested, requestedProcesstap, doBackendValidation);

    const validationResponse =
      checkValidateResult.shouldValidate && props.activeProcesstapType
        ? await props.validate(
            props.meldingId,
            values,
            props.user,
            props.autorisatiegegevens,
            new MeldingContext(props.wordtMeldingGewijzigd, props.meldingStatus),
            props.activeProcesstapType,
            checkValidateResult.validateOnServer,
            props.type,
            props.submitValidationResults ?? undefined
          )
        : new ValidationResponse([]);

    const captchaValidationFailure = validateCaptcha(values);
    if (captchaValidationFailure) {
      validationResponse.addValidationFailures([captchaValidationFailure]);
    }

    return Promise.resolve(validationResponse);
  };

  const validateCaptcha = (values: any): ValidationFailure | null => {
    const shouldValidateExistingCaptchaError = props.captchaHasError && !props.isBackwardsNavigationRequested;
    const shouldValidateCaptcha = props.captchaRequired && (isSubmitRequested || shouldValidateExistingCaptchaError);
    const validation = shouldValidateCaptcha ? validationHelpers.validateCaptcha(values.captcha) : null;

    if (validation && !props.captchaHasError) {
      props.setCaptchaError();
    } else if (!validation && props.captchaHasError) {
      props.clearCaptchaError();
    }

    return validation;
  };

  const validateAndOptionallyResetRequestedPage = async (
    values: any,
    allowAllNavigation = false,
    doBackendValidation = false
  ) => {
    const validationResult: ValidationResponse = await validate(values, doBackendValidation);

    if (!validationResult.isValid) {
      props.onCancelSubmitRequested();
      const elementToFocusId = focusHelpers.getFirstErrorElementId(validationResult);

      if (props.isNavigating && (props.isBackwardsNavigationRequested || allowAllNavigation)) {
        props.onNavigate(values, true, elementToFocusId);
      }

      if (!props.isBackwardsNavigationRequested && !allowAllNavigation) {
        setFirstElementWithErrorId(elementToFocusId);

        if (props.isNavigating) {
          props.setIsNavigatingWithValidationErrors(
            props.isNavigating && !props.isBackwardsNavigationRequested,
            elementToFocusId,
            values
          );
        }
      }
    }

    return validationResult;
  };

  const focusOnFirstErrorElement = () => {
    const elementId = firstElementWithErrorId;
    if (elementId) {
      setFirstElementWithErrorId(null);
      asyncHelpers.delay(focusDelayMs).then(() => focusHelpers.setFocusById(elementId));
    }
  };

  const onNavigateWithValidationErrorsDialogClosed = () => {
    setIsNavigatingWithErrors(false);
    if (firstElementWithErrorId && !isNavigatingWithErrors) {
      focusOnFirstErrorElement();
    }
  };

  const onNavigateWithValidationErrorsDialogConfirmed = () => {
    setIsNavigatingWithErrors(true);
    props.setIsNavigatingWithValidationErrors(false, firstElementWithErrorId);
    setFirstElementWithErrorId(null);
  };

  const onNavigate = (values: AntwoordBlokken) => {
    props.onNavigate(values, false);
  };

  const onBlokActivated = (processtapType: ProcesstapType) => {
    if (props.activeProcesstapType !== processtapType) {
      return;
    }

    if (props.activeProcesstapFirstElementWithErrorId) {
      focusHelpers.setFocusById(props.activeProcesstapFirstElementWithErrorId);
    } else {
      focusHelpers.setFocusOnFirstHeading("main-form");
    }
  };

  const onSamenvattingActivated = () => {
    onBlokActivated(ProcesstapType.samenvatting);
  };

  return (
    <FormulierFormik
      validate={validateAndOptionallyResetRequestedPage}
      blokken={props.blokken}
      onNavigate={onNavigate}
      onNext={props.onNext}
      onPrevious={props.onPrevious}
      onSubmitRequested={props.onSubmitRequested}
      onSubmit={props.onSubmit}
      hasPreviousButton={props.hasPreviousButton}
      hasNextButton={props.hasNextButton}
      hasVerzendenButton={props.hasVerzendenButton}
      verzendenButtonText={props.verzendenButtonText}
      isSubmitting={props.isSubmitting}
      navigateOnSubmit={requestedProcesstap !== null}
      reinitializeForm={props.reinitializeForm}
      onFormReinitializationComplete={props.onFormReinitializationComplete}
      isInterneMedewerker={props.isInterneMedewerker}
    >
      <NavigateWithValidationErrorsDialog
        antwoordBlokken={props.blokken}
        onConfirm={onNavigateWithValidationErrorsDialogConfirmed}
        onClosed={onNavigateWithValidationErrorsDialogClosed}
      />
      <FormulierBlokken blokDefinitie={props.blokDefinitie} onBlokActivated={onBlokActivated} />
      {props.blokDefinitie.some((b) => processtapHelpers.hasSamenvatting(b)) && (
        <Samenvatting
          blokken={props.blokDefinitie}
          processtappen={props.processtappen}
          onBlokActivated={onSamenvattingActivated}
        />
      )}
    </FormulierFormik>
  );
};

const FormulierBlokken = (props: {
  blokDefinitie: BlokDefinitie[];
  onBlokActivated: (processtapType: ProcesstapType) => void;
}) => {
  const onBlokActivated = (processtapType: ProcesstapType) => {
    props.onBlokActivated(processtapType);
  };

  return (
    <Fragment>
      {props.blokDefinitie.map((b) => {
        if (b.vraagBlok) {
          // Afhankelijk van voorkomen van iets in de formulierdefinitie het nieuwe blok renderen, of het onderstaande.
          return React.createElement(b.vraagBlok, {
            key: b.type,
            processtap: b.type,
            ...b.additional
          });
        } else {
          return <GeneriekVraagBlokRoot processtap={b.type} key={b.type} onActivated={onBlokActivated} />;
        }
      })}
    </Fragment>
  );
};

const SamenvattingBarBlokken = (props: { blokDefinitie: BlokDefinitie[] }) => {
  const { t, i18n } = useTranslation();

  if (!Configuration.samenvattingBarEnabled) {
    return null;
  }

  return props.blokDefinitie.some((b) => processtapHelpers.hasSamenvatting(b)) ? (
    <Col xxl={3} className="main-container-samenvatting">
      <section role="complementary" aria-label={t(translationKeys["samenvatting-bar"].beschrijving)}>
        <SamenvattingBar.Container key="Samenvatting">
          {props.blokDefinitie
            .filter((b) => processtapHelpers.hasSamenvatting(b))
            .map((b) => {
              return (
                <SamenvattingBar.Item naam={b.title[i18n.language]} type={b.type} key={b.type}>
                  {b.samenvattingBlok ? (
                    React.createElement(b.samenvattingBlok, {
                      processtap: b.type
                    })
                  ) : (
                    <GeneriekSamenvattingBlokRoot processtap={b.type} />
                  )}
                </SamenvattingBar.Item>
              );
            })}
        </SamenvattingBar.Container>
      </section>
    </Col>
  ) : null;
};

export const Formulier = (props: FormulierProps) => {
  const { t } = useTranslation();

  return props.formulier ? (
    <Fragment>
      <Row className={`main-container`}>
        <VisibleWrapper isVisible={!(props.hideHeaders || false)}>
          <Col md={8} className={`main-container-title`}>
            <FormulierHeader formulier={props.formulier} meldingnummer={props.meldingnummer} />
          </Col>
        </VisibleWrapper>
        <Col xxl={9} className={`main-container-content`}>
          <MainContent>
            <main className={`main-section`}>
              <VisibleWrapper isVisible={props.hasNextButton}>
                <div className="form-required-fields">* {t(translationKeys.verplichteVelden)}</div>
              </VisibleWrapper>
              {props.isInitialized && props.isFormulierInitialized && (
                <div className={`main-form`}>
                  {props.processtappen.length > 1 && <FormulierProcesBalk />}
                  <FormulierBreadcrumbs
                    formulierTitel={props.formulier.titel}
                    formulierType={props.type}
                    isMijnMelding={props.meldingnummer != null && isFormulierTypeMelding(props.type)}
                  />
                  {!props.hideLeaveFormulier && props.processtappen.some((p) => p.type !== ProcesstapType.samenvatting) && (
                    <LeaveFormulierDialog />
                  )}
                  <MainForm {...props} />
                </div>
              )}
            </main>
          </MainContent>
        </Col>
        <SamenvattingBarBlokken blokDefinitie={props.blokDefinitie} />
      </Row>
    </Fragment>
  ) : null;
};
