import { Formik, Form as FormikForm } from "formik";
import { useEffect } from "react";
import { Alert, Col, Form as ReactForm, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import logoEn from "../../../assets/icons/britain.svg";
import logoDe from "../../../assets/icons/germany-15.svg";
import logoNl from "../../../assets/icons/netherlands-12.svg";
import logoPl from "../../../assets/icons/poland-11.svg";
import logoRo from "../../../assets/icons/romania-10.svg";
import { translationKeys } from "../../../constants/translation-keys";
import { Mededeling } from "../../../models/api";
import { Breadcrumb } from "../../breadcrumbs";
import { ButtonWithLoader } from "../../button-with-loader";
import { InputControls } from "../../input-controls";
import { Field } from "../../input-controls/field";
import { Spinner } from "../../spinner";
import { BeheerMededelingProps } from "./beheer-mededeling.component.interfaces";

export const BeheerMededeling = (props: BeheerMededelingProps) => {
  const { t } = useTranslation();

  const { onLoad } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const languages = [
    { translationKey: translationKeys.taalselectie.nl, imagePath: logoNl, language: "NL" },
    { translationKey: translationKeys.taalselectie.en, imagePath: logoEn, language: "EN" },
    { translationKey: translationKeys.taalselectie.de, imagePath: logoDe, language: "DE" },
    { translationKey: translationKeys.taalselectie.pl, imagePath: logoPl, language: "PL" },
    { translationKey: translationKeys.taalselectie.ro, imagePath: logoRo, language: "RO" }
  ];

  const extendedLanguages = languages.map((lang) => {
    const matchingMedeling =
      props.mededelingen?.find((m) => m.language === lang.language) ||
      ({ language: lang.language, isEnabled: false } as Mededeling);
    return {
      ...lang,
      mededeling: matchingMedeling
    };
  });

  const formikInitialValues = Object.fromEntries(
    extendedLanguages.map((lang) => [lang.language, { mededeling: lang.mededeling }])
  ) as { [key: string]: { mededeling: Mededeling } };

  const doSubmit = (values: { [key: string]: { mededeling: Mededeling | null } }) => {
    const mededelingen = Object.values(values)
      .map((value) => value.mededeling)
      .filter((mededeling): mededeling is Mededeling => mededeling !== null);
    props.onSubmit(mededelingen);
  };

  const LanguageSection = ({ lang, handleChange, values }: any) => {
    return (
      <div key={lang.translationKey}>
        <h2>
          <img className="flag-logo" alt="" role="presentation" src={lang.imagePath} /> {t(lang.translationKey)}
        </h2>
        <Field name="isEnabled" label={t(translationKeys.beheer.mededeling.actief)}>
          <ReactForm.Check
            aria-label={t(translationKeys.beheer.mededeling.actief)}
            checked={values[lang.language]?.mededeling?.isEnabled ?? false}
            name={`${lang.language}.mededeling.isEnabled`}
            onChange={handleChange}
          />
        </Field>
        <InputControls.TextArea
          name={`${lang.language}.mededeling.content`}
          label={t(translationKeys.beheer.mededeling.inhoud)}
        />
        <div id={`${lang.language}_voorbeeld`}>
          <Field name="voorbeeld" label={<>{t(translationKeys.beheer.mededeling.voorbeeld)}</>}>
            <ReactMarkdown
              key={lang.translationKey}
              children={values[lang.language]?.mededeling?.content}
              className="mededeling-voorbeeld"
            />
          </Field>
        </div>
      </div>
    );
  };

  return (
    <main>
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
      />
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.beheer.mededeling.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.beheer.mededeling.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.beheer.mededeling.titel)}
      />
      <Row className="main-container beheer-mededeling">
        <Col xs={12} className="main-container-title">
          <h1>{t(translationKeys.beheer.titel)}</h1>
          <h2>{t(translationKeys.beheer.mededeling.titel)}</h2>
        </Col>
      </Row>
      <Row className="main-container">
        <Col xs={12}>
          {props.isLoading && <Spinner>{t(translationKeys.bezigMetLaden)}</Spinner>}
          {props.hasApiError && <Alert variant="danger">{t(translationKeys.oepsErGingIetsFout)}</Alert>}
          {!props.isLoading && !props.hasApiError && (
            <Formik
              initialValues={formikInitialValues}
              onSubmit={doSubmit}
              validate={(values) => {
                const errors: Record<string, any> = {};
                Object.keys(values).forEach((language) => {
                  if (values[language]?.mededeling?.isEnabled && !values[language]?.mededeling?.content) {
                    errors[language] = { mededeling: { content: "Indien actief, mededeling verplicht" } };
                  }
                });
                return errors;
              }}
            >
              {({ handleChange, handleSubmit, values, errors }) => (
                <FormikForm onSubmit={handleSubmit}>
                  {extendedLanguages.map((lang) => (
                    <LanguageSection key={lang.translationKey} lang={lang} handleChange={handleChange} values={values} />
                  ))}
                  <ButtonWithLoader
                    id="opslaanButton"
                    isVisible={true}
                    isLoading={props.isLoading}
                    className="next mb-3"
                    type="submit"
                  >
                    {t(translationKeys.opslaan)}
                  </ButtonWithLoader>
                </FormikForm>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </main>
  );
};
