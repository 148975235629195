import { InputPurpose } from "./input-purpose";
import { VraagDisplayDefinition } from "./vraag-display-definition";

export class AdresVragenDisplayDefinition {
  public postcode: VraagDisplayDefinition = {
    inputPurpose: InputPurpose.postalCode
  };

  public plaats: VraagDisplayDefinition = {
    inputPurpose: InputPurpose.addressLevel2
  };
}
