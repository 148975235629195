import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";
import { LocatieAntwoorden } from "./locatie-antwoorden";

export class KlachtenBedrijfsgegevensWerklocatieAntwoorden extends AntwoordBlok {
  constructor(
    public bekendWelkBedrijf: Antwoord<string>,
    public bedrijfslocatiegegevens: BedrijfslocatiegegevensAntwoorden | null,
    public werklocatieZelfdeLocatieAlsWerkgever: Antwoord<string>,
    public werklocatieKvkNummerGebruiken: Antwoord<string>,
    public werklocatieBedrijfsgegevens: BedrijfsgegevensAntwoorden | null,
    public werklocatie: LocatieAntwoorden | null
  ) {
    super();
  }

  static initialize(): KlachtenBedrijfsgegevensWerklocatieAntwoorden {
    return new KlachtenBedrijfsgegevensWerklocatieAntwoorden(
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfsgegevensAntwoorden.initialize(),
      LocatieAntwoorden.initialize()
    );
  }

  static fromJson(json: any): KlachtenBedrijfsgegevensWerklocatieAntwoorden {
    return new KlachtenBedrijfsgegevensWerklocatieAntwoorden(
      Antwoord.fromJson<string>(json.bekendWelkBedrijf),
      BedrijfslocatiegegevensAntwoorden.tryGetFromJson(json.bedrijfslocatiegegevens),
      Antwoord.fromJson<string>(json.werklocatieZelfdeLocatieAlsWerkgever),
      Antwoord.fromJson<string>(json.werklocatieKvkNummerGebruiken),
      BedrijfsgegevensAntwoorden.fromJson(json.werklocatieBedrijfsgegevens),
      LocatieAntwoorden.fromJson(json.werklocatie)
    );
  }
}
