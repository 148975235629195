import { connect } from "react-redux";
import { clearBreadcrumbs } from "../../../actions";
import { State } from "../../../store";
import { Breadcrumbs } from "./breadcrumbs.component";
import { BreadcrumbsDispatchProps, BreadcrumbsStateProps } from "./breadcrumbs.component.interface";

const mapStateToProps = (state: State): BreadcrumbsStateProps => ({
  breadcrumbs: state.breadcrumbs ? state.breadcrumbs.items : []
});

const mapDispatchToProps = (dispatch: any): BreadcrumbsDispatchProps => ({
  onClearBreadcrumbs: () => dispatch(clearBreadcrumbs())
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
