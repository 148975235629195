import { Formuliertype } from "../../application";
import { ArieFormulierVraagBlokken } from "./arie-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class ArieFormulier extends FormulierBase {
  constructor(public blokken: ArieFormulierVraagBlokken, json: any) {
    super(Formuliertype.arie, blokken, json);
  }

  static fromJson(json: any): ArieFormulier {
    return new ArieFormulier(ArieFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
