import { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../../../../constants/translation-keys";
import { useGridRowToggle } from "../../grid-row-toggle";
import { CollectionTableRowToggleProps } from "./collection-table-row-toggle.component.interfaces";

export const CollectionTableRowToggle = (props: CollectionTableRowToggleProps) => {
  const { t } = useTranslation();

  const gridToggleEvent = useGridRowToggle(props.rowKey, props.selectedCollectionItemKey, props.setSelectedCollectionItem);

  const isCurrentEventKey = useCallback(() => {
    return props.rowKey === props.selectedCollectionItemKey;
  }, [props.selectedCollectionItemKey, props.rowKey]);

  const getTitle = useCallback(() => {
    return isCurrentEventKey() ? t(translationKeys.collection.sluitDetails) : t(translationKeys.collection.toonDetails);
  }, [isCurrentEventKey, t]);

  return <Fragment>{props.children(getTitle(), isCurrentEventKey(), gridToggleEvent)}</Fragment>;
};
