import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { KennisgevingFormulierVraagBlokken } from "./kennisgeving-formulier-vraag-blokken";

export class GevaarlijkeLiftFormulier extends FormulierBase {
  constructor(public blokken: KennisgevingFormulierVraagBlokken, json: any) {
    super(Formuliertype.gevaarlijkeLift, blokken, json);
  }

  static fromJson(json: any): GevaarlijkeLiftFormulier {
    return new GevaarlijkeLiftFormulier(KennisgevingFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
