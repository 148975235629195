import { SamenvattingFieldInventarisatievraag } from "components/samenvatting";
import { languageHelpers } from "helpers";
import {
  SamengesteldeVraag,
  TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden,
  TeVerwijderenAsbestMateriaalRisicoklasse1Vragen
} from "../../../../models/api";
import { SamengesteldeVraagType } from "../../../../models/application";

export class TeVerwijderenAsbestMateriaalRisicoklasse1 {
  static getType = (): SamengesteldeVraagType => SamengesteldeVraagType.teVerwijderenAsbestMateriaalRisicoklasse1;

  static create = (vraag: SamengesteldeVraag<TeVerwijderenAsbestMateriaalRisicoklasse1Vragen>): React.JSX.Element => (
    <SamenvattingFieldInventarisatievraag
      vraag={vraag}
      getRowAsString={(values: TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden, language: string) =>
        `${values.hoeveelheid.waarde} ${languageHelpers.getWaardeFromMeerkeuzevraag(
          vraag.subVragen.eenheid.mogelijkeAntwoorden,
          values.eenheid.waarde,
          language
        )} ${values.soortMateriaal.waarde}`
      }
    />
  );
}
