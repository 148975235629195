import {
  AntwoordenActions,
  CANCEL_NAVIGATION,
  CONFIRM_NAVIGATION,
  INIT_FORM,
  NavigationActions,
  REQUEST_ACTION_NAVIGATION,
  REQUEST_URL_NAVIGATION,
  REQUIRE_NAVIGATION_CONFIRMATION,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SET_DIRTY,
  SET_NOT_DIRTY,
  SharedActions
} from "../actions";
import { NavigationState } from "../store";

const initialState: NavigationState = {
  confirmNavigationRequired: false,
  isDirty: false
};

const navigationReducer = (
  state: NavigationState = initialState,
  action: NavigationActions | SharedActions | AntwoordenActions
): NavigationState => {
  switch (action.type) {
    case REQUEST_ACTION_NAVIGATION:
      return {
        ...state,
        requestedAction: action.action
      };
    case REQUEST_URL_NAVIGATION:
      return {
        ...state,
        requestedUrl: action.url,
        skipRouterForUrl: action.skipRouter
      };
    case CONFIRM_NAVIGATION:
      return { ...state, requestedUrl: undefined, skipRouterForUrl: undefined, requestedAction: undefined, isDirty: false };
    case CANCEL_NAVIGATION:
      return { ...state, requestedUrl: undefined, skipRouterForUrl: undefined, requestedAction: undefined };
    case REQUIRE_NAVIGATION_CONFIRMATION:
      return {
        ...state,
        confirmNavigationRequired: action.requireNavigationConfirmation
      };
    case ROUTER_LOCATION_CHANGE:
    case SET_NOT_DIRTY:
    case RESET_FORM:
    case INIT_FORM: {
      return {
        ...state,
        isDirty: false
      };
    }
    case SET_DIRTY: {
      return {
        ...state,
        isDirty: true
      };
    }
    default:
      return state;
  }
};

export default navigationReducer;
