import { commitRequestedPage, saveAntwoorden, setRequestedPage, setSubmitRequested } from "actions";
import { AntwoordBlokken } from "../models/api";
import { AppThunk } from "./app-thunk-type";
import { commitBlokData } from "./blok";

export const onNavigate =
  (antwoordBlokken: AntwoordBlokken): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(commitBlokData());
    dispatch(commitRequestedPage());
    dispatch(saveAntwoorden(antwoordBlokken));
  };

export const onCancelNavigateOrSubmit =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setRequestedPage(null));
    dispatch(setSubmitRequested(false));
  };
