import { setNestedObjectValues, useFormikContext } from "formik";
import { useEffect } from "react";
import { validationHelpers } from "../../../helpers";
import { SubmitHandlerStateProps } from "./submit-handler.component.interfaces";

export const SubmitHandler = (props: SubmitHandlerStateProps) => {
  const { handleSubmit, setErrors, setTouched, validateForm } = useFormikContext();
  const { requestedPageType, activePageType, isSubmitRequested, activePageHasValidationErrors } = props;

  useEffect(() => {
    if ((requestedPageType && requestedPageType !== activePageType) || isSubmitRequested) {
      handleSubmit();
    }
  }, [handleSubmit, requestedPageType, activePageType, isSubmitRequested]);

  useEffect(() => {
    if (!requestedPageType && activePageType && !isSubmitRequested && !!activePageHasValidationErrors) {
      // Validate the form again if the processtap has validation errors
      validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) {
          // Set all fields touched so validation errors will show
          setTouched(setNestedObjectValues(errors, true));
        }
      });
    }
  }, [
    handleSubmit,
    requestedPageType,
    activePageType,
    isSubmitRequested,
    activePageHasValidationErrors,
    validateForm,
    setTouched
  ]);

  useEffect(() => {
    if (props.submitValidationResults) {
      const validationErrorObject = validationHelpers.convertToErrorObject(props.submitValidationResults);

      setTouched(validationErrorObject, false);
      setErrors(validationErrorObject);
    }
  }, [setErrors, setTouched, props.submitValidationResults]);

  return null;
};
