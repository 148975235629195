import { useEffect } from "react";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { MeldingBase, OntheffingNachtarbeidMelding, OntheffingNachtarbeidMeldingAntwoordBlokken } from "../../../models/api";
import { BlokDefinitie, Formuliertype } from "../../../models/application";
import { formulierHelpers } from "./../../../helpers";
import { OntheffingNachtarbeidFormulierComponentProps } from "./ontheffing-nachtarbeid-formulier.component.interfaces";
import { useParams } from "react-router-dom";

export const OntheffingNachtarbeidFormulierComponent = (props: OntheffingNachtarbeidFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.ontheffingNachtarbeid;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const blokDefinitie: BlokDefinitie[] = FormulierDefinitie.getOntheffingNachtarbeidFormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(OntheffingNachtarbeidMelding)}
        getEmailadres={(blokken: OntheffingNachtarbeidMeldingAntwoordBlokken) =>
          blokken.inleiding.gegevensAanvragerOntheffingNachtarbeid.aanvrager?.emailadres.waarde
        }
      ></Formulier>
    );
  } else {
    return null;
  }
};
