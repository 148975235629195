import { AutorisatieProfielVragen, VraagBlokken } from "../blok";
import { HasAutorisatieProfielVragen } from "./has-autorisatie-profiel-vragen";

export class AutorisatieProfielFormulierVraagBlokken extends VraagBlokken implements HasAutorisatieProfielVragen {
  [key: string]: any;

  constructor(public autorisatieProfiel: AutorisatieProfielVragen) {
    super();
  }

  static fromJson(json: any): AutorisatieProfielFormulierVraagBlokken {
    return new AutorisatieProfielFormulierVraagBlokken(
      AutorisatieProfielVragen.fromJson("autorisatieProfiel", json.autorisatieProfiel)
    );
  }
}
