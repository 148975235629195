import { MeldingBasisgegevens } from "models/api/melding/melding-basisgegevens";
import { User } from "models/api/security/user";
import { meldingApi } from "../api";
import { AntwoordBlokken, MeldingContext, MetadataGegevens, ValidationResponse, VraagBlokken } from "../models/api";
import { Autorisatiegegevens } from "../models/api/security/autorisatiegegevens";
import { Formuliertype, ProcesstapType } from "../models/application";
import { getLanguage } from "./language-helpers";
import { validationHelpers } from "./validation-helpers";

export class MeldingValidator {
  protected validate = async (validateArgs: {
    meldingId: string | undefined;
    vraagBlokken: VraagBlokken;
    antwoordBlokken: AntwoordBlokken;
    user: User | null;
    autorisatiegegevens: Autorisatiegegevens;
    meldingContext: MeldingContext;
    processtap: ProcesstapType;
    validateServerSide: boolean;
    formulierType: Formuliertype;
    submitValidationResults?: ValidationResponse;
  }) => {
    const validationResult = validationHelpers.validateGenericClientSide(
      validateArgs.vraagBlokken,
      validateArgs.antwoordBlokken,
      validateArgs.user,
      validateArgs.autorisatiegegevens,
      validateArgs.meldingContext,
      validateArgs.processtap
    );

    if (validationResult.isValid && validateArgs.validateServerSide) {
      validationResult.addValidationResponse(
        await meldingApi.createValidate(
          validateArgs.formulierType,
          validateArgs.processtap
        )({
          basisgegevens: new MeldingBasisgegevens(validateArgs.meldingId),
          blokken: validateArgs.antwoordBlokken,
          metadata: new MetadataGegevens(validateArgs.autorisatiegegevens),
          taal: getLanguage(),
          id: validateArgs.meldingId,
          captchaToken: validateArgs.antwoordBlokken.captcha as unknown as string
        })
      );
    }

    if (validateArgs.submitValidationResults) {
      validationResult.addValidationResponse(validateArgs.submitValidationResults);
    }

    return validationResult;
  };

  static createValidate = (
    vraagBlokken: VraagBlokken
  ): ((
    meldingId: string | undefined,
    antwoordBlokken: AntwoordBlokken,
    user: User | null,
    autorisatiegegevens: Autorisatiegegevens,
    meldingContext: MeldingContext,
    processtap: ProcesstapType,
    validateServerSide: boolean,
    formulierType: Formuliertype,
    submitValidationResults?: ValidationResponse
  ) => Promise<any>) => {
    const validator = new MeldingValidator();

    return (
      meldingId,
      antwoordBlokken,
      user,
      autorisatiegegevens,
      meldingContext,
      processtap,
      validateServerSide,
      formulierType,
      submitValidationResults
    ) =>
      validator.validate({
        meldingId,
        vraagBlokken,
        antwoordBlokken,
        user,
        autorisatiegegevens,
        meldingContext,
        processtap,
        validateServerSide,
        formulierType,
        submitValidationResults
      });
  };
}
