import { HasBijlageVragen, HasInformatieverzoekVragen, HasVerzoekMelderVragen } from "..";
import { BijlageVragen, InformatieverzoekVragen, VerzoekMelderVragen, VraagBlokken } from "../blok";

export class InformatieverzoekFormulierVraagBlokken
  extends VraagBlokken
  implements HasInformatieverzoekVragen, HasVerzoekMelderVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(public verzoek: InformatieverzoekVragen, public melder: VerzoekMelderVragen, public bijlage: BijlageVragen) {
    super();
  }

  static fromJson(json: any): InformatieverzoekFormulierVraagBlokken {
    return new InformatieverzoekFormulierVraagBlokken(
      InformatieverzoekVragen.fromJson("verzoek", json.verzoek),
      VerzoekMelderVragen.fromJson("melder", json.melder),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
