import { User } from "models/api/security/user";
import { expressionHelpers, objectHelpers } from "../../../helpers";
import { AntwoordBlok, AntwoordBlokken, MeldingContext, VraagBlok, VraagBlokken } from "../../../models/api";
import { ExpressionContext } from "../../../models/api/blok/expression-context";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";

export const isVraagBlokReadonly = (
  item: VraagBlok,
  vragen: VraagBlokken,
  antwoorden: AntwoordBlokken,
  user: User | null,
  autorisatiegegevens: Autorisatiegegevens,
  melding: MeldingContext
) => {
  let isReadonly = false;

  const antwoordBlok = objectHelpers.getValue<AntwoordBlok>(antwoorden, item.key);

  if (antwoordBlok) {
    const context = new ExpressionContext(user, autorisatiegegevens, melding, antwoordBlok, antwoorden, vragen);

    isReadonly = expressionHelpers.isVraagBlokReadonly(item, context);
  }

  return isReadonly;
};
