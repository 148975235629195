import {
  LocatieMetToelichtingVragen,
  MelderVragen,
  OntheffingLiftenAanvraagVragen,
  OpdrachtgeverOntheffingLiftenVragen,
  VraagBlokken
} from "../blok";
import { HasAanvragerVragen } from "./has-aanvrager-vragen";
import { HasEigenaarVragen } from "./has-eigenaar-vragen";
import { HasLocatieMetToelichtingVragen } from "./has-locatie-met-toelichting-vragen";
import { HasOntheffingLiftenAanvraagVragen } from "./has-ontheffing-liften-aanvraag-vragen";

export class OntheffingLiftenFormulierVraagBlokken
  extends VraagBlokken
  implements HasAanvragerVragen, HasEigenaarVragen, HasLocatieMetToelichtingVragen, HasOntheffingLiftenAanvraagVragen
{
  [key: string]: any;

  constructor(
    public aanvrager: MelderVragen,
    public eigenaar: OpdrachtgeverOntheffingLiftenVragen,
    public locatie: LocatieMetToelichtingVragen,
    public aanvraag: OntheffingLiftenAanvraagVragen
  ) {
    super();
  }

  static fromJson(json: any): OntheffingLiftenFormulierVraagBlokken {
    return new OntheffingLiftenFormulierVraagBlokken(
      MelderVragen.fromJson("aanvrager", json.aanvrager),
      OpdrachtgeverOntheffingLiftenVragen.fromJson("eigenaar", json.eigenaar),
      LocatieMetToelichtingVragen.fromJson("locatie", json.locatie),
      OntheffingLiftenAanvraagVragen.fromJson("aanvraag", json.aanvraag)
    );
  }
}
