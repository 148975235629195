import { connect } from "react-redux";
import { DouaneFormulier } from "../../../models/api/formulier/douane-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getDouaneMelding, initDouaneFormulier, initDouaneFormulierFromCopy } from "../../../thunks";
import { DouaneFormulierComponent } from "./douane-formulier.component";
import {
  DouaneFormulierComponentDispatchProps,
  DouaneFormulierComponentStateProps
} from "./douane-formulier.component.interfaces";

const mapStateToProps = (state: State): DouaneFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.douane ? (state.vragen.formulier as DouaneFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): DouaneFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initDouaneFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getDouaneMelding(meldingId));
    } else {
      dispatch(initDouaneFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DouaneFormulierComponent);
