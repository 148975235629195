import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class HasMultipleValues extends VraagExpression {
  get operator(): string {
    return HasMultipleValues.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;
    return antwoord?.toString().split(",").length > 1;
  };

  protected clone = (instance: HasMultipleValues): HasMultipleValues => new HasMultipleValues(instance.vraagProperty);

  static fromJson = (json: any): HasMultipleValues => new HasMultipleValues(json.vraag);

  static operator = (): string => "hasMultipleValues";

  static matches = (json: any): boolean => json?.operator === HasMultipleValues.operator();
}
