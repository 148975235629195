import { useField, useFormikContext } from "formik";
import { ReactElement } from "react";
import { Form } from "react-bootstrap";
import { InputOptionProps } from "../input-option-base/input-option-base.interface";

export const InputOptionBase = (props: InputOptionProps): ReactElement => {
  const [field, , fieldHelpers] = useField(props.name);

  const { optionValue, touched, onChange: onPropsChange, type, checked, ...propsRest } = props;

  const { onChange: onFieldChange, onBlur } = field;

  const { validateField } = useFormikContext<any>();

  const onChange = async (e: any) => {
    const eventCopy = { ...e };

    onFieldChange(eventCopy);

    if (onPropsChange) {
      onPropsChange(eventCopy);
    }

    setTimeout(() => {
      fieldHelpers.setTouched(true);
      validateField(props.name);
    });
  };

  return (
    <Form.Check
      type={type}
      checked={checked}
      {...propsRest}
      value={optionValue}
      bsSwitchPrefix="custom"
      onBlur={!props.readOnly ? onBlur : undefined}
      onChange={!props.readOnly ? onChange : undefined}
    />
  );
};
