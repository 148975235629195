import { meldingApi } from "api";
import { Paragraaf } from "components/blok/generiek-samenvatting-blok/supported-tekst-typen/paragraaf";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { BestandImportVraag } from "../bestand-import-vraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { OntheffingKinderarbeidMeldingAntwoordBlokken } from "../melding";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";
import { importerenKinderarbeidBestandHandler } from "./importeren-kinderarbeid-bestand-handler";

export class ImporterenKinderarbeidVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public importerenKinderarbeidToelichting: Paragraaf,
    public importerenKinderarbeidBestandVoorwaarden: Paragraaf,
    public importerenKinderarbeidWaarschuwingWerkmomenten: Paragraaf,
    public importerenKinderarbeidNietMogelijk: Paragraaf,
    public importerenKinderarbeidNietMogelijkRisicoOnbekend: Paragraaf,
    public importerenKinderarbeidVoorbeeldbestandTitel: Tekst,
    public importerenKinderarbeidVoorbeeldbestand: Paragraaf,
    public maaktGebruikVanImporterenKinderarbeid: Meerkeuzevraag,
    public uploadBestandImporterenKinderarbeidTitel: Tekst,
    public uploadBestandImporterenKinderarbeidToelichting: Paragraaf,
    public importerenKinderarbeidBestand: BestandImportVraag<OntheffingKinderarbeidMeldingAntwoordBlokken>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ImporterenKinderarbeidVragen {
    const voorbeeldBestandUrl = meldingApi.getOntheffingKinderarbeidImportVoorbeeldbestandUrl();

    return new ImporterenKinderarbeidVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(`${key}.importerenKinderarbeidToelichting`, json.importerenKinderarbeidToelichting),
      jsonHelpers.toParagraaf(
        `${key}.importerenKinderarbeidBestandVoorwaarden`,
        json.importerenKinderarbeidBestandVoorwaarden
      ),
      jsonHelpers.toInfo(
        `${key}.importerenKinderarbeidWaarschuwingWerkmomenten`,
        json.importerenKinderarbeidWaarschuwingWerkmomenten
      ),
      jsonHelpers.toWaarschuwing(`${key}.importerenKinderarbeidNietMogelijk`, json.importerenKinderarbeidNietMogelijk),
      jsonHelpers.toWaarschuwing(
        `${key}.importerenKinderarbeidNietMogelijkRisicoOnbekend`,
        json.importerenKinderarbeidNietMogelijkRisicoOnbekend
      ),
      jsonHelpers.toTitel(
        `${key}.importerenKinderarbeidVoorbeeldbestandTitel`,
        json.importerenKinderarbeidVoorbeeldbestandTitel
      ),
      jsonHelpers.toParagraaf(
        `${key}.importerenKinderarbeidVoorbeeldbestand`,
        json.importerenKinderarbeidVoorbeeldbestand,
        new Map<string, string>([["%importfileurl%", voorbeeldBestandUrl]])
      ),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.maaktGebruikVanImporterenKinderarbeid`,
        json.maaktGebruikVanImporterenKinderarbeid
      ),
      jsonHelpers.toTitel(`${key}.uploadBestandImporterenKinderarbeidTitel`, json.uploadBestandImporterenKinderarbeidTitel),
      jsonHelpers.toParagraaf(
        `${key}.uploadBestandImporterenKinderarbeidToelichting`,
        json.uploadBestandImporterenKinderarbeidToelichting
      ),
      jsonHelpers.toBestandImportVraag(
        `${key}.importerenKinderarbeidBestand`,
        json.importerenKinderarbeidBestand,
        importerenKinderarbeidBestandHandler
      )
    );
  }
}
