import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { MelderAntwoorden } from "./melder-antwoorden";

export class VerzoekMelderAntwoorden extends AntwoordBlok {
  constructor(public aanvragerFunctie: Antwoord<string>, public common: MelderAntwoorden | null) {
    super();
  }

  static initialize(user: User | null = null): VerzoekMelderAntwoorden {
    return new VerzoekMelderAntwoorden(new Antwoord<string>(""), MelderAntwoorden.initialize(user));
  }

  static fromJson(json: any): VerzoekMelderAntwoorden {
    return new VerzoekMelderAntwoorden(
      Antwoord.fromJson<string>(json.aanvragerFunctie),
      MelderAntwoorden.fromJson(json.common)
    );
  }
}
