import { BouwprocesFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getBouwprocesFormulierDefinitie = (formulier: BouwprocesFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.bouwwerk.titelVerkort,
    type: ProcesstapType.bouwwerk
  },
  {
    title: formulier.blokken.opdrachtgever.titel,
    type: ProcesstapType.opdrachtgever
  },
  {
    title: formulier.blokken.ontwerpendePartij.titel,
    type: ProcesstapType.ontwerpendePartij
  },
  {
    title: formulier.blokken.uitvoerendePartij.titel,
    type: ProcesstapType.uitvoerendePartij
  },
  {
    title: formulier.blokken.aannemers.titel,
    type: ProcesstapType.aannemers
  },
  {
    title: formulier.blokken.werkzaamheden.titel,
    type: ProcesstapType.werkzaamheden
  }
];
