import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";
import { LocatieMetToelichtingVragen } from "./locatie-met-toelichting-vragen";

export class WerkzaamhedenOpAdresBedrijfLocatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public werkzaamhedenLocatieBedrijf: Meerkeuzevraag,
    public locatie: LocatieMetToelichtingVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): WerkzaamhedenOpAdresBedrijfLocatieVragen {
    return new WerkzaamhedenOpAdresBedrijfLocatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toMeerkeuzeVraag(`${key}.werkzaamhedenLocatieBedrijf`, json.werkzaamhedenLocatieBedrijf),
      LocatieMetToelichtingVragen.fromJson(`${key}.locatie`, json.locatie)
    );
  }
}
