import React from "react";
import { FormulierHeaderProps } from "./formulier-header.component.interfaces";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

export const FormulierHeader = (props: FormulierHeaderProps) => {
  const { i18n } = useTranslation();

  const lang = i18n.language;

  const meldingnummerPrefix = props.meldingnummer ? `${props.meldingnummer} - ` : "";

  return props.formulier ? (
    <section aria-labelledby="formulierTitel">
      <h1 id="formulierTitel">
        {meldingnummerPrefix}
        {props.formulier.titel[lang]}
      </h1>
      <div>
        <ReactMarkdown children={props.formulier.helptekst[lang]}></ReactMarkdown>
      </div>
    </section>
  ) : null;
};
