import { VisibleWrapper } from "../visible-wrapper";
import { ProgressBarProps } from "./progress-bar.component.interfaces";

export const ProgressBar = (props: ProgressBarProps) => {
  const completePercentage = 100;
  const fillCompleteClass = props.value === completePercentage ? "progress-bar-fill-complete" : "";

  return (
    <VisibleWrapper isVisible={props.isVisible}>
      <div className={`progress-bar-container ${props.className}`}>
        <div className={`progress-bar-fill ${fillCompleteClass}`} style={{ width: `${props.value}%` }}></div>
        <span className="progress-bar-label">{props.label}</span>
      </div>
    </VisibleWrapper>
  );
};
