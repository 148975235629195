import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { AvvArtikel10AVerzoekMelding, AvvArtikel10AVerzoekMeldingAntwoordBlokken, MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { formulierHelpers } from "./../../../helpers";
import { AvvArtikel10AVerzoekFormulierComponentProps } from "./avv-artikel10a-verzoek-formulier.component.interfaces";

export const AvvArtikel10AVerzoekFormulierComponent = (props: AvvArtikel10AVerzoekFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.avvArtikel10AVerzoek;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const blokDefinitie = FormulierDefinitie.getAvvArtikel10AVerzoekFormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={Formuliertype.avvArtikel10AVerzoek}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(AvvArtikel10AVerzoekMelding)}
        getEmailadres={(blokken: AvvArtikel10AVerzoekMeldingAntwoordBlokken) => blokken.verzoeker.melder?.emailadres.waarde}
      ></Formulier>
    );
  } else {
    return null;
  }
};
