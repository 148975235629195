import { IdentityProviders } from "../../../constants";
import { userHelpers } from "../../../helpers";
import { User } from "../security/user";
export class Autorisatiegegevens {
  constructor(
    public isIngelogd: boolean,
    public isBedrijf: boolean,
    public isBedrijfIngeschreven: boolean,
    public isParticulier: boolean,
    public externalLoginType: string,
    public isExternalLogin: boolean,
    public isInterneMedewerker: boolean
  ) {}

  static initialize(user: User | null = null): Autorisatiegegevens {
    return new Autorisatiegegevens(
      Autorisatiegegevens.isIngelogd(user),
      Autorisatiegegevens.isBedrijf(user),
      Autorisatiegegevens.isBedrijfIngeschreven(user),
      Autorisatiegegevens.isParticulier(user),
      Autorisatiegegevens.getExternalLoginType(user),
      Autorisatiegegevens.isExternalLogin(user),
      Autorisatiegegevens.isInterneMedewerker(user)
    );
  }

  private static isIngelogd(user: User | null = null): boolean {
    return user !== null;
  }

  private static isBedrijf(user: User | null = null): boolean {
    return userHelpers.getIsBedrijf(user);
  }

  private static isExternalLogin(user: User | null = null): boolean {
    const identityProvider = userHelpers.getIdentityProvider(user);
    return identityProvider !== IdentityProviders.local;
  }

  private static getExternalLoginType(user: User | null = null): string {
    const identityProvider = userHelpers.getIdentityProvider(user);
    return Autorisatiegegevens.isExternalLogin(user) ? identityProvider : "";
  }

  private static isBedrijfIngeschreven(user: User | null = null): boolean {
    const kvkNummer = user?.kvkNummer;
    const vestigingsnummer = user?.vestigingsnummer;

    return (
      Autorisatiegegevens.isBedrijf(user) &&
      ((kvkNummer !== undefined && kvkNummer.length > 0) || (vestigingsnummer !== undefined && vestigingsnummer.length > 0))
    );
  }

  private static isParticulier(user: User | null = null): boolean {
    return userHelpers.getIsParticulier(user);
  }

  private static isInterneMedewerker(user: User | null = null): boolean {
    return userHelpers.getIsInternalUser(user);
  }

  static fromJson(json: any): Autorisatiegegevens {
    return json
      ? new Autorisatiegegevens(
          json.isIngelogd,
          json.isBedrijf,
          json.isBedrijfIngeschreven,
          json.isParticulier,
          json.externalLoginType,
          json.isExternalLogin,
          json.isInterneMedewerker
        )
      : new Autorisatiegegevens(false, false, false, false, "", false, false);
  }
}
