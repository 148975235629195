import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensLavsAntwoorden } from "./bedrijfsgegevens-lavs-antwoorden";

export class CertificerendeInstellingAntwoorden extends AntwoordBlok {
  constructor(public bedrijfsgegevens: BedrijfsgegevensLavsAntwoorden) {
    super();
  }

  static initialize() {
    return new CertificerendeInstellingAntwoorden(BedrijfsgegevensLavsAntwoorden.initialize());
  }

  static fromJson(json: any) {
    return new CertificerendeInstellingAntwoorden(BedrijfsgegevensLavsAntwoorden.fromJson(json.bedrijfsgegevens));
  }
}
