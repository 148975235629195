import { Configuration } from "../configuration";
import { Mededeling } from "../models/api";
import { get, put } from "./api";

const mededelingBaseUrl = `${Configuration.apiUrl}/mededelingen`;

const getMededeling = (): Promise<Mededeling[]> =>
  get({
    uri: `${mededelingBaseUrl}`,
    success: (r) => r
  });

const updateMededeling = (mededelingen: Mededeling[]) =>
  put({
    uri: `${mededelingBaseUrl}`,
    data: mededelingen,
    success: (r) => r
  });

export const mededelingApi = {
  getMededeling,
  updateMededeling
};
