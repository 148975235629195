import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { VluchtigeOrganischeStoffenFormulierVraagBlokken } from "./vluchtige-organische-stoffen-formulier-vraag-blokken";

export class VluchtigeOrganischeStoffenFormulier extends FormulierBase {
  constructor(public blokken: VluchtigeOrganischeStoffenFormulierVraagBlokken, json: any) {
    super(Formuliertype.vluchtigeOrganischeStoffen, blokken, json);
  }

  static fromJson(json: any): VluchtigeOrganischeStoffenFormulier {
    return new VluchtigeOrganischeStoffenFormulier(
      VluchtigeOrganischeStoffenFormulierVraagBlokken.fromJson(json.blokken),
      json
    );
  }
}
