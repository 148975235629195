import { FormikContextType } from "formik";
import { objectHelpers } from "helpers";
import { AntwoordBlokken, VraagBlokCollectionVerplichtType } from "models/api";
import { connect } from "react-redux";
import { State } from "store";
import { addVraagBlok } from "thunks";
import { CollectionAdd } from "./collection-add.component";
import {
  CollectionAddDispatchProps,
  CollectionAddOwnProps,
  CollectionAddStateProps
} from "./collection-add.component.interfaces";

const mapStateToProps = (state: State, props: CollectionAddOwnProps): CollectionAddStateProps => ({
  totalRecords: state.collections.list[props.collectionKey]?.unfilteredTotalRecords ?? 0,
  hasCollectionValidationFailures: state.collections.list[props.collectionKey]?.validationFailures.length > 0
});

const mapDispatchToProps = (dispatch: any, props: CollectionAddOwnProps): CollectionAddDispatchProps => ({
  onAddVraagBlok: (antwoordBlokkenContext: FormikContextType<AntwoordBlokken>) => {
    dispatch(
      addVraagBlok(
        props.collectionKey,
        props.template,
        antwoordBlokkenContext,
        props.getVraagBlokFromTemplate,
        props.initializeAntwoorden
      )
    );
  },
  addInitialVraagBlok: (antwoordBlokkenContext: FormikContextType<AntwoordBlokken>) => {
    const list = objectHelpers.getVraagBlokCollectionValues(antwoordBlokkenContext.values, props.collectionKey);
    if (list && list.length === 0 && props.collectionVerplichtType === VraagBlokCollectionVerplichtType.VerplichtEnGetoond) {
      dispatch(
        addVraagBlok(
          props.collectionKey,
          props.template,
          antwoordBlokkenContext,
          props.getVraagBlokFromTemplate,
          props.initializeAntwoorden,
          true
        )
      );
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionAdd);
