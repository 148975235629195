import { ExternReferentienummerType } from "models/api/melding/externReferentienummerType";
import { Ontvangstwijze } from "models/api/melding/ontvangstwijze";

export class ExterneReferentie {
  constructor(
    public externReferentienummer: string | undefined,
    public externReferentienummerType: ExternReferentienummerType | undefined,
    public ontvangstwijze: Ontvangstwijze | undefined,
    public datumAangemaakt: Date | undefined
  ) {}
}
