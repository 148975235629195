import { Formik } from "formik";
import { Fragment, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { validationHelpers } from "../../../helpers";
import { ValidationResponse } from "../../../models/api";
import { ButtonWithLoader } from "../../button-with-loader";
import { Captcha } from "../../captcha";
import { SubmitHandler } from "../submit-handler";
import { FormulierFormikOwnProps } from "./formulier-formik.component.interfaces";

export const FormulierFormik = (props: FormulierFormikOwnProps) => {
  const { t } = useTranslation();

  const handleFormSubmit = (e: any) => {
    // The form will be submitted (even though it should not) on some occasions
    e.preventDefault();
  };

  const { reinitializeForm, onFormReinitializationComplete } = props;

  useEffect(() => {
    if (reinitializeForm) {
      onFormReinitializationComplete();
    }
  }, [reinitializeForm, onFormReinitializationComplete]);

  return (
    <Formik
      validate={async (values) =>
        validationHelpers.convertToErrorObject(await props.validate(values, props.isInterneMedewerker))
      }
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={props.blokken}
      enableReinitialize={props.reinitializeForm}
      onSubmit={async (values, actions) => {
        actions.setTouched({});

        // Validate (including server validation)
        const validationResults: ValidationResponse = await props.validate(values, props.isInterneMedewerker, true);

        // Keep server validation errors
        actions.setStatus(validationHelpers.convertToErrorObject(validationResults, true));

        actions.setSubmitting(false);

        if (validationResults.isValid) {
          if (props.navigateOnSubmit) {
            props.onNavigate(values);
          } else {
            props.onSubmit(values);
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Fragment>
          <Form onSubmit={handleFormSubmit}>
            {props.children}
            {props.hasVerzendenButton && <Captcha />}
            <ButtonWithLoader
              id="vorigeStapButton"
              isVisible={props.hasPreviousButton}
              isLoading={isSubmitting || props.isSubmitting}
              className="previous"
              onClick={() => {
                props.onPrevious();
              }}
            >
              {t(translationKeys.vorigeStap)}
            </ButtonWithLoader>
            <ButtonWithLoader
              id="volgendeStapButton"
              isVisible={props.hasNextButton}
              isLoading={isSubmitting || props.isSubmitting}
              className="next"
              onClick={() => {
                props.onNext();
              }}
            >
              {t(translationKeys.volgendeStap)}
            </ButtonWithLoader>
            <ButtonWithLoader
              id="verzendenButton"
              isVisible={props.hasVerzendenButton}
              isLoading={isSubmitting || props.isSubmitting}
              className="next"
              onClick={() => {
                props.onSubmitRequested();
              }}
            >
              {props.verzendenButtonText ? props.verzendenButtonText : t(translationKeys.verzenden)}
            </ButtonWithLoader>
            <SubmitHandler />
          </Form>
        </Fragment>
      )}
    </Formik>
  );
};
