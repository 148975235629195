import { DebounceProvider } from "components/input-controls/debounce-input";
import { Field } from "components/input-controls/field";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CollectionAdd } from "../collection-add";
import { CollectionError, CollectionErrorsOnInactiveItems } from "../collection-errors";
import { CollectionHandler } from "../collection-handler";
import { CollectionValidator } from "../collection-validator";
import { getVraagCollectionLabel } from "../vraag-blok-collection.helpers";
import { CollectionAccordionContainer } from "./collection-accordion";
import { VraagBlokkenCollectionAccordionOwnProps } from "./vraag-blokken-collection-accordion.component.interface";

const VraagBlokkenCollectionAccordionCmp = (props: VraagBlokkenCollectionAccordionOwnProps) => {
  const { i18n } = useTranslation();

  return (
    <Fragment>
      <div data-test={props.vraagBlokCollection.key} className={`vraagblok-collection-accordion-container`}>
        <DebounceProvider>
          <Field
            name={props.vraagBlokCollection}
            label={getVraagCollectionLabel(props.vraagBlokCollection, i18n.language)}
            header={props.vraagBlokCollection.helptekst ? props.vraagBlokCollection.helptekst[i18n.language] : undefined}
            inputOnly={props.inputOnly ?? false}
            showLabel={props.vraagBlokCollection.showLabel}
          >
            <CollectionHandler collectionKey={props.vraagBlokCollection.key} vraagBlokken={props.vraagBlokCollection.list} />
            <CollectionValidator collectionKey={props.vraagBlokCollection.key} />

            <CollectionAccordionContainer
              getCollectionItemSummary={props.getCollectionItemSummary}
              collectionKey={props.vraagBlokCollection.key}
              collectionVerplichtType={props.vraagBlokCollection.verplichtType}
              itemComponent={props.itemComponent}
            />
            <CollectionAdd
              template={props.vraagBlokCollection.template}
              collectionKey={props.vraagBlokCollection.key}
              collectionVerplichtType={props.vraagBlokCollection.verplichtType}
              extraToevoegenTekst={props.vraagBlokCollection.extraToevoegenTekst}
              toevoegenTekst={props.vraagBlokCollection.toevoegenTekst}
              getVraagBlokFromTemplate={props.getVraagBlokFromTemplate}
              initializeAntwoorden={props.initializeAntwoorden}
            />
            <CollectionError collectionKey={props.vraagBlokCollection.key} />
            <CollectionErrorsOnInactiveItems
              collectionKey={props.vraagBlokCollection.key}
              vraagBlokCollection={props.vraagBlokCollection}
              getCollectionItemSummary={props.getCollectionItemSummary}
            />
          </Field>
        </DebounceProvider>
      </div>
    </Fragment>
  );
};

export const VraagBlokkenCollectionAccordion = connect()(VraagBlokkenCollectionAccordionCmp);
