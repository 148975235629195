export class AutorisatiesListItem {
  constructor(
    public id: string,
    public bedrijfsnaam: string,
    public gebruikersnaam: string,
    public achternaam: string,
    public kvknummer: string,
    public vestigingsnummer: string,
    public geldig: string
  ) {}

  static fromJson(json: any): AutorisatiesListItem {
    return new AutorisatiesListItem(
      json.id,
      json.bedrijfsnaam,
      json.gebruikersnaam,
      json.achternaam,
      json.kvknummer,
      json.vestigingsnummer,
      json.geldig
    );
  }
}
