import { connect } from "react-redux";
import { State } from "store";
import { handleTokenExpired, handleTokenExpiring } from "thunks/security-thunks";
import { SecurityExpirationHandler } from "./security-expiration-handler.component";
import {
  SecurityExpirationHandlerDispatchProps,
  SecurityExpirationHandlerStateProps
} from "./security-expiration-handler.component.interfaces";

const mapStateToProps = (state: State): SecurityExpirationHandlerStateProps => ({
  userInfoChanges: state.security.userInfoChanges,
  user: state.security.user
});

const mapDispatchToProps = (dispatch: any): SecurityExpirationHandlerDispatchProps => ({
  onEndSession() {
    dispatch(handleTokenExpired());
  },
  onExtendSession() {
    dispatch(handleTokenExpiring());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityExpirationHandler);
