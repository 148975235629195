import { Land } from "models/api/landen-search-response";

export const SET_LANDEN_ISLOADING = "SET_LANDEN_ISLOADING";
export const SET_LANDEN = "SET_LANDEN";

interface SetLandenIsLoadingAction {
  type: typeof SET_LANDEN_ISLOADING;
  data: boolean;
}

interface SetLandenAction {
  type: typeof SET_LANDEN;
  data: Land[];
}

export type LandenActions = SetLandenIsLoadingAction | SetLandenAction;

export const setLandenIsLoading = (isLoading: boolean): SetLandenIsLoadingAction => {
  return {
    type: SET_LANDEN_ISLOADING,
    data: isLoading
  };
};

export const setLanden = (landen: Land[]): SetLandenAction => {
  return {
    type: SET_LANDEN,
    data: landen
  };
};
