import { VraagBlokWithAntwoordIndex } from "../../../model";
import { CollectionTableRow } from "../collection-table-row";
import { CollectionTableBodyProps } from "./collection-table-body.component.interfaces";

export const CollectionTableBody = (props: CollectionTableBodyProps) => {
  return (
    <tbody>
      {props.vraagBlokAntwoordIndexes
        .slice(props.pageStartIndex, props.gridPageSize + props.pageStartIndex)
        .map((vraagAntwoordBlok: VraagBlokWithAntwoordIndex, vraagAntwoordBlokIndex: number) => (
          <CollectionTableRow
            columnSettings={props.columnSettings}
            collectionKey={props.collectionKey}
            collectionVerplichtType={props.collectionVerplichtType}
            itemComponent={props.itemComponent}
            rowIndex={vraagAntwoordBlokIndex}
            vraagAntwoordBlok={vraagAntwoordBlok}
            key={vraagAntwoordBlok.vraagBlok.key}
            processtapType={props.processtapType}
            removeCollectionItem={props.removeCollectionItem}
            template={props.template}
            getVraagBlokFromTemplate={props.getVraagBlokFromTemplate}
            copyAntwoorden={props.copyAntwoorden}
          />
        ))}
    </tbody>
  );
};
