import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { jsonHelpers } from "./../../../helpers/json-helpers";

export class ArieMeldingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public ariePlichtigMelding: Meerkeuzevraag,
    public ariePlichtigRedenAfmelding: Meerkeuzevraag,
    public ariePlichtigAfmeldingOverigeReden: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ArieMeldingVragen {
    return new ArieMeldingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toMeerkeuzeVraag(`${key}.ariePlichtigMelding`, json.ariePlichtigMelding),
      jsonHelpers.toMeerkeuzeVraag(`${key}.ariePlichtigRedenAfmelding`, json.ariePlichtigRedenAfmelding),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.ariePlichtigAfmeldingOverigeReden`,
        json.ariePlichtigAfmeldingOverigeReden
      )
    );
  }
}
