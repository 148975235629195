import { VraagBlokkenCollectionAccordion, VraagBlokkenCollectionGrid } from "../blok/vraag-blokken-collection/";
import { InputCheck, InputCheckOption } from "./input-check";
import { InputDate } from "./input-date";
import { InputDropdown, InputDropdownOption } from "./input-dropdown";
import { InputFileImport } from "./input-file-import";
import { InputGebrokenAantal } from "./input-gebroken-aantal";
import { InputNumber } from "./input-number";
import { InputRadio, InputRadioOption } from "./input-radio";
import { Recaptcha } from "./input-recaptcha";
import { InputSearch } from "./input-search";
import { InputTabular } from "./input-tabular";
import { InputText } from "./input-text";
import { InputTextArea } from "./input-textarea";
import { InputTime } from "./input-time";
import { InputAsyncTypeahead, InputSyncTypeahead } from "./input-typeahead";
import { InputUniqueFiles } from "./input-unique-files";
import { withSetDirty } from "./with-set-dirty";
import { withValidation } from "./with-validation";

const container = {
  Files: withValidation(withSetDirty(InputUniqueFiles)),
  FileImport: withValidation(withSetDirty(InputFileImport)),
  Time: withValidation(withSetDirty(InputTime)),
  Text: withValidation(withSetDirty(InputText)),
  TextArea: withValidation(withSetDirty(InputTextArea)),
  Number: withValidation(withSetDirty(InputNumber)),
  GebrokenAantal: withValidation(withSetDirty(InputGebrokenAantal)),
  Check: withSetDirty(InputCheck),
  CheckOption: withValidation(InputCheckOption, false),
  Radio: withSetDirty(InputRadio),
  RadioOption: withValidation(InputRadioOption, false),
  Dropdown: withValidation(withSetDirty(InputDropdown, true)),
  DropdownOption: InputDropdownOption,
  AsyncTypeahead: withValidation(withSetDirty(InputAsyncTypeahead, true)),
  SyncTypeahead: withValidation(withSetDirty(InputSyncTypeahead, true)),
  Date: withValidation(withSetDirty(InputDate)),
  Recaptcha: withValidation(Recaptcha),
  Tabular: withValidation(withSetDirty(InputTabular)),
  CollectionGrid: VraagBlokkenCollectionGrid,
  CollectionAccordion: VraagBlokkenCollectionAccordion,
  Search: withSetDirty(InputSearch)
};

export * from "./volgnummer-element";
export { container as InputControls };
