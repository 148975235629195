import { ValidationFailure } from "./validation-failure";

export class ValidationResponse {
  get isValid(): boolean {
    return this.validationFailures.length === 0;
  }

  constructor(public validationFailures: ValidationFailure[]) {}

  public addValidationResponse = (validationResponse: ValidationResponse) => {
    this.addValidationFailures(validationResponse.validationFailures);
  };

  public addValidationFailures = (validationFailures: ValidationFailure[]) => {
    this.validationFailures.push(...validationFailures);
  };

  public contains = (property: string): boolean =>
    this.validationFailures.some((f) => f.property?.toLowerCase() === property.toLowerCase());

  static fromJson(json: any): ValidationResponse {
    return new ValidationResponse(json.validationFailures.map((x: any) => ValidationFailure.fromJson(x)));
  }
}
