import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { MeldingValidator } from "../../../helpers/melding-validator";
import {
  AsbestverwijderingLavsMelding,
  AsbestverwijderingLavsMeldingAntwoordBlokken,
  MeldingBase
} from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { formulierHelpers } from "./../../../helpers";
import { AsbestverwijderingLavsFormulierComponentProps } from "./asbestverwijdering-lavs-formulier.component.interfaces";

export const AsbestverwijderingLavsFormulierComponent = (props: AsbestverwijderingLavsFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId);
  }, [onInit, meldingId]);

  const formuliertype = Formuliertype.asbestverwijderingLavs;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      false
    )
  ) {
    const blokDefinitie = FormulierDefinitie.getAsbestverwijderingLavsFormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(AsbestverwijderingLavsMelding)}
        getEmailadres={(blokken: AsbestverwijderingLavsMeldingAntwoordBlokken) =>
          blokken.melder.contactpersoon?.emailadres.waarde
        }
      ></Formulier>
    );
  } else {
    return null;
  }
};
