import { FormulierMetadata } from "../models/api";
import { ColumnSortOption } from "../store";
import { MeldingListItem } from "./../models/api/meldingen/melding-list-item";

export const SET_MELDINGEN_FORMULIEREN = "SET_MELDINGEN_FORMULIEREN";

export const SET_MELDINGEN = "SET_MELDINGEN";
export const SET_MELDINGEN_LOADING = "SET_MELDINGEN_LOADING";
export const SET_MELDINGEN_FILTER = "SET_MELDINGEN_FILTER";
export const SET_API_ERROR = "SET_API_ERROR";

interface SetMeldingenFormulierenAction {
  type: typeof SET_MELDINGEN_FORMULIEREN;
  data: FormulierMetadata[];
}

interface SetMeldingenAction {
  type: typeof SET_MELDINGEN;
  meldingen: MeldingListItem[];
  totaalAantalMeldingen: number;
}

interface SetApiErrorAction {
  type: typeof SET_API_ERROR;
  apiError: string;
}

interface SetMeldingenLoadingAction {
  type: typeof SET_MELDINGEN_LOADING;
  isLoading: boolean;
  hasApiError: boolean;
}

interface SetMeldingenFilterAction {
  type: typeof SET_MELDINGEN_FILTER;
  sortOption: ColumnSortOption;
  pageNumber: number;
}

export type MeldingenActions =
  | SetMeldingenFormulierenAction
  | SetMeldingenAction
  | SetMeldingenLoadingAction
  | SetMeldingenFilterAction
  | SetApiErrorAction;

export const setMeldingenFormulieren = (data: FormulierMetadata[]): SetMeldingenFormulierenAction => {
  return {
    type: SET_MELDINGEN_FORMULIEREN,
    data
  };
};

export const setMeldingenLoading = (isLoading: boolean, hasApiError: boolean): SetMeldingenLoadingAction => {
  return {
    type: SET_MELDINGEN_LOADING,
    isLoading,
    hasApiError: hasApiError
  };
};

export const setMeldingen = (meldingen: MeldingListItem[], totaalAantalMeldingen: number): SetMeldingenAction => {
  return {
    type: SET_MELDINGEN,
    meldingen,
    totaalAantalMeldingen
  };
};

export const setMeldingenFilter = (sortOption: ColumnSortOption, pageNumber: number): SetMeldingenFilterAction => {
  return {
    type: SET_MELDINGEN_FILTER,
    sortOption,
    pageNumber
  };
};

export const setApiError = (apiError: string): SetApiErrorAction => {
  return {
    type: SET_API_ERROR,
    apiError: apiError
  };
};
