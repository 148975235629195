import {
  ImporterenKinderarbeidVragen,
  KinderenVragen,
  LocatiesVragen,
  MelderVragen,
  OpdrachtgeverOntheffingKinderarbeidVragen,
  ProductieVragen,
  VoorwaardenVragen,
  VraagBlokken,
  WerkmomentenVragen
} from "../blok";
import { HasImporterenKinderarbeidVragen } from "./has-importeren-kinderarbeid-vragen";
import { HasKinderenVragen } from "./has-kinderen-vragen";
import { HasLocatiesVragen } from "./has-locaties-vragen";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasOntheffingKinderarbeidOpdrachtgeverVragen } from "./has-ontheffing-kinderarbeid-opdrachtgever-vragen";
import { HasProductieVragen } from "./has-productie-vragen";
import { HasVoorwaardenVragen } from "./has-voorwaarden-vragen";
import { HasWerkmomentenVragen } from "./has-werkmomenten-vragen";

export class OntheffingKinderarbeidFormulierVraagBlokken
  extends VraagBlokken
  implements
    HasMelderVragen,
    HasOntheffingKinderarbeidOpdrachtgeverVragen,
    HasProductieVragen,
    HasImporterenKinderarbeidVragen,
    HasLocatiesVragen,
    HasKinderenVragen,
    HasWerkmomentenVragen,
    HasVoorwaardenVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public werkgever: OpdrachtgeverOntheffingKinderarbeidVragen,
    public productie: ProductieVragen,
    public importerenKinderarbeid: ImporterenKinderarbeidVragen,
    public locaties: LocatiesVragen,
    public kinderen: KinderenVragen,
    public werkmomenten: WerkmomentenVragen,
    public voorwaarden: VoorwaardenVragen
  ) {
    super();
  }

  static fromJson(json: any): OntheffingKinderarbeidFormulierVraagBlokken {
    return new OntheffingKinderarbeidFormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      OpdrachtgeverOntheffingKinderarbeidVragen.fromJson("werkgever", json.werkgever),
      ProductieVragen.fromJson("productie", json.productie),
      ImporterenKinderarbeidVragen.fromJson("importerenKinderarbeid", json.importerenKinderarbeid),
      LocatiesVragen.fromJson("locaties", json.locaties),
      KinderenVragen.fromJson("kinderen", json.kinderen),
      WerkmomentenVragen.fromJson("werkmomenten", json.werkmomenten),
      VoorwaardenVragen.fromJson("voorwaarden", json.voorwaarden)
    );
  }
}
