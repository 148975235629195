import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { VerzoekTotGezamenlijkeInterventieFormulierVraagBlokken } from "./verzoek-tot-gezamenlijke-interventie-formulier-vraag-blokken";

export class VerzoekTotGezamenlijkeInterventieFormulier extends FormulierBase {
  constructor(public blokken: VerzoekTotGezamenlijkeInterventieFormulierVraagBlokken, json: any) {
    super(Formuliertype.verzoekTotGezamenlijkeInterventie, blokken, json);
  }

  static fromJson(json: any): VerzoekTotGezamenlijkeInterventieFormulier {
    return new VerzoekTotGezamenlijkeInterventieFormulier(
      VerzoekTotGezamenlijkeInterventieFormulierVraagBlokken.fromJson(json.blokken),
      json
    );
  }
}
