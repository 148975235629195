import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { LocatieWerkzaamhedenAntwoorden } from "./locatie-werkzaamheden-antwoorden";

export class LocatiesAntwoorden extends AntwoordBlok {
  constructor(public locaties: AntwoordBlokCollection<LocatieWerkzaamhedenAntwoorden> | null) {
    super();
  }

  static initialize(): LocatiesAntwoorden {
    return new LocatiesAntwoorden(AntwoordBlokCollection.initialize([]));
  }

  static fromJson(json: any): LocatiesAntwoorden {
    return new LocatiesAntwoorden(AntwoordBlokCollection.fromJson(LocatieWerkzaamhedenAntwoorden.fromJson, json.locaties));
  }
}
