import { arrayHelpers, processtapHelpers } from "helpers";
import _ from "lodash";
import {
  ClearSubmitValidationResult,
  CLEAR_SUBMIT_VALIDATION_RESULT,
  COMMIT_REQUESTED_PAGE,
  HIDE_VALIDATION_ERRORS_DIALOG,
  INIT_PROCES,
  NavigationActions,
  ProcesActions,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SetHasValidationErrors,
  SetRequestedPageAction,
  SetSubmitValidationResults,
  SET_ALL_PAGES_COMPLETE,
  SET_HAS_VALIDATION_ERRORS,
  SET_NEXT_PAGE,
  SET_PREVIOUS_PAGE,
  SET_REQUESTED_PAGE,
  SET_SUBMITTING_COMPLETE,
  SET_SUBMIT_REQUESTED,
  SET_SUBMIT_VALIDATION_RESULTS,
  SharedActions,
  SHOW_VALIDATION_ERRORS_DIALOG
} from "../actions";
import { ValidationResponse } from "../models/api";
import { Proces, Processtap, ProcesstapState } from "./../models/application";

const initialState: Proces = {
  items: [],
  activeItem: null,
  requestedItem: null,
  isSubmitRequested: false,
  submitValidationResults: null,
  isBackwardsNavigationRequested: false,
  showValidationErrorsDialog: false
};

function setNextPage(state: Proces): Proces {
  const currentActiveIndex = state.items.findIndex((i) => i.state === ProcesstapState.active);
  const nextIndex = Math.min(currentActiveIndex + 1, state.items.length - 1);

  const nextItem = state.items[nextIndex];

  const newItems = arrayHelpers.replaceAt(state.items, currentActiveIndex, {
    ...state.items[currentActiveIndex],
    hasValidationErrors: false,
    firstElementWithErrorId: undefined
  });

  return {
    ...state,
    requestedItem: { item: nextItem, shouldValidate: true },
    items: newItems,
    isBackwardsNavigationRequested: false
  };
}

function setPreviousPage(state: Proces): Proces {
  const activeIndex = state.items.findIndex((i) => i.state === ProcesstapState.active);
  const previousIndex = Math.max(activeIndex - 1, 0);

  const previousItem = state.items[previousIndex];

  return {
    ...state,
    requestedItem: {
      item: previousItem,
      shouldValidate: true
    },
    isBackwardsNavigationRequested: true
  };
}

function setAllPagesComplete(state: Proces) {
  const lastItemIndex = state.items.length - 1;
  const newItems: Processtap[] = state.items.map((i: Processtap, index: number) => {
    const isLastItem = index === lastItemIndex;
    return {
      ...i,
      state: isLastItem ? ProcesstapState.active : ProcesstapState.visited,
      isComplete: !isLastItem
    };
  });

  return {
    ...state,
    items: newItems,
    activeItem: newItems[newItems.length - 1]
  };
}

function setRequestedPage(state: Proces, action: SetRequestedPageAction) {
  const activeIndex = state.items.findIndex((i) => i.state === ProcesstapState.active);
  const activeItem = state.items[activeIndex];

  if (activeItem === action.item || !action.item) {
    return {
      ...state,
      requestedItem: null
    };
  }

  const isNavigatingBackwards = activeIndex > state.items.indexOf(action.item);

  return {
    ...state,
    requestedItem: action.item ? { item: action.item, shouldValidate: true } : null,
    isBackwardsNavigationRequested: isNavigatingBackwards
  };
}

function commitRequestedPage(state: Proces): Proces {
  let newItems = state.items;

  const requestedItem = state.requestedItem;

  if (requestedItem) {
    newItems = state.items.map((i): Processtap => {
      if (i.state === ProcesstapState.active) {
        return {
          ...i,
          state: ProcesstapState.visited,
          isComplete: requestedItem.shouldValidate
        };
      } else if (i === requestedItem.item) {
        return {
          ...i,
          state: ProcesstapState.active
        };
      } else {
        return i;
      }
    });
  }
  return {
    ...state,
    requestedItem: null,
    isBackwardsNavigationRequested: false,
    items: newItems,
    activeItem: newItems.find((i) => i.state === ProcesstapState.active) ?? null,
    submitValidationResults: null
  };
}

function setSubmitValidationResults(state: Proces, action: SetSubmitValidationResults) {
  const processtappenWithErrors = _.uniq(
    action.data.validationFailures.map((f) => processtapHelpers.getProcesstapTypeFromFieldName(f.property))
  );
  const newProcesstapItems = [...state.items];
  processtappenWithErrors.forEach((processtapType) => {
    const stapToReplaceIndex = newProcesstapItems.findIndex((p) => p.type === processtapType);
    if (stapToReplaceIndex >= 0) {
      newProcesstapItems[stapToReplaceIndex] = {
        ...newProcesstapItems[stapToReplaceIndex],
        hasValidationErrors: true
      };
    }
  });

  return {
    ...state,
    isSubmitRequested: false,
    submitValidationResults: action.data,
    items: newProcesstapItems,
    activeItem: newProcesstapItems.find((p) => p.state === ProcesstapState.active) ?? null
  };
}

function clearSubmitValidationResult(state: Proces, action: ClearSubmitValidationResult) {
  if (state.submitValidationResults?.contains(action.data)) {
    return {
      ...state,
      submitValidationResults: new ValidationResponse(
        state.submitValidationResults.validationFailures.filter(
          (f) => f.property?.toLowerCase() !== action.data.toLowerCase()
        )
      )
    };
  } else {
    return state;
  }
}

function setHasValidationErrors(state: Proces, action: SetHasValidationErrors): Proces {
  const activeItemIndex = state.items.findIndex((i) => i.state === ProcesstapState.active);
  if (activeItemIndex < 0) {
    return state;
  }

  const activeItem = state.items[activeItemIndex];
  const activeItemIsLast = activeItemIndex === state.items.length - 1;

  const newActiveItem = {
    ...activeItem,
    hasValidationErrors: !activeItemIsLast && action.hasValidationErrors,
    firstElementWithErrorId: action.firstElementWithErrorId
  };

  const newItems = arrayHelpers.replace(state.items, activeItem, newActiveItem);
  return {
    ...state,
    items: newItems,
    activeItem: newActiveItem
  };
}

const ProcesReducer = (state: Proces = initialState, action: ProcesActions | SharedActions | NavigationActions): Proces => {
  switch (action.type) {
    case INIT_PROCES: {
      return {
        ...state,
        items: action.data,
        activeItem: action.data.find((p) => p.state === ProcesstapState.active) ?? null
      };
    }
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM: {
      return initialState;
    }
    case SET_NEXT_PAGE: {
      return setNextPage(state);
    }
    case SET_PREVIOUS_PAGE: {
      return setPreviousPage(state);
    }
    case SET_REQUESTED_PAGE: {
      return setRequestedPage(state, action);
    }
    case COMMIT_REQUESTED_PAGE: {
      return commitRequestedPage(state);
    }
    case SET_SUBMIT_REQUESTED: {
      return {
        ...state,
        isSubmitRequested: action.isRequested,
        isBackwardsNavigationRequested: false
      };
    }
    case SET_SUBMITTING_COMPLETE: {
      return {
        ...state,
        isSubmitRequested: false
      };
    }
    case SET_SUBMIT_VALIDATION_RESULTS: {
      return setSubmitValidationResults(state, action);
    }
    case CLEAR_SUBMIT_VALIDATION_RESULT: {
      return clearSubmitValidationResult(state, action);
    }
    case SET_ALL_PAGES_COMPLETE: {
      return setAllPagesComplete(state);
    }
    case SET_HAS_VALIDATION_ERRORS: {
      return setHasValidationErrors(state, action);
    }
    case SHOW_VALIDATION_ERRORS_DIALOG: {
      return {
        ...state,
        showValidationErrorsDialog: true
      };
    }
    case HIDE_VALIDATION_ERRORS_DIALOG: {
      return {
        ...state,
        showValidationErrorsDialog: false
      };
    }
    default: {
      return state;
    }
  }
};

export default ProcesReducer;
