import {
  AannemersAntwoorden,
  AntwoordBlokken,
  BouwprocesWerkzaamhedenAntwoorden,
  BouwwerkAntwoorden,
  MelderAntwoorden,
  OntwerpendePartijAntwoorden,
  OpdrachtgeverBouwprocesAntwoorden,
  UitvoerendePartijAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasBouwwerkAntwoorden } from "./has-bouwwerk-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";
import { HasOpdrachtgeverBouwprocesAntwoorden } from "./has-opdrachtgever-bouwproces-antwoorden";

export class BouwprocesMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasMelderAntwoorden, HasBouwwerkAntwoorden, HasOpdrachtgeverBouwprocesAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public bouwwerk: BouwwerkAntwoorden,
    public opdrachtgever: OpdrachtgeverBouwprocesAntwoorden,
    public ontwerpendePartij: OntwerpendePartijAntwoorden,
    public uitvoerendePartij: UitvoerendePartijAntwoorden,
    public aannemers: AannemersAntwoorden,
    public werkzaamheden: BouwprocesWerkzaamhedenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): BouwprocesMeldingAntwoordBlokken {
    return new BouwprocesMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      BouwwerkAntwoorden.initialize(),
      OpdrachtgeverBouwprocesAntwoorden.initialize(),
      OntwerpendePartijAntwoorden.initialize(),
      UitvoerendePartijAntwoorden.initialize(),
      AannemersAntwoorden.initialize(),
      BouwprocesWerkzaamhedenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): BouwprocesMeldingAntwoordBlokken {
    return new BouwprocesMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      BouwwerkAntwoorden.fromJson(json.bouwwerk),
      OpdrachtgeverBouwprocesAntwoorden.fromJson(json.opdrachtgever),
      OntwerpendePartijAntwoorden.fromJson(json.ontwerpendePartij),
      UitvoerendePartijAntwoorden.fromJson(json.uitvoerendePartij),
      AannemersAntwoorden.fromJson(json.aannemers),
      BouwprocesWerkzaamhedenAntwoorden.fromJson(json.werkzaamheden)
    );
  }
}
