import { jsonHelpers } from "../../../helpers/json-helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { LocatieVragen } from "./locatie-vragen";

export class LocatieWerkzaamhedenBijBedrijfVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public werkzaamhedenLocatieBedrijf: Meerkeuzevraag,
    public kvkNummerGebruiken: Meerkeuzevraag,
    public bedrijfsgegevens: BedrijfsgegevensVragen,
    public locatie: LocatieVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): LocatieWerkzaamhedenBijBedrijfVragen {
    return new LocatieWerkzaamhedenBijBedrijfVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toMeerkeuzeVraag(`${key}.werkzaamhedenLocatieBedrijf`, json.werkzaamhedenLocatieBedrijf),
      jsonHelpers.toMeerkeuzeVraag(`${key}.kvkNummerGebruiken`, json.kvkNummerGebruiken),
      BedrijfsgegevensVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens),
      LocatieVragen.fromJson(`${key}.locatie`, json.locatie)
    );
  }
}
