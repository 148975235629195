import { CaptchaActions, CLEAR_CAPTCHA_ERROR, SET_CAPTCHA_ERROR } from "../actions";
import { CaptchaState } from "../store";

const initialState: CaptchaState = {
  hasError: false
};

const captchaReducer = (state: CaptchaState = initialState, action: CaptchaActions): CaptchaState => {
  switch (action.type) {
    case SET_CAPTCHA_ERROR: {
      return { ...state, hasError: true };
    }
    case CLEAR_CAPTCHA_ERROR: {
      return { ...state, hasError: false };
    }
    default:
      return state;
  }
};

export default captchaReducer;
