import { objectHelpers } from "helpers";
import { Formulier } from "../models/api";
import { BlokDefinitie } from "../models/application";
import { FormulierDefinitie } from "./index";

const getFormulierDefinitie = <TFormulier extends Formulier>(formulier: TFormulier): BlokDefinitie[] | null => {
  const definitieMethodName = `get${objectHelpers.toPascalCase(formulier.type)}FormulierDefinitie`;
  return FormulierDefinitie[definitieMethodName as keyof typeof FormulierDefinitie](formulier as any);
};

export const formulierDefinitieHelpers = {
  getFormulierDefinitie
};
