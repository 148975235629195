import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";

export class OpdrachtgeverOntheffingKinderarbeidAntwoorden extends AntwoordBlok {
  constructor(
    public gegevensGelijk: Antwoord<string>,
    public doorAndereWerkgeverGemachtigd: Antwoord<string>,
    public opdrachtgever: OpdrachtgeverAntwoorden | null,
    json?: any
  ) {
    super(json);
  }

  static initialize(): OpdrachtgeverOntheffingKinderarbeidAntwoorden {
    return new OpdrachtgeverOntheffingKinderarbeidAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      OpdrachtgeverAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OpdrachtgeverOntheffingKinderarbeidAntwoorden {
    return new OpdrachtgeverOntheffingKinderarbeidAntwoorden(
      Antwoord.fromJson<string>(json.gegevensGelijk),
      Antwoord.fromJson<string>(json.doorAndereWerkgeverGemachtigd),
      OpdrachtgeverAntwoorden.fromJson(json.opdrachtgever),
      json
    );
  }
}
