import { ADD_BREADCRUMB, BreadcrumbActions, CLEAR_BREADCRUMB, CLEAR_BREADCRUMBS } from "../actions";
import { BreadcrumbsState } from "../store";

const initialState: BreadcrumbsState = {
  items: []
};

const breadcrumbsReducer = (state: BreadcrumbsState = initialState, action: BreadcrumbActions): BreadcrumbsState => {
  switch (action.type) {
    case ADD_BREADCRUMB: {
      const newBreadcrumbs = [...state.items, action.data];
      return { ...state, items: newBreadcrumbs };
    }
    case CLEAR_BREADCRUMB: {
      if (state.items.length > 0) {
        return {
          ...state,
          items: state.items.filter((b) => b.id !== action.id)
        };
      }
      return state;
    }
    case CLEAR_BREADCRUMBS: {
      return { ...state, items: [] };
    }
    default:
      return state;
  }
};

export default breadcrumbsReducer;
