import { VisibleWrapper } from "components/visible-wrapper";
import i18n from "i18next";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ConfirmDialogOwnProps } from "./confirm-dialog.component.interfaces";

export const ConfirmDialog = (props: ConfirmDialogOwnProps) => {
  const { t } = useTranslation();
  const [enforceFocus, setEnforceFocus] = useState<boolean>(true);

  const language = props.language || i18n.language;

  const onEntering = () => {
    setEnforceFocus(true);
  };

  const onExiting = () => {
    setEnforceFocus(false);
  };

  return (
    <Modal
      show={props.showDialog}
      centered
      enforceFocus={enforceFocus}
      backdrop="static"
      keyboard={false}
      onEntering={onEntering}
      onExited={props.onClosed}
      onExiting={onExiting}
    >
      <Modal.Header>
        <Modal.Title className="dialog-title">{t(props.titelTranslationKey, { lng: language })}</Modal.Title>
      </Modal.Header>
      {props.tekstTranslationKey && (
        <Modal.Body>
          <>{t(props.tekstTranslationKey, { lng: language })}</>
        </Modal.Body>
      )}
      {props.tekstTranslation && (
        <Modal.Body>
          <>{props.tekstTranslation}</>
        </Modal.Body>
      )}
      <Modal.Footer>
        <VisibleWrapper isVisible={props.showCancelButton ?? true}>
          <Button variant="secondary" onClick={props.onCancel} id={`${props.name}.cancel`}>
            {t(props.cancelTranslationKey, { lng: language })}
          </Button>
        </VisibleWrapper>
        <Button variant="primary" onClick={props.onConfirm} id={`${props.name}.confirm`}>
          {t(props.confirmTranslationKey, { lng: language })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
