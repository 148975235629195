import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class HasValue extends VraagExpression {
  get operator(): string {
    return HasValue.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly value: any) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;
    return antwoord?.toString().split(",").indexOf(this.value) > -1;
  };

  protected clone = (instance: HasValue): HasValue => new HasValue(instance.vraagProperty, instance.value);

  static fromJson = (json: any): HasValue => new HasValue(json.vraag, json.value);

  static operator = (): string => "hasValue";

  static matches = (json: any): boolean => json?.operator === HasValue.operator();
}
