import React from "react";

interface AccordionContextData {
  activeKey: string;
  setActiveKey: (key: string) => void;
  keyOfItemWithFocusedToggleButton: string;
  setKeyOfItemWithFocusedToggleButton: (key: string) => void;
}

export const AccordionContext = React.createContext<AccordionContextData>({} as AccordionContextData);
