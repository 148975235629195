import { Bestand } from "../blok/bestand";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class ExceedsMaximumSize extends VraagExpression {
  get operator(): string {
    return ExceedsMaximumSize.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly maxSize: number) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;

    return antwoord && (antwoord as Bestand[]).reduce((total, current) => total + current.grootte, 0) > this.maxSize;
  };

  protected clone = (instance: ExceedsMaximumSize): ExceedsMaximumSize =>
    new ExceedsMaximumSize(instance.vraagProperty, instance.maxSize);

  static fromJson = (json: any): ExceedsMaximumSize => new ExceedsMaximumSize(json.vraag, json.maxSize);

  static operator = (): string => "exceedsMaximumSize";

  static matches = (json: any): boolean => json?.operator === ExceedsMaximumSize.operator();
}
