import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class MotivatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public titel: Dictionary<string>,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public informatieOndergrondTitel: Tekst,
    public specificatieOndergrond: Vraag,
    public vetteOndergrond: Meerkeuzevraag,
    public behandelingOndergrondMogelijk: Meerkeuzevraag,
    public toelichtingBehandelingOndergrond: Vraag,
    public informatieApplicatiefaseTitel: Tekst,
    public redenVosProductenBenodigdApplicatiefase: Vraag,
    public informatieGebruiksfaseTitel: Tekst,
    public redenVosProductenBenodigdGebruiksfase: Vraag,
    public technischeOnderbouwingLeveranciersTitel: Tekst,
    public bijlageTechnischeOnderbouwingToelichting: Tekst,
    public bestanden: Bestandenvraag,
    public maatregelenBeschermingBlootstellingOplosmiddelen: Vraag,
    public toelichtingAanvraag: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): MotivatieVragen {
    return new MotivatieVragen(
      key,
      json.volgnummer,
      json.titel,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTitel(`${key}.informatieOndergrondTitel`, json.informatieOndergrondTitel),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.specificatieOndergrond`, json.specificatieOndergrond),
      jsonHelpers.toMeerkeuzeVraag(`${key}.vetteOndergrond`, json.vetteOndergrond),
      jsonHelpers.toMeerkeuzeVraag(`${key}.behandelingOndergrondMogelijk`, json.behandelingOndergrondMogelijk),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.toelichtingBehandelingOndergrond`,
        json.toelichtingBehandelingOndergrond
      ),
      jsonHelpers.toTitel(`${key}.informatieApplicatiefaseTitel`, json.informatieApplicatiefaseTitel),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.redenVosProductenBenodigdApplicatiefase`,
        json.redenVosProductenBenodigdApplicatiefase
      ),
      jsonHelpers.toTitel(`${key}.informatieGebruiksfaseTitel`, json.informatieGebruiksfaseTitel),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.redenVosProductenBenodigdGebruiksfase`,
        json.redenVosProductenBenodigdGebruiksfase
      ),
      jsonHelpers.toTitel(`${key}.technischeOnderbouwingLeveranciersTitel`, json.technischeOnderbouwingLeveranciersTitel),
      jsonHelpers.toParagraaf(
        `${key}.bijlageTechnischeOnderbouwingToelichting`,
        json.bijlageTechnischeOnderbouwingToelichting
      ),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.maatregelenBeschermingBlootstellingOplosmiddelen`,
        json.maatregelenBeschermingBlootstellingOplosmiddelen
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.toelichtingAanvraag`, json.toelichtingAanvraag)
    );
  }
}
