import { User } from "models/api/security/user";
import { GeautoriseerdAls, IdentityProviders } from "../constants";
import { nameHelpers } from "./name-helpers";

const getIsVestigingsnummerFixed = (user: User | null): boolean => user?.isVestigingsnummerFixed === "True";
const getMagKvkNummerWijzigen = (user: User | null): boolean => user?.magKvkNummerWijzigen === "True";

const getIdentityProvider = (user: User | null): string => user?.idp ?? IdentityProviders.local;

const getVolledigeNaam = (user: User | null): string | undefined => {
  let volledigeNaam = nameHelpers.getVolledigeNaamMetVoorletters({
    voorletters: user?.voorletters,
    tussenvoegsels: user?.tussenvoegsels,
    achternaam: user?.acthternaam
  });

  if (!volledigeNaam && getIdentityProvider(user) === IdentityProviders.eHerkenning) {
    volledigeNaam = "eHerkenning";
  } else if (!volledigeNaam && getIsInternalUser(user)) {
    volledigeNaam = user?.username;
  }

  return volledigeNaam;
};

const getIsBeheerderUser = (user: User | null): boolean => {
  const beheerderRole = "Webportaal.Beheerder";
  const role = user?.role;
  return isOrHasRole(role, beheerderRole);
};

const getIsServicedeskUser = (user: User | null): boolean => {
  const servicedeskRole = "Webportaal.Servicedesk";
  const role = user?.role;
  return isOrHasRole(role, servicedeskRole);
};

const getIsInternalUser = (user: User | null): boolean => {
  const internalRole = "Webportaal.InterneMedewerker";
  const role = user?.role;
  return isOrHasRole(role, internalRole);
};

const isOrHasRole = (actualRoleOrRoles: string | Array<string>, desiredRole: string) =>
  actualRoleOrRoles === desiredRole || (Array.isArray(actualRoleOrRoles) && actualRoleOrRoles.indexOf(desiredRole) >= 0);

const getIsBedrijf = (user: User | null): boolean => {
  const geautoriseerdAls = user?.geautoriseerdAls;
  const identityProvider = getIdentityProvider(user);
  return geautoriseerdAls === GeautoriseerdAls.bedrijf || identityProvider === IdentityProviders.eHerkenning;
};

const getIsParticulier = (user: User | null): boolean => {
  return user?.geautoriseerdAls === GeautoriseerdAls.particulier;
};

export const userHelpers = {
  getIsVestigingsnummerFixed,
  getIdentityProvider,
  getVolledigeNaam,
  getIsBeheerderUser,
  getIsServicedeskUser,
  getIsInternalUser,
  getIsBedrijf,
  getIsParticulier,
  getMagKvkNummerWijzigen
};
