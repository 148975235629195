import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden extends AntwoordBlok {
  constructor(
    public risicoklasse: Antwoord<string>,
    public hoeveelheid: Antwoord<string>,
    public eenheid: Antwoord<string>,
    public soortMateriaal: Antwoord<string>
  ) {
    super();
  }

  static initialize(): TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden {
    return new TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden(
      new Antwoord<string>("1"),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden {
    return new TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden(
      Antwoord.fromJson<string>(json.risicoklasse),
      Antwoord.fromJson<string>(json.hoeveelheid),
      Antwoord.fromJson<string>(json.eenheid),
      Antwoord.fromJson<string>(json.soortMateriaal)
    );
  }
}
