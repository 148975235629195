import { AntwoordBlokken, AutorisatiesZoekenAntwoorden } from "../blok";
import { HasAutorisatiesZoekenAntwoorden } from "./has-autorisaties-zoeken-antwoorden";

export class AutorisatiesZoekenMeldingAntwoordBlokken extends AntwoordBlokken implements HasAutorisatiesZoekenAntwoorden {
  constructor(public filter: AutorisatiesZoekenAntwoorden) {
    super();
  }

  static initialize(): AutorisatiesZoekenMeldingAntwoordBlokken {
    return new AutorisatiesZoekenMeldingAntwoordBlokken(AutorisatiesZoekenAntwoorden.initialize());
  }
}
