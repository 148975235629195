import {
  AanleveringLavsVragen,
  AsbestWerkzaamhedenLavsVragen,
  CertificerendeInstellingVragen,
  InventarisatieLavsVragen,
  LaboratoriumVragen,
  LocatieLavsVragen,
  MelderLavsVragen,
  OpdrachtgeverLavsVragen,
  OpdrachtnemerLavsVragen,
  PeriodeDatumTijdVragen,
  VraagBlokken
} from "../blok";

export class AsbestverwijderingLavsFormulierVraagBlokken extends VraagBlokken {
  [key: string]: any;

  constructor(
    public aanlevering: AanleveringLavsVragen,
    public melder: MelderLavsVragen,
    public certificerendeInstelling: CertificerendeInstellingVragen,
    public opdrachtgever: OpdrachtgeverLavsVragen,
    public opdrachtnemer: OpdrachtnemerLavsVragen,
    public inventarisatie: InventarisatieLavsVragen,
    public locatie: LocatieLavsVragen,
    public periode: PeriodeDatumTijdVragen,
    public werkzaamheden: AsbestWerkzaamhedenLavsVragen,
    public laboratorium: LaboratoriumVragen
  ) {
    super();
  }

  static fromJson(json: any): AsbestverwijderingLavsFormulierVraagBlokken {
    return new AsbestverwijderingLavsFormulierVraagBlokken(
      AanleveringLavsVragen.fromJson("aanlevering", json.aanlevering),
      MelderLavsVragen.fromJson("melder", json.melder),
      CertificerendeInstellingVragen.fromJson("certificerendeInstelling", json.certificerendeInstelling),
      OpdrachtgeverLavsVragen.fromJson("opdrachtgever", json.opdrachtgever),
      OpdrachtnemerLavsVragen.fromJson("opdrachtnemer", json.opdrachtnemer),
      InventarisatieLavsVragen.fromJson("inventarisatie", json.inventarisatie),
      LocatieLavsVragen.fromJson("locatie", json.locatie),
      PeriodeDatumTijdVragen.fromJson("periode", json.periode),
      AsbestWerkzaamhedenLavsVragen.fromJson("werkzaamheden", json.werkzaamheden),
      LaboratoriumVragen.fromJson("laboratorium", json.laboratorium)
    );
  }
}
