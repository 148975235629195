import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class ArbeidsMeldingSituatieAntwoorden extends AntwoordBlok {
  constructor(
    public situatieWaaroverMeldingGaat: Antwoord<string>,
    public toelichtingWerkomstandigheden: Antwoord<string>,
    public meldingBesprokenMet: Antwoord<string[]>,
    public resultaatGesprek: Antwoord<string>,
    public redenSituatieNietBesproken: Antwoord<string>,
    public meldingOndersteundDoorAnderen: Antwoord<string>,
    public doorWie: Antwoord<string>
  ) {
    super();
  }

  static initialize(): ArbeidsMeldingSituatieAntwoorden {
    return new ArbeidsMeldingSituatieAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): ArbeidsMeldingSituatieAntwoorden {
    return json
      ? new ArbeidsMeldingSituatieAntwoorden(
          Antwoord.fromJson<string>(json.situatieWaaroverMeldingGaat),
          Antwoord.fromJson<string>(json.toelichtingWerkomstandigheden),
          Antwoord.fromJson<string[]>(json.meldingBesprokenMet),
          Antwoord.fromJson<string>(json.resultaatGesprek),
          Antwoord.fromJson<string>(json.redenSituatieNietBesproken),
          Antwoord.fromJson<string>(json.meldingOndersteundDoorAnderen),
          Antwoord.fromJson<string>(json.doorWie)
        )
      : this.initialize();
  }
}
