import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldBijlagenvraag } from "../../../samenvatting";

export class Bestanden {
  static getType = (): VraagType => VraagType.bestanden;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldBijlagenvraag vraag={vraag} />;
}
