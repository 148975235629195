import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { BijlageAntwoorden } from "./bijlage-antwoorden";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { ZaakPincodeAntwoorden } from "./zaak-pincode-antwoorden";

export class UploadRieAntwoorden extends AntwoordBlok {
  constructor(
    public zaakPincode: ZaakPincodeAntwoorden,
    public contactpersoon: ContactpersoonAntwoorden | null,
    public aantalWerknemersWerkzaam: Antwoord<number | null>,
    public lidVanBranchevereniging: Antwoord<string>,
    public welkeBranchevereniging: Antwoord<string>,
    public bestanden: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): UploadRieAntwoorden {
    return new UploadRieAntwoorden(
      ZaakPincodeAntwoorden.initialize(),
      ContactpersoonAntwoorden.initialize(user),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): UploadRieAntwoorden {
    return new UploadRieAntwoorden(
      ZaakPincodeAntwoorden.fromJson(json.zaakPincode),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      Antwoord.fromJson<number | null>(json.aantalWerknemersWerkzaam),
      Antwoord.fromJson(json.lidVanBranchevereniging),
      Antwoord.fromJson(json.welkeBranchevereniging),
      BijlageAntwoorden.fromJson(json.bestanden)
    );
  }
}
