import { Formuliertype } from "../../application";
import { DuikarbeidFormulierVraagBlokken } from "./duikarbeid-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class DuikarbeidFormulier extends FormulierBase {
  constructor(public blokken: DuikarbeidFormulierVraagBlokken, json: any) {
    super(Formuliertype.duikarbeid, blokken, json);
  }

  static fromJson(json: any): DuikarbeidFormulier {
    return new DuikarbeidFormulier(DuikarbeidFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
