import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { objectHelpers } from "../../../helpers";
import { Antwoord, AntwoordBlokken } from "../../../models/api";
import { Bestand } from "../../../models/api/blok/bestand";
import { State } from "../../../store";
import { SamenvattingBijlagenProps } from "../samenvatting-bijlagen/samenvatting-bijlagen.component.interfaces";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { WithSamenvattingBestandenvraagProps } from "./with-samenvatting-bestandenvraag.interfaces";

export const withSamenvattingBestandenvraag = <P extends object>(
  Component: React.ComponentType<P & SamenvattingBijlagenProps>
) => {
  return (props: P & WithSamenvattingBestandenvraagProps) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(Component as any);

    const { vraag, ...rest } = props;

    const { antwoordBlokken } = useSelector<State, { antwoordBlokken: AntwoordBlokken }>(
      (state: State) => ({
        antwoordBlokken: state.antwoorden.blokken
      }),
      shallowEqual
    );

    const bestanden = objectHelpers.getValue<Antwoord<Bestand[]>>(antwoordBlokken, vraag.key);

    return <SamenvattingVraagComponent item={vraag} bestanden={bestanden} {...(rest as P)} />;
  };
};
