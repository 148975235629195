import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class FraudeSocialeZekerheidAntwoorden extends AntwoordBlok {
  constructor(public aanvullendeInformatie: Antwoord<string>) {
    super();
  }

  static initialize(): FraudeSocialeZekerheidAntwoorden {
    return new FraudeSocialeZekerheidAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): FraudeSocialeZekerheidAntwoorden {
    return json
      ? new FraudeSocialeZekerheidAntwoorden(Antwoord.fromJson<string>(json.aanvullendeInformatie))
      : this.initialize();
  }
}
