import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GeneriekBlok } from ".";
import { withConditioneelReadonlyVraagBlok } from "../../../components/formulier/with-conditioneel-readonly";
import { formulierHelpers } from "../../../helpers";
import { AdresVragen, BedrijfsgegevensVragen, HasVolgnummer, VraagBlok } from "../../../models/api";
import { ProcesstapType, VraagBlokType } from "../../../models/application";
import { NederlandsAdres } from "../nederlands-adres";
import { Vestiging } from "../vestiging";
import { getVestigingProperties } from "../vestiging/vestiging.component.helpers";
import { GeneriekVraagBlokProps } from "./generiek-vraag-blok.component.interfaces";
import { SamengesteldeVraagComponenten } from "./supported-samengestelde-vraag-typen";
import { TekstComponenten } from "./supported-tekst-typen";
import { VraagBlokCollectionComponenten } from "./supported-vraag-blok-collection-typen";
import { VraagComponenten } from "./supported-vraag-typen";

const VraagBlokItemComponent = (props: { item: HasVolgnummer; processtap: ProcesstapType; readOnly?: boolean }) => {
  const component = formulierHelpers.getGeneriekComponent(
    VraagComponenten,
    TekstComponenten,
    VraagBlokCollectionComponenten,
    SamengesteldeVraagComponenten,
    props.item,
    (type: string, key: string) => (
      <div>
        niet ondersteund type {type} {key}
      </div>
    ),
    props.readOnly
  );

  if (component) {
    return component;
  } else if (formulierHelpers.isVraagBlok(props.item)) {
    const vraagBlok = props.item as VraagBlok;

    if (vraagBlok.type === VraagBlokType.vestiging) {
      const vestigingProps = getVestigingProperties(vraagBlok as BedrijfsgegevensVragen);
      return <Vestiging {...vestigingProps} vragen={vraagBlok as BedrijfsgegevensVragen} processtap={props.processtap} />;
    } else if (vraagBlok.type === VraagBlokType.nederlandsAdres) {
      return <NederlandsAdres vragen={vraagBlok as AdresVragen} readOnly={props.readOnly} />;
    } else {
      return <GeneriekBlok.VraagBlok vragen={vraagBlok} processtap={props.processtap} readOnly={props.readOnly} />;
    }
  } else {
    return <div>niet ondersteund item</div>;
  }
};

const GeneriekVraagBlokBase = (props: GeneriekVraagBlokProps, isRoot: boolean) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const { vragen, processtap, readOnly, wordtVertaald } = props;

  const items = formulierHelpers.getVolgnummerItems(vragen);

  return (
    <section>
      {vragen.titel && isRoot && <h2>{vragen.titel[lang]}</h2>}
      {items.map((i) => {
        return <VraagBlokItemComponent item={i} key={i.key} processtap={processtap} readOnly={readOnly || wordtVertaald} />;
      })}
    </section>
  );
};
const GeneriekVraagBlokChildComponent = (props: GeneriekVraagBlokProps) => GeneriekVraagBlokBase(props, false);
const GeneriekVraagBlokRootComponent = (props: GeneriekVraagBlokProps) => GeneriekVraagBlokBase(props, true);

const container = {
  VraagBlok: withConditioneelReadonlyVraagBlok(connect()(GeneriekVraagBlokChildComponent)),
  VraagBlokRoot: withConditioneelReadonlyVraagBlok(connect()(GeneriekVraagBlokRootComponent))
};

export { container as GeneriekBlok };
