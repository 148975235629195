import { AutorisatiesListItem } from "./autorisaties-list-item";

export class GetAutorisatiesResponse {
  constructor(public autorisaties: AutorisatiesListItem[], public totalItemCount: number) {}

  static fromJson(json: any): GetAutorisatiesResponse {
    return new GetAutorisatiesResponse(
      json.autorisaties.map((m: AutorisatiesListItem) => AutorisatiesListItem.fromJson(m)),
      json.totalItemCount
    );
  }
}
