import { connect } from "react-redux";
import { VerzoekTotGezamenlijkeInterventieFormulier } from "../../../models/api/formulier/verzoek-tot-gezamenlijke-interventie-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
    getVerzoekTotGezamenlijkeInterventieformulierMelding,
    initVerzoekTotGezamenlijkeInterventieFormulier,
    initVerzoekTotGezamenlijkeInterventieFormulierFromCopy
} from "../../../thunks";
import { VerzoekTotGezamenlijkeInterventieFormulierComponent } from "./verzoek-tot-gezamenlijke-interventie-formulier.component";
import {
    VerzoekTotGezamenlijkeInterventieFormulierComponentDispatchProps,
    VerzoekTotGezamenlijkeInterventieFormulierComponentStateProps
} from "./verzoek-tot-gezamenlijke-interventie-formulier.component.interfaces";

const mapStateToProps = (state: State): VerzoekTotGezamenlijkeInterventieFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.verzoekTotGezamenlijkeInterventie
      ? (state.vragen.formulier as VerzoekTotGezamenlijkeInterventieFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): VerzoekTotGezamenlijkeInterventieFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initVerzoekTotGezamenlijkeInterventieFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getVerzoekTotGezamenlijkeInterventieformulierMelding(meldingId));
    } else {
      dispatch(initVerzoekTotGezamenlijkeInterventieFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VerzoekTotGezamenlijkeInterventieFormulierComponent);
