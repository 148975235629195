import React from "react";
import { dateHelpers } from "../../../helpers";
import { SamenvattingFieldOwnProps } from "../samenvatting-field/samenvatting-field.component.interfaces";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { withSamenvattingVraag } from "./../with-samenvatting-vraag";
import { WithSamenvattingDatumTijdvraagProps } from "./with-samenvatting-datum-tijdvraag.interfaces";

export const withSamenvattingDatumTijdvraag = <P extends object>(
  Component: React.ComponentType<P & SamenvattingFieldOwnProps>
) => {
  return (props: P & WithSamenvattingDatumTijdvraagProps) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(withSamenvattingVraag(Component));

    return (
      <SamenvattingVraagComponent
        {...props}
        item={props.vraag}
        getAntwoord={(antwoordWaarde: string) => (antwoordWaarde ? dateHelpers.formatDateTime(antwoordWaarde) : "")}
      />
    );
  };
};
