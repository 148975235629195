import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { VraagBlok, VraagBlokCollection } from "../../../../models/api";
import { VraagBlokCollectionType } from "../../../../models/application";
import { SamenvattingVraagBlokCollection } from "../../../samenvatting";
import { GeneriekSamenvattingBlok } from "../generiek-samenvatting-blok.component";

export class KinderenGegevens {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.kindGegevens;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => {
    const { i18n } = useTranslation();

    return (
      <SamenvattingVraagBlokCollection
        vraag={vraagBlokCollection}
        component={(props: any) => {
          const { bsnIsGeldig, ...vragen } = props.vragen;
          return (
            <Fragment>
              <h3>{`${props.vragen.titel[i18n.language]} ${props.index + 1}`}</h3>
              <GeneriekSamenvattingBlok vragen={vragen} />
            </Fragment>
          );
        }}
      />
    );
  };
}
