import { ReactElement } from "react";
import { DebouncedFormControl } from "../debounce-input";
import { Field } from "../field";

export const InputNumberBase = (props: any): ReactElement => {
  const { name, touched, error, serverError, onBlur, ...propsRest } = props;

  const { inputOnly, label, ...propsControl } = propsRest;

  const onKeyDown = (e: any) => {
    // keycodes 48-57 is [0-9]
    // keycodes 96-105 is [0-9] on numpad
    const keyCodeDigitZero = 48;
    const keyCodeDigitNine = 57;
    const keyCodeNumpadZero = 96;
    const keyCodeNumpadNine = 105;
    if (
      e.target?.value?.length >= props.maxLength &&
      ((e.keyCode >= keyCodeDigitZero && e.keyCode <= keyCodeDigitNine) ||
        (e.keyCode >= keyCodeNumpadZero && e.keyCode <= keyCodeNumpadNine))
    ) {
      e.preventDefault();
    }
  };

  const isInvalid = (touched && error) || serverError;

  return (
    <Field name={name} {...propsRest}>
      <DebouncedFormControl
        type="number"
        name={name}
        id={name}
        isInvalid={isInvalid}
        onBlur={onBlur}
        {...propsControl}
        {...(props.inputOnly ? { "aria-label": props.label } : {})}
        onKeyDown={onKeyDown}
      />
    </Field>
  );
};
