import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";
import { Bestandenvraag } from "./../bestandenvraag";
import { Meerkeuzevraag } from "./../meerkeuzevraag";
import { Vraag } from "./../vraag";
import { LandVragen } from "./land-vragen";

export class ProductgegevensVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public productgegevensToelichting: Tekst,
    public soortProductTitel: Tekst,
    public soortProduct: Meerkeuzevraag,
    public soortProductOverig: Vraag,
    public omschrijvingProduct: Vraag,
    public merkOfNaamProduct: Vraag,
    public typenummer: Vraag,
    public landVanHerkomst: LandVragen,
    public omschrijvingKlacht: Vraag,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public bestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ProductgegevensVragen {
    return new ProductgegevensVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.productgegevensToelichting`, json.productgegevensToelichting),
      jsonHelpers.toTitel(`${key}.soortProductTitel`, json.soortProductTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.soortProduct`, json.soortProduct),
      jsonHelpers.toTekstVraag(`${key}.soortProductOverig`, json.soortProductOverig),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijvingProduct`, json.omschrijvingProduct),
      jsonHelpers.toTekstVraag(`${key}.merkOfNaamProduct`, json.merkOfNaamProduct),
      jsonHelpers.toTekstVraag(`${key}.typenummer`, json.typenummer),
      LandVragen.fromJson(`${key}.landVanHerkomst`, json.landVanHerkomst),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijvingKlacht`, json.omschrijvingKlacht),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden)
    );
  }
}
