import React from "react";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldChecklistvraag } from "../../../samenvatting";
import { Meerkeuzevraag, Vraag } from "./../../../../models/api";

export class MeerkeuzeMeerdereAntwoordmogelijkheden {
  static getType = (): VraagType => VraagType.meerkeuzeMeerdereAntwoordmogelijkheden;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldChecklistvraag vraag={vraag as Meerkeuzevraag} />;
}
