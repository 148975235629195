import { connect } from "react-redux";
import { State } from "../../store";
import { Captcha } from "./captcha.component";
import { CaptchaStateProps } from "./captcha.component.interfaces";

const mapStateToProps = (state: State): CaptchaStateProps => ({
  captchaRequired: state.vragen.formulier?.captchaRequired ?? false,
  submitting: state.proces.isSubmitRequested
});

export default connect(mapStateToProps)(Captcha);
