import { connect } from "react-redux";
import { AsbestverwijderingLavsV2Formulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getAsbestverwijderingLavsV2FormulierMelding } from "../../../thunks/melding-thunks";
import { AsbestverwijderingLavsV2FormulierComponent } from "./asbestverwijdering-lavs-v2-formulier.component";
import {
  AsbestverwijderingLavsV2FormulierComponentDispatchProps,
  AsbestverwijderingLavsV2FormulierComponentStateProps
} from "./asbestverwijdering-lavs-v2-formulier.component.interfaces";

const mapStateToProps = (state: State): AsbestverwijderingLavsV2FormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.asbestverwijderingLavsV2
      ? (state.vragen.formulier as AsbestverwijderingLavsV2Formulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AsbestverwijderingLavsV2FormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined) => {
    if (!meldingId) {
      throw new Error("Not supported operation");
    }

    dispatch(getAsbestverwijderingLavsV2FormulierMelding(meldingId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AsbestverwijderingLavsV2FormulierComponent);
