import React from "react";
import { useTranslation } from "react-i18next";
import { CaptchaAttributes, translationKeys } from "../../constants";
import { InputControls } from "../input-controls";
import { CaptchaStateProps } from "./captcha.component.interfaces";

export const Captcha = (props: CaptchaStateProps) => {
  const { t } = useTranslation();

  return props.captchaRequired ? (
    <InputControls.Recaptcha
      id={CaptchaAttributes.Id}
      name={CaptchaAttributes.Name}
      label={t(translationKeys.captcha.tekst)}
      submitting={props.submitting}
    />
  ) : null;
};
