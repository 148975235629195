import {
  BlokActions,
  CLEAR_VRAAGBLOK_FROM_COLLECTION,
  COMMIT_BLOK,
  NavigationActions,
  REMOVE_VRAAGBLOK_FROM_COLLECTION,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SharedActions,
  VragenActions
} from "../../actions";
import {
  CLEAR_SELECTED_VESTIGING,
  CLEAR_VESTIGINGEN_SEARCH_CRITERIA,
  CLEAR_VESTIGINGEN_SEARCH_RESULTS,
  INITIALIZE_VESTIGINGEN_SEARCHES_STATE,
  RESET_VESTIGINGEN_PAGING,
  SET_SELECTED_VESTIGING,
  SET_VESTIGINGEN_PAGE,
  SET_VESTIGINGEN_PAGING,
  SET_VESTIGINGEN_SEARCHING,
  SET_VESTIGINGEN_SEARCH_CRITERIA,
  SET_VESTIGINGEN_SEARCH_RESULTS,
  VestigingenActions
} from "../../actions/blok/vestigingen-actions";
import { Configuration } from "../../configuration";
import { reducerHelper } from "../../helpers";
import {
  VestigingenPagingState,
  VestigingenSearchCriteria,
  VestigingenSearchesState,
  VestigingenSearchingState,
  VestigingenSearchResult,
  VestigingenState
} from "../../store";

const initialState: VestigingenState = {
  searches: {}
};

const initialSearchCriteria = (): VestigingenSearchCriteria => ({
  naam: "",
  kvkNummer: "",
  vestigingsnummer: "",
  straat: "",
  postcode: "",
  plaats: "",
  land: ""
});

const initialSearchResultState = (): VestigingenSearchResult => ({
  vestigingen: [],
  totaalAantalVestigingen: 0
});

const initializePagingState = (): VestigingenPagingState => ({
  busy: false,
  page: 1,
  pageSize: Configuration.vestigingSearchPageSize
});

const initialSearchingState = (): VestigingenSearchingState => ({
  busy: false,
  criteria: initialSearchCriteria(),
  result: initialSearchResultState()
});

const initialSearchesState = (): VestigingenSearchesState => ({
  searching: initialSearchingState(),
  paging: initializePagingState(),
  selectedVestiging: null,
  committedVestiging: null,
  showSearch: true,
  showResults: false,
  showSelected: false
});

function updateSearchingState(
  state: VestigingenState,
  action: VestigingenActions,
  getUpdatedSearch: (searches: VestigingenSearchesState) => VestigingenSearchesState
) {
  const searchingState = state.searches[action.vestigingenSearchKey] ?? initialSearchesState();

  return {
    ...state,
    searches: {
      ...state.searches,
      [action.vestigingenSearchKey]: getUpdatedSearch(searchingState)
    }
  };
}

type VestigingReducerActions = VestigingenActions | BlokActions | SharedActions | NavigationActions;

const vestigingenReducer = (
  state: VestigingenState = { ...initialState },
  action: VestigingReducerActions | VragenActions
): VestigingenState => {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM:
      return { ...initialState };
    case COMMIT_BLOK: {
      let result = state;

      const searchesCopy = {
        ...state.searches
      };

      for (const property in state.searches) {
        if (property.startsWith(`${action.data}.`)) {
          if (state.searches[property]) {
            state.searches[property].committedVestiging = state.searches[property].selectedVestiging;
            searchesCopy[property] = state.searches[property];
          }
        }
      }

      result = {
        ...state,
        searches: searchesCopy
      };

      return result;
    }
    case INITIALIZE_VESTIGINGEN_SEARCHES_STATE: {
      return updateSearchingState(state, action, (search) => search);
    }
    case SET_VESTIGINGEN_PAGING: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        paging: { ...search.paging, busy: action.data }
      }));
    }
    case SET_VESTIGINGEN_PAGE: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        paging: { ...search.paging, page: action.data }
      }));
    }
    case RESET_VESTIGINGEN_PAGING: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        paging: initializePagingState()
      }));
    }
    case SET_VESTIGINGEN_SEARCHING: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        searching: { ...search.searching, busy: action.data }
      }));
    }
    case SET_VESTIGINGEN_SEARCH_CRITERIA: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        searching: {
          ...search.searching,
          criteria: action.data
        }
      }));
    }
    case CLEAR_VESTIGINGEN_SEARCH_CRITERIA: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        searching: {
          ...search.searching,
          criteria: initialSearchCriteria()
        }
      }));
    }
    case SET_VESTIGINGEN_SEARCH_RESULTS: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        searching: {
          ...search.searching,
          result: action.data
        },
        showResults: true
      }));
    }
    case CLEAR_VESTIGINGEN_SEARCH_RESULTS: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        searching: {
          ...search.searching,
          result: initialSearchResultState()
        },
        showResults: false
      }));
    }
    case SET_SELECTED_VESTIGING: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        selectedVestiging: action.data,
        showSelected: true,
        showSearch: false,
        showResults: false
      }));
    }
    case CLEAR_SELECTED_VESTIGING: {
      return updateSearchingState(state, action, (search) => ({
        ...search,
        selectedVestiging: null,
        showSelected: false,
        showSearch: true,
        showResults: search.searching.result.totaalAantalVestigingen > 0
      }));
    }
    case REMOVE_VRAAGBLOK_FROM_COLLECTION: {
      const reindexSearchesCopy = { ...state.searches };
      reducerHelper.reindexCollection(reindexSearchesCopy, action.collectionIndex, `${action.collectionKey}.list`);
      return {
        ...state,
        searches: reindexSearchesCopy
      };
    }
    case CLEAR_VRAAGBLOK_FROM_COLLECTION:
      const clearSearchedCopy = { ...state.searches };
      reducerHelper.clearCollection(clearSearchedCopy, action.collectionKey);
      return {
        ...state,
        searches: clearSearchedCopy
      };
    default:
      return state;
  }
};

export default vestigingenReducer;
