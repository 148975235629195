import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class GegevensIllegaleWerknemerAntwoorden extends AntwoordBlok {
  constructor(public naam: Antwoord<string>, public beschrijving: Antwoord<string>, public land: Antwoord<string>) {
    super();
  }

  static initialize(): GegevensIllegaleWerknemerAntwoorden {
    return new GegevensIllegaleWerknemerAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): GegevensIllegaleWerknemerAntwoorden {
    return new GegevensIllegaleWerknemerAntwoorden(
      Antwoord.fromJson<string>(json.naam),
      Antwoord.fromJson<string>(json.beschrijving),
      Antwoord.fromJson<string>(json.land)
    );
  }
}
