import { AutorisatieaanvraagFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getAutorisatieaanvraagFormulierDefinitie = (formulier: AutorisatieaanvraagFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.autorisatieaanvraag.titel,
    type: ProcesstapType.autorisatieaanvraag,
    hasNoSamenvatting: true
  }
];
