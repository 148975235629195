import { LocatieAntwoorden } from ".";
import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class AsbestlocatieAntwoorden extends AntwoordBlok {
  constructor(public locatie: LocatieAntwoorden | null, public bewoondeLocatie: Antwoord<string>) {
    super();
  }

  static initialize(): AsbestlocatieAntwoorden {
    return new AsbestlocatieAntwoorden(LocatieAntwoorden.initialize(), new Antwoord<string>(""));
  }

  static fromJson(json: any): AsbestlocatieAntwoorden {
    return new AsbestlocatieAntwoorden(
      LocatieAntwoorden.fromJson(json.locatie),
      Antwoord.fromJson<string>(json.bewoondeLocatie)
    );
  }
}
