import { Breadcrumb } from "../models/application";

export const ADD_BREADCRUMB = "ADD_BREADCRUMB";
export const CLEAR_BREADCRUMB = "CLEAR_BREADCRUMB";
export const CLEAR_BREADCRUMBS = "CLEAR_BREADCRUMBS";

interface AddBreadcrumbAction {
  type: typeof ADD_BREADCRUMB;
  data: Breadcrumb;
}

interface ClearBreadcrumbAction {
  type: typeof CLEAR_BREADCRUMB;
  id: string;
}

interface ClearBreadcrumbsAction {
  type: typeof CLEAR_BREADCRUMBS;
}

export type BreadcrumbActions = AddBreadcrumbAction | ClearBreadcrumbAction | ClearBreadcrumbsAction;

export const addBreadcrumb = (data: Breadcrumb): AddBreadcrumbAction => ({
  type: ADD_BREADCRUMB,
  data
});

export const clearBreadcrumb = (id: string): ClearBreadcrumbAction => ({
  type: CLEAR_BREADCRUMB,
  id: id
});

export const clearBreadcrumbs = (): ClearBreadcrumbsAction => ({
  type: CLEAR_BREADCRUMBS
});
