import { jsonHelpers } from "../../../helpers";
import { VraagBlokType } from "../../application";
import { AdresVragenDisplayDefinition } from "../display-definition";
import { Vraag } from "../vraag";
import { AdresVragen } from "./adres-vragen";

export class NederlandsAdresVragen extends AdresVragen {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public postcode: Vraag,
    public huisnummer: Vraag,
    public toevoeging: Vraag,
    public straatnaam: Vraag,
    public plaats: Vraag
  ) {
    super(
      key,
      volgnummer,
      conditioneelGesteld,
      conditioneelReadonly,
      postcode,
      huisnummer,
      toevoeging,
      straatnaam,
      plaats,
      VraagBlokType.nederlandsAdres
    );
  }

  static fromJson(key: string, json: any, displayDefinition?: AdresVragenDisplayDefinition): NederlandsAdresVragen {
    return new NederlandsAdresVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTekstVraag(`${key}.postcode`, json.postcode, displayDefinition?.postcode),
      jsonHelpers.toNumeriekeVraag(`${key}.huisnummer`, json.huisnummer),
      jsonHelpers.toTekstVraag(`${key}.toevoeging`, json.toevoeging),
      jsonHelpers.toTekstVraag(`${key}.straatnaam`, json.straatnaam),
      jsonHelpers.toPlaatsVraag(`${key}.plaats`, json.plaats, displayDefinition?.plaats)
    );
  }
}
