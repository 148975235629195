import { AntwoordBlokken, AvvArtikel10AVerzoekAntwoorden, BijlageAntwoorden, MelderAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasBijlageAntwoorden } from "./has-bijlage-antwoorden";

export class AvvArtikel10AVerzoekMeldingAntwoordBlokken extends AntwoordBlokken implements HasBijlageAntwoorden {
  constructor(
    public verzoek: AvvArtikel10AVerzoekAntwoorden,
    public verzoeker: MelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): AvvArtikel10AVerzoekMeldingAntwoordBlokken {
    return new AvvArtikel10AVerzoekMeldingAntwoordBlokken(
      AvvArtikel10AVerzoekAntwoorden.initialize(),
      MelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AvvArtikel10AVerzoekMeldingAntwoordBlokken {
    return new AvvArtikel10AVerzoekMeldingAntwoordBlokken(
      AvvArtikel10AVerzoekAntwoorden.fromJson(json.verzoek),
      MelderAntwoorden.fromJson(json.verzoeker),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
