import { connect } from "react-redux";
import { TaalWijzigDialogMode } from "store";
import { cancelTaalWijzigenDialoog, confirmTaalWijzigenDialoog } from "thunks";
import { translationKeys } from "../../../constants/translation-keys";
import { State } from "../../../store/state";
import { InvoertaalWijzigingDialog } from "./invoertaal-wijziging-dialog.component";
import {
  InvoertaalWijzigingDialogDispatchProps,
  InvoertaalWijzigingDialogStateProps
} from "./invoertaal-wijziging-dialog.component.interfaces";

const translationKeyMapping = new Map<TaalWijzigDialogMode, string>([
  [TaalWijzigDialogMode.FormulierTaalNietBeschikbaar, translationKeys["formulier-niet-in-deze-taal-beschikbaar"]],
  [
    TaalWijzigDialogMode.FormulierTaalNietTeWijzigenBestaandeMelding,
    translationKeys.dialoog.taalWijzigenDialoog.tekstMeldingKanNietgewijzigdWorden
  ],
  [TaalWijzigDialogMode.FormulierTaalWijzigVerzoek, translationKeys.dialoog.formulierVerlaten.tekst]
]);

const mapStateToProps = (state: State): InvoertaalWijzigingDialogStateProps => ({
  requestedLanguage: state.taal.requestedLanguage,
  showCancelButton: state.taal.taalWijzigDialogMode === TaalWijzigDialogMode.FormulierTaalWijzigVerzoek,
  showDialog: state.taal.taalWijzigDialogMode !== TaalWijzigDialogMode.None,
  taalWijzigDialogMode: state.taal.taalWijzigDialogMode,
  dialogConfirmButtonTranslationKey:
    state.taal.taalWijzigDialogMode === TaalWijzigDialogMode.FormulierTaalWijzigVerzoek
      ? translationKeys.dialoog.taalWijzigenDialoog.doorgaanInTaalIndiener
      : translationKeys.dialoog.verderGaan,
  dialogTekstTranslationKey: translationKeyMapping.get(state.taal.taalWijzigDialogMode)!
});

const mapDispatchToProps = (dispatch: any): InvoertaalWijzigingDialogDispatchProps => ({
  onConfirm: () => {
    dispatch(confirmTaalWijzigenDialoog());
  },
  onCancel: () => {
    dispatch(cancelTaalWijzigenDialoog());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoertaalWijzigingDialog);
