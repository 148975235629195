import { AntwoordBlokken, ProfielAntwoorden } from "../blok";
import { HasProfielAntwoorden } from "./has-profiel-antwoorden";

export class ProfielMeldingAntwoordBlokken extends AntwoordBlokken implements HasProfielAntwoorden {
  constructor(public profiel: ProfielAntwoorden) {
    super();
  }

  static fromJson(json: any): ProfielMeldingAntwoordBlokken {
    return new ProfielMeldingAntwoordBlokken(ProfielAntwoorden.fromJson(json.profiel));
  }
}
