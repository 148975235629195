import { Dictionary } from "../models/application";

const reindexCollection = <TState>(collection: Dictionary<TState>, indexToRemove: number, collectionKey: string) => {
  // Get all keys that need to be re-indexed in guaranteed order
  const collectionKeysToReindex = getCollectionKeysToReindex();

  applyReindex();

  return;

  function applyReindex() {
    if (collectionKeysToReindex.length > indexToRemove) {
      for (let index = indexToRemove + 1; index < collectionKeysToReindex.length; index++) {
        for (const oldKey of collectionKeysToReindex[index]) {
          const lastIndexOfDot = oldKey.indexOf(".", collectionKey.length + 1);
          const newKey =
            oldKey.substring(0, collectionKey.length + 1) +
            (index - 1) +
            (lastIndexOfDot >= 0 ? oldKey.substring(oldKey.indexOf(".", collectionKey.length + 1)) : "");

          // Re-index
          collection[newKey] = collection[oldKey];

          // Delete last (highest) remaining old index
          if (index === collectionKeysToReindex.length - 1) {
            delete collection[oldKey];
          }
        }
      }
    }
  }

  function getCollectionKeysToReindex() {
    const result: Array<Array<string>> = [[]];
    const properties = Object.keys(collection);
    properties.forEach((property) => {
      if (property.startsWith(`${collectionKey}.`)) {
        const index = parseInt(
          property.substring(
            collectionKey.length + 1,
            Math.max(property.indexOf(".", collectionKey.length + 1), property.length)
          )
        );

        // Do nothing for index < indexToRemove
        // Delete items at indexToRemove
        if (index === indexToRemove) {
          delete collection[property];
        }

        // Add items at index > indexToRemove to array to be re-indexed later
        else if (index > indexToRemove) {
          if (result[index] === undefined) {
            result[index] = [];
          }
          result[index].push(property);
        }
      }
    });
    return result;
  }
};

const clearCollection = <TState>(collection: Dictionary<TState>, collectionKey: string) => {
  const properties = Object.keys(collection).filter((p) => p.startsWith(`${collectionKey}.`));

  properties.forEach((property) => {
    delete collection[property];
  });
};

export const reducerHelper = {
  reindexCollection,
  clearCollection
};
