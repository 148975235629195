import { setCollectionCurrentPage, setCollectionPageSize } from "actions";
import { connect } from "react-redux";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, NO_SELECTED_ITEM } from "reducers/collections-reducer";
import { State } from "store";
import { CollectionTablePager } from "./collection-table-pager.component";
import {
  CollectionTablePagerDispatchProps,
  CollectionTablePagerOwnProps,
  CollectionTablePagerStateProps
} from "./collection-table-pager.component.interfaces";

const mapStateToProps = (state: State, props: CollectionTablePagerOwnProps): CollectionTablePagerStateProps => ({
  currentPage: state.collections.list[props.collectionKey]?.paging.currentPage || DEFAULT_PAGE_NUMBER,
  gridPageSize: state.collections.list[props.collectionKey]?.paging.pageSize ?? DEFAULT_PAGE_SIZE,
  filteredRecords: state.collections.list[props.collectionKey]?.vraagBlokkenWithAntwoordIndexes.length ?? 0,
  totalRecords: state.collections.list[props.collectionKey]?.unfilteredTotalRecords ?? 0,
  isCollectionItemSelected:
    (state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM) !== NO_SELECTED_ITEM,
  pageStartIndex: state.collections.list[props.collectionKey]?.paging.firstItemIndex ?? 0
});

const mapDispatchToProps = (dispatch: any, ownProps: CollectionTablePagerOwnProps): CollectionTablePagerDispatchProps => ({
  onPageChange: (pageNumber: number) => {
    dispatch(setCollectionCurrentPage(ownProps.collectionKey, pageNumber));
  },
  onPageSizeChange: (pageSize: number) => {
    dispatch(setCollectionPageSize(ownProps.collectionKey, pageSize));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTablePager);
