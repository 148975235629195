import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagType } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class ProductieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public soortProductie: Meerkeuzevraag,
    public productietitel: Vraag,
    public productieStartdatum: Vraag,
    public productieEinddatum: Vraag,
    public isActualiteitenprogramma: Meerkeuzevraag,
    public opgaveArbeidMinimaleTermijn: Tekst,
    public naamActualiteitenprogramma: Vraag,
    public beschrijvingActiviteitenEnOmstandighedenKinderen: Vraag,
    public beschrijvingOrganisatieArbeid: Vraag,
    public beschrijvingWerklocatiesEnRisicos: Vraag,
    public risicosEnMaatregelen: Tekst,
    public productieRisicos: Meerkeuzevraag,
    public maatregelenRisicos: Tekst,
    public maatregelenGevaarlijkeStoffen: Vraag,
    public maatregelenGeluidsniveau: Vraag,
    public maatregelenBedienenArbeidsmiddel: Vraag,
    public maatregelenEmotioneleOverbelasting: Vraag,
    public maatregelenFysiekeOverbelasting: Vraag,
    public maatregelenBrandenElectrocutieOfBevriezing: Vraag,
    public maatregelenKnellenOfPletten: Vraag,
    public maatregelenSnijden: Vraag,
    public maatregelenVallen: Vraag,
    public maatregelenBedwelmingOfVerstikking: Vraag,
    public gebruikPersoonlijkeBeschermingsmiddelen: Meerkeuzevraag,
    public isRisicovol: Vraag,
    public isRisicovolOnbekend: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ProductieVragen {
    return new ProductieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.soortProductie`, json.soortProductie),
      jsonHelpers.toTekstVraag(`${key}.productietitel`, json.productietitel),
      jsonHelpers.toDatumVraag(`${key}.productieStartdatum`, json.productieStartdatum),
      jsonHelpers.toDatumVraag(`${key}.productieEinddatum`, json.productieEinddatum),
      jsonHelpers.toMeerkeuzeVraag(`${key}.isActualiteitenprogramma`, json.isActualiteitenprogramma),
      jsonHelpers.toWaarschuwing(`${key}.opgaveArbeidMinimaleTermijn`, json.opgaveArbeidMinimaleTermijn),
      jsonHelpers.toTekstVraag(`${key}.naamActualiteitenprogramma`, json.naamActualiteitenprogramma),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.beschrijvingActiviteitenEnOmstandighedenKinderen`,
        json.beschrijvingActiviteitenEnOmstandighedenKinderen
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.beschrijvingOrganisatieArbeid`, json.beschrijvingOrganisatieArbeid),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.beschrijvingWerklocatiesEnRisicos`,
        json.beschrijvingWerklocatiesEnRisicos
      ),
      jsonHelpers.toTitel(`${key}.risicosEnMaatregelen`, json.risicosEnMaatregelen),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.productieRisicos`,
        json.productieRisicos,
        VraagType.meerkeuzeMeerdereAntwoordmogelijkheden
      ),
      jsonHelpers.toTitel(`${key}.maatregelenRisicos`, json.maatregelenRisicos),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.maatregelenGevaarlijkeStoffen`, json.maatregelenGevaarlijkeStoffen),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.maatregelenGeluidsniveau`, json.maatregelenGeluidsniveau),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.maatregelenBedienenArbeidsmiddel`,
        json.maatregelenBedienenArbeidsmiddel
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.maatregelenEmotioneleOverbelasting`,
        json.maatregelenEmotioneleOverbelasting
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.maatregelenFysiekeOverbelasting`, json.maatregelenFysiekeOverbelasting),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.maatregelenBrandenElectrocutieOfBevriezing`,
        json.maatregelenBrandenElectrocutieOfBevriezing
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.maatregelenKnellenOfPletten`, json.maatregelenKnellenOfPletten),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.maatregelenSnijden`, json.maatregelenSnijden),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.maatregelenVallen`, json.maatregelenVallen),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.maatregelenBedwelmingOfVerstikking`,
        json.maatregelenBedwelmingOfVerstikking
      ),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.gebruikPersoonlijkeBeschermingsmiddelen`,
        json.gebruikPersoonlijkeBeschermingsmiddelen
      ),
      jsonHelpers.toBerekendeVraag(`${key}.isRisicovol`, json.isRisicovol),
      jsonHelpers.toBerekendeVraag(`${key}.isRisicovolOnbekend`, json.isRisicovolOnbekend)
    );
  }
}
