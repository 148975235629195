import { connect } from "react-redux";
import { State } from "../../../store";
import { SubmitHandler } from "./submit-handler.component";
import { SubmitHandlerStateProps } from "./submit-handler.component.interfaces";

const mapStateToProps = (state: State): SubmitHandlerStateProps => ({
  activePageType: state.proces.activeItem?.type,
  activePageHasValidationErrors: state.proces.activeItem?.hasValidationErrors,
  requestedPageType: state.proces.requestedItem?.item?.type,
  isSubmitRequested: state.proces.isSubmitRequested,
  submitValidationResults: state.proces.submitValidationResults
});

export default connect(mapStateToProps)(SubmitHandler);
