import { useMatch } from "react-router";
import { Link } from "react-router-dom";
import { GuardedLinkProps } from "./guarded-link.component.interfaces";

export const GuardedLink = (props: GuardedLinkProps) => {
  const { url, children, onClick, navigate } = props;

  const isCurrent = useMatch(url);

  const handleClick = (e: any) => {
    e.preventDefault();

    if (!isCurrent) {
      if (onClick) {
        onClick(e);
      }

      navigate();
    }
  };

  return url.startsWith("/") ? (
    <Link to={url} onClick={handleClick} className={isCurrent ? "active" : ""}>
      {children}
    </Link>
  ) : (
    <a href={url} onClick={handleClick}>
      {children}
    </a>
  );
};
