import { ArieFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getArieFormulierDefinitie = (formulier: ArieFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.melding.titelVerkort,
      type: ProcesstapType.melding
    },
    {
      title: formulier.blokken.melder.titel,
      type: ProcesstapType.melder
    },
    {
      title: formulier.blokken.locatie.titelVerkort,
      type: ProcesstapType.locatie
    },
    {
      title: formulier.blokken.bijlage.titelVerkort,
      type: ProcesstapType.bijlage
    }
  ];
};
