import { Tekst } from "../../../components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";

export class DwangDoorWerkgeverVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public dwangDoorWerkgeverTitel: Tekst,
    public watIsErAanDeHand: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): DwangDoorWerkgeverVragen {
    return new DwangDoorWerkgeverVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.dwangDoorWerkgeverTitel`, json.dwangDoorWerkgeverTitel),
      jsonHelpers.toChecklistvraag(`${key}.watIsErAanDeHand`, json.watIsErAanDeHand)
    );
  }
}
