import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, OngevalMelderAntwoorden, OngevalMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initOngevalFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getOngevalFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getOngevalFormulierDefinitie,
      OngevalMeldingAntwoordBlokken.initialize
    );
  };

export const initOngevalFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getOngevalFormulier(),
      meldingApi.getOngevalMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): OngevalMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: OngevalMelderAntwoorden.initialize(user),
        meldingsplichtToedracht: {
          ...melding.blokken.meldingsplichtToedracht,
          toedracht: {
            ...melding.blokken.meldingsplichtToedracht.toedracht,
            beeldmateriaalBestanden: new Antwoord<Bestand[]>([])
          }
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getOngevalFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
