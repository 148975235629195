import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { InformatieverzoekFormulierVraagBlokken } from "./informatieverzoek-formulier-vraag-blokken";

export class InformatieverzoekFormulier extends FormulierBase {
  constructor(public blokken: InformatieverzoekFormulierVraagBlokken, json: any) {
    super(Formuliertype.informatieverzoek, blokken, json);
  }

  static fromJson(json: any): InformatieverzoekFormulier {
    return new InformatieverzoekFormulier(InformatieverzoekFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
