import { BiologischeAgentiaFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getBiologischeAgentiaFormulierDefinitie = (formulier: BiologischeAgentiaFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.locatie.titelVerkort,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.werkzaamheden.titel,
    type: ProcesstapType.werkzaamheden
  }
];
