import React from "react";
import { EditableProps } from "./editable-wrapper.component.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export const EditableWrapper: React.FC<EditableProps> = (props) => {
  const EditIcon = () => {
    const { item, onEdit } = props;

    return item && onEdit ? (
      <button
        type="button"
        aria-label="{props.editIconTitle}"
        className="edit"
        onClick={(e) => {
          onEdit(item);
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon icon={faEdit} size="1x" className="edit" title={props.editIconTitle} />
      </button>
    ) : null;
  };

  return (
    <div>
      <EditIcon />
      {props.children}
    </div>
  );
};
