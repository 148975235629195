import { BijlageVragen, CbiMelderVragen, VraagBlokken } from "../blok";
import { HasBijlageVragen } from "./has-bijlage-vragen";
import { HasCbiMelderVragen } from "./has-cbi-melder-vragen";

export class CbiFormulierVraagBlokken extends VraagBlokken implements HasCbiMelderVragen, HasBijlageVragen {
  [key: string]: any;

  constructor(public melder: CbiMelderVragen, public bijlage: BijlageVragen) {
    super();
  }

  static fromJson(json: any): CbiFormulierVraagBlokken {
    return new CbiFormulierVraagBlokken(
      CbiMelderVragen.fromJson("melder", json.melder),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
