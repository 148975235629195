import { AntwoordBlokken, BijlageAntwoorden, CbiMelderAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasBijlageAntwoorden } from "./has-bijlage-antwoorden";
import { HasCbiMelderAntwoorden } from "./has-cbi-melder-antwoorden";

export class CbiMeldingAntwoordBlokken extends AntwoordBlokken implements HasCbiMelderAntwoorden, HasBijlageAntwoorden {
  constructor(public melder: CbiMelderAntwoorden, public bijlage: BijlageAntwoorden) {
    super();
  }

  static initialize(user: User | null = null): CbiMeldingAntwoordBlokken {
    return new CbiMeldingAntwoordBlokken(CbiMelderAntwoorden.initialize(user), BijlageAntwoorden.initialize());
  }

  static fromJson(json: any): CbiMeldingAntwoordBlokken {
    return new CbiMeldingAntwoordBlokken(
      CbiMelderAntwoorden.fromJson(json.melder),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
