import { connect } from "react-redux";
import { State } from "../../../store";
import { addFiles, deleteFiles } from "../../../thunks";
import { InputUniqueFiles } from "./input-unique-files.component";
import { InputUniqueFilesDispatchProps, InputUniqueFilesStateProps } from "./input-unique-files.interface";

const mapStateToProps = (state: State): InputUniqueFilesStateProps => ({
  hasConflicts: state.bestanden.conflictingFiles.length > 0,
  files: state.bestanden.files
});

const mapDispatchToProps = (dispatch: any): InputUniqueFilesDispatchProps => ({
  onAddFiles: (files: File[]) => dispatch(addFiles(files)),
  onDeleteFiles: (files: File[]) => dispatch(deleteFiles(files))
});

export default connect(mapStateToProps, mapDispatchToProps)(InputUniqueFiles);
