import { composeWithDevTools } from "@redux-devtools/extension";
import { createBrowserHistory } from "history";
import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { thunk } from "redux-thunk";
import { createRootReducer } from "./../reducers";

const browserHistory = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: browserHistory
});

const rootReducer = createRootReducer(routerReducer);

const wrappedRootReducer = (state: any, action: any) => {
  if (action.type === "SET_STATE") {
    state = { ...state, ...action.state };
  }
  return rootReducer(state, action);
};

const appliedMiddleware = applyMiddleware(routerMiddleware, thunk);

export const store = createStore(wrappedRootReducer, composeWithDevTools(appliedMiddleware));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const reduxHistory = createReduxHistory(store);
