import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import {
  MeldingBase,
  OntheffingVuurOpTankschipMelding,
  OntheffingVuurOpTankschipMeldingAntwoordBlokken
} from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { OntheffingVuurOpTankschipFormulierComponentProps } from "./ontheffing-vuur-op-tankschip-formulier.component.interfaces";

export const OntheffingVuurOpTankschipFormulierComponent = (props: OntheffingVuurOpTankschipFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.ontheffingVuurOpTankschip;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const formulier = props.formulier;
    const blokDefinitie = FormulierDefinitie.getOntheffingVuurOpTankschipFormulierDefinitie(formulier);

    return (
      <Formulier
        type={Formuliertype.ontheffingVuurOpTankschip}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(OntheffingVuurOpTankschipMelding)}
        getEmailadres={(blokken: OntheffingVuurOpTankschipMeldingAntwoordBlokken) =>
          blokken.aanvrager.melder?.emailadres.waarde
        }
      ></Formulier>
    );
  } else {
    return null;
  }
};
