import { jsonHelpers } from "../../../helpers";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class PersoonsgegevensVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public persoonsgegevens: Tekst,
    public voorletters: Vraag,
    public tussenvoegsels: Vraag,
    public achternaam: Vraag,
    public geslacht: Meerkeuzevraag,
    public functie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly);
  }

  static fromJson(key: string, json: any): PersoonsgegevensVragen {
    return new PersoonsgegevensVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTitel(`${key}.persoonsgegevens`, json.persoonsgegevens),
      jsonHelpers.toTekstVraag(`${key}.voorletters`, json.voorletters),
      jsonHelpers.toTekstVraag(`${key}.tussenvoegsels`, json.tussenvoegsels),
      jsonHelpers.toTekstVraag(`${key}.achternaam`, json.achternaam),
      jsonHelpers.toMeerkeuzeVraag(`${key}.geslacht`, json.geslacht),
      jsonHelpers.toTekstVraag(`${key}.functie`, json.functie)
    );
  }
}
