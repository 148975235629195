import _ from "lodash";
import { Order } from "store";

const deaccent = (s: string): string => _.deburr(s);

const toLocalLowerCase = (s: string): string => deaccent(s).toLowerCase();
const toLocalUpperCase = (s: string): string => deaccent(s).toUpperCase();

const includesDeaccent = (source: string, part: string, caseInsensitive = true) => {
  if (caseInsensitive) {
    return toLocalLowerCase(source).includes(toLocalLowerCase(part));
  } else {
    return deaccent(source).includes(deaccent(part));
  }
};

const sort = (left: string, right: string, order = Order.ascending) => {
  if (order === Order.ascending) {
    return toLocalLowerCase(right).localeCompare(toLocalLowerCase(left));
  } else {
    return toLocalLowerCase(left).localeCompare(toLocalLowerCase(right));
  }
};

export const stringHelpers = {
  toLocalLowerCase,
  toLocalUpperCase,
  includesDeaccent,
  deaccent,
  sort
};
