import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { OpdrachtgeverVragen } from "./opdrachtgever-vragen";

export class BibobVerzoekVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public omschrijvingVerzoek: Vraag,
    public verzoekVestigingen: Tekst,
    public vestigingen: VraagBlokCollection<OpdrachtgeverVragen>,
    public externeReferentieTitel: Tekst,
    public externeReferentie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): BibobVerzoekVragen {
    return new BibobVerzoekVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijvingVerzoek`, json.omschrijvingVerzoek),
      jsonHelpers.toParagraaf(`${key}.verzoekVestigingen`, json.verzoekVestigingen),
      VraagBlokCollection.fromJson<OpdrachtgeverVragen>(
        OpdrachtgeverVragen.fromJson,
        `${key}.vestigingen`,
        json.vestigingen,
        VraagBlokCollectionType.vestigingen
      ),
      jsonHelpers.toTitel(`${key}.externeReferentieTitel`, json.externeReferentieTitel),
      jsonHelpers.toTekstVraag(`${key}.externeReferentie`, json.externeReferentie)
    );
  }
}
