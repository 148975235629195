import { VraagBlokken, ProfielVragen } from "../blok";
import { HasProfielVragen } from "./has-profiel-vragen";

export class ProfielFormulierVraagBlokken extends VraagBlokken implements HasProfielVragen {
  [key: string]: any;

  constructor(public profiel: ProfielVragen) {
    super();
  }

  static fromJson(json: any): ProfielFormulierVraagBlokken {
    return new ProfielFormulierVraagBlokken(ProfielVragen.fromJson("profiel", json.profiel));
  }
}
