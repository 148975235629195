import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { MelderAntwoorden } from "./melder-antwoorden";

export class AnoniemMelderAntwoorden extends AntwoordBlok {
  constructor(
    public anoniemMelden: Antwoord<string>,
    public anoniemMeldenReden: Antwoord<string>,
    public common: MelderAntwoorden | null
  ) {
    super();
  }

  static initialize(user: User | null = null): AnoniemMelderAntwoorden {
    return new AnoniemMelderAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      MelderAntwoorden.initialize(user)
    );
  }

  static fromJson(json: any): AnoniemMelderAntwoorden | null {
    return json
      ? new AnoniemMelderAntwoorden(
          Antwoord.fromJson(json.anoniemMelden),
          Antwoord.fromJson(json.anoniemMeldenReden),
          MelderAntwoorden.fromJson(json.common)
        )
      : null;
  }
}
