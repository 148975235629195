import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { OngevalFormulierVraagBlokken } from "./ongeval-formulier-vraag-blokken";

export class OngevalFormulier extends FormulierBase {
  constructor(public blokken: OngevalFormulierVraagBlokken, json: any) {
    super(Formuliertype.ongeval, blokken, json);
  }

  static fromJson(json: any): OngevalFormulier {
    return new OngevalFormulier(OngevalFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
