import {
  MotivatieVragen,
  VluchtigeOrganischeStoffenAanvraagVragen,
  VluchtigeOrganischeStoffenMelderVragen,
  VraagBlokken,
  WerkzaamhedenOpAdresBedrijfLocatieVragen
} from "../blok";
import { HasMotivatieVragen } from "./has-motivatie-vragen";
import { HasVluchtigeOrganischeStoffenAanvraagVragen } from "./has-vluchtige-organische-stoffen-aanvraag-vragen";
import { HasVluchtigeOrganischeStoffenAanvragerVragen } from "./has-vluchtige-organische-stoffen-aanvrager-vragen";
import { HasWerkzaamhedenOpAdresBedrijfLocatieVragen } from "./has-werkzaamheden-op-adres-bedrijf-locatie-vragen";

export class VluchtigeOrganischeStoffenFormulierVraagBlokken
  extends VraagBlokken
  implements
    HasVluchtigeOrganischeStoffenAanvragerVragen,
    HasWerkzaamhedenOpAdresBedrijfLocatieVragen,
    HasVluchtigeOrganischeStoffenAanvraagVragen,
    HasMotivatieVragen
{
  [key: string]: any;

  constructor(
    public aanvrager: VluchtigeOrganischeStoffenMelderVragen,
    public locatie: WerkzaamhedenOpAdresBedrijfLocatieVragen,
    public aanvraag: VluchtigeOrganischeStoffenAanvraagVragen,
    public motivatie: MotivatieVragen
  ) {
    super();
  }

  static fromJson(json: any): VluchtigeOrganischeStoffenFormulierVraagBlokken {
    return new VluchtigeOrganischeStoffenFormulierVraagBlokken(
      VluchtigeOrganischeStoffenMelderVragen.fromJson("aanvrager", json.aanvrager),
      WerkzaamhedenOpAdresBedrijfLocatieVragen.fromJson("locatie", json.locatie),
      VluchtigeOrganischeStoffenAanvraagVragen.fromJson("aanvraag", json.aanvraag),
      MotivatieVragen.fromJson("motivatie", json.motivatie)
    );
  }
}
