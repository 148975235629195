import { useFormikContext } from "formik";
import { AntwoordBlokken } from "models/api";
import { Fragment, useCallback } from "react";
import { Card as BSCard } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { objectHelpers } from "../../../../../../helpers";
import { getAntwoordBlokFromFormik } from "../../../collection-handler";
import { CollectionRowToggleHeader } from "../../../grid/collection-table/collection-table-row-toggle/header";
import { CollectionAccordionHeaderProps } from "./collection-accordion-header.component.interfaces";

export const CollectionAccordionHeader = (props: CollectionAccordionHeaderProps) => {
  const {
    i18n: { language }
  } = useTranslation();
  const formikContext = useFormikContext<AntwoordBlokken>();
  const { vraagAntwoordBlok, collectionValidationFailures, getCollectionItemSummary, rowIndex } = props;
  const { vraagBlok, antwoordBlokIndex } = vraagAntwoordBlok;
  const { key: vraagBlokKey, titel: vraagBlokTitel } = vraagBlok;

  const isErrorInRow = useCallback(
    () =>
      collectionValidationFailures
        .map((vf) => objectHelpers.removeLastPropertyFromKey(vf.property, true))
        .some((vf) => vf.startsWith(`${vraagBlokKey}.`)),
    [collectionValidationFailures, vraagBlokKey]
  );

  const getHeaderTitle = useCallback(() => {
    return vraagBlokTitel ? `${vraagBlokTitel[language]} ${antwoordBlokIndex + 1}` : undefined;
  }, [vraagBlokTitel, antwoordBlokIndex, language]);

  const getAntwoordTitel = useCallback(() => {
    const headerTitleAntwoord = getAntwoordBlokFromFormik(vraagAntwoordBlok, formikContext.values);
    return headerTitleAntwoord ? getCollectionItemSummary(headerTitleAntwoord, rowIndex) : "";
  }, [formikContext, getCollectionItemSummary, vraagAntwoordBlok, rowIndex]);

  return (
    <Fragment>
      <BSCard.Header {...(isErrorInRow() && { className: "error-row" })}>
        <CollectionRowToggleHeader collectionKey={props.collectionKey} rowKey={vraagBlokKey} rowIndex={rowIndex}>
          <div className="card-header-title ">
            {getHeaderTitle()}
            {getAntwoordTitel() ? <span className="card-header-additional-title">({getAntwoordTitel()})</span> : null}
          </div>
        </CollectionRowToggleHeader>
      </BSCard.Header>
    </Fragment>
  );
};
