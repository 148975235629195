import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../constants/translation-keys";
import { Formuliertype } from "../../models/application";
import { FormulierBreadcrumbs } from "../breadcrumbs";
import { FormulierHeader } from "../formulier-header";
import { MainContent } from "../main-content";
import { AutorisatieaanvraagFormulierVerstuurdProps } from "./autorisatieaanvraag-formulier-verstuurd.component.interfaces";

export const AutorisatieaanvraagFormulierVerstuurd = (props: AutorisatieaanvraagFormulierVerstuurdProps) => {
  const { t } = useTranslation();

  return props.formulier ? (
    <Row className="main-container">
      <Col xs={12} className="main-container-title">
        <FormulierHeader formulier={props.formulier} />
      </Col>
      <Col xs={12} className="main-container-content">
        <FormulierBreadcrumbs formulierTitel={props.formulier.titel} formulierType={Formuliertype.autorisatieaanvraag} />
        <MainContent>
          <div className="main-form">
            <h2>{t(translationKeys["autorisatieaanvraag-formulier-verstuurd"].titel)}</h2>
            <div id="formulierVerstuurdMelding">{t(translationKeys["autorisatieaanvraag-formulier-verstuurd"].melding)}</div>
          </div>
        </MainContent>
      </Col>
    </Row>
  ) : null;
};
