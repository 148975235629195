import { connect } from "react-redux";
import { push } from "redux-first-history";
import { initAntwoorden } from "../../../actions";
import { AutorisatieaanvraagFormulier, AutorisatieaanvraagMeldingAntwoordBlokken } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { initAutorisatieaanvraagFormulier } from "../../../thunks";
import { AutorisatieaanvraagFormulierComponent } from "./autorisatieaanvraag-formulier.component";
import {
  AutorisatieaanvraagFormulierComponentDispatchProps,
  AutorisatieaanvraagFormulierComponentStateProps
} from "./autorisatieaanvraag-formulier.component.interfaces";

const mapStateToProps = (state: State): AutorisatieaanvraagFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.autorisatieaanvraag
      ? (state.vragen.formulier as AutorisatieaanvraagFormulier)
      : undefined;

  return {
    formulier,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AutorisatieaanvraagFormulierComponentDispatchProps => ({
  onInit: () => {
    dispatch(initAutorisatieaanvraagFormulier());

    dispatch(initAntwoorden(AutorisatieaanvraagMeldingAntwoordBlokken.initialize(), Formuliertype.autorisatieaanvraag));
  },
  onCreated: async () => {
    dispatch(push("/autorisatieaanvraagverstuurd"));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AutorisatieaanvraagFormulierComponent);
