import { AsbestRisicoklasse1Formulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getAsbestRisicoklasse1FormulierDefinitie = (formulier: AsbestRisicoklasse1Formulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.opdrachtgever.titel,
    type: ProcesstapType.opdrachtgever
  },
  {
    title: formulier.blokken.inventarisatie.titelVerkort,
    type: ProcesstapType.inventarisatie
  },
  {
    title: formulier.blokken.locatie.titelVerkort,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.periode.titel,
    type: ProcesstapType.periode
  },
  {
    title: formulier.blokken.werkzaamheden.titel,
    type: ProcesstapType.werkzaamheden
  }
];
