import {
  setMededelingen,
  setMededelingenLoading,
  setNotDirty,
  setSubmitRequested,
  setSubmitting,
  setSubmittingComplete
} from "../actions";
import { AutorisatiesZoekenAntwoorden, GetAutorisatiesRequest, GetAutorisatiesResponse, Mededeling } from "../models/api";
import { setAutorisaties, setAutorisatiesFilter, setAutorisatiesLoading } from "./../actions/autorisaties-actions";
import { beheerApi, mededelingApi } from "./../api";
import { AppThunk } from "./app-thunk-type";

export const submitAutorisatiesZoekenFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setSubmitting());

    const state = getState();

    let zoekFilter = state.antwoorden.blokken.filter as AutorisatiesZoekenAntwoorden;
    if (zoekFilter === undefined && state.beheer.zoekFilter) {
      zoekFilter = state.beheer.zoekFilter;
    }
    dispatch(setAutorisatiesFilter(state.beheer.sortOption, state.beheer.pageNumber, zoekFilter));

    const request = new GetAutorisatiesRequest(
      state.beheer.sortOption,
      state.beheer.pageNumber,
      state.beheer.pageSize,

      zoekFilter?.bedrijfsnaam.waarde,
      zoekFilter?.gebruikersnaam.waarde,
      zoekFilter?.achternaam.waarde,
      zoekFilter?.kvkNummer.waarde,
      zoekFilter?.vestigingsnummer.waarde,
      zoekFilter?.geldig.waarde
    );

    dispatch(getAutorisaties(request));

    dispatch(setSubmittingComplete(true));
    dispatch(setSubmitRequested(false));
  };

export const getAutorisaties =
  (request: GetAutorisatiesRequest): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setAutorisatiesLoading(true, false));

    await beheerApi
      .getAutorisaties(request)
      .then((json) => {
        const response = GetAutorisatiesResponse.fromJson(json);
        dispatch(setAutorisaties(response.autorisaties, response.totalItemCount));
        dispatch(setAutorisatiesLoading(false, false));
      })
      .catch(() => {
        dispatch(setAutorisatiesLoading(false, true));
      });
  };

export const getMededeling =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setMededelingenLoading(true, false));

    await mededelingApi
      .getMededeling()
      .then((json) => {        
        dispatch(setMededelingen(json));
        dispatch(setMededelingenLoading(false, false));
      })
      .catch(() => {
        dispatch(setMededelingenLoading(false, true));
      });
  };

export const updateMededeling =
  (mededelingen: Mededeling[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setMededelingenLoading(true, false));

    await mededelingApi
      .updateMededeling(mededelingen)
      .then(() => {
        dispatch(setNotDirty());
        dispatch(setMededelingen(mededelingen));
        dispatch(setMededelingenLoading(false, false));
      })
      .catch(() => {
        dispatch(setMededelingenLoading(false, true));
      });
  };
