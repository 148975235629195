import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class ArbeidstijdenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public arbeidstijdenTitel: Tekst,
    public welkeArbeidstijdenMeldingBetrekking: Meerkeuzevraag,
    public welkeTijdstippenUitvoerWerkzaamheden: Vraag,
    public arbeidstijdregistratieDoorOnderneming: Meerkeuzevraag,
    public arbeidstijdregistratieDoorWerknemer: Meerkeuzevraag,
    public graagBijvoegenParagraaf: Tekst
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ArbeidstijdenVragen {
    return new ArbeidstijdenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.arbeidstijdenTitel`, json.arbeidstijdenTitel),
      jsonHelpers.toChecklistvraag(`${key}.welkeArbeidstijdenMeldingBetrekking`, json.welkeArbeidstijdenMeldingBetrekking),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.welkeTijdstippenUitvoerWerkzaamheden`,
        json.welkeTijdstippenUitvoerWerkzaamheden
      ),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.arbeidstijdregistratieDoorOnderneming`,
        json.arbeidstijdregistratieDoorOnderneming
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.arbeidstijdregistratieDoorWerknemer`, json.arbeidstijdregistratieDoorWerknemer),
      jsonHelpers.toParagraaf(`${key}.graagBijvoegenParagraaf`, json.graagBijvoegenParagraaf)
    );
  }
}
