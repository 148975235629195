import {
  AntwoordBlokken,
  BijlageAntwoorden,
  KlachtenBedrijfsgegevensWerklocatieAntwoorden,
  MeldingAntwoorden
} from "../blok";
import { KlachtenMelderAntwoorden } from "../blok/klachten-melder-antwoorden";
import { User } from "../security/user";
import { HasBijlageAntwoorden } from "./has-bijlage-antwoorden";
import { HasKlachtenBedrijfsgegevensWerklocatieAntwoorden } from "./has-klachten-bedrijfsgegevens-werklocatie-antwoorden";
import { HasKlachtenMelderAntwoorden } from "./has-klachten-melder-antwoorden";
import { HasMeldingAntwoorden } from "./has-melding-antwoorden";

export class KlachtenMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasKlachtenMelderAntwoorden,
    HasKlachtenBedrijfsgegevensWerklocatieAntwoorden,
    HasMeldingAntwoorden,
    HasBijlageAntwoorden
{
  constructor(
    public melder: KlachtenMelderAntwoorden,
    public bedrijfsgegevensWerklocatie: KlachtenBedrijfsgegevensWerklocatieAntwoorden,
    public melding: MeldingAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): KlachtenMeldingAntwoordBlokken {
    return new KlachtenMeldingAntwoordBlokken(
      KlachtenMelderAntwoorden.initialize(user),
      KlachtenBedrijfsgegevensWerklocatieAntwoorden.initialize(),
      MeldingAntwoorden.initialize(),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): KlachtenMeldingAntwoordBlokken {
    return new KlachtenMeldingAntwoordBlokken(
      KlachtenMelderAntwoorden.fromJson(json.melder),
      KlachtenBedrijfsgegevensWerklocatieAntwoorden.fromJson(json.bedrijfsgegevensWerklocatie),
      MeldingAntwoorden.fromJson(json.melding),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
