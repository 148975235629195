import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { InventarisatieTeVerwijderenAsbestMateriaalValidator, languageHelpers } from "../../../helpers";
import { Antwoord, TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden } from "../../../models/api";
import { FormulierInputControls } from "../../formulier";
import { TeVerwijderenAsbestMateriaalInventarisatieOwnProps } from "./te-verwijderen-asbest-materiaal-inventarisatie.interfaces";

export const TeVerwijderenAsbestMateriaalInventarisatie = (props: TeVerwijderenAsbestMateriaalInventarisatieOwnProps) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const { vraag } = props;

  const [field] = useField<Antwoord<TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden[]>>(vraag.key);

  const getEenheidWaarde = (waarde: string) => {
    return languageHelpers.getWaardeFromMeerkeuzevraag(vraag.subVragen.eenheid.mogelijkeAntwoorden, waarde, lang);
  };

  return (
    <div className="asbest-inventarisatie">
      <FormulierInputControls.Tabular
        fullWidth={true}
        header={t(translationKeys.blok.inventarisatie.inventarisatieHelptekst, {
          knop: t(translationKeys.toevoegen)
        })}
        vraag={vraag}
        vraagKey={vraag.key}
        initialAddValues={TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden.initialize()}
        validateForm={InventarisatieTeVerwijderenAsbestMateriaalValidator.createValidate(vraag.subVragen)}
        values={field.value.waarde}
        itemTemplate={[
          {
            component: FormulierInputControls.Text,
            vraag: vraag.subVragen.hoeveelheid,
            getAntwoord: (value: any) => value.hoeveelheid.waarde,
            createInputElement: () => {
              return React.createElement(FormulierInputControls.Text, {
                vraag: vraag.subVragen.hoeveelheid,
                inputOnly: true
              });
            }
          },
          {
            component: FormulierInputControls.Dropdown,
            vraag: vraag.subVragen.eenheid,
            getAntwoord: (value: any) => getEenheidWaarde(value.eenheid.waarde),
            createInputElement: () => {
              return React.createElement(FormulierInputControls.Dropdown, {
                vraag: vraag.subVragen.eenheid,
                inputOnly: true
              });
            }
          },
          {
            component: FormulierInputControls.Text,
            vraag: vraag.subVragen.soortMateriaal,
            getAntwoord: (value: any) => value.soortMateriaal.waarde,
            createInputElement: () => {
              return React.createElement(FormulierInputControls.Text, {
                vraag: vraag.subVragen.soortMateriaal,
                inputOnly: true
              });
            }
          }
        ]}
        getItemTekst={(value: any) =>
          `${value.hoeveelheid.waarde} ${getEenheidWaarde(value.eenheid.waarde)} ${value.soortMateriaal.waarde}`
        }
      />
    </div>
  );
};
