import { userHelpers } from "helpers";
import { ExterneReferentie, Formuliertype } from "models/application";
import { connect } from "react-redux";
import { createConceptMelding, requestUrlNavigation } from "thunks";
import { getFormulieren } from "thunks/meldingen-thunks";
import { State } from "../../../store";
import { Toevoegen } from "./toevoegen.component";
import { ToevoegenDispatchProps, ToevoegenStateProps } from "./toevoegen.component.interfaces";

const mapStateToProps = (state: State): ToevoegenStateProps => ({
  formulierTypes: state.meldingen.formulierTypes,
  isInterneMedewerker: userHelpers.getIsInternalUser(state.security.user),
  isSubmitting: state.antwoorden.isSubmitting,
  hasSubmitError: state.antwoorden.hasSubmitError
});

const mapDispatchToProps = (dispatch: any): ToevoegenDispatchProps => ({
  onLoad: () => {
    dispatch(getFormulieren());
  },
  openFormulier: async (formuliertype: Formuliertype) => {
    dispatch(requestUrlNavigation(`/${formuliertype.toLowerCase()}`));
  },
  startConceptMelding: async (formuliertype: Formuliertype, externeReferentie: ExterneReferentie) => {
    const conceptMeldingId = await dispatch(createConceptMelding(formuliertype, externeReferentie));
    if (conceptMeldingId) {
      dispatch(requestUrlNavigation(`/${formuliertype.toLowerCase()}/${conceptMeldingId}`));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Toevoegen);
