import { MeldingListItem } from "../models/api";
import { MeldingFilters } from "../models/api/zoek";
import { ColumnSortOption } from "../store/collections";

export const SET_ZOEK_MELDINGEN_LOADING = "SET_ZOEK_MELDINGEN_LOADING";
export const SET_ZOEK_MELDINGEN_FILTER = "SET_ZOEK_MELDINGEN_FILTER";
export const SET_ZOEK_MELDINGEN_FAILED = "SET_ZOEK_MELDINGEN_FAILED";
export const SET_ZOEK_MELDINGEN_SUCCESS = "SET_ZOEK_MELDINGEN_SUCCESS";
export const SET_ZOEK_MELDINGEN_RESULT = "SET_ZOEK_MELDINGEN_RESULT";
export const RESET_ZOEK_MELDINGEN = "RESET_ZOEK_MELDINGEN";

interface SetZoekMeldingenLoadingAction {
  type: typeof SET_ZOEK_MELDINGEN_LOADING;
  isLoading: boolean;
}

interface SetZoekMeldingenFilterAction {
  type: typeof SET_ZOEK_MELDINGEN_FILTER;
  sortOption?: ColumnSortOption;
  pageNumber?: number;
  filter?: MeldingFilters;
}

interface SetZoekMeldingenFailedAction {
  type: typeof SET_ZOEK_MELDINGEN_FAILED;
}

interface SetZoekMeldingenSuccessAction {
  type: typeof SET_ZOEK_MELDINGEN_SUCCESS;
}

interface SetZoekMeldingenResultAction {
  type: typeof SET_ZOEK_MELDINGEN_RESULT;
  meldingen: MeldingListItem[];
  totalItemCount: number;
}

interface ResetZoekMeldingenAction {
  type: typeof RESET_ZOEK_MELDINGEN;
}

export type ZoekMeldingenActions =
  | SetZoekMeldingenLoadingAction
  | SetZoekMeldingenFilterAction
  | SetZoekMeldingenFailedAction
  | SetZoekMeldingenSuccessAction
  | SetZoekMeldingenResultAction
  | ResetZoekMeldingenAction;

export const setZoekMeldingenLoading = (isLoading: boolean): SetZoekMeldingenLoadingAction => {
  return {
    type: SET_ZOEK_MELDINGEN_LOADING,
    isLoading: isLoading
  };
};

export const setZoekMeldingenFilter = (options: {
  sortOption?: ColumnSortOption;
  pageNumber?: number;
  filter?: MeldingFilters;
}): SetZoekMeldingenFilterAction => {
  return {
    type: SET_ZOEK_MELDINGEN_FILTER,
    filter: options.filter,
    sortOption: options.sortOption,
    pageNumber: options.pageNumber
  };
};

export const setZoekMeldingenFailed = (): SetZoekMeldingenFailedAction => {
  return {
    type: SET_ZOEK_MELDINGEN_FAILED
  };
};

export const setZoekMeldingenSuccess = (): SetZoekMeldingenSuccessAction => {
  return {
    type: SET_ZOEK_MELDINGEN_SUCCESS
  };
};

export const setZoekMeldingenResult = (
  meldingen: MeldingListItem[],
  totalItemCount: number
): SetZoekMeldingenResultAction => {
  return {
    type: SET_ZOEK_MELDINGEN_RESULT,
    meldingen: meldingen,
    totalItemCount: totalItemCount
  };
};

export const resetZoekMeldingen = (): ResetZoekMeldingenAction => {
  return {
    type: RESET_ZOEK_MELDINGEN
  };
};
