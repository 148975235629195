import { ReactNode } from "react";
import { Accordion } from "../../accordion";
import { SamenvattingContainer } from "../../samenvatting";

export const SamenvattingBar = (props: { children: ReactNode }) => {
  return (
    <Accordion.Container className="samenvatting">
      <SamenvattingContainer idPrefix="samenvatting-bar">{props.children}</SamenvattingContainer>
    </Accordion.Container>
  );
};
