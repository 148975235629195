import React from "react";
import { FormulierDropdown } from "../formulier-dropdown";

export const MijnMeldingenZoekbalk = () => {
  return (
    <div className="zoekbalk-container">
      <FormulierDropdown />
    </div>
  );
};
