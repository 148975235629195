import { useFormikContext } from "formik";
import React from "react";
import { objectHelpers } from "../../../helpers";
import { AntwoordBlokCollection, AntwoordBlokken, Vraag } from "../../../models/api";
import { InputTypeaheadItem, InputTypeaheadProps } from "../../input-controls/input-typeahead/input-typeahead.interfaces";
import { WithCollectionTypeaheadOwnProps } from "./with-collection-typeahead.interfaces";

export const withCollectionTypeahead = <P extends object>(
  Component: React.ComponentType<P & InputTypeaheadProps & { vraag: Vraag }>
) => {
  return (props: P & WithCollectionTypeaheadOwnProps) => {
    const { vraag } = props;
    const { values } = useFormikContext<AntwoordBlokken>();
    const collection = objectHelpers.getValue<AntwoordBlokCollection<any>>(values, vraag.vraagBlokCollectionProperty);
    const options: InputTypeaheadItem[] = [];

    if (collection && collection.list) {
      collection.list.forEach((item) => {
        options.push(vraag.getCollectionItem(item));
      });
    }

    return <Component {...props} isLoading={false} options={options} labelKey="label" valueKey="value" />;
  };
};
