import { Antwoord } from "../antwoord";
import { AntwoordBlokCollectionItem } from "./antwoord-blok-collection-item";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";

export class AndereWerkgeverAntwoorden extends AntwoordBlokCollectionItem {
  static get type(): string {
    return "AndereWerkgeverAntwoorden";
  }

  constructor(
    public andereWerkgever: BedrijfslocatiegegevensAntwoorden | null,
    public urenPerWeekGemiddeld: Antwoord<number | null>,
    public urenPerWeekGemiddeldNacht: Antwoord<number | null>,
    json?: any
  ) {
    super(AndereWerkgeverAntwoorden.type, json);
  }

  static initialize(): AndereWerkgeverAntwoorden {
    return new AndereWerkgeverAntwoorden(
      BedrijfslocatiegegevensAntwoorden.initialize(),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null)
    );
  }

  static fromJson(json: any): AndereWerkgeverAntwoorden {
    return new AndereWerkgeverAntwoorden(
      BedrijfslocatiegegevensAntwoorden.fromJson(json.andereWerkgever),
      json.urenPerWeekGemiddeld,
      json.urenPerWeekGemiddeldNacht
    );
  }
}
