import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { AsbestRisicoklasse1Melding, AsbestRisicoklasse1MeldingAntwoordBlokken, MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { formulierHelpers } from "./../../../helpers";
import { AsbestRisicoklasse1FormulierComponentProps } from "./asbest-risicoklasse1-formulier.component.interfaces";

export const AsbestRisicoklasse1FormulierComponent = (props: AsbestRisicoklasse1FormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.asbestRisicoklasse1;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const blokDefinitie = FormulierDefinitie.getAsbestRisicoklasse1FormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(AsbestRisicoklasse1Melding)}
        getEmailadres={(blokken: AsbestRisicoklasse1MeldingAntwoordBlokken) => blokken.melder.melder?.emailadres.waarde}
      ></Formulier>
    );
  } else {
    return null;
  }
};
