import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";

export class ReactieOpenbaarmakingReactieAntwoorden extends AntwoordBlok {
  constructor(
    public reactieOpenbaarmakingType: Antwoord<string>,
    public referentienummerBrief: Antwoord<string>,
    public datumDagtekening: Antwoord<string>,
    public reageerder: ContactpersoonAntwoorden | null,
    public reactie: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): ReactieOpenbaarmakingReactieAntwoorden {
    return new ReactieOpenbaarmakingReactieAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      ContactpersoonAntwoorden.initialize(user),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): ReactieOpenbaarmakingReactieAntwoorden {
    return new ReactieOpenbaarmakingReactieAntwoorden(
      Antwoord.fromJson(json.reactieOpenbaarmakingType),
      Antwoord.fromJson(json.referentienummerBrief),
      Antwoord.fromJson(json.datumDagtekening),
      ContactpersoonAntwoorden.fromJson(json.reageerder),
      Antwoord.fromJson(json.reactie)
    );
  }
}
