import { Dictionary, VraagBlokType } from "models/application";
import { HasConditioneelGesteld } from "./has-conditioneel-gesteld";
import { HasConditioneelReadonly } from "./has-conditioneel-readonly";
import { HasKey } from "./has-key";
import { HasVolgnummer } from "./has-volgnummer";

export abstract class VraagBlok implements HasKey, HasVolgnummer, HasConditioneelGesteld, HasConditioneelReadonly {
  [key: string]: any;

  public readonly isVraagBlok = true;

  public berekendeWaarde: any = null;

  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel?: Dictionary<string>,
    public titelVerkort?: Dictionary<string>,
    public type?: VraagBlokType
  ) {}
}
