import { VraagBlokken } from "../blok";
import { UploadRieVragen } from "../blok/upload-rie-vragen";
import { HasUploadRieVragen } from "./has-upload-rie-vragen";

export class UploadRieFormulierVraagBlokken extends VraagBlokken implements HasUploadRieVragen {
  [key: string]: any;

  constructor(public upload: UploadRieVragen) {
    super();
  }

  static fromJson(json: any): UploadRieFormulierVraagBlokken {
    return new UploadRieFormulierVraagBlokken(UploadRieVragen.fromJson("upload", json.upload));
  }
}
