import { vraagBlokCollectionHelpers } from "helpers";
import { VraagBlok } from "../vraag-blok";
import { ExpressionContext } from "./../blok/expression-context";
import { Expression } from "./expression";
import { expressionParser } from "./expression-parser";
import { VraagExpression } from "./vraag-expression";

export class CollectionItemExpression extends VraagExpression {
  get operator(): string {
    return CollectionItemExpression.operator();
  }

  constructor(
    public readonly vraagProperty: string,
    private readonly vraagBlokCollectionProperty: string,
    private readonly linkedItemExpressions: Expression[]
  ) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    let result = false;

    const linkedId = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;
    const linkedCollectionItem = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemById(
      linkedId,
      this.vraagBlokCollectionProperty,
      context.antwoordBlokken
    );
    const linkedCollectionTemplate = context.getVraagBlokCollection(this.vraagBlokCollectionProperty)?.template;

    if (linkedCollectionTemplate && linkedCollectionItem) {
      result = this.linkedItemExpressions.every((expression) => {
        return expression.execute(
          linkedCollectionTemplate,
          new ExpressionContext(
            context.currentUser,
            context.autorisatiegegevens,
            context.meldingContext,
            linkedCollectionItem,
            context.antwoordBlokken,
            context.vraagBlokken,
            context.currentAntwoordBlok
          )
        );
      });
    }

    return result;
  };

  protected clone = (instance: CollectionItemExpression): CollectionItemExpression =>
    new CollectionItemExpression(
      instance.vraagProperty,
      instance.vraagBlokCollectionProperty,
      instance.linkedItemExpressions
    );

  static fromJson = (json: any): CollectionItemExpression =>
    new CollectionItemExpression(
      json.vraag,
      json.vraagBlokCollection,
      expressionParser.fromJsonArray(json.linkedItemExpressions)
    );

  static operator = (): string => "collectionItemExpression";

  static matches = (json: any): boolean => json?.operator === CollectionItemExpression.operator();
}
