import { setSearchIsValid } from "actions";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Land, Vraag } from "../../../models/api";
import { State } from "../../../store";
import { InputTypeaheadProps } from "../../input-controls/input-typeahead/input-typeahead.interfaces";
import {
  WithLandenTypeaheadDispatchProps,
  WithLandenTypeaheadOwnProps,
  WithLandenTypeaheadProps,
  WithLandenTypeaheadStateProps
} from "./with-landen-typeahead.interfaces";

export const withLandenTypeahead = <P extends object>(
  Component: React.ComponentType<P & InputTypeaheadProps & { vraag?: Vraag }>
) => {
  const EMPTYLIST: string[] = [];
  const mapStateToProps = (state: State, ownProps: WithLandenTypeaheadOwnProps): WithLandenTypeaheadStateProps => ({
    landenIsLoading: state.landen.landenIsLoading || false,
    landen: state.landen.landen ?? EMPTYLIST
  });

  const mapDispatchToProps = (dispatch: any, ownProps: WithLandenTypeaheadOwnProps): WithLandenTypeaheadDispatchProps => ({
    validateSearch: (landen: Land[], naam: string | Land) => {
      const landNaam = _.isString(naam) ? naam : naam.displayNaam;
      dispatch(
        setSearchIsValid(
          landen.some((l) => l.displayNaam === landNaam),
          ownProps.vraag?.key ?? ownProps.key
        )
      );
    }
  });

  const TypeaheadComponent = (componentProps: WithLandenTypeaheadProps) => {
    const { vraag, landenIsLoading, landen, validateSearch, ...rest } = componentProps;

    const landenFiltered = componentProps.includeNederland ? landen : landen.filter((l) => l.landKey !== Land.landKeyNl);

    return (
      <Component
        {...(rest as P)}
        vraag={vraag}
        isLoading={landenIsLoading}
        labelKey="displayNaam"
        valueKey="landKey"
        options={landenFiltered}
        onSearch={(naam: string | Land) => validateSearch(landenFiltered, naam)}
        onSelect={(naam: string | Land) => validateSearch(landenFiltered, naam)}
      />
    );
  };

  const ConnectedTypeaheadComponent = connect(mapStateToProps, mapDispatchToProps)(TypeaheadComponent);

  return (props: P & WithLandenTypeaheadProps) => <ConnectedTypeaheadComponent {...props} />;
};
