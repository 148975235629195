import { InputOptionBase } from "components/input-controls/input-option-base";
import { useField } from "formik";
import { ReactElement } from "react";
import { InputRadioOptionProps } from "./input-radio-option.interfaces";

export const InputRadioOption: React.FC<InputRadioOptionProps> = (props: InputRadioOptionProps): ReactElement => {
  const [field] = useField(props);

  return <InputOptionBase type="radio" checked={field.value === props.optionValue} {...props} />;
};
