import { withSetDirty } from "components/input-controls/with-set-dirty";
import { withVertaling } from "components/input-controls/with-vertaling";
import { VestigingSearch } from "../blok/vestiging/vestiging-search";
import { withValidation } from "../input-controls/with-validation";
import { InputControls } from "./../../components/input-controls";
import { withCollectionTypeahead } from "./with-collection-typeahead";
import { withConditioneelGesteldeVraag, withConditioneelGesteldeVraagBlokCollection } from "./with-conditioneel-gesteld";
import { withGelinkteMeerkeuzevraag } from "./with-gelinkte-meerkeuzevraag";
import { withLandenTypeahead } from "./with-landen-typeahead";
import { withVraag } from "./with-vraag";

const container = {
  Files: withConditioneelGesteldeVraag(withVraag(InputControls.Files)),
  FileImport: withConditioneelGesteldeVraag(withVraag(InputControls.FileImport)),
  Text: withConditioneelGesteldeVraag(withVertaling(withVraag(InputControls.Text))),
  Time: withConditioneelGesteldeVraag(withVraag(InputControls.Time)),
  TextArea: withConditioneelGesteldeVraag(withVertaling(withVraag(InputControls.TextArea))),
  AsyncTypeahead: withConditioneelGesteldeVraag(withVraag(InputControls.AsyncTypeahead)),
  SyncTypeahead: withConditioneelGesteldeVraag(withVraag(InputControls.SyncTypeahead)),
  TypeaheadLanden: withLandenTypeahead(withConditioneelGesteldeVraag(withVraag(InputControls.AsyncTypeahead))),
  TypeaheadCollection: withCollectionTypeahead(withConditioneelGesteldeVraag(withVraag(InputControls.SyncTypeahead))),
  Number: withConditioneelGesteldeVraag(withVraag(InputControls.Number)),
  GebrokenAantal: withConditioneelGesteldeVraag(withVraag(InputControls.GebrokenAantal)),
  Check: withConditioneelGesteldeVraag(withVraag(InputControls.Check)),
  CheckOption: InputControls.CheckOption,
  GelinkteCheck: withConditioneelGesteldeVraag(withGelinkteMeerkeuzevraag(withVraag(InputControls.Check))),
  GelinkteRadio: withConditioneelGesteldeVraag(withGelinkteMeerkeuzevraag(withVraag(InputControls.Radio))),
  Radio: withConditioneelGesteldeVraag(withVraag(InputControls.Radio)),
  RadioOption: InputControls.RadioOption,
  Dropdown: withConditioneelGesteldeVraag(withVraag(InputControls.Dropdown)),
  DropdownOption: InputControls.DropdownOption,
  Date: withConditioneelGesteldeVraag(withVraag(InputControls.Date)),
  Tabular: withConditioneelGesteldeVraag(withVraag(InputControls.Tabular)),
  CollectionGrid: withConditioneelGesteldeVraagBlokCollection(InputControls.CollectionGrid),
  CollectionAccordion: withConditioneelGesteldeVraagBlokCollection(InputControls.CollectionAccordion),
  VestigingSearch: withConditioneelGesteldeVraag(withVraag(withValidation(withSetDirty(VestigingSearch))))
};

export { container as FormulierInputControls };
