import { VerzoekTotGezamenlijkeInterventieMelding } from "models/api/melding/verzoek-tot-gezamenlijke-interventie-melding";
import { VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken } from "models/api/melding/verzoek-tot-gezamenlijke-interventie-melding-antwoord-blokken";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { formulierHelpers } from "./../../../helpers";
import { VerzoekTotGezamenlijkeInterventieFormulierComponentProps } from "./verzoek-tot-gezamenlijke-interventie-formulier.component.interfaces";

export const VerzoekTotGezamenlijkeInterventieFormulierComponent = (
  props: VerzoekTotGezamenlijkeInterventieFormulierComponentProps
) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.verzoekTotGezamenlijkeInterventie;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const formulier = props.formulier;
    const blokDefinitie = FormulierDefinitie.getVerzoekTotGezamenlijkeInterventieFormulierDefinitie(formulier);

    return (
      <Formulier
        type={Formuliertype.verzoekTotGezamenlijkeInterventie}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(VerzoekTotGezamenlijkeInterventieMelding)}
        getEmailadres={(blokken: VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken) =>
          blokken.melder.common?.melder?.emailadres.waarde
        }
      ></Formulier>
    );
  } else {
    return null;
  }
};
