import { dateHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { Expression } from "./expression";

export class ExceedsPeriodLength extends Expression {
  get operator(): string {
    return ExceedsPeriodLength.operator();
  }

  constructor(
    private readonly startVraagProperty: string,
    private readonly eindVraagProperty: string,
    private readonly numberOfYears: number
  ) {
    super();
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    let result = false;

    const startAntwoord = context.getAntwoord(this.startVraagProperty)?.waarde;
    const eindAntwoord = context.getAntwoord(this.eindVraagProperty)?.waarde;

    if (startAntwoord && dateHelpers.isValidDate(startAntwoord) && eindAntwoord && dateHelpers.isValidDate(eindAntwoord)) {
      const maxEinddatum = dateHelpers.getMomentFromDateString(startAntwoord).add(this.numberOfYears, "years");
      const einddatum = dateHelpers.getMomentFromDateString(eindAntwoord);

      result = einddatum > maxEinddatum;
    }

    return result;
  };

  static fromJson = (json: any): ExceedsPeriodLength =>
    new ExceedsPeriodLength(json.startVraagDatum, json.eindVraagDatum, json.numberOfYears);

  static operator = (): string => "exceedsPeriodLength";

  static matches = (json: any): boolean => json?.operator === ExceedsPeriodLength.operator();
}
