import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { AsbestinventarisatieMateriaalAntwoorden } from "./asbestinventarisatie-materiaal-antwoorden";

export class AsbestinventarisatieAntwoorden extends AntwoordBlok {
  constructor(
    public rapportdatum: Antwoord<string>,
    public projectnummer: Antwoord<string>,
    public smartNs: Antwoord<string>,
    public uitvoerValidatiemetingen: Antwoord<string>,
    public validatieUitgevoerd: Antwoord<string>,
    public materiaal: AntwoordBlokCollection<AsbestinventarisatieMateriaalAntwoorden> | null,
    public saneringObject: Antwoord<string>,
    public objectomschrijving: Antwoord<string>
  ) {
    super();
  }

  static initialize(): AsbestinventarisatieAntwoorden {
    return new AsbestinventarisatieAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      AntwoordBlokCollection.initialize([]),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): AsbestinventarisatieAntwoorden {
    return new AsbestinventarisatieAntwoorden(
      Antwoord.fromJson<string>(json.rapportdatum),
      Antwoord.fromJson<string>(json.projectnummer),
      Antwoord.fromJson<string>(json.smartNs),
      Antwoord.fromJson<string>(json.uitvoerValidatiemetingen),
      Antwoord.fromJson<string>(json.validatieUitgevoerd),
      AntwoordBlokCollection.fromJson(AsbestinventarisatieMateriaalAntwoorden.fromJson, json.materiaal),
      Antwoord.fromJson<string>(json.saneringObject),
      Antwoord.fromJson<string>(json.objectomschrijving)
    );
  }
}
