import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsLessThanOrEqual extends VraagExpression {
  get operator(): string {
    return IsLessThanOrEqual.operator();
  }

  constructor(
    public readonly vraagProperty: string,
    private readonly otherVraagProperty: string,
    private readonly allowEqual: boolean
  ) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    return this.allowEqual
      ? context.getAntwoord(this.vraagProperty)?.waarde <= context.getAntwoord(this.otherVraagProperty)?.waarde
      : context.getAntwoord(this.vraagProperty)?.waarde < context.getAntwoord(this.otherVraagProperty)?.waarde;
  };

  protected clone = (instance: IsLessThanOrEqual): IsLessThanOrEqual =>
    new IsLessThanOrEqual(instance.vraagProperty, instance.otherVraagProperty, instance.allowEqual);

  static fromJson = (json: any): IsLessThanOrEqual => new IsLessThanOrEqual(json.vraag, json.otherVraag, json.allowEqual);

  static operator = (): string => "isLessThanOrEqual";

  static matches = (json: any): boolean => json?.operator === IsLessThanOrEqual.operator();
}
