import { connect } from "react-redux";
import { MeldingFile } from "../../../../models/application";
import { State } from "../../../../store/state";
import { overwriteConflictingFile, skipConflictingFile } from "../../../../thunks";
import { FileOverwriteDialog } from "./file-overwrite-dialog.component";
import {
  FileOverwriteDialogDispatchProps,
  FileOverwriteDialogStateProps
} from "./file-overwrite-dialog.component.interfaces";

const mapStateToProps = (state: State): FileOverwriteDialogStateProps => ({
  showDialog: state.bestanden?.conflictingFiles.length > 0,
  conflictingFile: state.bestanden?.conflictingFiles[0]
});

const mapDispatchToProps = (dispatch: any): FileOverwriteDialogDispatchProps => ({
  onOverwrite: (file: MeldingFile) => {
    dispatch(overwriteConflictingFile(file));
  },
  onSkip: (file: MeldingFile) => {
    dispatch(skipConflictingFile(file));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FileOverwriteDialog);
