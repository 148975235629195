import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class VerzoekTotGezamenlijkeInterventieMeldingAntwoorden extends AntwoordBlok {
  constructor(
    public wanneerIsDeControle: Antwoord<string>,
    public watIsHetVoorControle: Antwoord<string>,
    public watIsHetVoorControleOverig: Antwoord<string>,
    public welkeWetgevingIsVanToepassing: Antwoord<string[]>,
    public aanvullendeOpmerkingenMelder: Antwoord<string>
  ) {
    super();
  }

  static initialize(): VerzoekTotGezamenlijkeInterventieMeldingAntwoorden {
    return new VerzoekTotGezamenlijkeInterventieMeldingAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): VerzoekTotGezamenlijkeInterventieMeldingAntwoorden {
    return new VerzoekTotGezamenlijkeInterventieMeldingAntwoorden(
      Antwoord.fromJson<string>(json.wanneerIsDeControle),
      Antwoord.fromJson<string>(json.watIsHetVoorControle),
      Antwoord.fromJson<string>(json.watIsHetVoorControleOverig),
      Antwoord.fromJson<string[]>(json.welkeWetgevingIsVanToepassing),
      Antwoord.fromJson<string>(json.aanvullendeOpmerkingenMelder)
    );
  }
}
