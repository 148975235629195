import { HasArieLocatieVragen } from "..";
import { ArieMeldingVragen, BijlageVragen, MelderVragen, VraagBlokken } from "../blok";
import { ArieLocatieVragen } from "../blok/arie-locatie-vragen";
import { HasArieMeldingVragen } from "./has-arie-melding-vragen";
import { HasBijlageVragen } from "./has-bijlage-vragen";
import { HasMelderVragen } from "./has-melder-vragen";

export class ArieFormulierVraagBlokken
  extends VraagBlokken
  implements HasArieLocatieVragen, HasArieMeldingVragen, HasMelderVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(
    public melding: ArieMeldingVragen,
    public melder: MelderVragen,
    public locatie: ArieLocatieVragen,
    public bijlage: BijlageVragen
  ) {
    super();
  }

  static fromJson(json: any): ArieFormulierVraagBlokken {
    return new ArieFormulierVraagBlokken(
      ArieMeldingVragen.fromJson("melding", json.melding),
      MelderVragen.fromJson("melder", json.melder),
      ArieLocatieVragen.fromJson("locatie", json.locatie),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
