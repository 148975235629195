import { Formuliertype } from "../../application";
import { ContactFormulierVraagBlokken } from "./contact-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class ContactFormulier extends FormulierBase {
  constructor(public blokken: ContactFormulierVraagBlokken, json: any) {
    super(Formuliertype.contact, blokken, json);
  }

  static fromJson(json: any): ContactFormulier {
    return new ContactFormulier(ContactFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
