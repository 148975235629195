import { KlachtFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getKlachtFormulierDefinitie = (formulier: KlachtFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.klacht.titel,
    type: ProcesstapType.klacht
  }
];
