import { jsonHelpers } from "helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfslocatiegegevensVragen } from "./bedrijfslocatiegegevens-vragen";
import { ContactpersoonVragen } from "./contactpersoon-vragen";

export class LocatieProductVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public bevindtProductZichOpLocatieVanMelder: Vraag,
    public productLocatie: BedrijfslocatiegegevensVragen,
    public contactpersoonTitel: Tekst,
    public contactpersoon: ContactpersoonVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): LocatieProductVragen {
    return new LocatieProductVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.bevindtProductZichOpLocatieVanMelder`, json.bevindtProductZichOpLocatieVanMelder),
      BedrijfslocatiegegevensVragen.fromJson(`${key}.productLocatie`, json.productLocatie),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon)
    );
  }
}
