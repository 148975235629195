import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, OntheffingNachtarbeidMeldingAntwoordBlokken } from "models/api";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initOntheffingNachtarbeidFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getOntheffingNachtarbeidFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getOntheffingNachtarbeidFormulierDefinitie,
      OntheffingNachtarbeidMeldingAntwoordBlokken.initialize
    );
  };

export const initOntheffingNachtarbeidFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getOntheffingNachtarbeidFormulier(),
      meldingApi.getOntheffingNachtarbeidMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (): OntheffingNachtarbeidMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        omstandigheden: {
          ...melding.blokken.omstandigheden,
          bijlage: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getOntheffingNachtarbeidFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
