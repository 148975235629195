import { Autorisatiegegevens } from "../models/api/security/autorisatiegegevens";

export const CLEAR_AUTORISATIE_GEGEVENS = "CLEAR_AUTORISATIE_GEGEVENS";
export const SET_AUTORISATIE_GEGEVENS = "SET_AUTORISATIE_GEGEVENS";

interface ClearAutorisatieGegevens {
  type: typeof CLEAR_AUTORISATIE_GEGEVENS;
}

interface SetAutorisatieGegevens {
  type: typeof SET_AUTORISATIE_GEGEVENS;
  autorisatiegegevens: Autorisatiegegevens;
}

export type AutorisatieActions = ClearAutorisatieGegevens | SetAutorisatieGegevens;

export const clearAutorisatieGegevens = (): AutorisatieActions => {
  return {
    type: CLEAR_AUTORISATIE_GEGEVENS
  };
};

export const setAutorisatiegegevens = (autorisatiegegevens: Autorisatiegegevens): AutorisatieActions => {
  return {
    type: SET_AUTORISATIE_GEGEVENS,
    autorisatiegegevens
  };
};
