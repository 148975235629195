import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class ImporterenKinderarbeidAntwoorden extends AntwoordBlok {
  constructor(
    public maaktGebruikVanImporterenKinderarbeid: Antwoord<string>,
    public importerenKinderarbeidBestand: Antwoord<Bestand[] | null>
  ) {
    super();
  }

  static initialize(): ImporterenKinderarbeidAntwoorden {
    return new ImporterenKinderarbeidAntwoorden(new Antwoord<string>(""), new Antwoord<Bestand[] | null>(null));
  }

  static fromJson(json: any): ImporterenKinderarbeidAntwoorden {
    return new ImporterenKinderarbeidAntwoorden(
      Antwoord.fromJson<string>(json.maaktGebruikVanImporterenKinderarbeid),
      Antwoord.fromJson<Bestand[] | null>({
        waarde: json.importerenKinderarbeidBestand?.waarde
          ? json.importerenKinderarbeidBestand.waarde.map((b: any) => Bestand.fromJson(b))
          : null
      })
    );
  }
}
