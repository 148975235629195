import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { UploadGeneriekMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initUploadGeneriekFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getUploadGeneriekFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getUploadGeneriekFormulierDefinitie,
      UploadGeneriekMeldingAntwoordBlokken.initialize
    );
  };

export const initUploadGeneriekFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getUploadGeneriekFormulier(),
      meldingApi.getUploadGeneriekMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): UploadGeneriekMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        reactie: UploadGeneriekMeldingAntwoordBlokken.initialize(user)
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getUploadGeneriekFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
