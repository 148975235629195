import { meldingApi } from "api/melding-api";
import { dateHelpers } from "helpers";
import { Col, Row } from "react-bootstrap";
import { DocumentenProps } from "./documenten.component.interfaces";

export const Documenten = (props: DocumentenProps) => {
  return (
    <section key="samenvatting-melding-data-section">
      <div className="samenvatting-blok">
        {props.documenten.map((d) => (
          <Row key={d.id}>
            <Col>
              <span className="document-date">{dateHelpers.formatDateTime(d.dateTime)}</span>
              <a href={meldingApi.getDownloadDocumentUrl(props.meldingId, d.id)} target="_blank" rel="noreferrer">
                {d.name}
              </a>
            </Col>
          </Row>
        ))}
      </div>
    </section>
  );
};
