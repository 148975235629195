import { AsbestverwijderingLavsV2Formulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getAsbestverwijderingLavsV2FormulierDefinitie = (
  formulier: AsbestverwijderingLavsV2Formulier
): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.aanlevering.titel,
      type: ProcesstapType.aanlevering
    },
    {
      title: formulier.blokken.melder.titel,
      type: ProcesstapType.melder
    },
    {
      title: formulier.blokken.certificerendeInstelling.titel,
      type: ProcesstapType.certificerendeInstelling
    },
    {
      title: formulier.blokken.opdrachtgever.titel,
      type: ProcesstapType.opdrachtgever
    },
    {
      title: formulier.blokken.opdrachtnemer.titel,
      type: ProcesstapType.opdrachtnemer
    },
    {
      title: formulier.blokken.inventarisatie.titel,
      type: ProcesstapType.inventarisatie
    },
    {
      title: formulier.blokken.locatie.titel,
      type: ProcesstapType.locatie
    },
    {
      title: formulier.blokken.periode.titel,
      type: ProcesstapType.periode
    },
    {
      title: formulier.blokken.werkzaamheden.titel,
      type: ProcesstapType.werkzaamheden
    },
    {
      title: formulier.blokken.laboratorium.titel,
      type: ProcesstapType.laboratorium
    }
  ];
};
