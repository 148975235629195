import { FunctionComponent } from "react";
import { useAppDispatch } from "store";
import { setDirty } from "../../../actions";

export const withSetDirty = (ComponentType: React.ComponentType<any> | FunctionComponent<any>, useSelect = false) => {
  return (props: any) => {
    const { onChange: onComponentChange, onSelect: onComponentSelect } = props;
    const dispatch = useAppDispatch();

    const onChange = (e: any) => {
      dispatch(setDirty());

      if (e && onComponentChange) {
        onComponentChange(e);
      }
    };

    const onSelect = (e: any) => {
      if (!useSelect) {
        return;
      }
      dispatch(setDirty());

      if (e && onComponentSelect) {
        onComponentSelect(e);
      }
    };

    return <ComponentType {...props} onChange={onChange} onSelect={onSelect} />;
  };
};
