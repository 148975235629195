import { GebruikerMetadata } from ".";
import { Autorisatiegegevens } from "../security/autorisatiegegevens";

export class MetadataGegevens {
  constructor(
    public readonly autorisatiegegevens: Autorisatiegegevens,
    public readonly laatstGewijzigdDoor?: GebruikerMetadata
  ) {}

  static fromJson(json: any): MetadataGegevens {
    return new MetadataGegevens(
      Autorisatiegegevens.fromJson(json.autorisatiegegevens),
      GebruikerMetadata.fromJson(json.laatstGewijzigdDoor)
    );
  }
}
