import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";

export class Bestanden {
  static getType = (): VraagType => VraagType.bestanden;

  static create = (vraag: Vraag, readOnly = false): React.JSX.Element => (
    <FormulierInputControls.Files vraag={vraag} readOnly={readOnly} />
  );
}
