import { jsonHelpers } from "helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class SloopmeldingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public geenSloopmeldingNodigBijSanerenAsbesthoudendeGrond: Tekst,
    public sloopmeldingsplicht: Meerkeuzevraag,
    public sloopmeldingBijBevoegdGezag: Meerkeuzevraag,
    public datumSloopmelding: Vraag,
    public codeSloopmelding: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): SloopmeldingVragen {
    return new SloopmeldingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(
        `${key}.geenSloopmeldingNodigBijSanerenAsbesthoudendeGrond`,
        json.geenSloopmeldingNodigBijSanerenAsbesthoudendeGrond
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.sloopmeldingsplicht`, json.sloopmeldingsplicht),
      jsonHelpers.toMeerkeuzeVraag(`${key}.sloopmeldingBijBevoegdGezag`, json.sloopmeldingBijBevoegdGezag),
      jsonHelpers.toDatumVraag(`${key}.datumSloopmelding`, json.datumSloopmelding),
      jsonHelpers.toTekstVraag(`${key}.codeSloopmelding`, json.codeSloopmelding)
    );
  }
}
