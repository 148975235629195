import {
  setZoekMeldingenFailed,
  setZoekMeldingenLoading,
  setZoekMeldingenResult,
  setZoekMeldingenSuccess
} from "../actions/zoek-meldingen-actions";
import { meldingApi } from "../api";
import { getLanguage } from "../helpers";
import { MeldingSortOption } from "../models/api";
import { SearchMeldingenRequest } from "../models/api/zoek";
import { Order } from "../store";
import { AppThunk } from "./app-thunk-type";

export const zoekMeldingen =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setZoekMeldingenLoading(true));

    const state = getState().zoekMeldingen;
    const request = new SearchMeldingenRequest(
      state.filter,
      getLanguage(),
      new MeldingSortOption(state.sortOption.columnName, state.sortOption.order === Order.ascending),
      state.pageNumber,
      state.pageSize
    );

    try {
      const response = await meldingApi.searchMeldingen(request);
      dispatch(setZoekMeldingenResult(response.meldingen, response.totalItemCount));
      dispatch(setZoekMeldingenLoading(false));
      dispatch(setZoekMeldingenSuccess());
    } catch (err) {
      dispatch(setZoekMeldingenLoading(false));
      dispatch(setZoekMeldingenFailed());
    }
  };
