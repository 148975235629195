import { CollectionEventsProvider } from "components/blok/vraag-blokken-collection/";
import { FormulierInputControls } from "components/formulier";
import { translationKeys } from "constants/translation-keys";
import { languageHelpers } from "helpers";
import i18n from "i18next";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Antwoord,
  AntwoordBlok,
  AsbestinventarisatieMateriaalAntwoorden,
  AsbestinventarisatieMateriaalVragen,
  VraagBlok,
  VraagBlokCollection
} from "../../../../models/api";
import { VraagBlokCollectionType } from "../../../../models/application";
import { GeneriekBlok } from "../generiek-vraag-blok.component";

const formatRisicoklasse = (antwoordBlok: AntwoordBlok, vraagBlok: VraagBlok) => {
  return formatAntwoord("risicoklasse", antwoordBlok, vraagBlok);
};

const formatEenheid = (antwoordBlok: AntwoordBlok, vraagBlok: VraagBlok) => {
  return formatAntwoord("eenheid", antwoordBlok, vraagBlok);
};

const formatSoortMateriaal = (antwoordBlok: AntwoordBlok, vraagBlok: VraagBlok) => {
  return formatAntwoord("soortMateriaal", antwoordBlok, vraagBlok);
};

const formatAntwoord = (key: string, antwoordBlok: AntwoordBlok, vraagBlok: VraagBlok) => {
  const antwoorden = antwoordBlok as AsbestinventarisatieMateriaalAntwoorden;
  let antwoord = (antwoorden[key] as Antwoord<string>)?.waarde ?? "";
  if (antwoord) {
    const vragen = vraagBlok as AsbestinventarisatieMateriaalVragen;
    antwoord = languageHelpers.getWaardeFromMeerkeuzevraag(vragen[key].mogelijkeAntwoorden, antwoord, i18n.language);
  }
  return antwoord;
};

export class Materiaal {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.materiaal;

  static createMateriaal = (vraagBlokCollection: VraagBlokCollection<VraagBlok>) => () => {
    const { t } = useTranslation();

    return (
      <Fragment>
        <CollectionEventsProvider>
          <FormulierInputControls.CollectionGrid
            itemComponent={GeneriekBlok.VraagBlok}
            getVraagBlokFromTemplate={AsbestinventarisatieMateriaalVragen.fromJson}
            initializeAntwoorden={AsbestinventarisatieMateriaalAntwoorden.initialize}
            copyAntwoorden={AsbestinventarisatieMateriaalAntwoorden.copyFromJson}
            vraagBlokCollection={vraagBlokCollection}
            getCollectionItemSummary={(antwoordBlok: AntwoordBlok) => {
              const antwoorden = antwoordBlok as AsbestinventarisatieMateriaalAntwoorden;
              return t(translationKeys.blok.asbestinventarisatie.materiaal.samengesteldeWaarde, {
                risicoklasse: antwoorden.risicoklasse?.waarde ?? "-",
                hoeveelheid: antwoorden.hoeveelheid?.waarde ?? "-",
                eenheid: antwoorden.eenheid?.waarde ?? "-",
                soortMateriaal: antwoorden.soortMateriaal?.waarde ?? "-"
              });
            }}
            pagingSettings={{
              numberOfItemsPerPage: 10,
              getTotalsDescription: (counters) =>
                counters.totalItemsInGrid > counters.filteredItemsInGrid
                  ? t(translationKeys.blok.asbestinventarisatie.materiaal.paginering.totaalAantalGefilterd, counters)
                  : t(translationKeys.blok.asbestinventarisatie.materiaal.paginering.totaalAantal, counters)
            }}
            columnSettings={{
              columns: {
                risicoklasse: { columnFormatter: formatRisicoklasse },
                hoeveelheid: {},
                eenheid: { columnFormatter: formatEenheid },
                soortMateriaal: { columnFormatter: formatSoortMateriaal }
              },
              translationBase: translationKeys.blok.asbestinventarisatie.materiaal.tabelkop
            }}
            defaultNewestOnTop={true}
            showSearch={false}
          />
        </CollectionEventsProvider>
      </Fragment>
    );
  };

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): any =>
    React.createElement(connect()(Materiaal.createMateriaal(vraagBlokCollection)));
}
