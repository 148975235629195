import deepcopy from "deepcopy";
import { formulierHelpers, getLanguage, objectHelpers } from "helpers";
import _ from "lodash";
import { Autorisatiegegevens } from "models/api/security/autorisatiegegevens";
import { CaptchaAttributes } from "../constants";
import {
  Antwoord,
  AntwoordBlok,
  AntwoordBlokken,
  AntwoordBlokkenModels,
  HasKey,
  IVertaalbaarAntwoord,
  Melding,
  MeldingBasisgegevens,
  MeldingModels,
  MetadataGegevens,
  SamengesteldeVraag,
  Vraag,
  VraagBlok,
  VraagBlokken
} from "../models/api";
import { Formuliertype } from "../models/application";
import { State } from "../store";

const SECOND_LAST = 2;

const waardeReplaceRegex = /(\.vertaaldeWaarde|\.waarde)/;

const getKeyPartsFromItem = (item: HasKey): [string, string, string, number?] => getKeyPartsFromKey(item.key);

const getKeyPartsFromKey = (key: string): [string, string, string, number?] => {
  const [bloknaam, ...propertyKeyParts] = key.split(".");

  const lastPropertyKeyPart = propertyKeyParts[propertyKeyParts.length - 1];

  const arrayIndex = Number(lastPropertyKeyPart);

  const isArrayIndex = !isNaN(arrayIndex);

  return [
    bloknaam,
    propertyKeyParts.join("."),
    isArrayIndex ? propertyKeyParts[propertyKeyParts.length - SECOND_LAST] : lastPropertyKeyPart,
    isArrayIndex ? arrayIndex : undefined
  ];
};

const getVraagKeyFromFieldName = (name: string): string => name.replace(waardeReplaceRegex, "");

const getFieldNameFromVraag = (vraag: Vraag, useVertaaldeWaarde = false): string =>
  getFieldNameFromVraagKey(vraag.key, useVertaaldeWaarde);

const getFieldNameFromVraagKey = (key: string, useVertaaldeWaarde = false): string => {
  if (key === CaptchaAttributes.Name) {
    return key;
  }

  return `${key}.${useVertaaldeWaarde ? "vertaaldeWaarde" : "waarde"}`;
};

const getVraagBlokForItem = (item: HasKey, vraagBlokken: VraagBlokken): VraagBlok | null => {
  const propertyPath = item.key.split(".");
  propertyPath.pop();

  return objectHelpers.getValue<VraagBlok>(vraagBlokken, propertyPath.join("."));
};

const getAntwoordBlokForItem = (item: HasKey, antwoordBlokken: AntwoordBlokken): AntwoordBlok | null => {
  const propertyPath = item.key.split(".");
  propertyPath.pop();

  return objectHelpers.getValue<AntwoordBlok>(antwoordBlokken, propertyPath.join("."));
};

const getVraagBlok = (item: HasKey, vragen?: VraagBlokken): VraagBlok | null => {
  if (vragen) {
    return (item as any).parent
      ? objectHelpers.getValue<SamengesteldeVraag<any>>(vragen, (item as any).parent.key)?.subVragen
      : getVraagBlokForItem(item, vragen);
  }
  return null;
};

const getAntwoordBlok = (item: HasKey, values: any) => {
  return (item as any).parent
    ? objectHelpers.getValue<AntwoordBlok>(values, (item as any).parent.key)
    : getAntwoordBlokForItem(item, values as AntwoordBlokken);
};

const getAntwoordOrDefault = <TValue>(values: any, item: HasKey, defaultValue: TValue) => {
  return objectHelpers.getValue<Antwoord<TValue>>(values, item.key)?.waarde ?? defaultValue;
};

const hasAntwoord = (values: any, item: HasKey) => {
  const antwoord = getAntwoordOrDefault(values, item, undefined);
  return !_.isEmpty(antwoord) || (_.isNumber(antwoord) && antwoord !== 0);
};

const isIngevoerdDoorInterneMedewerker = (state: State): boolean => {
  return state.melding?.metadata?.autorisatiegegevens?.isInterneMedewerker;
};

const determineIngediendeKunstkinderenMelding = (state: State): boolean => {
  return (
    state.antwoorden.formuliertype === Formuliertype.ontheffingKinderarbeid &&
    state.antwoorden.blokken.werkgever.isIngediend?.waarde === true
  );
};

const resetAntwoordenBijMeldingWijzigen = (
  antwoordBlokken: AntwoordBlokken,
  vraagBlokken: VraagBlokken
): { areAnyAntwoordenReset: boolean; antwoordBlokken: AntwoordBlokken } => {
  let areAnyAntwoordenReset = false;

  const antwoordenBlokkenClone = deepcopy(antwoordBlokken);

  const rootVraagBlokken = formulierHelpers.getRootBlokken(vraagBlokken);

  rootVraagBlokken.forEach((rootVraagBlok) => {
    const vragen = formulierHelpers.getVragen(rootVraagBlok, true);

    const vragenToResetBijMeldingWijzigen = vragen.filter((v) => v.resetBijMeldingWijzigen);

    vragenToResetBijMeldingWijzigen.forEach((vraagToResetBijMeldingWijzigen) => {
      const antwoordKey = getVraagKeyFromFieldName(vraagToResetBijMeldingWijzigen.key);

      objectHelpers.setValue(
        antwoordenBlokkenClone,
        {
          waarde: vraagToResetBijMeldingWijzigen.defaultAntwoord,
          initialValue: vraagToResetBijMeldingWijzigen.defaultAntwoord
        },
        antwoordKey
      );
    });

    areAnyAntwoordenReset ||= vragenToResetBijMeldingWijzigen.length > 0;
  });

  return { areAnyAntwoordenReset, antwoordBlokken: antwoordenBlokkenClone };
};

const hasAnyAntwoordMatchingCondition = (
  vragen: Vraag[],
  antwoordBlokken: AntwoordBlokken,
  condition: (antwoord: Antwoord<any> | IVertaalbaarAntwoord | undefined) => boolean
) => {
  return vragen.some((vraag) =>
    condition(objectHelpers.getValue<Antwoord<any> | IVertaalbaarAntwoord>(antwoordBlokken, vraag.key) ?? undefined)
  );
};

const constructNewMelding = (
  formuliertype: Formuliertype,
  meldingBasisgegevens: MeldingBasisgegevens,
  autorisatiegegevens: Autorisatiegegevens
): Melding => {
  const metadata = new MetadataGegevens(autorisatiegegevens);

  const meldingTypeString: string = `${objectHelpers.toPascalCase(formuliertype)}Melding`;
  const blokkenTypeString: string = `${meldingTypeString}AntwoordBlokken`;

  const blokken = (AntwoordBlokkenModels as any)[blokkenTypeString].initialize();
  const melding = new (MeldingModels as any)[meldingTypeString](
    meldingBasisgegevens,
    blokken,
    getLanguage(),
    undefined,
    metadata
  );

  return melding;
};

const container = {
  getKeyPartsFromKey,
  getKeyPartsFromItem,
  getFieldNameFromVraag,
  getFieldNameFromVraagKey,
  getVraagKeyFromFieldName,
  getVraagBlok,
  getAntwoordBlok,
  getAntwoordOrDefault,
  determineIngediendeKunstkinderenMelding,
  resetAntwoordenBijMeldingWijzigen,
  isIngevoerdDoorInterneMedewerker,
  hasAnyAntwoordMatchingCondition,
  hasAntwoord,
  constructNewMelding
};

export { container as meldingHelpers };

