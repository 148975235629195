import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AnoniemMelderAntwoorden } from "./anoniem-melder-antwoorden";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";

export class KlachtenMelderAntwoorden extends AntwoordBlok {
  constructor(
    public anoniemMelder: AnoniemMelderAntwoorden | null,
    public relatieTovSituatieMelding: Antwoord<string>,
    public registratienummer: Antwoord<string>,
    public andereRelatie: Antwoord<string>,
    public gegevensWerkgeverWerkzaamheden: BedrijfslocatiegegevensAntwoorden | null
  ) {
    super();
  }

  static initialize(user: User | null = null): KlachtenMelderAntwoorden {
    return new KlachtenMelderAntwoorden(
      AnoniemMelderAntwoorden.initialize(user),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize()
    );
  }

  static fromJson(json: any): KlachtenMelderAntwoorden {
    return new KlachtenMelderAntwoorden(
      AnoniemMelderAntwoorden.fromJson(json.anoniemMelder),
      Antwoord.fromJson(json.relatieTovSituatieMelding),
      Antwoord.fromJson(json.registratienummer),
      Antwoord.fromJson(json.andereRelatie),
      BedrijfslocatiegegevensAntwoorden.tryGetFromJson(json.gegevensWerkgeverWerkzaamheden)
    );
  }
}
