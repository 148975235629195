import { connect } from "react-redux";
import { Mededeling } from "../../../models/api";
import { State } from "../../../store";
import { getMededeling, updateMededeling } from "../../../thunks";
import { BeheerMededeling } from "./beheer-mededeling.component";
import { BeheerMededelingDispatchProps, BeheerMededelingStateProps } from "./beheer-mededeling.component.interfaces";

const mapStateToProps = (state: State): BeheerMededelingStateProps => ({
  mededelingen: state.mededeling.mededelingen,
  isLoading: state.mededeling.isLoading,
  hasApiError: state.mededeling.hasApiError,
  user: state.security.user
});

const mapDispatchToProps = (dispatch: any): BeheerMededelingDispatchProps => ({
  onLoad: () => {
    dispatch(getMededeling());
  },
  onSubmit: async (mededelingen: Mededeling[]) => {
    dispatch(updateMededeling(mededelingen));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BeheerMededeling);
