import { translationKeys } from "../../constants/translation-keys";
import { FormulierOpgeslagenBevestiging } from "../formulier-opgeslagen-bevestiging";
import { FormulierVerstuurdProps } from "./formulier-verstuurd.component.interfaces";

export const FormulierVerstuurd = (props: FormulierVerstuurdProps) => {
  return (
    <FormulierOpgeslagenBevestiging
      opgeslagenBevestigingTranslationKey={
        props.isInterneMedewerker
          ? translationKeys["formulier-verstuurd-interne-medewerker"]
          : translationKeys["formulier-verstuurd"]
      }
    />
  );
};
