import { connect } from "react-redux";
import { BiologischeAgentiaFormulier } from "../../../models/api/formulier/biologische-agentia-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getBiologischeAgentiaMelding,
  initBiologischeAgentiaFormulier,
  initBiologischeAgentiaFormulierFromCopy
} from "../../../thunks";
import { BiologischeAgentiaFormulierComponent } from "./biologische-agentia-formulier.component";
import {
  BiologischeAgentiaFormulierComponentDispatchProps,
  BiologischeAgentiaFormulierComponentStateProps
} from "./biologische-agentia-formulier.component.interfaces";

const mapStateToProps = (state: State): BiologischeAgentiaFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.biologischeAgentia
      ? (state.vragen.formulier as BiologischeAgentiaFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): BiologischeAgentiaFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initBiologischeAgentiaFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getBiologischeAgentiaMelding(meldingId));
    } else {
      dispatch(initBiologischeAgentiaFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BiologischeAgentiaFormulierComponent);
