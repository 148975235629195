import { InputValidated } from "components/input-validated";
import { DebouncedFormControl } from "../debounce-input";
import { Field } from "../field";
import { Spinner } from "./../../spinner";

export const InputText = (props: any) => {
  const { name, touched, error, serverError, onBlur, onChange, loading, inputValidated, placeholder, ...propsRest } = props;

  const { inputOnly, label, ...propsControl } = propsRest;

  const isInvalid = (touched && error) || serverError;

  return (
    <Field name={name} {...propsRest}>
      <DebouncedFormControl
        name={name}
        id={name}
        isInvalid={isInvalid}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        {...(props.inputOnly ? { "aria-label": props.label } : {})}
        {...propsControl}
      />
      {loading && <Spinner />}
      {inputValidated && <InputValidated />}
    </Field>
  );
};
