import { Moment } from "moment";
import { dateHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export abstract class IsAgeAtExpression extends VraagExpression {
  constructor(
    public readonly vraagProperty: string,
    protected readonly otherVraagProperty: string,
    protected readonly age: number
  ) {
    super(vraagProperty);
  }

  execute = (
    _vraagBlok: VraagBlok,
    context: ExpressionContext,
    vraagProperty?: string,
    otherVraagProperty?: string
  ): boolean => {
    let result = false;

    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;
    const otherAntwoord = context.getAntwoord(this.otherVraagProperty ?? otherVraagProperty)?.waarde;

    if (antwoord && dateHelpers.isValidDate(antwoord) && otherAntwoord && dateHelpers.isValidDate(otherAntwoord)) {
      const antwoordAsMoment = dateHelpers.getMomentFromDateString(antwoord);
      const otherAntwoordAsMoment = dateHelpers.getMomentFromDateString(otherAntwoord);

      result = this.executeCompare(antwoordAsMoment, otherAntwoordAsMoment);
    }

    return result;
  };

  protected abstract executeCompare(date: Moment, atDate: Moment): boolean;
}
