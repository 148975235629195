import { OngevalFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getOngevalFormulierDefinitie = (formulier: OngevalFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.meldingsplichtToedracht.titel,
    type: ProcesstapType.meldingsplichtToedracht
  },
  {
    title: formulier.blokken.slachtoffers.titel,
    type: ProcesstapType.slachtoffers
  },
  {
    title: formulier.blokken.getuigen.titel,
    type: ProcesstapType.getuigen
  },
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.werkgeverLocatie.titel,
    type: ProcesstapType.werkgeverLocatie
  }
];
