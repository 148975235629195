import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { SlachtofferInformatieVragen } from "./slachtoffer-informatie-vragen";

export class SlachtoffersVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public aantalSlachtoffersGevaarlijkeStoffen: Vraag,
    public aantalSlachtoffers: Vraag,
    public aantalDerdenSlachtoffers: Vraag,
    public gegevensSlachtoffersBekend: Vraag,
    public slachtofferInformatieLijst: VraagBlokCollection<SlachtofferInformatieVragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): SlachtoffersVragen {
    return new SlachtoffersVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toNumeriekeVraag(`${key}.aantalSlachtoffersGevaarlijkeStoffen`, json.aantalSlachtoffersGevaarlijkeStoffen),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalSlachtoffers`, json.aantalSlachtoffers),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalDerdenSlachtoffers`, json.aantalDerdenSlachtoffers),
      jsonHelpers.toMeerkeuzeVraag(`${key}.gegevensSlachtoffersBekend`, json.gegevensSlachtoffersBekend),
      VraagBlokCollection.fromJson<SlachtofferInformatieVragen>(
        SlachtofferInformatieVragen.fromJson,
        `${key}.slachtofferInformatieLijst`,
        json.slachtofferInformatieLijst,
        VraagBlokCollectionType.slachtoffers
      )
    );
  }
}
