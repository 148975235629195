import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { objectHelpers } from "../../../helpers";
import { AntwoordBlok, AntwoordBlokCollection, AntwoordBlokken, VraagBlok } from "../../../models/api";
import { State } from "../../../store";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { VraagBlokCollectionSamenvattingProps } from "./../../blok/vraag-blok-collection-samenvatting/vraag-blok-collection-samenvatting.component.interfaces";
import { WithSamenvattingVraagBlokCollectionProps } from "./with-samenvatting-vraag-blok-collection.interfaces";

export const withSamenvattingVraagBlokCollection = <
  P extends object,
  TVraagBlok extends VraagBlok,
  TAntwoordBlok extends AntwoordBlok
>(
  Component: React.ComponentType<P & VraagBlokCollectionSamenvattingProps<TVraagBlok, TAntwoordBlok>>
) => {
  return (props: P & WithSamenvattingVraagBlokCollectionProps<TVraagBlok>) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(Component as any);

    const { vraag, component, processtap, ...rest } = props;

    const { antwoordBlokken } = useSelector<State, { antwoordBlokken: AntwoordBlokken }>(
      (state: State) => ({
        antwoordBlokken: state.antwoorden.blokken
      }),
      shallowEqual
    );

    const antwoordBlokCollection = objectHelpers.getValue<AntwoordBlokCollection<TAntwoordBlok>>(antwoordBlokken, vraag.key);

    return (
      <SamenvattingVraagComponent
        item={vraag}
        {...(rest as P)}
        component={component}
        processtap={processtap}
        antwoordBlokCollection={antwoordBlokCollection}
        vraagBlokCollection={props.vraag}
      />
    );
  };
};
