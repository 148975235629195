import i18n from "i18next";
import { connect } from "react-redux";
import { cancelIntrekken, cancelWijzigen, setTaalWijzigModeAction } from "../../../actions";
import { formulierHelpers, meldingHelpers, userHelpers } from "../../../helpers";
import { Formuliercategorie, isFormulierTypeMelding, ProcesstapState, ProcesstapType } from "../../../models/application";
import { State, TaalWijzigDialogMode } from "../../../store";
import { meldingBewerken } from "../../../thunks";
import {
  confirmIntrekken,
  confirmWijzigen,
  getDocumenten,
  getZaakLinks,
  koppelZaak,
  meldingIntrekken,
  meldingVertalen
} from "../../../thunks/melding-thunks";
import { meldingKopieren } from "./../../../thunks/melding-thunks";
import { Samenvatting } from "./samenvatting.component";
import {
  SamenvattingDispatchProps,
  SamenvattingOwnProps,
  SamenvattingStateProps
} from "./samenvatting.component.interfaces";

import { wordtGesteld } from "components/formulier/with-conditioneel-gesteld/conditioneel-gesteld-helper";
import { dateHelpers } from "helpers";
import { IVertaalbaarAntwoord, MeldingContext } from "models/api";

const getVertalingInfo = (
  isInterneMedewerker: boolean,
  state: State
): { hasMissendeVertalingen: boolean; hasVertaalbareVragenMetAntwoord: boolean } => {
  if (!isInterneMedewerker || !state.melding?.meldingrechten?.kanVertalen) {
    return { hasMissendeVertalingen: false, hasVertaalbareVragenMetAntwoord: false };
  }

  const vraagBlokken = state.vragen.formulier?.blokken;
  const antwoordBlokken = state.antwoorden?.blokken;

  const vertaalbareVragen = vraagBlokken
    ? formulierHelpers.getVragenWithFilter(vraagBlokken, (v) => v.isAntwoordVertaalbaar)
    : [];

  const hasVertaalbareVragenMetAntwoord = vertaalbareVragen.some(
    (v) =>
      wordtGesteld(
        v,
        state.vragen.formulier!.blokken,
        state.antwoorden.blokken,
        state.security.user,
        state.autorisatie.autorisatiegegevens,
        new MeldingContext(false, state.melding.status)
      ) && meldingHelpers.hasAntwoord(antwoordBlokken, v)
  );

  const hasMissendeVertalingen =
    vertaalbareVragen.length > 0 &&
    !!antwoordBlokken &&
    meldingHelpers.hasAnyAntwoordMatchingCondition(
      vertaalbareVragen,
      antwoordBlokken,
      (a) => a?.waarde && !(a as IVertaalbaarAntwoord).vertaaldeWaarde
    );

  return { hasMissendeVertalingen, hasVertaalbareVragenMetAntwoord };
};

const shouldShowWijzigDialoog = (state: State, formulierCategorie: Formuliercategorie) => {
  return state.melding?.wijzigenRequested === true && state.vragen?.formulier?.categorie === formulierCategorie;
};

const mapStateToProps = (state: State, ownProps: SamenvattingOwnProps): SamenvattingStateProps => {
  const isActive = state.proces.items.some(
    (i) => i.type === ProcesstapType.samenvatting && i.state === ProcesstapState.active
  );
  const isSamenvattingOnlyMode = ownProps.processtappen.every((p) => p.type === ProcesstapType.samenvatting);
  const formuliertype = state.melding?.formuliertype;
  const meldingId = state.melding?.meldingId;
  const isMelding = isFormulierTypeMelding(state.melding?.formuliertype);
  const verzenddatum = dateHelpers.getMomentFromDate(state.melding?.verzenddatum);
  const showSamenvattingButtonbar = isSamenvattingOnlyMode && formuliertype && !!meldingId && isMelding;
  const showSamenvattingMeldingData = !!verzenddatum && isMelding;
  const gekozenTaalAfwijkend = state.melding?.taal.toString().toLowerCase() !== i18n.language;
  const apiError = state.meldingen.apiError;
  const isInterneMedewerker = userHelpers.getIsInternalUser(state.security.user);
  const vertalingInfo = isActive
    ? getVertalingInfo(isInterneMedewerker, state)
    : { hasMissendeVertalingen: false, hasVertaalbareVragenMetAntwoord: false };

  return {
    isActive: isActive,
    isSamenvattingOnlyMode,
    isSubmitting: state.antwoorden.isSubmitting,
    hasSubmitError: state.antwoorden.hasSubmitError,
    submitValidationResults: state.proces.submitValidationResults,
    fileErrors: state.bestanden.fileErrors,
    submitProgress: state.antwoorden.submitProgress,
    formuliertype: formuliertype,
    meldingId: meldingId,
    meldingrechten: state.melding?.meldingrechten,
    meldingnummer: state.melding?.meldingnummer,
    versie: state.melding?.versie,
    datumIngediend: dateHelpers.getMomentFromDate(state.melding?.datumIngediend),
    verzenddatum: verzenddatum,
    datumIntrekking: dateHelpers.getMomentFromDate(state.melding?.datumIntrekking),
    status: state.melding?.status,
    showIntrekkenDialog: state.melding?.intrekkenRequested === true,
    showWijzigenAanvraagDialog: shouldShowWijzigDialoog(state, Formuliercategorie.Aanvraag),
    showWijzigenVerzoekDialog: shouldShowWijzigDialoog(state, Formuliercategorie.Verzoek),
    showDocuments: showSamenvattingMeldingData && !!state.melding?.meldingId && state.security.userLoaded,
    showSamenvattingButtonbar,
    showSamenvattingMeldingData,
    laatstGewijzigdDoor: state.melding?.metadata?.laatstGewijzigdDoor?.volledigeNaam,
    showZaakLinks: isInterneMedewerker,
    zaakLinks: state.melding?.zaakLinks,
    documenten: state.melding?.documenten,
    gekozenTaalAfwijkend,
    showGekozenTaalIsNietGelijkAanOorspronkelijkeTaal:
      isSamenvattingOnlyMode && showSamenvattingButtonbar && gekozenTaalAfwijkend,
    hasMissendeVertalingen: vertalingInfo.hasMissendeVertalingen,
    hasVertaalbareVragenMetAntwoord: vertalingInfo.hasVertaalbareVragenMetAntwoord,
    apiError: apiError
  };
};

const mapDispatchToProps = (dispatch: any): SamenvattingDispatchProps => ({
  onLoad: (fetchZaakLinks: boolean, fetchDocuments: boolean) => {
    if (fetchZaakLinks) {
      dispatch(getZaakLinks());
    }
    if (fetchDocuments) {
      dispatch(getDocumenten());
    }
  },
  onKopieerMelding: () => {
    dispatch(meldingKopieren());
  },
  onIntrekkenMelding: () => {
    dispatch(meldingIntrekken());
  },
  onIntrekkenConfirm: () => {
    dispatch(confirmIntrekken());
  },
  onIntrekkenCancel: () => {
    dispatch(cancelIntrekken());
  },
  onWijzigMelding: (gekozenTaalAfwijkend: boolean) => {
    if (gekozenTaalAfwijkend) {
      dispatch(setTaalWijzigModeAction(i18n.language, TaalWijzigDialogMode.FormulierTaalNietTeWijzigenBestaandeMelding));
    } else {
      dispatch(meldingBewerken());
    }
  },
  onWijzigenConfirm: () => {
    dispatch(confirmWijzigen());
  },
  onWijzigenCancel: () => {
    dispatch(cancelWijzigen());
  },
  onVertaalMelding: () => {
    dispatch(meldingVertalen());
  },
  onKoppelZaak: () => {
    dispatch(koppelZaak());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Samenvatting);
