import {
  clearMelding,
  getFormulier,
  initAntwoorden,
  initProces,
  resetFormulier,
  setAutorisatiegegevens,
  setBestanden
} from "actions";
import { processtapHelpers } from "helpers";
import i18n from "i18next";
import { AntwoordBlokken, Formulier, KennisgevingMeldingAntwoordBlokken, MelderAntwoorden } from "models/api";
import { Autorisatiegegevens } from "models/api/security/autorisatiegegevens";
import { BlokDefinitie, Formuliertype } from "models/application";

import { User } from "models/api/security/user";
import { Action } from "redux";
import { push, replace } from "redux-first-history";
import { ThunkDispatch } from "redux-thunk";
import { State } from "store";
import { AppThunk } from "thunks/app-thunk-type";
import { checkProfileComplete } from "thunks/security-thunks";
import { initializeVestigingenForFormulier } from "./vestiging-thunk-helper";

export const initKennisgevingMeldingAntwoordenFromCopy = (
  blokken: KennisgevingMeldingAntwoordBlokken
): ((user: User | null) => KennisgevingMeldingAntwoordBlokken) => {
  return (user: User | null) => ({
    ...blokken,
    melder: MelderAntwoorden.initialize(user)
  });
};

const initializeFormulier =
  <TFormulier extends Formulier>(
    formulier: TFormulier,
    initAntwoordBlokken: (user: User | null) => AntwoordBlokken
  ): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const user = getState().security.user;
    dispatch(resetFormulier());
    dispatch(getFormulier(formulier));
    dispatch(initAntwoorden(initAntwoordBlokken(user), formulier.type));
  };

export const initializeFormulierProces = async <TFormulier extends Formulier>(
  dispatch: ThunkDispatch<State, null, Action<string>>,
  getState: () => State,
  formulier: TFormulier,
  getFormulierDefinitie: (formulier: TFormulier) => BlokDefinitie[],
  initAntwoordBlokken: (user: User | null) => AntwoordBlokken
) => {
  // Check of profiel compleet is, zo niet moet de gebruiker zijn profiel eerst aanvullen
  await checkProfileComplete(dispatch, getState);

  const state = getState();
  const user = state.security.user;

  const profileIsComplete = state.security.profileIsComplete;

  if (formulier.ondersteundeTalen.every((ot) => ot.toString() !== i18n.language.toLocaleUpperCase())) {
    // taal wordt niet ondersteund door het formulier
    dispatch(replace("/formuliertaalnietbeschikbaar"));
  } else if (
    formulier.type === Formuliertype.profiel ||
    formulier.type === Formuliertype.autorisatiesZoeken ||
    user === null ||
    profileIsComplete
  ) {
    dispatch(clearMelding());
    dispatch(setBestanden([]));

    dispatch(setAutorisatiegegevens(Autorisatiegegevens.initialize(user)));

    dispatch(initializeFormulier(formulier, initAntwoordBlokken));
    dispatch(initializeVestigingenForFormulier(formulier, getState().antwoorden.blokken));

    dispatch(initProces(processtapHelpers.create(getFormulierDefinitie(formulier))));
  } else {
    dispatch(push("/profiel/wijzigen"));
  }
};
