import { UserActivityState } from "../store";
import { SharedActions, UserActivityActions, SET_USER_IDLE, SET_USER_ACTIVE } from "../actions";

const initialState: UserActivityState = {
  isUserIdle: false
};

const userActivityReducer = (
  state: UserActivityState = initialState,
  action: UserActivityActions | SharedActions
): UserActivityState => {
  switch (action.type) {
    case SET_USER_IDLE:
      return { ...state, isUserIdle: true };
    case SET_USER_ACTIVE:
      return { ...state, isUserIdle: false };
    default:
      return state;
  }
};

export default userActivityReducer;
