import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { KindGegevensAntwoorden } from "./kind-gegevens-antwoorden";

export class KinderenAntwoorden extends AntwoordBlok {
  constructor(public kinderenGegevensLijst: AntwoordBlokCollection<KindGegevensAntwoorden> | null) {
    super();
  }

  static initialize(): KinderenAntwoorden {
    return new KinderenAntwoorden(AntwoordBlokCollection.initialize([]));
  }

  static fromJson(json: any): KinderenAntwoorden {
    return new KinderenAntwoorden(
      AntwoordBlokCollection.fromJson(KindGegevensAntwoorden.fromJson, json.kinderenGegevensLijst)
    );
  }
}
