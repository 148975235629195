import { AntwoordBlokken, UploadAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasUploadAntwoorden } from "./has-upload-antwoorden";

export class UploadGeneriekMeldingAntwoordBlokken extends AntwoordBlokken implements HasUploadAntwoorden {
  constructor(public upload: UploadAntwoorden) {
    super();
  }

  static initialize(user: User | null = null): UploadGeneriekMeldingAntwoordBlokken {
    return new UploadGeneriekMeldingAntwoordBlokken(UploadAntwoorden.initialize(user));
  }

  static fromJson(json: any): UploadGeneriekMeldingAntwoordBlokken {
    return new UploadGeneriekMeldingAntwoordBlokken(UploadAntwoorden.fromJson(json.upload));
  }
}
