import {
  AntwoordBlokken,
  InleidingNachtarbeidAntwoorden,
  OmstandighedenNachtarbeidAntwoorden,
  OntheffingNachtarbeidWerkgeverAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasInleidingNachtarbeidAntwoorden } from "./has-inleiding-nachtarbeid-antwoorden";
import { HasOmstandighedenNachtarbeidAntwoorden } from "./has-omstandigheden-nachtarbeid-antwoorden";
import { HasOntheffingNachtarbeidWerkgeverAntwoorden } from "./has-ontheffing-nachtarbeid-werkgever-antwoorden";

export class OntheffingNachtarbeidMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasInleidingNachtarbeidAntwoorden,
    HasOntheffingNachtarbeidWerkgeverAntwoorden,
    HasOmstandighedenNachtarbeidAntwoorden
{
  constructor(
    public inleiding: InleidingNachtarbeidAntwoorden,
    public werkgever: OntheffingNachtarbeidWerkgeverAntwoorden,
    public omstandigheden: OmstandighedenNachtarbeidAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): OntheffingNachtarbeidMeldingAntwoordBlokken {
    return new OntheffingNachtarbeidMeldingAntwoordBlokken(
      InleidingNachtarbeidAntwoorden.initialize(user),
      OntheffingNachtarbeidWerkgeverAntwoorden.initialize(),
      OmstandighedenNachtarbeidAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OntheffingNachtarbeidMeldingAntwoordBlokken {
    return new OntheffingNachtarbeidMeldingAntwoordBlokken(
      InleidingNachtarbeidAntwoorden.fromJson(json.inleiding),
      OntheffingNachtarbeidWerkgeverAntwoorden.fromJson(json.werkgever),
      OmstandighedenNachtarbeidAntwoorden.fromJson(json.omstandigheden)
    );
  }
}
