export const SET_SEARCH = "SET_SEARCH";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

interface SetSearchAction {
  type: typeof SET_SEARCH;
  searchValue: string;
  searchKey: string;
}

interface ClearSearchAction {
  type: typeof CLEAR_SEARCH;
  searchKey: string;
}

export const setSearch = (searchKey: string, searchValue: string): SetSearchAction => {
  return {
    type: SET_SEARCH,
    searchValue: searchValue,
    searchKey: searchKey
  };
};

export const clearSearch = (searchKey: string): ClearSearchAction => {
  return {
    type: CLEAR_SEARCH,
    searchKey: searchKey
  };
};

/** Export all actions */
export type SearchActions = SetSearchAction | ClearSearchAction;
