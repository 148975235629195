import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { MijnMeldingenBreadcrumbs } from "../../breadcrumbs";
import { Spinner } from "../../spinner";
import { VisibleWrapper } from "../../visible-wrapper";
import { MeldingGrid } from "../melding-grid";
import { MijnMeldingenZoekbalk } from "../mijn-meldingen-zoekbalk";
import { MijnMeldingenProps } from "./mijn-meldingen.component.interfaces";

export const MijnMeldingen = (props: MijnMeldingenProps) => {
  const { t } = useTranslation();

  const { onLoad, sortOption, pageNumber } = props;

  useEffect(() => {
    onLoad(sortOption, pageNumber);
  }, [onLoad, sortOption, pageNumber]);

  return (
    <VisibleWrapper isVisible={props.isUserLoaded}>
      <Row className="main-container" role="main">
        <Col xs={12} className="main-container-title">
          <MijnMeldingenBreadcrumbs />
          <h1>{t(translationKeys.melding.mijnMeldingen.titel)}</h1>
          <h2>{t(translationKeys.melding.mijnMeldingen.subTitel)}</h2>
          <MijnMeldingenZoekbalk />
          {props.isLoading ? <Spinner>{t(translationKeys.bezigMetLaden)}</Spinner> : null}
          {props.hasApiError ? <Alert variant="danger">{t(translationKeys.oepsErGingIetsFout)}</Alert> : null}
          {!props.isLoading && !props.hasApiError && props.meldingen.length === 0 ? (
            <div>{t(translationKeys.melding.meldingen.geenResultaten)}</div>
          ) : null}
          {!props.isLoading && !props.hasApiError && props.meldingen.length > 0 ? (
            <MeldingGrid
              columns={[
                "meldingnummer",
                "vestigingsnummer",
                "formulier",
                "aangemaaktOp",
                "gewijzigdOp",
                "gewijzigdDoor",
                "locatiePlaats",
                "aanvang",
                "status",
                "contactpersoon"
              ]}
              meldingen={props.meldingen}
              onSearchOptionChange={props.onSearchOptionChange}
              pageNumber={props.pageNumber}
              pageSize={props.pageSize}
              sortOption={props.sortOption}
              totaalAantalMeldingen={props.totaalAantalMeldingen}
            />
          ) : null}
        </Col>
      </Row>
    </VisibleWrapper>
  );
};
