import { ReadOnly } from "./read-only";
import { Text } from "./text";
import { Title } from "./title";

const container = {
  ReadOnly,
  Text,
  Title
};

export { container as ReadOnlyControls };
