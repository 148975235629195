import { useEffect } from "react";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { CbiMelding, CbiMeldingAntwoordBlokken, MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { CbiFormulierComponentProps } from "./cbi-formulier.component.interfaces";
import { useParams } from "react-router-dom";

export const CbiFormulierComponent = (props: CbiFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.cbi;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const formulier = props.formulier;
    const blokDefinitie = FormulierDefinitie.getCbiFormulierDefinitie(formulier);

    return (
      <Formulier
        type={Formuliertype.cbi}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(CbiMelding)}
        getEmailadres={(blokken: CbiMeldingAntwoordBlokken) => blokken.melder.common?.melder?.emailadres.waarde}
      ></Formulier>
    );
  } else {
    return null;
  }
};
