import React, { useContext, useMemo } from "react";

type DebounceProviderProps = {
  children: React.ReactNode;
  debounceWait?: number;
  debounceEnabled?: boolean;
};

export interface DebounceInputProps {
  debounceEnabled: boolean;
  debounceWait?: number;
}

const defaultDebounce: DebounceInputProps = {
  debounceEnabled: false,
  debounceWait: 300
};

const DebounceInputContext = React.createContext<DebounceInputProps>(defaultDebounce);

const DebounceProvider = (props: DebounceProviderProps) => {
  const { debounceEnabled, debounceWait } = props;

  const value = useMemo(() => {
    return {
      debounceEnabled: debounceEnabled === undefined ? true : debounceEnabled,
      debounceWait: debounceWait
    };
  }, [debounceEnabled, debounceWait]);

  return <DebounceInputContext.Provider value={value}>{props.children}</DebounceInputContext.Provider>;
};

const useDebounceInputContext = (): DebounceInputProps => {
  return useContext(DebounceInputContext);
};

export { DebounceProvider, useDebounceInputContext };
