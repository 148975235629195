import { formulierApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { AutorisatieaanvraagMeldingAntwoordBlokken } from "models/api";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initAutorisatieaanvraagFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getAutorisatieaanvraagFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getAutorisatieaanvraagFormulierDefinitie,
      AutorisatieaanvraagMeldingAntwoordBlokken.initialize
    );
  };
