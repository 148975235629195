import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";

export class OpdrachtgeverVuurOpTankschipAntwoorden extends AntwoordBlok {
  constructor(public gegevensGelijk: Antwoord<string>, public opdrachtgever: OpdrachtgeverAntwoorden | null) {
    super();
  }

  static initialize(): OpdrachtgeverVuurOpTankschipAntwoorden {
    return new OpdrachtgeverVuurOpTankschipAntwoorden(new Antwoord<string>(""), OpdrachtgeverAntwoorden.initialize());
  }

  static fromJson(json: any): OpdrachtgeverVuurOpTankschipAntwoorden {
    return new OpdrachtgeverVuurOpTankschipAntwoorden(
      Antwoord.fromJson<string>(json.gegevensGelijk),
      OpdrachtgeverAntwoorden.fromJson(json.opdrachtgever)
    );
  }
}
