import React from "react";
import { FormulierInputControls } from "../../formulier";
import { AutorisatiesZoekenProps } from "./autorisaties-zoeken.component.interfaces";

export const AutorisatiesZoeken = (props: AutorisatiesZoekenProps) => {
  return (
    <section>
      <FormulierInputControls.Text vraag={props.filter.bedrijfsnaam} />
      <FormulierInputControls.Text vraag={props.filter.gebruikersnaam} />
      <FormulierInputControls.Text vraag={props.filter.achternaam} />
      <FormulierInputControls.Text vraag={props.filter.kvkNummer} />
      <FormulierInputControls.Text vraag={props.filter.vestigingsnummer} />
    </section>
  );
};
