import { Expression } from "./expression";

export class True extends Expression {
  get operator(): string {
    return True.operator();
  }

  execute = (): boolean => true;

  static fromJson = (): True => new True();

  static operator = (): string => "true";

  static matches = (json: any): boolean => json?.operator === True.operator();
}
