import { AntwoordBlok } from "./antwoord-blok";
import { AutorisatieGeldigheidAntwoorden } from "./autorisatie-geldigheid-antwoorden";
import { ProfielAntwoorden } from "./profiel-antwoorden";

export class AutorisatieProfielAntwoorden extends AntwoordBlok {
  constructor(public autorisatieProfiel: ProfielAntwoorden, public autorisatieGeldigheid: AutorisatieGeldigheidAntwoorden) {
    super();
  }

  static fromJson(json: any): AutorisatieProfielAntwoorden {
    return new AutorisatieProfielAntwoorden(
      ProfielAntwoorden.fromJson(json.autorisatieProfiel),
      AutorisatieGeldigheidAntwoorden.fromJson(json.autorisatieGeldigheid)
    );
  }
}
