import { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Help } from "../../help";

const HelpToggle = (props: { name: string; toelichting: string; helpToggleLabel: string | null }) => {
  return (
    <Fragment>
      {props.toelichting && (
        <Help.HelpToggle
          className="help-toggle toelichting"
          key={`${props.name}--help-toggle`}
          id={`${props.name}-help-toggle`}
          aria-label={props.helpToggleLabel}
        ></Help.HelpToggle>
      )}
    </Fragment>
  );
};

const Toelichting = (props: { name: string; toelichting: string }) => {
  return (
    <Fragment>
      {props.toelichting && (
        <Help.HelpText
          key={`${props.name}--help-text`}
          helptekst={props.toelichting}
          id={`${props.name}-help`}
        ></Help.HelpText>
      )}
    </Fragment>
  );
};

export const Text = (props: any) => {
  const { t } = useTranslation();
  const { id, text, toelichting } = props;

  const markdownClassName = `${id}--markdown-with-help-toggle`;
  const helpToggleId = `${id}-help-toggle`;
  const helpToggleLabel = toelichting ? t("help-toggle", { naam: id }) : null;

  useEffect(() => {
    if (toelichting) {
      const p = document.getElementsByClassName(markdownClassName)[0].lastChild;
      const toggle = document.getElementById(helpToggleId);
      if (p !== null && toggle !== null) {
        p.appendChild(toggle);
      }
    }
  }, [toelichting, markdownClassName, helpToggleId]);

  return (
    <div id={id} className={`explanation ${props.className}`}>
      <Help.Container key={`${id}--help`}>
        <ReactMarkdown children={text} className={markdownClassName} />
        <HelpToggle name={id} toelichting={toelichting} helpToggleLabel={helpToggleLabel} />
        <div className="clearfix"></div>
        <Toelichting name={id} toelichting={toelichting} />
      </Help.Container>
    </div>
  );
};
