import { HelpContainer } from "./help-container.component";
import { HelpToggle } from "./help-toggle.component";
import { HelpText } from "./help-text.component";

const container = {
  Container: HelpContainer,
  HelpToggle: HelpToggle,
  HelpText: HelpText
};

export { container as Help };
