import { Vraag } from "../vraag";
import { Dictionary } from "../../application";
import { jsonHelpers } from "../../../helpers";
import { VraagBlok } from "../vraag-blok";

export class KennisgevingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public omschrijving: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): KennisgevingVragen {
    return new KennisgevingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijving`, json.omschrijving)
    );
  }
}
