import {
  CLEAR_SEARCH,
  NavigationActions,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SearchActions,
  SET_SEARCH,
  SharedActions
} from "../actions";
import { SearchState } from "../store";

const initialState: SearchState = {
  searches: {}
};

const searchReducer = (
  state: SearchState = initialState,
  action: SearchActions | SharedActions | NavigationActions
): SearchState => {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM:
      return initialState;
    case SET_SEARCH:
      const searchesCopy = { ...state.searches };
      searchesCopy[action.searchKey] = {
        value: action.searchValue,
        timestamp: Date.now()
      };
      return { ...state, searches: { ...searchesCopy } };
    case CLEAR_SEARCH:
      const searchesClearCopy = { ...state.searches };
      delete searchesClearCopy[action.searchKey];
      return { ...state, searches: { ...searchesClearCopy } };
    default:
      return state;
  }
};

export default searchReducer;
