import React, { useMemo } from "react";

export interface CollectionItemCanBeRemovedResult {
  canBeRemoved: boolean;
  reason?: string;
}

export interface CollectionItemCanBeCopiedResult {
  canBeCopied: boolean;
  reason?: string;
}

interface CollectionEvents {
  canBeRemoved?: (collectionItemKey: string, collectionItemIndex: number) => CollectionItemCanBeRemovedResult | undefined;
  canBeCopied?: (
    collectionItemKey: string,
    collectionItemIndex: number,
    collectionItemValue?: any
  ) => CollectionItemCanBeCopiedResult | undefined;
}

interface CollectionEventsProviderProps extends CollectionEvents {
  children: React.ReactNode;
}

const defaultEventsContext: CollectionEvents = {
  canBeRemoved: () => ({ canBeRemoved: true }),
  canBeCopied: () => ({ canBeCopied: true })
};

const CollectionEventsContext = React.createContext<CollectionEvents>(defaultEventsContext);

const CollectionEventsProvider = (props: CollectionEventsProviderProps) => {
  const { children, ...restProps } = props;
  const providerValue = useMemo(() => {
    return { ...defaultEventsContext, ...restProps };
  }, [restProps]);

  return <CollectionEventsContext.Provider value={providerValue}>{props.children}</CollectionEventsContext.Provider>;
};

const useCollectionEvents = () => {
  return React.useContext(CollectionEventsContext);
};

export { CollectionEventsProvider, useCollectionEvents };
