import { Spinner } from "components/spinner";
import { VisibleWrapper } from "components/visible-wrapper";
import { Configuration } from "configuration";
import { ExternReferentienummerType, Ontvangstwijze } from "models/api/melding";
import { ExterneReferentie, Formuliercategorie, Formuliertype } from "models/application";
import { useEffect } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { translationKeys } from "../../../constants/translation-keys";
import { ToevoegenBreadcrumbs } from "../../breadcrumbs";
import { ToevoegenProps } from "./toevoegen.component.interfaces";

export const Toevoegen = (props: ToevoegenProps) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { onLoad } = props;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const [searchParams] = useSearchParams();
  const datumIngediend = searchParams.get("ontvangstdatum");
  const externReferentienummer = searchParams.get("externReferentienummer");
  const externReferentienummerType = searchParams.get("externReferentienummerType");
  const ontvangstwijze = searchParams.get("ontvangstwijze");

  props.formulierTypes.sort(function (a, b) {
    return a.titel[lang].localeCompare(b.titel[lang]);
  });

  const meldingen = props.formulierTypes.filter((ft) => {
    return Configuration.meldingToevoegenButtons.meldingen.some(
      (config: string) => config.toLowerCase() === ft.formuliertype.toLowerCase()
    );
  });
  const aanvragen = props.formulierTypes.filter((ft) => {
    return Configuration.meldingToevoegenButtons.aanvragen.some(
      (config: string) => config.toLowerCase() === ft.formuliertype.toLowerCase()
    );
  });
  const verzoeken = props.formulierTypes.filter((ft) => {
    return Configuration.meldingToevoegenButtons.verzoeken.some(
      (config: string) => config.toLowerCase() === ft.formuliertype.toLowerCase()
    );
  });

  const formulierDisabled = (categorie?: Formuliercategorie) =>
    !!datumIngediend && categorie === Formuliercategorie.Kennisgeving;

  const onFormulierClick = (formuliertype: Formuliertype) => {
    if (shouldCreateConceptMelding()) {
      props.startConceptMelding(
        formuliertype,
        new ExterneReferentie(
          externReferentienummer!,
          ExternReferentienummerType[externReferentienummerType as keyof typeof ExternReferentienummerType],
          Ontvangstwijze[ontvangstwijze as keyof typeof Ontvangstwijze],
          datumIngediend ? new Date(datumIngediend) : undefined
        )
      );
    } else {
      props.openFormulier(formuliertype);
    }
  };

  const shouldCreateConceptMelding = (): boolean =>
    !!datumIngediend && !!externReferentienummer && !!externReferentienummerType && !!ontvangstwijze;

  return (
    <VisibleWrapper isVisible={props.isInterneMedewerker}>
      <Row className="main-container" role="main">
        <Col xs={12} className="main-container-title">
          <ToevoegenBreadcrumbs />
          <h1>{t(translationKeys.melding.toevoegen.titel)}</h1>
          {props.isSubmitting && <Spinner>{t(translationKeys.bezigMetLaden)}</Spinner>}
          {props.hasSubmitError && <Alert variant="danger">{t(translationKeys.oepsErGingIetsFout)}</Alert>}
          <Row>
            <Col xs={12} md={4}>
              <h2>{t(translationKeys.melding.toevoegen.meldingen)}</h2>
              {meldingen.map((ft) => (
                <Button
                  onClick={() => onFormulierClick(ft.formuliertype)}
                  key={ft.formuliertype}
                  role="button"
                  className="button-toevoegen"
                  disabled={formulierDisabled(ft.formuliercategorie)}
                  data-test={ft.formuliertype}
                >
                  {ft.titel[lang]}
                </Button>
              ))}
            </Col>
            <Col xs={12} md={4}>
              <h2>{t(translationKeys.melding.toevoegen.aanvragen)}</h2>
              {aanvragen.map((ft) => (
                <Button
                  onClick={() => onFormulierClick(ft.formuliertype)}
                  key={ft.formuliertype}
                  role="button"
                  className="button-toevoegen"
                  disabled={formulierDisabled(ft.formuliercategorie)}
                  data-test={ft.formuliertype}
                >
                  {ft.titel[lang]}
                </Button>
              ))}
            </Col>
            <Col xs={12} md={4}>
              <h2>{t(translationKeys.melding.toevoegen.verzoeken)}</h2>
              {verzoeken.map((ft) => (
                <Button
                  onClick={() => onFormulierClick(ft.formuliertype)}
                  key={ft.formuliertype}
                  role="button"
                  className="button-toevoegen"
                  disabled={formulierDisabled(ft.formuliercategorie)}
                  data-test={ft.formuliertype}
                >
                  {ft.titel[lang]}
                </Button>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </VisibleWrapper>
  );
};
