import { Formuliertype } from "../../application";
import { AsbestRisicoklasse1FormulierVraagBlokken } from "./asbest-risicoklasse1-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AsbestRisicoklasse1Formulier extends FormulierBase {
  constructor(public blokken: AsbestRisicoklasse1FormulierVraagBlokken, json: any) {
    super(Formuliertype.asbestRisicoklasse1, blokken, json);
  }

  static fromJson(json: any): AsbestRisicoklasse1Formulier {
    return new AsbestRisicoklasse1Formulier(AsbestRisicoklasse1FormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
