import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class AanleveringLavsV2Antwoorden extends AntwoordBlok {
  constructor(
    public meldingnummer: Antwoord<string>,
    public opdrachtnummer: Antwoord<string>,
    public projectnummer: Antwoord<string>,
    public meldingstatus: Antwoord<string>,
    public datumGemeld: Antwoord<string>,
    public tussentijdseMelding: Antwoord<string>,
    public inventarisatieOpdrachtnummer: Antwoord<string>,
    public typeAsbestSanering: Antwoord<string>,
    public saneringDoorGecertificeerdBedrijf: Antwoord<string>
  ) {
    super();
  }

  static initialize() {
    return new AanleveringLavsV2Antwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new AanleveringLavsV2Antwoorden(
      Antwoord.fromJson(json.meldingnummer),
      Antwoord.fromJson(json.opdrachtnummer),
      Antwoord.fromJson(json.projectnummer),
      Antwoord.fromJson(json.meldingstatus),
      Antwoord.fromJson(json.datumGemeld),
      Antwoord.fromJson(json.tussentijdseMelding),
      Antwoord.fromJson(json.inventarisatieOpdrachtnummer),
      Antwoord.fromJson(json.typeAsbestSanering),
      Antwoord.fromJson(json.saneringDoorGecertificeerdBedrijf)
    );
  }
}
