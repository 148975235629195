import {
  CLEAR_VRAAGBLOK_FROM_COLLECTION,
  NavigationActions,
  NederlandsAdresActions,
  REMOVE_VRAAGBLOK_FROM_COLLECTION,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SET_ADRESSEN,
  SET_ADRESSEN_ISLOADING,
  SET_PLAATSEN,
  SET_PLAATSEN_ISLOADING,
  SharedActions,
  VragenActions
} from "../../actions";
import { reducerHelper } from "../../helpers";
import { NederlandsAdresState } from "../../store";

const initialState: NederlandsAdresState = {
  plaatsenIsLoading: {},
  plaatsen: {},
  adressenIsLoading: {},
  adressen: {}
};

const nederlandsAdresReducer = (
  state: NederlandsAdresState = initialState,
  action: NederlandsAdresActions | VragenActions | SharedActions | NavigationActions
): NederlandsAdresState => {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM:
      return initialState;
    case SET_PLAATSEN_ISLOADING:
      const plaatsenIsLoadingCopy = { ...state.plaatsenIsLoading };

      plaatsenIsLoadingCopy[action.key] = action.data;

      return { ...state, plaatsenIsLoading: { ...plaatsenIsLoadingCopy } };
    case SET_PLAATSEN:
      const plaatsenCopy = { ...state.plaatsen };

      plaatsenCopy[action.key] = action.data;

      return { ...state, plaatsen: { ...plaatsenCopy } };
    case SET_ADRESSEN_ISLOADING:
      const adressenIsLoadingCopy = { ...state.adressenIsLoading };

      adressenIsLoadingCopy[action.key] = action.data;

      return { ...state, adressenIsLoading: { ...adressenIsLoadingCopy } };
    case SET_ADRESSEN:
      const adressenCopy = { ...state.adressen };

      adressenCopy[action.key] = action.data;

      return { ...state, adressen: { ...adressenCopy } };
    case REMOVE_VRAAGBLOK_FROM_COLLECTION: {
      const removeAdressenCopy = { ...state.adressen };
      reducerHelper.reindexCollection(removeAdressenCopy, action.collectionIndex, `${action.collectionKey}.list`);

      const removePlaatsenCopy = { ...state.plaatsen };
      reducerHelper.reindexCollection(removePlaatsenCopy, action.collectionIndex, `${action.collectionKey}.list`);

      return {
        ...state,
        adressen: removeAdressenCopy,
        plaatsen: removePlaatsenCopy
      };
    }
    case CLEAR_VRAAGBLOK_FROM_COLLECTION:
      const clearAdressenCopy = { ...state.adressen };
      const clearPlaatsenCopy = { ...state.plaatsen };

      reducerHelper.clearCollection(clearAdressenCopy, action.collectionKey);
      reducerHelper.clearCollection(clearPlaatsenCopy, action.collectionKey);

      return {
        ...state,
        adressen: clearAdressenCopy,
        plaatsen: clearPlaatsenCopy
      };
    default:
      return state;
  }
};

export default nederlandsAdresReducer;
