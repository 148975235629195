import { connect } from "react-redux";
import { KlachtFormulier } from "../../../models/api/formulier/klacht-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getKlachtformulierMelding, initKlachtFormulier, initKlachtFormulierFromCopy } from "../../../thunks";
import { KlachtFormulierComponent } from "./klacht-formulier.component";
import {
  KlachtFormulierComponentDispatchProps,
  KlachtFormulierComponentStateProps
} from "./klacht-formulier.component.interfaces";

const mapStateToProps = (state: State): KlachtFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.klacht ? (state.vragen.formulier as KlachtFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): KlachtFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initKlachtFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getKlachtformulierMelding(meldingId));
    } else {
      dispatch(initKlachtFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(KlachtFormulierComponent);
