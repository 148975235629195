import { Berekend } from "./berekend";
import { BestandImport } from "./bestand-import";
import { Bestanden } from "./bestanden";
import { Buitenland } from "./buitenland";
import { CollectionTypeahead } from "./collection-typeahead";
import { Datum } from "./datum";
import { GebrokenAantal } from "./gebroken-aantal";
import { GelinkteMeerkeuze } from "./gelinkte-meerkeuze";
import { GelinkteMeerkeuzeMeerdereAntwoordmogelijkheden } from "./gelinkte-meerkeuze-meerdere-antwoordmogelijkheden";
import { Land } from "./land";
import { MeerkeuzeEenAntwoordmogelijkheid } from "./meerkeuze-een-antwoordmogelijkheid";
import { MeerkeuzeMeerdereAntwoordmogelijkheden } from "./meerkeuze-meerdere-antwoordmogelijkheden";
import { Numeriek } from "./numeriek";
import { Tekst } from "./tekst";
import { TekstMeerdereRegels } from "./tekst-meerdere-regels";
import { Tijd } from "./tijd";

const vraagComponenten = {
  Berekend,
  Bestanden,
  BestandImport,
  Buitenland,
  CollectionTypeahead,
  Datum,
  Land,
  GelinkteMeerkeuze,
  GelinkteMeerkeuzeMeerdereAntwoordmogelijkheden,
  MeerkeuzeEenAntwoordmogelijkheid,
  MeerkeuzeMeerdereAntwoordmogelijkheden,
  Numeriek,
  GebrokenAantal,
  TekstMeerdereRegels,
  Tekst,
  Tijd
};

export { vraagComponenten as VraagComponenten };
