import { AdresVragenDisplayDefinition } from "./adres-vragen-display-definition";
import { InputPurpose } from "./input-purpose";
import { VraagDisplayDefinition } from "./vraag-display-definition";

export class CorrespondentieAdresVragenDisplayDefinition {
  public land: VraagDisplayDefinition = {
    inputPurpose: InputPurpose.countryName
  };

  public nederlandsAdres = new AdresVragenDisplayDefinition();

  public buitenlandsAdres = new AdresVragenDisplayDefinition();
}
