import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, InformatieverzoekMeldingAntwoordBlokken, MelderAntwoorden } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initInformatieverzoekFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getInformatieverzoekFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getInformatieverzoekFormulierDefinitie,
      InformatieverzoekMeldingAntwoordBlokken.initialize
    );
  };

export const initInformatieverzoekFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getInformatieverzoekFormulier(),
      meldingApi.getInformatieverzoekMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): InformatieverzoekMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: {
          ...melding.blokken.melder,
          common: MelderAntwoorden.initialize(user)
        },
        bijlage: {
          ...melding.blokken.bijlage,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getInformatieverzoekFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
