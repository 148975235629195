import { AndereWerkgeverVragen, Meerkeuzevraag, Tekst, VraagBlokCollection } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { BedrijfslocatiegegevensVragen } from "./bedrijfslocatiegegevens-vragen";
import { ContactpersoonVragen } from "./contactpersoon-vragen";

export class OntheffingNachtarbeidWerkgeverVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public werkgever: BedrijfslocatiegegevensVragen,
    public contactpersoonTitel: Tekst,
    public contactpersoon: ContactpersoonVragen,
    public werkzaamOpHoofdlocatie: Meerkeuzevraag,
    public werklocatieTitel: Tekst,
    public werklocatie: BedrijfslocatiegegevensVragen,
    public andereWerkgevers: VraagBlokCollection<AndereWerkgeverVragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): OntheffingNachtarbeidWerkgeverVragen {
    return new OntheffingNachtarbeidWerkgeverVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      BedrijfslocatiegegevensVragen.fromJson(`${key}.werkgever`, json.werkgever),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon),
      jsonHelpers.toMeerkeuzeVraag(`${key}.werkzaamOpHoofdlocatie`, json.werkzaamOpHoofdlocatie),
      jsonHelpers.toTitel(`${key}.werklocatieTitel`, json.werklocatieTitel),
      BedrijfslocatiegegevensVragen.fromJson(`${key}.werklocatie`, json.werklocatie),
      VraagBlokCollection.fromJson<AndereWerkgeverVragen>(
        AndereWerkgeverVragen.fromJson,
        `${key}.andereWerkgevers`,
        json.andereWerkgevers,
        VraagBlokCollectionType.andereWerkgevers
      )
    );
  }
}
