import { Vestiging } from "../../models/api";
import { VestigingenSearchCriteria, VestigingenSearchResult } from "../../store/blok/vestigingen";

interface ActionBase<TType> {
  type: TType;
  vestigingenSearchKey: string;
}

interface ActionBaseWithData<TType, TData> extends ActionBase<TType> {
  data: TData;
}

// Initialize
export const INITIALIZE_VESTIGINGEN_SEARCHES_STATE = "INITIALIZE_VESTIGINGEN_SEARCHES_STATE";

type InitializeVestigingenSearchesStateAction = ActionBase<typeof INITIALIZE_VESTIGINGEN_SEARCHES_STATE>;

export const initializeVestigingenSearchesState = (
  vestigingenSearchKey: string
): InitializeVestigingenSearchesStateAction => {
  return {
    type: INITIALIZE_VESTIGINGEN_SEARCHES_STATE,
    vestigingenSearchKey
  };
};

// Paging
export const SET_VESTIGINGEN_PAGING = "SET_VESTIGINGEN_PAGING";
export const SET_VESTIGINGEN_PAGE = "SET_VESTINGEN_PAGING";
export const RESET_VESTIGINGEN_PAGING = "RESET_VESTIGINGEN_PAGING";

type SetVestigingenPagingAction = ActionBaseWithData<typeof SET_VESTIGINGEN_PAGING, boolean>;
type SetVestigingenPageAction = ActionBaseWithData<typeof SET_VESTIGINGEN_PAGE, number>;
type ResetVestigingenPagingAction = ActionBase<typeof RESET_VESTIGINGEN_PAGING>;

export const setVestigingenPagingStarted = (vestigingenSearchKey: string): SetVestigingenPagingAction => {
  return {
    type: SET_VESTIGINGEN_PAGING,
    data: true,
    vestigingenSearchKey
  };
};

export const setVestigingenPagingEnded = (vestigingenSearchKey: string): SetVestigingenPagingAction => {
  return {
    type: SET_VESTIGINGEN_PAGING,
    data: false,
    vestigingenSearchKey
  };
};

export const setVestigingenPage = (vestigingenSearchKey: string, page: number): SetVestigingenPageAction => {
  return {
    type: SET_VESTIGINGEN_PAGE,
    data: page,
    vestigingenSearchKey
  };
};

export const resetVestigingenPaging = (vestigingenSearchKey: string): ResetVestigingenPagingAction => {
  return {
    type: RESET_VESTIGINGEN_PAGING,
    vestigingenSearchKey
  };
};

// Searching
export const SET_VESTIGINGEN_SEARCHING = "SET_VESTIGINGEN_SEARCHING";

export const SET_VESTIGINGEN_SEARCH_CRITERIA = "SET_VESTIGINGEN_SEARCH_CRITERIA";
export const CLEAR_VESTIGINGEN_SEARCH_CRITERIA = "CLEAR_VESTIGINGEN_SEARCH_CRITERIA";

export const SET_VESTIGINGEN_SEARCH_RESULTS = "SET_VESTIGINGEN_SEARCH_RESULTS";
export const CLEAR_VESTIGINGEN_SEARCH_RESULTS = "CLEAR_VESTIGINGEN_SEARCH_RESULTS";

export const SET_SELECTED_VESTIGING = "SET_SELECTED_VESTIGING";
export const CLEAR_SELECTED_VESTIGING = "CLEAR_SELECTED_VESTIGING";

export const SHOW_VESTIGINGEN_SEARCH = "SHOW_VESTIGINGEN_SEARCH";
export const HIDE_VESTIGINGEN_SEARCH = "HIDE_VESTIGINGEN_SEARCH";
export const SHOW_VESTIGINGEN_RESULTS = "SHOW_VESTIGINGEN_RESULTS";
export const HIDE_VESTIGINGEN_RESULTS = "HIDE_VESTIGINGEN_RESULTS";
export const SHOW_SELECTED_VESTIGING = "SHOW_SELECTED_VESTIGING";
export const HIDE_SELECTED_VESTIGING = "HIDE_SELECTED_VESTIGING";

type SetVestigingenSearchingAction = ActionBaseWithData<typeof SET_VESTIGINGEN_SEARCHING, boolean>;
type SetVestigingenSearchCriteriaAction = ActionBaseWithData<
  typeof SET_VESTIGINGEN_SEARCH_CRITERIA,
  VestigingenSearchCriteria
>;
type ClearVestigingenSearchCriteriaAction = ActionBase<typeof CLEAR_VESTIGINGEN_SEARCH_CRITERIA>;

type SetVestigingenSearchResults = ActionBaseWithData<typeof SET_VESTIGINGEN_SEARCH_RESULTS, VestigingenSearchResult>;
type ClearVestigingenSearchResults = ActionBase<typeof CLEAR_VESTIGINGEN_SEARCH_RESULTS>;

type SetSelectedVestiging = ActionBaseWithData<typeof SET_SELECTED_VESTIGING, Vestiging>;
type ClearSelectedVestiging = ActionBase<typeof CLEAR_SELECTED_VESTIGING>;

type ShowVestigingenSearch = ActionBase<typeof SHOW_VESTIGINGEN_SEARCH>;
type HideVestigingenSearch = ActionBase<typeof HIDE_VESTIGINGEN_SEARCH>;
type ShowVestigingenResult = ActionBase<typeof SHOW_VESTIGINGEN_RESULTS>;
type HideVestigingenResult = ActionBase<typeof HIDE_VESTIGINGEN_RESULTS>;
type ShowSelectdVestigingen = ActionBase<typeof SHOW_SELECTED_VESTIGING>;
type HideSelectedVestigingen = ActionBase<typeof HIDE_SELECTED_VESTIGING>;

export const setVestigingenSearchingStarted = (vestigingenSearchKey: string): SetVestigingenSearchingAction => {
  return {
    type: SET_VESTIGINGEN_SEARCHING,
    data: true,
    vestigingenSearchKey
  };
};

export const setVestigingenSearchingEnded = (vestigingenSearchKey: string): SetVestigingenSearchingAction => {
  return {
    type: SET_VESTIGINGEN_SEARCHING,
    data: false,
    vestigingenSearchKey
  };
};

export const setVestigingenSearchCriteria = (
  vestigingenSearchKey: string,
  criteria: VestigingenSearchCriteria
): SetVestigingenSearchCriteriaAction => {
  return {
    type: SET_VESTIGINGEN_SEARCH_CRITERIA,
    data: criteria,
    vestigingenSearchKey
  };
};

export const clearVestigingSearchCriteria = (vestigingenSearchKey: string): ClearVestigingenSearchCriteriaAction => {
  return {
    type: CLEAR_VESTIGINGEN_SEARCH_CRITERIA,
    vestigingenSearchKey
  };
};

export const setVestigingenSearchResult = (
  vestigingenSearchKey: string,
  searchResult: VestigingenSearchResult
): SetVestigingenSearchResults => {
  return {
    type: SET_VESTIGINGEN_SEARCH_RESULTS,
    data: searchResult,
    vestigingenSearchKey
  };
};

export const clearVestigingSearchResult = (vestigingenSearchKey: string): ClearVestigingenSearchResults => {
  return {
    type: CLEAR_VESTIGINGEN_SEARCH_RESULTS,
    vestigingenSearchKey
  };
};

export const setSelectedVestiging = (vestigingenSearchKey: string, selectedVestiging: Vestiging): SetSelectedVestiging => {
  return {
    type: SET_SELECTED_VESTIGING,
    data: selectedVestiging,
    vestigingenSearchKey
  };
};

export const clearSelectedVestiging = (vestigingenSearchKey: string): ClearSelectedVestiging => {
  return {
    type: CLEAR_SELECTED_VESTIGING,
    vestigingenSearchKey
  };
};

/** Exort all actions */
export type VestigingenActions =
  | SetVestigingenPagingAction
  | SetVestigingenPageAction
  | ResetVestigingenPagingAction
  | SetVestigingenSearchingAction
  | SetVestigingenSearchCriteriaAction
  | ClearVestigingenSearchCriteriaAction
  | InitializeVestigingenSearchesStateAction
  | SetVestigingenSearchResults
  | ClearVestigingenSearchResults
  | SetSelectedVestiging
  | ClearSelectedVestiging
  | ShowVestigingenSearch
  | HideVestigingenSearch
  | ShowVestigingenResult
  | HideVestigingenResult
  | ShowSelectdVestigingen
  | HideSelectedVestigingen;
