import { faChevronDown, faChevronUp, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import BSAccordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../constants/translation-keys";
import { VisibleWrapper } from "../visible-wrapper";
import { AccordionContext } from "./accordion-context";

const AccordionItem: React.FC<any> = (props) => {
  const context = React.useContext(AccordionContext);

  const { t } = useTranslation();

  const headerClasses = "";
  const activeHeaderClasses = "active";

  const editButtonTitle = t(translationKeys["samenvatting-bar"]["bewerk-titel"], {
    bloknaam: props.title.toLowerCase()
  });

  const isActiveItem = context.activeKey === props.eventKey;
  const hasToggleButtonFocus = context.keyOfItemWithFocusedToggleButton === props.eventKey;
  const toggleButtonTitle = isActiveItem ? t(translationKeys.verbergen) : t(translationKeys.tonen);

  const handleToggleButtonClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    context.setActiveKey(props.eventKey);
    context.setKeyOfItemWithFocusedToggleButton(props.eventKey);
  };

  const handleHeaderClick = () => {
    context.setActiveKey(props.eventKey);
    context.setKeyOfItemWithFocusedToggleButton("");
  };

  const handleEditButtonClick = (e: any) => {
    context.setKeyOfItemWithFocusedToggleButton("");
    props.onEdit();
    e.stopPropagation();
  };

  const ToggleButton = (props: { id: string }) => (
    <button
      id={props.id}
      type="button"
      className="icon-button"
      aria-label={toggleButtonTitle}
      onClick={handleToggleButtonClick}
      {...(hasToggleButtonFocus ? { autoFocus: true } : {})}
    >
      <FontAwesomeIcon
        icon={isActiveItem ? faChevronUp : faChevronDown}
        className="toggle"
        size="1x"
        title={toggleButtonTitle}
      />
    </button>
  );

  const EditIcon = () => (
    <VisibleWrapper isVisible={props.onEdit}>
      <button type="button" className="icon-button" aria-label={editButtonTitle} onClick={handleEditButtonClick}>
        <FontAwesomeIcon icon={faEdit} size="1x" className="edit" title={editButtonTitle} />
      </button>
    </VisibleWrapper>
  );

  return (
    <Card>
      <BSAccordion.Button
        as={Card.Header}
        className={context.activeKey === props.eventKey ? activeHeaderClasses : headerClasses}
        eventKey={props.eventKey}
        onClick={handleHeaderClick}
      >
        <ToggleButton id={props.eventKey} /> <div className="card-header-title">{props.title}</div>
        {props.onEdit && <EditIcon />}
      </BSAccordion.Button>
      <BSAccordion.Collapse eventKey={props.eventKey}>
        <Card.Body>{props.children}</Card.Body>
      </BSAccordion.Collapse>
    </Card>
  );
};

export { AccordionItem };
