import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { KopieerButtonProps } from "./kopieer-button.component.interfaces";

export const KopieerButton = (props: KopieerButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button id="kopieerButton" className="actie-button" as="a" href={undefined} role="button" onClick={props.onClick}>
      <FontAwesomeIcon icon={faCopy} /> {t(translationKeys.melding.mijnMeldingen.kopieren)}
    </Button>
  );
};
