import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { AutorisatieGeldigheidVragen } from "./autorisatie-geldigheid-vragen";
import { ProfielVragen } from "./profiel-vragen";

export class AutorisatieProfielVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public autorisatieProfiel: ProfielVragen,
    public autorisatieGeldigheid: AutorisatieGeldigheidVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AutorisatieProfielVragen {
    return new AutorisatieProfielVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      ProfielVragen.fromJson(`${key}.autorisatieProfiel`, json.autorisatieProfiel),
      AutorisatieGeldigheidVragen.fromJson(`${key}.autorisatieGeldigheid`, json.autorisatieGeldigheid)
    );
  }
}
