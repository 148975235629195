import { ValidationFailure } from "models/api";
import { Order } from "store";
import { VraagBlokWithAntwoordIndex } from "../components/blok/vraag-blokken-collection/model";

export const SET_VRAAG_BLOK_ANTWOORD_INDEXES = "SET_VRAAG_BLOK_ANTWOORD_INDEXES";
export const INITIALIZE_COLLECTION_STATE = "INITIALIZE_COLLECTION_STATE";
export const TOGGLE_SELECTED_COLLECTION_ITEM = "TOGGLE_SELECTED_COLLECTION_ITEM";
export const SET_SELECTED_COLLECTION_ITEM = "SET_SELECTED_COLLECTION_ITEM";
export const RESET_SELECTED_COLLECTION_ITEM = "RESET_SELECTED_COLLECTION_ITEM";
export const SET_COLLECTION_VALIDATION_FAILURES = "SET_COLLECTION_VALIDATION_FAILURES";
export const SET_COLLECTION_VESTIGINGEN_KEYS = "SET_COLLECTION_VESTIGINGEN_KEYS";
export const SET_COLLECTION_CURRENTPAGE = "SET_COLLECTION_CURRENTPAGE";
export const RESET_COLLECTION_CURRENTPAGE = "RESET_COLLECTION_CURRENTPAGE";
export const SET_COLLECTION_PAGESIZE = "SET_COLLECTION_PAGESIZE";
export const RESET_COLLECTION_PAGESIZE = "RESET_COLLECTION_PAGESIZE";
export const SET_COLLECTION_SORTING = "SET_COLLECTION_SORTING";
export const RESET_COLLECTION_SORTING = "RESET_COLLECTION_SORTING";
export const SET_DEFAULT_COLLECTION_SORTING = "SET_DEFAULT_COLLECTION_SORTING";
export const RESET_DEFAULT_COLLECTION_SORTING = "RESET_DEFAULT_COLLECTION_SORTING";
export const SET_REMOVE_COLLECTION_ITEM_INDEX = "SET_REMOVE_COLLECTION_ITEM_INDEX";
export const RESET_REMOVE_COLLECTION_ITEM_INDEX = "RESET_REMOVE_COLLECTION_ITEM_INDEX";
export const RESET_FOCUS_ON_FIRST_VRAAG = "RESET_FOCUS_ON_FIRST_VRAAG";

interface SetVraagBlokAntwoordIndexesAction {
  type: typeof SET_VRAAG_BLOK_ANTWOORD_INDEXES;
  collectionKey: string;
  vraagBlokAntwoordIndexes: VraagBlokWithAntwoordIndex[];
  unfilteredTotalRecords: number;
}

interface InitializeCollectionStateAction {
  type: typeof INITIALIZE_COLLECTION_STATE;
  collectionKey: string;
}

interface ToggleSelectedCollectionItemAction {
  type: typeof TOGGLE_SELECTED_COLLECTION_ITEM;
  collectionKey: string;
  itemKey: string;
}

interface SetSelectedCollectionItemAction {
  type: typeof SET_SELECTED_COLLECTION_ITEM;
  collectionKey: string;
  itemKey: string;
  focusOnFirstVraag: boolean;
}

interface ResetSelectedCollectionItemAction {
  type: typeof RESET_SELECTED_COLLECTION_ITEM;
  collectionKey: string;
}

interface SetCollectionValidationFailures {
  type: typeof SET_COLLECTION_VALIDATION_FAILURES;
  collectionKey: string;
  validationFailures: ValidationFailure[];
}

interface SetCollectionVestigingenKeys {
  type: typeof SET_COLLECTION_VESTIGINGEN_KEYS;
  collectionKey: string;
  vestigingenKeys: string[];
}

interface SetCollectionCurrentPageAction {
  type: typeof SET_COLLECTION_CURRENTPAGE;
  collectionKey: string;
  pageNumber: number;
}

interface ResetCollectionCurrentPageAction {
  type: typeof RESET_COLLECTION_CURRENTPAGE;
  collectionKey: string;
}

interface SetCollectionPageSizeAction {
  type: typeof SET_COLLECTION_PAGESIZE;
  collectionKey: string;
  pageSize: number;
}

interface ResetCollectionPageSizeAction {
  type: typeof RESET_COLLECTION_PAGESIZE;
  collectionKey: string;
}

interface SetCollectionSortingAction {
  type: typeof SET_COLLECTION_SORTING;
  collectionKey: string;
  sortProperty: string;
  sortOrder?: Order;
}

interface ResetCollectionSortingAction {
  type: typeof RESET_COLLECTION_SORTING;
  collectionKey: string;
}

interface SetDefaultCollectionSortingAction {
  type: typeof SET_DEFAULT_COLLECTION_SORTING;
  collectionKey: string;
  sortProperty: string;
  sortOrder: Order;
}

interface ResetDefaultCollectionSortingAction {
  type: typeof RESET_DEFAULT_COLLECTION_SORTING;
  collectionKey: string;
}

interface SetRemoveCollectionItemIndexAction {
  type: typeof SET_REMOVE_COLLECTION_ITEM_INDEX;
  collectionKey: string;
  itemIndex: number;
}

interface ResetRemoveCollectionItemIndexAction {
  type: typeof RESET_REMOVE_COLLECTION_ITEM_INDEX;
  collectionKey: string;
}

interface ResetFocusOnFirstVraagAction {
  type: typeof RESET_FOCUS_ON_FIRST_VRAAG;
  collectionKey: string;
}

export const setVraagBlokAntwoordIndexes = (
  collectionKey: string,
  vraagBlokAntwoordIndexes: VraagBlokWithAntwoordIndex[],
  unfilteredTotalRecords: number
): SetVraagBlokAntwoordIndexesAction => {
  return {
    type: SET_VRAAG_BLOK_ANTWOORD_INDEXES,
    collectionKey,
    vraagBlokAntwoordIndexes,
    unfilteredTotalRecords
  };
};

export const initializeCollectionState = (collectionKey: string): InitializeCollectionStateAction => {
  return {
    type: INITIALIZE_COLLECTION_STATE,
    collectionKey
  };
};

export const toggleSelectedCollectionItem = (collectionKey: string, itemKey: string): ToggleSelectedCollectionItemAction => {
  return {
    type: TOGGLE_SELECTED_COLLECTION_ITEM,
    collectionKey,
    itemKey
  };
};

export const setSelectedCollectionItem = (
  collectionKey: string,
  itemKey: string,
  focusOnFirstVraag: boolean
): SetSelectedCollectionItemAction => {
  return {
    type: SET_SELECTED_COLLECTION_ITEM,
    collectionKey,
    itemKey,
    focusOnFirstVraag
  };
};

export const resetSelectedCollectionItem = (collectionKey: string): ResetSelectedCollectionItemAction => {
  return {
    type: RESET_SELECTED_COLLECTION_ITEM,
    collectionKey
  };
};

export const setCollectionValidationFailures = (
  collectionKey: string,
  validationFailures: ValidationFailure[]
): SetCollectionValidationFailures => {
  return {
    type: SET_COLLECTION_VALIDATION_FAILURES,
    collectionKey,
    validationFailures
  };
};

export const setCollectionVestigingenKeys = (
  collectionKey: string,
  vestigingenKeys: string[]
): SetCollectionVestigingenKeys => {
  return {
    type: SET_COLLECTION_VESTIGINGEN_KEYS,
    collectionKey,
    vestigingenKeys
  };
};

export const setCollectionCurrentPage = (collectionKey: string, pageNumber: number): SetCollectionCurrentPageAction => {
  return {
    type: SET_COLLECTION_CURRENTPAGE,
    collectionKey,
    pageNumber
  };
};

export const resetCollectionCurrentPage = (collectionKey: string): ResetCollectionCurrentPageAction => {
  return {
    type: RESET_COLLECTION_CURRENTPAGE,
    collectionKey
  };
};

export const setCollectionPageSize = (collectionKey: string, pageSize: number): SetCollectionPageSizeAction => {
  return {
    type: SET_COLLECTION_PAGESIZE,
    collectionKey,
    pageSize
  };
};

export const resetCollectionPageSize = (collectionKey: string): ResetCollectionPageSizeAction => {
  return {
    type: RESET_COLLECTION_PAGESIZE,
    collectionKey
  };
};

export const toggleCollectionSorting = (collectionKey: string, sortProperty: string): SetCollectionSortingAction => {
  return {
    type: SET_COLLECTION_SORTING,
    collectionKey,
    sortProperty
  };
};

export const setCollectionSorting = (
  collectionKey: string,
  sortProperty: string,
  sortOrder: Order
): SetCollectionSortingAction => {
  return {
    type: SET_COLLECTION_SORTING,
    collectionKey,
    sortProperty,
    sortOrder
  };
};

export const resetCollectionSorting = (collectionKey: string): ResetCollectionSortingAction => {
  return {
    type: RESET_COLLECTION_SORTING,
    collectionKey
  };
};

export const setDefaultCollectionSorting = (
  collectionKey: string,
  sortProperty: string,
  sortOrder: Order
): SetDefaultCollectionSortingAction => {
  return {
    type: SET_DEFAULT_COLLECTION_SORTING,
    collectionKey,
    sortProperty,
    sortOrder
  };
};

export const resetDefaultCollectionSorting = (collectionKey: string): ResetDefaultCollectionSortingAction => {
  return {
    type: RESET_DEFAULT_COLLECTION_SORTING,
    collectionKey
  };
};

export const setRemoveCollectionItemIndex = (
  collectionKey: string,
  itemIndex: number
): SetRemoveCollectionItemIndexAction => {
  return {
    type: SET_REMOVE_COLLECTION_ITEM_INDEX,
    collectionKey,
    itemIndex
  };
};

export const resetRemoveCollectionItemIndex = (collectionKey: string): ResetRemoveCollectionItemIndexAction => {
  return {
    type: RESET_REMOVE_COLLECTION_ITEM_INDEX,
    collectionKey
  };
};

export const resetFocusOnFirstVraag = (collectionKey: string): ResetFocusOnFirstVraagAction => {
  return {
    type: RESET_FOCUS_ON_FIRST_VRAAG,
    collectionKey
  };
};

/** Export all actions */
export type CollectionActions =
  | InitializeCollectionStateAction
  | SetVraagBlokAntwoordIndexesAction
  | ToggleSelectedCollectionItemAction
  | SetSelectedCollectionItemAction
  | ResetSelectedCollectionItemAction
  | SetCollectionValidationFailures
  | SetCollectionVestigingenKeys
  | SetCollectionCurrentPageAction
  | ResetCollectionCurrentPageAction
  | SetCollectionPageSizeAction
  | ResetCollectionPageSizeAction
  | SetCollectionSortingAction
  | ResetCollectionSortingAction
  | SetDefaultCollectionSortingAction
  | ResetDefaultCollectionSortingAction
  | SetRemoveCollectionItemIndexAction
  | ResetRemoveCollectionItemIndexAction
  | ResetFocusOnFirstVraagAction;
