import { MelderVragen } from "./melder-vragen";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { Vraag } from "../vraag";
import { jsonHelpers } from "../../../helpers";
import { Meerkeuzevraag } from "../meerkeuzevraag";

export class OngevalMelderVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public common: MelderVragen,
    public relatieTovBedrijfOngeval: Meerkeuzevraag,
    public andereHandhavingsinstantie: Vraag,
    public andereRelatie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): OngevalMelderVragen {
    return new OngevalMelderVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      MelderVragen.fromJson(`${key}.common`, json.common),
      jsonHelpers.toMeerkeuzeVraag(`${key}.relatieTovBedrijfOngeval`, json.relatieTovBedrijfOngeval),
      jsonHelpers.toTekstVraag(`${key}.andereHandhavingsinstantie`, json.andereHandhavingsinstantie),
      jsonHelpers.toTekstVraag(`${key}.andereRelatie`, json.andereRelatie)
    );
  }
}
