import { getCookie } from "typescript-cookie";

type xsrfTokenHeaderType = {
  "X-XSRF-TOKEN": string;
};
export const getXsrfToken = () => {
  return getCookie("XSRF-TOKEN");
};

export const getXsrfTokenHeader = (): xsrfTokenHeaderType | {} => {
  const token = getXsrfToken();
  if (token) {
    return { "X-XSRF-TOKEN": token };
  }
  return {};
};

const xsrfHttpMethods = ["POST", "PUT", "DELETE", "PATCH"];
export const shouldIncludeXsrfTokenHeader = (method: string): boolean => {
  return xsrfHttpMethods.includes(method);
};
