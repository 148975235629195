import { VraagBlok } from "../models/api";
import { ExpressionContext } from "../models/api/blok/expression-context";
import { Autorisatiegegevens } from "../models/api/security/autorisatiegegevens";
import { validationHelpers } from "./validation-helpers";

export abstract class InventarisatieValidator {
  public validate = (vraagBlok: VraagBlok, antwoordBlok: any) => {
    const validationResult = validationHelpers.validateVraagBlokGenericClientSide(
      vraagBlok,
      new ExpressionContext(null, Autorisatiegegevens.initialize(), null, antwoordBlok, antwoordBlok, vraagBlok)
    );

    return validationHelpers.convertToErrorObject(validationResult);
  };
}
