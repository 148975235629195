import deepcopy from "deepcopy";
import {
  ADD_VRAAGBLOK_TO_COLLECTION,
  CLEAR_VRAAGBLOK_FROM_COLLECTION,
  GET_FORMULIER,
  REMOVE_VRAAGBLOK_FROM_COLLECTION,
  SET_STATE,
  SharedActions,
  VragenActions
} from "../actions";
import { formulierHelpers, objectHelpers } from "../helpers";
import { SamengesteldeVraag, TeVerwijderenAsbestMateriaalRisicoklasse1Vragen, Vraag, VraagBlok } from "../models/api";
import { Formuliertype } from "../models/application";
import { VragenState } from "../store";

const initialState: VragenState = {};

const vragenReducer = (state: VragenState = initialState, action: VragenActions | SharedActions): VragenState => {
  switch (action.type) {
    case SET_STATE: {
      // HACK tbv ART state inladen: ARK1 formulier heeft circulaire referentie in formulier. Deze kan niet geserialiseerd worden.
      // Bij inladen moet deze circulaire referentie weer hersteld worden.
      if (state.formulier?.type === Formuliertype.asbestRisicoklasse1) {
        const samengesteldeVraag = state.formulier.blokken.inventarisatie
          .teVerwijderenAsbestMateriaalRisicoklasse1 as SamengesteldeVraag<TeVerwijderenAsbestMateriaalRisicoklasse1Vragen>;

        formulierHelpers.getVragen(samengesteldeVraag.subVragen).forEach((v: Vraag) => (v.parent = samengesteldeVraag));
      }

      return state;
    }
    case GET_FORMULIER:
      return { ...state, formulier: action.data };
    case ADD_VRAAGBLOK_TO_COLLECTION:
      if (!state.formulier?.blokken) {
        throw new Error("formulier.blokken must be set before adding items");
      }
      const formulierBlokkenToAddCopy = deepcopy(state.formulier?.blokken);
      const vraagBlokCollectionForAdding = objectHelpers.getVraagBlokCollectionValues<VraagBlok>(
        formulierBlokkenToAddCopy,
        action.collectionKey
      );

      vraagBlokCollectionForAdding?.push(action.vraagBlok);

      return {
        ...state,
        formulier: { ...state.formulier, blokken: formulierBlokkenToAddCopy }
      };
    case REMOVE_VRAAGBLOK_FROM_COLLECTION: {
      if (!state.formulier?.blokken) {
        throw new Error("formulier.blokken must be set before removing items");
      }
      const formulierBlokkenToRemoveCopy = deepcopy(state.formulier?.blokken);
      const vraagBlokCollectionForRemoval = objectHelpers.getVraagBlokCollectionValues<VraagBlok>(
        formulierBlokkenToRemoveCopy,
        action.collectionKey
      );

      // Remove VraagBlok
      // Always remove last vraagblok, because this way the keys of the subsequent vraagblokken don't have to be changed.
      vraagBlokCollectionForRemoval?.splice(vraagBlokCollectionForRemoval.length - 1, 1);

      return {
        ...state,
        formulier: { ...state.formulier, blokken: formulierBlokkenToRemoveCopy }
      };
    }
    case CLEAR_VRAAGBLOK_FROM_COLLECTION: {
      if (!state.formulier?.blokken) {
        throw new Error("formulier.blokken must be set before removing items");
      }

      const formulierBlokkenToClearCopy = deepcopy(state.formulier?.blokken);
      const vraagBlokCollectionForRemoval = objectHelpers.getVraagBlokCollectionValues<VraagBlok>(
        formulierBlokkenToClearCopy,
        action.collectionKey
      );

      if (vraagBlokCollectionForRemoval && vraagBlokCollectionForRemoval.length === 0) {
        return { ...state };
      }

      // Clear VraagBlok
      vraagBlokCollectionForRemoval?.splice(0, vraagBlokCollectionForRemoval.length);

      return {
        ...state,
        formulier: { ...state.formulier, blokken: formulierBlokkenToClearCopy }
      };
    }
    default:
      return state;
  }
};

export default vragenReducer;
