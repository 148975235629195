import { connect } from "react-redux";
import { BrpAchternaamSearchRequest } from "../../../../models/api";
import { State } from "../../../../store";
import { searchBrp } from "../../../../thunks/brp-thunks";
import { BrpSearch } from "./brp-search.component";
import { BrpSearchDispatchProps, BrpSearchOwnProps, BrpSearchStateProps } from "./brp-search.component.interfaces";

const mapStateToProps = (state: State, ownProps: BrpSearchOwnProps): BrpSearchStateProps => ({
  brpSearchResult: state.brp.results[ownProps.vragen.key],
  hasBrpServerError: state.brp.results[ownProps.vragen.key]?.error ?? false,
  brpIsLoading: state.brp.results[ownProps.vragen.key]?.busy ?? false
});

const mapDispatchToProps = (dispatch: any, ownProps: BrpSearchOwnProps): BrpSearchDispatchProps => ({
  searchBrp: (request: BrpAchternaamSearchRequest) => dispatch(searchBrp(ownProps.vragen.key, request)),
  getVolgnummer: () => ownProps.vragen.bsn.volgnummer
});

export default connect(mapStateToProps, mapDispatchToProps)(BrpSearch);
