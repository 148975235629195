import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BijlageVragen } from "./bijlage-vragen";
import { ZaakPincodeVragen } from "./zaak-pincode-vragen";

export class UploadVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public aanleverenDocumenten: Tekst,
    public zaakPincode: ZaakPincodeVragen,
    public emailadres: Vraag,
    public bestandenTitel: Tekst,
    public bestanden: BijlageVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): UploadVragen {
    return new UploadVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.aanleverenDocumenten`, json.aanleverenDocumenten),
      ZaakPincodeVragen.fromJson(`${key}.zaakPincode`, json.zaakPincode),
      jsonHelpers.toTekstVraag(`${key}.emailadres`, json.emailadres),
      jsonHelpers.toTitel(`${key}.bestandenTitel`, json.bestandenTitel),
      BijlageVragen.fromJson(`${key}.bestanden`, json.bestanden)
    );
  }
}
