import { State, store } from "../../../store";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export abstract class IsGezochtItemGevonden extends VraagExpression {
  execute = (vraagBlok: VraagBlok, _context: ExpressionContext, vraagProperty?: string): boolean => {
    if (vraagProperty) {
      const vraagKey = vraagBlok[vraagProperty].key;
      const searchValidations = (store.getState() as State).validation.searchIsValid;

      //  true bij ontbreken validation result is niet goed,
      // maar vanuit bepaalde situaties (zoals vanuit melding wijzigen) wordt de frontend lookup niet getriggerd
      // en is er geen searchValidation result in de store beschikbaar.
      if (!searchValidations.hasOwnProperty(vraagKey)) {
        return true;
      }
      return searchValidations.hasOwnProperty(vraagKey) && searchValidations[vraagKey];
    }

    return false;
  };
}
