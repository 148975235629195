import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translationKeys } from "constants/translation-keys";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { useTranslation } from "react-i18next";
import logoEn from "../../assets/icons/britain.svg";
import logoDe from "../../assets/icons/germany-15.svg";
import logoNl from "../../assets/icons/netherlands-12.svg";
import logoPl from "../../assets/icons/poland-11.svg";
import logoRo from "../../assets/icons/romania-10.svg";
import { TaalSelectieProps } from "./taal-selectie.component.interfaces";

export const TaalSelectie = (props: TaalSelectieProps) => {
  const { handleTaalSelect } = props;

  const { t } = useTranslation();

  return (
    <DropdownButton
      className="dropdown--no-arrow"
      title={
        <>
          <label className="sr-only">{t(translationKeys.taalselectie.taalKiezen)}</label>
          <FontAwesomeIcon
            icon={faGlobe}
            aria-label={t(translationKeys.taalselectie.taalKiezen)}
            data-test="taal-selectie"
          />
        </>
      }
    >
      <DropdownItem onClick={() => handleTaalSelect("nl")} data-test="taal-selectie-nl">
        <img className="flag-logo" alt="" role="presentation" src={logoNl} /> {t(translationKeys.taalselectie.nl)}
      </DropdownItem>
      <DropdownItem onClick={() => handleTaalSelect("en")} data-test="taal-selectie-en">
        <img className="flag-logo" alt="" role="presentation" src={logoEn} /> {t(translationKeys.taalselectie.en)}
      </DropdownItem>
      <DropdownItem onClick={() => handleTaalSelect("ro")} data-test="taal-selectie-ro">
        <img className="flag-logo" alt="" role="presentation" src={logoRo} /> {t(translationKeys.taalselectie.ro)}
      </DropdownItem>
      <DropdownItem onClick={() => handleTaalSelect("pl")} data-test="taal-selectie-pl">
        <img className="flag-logo" alt="" role="presentation" src={logoPl} /> {t(translationKeys.taalselectie.pl)}
      </DropdownItem>
      <DropdownItem onClick={() => handleTaalSelect("de")} data-test="taal-selectie-de">
        <img className="flag-logo" alt="" role="presentation" src={logoDe} /> {t(translationKeys.taalselectie.de)}
      </DropdownItem>
    </DropdownButton>
  );
};
