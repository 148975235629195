import { AntwoordBlok } from "./antwoord-blok";
import { Antwoord } from "../antwoord";
import { TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden } from "./te-verwijderen-asbest-materiaal-risicoklasse1-antwoorden";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";

export class InventarisatieAntwoorden extends AntwoordBlok {
  constructor(
    public bedrijfsgegevens: BedrijfsgegevensAntwoorden | null,
    public scaCode: Antwoord<string>,
    public rapportdatum: Antwoord<string>,
    public teVerwijderenAsbestMateriaalRisicoklasse1: Antwoord<TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden[]>
  ) {
    super();
  }

  static initialize(): InventarisatieAntwoorden {
    return new InventarisatieAntwoorden(
      BedrijfsgegevensAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden[]>([])
    );
  }

  static fromJson(json: any): InventarisatieAntwoorden {
    return new InventarisatieAntwoorden(
      BedrijfsgegevensAntwoorden.fromJson(json.bedrijfsgegevens),
      Antwoord.fromJson<string>(json.scaCode),
      Antwoord.fromJson<string>(json.rapportdatum),
      Antwoord.fromJsonArray<TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden>(
        json.teVerwijderenAsbestMateriaalRisicoklasse1,
        TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden.fromJson
      )
    );
  }
}
