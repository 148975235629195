import _ from "lodash";
import { InputTypeaheadItem } from "../components/input-controls/input-typeahead/input-typeahead.interfaces";
import {
  AntwoordBlok,
  Bestandenvraag,
  BestandImportVraag,
  CollectionTypeaheadVraag,
  GelinktAntwoord,
  GelinkteMeerkeuzevraag,
  GelinkteVraag,
  Meerkeuzevraag,
  MogelijkAntwoord,
  RedenVanVerlateMeldingVraag,
  SamengesteldeVraag,
  Tekst,
  Vraag,
  VraagBlok,
  VraagDisplayDefinition
} from "../models/api";
import { Dictionary, MeldingFile, SamengesteldeVraagType, TekstOptions, TekstType, VraagType } from "../models/application";
import { formulierHelpers } from "./formulier-helpers";

const toVraag = (key: string, json: any, type = VraagType.unknown, displayDefinition?: VraagDisplayDefinition): Vraag => {
  try {
    return {
      key: key,
      volgnummer: json.volgnummer,
      type,
      isVerplicht: json.isVerplicht,
      isVerplichtValidationMessage: json.isVerplichtValidationMessage,
      berekendeWaarde: json.berekendeWaarde,
      conditioneelReadonly: json.conditioneelReadonly,
      conditioneelVerplicht: json.conditioneelVerplicht,
      conditioneelVerplichtValidationMessage: json.conditioneelVerplichtValidationMessage,
      conditioneelGesteld: json.conditioneelGesteld,
      customValidations: json.customValidations,
      maxLength: json.maxLength,
      isMeervoud: json.isMeervoud,
      format: json.format,
      formatValidationMessage: json.formatValidationMessage,
      tekst: json.tekst,
      tekstMetLidwoord: json.tekstMetLidwoord,
      toelichting: json.toelichting,
      placeholder: json.placeholder,
      defaultAntwoord: json.defaultAntwoord,
      isAntwoordVertaalbaar: json.isAntwoordVertaalbaar,
      isVraag: true,
      displayDefinition: displayDefinition,
      resetBijMeldingWijzigen: json.resetBijMeldingWijzigen
    };
  } catch (ex) {
    throw new Error(`Error occured while processing vraag with key ${key}: ${ex}`);
  }
};

const toTekstVraag = (key: string, json: any, displayDefinition?: VraagDisplayDefinition): Vraag =>
  toVraag(key, json, VraagType.tekst, displayDefinition);

const toTekstMeerdereRegelsVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.tekstMeerdereRegels);

const toDatumVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.datum);

const toTijdVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.tijd);

const toDatumTijdVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.datumTijd);

const toLandVraag = (key: string, json: any, displayDefinition?: VraagDisplayDefinition): Vraag =>
  toVraag(key, json, VraagType.land, displayDefinition);

const toBerekendeVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.berekend);

const toBuitenlandVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.buitenland);

const toNumeriekeVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.numeriek);

const toGebrokenAantalVraag = (key: string, json: any): Vraag => toVraag(key, json, VraagType.gebrokenAantal);

const toPlaatsVraag = (key: string, json: any, displayDefinition?: VraagDisplayDefinition): Vraag =>
  toVraag(key, json, VraagType.plaats, displayDefinition);

const toBestandenVraag = (key: string, json: any): Bestandenvraag => ({
  ...toVraag(key, json, VraagType.bestanden),
  maxGrootteInMB: json.maxGrootteInMB,
  toegestaneExtensies: json.toegestaneExtensies
});

const toChecklistvraag = (key: string, json: any): Meerkeuzevraag => ({
  ...toMeerkeuzeVraag(key, json, VraagType.meerkeuzeMeerdereAntwoordmogelijkheden)
});

const toMeerkeuzeVraag = (
  key: string,
  json: any,
  type = VraagType.meerkeuzeEenAntwoordmogelijkheid,
  displayDefinition?: VraagDisplayDefinition
): Meerkeuzevraag => ({
  ...toVraag(key, json, type, displayDefinition),
  mogelijkeAntwoorden: json.mogelijkeAntwoorden.map((ma: any) => MogelijkAntwoord.fromJson(ma))
});

const toBestandImportVraag = <TAntwoordblokken>(
  key: string,
  json: any,
  onImportFile: (file: MeldingFile, antwoordblokken: TAntwoordblokken, translate: any) => Promise<void>
): BestandImportVraag<TAntwoordblokken> => ({
  ...toVraag(key, json, VraagType.bestandImport),
  maxGrootteInMB: json.maxGrootteInMB,
  toegestaneExtensies: json.toegestaneExtensies,
  onImportFile: onImportFile
});

const toGelinkteChecklistvraag = (
  key: string,
  json: any,
  displayDefinition?: VraagDisplayDefinition
): GelinkteMeerkeuzevraag => ({
  ...toGelinkteMeerkeuzeVraag(key, json, VraagType.gelinkteMeerkeuzeMeerdereAntwoordmogelijkheden, displayDefinition)
});

const toGelinktAntwoord = (value: GelinktAntwoord): GelinktAntwoord => {
  return {
    ...value,
    gelinkteVragen: _.map(value.gelinkteVragen, toGelinkteVraag)
  };
};

const toGelinkteVraag = (value: GelinkteVraag): GelinkteVraag => {
  return {
    ...value,
    gelinkteAntwoorden: _.map(value.gelinkteAntwoorden, toGelinktAntwoord)
  };
};

const toGelinkteVragenDictionary = (json: any): Dictionary<GelinkteVraag> | undefined => {
  if (!_.isNil(json)) {
    return _.mapValues(json, (value) => {
      return toGelinkteVraag(value);
    });
  }
  return undefined;
};

const toGelinkteMeerkeuzeVraag = (
  key: string,
  json: any,
  type = VraagType.gelinkteMeerkeuze,
  displayDefinition?: VraagDisplayDefinition
): GelinkteMeerkeuzevraag => ({
  ...toMeerkeuzeVraag(key, json, type, displayDefinition),
  databron: toGelinkteVragenDictionary(json.databron),
  alleMogelijkeAntwoorden: json.mogelijkeAntwoorden
});

const toCollectionTypeaheadVraag = (
  key: string,
  json: any,
  getCollectionItem: (antwoordBlok: AntwoordBlok) => InputTypeaheadItem,
  type = VraagType.collectionTypeahead
): CollectionTypeaheadVraag => ({
  ...toVraag(key, json, type),
  vraagBlokCollectionProperty: json.vraagBlokCollectionProperty,
  getCollectionItem: getCollectionItem
});

const toRedenVanVerlateMeldingVraag = (key: string, json: any): RedenVanVerlateMeldingVraag => ({
  ...toMeerkeuzeVraag(key, json),
  meldtermijnInDagen: json.meldtermijnInDagen
});

const toSamengesteldeVraag = <TSubVragen extends VraagBlok>(
  key: string,
  json: any,
  subVragenFromJson: (json: any) => TSubVragen,
  type: SamengesteldeVraagType
): SamengesteldeVraag<TSubVragen> => {
  const subVraagBlok = subVragenFromJson(json.subVragen);

  const samengesteldeVraag: SamengesteldeVraag<TSubVragen> = {
    ...toVraag(key, json, VraagType.samengesteldeVraag),
    subVragen: subVraagBlok,
    samengesteldeVraagType: type,
    isSamengesteldeVraag: true
  };

  const subVragen = formulierHelpers.getVragen(subVraagBlok);

  for (const subVraag of subVragen) {
    subVraag.parent = samengesteldeVraag;
  }

  return samengesteldeVraag;
};

const toParagraaf = (key: string, json: any, variables?: Map<string, string>): Tekst =>
  toTekst(key, json, { tekstType: TekstType.paragraaf, variables: variables });

const toWaarschuwing = (key: string, json: any): Tekst => toTekst(key, json, { tekstType: TekstType.waarschuwing });

const toInfo = (key: string, json: any): Tekst => toTekst(key, json, { tekstType: TekstType.info });

const toTitel = (key: string, json: any): Tekst => toTekst(key, json, { tekstType: TekstType.titel });

const toTekst = (key: string, json: any, tekstOptions: TekstOptions): Tekst => {
  try {
    return {
      key: key,
      volgnummer: json.volgnummer,
      conditioneelGesteld: json.conditioneelGesteld,
      waarde: json.waarde,
      toelichting: json.toelichting,
      type: tekstOptions.tekstType,
      isTekst: true,
      variables: tekstOptions.variables,
      isHiddenFromSamenvatting: json.hideFromSamenvatting
    };
  } catch (ex) {
    throw new Error(`Error occured while processing tekst with key ${key}: ${ex}`);
  }
};

export const jsonHelpers = {
  toVraag,
  toChecklistvraag,
  toBerekendeVraag,
  toBestandenVraag,
  toDatumVraag,
  toTijdVraag,
  toDatumTijdVraag,
  toLandVraag,
  toBuitenlandVraag,
  toMeerkeuzeVraag,
  toGelinkteChecklistvraag,
  toGelinkteMeerkeuzeVraag,
  toNumeriekeVraag,
  toPlaatsVraag,
  toRedenVanVerlateMeldingVraag,
  toSamengesteldeVraag,
  toTekstVraag,
  toTekstMeerdereRegelsVraag,
  toParagraaf,
  toInfo,
  toWaarschuwing,
  toTitel,
  toGebrokenAantalVraag,
  toCollectionTypeaheadVraag,
  toBestandImportVraag
};
