import { LocatieWerkzaamhedenBijBedrijfVragen, MelderVragen, MeldingOmschrijvingVragen, VraagBlokken } from "../blok";
import { HasMelderVragen } from "./has-melder-vragen";

export class GevaarlijkeGassenImportcontainersVraagBlokken extends VraagBlokken implements HasMelderVragen {
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public locatie: LocatieWerkzaamhedenBijBedrijfVragen,
    public meldingOmschrijving: MeldingOmschrijvingVragen
  ) {
    super();
  }

  static fromJson(json: any): GevaarlijkeGassenImportcontainersVraagBlokken {
    return new GevaarlijkeGassenImportcontainersVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      LocatieWerkzaamhedenBijBedrijfVragen.fromJson("locatie", json.locatie),
      MeldingOmschrijvingVragen.fromJson("meldingOmschrijving", json.meldingOmschrijving)
    );
  }
}
