import { useFormikContext } from "formik";
import { User } from "models/api/security/user";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AntwoordBlokken, MeldingContext, VraagBlokken } from "../../../models/api";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";
import { State } from "../../../store";
import { wordtGesteld } from "./conditioneel-gesteld-helper";
import { WithConditioneelGesteldProps } from "./with-conditioneel-gesteld.interfaces";

export const withConditioneelGesteld = <P extends object>(
  Component: React.ComponentType<P & WithConditioneelGesteldProps>
) => {
  return (props: P & WithConditioneelGesteldProps) => {
    const { item, ...rest } = props;

    const { vragen, user, autorisatiegegevens, meldingStatus, wordtMeldingGewijzigd } = useSelector<
      State,
      {
        vragen?: VraagBlokken;
        user: User | null;
        autorisatiegegevens: Autorisatiegegevens;
        meldingStatus?: string;
        wordtMeldingGewijzigd: boolean;
      }
    >(
      (state: State) => ({
        vragen: state.vragen.formulier?.blokken,
        user: state.security.user,
        autorisatiegegevens: state.autorisatie.autorisatiegegevens,
        meldingStatus: state.melding?.status,
        wordtMeldingGewijzigd: state.melding?.wordtGewijzigd
      }),
      shallowEqual
    );

    const { values } = useFormikContext<AntwoordBlokken>();

    let wordtItemGesteld = false;

    if (vragen) {
      wordtItemGesteld = wordtGesteld(
        item,
        vragen,
        values,
        user,
        autorisatiegegevens,
        new MeldingContext(wordtMeldingGewijzigd, meldingStatus)
      );
    }

    return wordtItemGesteld ? <Component item={item} {...(rest as P)} /> : null;
  };
};
