import { useFormikContext } from "formik";
import i18n from "i18next";
import _ from "lodash";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { meldingHelpers, objectHelpers } from "../../../helpers";
import {
  Antwoord,
  AntwoordBlokken,
  GelinktAntwoord,
  GelinkteMeerkeuzevraag,
  GelinkteVraag,
  MogelijkAntwoord,
  Vraag,
  VraagBlok,
  VraagBlokken
} from "../../../models/api";
import { State } from "../../../store";
import { WithGelinkteMeerkeuzevraagProps } from "./with-gelinkte-meerkeuzevraag.interfaces";

const resetAntwoord = (values: any, vraag: Vraag) => {
  const vervolgvraagAntwoord = objectHelpers.getValue<Antwoord<any>>(values, vraag.key);
  if (vervolgvraagAntwoord) {
    vervolgvraagAntwoord.waarde = null;
    vervolgvraagAntwoord.initialValue = null;
  }
};

const setMogelijkeAntwoorden = (vraag: GelinkteMeerkeuzevraag, antwoorden: GelinktAntwoord[]) => {
  if (vraag.alleMogelijkeAntwoorden && vraag.alleMogelijkeAntwoorden.length > 0) {
    vraag.mogelijkeAntwoorden = [
      ...vraag.alleMogelijkeAntwoorden.filter((ma) => antwoorden.some((a) => a.antwoord === ma.waarde))
    ];
  } else {
    vraag.mogelijkeAntwoorden = antwoorden.map((antwd) => new MogelijkAntwoord(antwd.antwoord, {}, {}));
  }
};

export const withGelinkteMeerkeuzevraag = <P extends object>(
  Component: React.ComponentType<P & WithGelinkteMeerkeuzevraagProps>
) => {
  return (props: P & WithGelinkteMeerkeuzevraagProps) => {
    const { vraag } = props;
    const lang = i18n.language;

    const { values, setFieldValue } = useFormikContext<AntwoordBlokken>();

    const { vragen } = useSelector<
      State,
      {
        vragen?: VraagBlokken;
      }
    >(
      (state: State) => ({
        vragen: state.vragen.formulier?.blokken
      }),
      shallowEqual
    );

    const antwoord = objectHelpers.getValue<Antwoord<any>>(values, vraag.key);

    let antwoorden: GelinktAntwoord[] = [];
    if (vraag.databron) {
      antwoorden = vraag.databron[lang].gelinkteAntwoorden;

      setMogelijkeAntwoorden(vraag, antwoorden);
    }

    const vraagBlok = meldingHelpers.getVraagBlok(vraag, vragen);

    const gelinktAntwoord: GelinktAntwoord | undefined = antwoorden.find((antwd) => antwd.antwoord === antwoord?.waarde);

    useEffect(() => {
      const reset = (gelinkteVraag: GelinkteVraag, vervolgvraagVraagBlok: VraagBlok) => {
        const vervolgvraag = vervolgvraagVraagBlok[gelinkteVraag.vraag] as GelinkteMeerkeuzevraag;

        if (vervolgvraag && vervolgvraag.currentParentAntwoord !== antwoord?.waarde) {
          resetVervolgvraag(vervolgvraag, gelinkteVraag);
        }
      };

      const resetVervolgvraag = (vervolgvraag: GelinkteMeerkeuzevraag, gelinkteVraag: GelinkteVraag) => {
        resetVervolgVraagAntwoord(vervolgvraag);

        vervolgvraag.currentParentAntwoord = antwoord?.waarde;

        vervolgvraag.databron = {};
        vervolgvraag.databron[lang] = gelinkteVraag;
      };

      const resetVervolgVraagAntwoord = (vervolgvraag: GelinkteMeerkeuzevraag) => {
        if (vervolgvraag.currentParentAntwoord !== undefined) {
          setFieldValue(meldingHelpers.getFieldNameFromVraag(vervolgvraag), null);

          resetAntwoord(values, vervolgvraag);
        }
      };

      if (gelinktAntwoord && vraagBlok !== null) {
        gelinktAntwoord.gelinkteVragen?.forEach((gelinkteVraag) => {
          const gelinkteVraagWithKey = _.defaults(gelinkteVraag, { key: `${vraagBlok.key}.${gelinkteVraag.vraag}` });
          const gelinktVraagBlok = meldingHelpers.getVraagBlok(gelinkteVraagWithKey, vragen);
          if (gelinktVraagBlok) {
            reset(gelinkteVraag, gelinktVraagBlok);
          }
        });
      }
      // eslint-disable-next-line
    }, [values, setFieldValue, gelinktAntwoord, vraagBlok, antwoord, antwoorden, lang]);

    return <Component {...props} />;
  };
};
