/**
 * Replace an item in an array at the specified index, returning the newly created array.
 * The original array will not be modified.
 * @param array The source array.
 * @param index The index of the item to replace.
 * @param newItem The item to replace the source item with.
 * @returns A new array with the replaced item.
 */
const replaceAt = <TItem>(array: TItem[], index: number, newItem: TItem): TItem[] => {
  return array.map((item, itemIndex) => {
    if (itemIndex === index) {
      return newItem;
    }
    return item;
  });
};

/**
 * Replace an item in an array, returning the newly created array.
 * The original array will not be modified.
 * @param array The source array.
 * @param itemToReplace The item to replace.
 * @param newItem The item to replace the source item with.
 * @returns A new array with the replaced item.
 */
const replace = <TItem>(array: TItem[], itemToReplace: TItem, newItem: TItem): TItem[] => {
  return array.map((item) => {
    if (item === itemToReplace) {
      return newItem;
    }
    return item;
  });
};

export const arrayHelpers = {
  replaceAt,
  replace
};
