import { InputPurpose } from "./input-purpose";
import { VraagDisplayDefinition } from "./vraag-display-definition";

export class ContactpersoonVragenDisplayDefinition {
  public achternaam: VraagDisplayDefinition = {
    inputPurpose: InputPurpose.familyName
  };

  public emailadres: VraagDisplayDefinition = {
    inputPurpose: InputPurpose.email
  };

  public telefoonnummer: VraagDisplayDefinition = {
    inputPurpose: InputPurpose.telephone
  };
}
