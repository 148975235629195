import { BijlageVragen, MelderVragen, PredicaatKoninklijkAanvraagVragen, VraagBlokken } from "../blok";
import { HasAanvragerVragen } from "./has-aanvrager-vragen";
import { HasBijlageVragen } from "./has-bijlage-vragen";
import { HasPredicaatKoninklijkAanvraagVragen } from "./has-predicaat-koninklijk-aanvraag-vragen";

export class PredicaatKoninklijkFormulierVraagBlokken
  extends VraagBlokken
  implements HasPredicaatKoninklijkAanvraagVragen, HasAanvragerVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(
    public aanvraag: PredicaatKoninklijkAanvraagVragen,
    public aanvrager: MelderVragen,
    public bijlage: BijlageVragen
  ) {
    super();
  }

  static fromJson(json: any): PredicaatKoninklijkFormulierVraagBlokken {
    return new PredicaatKoninklijkFormulierVraagBlokken(
      PredicaatKoninklijkAanvraagVragen.fromJson("aanvraag", json.aanvraag),
      MelderVragen.fromJson("aanvrager", json.aanvrager),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
