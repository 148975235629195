import { AntwoordBlok, expressionParser, HasConditioneelGesteld, HasKey, VraagBlok } from "../models/api";
import { ExpressionContext } from "../models/api/blok/expression-context";
import { HasBerekendeWaarde } from "./../models/api/has-berekende-waarde";
import { HasConditioneelReadonly } from "./../models/api/has-conditioneel-readonly";

const wordtItemGesteld = (
  item: HasConditioneelGesteld & HasKey,
  vraagBlok: VraagBlok,
  context: ExpressionContext
): boolean => {
  const remainingKey = item.key.replace(`${vraagBlok.key}.`, "");

  return wordtItemByKeyGesteld(remainingKey, vraagBlok, context);
};

const isSamengesteldeVraagKey = (key: string): boolean => {
  return !!key.match(/^waarde\.\d/);
};

const wordtItemByKeyGesteld = (key: string, vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
  const [first, ...rest] = key.split(".");

  const item: HasConditioneelGesteld & HasKey = vraagBlok[first];

  const wordtGesteld =
    item &&
    (!item.conditioneelGesteld || expressionParser.fromJsonConditioneelGesteld(item)?.execute(vraagBlok, context) !== false);

  const restKey = rest.join(".");

  const currentAntwoordBlok = context.currentAntwoordBlok && (context.currentAntwoordBlok[first] as AntwoordBlok);

  return (
    wordtGesteld &&
    (rest.length === 0 ||
      !currentAntwoordBlok ||
      isSamengesteldeVraagKey(restKey) ||
      wordtItemByKeyGesteld(
        restKey,
        item as VraagBlok,
        new ExpressionContext(
          context.currentUser,
          context.autorisatiegegevens,
          context.meldingContext,
          currentAntwoordBlok,
          context.antwoordBlokken,
          context.vraagBlokken
        )
      ))
  );
};

const isVraagBlokReadonly = (vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
  return expressionParser.fromJsonConditioneelReadonly(vraagBlok)?.execute(vraagBlok, context) === true;
};

const isItemReadonly = (
  item: HasConditioneelReadonly & HasKey,
  vraagBlok: VraagBlok,
  context: ExpressionContext
): boolean => {
  const remainingKey = item.key.replace(`${vraagBlok.key}.`, "");

  return isItemByKeyReadonly(remainingKey, vraagBlok, context);
};

const isItemByKeyReadonly = (key: string, vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
  const [first, ...rest] = key.split(".");

  const item: HasConditioneelReadonly & HasKey = vraagBlok[first];
  const isReadonly = item && expressionParser.fromJsonConditioneelReadonly(item)?.execute(vraagBlok, context) === true;

  return (
    isReadonly ||
    (rest.length > 0 &&
      executeBerekendeWaardeByKey(
        rest.join("."),
        item as VraagBlok,
        new ExpressionContext(
          context.currentUser,
          context.autorisatiegegevens,
          context.meldingContext,
          context.currentAntwoordBlok[first] as AntwoordBlok,
          context.antwoordBlokken,
          context.vraagBlokken
        )
      ))
  );
};

const executeBerekendeWaarde = (
  item: HasBerekendeWaarde & HasKey,
  vraagBlok: VraagBlok,
  context: ExpressionContext
): boolean => {
  const remainingKey = item.key.replace(`${vraagBlok.key}.`, "");

  return executeBerekendeWaardeByKey(remainingKey, vraagBlok, context);
};

const executeBerekendeWaardeByKey = (key: string, vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
  const [first] = key.split(".");

  const item: HasBerekendeWaarde & HasKey = vraagBlok[first];
  return item && expressionParser.fromJsonBerekendeWaarde(item)?.execute(vraagBlok, context) === true;
};

export const expressionHelpers = {
  executeBerekendeWaarde,
  isVraagBlokReadonly,
  isItemReadonly,
  wordtItemGesteld,
  wordtItemByKeyGesteld
};
