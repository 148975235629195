import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class PeriodeEerdereOntheffingAntwoorden extends AntwoordBlok {
  constructor(public begindatum: Antwoord<string>, public einddatum: Antwoord<string>) {
    super();
  }

  static initialize(): PeriodeEerdereOntheffingAntwoorden {
    return new PeriodeEerdereOntheffingAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): PeriodeEerdereOntheffingAntwoorden {
    return new PeriodeEerdereOntheffingAntwoorden(
      Antwoord.fromJson<string>(json.begindatum),
      Antwoord.fromJson<string>(json.einddatum)
    );
  }
}
