import { connect } from "react-redux";
import { resetZoekMeldingen, setZoekMeldingenFilter } from "../../../actions/zoek-meldingen-actions";
import { MeldingFilters } from "../../../models/api/zoek";
import { Formuliercategorie } from "../../../models/application";
import { ColumnSortOption, State } from "../../../store";
import { zoekPlaatsen } from "../../../thunks/blok/nederlands-adres-thunks";
import { getFormulieren } from "../../../thunks/meldingen-thunks";
import { zoekMeldingen } from "../../../thunks/zoek-meldingen-thunks";
import { ZoekMeldingen } from "./zoek-meldingen.component";
import { ZoekMeldingenDispatchProps, ZoekMeldingenStateProps } from "./zoek-meldingen.component.interfaces";

const mapStateToProps = (state: State): ZoekMeldingenStateProps => ({
  isLoading: state.zoekMeldingen.isLoading,
  hasApiError: state.zoekMeldingen.hasApiError,
  meldingen: state.zoekMeldingen.meldingen,
  totaalAantalMeldingen: state.zoekMeldingen.totalItemCount,
  filter: state.zoekMeldingen.filter,
  formulierTypes: state.meldingen.formulierTypes.filter((ft) => ft.formuliercategorie === Formuliercategorie.Kennisgeving),
  pageNumber: state.zoekMeldingen.pageNumber,
  pageSize: state.zoekMeldingen.pageSize,
  sortOption: state.zoekMeldingen.sortOption,
  plaatsen: state.nederlandsAdres.plaatsen["zoekMeldingen.plaats"] ?? []
});

const mapDispatchToProps = (dispatch: any): ZoekMeldingenDispatchProps => ({
  onLoad: () => {
    dispatch(getFormulieren());
    dispatch(resetZoekMeldingen());
  },
  onSearch: async (options: { filter?: MeldingFilters; sortOption?: ColumnSortOption; pageNumber?: number }) => {
    dispatch(setZoekMeldingenFilter(options));
    dispatch(zoekMeldingen());
  },
  onSearchOptionChange: (sortOption: ColumnSortOption, pageNumber: number) => {
    dispatch(setZoekMeldingenFilter({ sortOption: sortOption, pageNumber: pageNumber }));
    dispatch(zoekMeldingen());
  },
  onReset: () => {
    dispatch(resetZoekMeldingen());
  },
  onSearchPlaatsen: (naam: string) => dispatch(zoekPlaatsen(naam, "zoekMeldingen.plaats"))
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoekMeldingen);
