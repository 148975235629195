import { useField } from "formik";
import { useCallback, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../configuration";
import { Field } from "../field";

export const Recaptcha = (props: any) => {
  const { name, label, touched, error, onChange: onWithValidationChange, ...propsRest } = props;

  const { i18n } = useTranslation();

  const lang: string = i18n.language;

  const [, meta, helpers] = useField(props);

  const captcha = useRef<ReCAPTCHA>(null);
  const currentValue = captcha.current?.getValue();

  const onChange = useCallback(
    (token: string | null) => {
      helpers.setValue(token);
      helpers.setTouched(true);

      if (token) {
        helpers.setError(undefined);
      }

      if (onWithValidationChange) {
        onWithValidationChange(token);
      }
    },
    [helpers, onWithValidationChange]
  );

  const onExpired = (): void => {
    resetForm();
  };

  const resetForm = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const resetCaptcha = useCallback(() => {
    captcha.current?.reset();
  }, [captcha]);

  useEffect(() => {
    // Show error when captcha not yet touched
    if (error && !touched) {
      helpers.setTouched(true);
    }

    // Reset form values
    // - After a previously failed form submit
    // - When going back and forth a step
    if (!currentValue && meta.value && !props.submitting) {
      resetForm();
    }

    // Reset captcha upon submit
    if (props.submitting && currentValue) {
      resetCaptcha();
    }
  }, [error, touched, helpers, props.submitting, currentValue, meta, resetCaptcha, resetForm]);

  return (
    <Field name={name} label={props.label} fullWidth={true} {...propsRest}>
      <ReCAPTCHA
        ref={captcha}
        hl={lang}
        sitekey={Configuration.recaptchaSiteKey}
        onChange={onChange}
        onExpired={onExpired}
      />
    </Field>
  );
};
