import {
  AannemersVragen,
  BouwprocesWerkzaamhedenVragen,
  BouwwerkVragen,
  MelderVragen,
  OntwerpendePartijVragen,
  OpdrachtgeverBouwprocesVragen,
  UitvoerendePartijVragen,
  VraagBlokken
} from "../blok";
import { HasAannemersVragen } from "./has-aannemers-vragen";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasOntwerpendePartijVragen } from "./has-ontwerpende-partij-vragen";
import { HasOpdrachtgeverBouwprocesVragen } from "./has-opdrachtgever-bouwproces-vragen";
import { HasUitvoerendePartijVragen } from "./has-uitvoerende-partij-vragen";
import { HasWerkzaamhedenVragen } from "./has-werkzaamheden-vragen";

export class BouwprocesFormulierVraagBlokken
  extends VraagBlokken
  implements
    HasMelderVragen,
    HasOpdrachtgeverBouwprocesVragen,
    HasOntwerpendePartijVragen,
    HasUitvoerendePartijVragen,
    HasAannemersVragen,
    HasWerkzaamhedenVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public bouwwerk: BouwwerkVragen,
    public opdrachtgever: OpdrachtgeverBouwprocesVragen,
    public ontwerpendePartij: OntwerpendePartijVragen,
    public uitvoerendePartij: UitvoerendePartijVragen,
    public aannemers: AannemersVragen,
    public werkzaamheden: BouwprocesWerkzaamhedenVragen
  ) {
    super();
  }

  static fromJson(json: any): BouwprocesFormulierVraagBlokken {
    return new BouwprocesFormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      BouwwerkVragen.fromJson("bouwwerk", json.bouwwerk),
      OpdrachtgeverBouwprocesVragen.fromJson("opdrachtgever", json.opdrachtgever),
      OntwerpendePartijVragen.fromJson("ontwerpendePartij", json.ontwerpendePartij),
      UitvoerendePartijVragen.fromJson("uitvoerendePartij", json.uitvoerendePartij),
      AannemersVragen.fromJson("aannemers", json.aannemers),
      BouwprocesWerkzaamhedenVragen.fromJson("werkzaamheden", json.werkzaamheden)
    );
  }
}
