import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { FormulierBreadcrumbs } from "../breadcrumbs";
import { FormulierHeader } from "../formulier-header";
import { GuardedLink } from "../guarded-link";
import { MainContent } from "../main-content";
import { DownloadSamenvattingButton } from "../samenvatting";
import { FormulierOpgeslagenBevestigingProps } from "./formulier-opgeslagen-bevestiging.component.interfaces";

export const FormulierOpgeslagenBevestiging = (props: FormulierOpgeslagenBevestigingProps) => {
  const { t } = useTranslation();

  return props.melding && props.formulier ? (
    <Row className="main-container">
      <Col xs={12} className="main-container-title">
        <FormulierHeader formulier={props.formulier} />
      </Col>
      <Col xs={12} className="main-container-content">
        <FormulierBreadcrumbs formulierTitel={props.formulier.titel} formulierType={props.melding.formuliertype} />
        <MainContent>
          <div className="main-form">
            <h2>{t(props.opgeslagenBevestigingTranslationKey.titel)}</h2>
            <div id="formulierBevestigingMelding">
              <ReactMarkdown
                children={t(props.opgeslagenBevestigingTranslationKey.melding, {
                  meldingnummer: props.melding.meldingnummer,
                  emailadres: props.emailadres
                })}
              />
            </div>
            <DownloadSamenvattingButton
              className="download-pdf"
              formuliertype={props.melding.formuliertype}
              meldingId={props.melding.meldingId}
            />
            <div id="mijnMeldingen" className="mt-4">
              <GuardedLink url="/mijnmeldingen">{t(props.opgeslagenBevestigingTranslationKey.mijnMeldingen)}</GuardedLink>
            </div>
          </div>
        </MainContent>
      </Col>
    </Row>
  ) : null;
};
