import {
  BijlageVragen,
  KlachtenBedrijfsgegevensWerklocatieVragen,
  KlachtenMelderVragen,
  MeldingVragen,
  VraagBlokken
} from "../blok";
import { HasBijlageVragen } from "./has-bijlage-vragen";
import { HasKlachtenBedrijfsgegevensWerklocatieVragen } from "./has-klachten-bedrijfsgegevens-vragen";
import { HasKlachtenMelderVragen } from "./has-klachten-melder-vragen";
import { HasMeldingVragen } from "./has-melding-vragen";

export class KlachtenFormulierVraagBlokken
  extends VraagBlokken
  implements HasKlachtenMelderVragen, HasKlachtenBedrijfsgegevensWerklocatieVragen, HasMeldingVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(
    public melder: KlachtenMelderVragen,
    public bedrijfsgegevensWerklocatie: KlachtenBedrijfsgegevensWerklocatieVragen,
    public melding: MeldingVragen,
    public bijlage: BijlageVragen
  ) {
    super();
  }

  static fromJson(json: any): KlachtenFormulierVraagBlokken {
    return new KlachtenFormulierVraagBlokken(
      KlachtenMelderVragen.fromJson("melder", json.melder),
      KlachtenBedrijfsgegevensWerklocatieVragen.fromJson("bedrijfsgegevensWerklocatie", json.bedrijfsgegevensWerklocatie),
      MeldingVragen.fromJson("melding", json.melding),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
