import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { ContactpersoonVragen } from "./contactpersoon-vragen";

export class ReactieOpenbaarmakingReactieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public reactieOpenbaarmakingType: Meerkeuzevraag,
    public referentienummerBrief: Vraag,
    public datumDagtekening: Vraag,
    public reageerder: ContactpersoonVragen,
    public reactie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ReactieOpenbaarmakingReactieVragen {
    return new ReactieOpenbaarmakingReactieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.reactieOpenbaarmakingType`, json.reactieOpenbaarmakingType),
      jsonHelpers.toTekstVraag(`${key}.referentienummerBrief`, json.referentienummerBrief),
      jsonHelpers.toDatumVraag(`${key}.datumDagtekening`, json.datumDagtekening),
      ContactpersoonVragen.fromJson(`${key}.reageerder`, json.reageerder),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.reactie`, json.reactie)
    );
  }
}
