import { userHelpers } from "helpers";
import { Navigate, Outlet } from "react-router";
import { State, store } from "store";

export const BeheerStatusGuard = () => {
  const user = (store.getState() as State).security.user;
  const isBeheerder = userHelpers.getIsBeheerderUser(user);
  const isServicedesk = userHelpers.getIsServicedeskUser(user);

  if (!isBeheerder && !isServicedesk) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};
