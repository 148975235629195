import { Bestand } from "../blok/bestand";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class HasEmptyFiles extends VraagExpression {
  get operator(): string {
    return HasEmptyFiles.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;

    return antwoord && (antwoord as Bestand[]).some((x) => x.grootte <= 0);
  };

  protected clone = (instance: HasEmptyFiles): HasEmptyFiles => new HasEmptyFiles(instance.vraagProperty);

  static fromJson = (json: any): HasEmptyFiles => new HasEmptyFiles(json.vraag);

  static operator = (): string => "hasEmptyFiles";

  static matches = (json: any): boolean => json?.operator === HasEmptyFiles.operator();
}
