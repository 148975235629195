import { asyncHelpers, focusHelpers } from "helpers";
import React, { Fragment, useEffect, useRef } from "react";
import { Accordion as BSAccordion, Card as BSCard } from "react-bootstrap";
import { CollectionRemove } from "../../../collection-remove";
import { CollectionTableRowSummary } from "../collection-table-row-summary";
import { CollectionTableRowToggleButton } from "../collection-table-row-toggle/button";
import { CollectionTableRowProps } from "./collection-table-row.component.interfaces";

export const CollectionTableRow = (props: CollectionTableRowProps) => {
  const collectionItemContainer = useRef(null);
  const focusDelayMs = 100;

  const { focusOnFirstVraag, selectedCollectionItemKey, vraagAntwoordBlok, onFirstVraagFocused } = props;

  useEffect(() => {
    if (
      focusOnFirstVraag &&
      selectedCollectionItemKey === vraagAntwoordBlok.vraagBlok.key &&
      collectionItemContainer.current
    ) {
      asyncHelpers.delay(focusDelayMs).then(() => {
        focusHelpers.setFocusOnFirstInput(collectionItemContainer);
        onFirstVraagFocused();
      });
    }
  }, [focusOnFirstVraag, selectedCollectionItemKey, vraagAntwoordBlok.vraagBlok.key, onFirstVraagFocused]);

  return (
    <Fragment key={`frag-${props.rowIndex}`}>
      <CollectionTableRowSummary
        columnSettings={props.columnSettings}
        rowIndex={props.rowIndex}
        vraagAntwoordBlok={props.vraagAntwoordBlok}
        collectionKey={props.collectionKey}
        collectionVerplichtType={props.collectionVerplichtType}
        removeCollectionItem={props.removeCollectionItem}
        template={props.template}
        getVraagBlokFromTemplate={props.getVraagBlokFromTemplate}
        copyAntwoorden={props.copyAntwoorden}
      />
      <tr key={`row-${props.rowIndex}-detail`} className={`hide-table-padding`}>
        <td
          key={`cell-${props.rowIndex}-detail`}
          colSpan={Object.values(props.columnSettings.columns).filter((col) => col.visible !== false).length + 1}
        >
          <BSAccordion.Collapse eventKey={props.vraagAntwoordBlok.vraagBlok.key}>
            <BSCard.Body key={props.vraagAntwoordBlok.vraagBlok.key}>
              <Fragment>
                <div ref={collectionItemContainer}>
                  {React.createElement(props.itemComponent, {
                    vragen: props.vraagAntwoordBlok.vraagBlok,
                    key: props.vraagAntwoordBlok.vraagBlok.key,
                    processtap: props.processtapType
                  })}
                </div>
                <div className="float-end">
                  <CollectionTableRowToggleButton
                    collectionKey={props.collectionKey}
                    rowKey={props.vraagAntwoordBlok.vraagBlok.key}
                    rowIndex={props.rowIndex}
                  />
                </div>
                <CollectionRemove
                  vraagAntwoordBlok={props.vraagAntwoordBlok}
                  collectionKey={props.collectionKey}
                  collectionVerplichtType={props.collectionVerplichtType}
                  rowIndex={props.rowIndex}
                  removeCollectionItem={props.removeCollectionItem}
                />
              </Fragment>
            </BSCard.Body>
          </BSAccordion.Collapse>
        </td>
      </tr>
    </Fragment>
  );
};
