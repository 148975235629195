import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, MelderAntwoorden, PredicaatKoninklijkMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initPredicaatKoninklijkFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getPredicaatKoninklijkFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getPredicaatKoninklijkFormulierDefinitie,
      PredicaatKoninklijkMeldingAntwoordBlokken.initialize
    );
  };

export const initPredicaatKoninklijkFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getPredicaatKoninklijkFormulier(),
      meldingApi.getPredicaatKoninklijkMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): PredicaatKoninklijkMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        aanvrager: MelderAntwoorden.initialize(user),
        bijlage: {
          ...melding.blokken.bijlage,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getPredicaatKoninklijkFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
