import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { DebouncedFormControl } from "../debounce-input";
import { Field } from "../field";
import { Spinner } from "./../../spinner";

export const InputTime = (props: any) => {
  const { name, touched, error, serverError, onBlur, loading, placeholder, ...propsRest } = props;

  const { inputOnly, label, ...propsControl } = propsRest;

  const { t } = useTranslation();

  const isInvalid = (touched && error) || serverError;

  return (
    <Field name={name} {...propsRest}>
      <DebouncedFormControl
        name={name}
        id={name}
        isInvalid={isInvalid}
        placeholder={t(translationKeys.placeholders.tijd)}
        onBlur={onBlur}
        {...{ "aria-label": props.label }}
        {...propsControl}
      />
      {loading && <Spinner />}
    </Field>
  );
};
