import moment from "moment";
import { TimeExpressionBase } from "./time-expression-base";

export class IsLaterThan extends TimeExpressionBase {
  get operator(): string {
    return IsLaterThan.operator();
  }

  constructor(public readonly vraagProperty: string, protected readonly tijd: string) {
    super(vraagProperty, tijd);
  }

  protected DateCompare = (tijdAntwoord: moment.Moment, tijdCompare: moment.Moment): boolean => tijdAntwoord > tijdCompare;

  protected clone = (instance: IsLaterThan): IsLaterThan => new IsLaterThan(instance.vraagProperty, instance.tijd);

  static fromJson = (json: any): IsLaterThan => new IsLaterThan(json.vraag, json.tijd);

  static operator = (): string => "isLaterThan";

  static matches = (json: any): boolean => json?.operator === IsLaterThan.operator();
}
