import { AndereWerkgeverAntwoorden, Antwoord, AntwoordBlokCollection } from "..";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { BedrijfslocatiegegevensAntwoorden } from "./bedrijfslocatiegegevens-antwoorden";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";

export class OntheffingNachtarbeidWerkgeverAntwoorden extends AntwoordBlok {
  constructor(
    public werkgever: BedrijfslocatiegegevensAntwoorden | null,
    public contactpersoon: ContactpersoonAntwoorden | null,
    public werkzaamOpHoofdlocatie: Antwoord<string>,
    public werklocatie: BedrijfslocatiegegevensAntwoorden | null,
    public andereWerkgevers: AntwoordBlokCollection<AndereWerkgeverAntwoorden> | null
  ) {
    super();
  }

  static initialize(): OntheffingNachtarbeidWerkgeverAntwoorden {
    return new OntheffingNachtarbeidWerkgeverAntwoorden(
      BedrijfslocatiegegevensAntwoorden.initialize(),
      ContactpersoonAntwoorden.initialize(),
      new Antwoord<string>(""),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      AntwoordBlokCollection.initialize([])
    );
  }

  static fromJson(json: any): OntheffingNachtarbeidWerkgeverAntwoorden {
    return new OntheffingNachtarbeidWerkgeverAntwoorden(
      BedrijfslocatiegegevensAntwoorden.fromJson(json.werkgever),
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      Antwoord.fromJson<string>(json.werkzaamOpHoofdlocatie),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.werklocatie),
      AntwoordBlokCollection.fromJson(AndereWerkgeverAntwoorden.fromJson, json.andereWerkgevers)
    );
  }
}
