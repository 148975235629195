import { useTranslation } from "react-i18next";
import { displayValues } from "../../../constants";
import { translationKeys } from "../../../constants/translation-keys";
import { dateHelpers } from "../../../helpers";
import { SamenvattingField } from "../samenvatting-field";
import { SamenvattingMeldingDataProps } from "./samenvatting-melding-data.component.interfaces";

export const SamenvattingMeldingData = (props: SamenvattingMeldingDataProps) => {
  const { t } = useTranslation();

  return (
    <section key="samenvatting-melding-data-section">
      <h2>{t(translationKeys.meldinggegevens)}</h2>
      <div className="samenvatting-blok">
        <div>
          <SamenvattingField
            id={`blok.melding-data.meldingnummer`}
            waarde={props.meldingNummer?.toString()}
            label={t(translationKeys.melding.meldingen.tabelkop.meldingnummer.title)}
          />
          {props.showZaakLinks && (
            <SamenvattingField id={`blok.melding-data.zaken`} label={t(translationKeys.blok.samenvatting.zaken)}>
              {props.zaakLinks?.map((zl) => (
                <span key={`zaak-${zl.nummer}`} className="comma-separated">
                  <a href={zl.url} target="_blank" id={`zaak-${zl.nummer}`} rel="noreferrer">
                    {zl.nummer}
                  </a>
                </span>
              ))}
            </SamenvattingField>
          )}
          <SamenvattingField
            id={`blok.melding-data.status`}
            waarde={props.status ? t(translationKeys.melding.status[props.status]) : displayValues.noValue}
            label={t(translationKeys.melding.meldingen.tabelkop.status.title)}
          />
          {props.datumIngediend && (
            <SamenvattingField
              id={`blok.melding-data.datumIngediend`}
              waarde={props.datumIngediend.format(dateHelpers.dateDisplayFormat)}
              label={t(translationKeys.melding.meldingen.tabelkop.aangemaaktOp.title)}
            />
          )}
          {props.verzenddatum && props.versie && props.versie > 1 && (
            <SamenvattingField
              id={`blok.melding-data.datumGewijzigd`}
              waarde={props.verzenddatum.format(dateHelpers.dateDisplayFormat)}
              label={t(translationKeys.melding.meldingen.tabelkop.gewijzigdOp.title)}
            />
          )}
          {props.datumIntrekking && (
            <SamenvattingField
              id={`blok.melding-data.datumIntrekking`}
              waarde={props.datumIntrekking.format(dateHelpers.dateDisplayFormat)}
              label={t(translationKeys.melding.meldingen.tabelkop.ingetrokkenOp.title)}
            />
          )}
          {props.laatstGewijzigdDoor && (
            <SamenvattingField
              id={`blok.melding-data.laatstGewijzigdDoor`}
              waarde={props.laatstGewijzigdDoor}
              label={t(translationKeys.melding.meldingen.tabelkop.laatstGewijzigdDoor.title)}
            />
          )}
        </div>
      </div>
    </section>
  );
};
