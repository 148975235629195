import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { CollectionTableRowToggle } from "..";
import { CollectionRowToggleHeaderProps } from "./collection-row-toggle-header.component.interfaces";

export const CollectionRowToggleHeader = (props: CollectionRowToggleHeaderProps) => {
  return (
    <Fragment>
      <CollectionTableRowToggle {...props}>
        {(title, currentItem, toggleEvent) => (
          <button type="button" className="icon-button-start" aria-label={title} onClick={toggleEvent}>
            <span className="visually-hidden">{title}</span>
            <FontAwesomeIcon icon={currentItem ? faChevronUp : faChevronDown} size="1x" />
            {props.children}
          </button>
        )}
      </CollectionTableRowToggle>
    </Fragment>
  );
};
