import { VluchtigeOrganischeStoffenFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getVluchtigeOrganischeStoffenFormulierDefinitie = (
  formulier: VluchtigeOrganischeStoffenFormulier
): BlokDefinitie[] => [
  {
    title: formulier.blokken.aanvrager.titel,
    type: ProcesstapType.aanvrager
  },
  {
    title: formulier.blokken.locatie.titelVerkort,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.aanvraag.titel,
    type: ProcesstapType.aanvraag
  },
  {
    title: formulier.blokken.motivatie.titel,
    type: ProcesstapType.motivatie
  }
];
