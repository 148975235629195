import { landHelpers, meldingHelpers } from "helpers";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { store } from "store";
import { translationKeys } from "../../../../constants";
import { ReadOnlyControls } from "../../../read-only-controls";
import { VisibleWrapper } from "../../../visible-wrapper";
import { VestigingFormDetailsProps } from "./vestiging-form-details.component.interfaces";

export const VestigingFormDetails = (props: VestigingFormDetailsProps) => {
  const { t } = useTranslation();

  const { name } = props;
  const isIngediendeKunstkinderenMelding = meldingHelpers.determineIngediendeKunstkinderenMelding(store.getState());
  const adres = `${props.vestiging.ves_straat ?? ""} ${props.vestiging.ves_huisnr ?? ""}${
    props.vestiging.ves_toev_huisnr ?? ""
  }`;

  return (
    <div className="details">
      <h3>{t(translationKeys.vestigingenSearch.vestigingGegevens)}</h3>
      <ReadOnlyControls.ReadOnly
        name={`${props.name}-naam`}
        label={t(translationKeys.vestigingenSearch.naam)}
        value={props.vestiging.ves_naam}
      />
      <ReadOnlyControls.ReadOnly
        name={`${props.name}-kvkNummer`}
        label={t(translationKeys.vestigingenSearch.kvknummer)}
        value={props.vestiging.ves_kvknr}
      />
      <ReadOnlyControls.ReadOnly
        name={`${props.name}-vestigingsnummer`}
        label={t(translationKeys.vestigingenSearch.vestigingsnummer)}
        value={props.vestiging.ves_vest_nr}
      />
      <ReadOnlyControls.ReadOnly
        name={`${props.name}-adres`}
        label={t(translationKeys.vestigingenSearch.adres)}
        value={adres}
      />
      <ReadOnlyControls.ReadOnly
        name={`${props.name}-postcode`}
        label={t(translationKeys.vestigingenSearch.postcode)}
        value={props.vestiging.ves_postcode}
      />
      <ReadOnlyControls.ReadOnly
        name={`${props.name}-plaats`}
        label={t(translationKeys.vestigingenSearch.plaats)}
        value={props.vestiging.ves_plaats}
      />
      <ReadOnlyControls.ReadOnly
        name={`${props.name}-land`}
        label={t(translationKeys.vestigingenSearch.land)}
        value={landHelpers.getLandNaam(props.landen, props.vestiging.LAN_NAAM)}
      />

      <VisibleWrapper isVisible={!props.readOnly && !isIngediendeKunstkinderenMelding}>
        <Form.Group className="form-group">
          <div className="input-container">
            <Button onClick={props.onChangeVestiging} className="float-end" name={`${name}.vestiging-form-edit`}>
              {t(translationKeys.vestigingenSearch.wijzigen)}
            </Button>
          </div>
        </Form.Group>
      </VisibleWrapper>
    </div>
  );
};
