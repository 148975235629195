import { GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken } from "models/api/melding/gevaarlijke-gassen-importcontainers-melding-antwoord-blokken";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { GevaarlijkeGassenImportcontainersMelding, MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { GevaarlijkeGassenImportcontainersFormulierComponentProps } from "./gevaarlijke-gassen-importcontainers-formulier.component.interfaces";

export const GevaarlijkeGassenImportcontainersFormulierComponent = (
  props: GevaarlijkeGassenImportcontainersFormulierComponentProps
) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.gevaarlijkeGassenImportcontainers;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const blokDefinitie = FormulierDefinitie.getGevaarlijkeGassenImportcontainersFormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(GevaarlijkeGassenImportcontainersMelding)}
        getEmailadres={(blokken: GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken) =>
          blokken.melder.melder?.emailadres.waarde
        }
      ></Formulier>
    );
  } else {
    return null;
  }
};
