import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants";
import { stringHelpers } from "../../../helpers";
import { ColumnSortOption, Order } from "../../../store";

const SortIcon = (props: { currentSorting: ColumnSortOption | null; columnName: string; title: string }) => {
  const orderAscending =
    props.currentSorting && props.currentSorting.columnName === props.columnName
      ? props.currentSorting.order === Order.ascending
      : null;

  const orderIcon = orderAscending ? faChevronUp : faChevronDown;

  let iconToShow = <span className="sort-icon"></span>;
  if (orderAscending !== null) {
    iconToShow = <FontAwesomeIcon icon={orderIcon} aria-label={props.title} className="sort-icon" title={props.title} />;
  }

  return <Fragment>{iconToShow}</Fragment>;
};

export const TableHeader = (props: {
  currentSorting: ColumnSortOption | null;
  columnName: string;
  columnTitleTranslationBase: any;
  onSortOptionChange: (columnName: string) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { columnTitleTranslationBase: translationBase, columnName, currentSorting } = props;

  const columnTitle = translationBase[columnName]?.title ? t(translationBase[columnName]?.title) : "No-Title";
  const columnHint = translationBase[columnName]?.hint ? t(translationBase[columnName]?.hint) : columnTitle;
  const columnIconTitle = t(
    translationKeys.tabel.kolom.sortering[currentSorting?.order === Order.ascending ? "oplopend" : "aflopend"],
    { kolomnaam: columnTitle }
  );

  const sortOptionChanged = () => {
    if (!props.disabled) {
      props.onSortOptionChange(columnName);
    }
  };

  return (
    <th title={columnHint}>
      <div
        className={`aligner${props.disabled ? " disabled" : ""}`}
        role="button"
        aria-label={columnIconTitle}
        onClick={sortOptionChanged}
      >
        {columnTitle}
        <SortIcon currentSorting={currentSorting} columnName={columnName} title={columnIconTitle} />
      </div>
    </th>
  );
};

export const matchColumnWithSearchValue = (
  columnValue: string,
  searchValue: string,
  searchMatcher?: (columnValue: string, searchValue: string) => boolean | undefined
): boolean => {
  const columnValueLowercase = stringHelpers.toLocalLowerCase(columnValue);
  const searchValueLowercase = stringHelpers.toLocalLowerCase(searchValue);

  let match: boolean | undefined = undefined;

  // If search handler is set, use this to determine if the search is a hit, if undefined is
  // returned use the default 'includes' for a column
  if (searchMatcher) {
    match = searchMatcher(columnValueLowercase, searchValueLowercase);
  }

  if (match === undefined) {
    match = columnValueLowercase.includes(searchValueLowercase);
  }

  return match;
};
