import {
  AntwoordBlokken,
  MelderAntwoorden,
  OpdrachtgeverVuurOpTankschipAntwoorden,
  PeriodeMetRedenAntwoorden,
  TankschipAntwoorden,
  TankschipWerkzaamhedenAntwoorden,
  VerklaringenAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasAanvragerAntwoorden } from "./has-aanvrager-antwoorden";
import { HasPeriodeMetRedenAntwoorden } from "./has-periode-met-reden-antwoorden";
import { HasTankschipAntwoorden } from "./has-tankschip-antwoorden";
import { HasTankschipWerkzaamhedenAntwoorden } from "./has-tankschipwerkzaamheden-antwoorden";
import { HasVerklaringenAntwoorden } from "./has-verklaringen-antwoorden";
import { HasWerkgeverAntwoorden } from "./has-werkgever-antwoorden";

export class VuurOpTankschipMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasAanvragerAntwoorden,
    HasWerkgeverAntwoorden,
    HasTankschipAntwoorden,
    HasPeriodeMetRedenAntwoorden,
    HasTankschipWerkzaamhedenAntwoorden,
    HasVerklaringenAntwoorden
{
  constructor(
    public aanvrager: MelderAntwoorden,
    public werkgever: OpdrachtgeverVuurOpTankschipAntwoorden,
    public tankschip: TankschipAntwoorden,
    public periode: PeriodeMetRedenAntwoorden,
    public werkzaamheden: TankschipWerkzaamhedenAntwoorden,
    public verklaringen: VerklaringenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): VuurOpTankschipMeldingAntwoordBlokken {
    return new VuurOpTankschipMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      OpdrachtgeverVuurOpTankschipAntwoorden.initialize(),
      TankschipAntwoorden.initialize(),
      PeriodeMetRedenAntwoorden.initialize(),
      TankschipWerkzaamhedenAntwoorden.initialize(),
      VerklaringenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): VuurOpTankschipMeldingAntwoordBlokken {
    return new VuurOpTankschipMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.aanvrager),
      OpdrachtgeverVuurOpTankschipAntwoorden.fromJson(json.werkgever),
      TankschipAntwoorden.fromJson(json.tankschip),
      PeriodeMetRedenAntwoorden.fromJson(json.periode),
      TankschipWerkzaamhedenAntwoorden.fromJson(json.werkzaamheden),
      VerklaringenAntwoorden.fromJson(json.verklaringen)
    );
  }
}
