import { withSamenvattingVertaling } from "components/input-controls/with-vertaling";
import { withTekst } from "../formulier/with-tekst";
import { VraagBlokCollectionSamenvatting } from "./../blok/vraag-blok-collection-samenvatting";
import { SamenvattingBijlagen } from "./samenvatting-bijlagen";
import { SamenvattingField } from "./samenvatting-field";
import { Title } from "./title";
import { withConditioneelGesteldeSamenvattingItem } from "./with-conditioneel-gestelde-samenvatting-item";
import { withSamenvattingBestandenvraag } from "./with-samenvatting-bestandenvraag";
import { withSamenvattingChecklistvraag } from "./with-samenvatting-checklistvraag";
import { withSamenvattingCollectionTypeaheadVraag } from "./with-samenvatting-collection-typeahead-vraag";
import { withSamenvattingDatumTijdvraag } from "./with-samenvatting-datum-tijdvraag";
import { withSamenvattingDatumvraag } from "./with-samenvatting-datumvraag";
import { withSamenvattingGebrokenAantalvraag } from "./with-samenvatting-gebroken-aantalvraag";
import { withSamenvattingGeneralVraag } from "./with-samenvatting-general-vraag";
import { withSamenvattingInventarisatievraag } from "./with-samenvatting-inventarisatievraag";
import { withSamenvattingLandvraag } from "./with-samenvatting-landvraag/";
import { withSamenvattingMeerkeuzevraag } from "./with-samenvatting-meerkeuzevraag";
import { withSamenvattingVraagBlokCollection } from "./with-samenvatting-vraag-blok-collection";

const SamenvattingFieldVraag = withSamenvattingVertaling(withSamenvattingGeneralVraag(SamenvattingField));

const SamenvattingFieldChecklistvraag = withSamenvattingChecklistvraag(SamenvattingField);
const SamenvattingFieldDatumvraag = withSamenvattingDatumvraag(SamenvattingField);
const SamenvattingFieldDatumTijdvraag = withSamenvattingDatumTijdvraag(SamenvattingField);
const SamenvattingFieldInventarisatievraag = withSamenvattingInventarisatievraag(SamenvattingField);
const SamenvattingFieldMeerkeuzevraag = withSamenvattingMeerkeuzevraag(SamenvattingField);
const SamenvattingFieldBijlagenvraag = withSamenvattingBestandenvraag(SamenvattingBijlagen);
const SamenvattingFieldCollectionTypeaheadVraag = withSamenvattingCollectionTypeaheadVraag(SamenvattingField);
const SamenvattingTitle = withConditioneelGesteldeSamenvattingItem(withTekst(Title));
const SamenvattingVraagBlokCollection = withSamenvattingVraagBlokCollection(VraagBlokCollectionSamenvatting);
const SamenvattingFieldGebrokenAantalvraag = withSamenvattingGebrokenAantalvraag(SamenvattingField);
const SamenvattingFieldLandvraag = withSamenvattingLandvraag(SamenvattingField);

export * from "../confirm-dialog";
export * from "./download-samenvatting-button";
export * from "./intrekken-button";
export * from "./kopieer-button";
export * from "./koppel-zaak-button";
export * from "./samenvatting-container.component";
export * from "./samenvatting-context";
export * from "./samenvatting-melding-data";
export * from "./vertaalopdracht-button";
export * from "./vertaling-invoeren-button";
export * from "./wijzig-button";
export {
  SamenvattingFieldBijlagenvraag,
  SamenvattingFieldChecklistvraag,
  SamenvattingFieldCollectionTypeaheadVraag,
  SamenvattingFieldDatumTijdvraag,
  SamenvattingFieldDatumvraag,
  SamenvattingFieldGebrokenAantalvraag,
  SamenvattingFieldInventarisatievraag,
  SamenvattingFieldLandvraag,
  SamenvattingFieldMeerkeuzevraag,
  SamenvattingFieldVraag,
  SamenvattingTitle,
  SamenvattingVraagBlokCollection
};
