import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class ArbeidsMeldingSituatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public situatieWaaroverMeldingGaat: Vraag,
    public klachtOmschrijvenParagraaf: Tekst,
    public meldingBesprokenMet: Meerkeuzevraag,
    public resultaatGesprek: Vraag,
    public redenSituatieNietBesproken: Vraag,
    public meldingOndersteundDoorAnderen: Meerkeuzevraag,
    public doorWie: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ArbeidsMeldingSituatieVragen {
    return new ArbeidsMeldingSituatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.situatieWaaroverMeldingGaat`, json.situatieWaaroverMeldingGaat),
      jsonHelpers.toParagraaf(`${key}.klachtOmschrijvenParagraaf`, json.klachtOmschrijvenParagraaf),
      jsonHelpers.toChecklistvraag(`${key}.meldingBesprokenMet`, json.meldingBesprokenMet),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.resultaatGesprek`, json.resultaatGesprek),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.redenSituatieNietBesproken`, json.redenSituatieNietBesproken),
      jsonHelpers.toMeerkeuzeVraag(`${key}.meldingOndersteundDoorAnderen`, json.meldingOndersteundDoorAnderen),
      jsonHelpers.toTekstVraag(`${key}.doorWie`, json.doorWie)
    );
  }
}
