import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { CorrespondentieAdresVragenDisplayDefinition } from "../display-definition";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { BuitenlandsAdresVragen } from "./buitenlands-adres-vragen";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";

export class ProfielVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public profielTitel: Tekst,
    public gebruikersnaam: Vraag,
    public voorletters: Vraag,
    public tussenvoegsels: Vraag,
    public achternaam: Vraag,
    public geslacht: Meerkeuzevraag,
    public meldingenEmailadres: Vraag,
    public controleMeldingenEmailadres: Vraag,
    public telefoonnummer: Vraag,
    public melderAdres: CorrespondentieadresVragen,
    public bedrijfsgegevensTitel: Tekst,
    public bedrijfsgegevens: BedrijfsgegevensVragen,
    public naamBedrijf: Vraag,
    public buitenlandsAdres: BuitenlandsAdresVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ProfielVragen {
    return new ProfielVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.profielTitel`, json.profielTitel),
      jsonHelpers.toTekstVraag(`${key}.gebruikersnaam`, json.gebruikersnaam),
      jsonHelpers.toTekstVraag(`${key}.voorletters`, json.voorletters),
      jsonHelpers.toTekstVraag(`${key}.tussenvoegsels`, json.tussenvoegsels),
      jsonHelpers.toTekstVraag(`${key}.achternaam`, json.achternaam),
      jsonHelpers.toMeerkeuzeVraag(`${key}.geslacht`, json.geslacht),
      jsonHelpers.toTekstVraag(`${key}.meldingenEmailadres`, json.meldingenEmailadres),
      jsonHelpers.toTekstVraag(`${key}.controleMeldingenEmailadres`, json.controleMeldingenEmailadres),
      jsonHelpers.toTekstVraag(`${key}.telefoonnummer`, json.telefoonnummer),
      CorrespondentieadresVragen.fromJson(
        `${key}.melderAdres`,
        json.melderAdres,
        new CorrespondentieAdresVragenDisplayDefinition()
      ),
      jsonHelpers.toTitel(`${key}.bedrijfsgegevensTitel`, json.bedrijfsgegevensTitel),
      BedrijfsgegevensVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens),
      jsonHelpers.toTekstVraag(`${key}.naamBedrijf`, json.naamBedrijf),
      BuitenlandsAdresVragen.fromJson(`${key}.buitenlandsAdres`, json.buitenlandsAdres)
    );
  }
}
