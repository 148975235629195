import { NederlandsAdresVragen, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class TankschipVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public nederlandsAdres: NederlandsAdresVragen,
    public naamTankschip: Vraag,
    public havennummer: Vraag,
    public ligplaats: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): TankschipVragen {
    return new TankschipVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      NederlandsAdresVragen.fromJson(`${key}.nederlandsAdres`, json.nederlandsAdres),
      jsonHelpers.toTekstVraag(`${key}.naamTankschip`, json.naamTankschip),
      jsonHelpers.toTekstVraag(`${key}.havennummer`, json.havennummer),
      jsonHelpers.toTekstVraag(`${key}.ligplaats`, json.ligplaats)
    );
  }
}
