import { displayAntwoordHelpers } from "helpers";
import { shallowEqual, useSelector } from "react-redux";
import {
  AntwoordBlok,
  OpdrachtgeverAntwoorden,
  OpdrachtgeverVragen,
  VraagBlok,
  VraagBlokCollection
} from "../../../../models/api";
import { Dictionary, VraagBlokCollectionType } from "../../../../models/application";
import { State, VestigingenSearchesState } from "../../../../store";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";

export class Aannemers {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.aannemers;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => {
    const { vestigingSearches } = useSelector<State, { vestigingSearches: Dictionary<VestigingenSearchesState> }>(
      (state: State) => ({
        vestigingSearches: state.vestigingen.searches
      }),
      shallowEqual
    );

    return (
      <FormulierInputControls.CollectionAccordion
        itemComponent={GeneriekBlok.VraagBlok}
        getVraagBlokFromTemplate={OpdrachtgeverVragen.fromJson}
        initializeAntwoorden={OpdrachtgeverAntwoorden.initialize}
        vraagBlokCollection={vraagBlokCollection}
        getCollectionItemSummary={(antwoordBlok: AntwoordBlok, index: number) =>
          displayAntwoordHelpers.getOpdrachtgeverDisplayAntwoord(
            antwoordBlok as OpdrachtgeverAntwoorden,
            vraagBlokCollection.list[index] as OpdrachtgeverVragen,
            vestigingSearches
          )
        }
      />
    );
  };
}
