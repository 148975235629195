import React, { useMemo } from "react";
import BSAccordion from "react-bootstrap/Accordion";
import { AccordionContext } from "./accordion-context";

const Accordion: React.FC<any> = (props) => {
  const [activeKey, setActiveKey] = React.useState(props.activeKey);
  const [keyOfItemWithFocusedToggleButton, setKeyOfItemWithFocusedToggleButton] = React.useState("");

  const defaultContext = useMemo(() => {
    return {
      activeKey: activeKey,
      setActiveKey: (value: any) => {
        if (value !== activeKey) {
          setActiveKey(value);
        } else {
          setActiveKey("0");
        }
      },
      keyOfItemWithFocusedToggleButton: keyOfItemWithFocusedToggleButton,
      setKeyOfItemWithFocusedToggleButton: (value: any) => {
        if (value !== keyOfItemWithFocusedToggleButton) {
          setKeyOfItemWithFocusedToggleButton(value);
        }
      }
    };
  }, [activeKey, keyOfItemWithFocusedToggleButton]);

  const Contents = () => {
    const context = React.useContext(AccordionContext);

    return (
      <BSAccordion id="accordion" activeKey={context.activeKey} {...props}>
        {props.children}
      </BSAccordion>
    );
  };

  return (
    <AccordionContext.Provider value={defaultContext}>
      <Contents></Contents>
    </AccordionContext.Provider>
  );
};

export { Accordion };
