import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { VertalingInvoerenButtonProps } from "./vertaling-invoeren-button.component.interfaces";

export const VertalingInvoerenButton = (props: VertalingInvoerenButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      id="vertalingInvoerenButton"
      className="actie-button"
      as="a"
      role="button"
      href={undefined}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faLanguage} /> {t(translationKeys.melding.mijnMeldingen.vertalingInvoeren)}
    </Button>
  );
};
