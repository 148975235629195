import { Tekst } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

import { VraagBlokCollection } from "../vraag-blok-collection";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { PeriodeMeldingAvvArtikel10Vragen } from "./periode-melding-avv-artikel10-vragen";

export class AvvArtikel10MeldingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public meldingWordtGedaanDoor: Meerkeuzevraag,
    public welkeCaoVanToepassing: Vraag,
    public redenMelding: Vraag,
    public welkeBepalingen: Vraag,
    public periodeTitel: Tekst,
    public periode: VraagBlokCollection<PeriodeMeldingAvvArtikel10Vragen>,
    public vestigingTitel: Tekst,
    public welkeOnderneming: Tekst,
    public bedrijfsgegevens: BedrijfsgegevensVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): AvvArtikel10MeldingVragen {
    return new AvvArtikel10MeldingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toChecklistvraag(`${key}.meldingWordtGedaanDoor`, json.meldingWordtGedaanDoor),
      jsonHelpers.toTekstVraag(`${key}.welkeCaoVanToepassing`, json.welkeCaoVanToepassing),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.redenMelding`, json.redenMelding),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.welkeBepalingen`, json.welkeBepalingen),
      jsonHelpers.toTitel(`${key}.periodeTitel`, json.periodeTitel),
      VraagBlokCollection.fromJson<PeriodeMeldingAvvArtikel10Vragen>(
        PeriodeMeldingAvvArtikel10Vragen.fromJson,
        `${key}.periode`,
        json.periode,
        VraagBlokCollectionType.periode,
        false
      ),
      jsonHelpers.toTitel(`${key}.vestigingTitel`, json.vestigingTitel),
      jsonHelpers.toParagraaf(`${key}.welkeOnderneming`, json.welkeOnderneming),
      BedrijfsgegevensVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens)
    );
  }
}
