import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { LocatieVragen } from "./locatie-vragen";

export class BouwwerkVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public aardBouwwerk: Vraag,
    public typenBouwwerkTitel: Tekst,
    public typeBouwwerk: Meerkeuzevraag,
    public omgevingsvergunningTitel: Tekst,
    public omgevingsvergunning: Vraag,
    public locatieWerkzaamhedenTitel: Tekst,
    public locatieWerkzaamhedenToelichtingParagraaf: Tekst,
    public locatie: LocatieVragen,
    public geoLocatieTitel: Tekst,
    public geoLocatieToelichtingParagraaf: Tekst,
    public breedtegraad: Vraag,
    public lengtegraad: Vraag,
    public uploadBestandenPlanningEnVGPlanTitel: Tekst,
    public uploadBestandenPlanningEnVGPlanParagraaf: Tekst,
    public planningBestanden: Bestandenvraag,
    public bouwkundigeTechnischeOrganisatorischeKeuzenTitel: Tekst,
    public bouwkundigeTechnischeOrganisatorischeKeuzenParagraaf: Tekst,
    public bouwkundigeTechnischeOrganisatorischeKeuzen: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): BouwwerkVragen {
    return new BouwwerkVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.aardBouwwerk`, json.aardBouwwerk),
      jsonHelpers.toTitel(`${key}.typenBouwwerkTitel`, json.typenBouwwerkTitel),
      jsonHelpers.toChecklistvraag(`${key}.typeBouwwerk`, json.typeBouwwerk),
      jsonHelpers.toTitel(`${key}.omgevingsvergunningTitel`, json.omgevingsvergunningTitel),
      jsonHelpers.toTekstVraag(`${key}.omgevingsvergunning`, json.omgevingsvergunning),
      jsonHelpers.toTitel(`${key}.locatieWerkzaamhedenTitel`, json.locatieWerkzaamhedenTitel),
      jsonHelpers.toParagraaf(
        `${key}.locatieWerkzaamhedenToelichtingParagraaf`,
        json.locatieWerkzaamhedenToelichtingParagraaf
      ),
      LocatieVragen.fromJson(`${key}.locatie`, json.locatie),
      jsonHelpers.toTitel(`${key}.geoLocatieTitel`, json.geoLocatieTitel),
      jsonHelpers.toParagraaf(`${key}.geoLocatieToelichtingParagraaf`, json.geoLocatieToelichtingParagraaf),
      jsonHelpers.toTekstVraag(`${key}.breedtegraad`, json.breedtegraad),
      jsonHelpers.toTekstVraag(`${key}.lengtegraad`, json.lengtegraad),
      jsonHelpers.toTitel(`${key}.uploadBestandenPlanningEnVGPlanTitel`, json.uploadBestandenPlanningEnVGPlanTitel),
      jsonHelpers.toParagraaf(
        `${key}.uploadBestandenPlanningEnVGPlanParagraaf`,
        json.uploadBestandenPlanningEnVGPlanParagraaf
      ),
      jsonHelpers.toBestandenVraag(`${key}.planningBestanden`, json.planningBestanden),
      jsonHelpers.toTitel(
        `${key}.bouwkundigeTechnischeOrganisatorischeKeuzenTitel`,
        json.bouwkundigeTechnischeOrganisatorischeKeuzenTitel
      ),
      jsonHelpers.toParagraaf(
        `${key}.bouwkundigeTechnischeOrganisatorischeKeuzenParagraaf`,
        json.bouwkundigeTechnischeOrganisatorischeKeuzenParagraaf
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.bouwkundigeTechnischeOrganisatorischeKeuzen`,
        json.bouwkundigeTechnischeOrganisatorischeKeuzen
      )
    );
  }
}
