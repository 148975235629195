import { AntwoordBlok } from "./antwoord-blok";
import { RedenAanvraagAntwoorden } from "./reden-aanvraag-antwoorden";
import { VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden } from "./vluchtige-organische-stoffen-werkzaamheden-antwoorden";

export class VluchtigeOrganischeStoffenAanvraagAntwoorden extends AntwoordBlok {
  constructor(
    public redenAanvraag: RedenAanvraagAntwoorden,
    public werkzaamheden: VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden
  ) {
    super();
  }

  static initialize(): VluchtigeOrganischeStoffenAanvraagAntwoorden {
    return new VluchtigeOrganischeStoffenAanvraagAntwoorden(
      RedenAanvraagAntwoorden.initialize(),
      VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): VluchtigeOrganischeStoffenAanvraagAntwoorden {
    return new VluchtigeOrganischeStoffenAanvraagAntwoorden(
      RedenAanvraagAntwoorden.fromJson(json.redenAanvraag),
      VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden.fromJson(json.werkzaamheden)
    );
  }
}
