import { Antwoord, NederlandsAdresAntwoorden } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class TankschipAntwoorden extends AntwoordBlok {
  constructor(
    public nederlandsAdres: NederlandsAdresAntwoorden | null,
    public naamTankschip: Antwoord<string>,
    public havennummer: Antwoord<string>,
    public ligplaats: Antwoord<string>
  ) {
    super();
  }

  static initialize(): TankschipAntwoorden {
    return new TankschipAntwoorden(
      NederlandsAdresAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): TankschipAntwoorden {
    return new TankschipAntwoorden(
      NederlandsAdresAntwoorden.fromJson(json.nederlandsAdres),
      Antwoord.fromJson<string>(json.naamTankschip),
      Antwoord.fromJson<string>(json.havennummer),
      Antwoord.fromJson<string>(json.ligplaats)
    );
  }
}
