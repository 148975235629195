import { FormulierInputControls } from "components/formulier";
import { BestandImportVraagBase } from "../../../../models/api";
import { VraagType } from "../../../../models/application";

export class BestandImport {
  static getType = (): VraagType => VraagType.bestandImport;

  static create = (vraag: BestandImportVraagBase, readOnly = false): React.JSX.Element => (
    <>
      <FormulierInputControls.FileImport
        vraag={vraag}
        readOnly={readOnly}
        onImportFile={vraag.onImportFile}
      ></FormulierInputControls.FileImport>
    </>
  );
}
