import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";

export class PredicaatKoninklijkAanvraagAntwoorden extends AntwoordBlok {
  constructor(public typePredicaat: Antwoord<string[]>, public bedrijfsgegevens: BedrijfsgegevensAntwoorden | null) {
    super();
  }

  static initialize(): PredicaatKoninklijkAanvraagAntwoorden {
    return new PredicaatKoninklijkAanvraagAntwoorden(new Antwoord<string[]>([]), BedrijfsgegevensAntwoorden.initialize());
  }

  static fromJson(json: any): PredicaatKoninklijkAanvraagAntwoorden {
    return new PredicaatKoninklijkAanvraagAntwoorden(
      Antwoord.fromJson<string[]>(json.typePredicaat),
      BedrijfsgegevensAntwoorden.fromJson(json.bedrijfsgegevens)
    );
  }
}
