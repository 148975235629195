import { State } from "../../store";
import { connect } from "react-redux";
import { AutorisatieaanvraagFormulierVerstuurd } from "./autorisatieaanvraag-formulier-verstuurd.component";
import { AutorisatieaanvraagFormulierVerstuurdStateProps } from "./autorisatieaanvraag-formulier-verstuurd.component.interfaces";

const mapStateToProps = (state: State): AutorisatieaanvraagFormulierVerstuurdStateProps => ({
  formulier: state.vragen.formulier
});

export default connect(mapStateToProps)(AutorisatieaanvraagFormulierVerstuurd);
