import {
  DuikarbeidWerkzaamhedenVragen,
  LocatieMetToelichtingVragen,
  MelderVragen,
  PeriodeMetRedenVragen,
  VraagBlokken
} from "../blok";
import { HasDuikarbeidWerkzaamhedenVragen } from "./has-duikarbeidwerkzaamheden-vragen";
import { HasLocatieMetToelichtingVragen } from "./has-locatie-met-toelichting-vragen";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasPeriodeMetRedenVragen } from "./has-periode-met-reden-vragen";

export class DuikarbeidFormulierVraagBlokken
  extends VraagBlokken
  implements HasMelderVragen, HasLocatieMetToelichtingVragen, HasPeriodeMetRedenVragen, HasDuikarbeidWerkzaamhedenVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public locatie: LocatieMetToelichtingVragen,
    public periode: PeriodeMetRedenVragen,
    public werkzaamheden: DuikarbeidWerkzaamhedenVragen
  ) {
    super();
  }

  static fromJson(json: any): DuikarbeidFormulierVraagBlokken {
    return new DuikarbeidFormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      LocatieMetToelichtingVragen.fromJson("locatie", json.locatie),
      PeriodeMetRedenVragen.fromJson("periode", json.periode),
      DuikarbeidWerkzaamhedenVragen.fromJson("werkzaamheden", json.werkzaamheden)
    );
  }
}
