import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { OntheffingVuurOpTankschipFormulierVraagBlokken } from "./ontheffing-vuur-op-tankschip-formulier-vraag-blokken";

export class OntheffingVuurOpTankschipFormulier extends FormulierBase {
  constructor(public blokken: OntheffingVuurOpTankschipFormulierVraagBlokken, json: any) {
    super(Formuliertype.ontheffingVuurOpTankschip, blokken, json);
  }

  static fromJson(json: any): OntheffingVuurOpTankschipFormulier {
    return new OntheffingVuurOpTankschipFormulier(
      OntheffingVuurOpTankschipFormulierVraagBlokken.fromJson(json.blokken),
      json
    );
  }
}
