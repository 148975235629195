import { connect } from "react-redux";
import { State } from "store";
import { getLanden } from "thunks";
import { getUser } from "thunks/security-thunks";
import App from "./App";
import { AppDispatchProps, AppStateProps } from "./App.interfaces";
import { setUserActive, setUserIdle } from "./actions";

const mapDispatchToProps = (dispatch: any): AppDispatchProps => ({
  onUserIdle: () => dispatch(setUserIdle()),
  onUserActive: () => dispatch(setUserActive()),
  onLoad: () => {
    dispatch(getLanden());
    dispatch(getUser());
  }
});

const mapStateToProps = (state: State): AppStateProps => ({
  mandatoryInfoLoaded: state.security.userRequestFinished && !state.landen.landenIsLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
