import { connect } from "react-redux";
import { State } from "../../../store";
import { inloggen, requestActionNavigation, uitloggen } from "../../../thunks";
import { SecurityMenu } from "./security-menu.component";
import { SecurityMenuDispatchProps, SecurityMenuStateProps } from "./security-menu.component.interfaces";

const mapStateToProps = (state: State): SecurityMenuStateProps => ({
  userInfoChanges: state.security.userInfoChanges,
  user: state.security.user
});

const mapDispatchToProps = (dispatch: any): SecurityMenuDispatchProps => ({
  onInloggen: () => {
    dispatch(
      requestActionNavigation(() => {
        dispatch(inloggen());
      })
    );
  },
  onSignOut: () => {
    dispatch(
      requestActionNavigation(() => {
        dispatch(uitloggen());
      })
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityMenu);
