import { connect } from "react-redux";
import { BrpAchternaamSearchRequest } from "../../../models/api";
import { State } from "../../../store";
import { searchBrp } from "./../../../thunks/brp-thunks";
import { Kindgegevens } from "./kindgegevens.component";
import {
  KindgegevensDispatchProps,
  KindgegevensOwnProps,
  KindgegevensStateProps
} from "./kindgegevens.component.interfaces";

const mapStateToProps = (state: State, ownProps: KindgegevensOwnProps): KindgegevensStateProps => ({
  brpSearchResult: state.brp.results[ownProps.vragen.key],
  brpIsLoading: state.brp.results[ownProps.vragen.key]?.busy ?? false
});

const mapDispatchToProps = (dispatch: any, ownProps: KindgegevensOwnProps): KindgegevensDispatchProps => ({
  searchBrp: (request: BrpAchternaamSearchRequest) => dispatch(searchBrp(ownProps.vragen.key, request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Kindgegevens);
