import { connect } from "react-redux";
import { OngevalFormulier } from "../../../models/api/formulier/ongeval-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getOngevalformulierMelding, initOngevalFormulier, initOngevalFormulierFromCopy } from "../../../thunks";
import { OngevalFormulierComponent } from "./ongeval-formulier.component";
import {
  OngevalFormulierComponentDispatchProps,
  OngevalFormulierComponentStateProps
} from "./ongeval-formulier.component.interfaces";

const mapStateToProps = (state: State): OngevalFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.ongeval ? (state.vragen.formulier as OngevalFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): OngevalFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initOngevalFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getOngevalformulierMelding(meldingId));
    } else {
      dispatch(initOngevalFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OngevalFormulierComponent);
