import { SamengesteldeVraag, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary, SamengesteldeVraagType } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensLavsVragen } from "./bedrijfsgegevens-lavs-vragen";
import { TeVerwijderenAsbestMateriaalLavsVragen } from "./te-verwijderen-asbest-materiaal-lavs-vragen";

export class InventarisatieLavsVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public scaCode: Vraag,
    public rapportdatum: Vraag,
    public teVerwijderenAsbestMateriaal: SamengesteldeVraag<TeVerwijderenAsbestMateriaalLavsVragen>,
    public aantalPakdagen: Vraag,
    public gedeeltelijkeVerwijdering: SamengesteldeVraag<TeVerwijderenAsbestMateriaalLavsVragen>,
    public aantalPakdagenGedeeltelijkeVerwijdering: Vraag,
    public bedrijfsgegevens: BedrijfsgegevensLavsVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): InventarisatieLavsVragen {
    return new InventarisatieLavsVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.scaCode`, json.scaCode),
      jsonHelpers.toDatumTijdVraag(`${key}.rapportdatum`, json.rapportdatum),
      jsonHelpers.toSamengesteldeVraag<TeVerwijderenAsbestMateriaalLavsVragen>(
        `${key}.teVerwijderenAsbestMateriaal`,
        json.teVerwijderenAsbestMateriaal,
        (teVerwijderenAsbestMateriaalJson) =>
          TeVerwijderenAsbestMateriaalLavsVragen.fromJson(`teVerwijderenAsbestMateriaal`, teVerwijderenAsbestMateriaalJson),
        SamengesteldeVraagType.teVerwijderenAsbestMateriaalLavs
      ),
      jsonHelpers.toGebrokenAantalVraag(`${key}.aantalPakdagen`, json.aantalPakdagen),
      jsonHelpers.toSamengesteldeVraag<TeVerwijderenAsbestMateriaalLavsVragen>(
        `${key}.gedeeltelijkeVerwijdering`,
        json.gedeeltelijkeVerwijdering,
        (gedeeltelijkeVerwijderingJson) =>
          TeVerwijderenAsbestMateriaalLavsVragen.fromJson(`gedeeltelijkeVerwijdering`, gedeeltelijkeVerwijderingJson),
        SamengesteldeVraagType.teVerwijderenAsbestMateriaalLavs
      ),
      jsonHelpers.toGebrokenAantalVraag(
        `${key}.aantalPakdagenGedeeltelijkeVerwijdering`,
        json.aantalPakdagenGedeeltelijkeVerwijdering
      ),
      BedrijfsgegevensLavsVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens)
    );
  }
}
