import { BedrijfsgegevensAntwoorden, BuitenlandsAdresAntwoorden, ContactpersoonAntwoorden } from ".";
import { Antwoord } from "..";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class MelderAntwoorden extends AntwoordBlok {
  constructor(
    public namensBedrijfMelden: Antwoord<string>,
    public bedrijfIngeschreven: Antwoord<string>,
    public bedrijfsgegevens: BedrijfsgegevensAntwoorden | null,
    public naamBedrijf: Antwoord<string>,
    public buitenlandsAdres: BuitenlandsAdresAntwoorden | null,
    public meldergegevensBekend: Antwoord<string>,
    public melder: ContactpersoonAntwoorden | null,
    public melderAdres: CorrespondentieadresAntwoorden | null,
    public functie: Antwoord<string>,
    public correspondentieadresGelijkAanVestigingsadres: Antwoord<string>,
    public afwijkendCorrespondentieadres: CorrespondentieadresAntwoorden | null,
    public contactpersoonGelijkAanMelder: Antwoord<string>,
    public afwijkendContactpersoon: ContactpersoonAntwoorden | null,
    public afwijkendContactpersoonAdres: CorrespondentieadresAntwoorden | null,
    public scaCode: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): MelderAntwoorden {
    return new MelderAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      BedrijfsgegevensAntwoorden.initialize(user),
      new Antwoord<string>(user?.bedrijfNaam ?? ""),
      BuitenlandsAdresAntwoorden.initialize(user),
      new Antwoord<string>(""),
      ContactpersoonAntwoorden.initialize(user),
      CorrespondentieadresAntwoorden.initialize(user),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>(""),
      ContactpersoonAntwoorden.initialize(),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): MelderAntwoorden {
    return new MelderAntwoorden(
      Antwoord.fromJson(json.namensBedrijfMelden),
      Antwoord.fromJson(json.bedrijfIngeschreven),
      BedrijfsgegevensAntwoorden.fromJson(json.bedrijfsgegevens),
      Antwoord.fromJson(json.naamBedrijf),
      BuitenlandsAdresAntwoorden.fromJson(json.buitenlandsAdres),
      Antwoord.fromJson(json.meldergegevensBekend),
      ContactpersoonAntwoorden.fromJson(json.melder),
      CorrespondentieadresAntwoorden.fromJson(json.melderAdres),
      Antwoord.fromJson(json.functie),
      Antwoord.fromJson(json.correspondentieadresGelijkAanVestigingsadres),
      CorrespondentieadresAntwoorden.fromJson(json.afwijkendCorrespondentieadres),
      Antwoord.fromJson(json.contactpersoonGelijkAanMelder),
      ContactpersoonAntwoorden.fromJson(json.afwijkendContactpersoon),
      CorrespondentieadresAntwoorden.fromJson(json.afwijkendContactpersoonAdres),
      Antwoord.fromJson(json.scaCode)
    );
  }
}
