import { translationKeys } from "constants/translation-keys";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CollectionTableRowToggle } from "..";
import { CollectionTableRowToggleButtonProps } from "./collection-table-row-toggle-btn.component.interfaces";

export const CollectionTableRowToggleButton = (props: CollectionTableRowToggleButtonProps) => {
  const { t } = useTranslation();

  return (
    <CollectionTableRowToggle {...props}>
      {(title, _, toggleEvent) => (
        <Button aria-label={title} onClick={toggleEvent}>
          <span className="visually-hidden">{title}</span>
          {t(translationKeys.collection.opslaan)}
        </Button>
      )}
    </CollectionTableRowToggle>
  );
};
