import { connect } from "react-redux";
import { HandelingenIoniserendeStralingFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getHandelingenIoniserendeStralingMelding,
  initHandelingenIoniserendeStralingFormulier,
  initHandelingenIoniserendeStralingFormulierFromCopy
} from "../../../thunks";
import { HandelingenIoniserendeStralingFormulierComponent } from "./handelingen-ioniserende-straling-formulier.component";
import {
  HandelingenIoniserendeStralingFormulierComponentDispatchProps,
  HandelingenIoniserendeStralingFormulierComponentStateProps
} from "./handelingen-ioniserende-straling-formulier.component.interfaces";

const mapStateToProps = (state: State): HandelingenIoniserendeStralingFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.handelingenIoniserendeStraling
      ? (state.vragen.formulier as HandelingenIoniserendeStralingFormulier)
      : undefined;
  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): HandelingenIoniserendeStralingFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initHandelingenIoniserendeStralingFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getHandelingenIoniserendeStralingMelding(meldingId));
    } else {
      dispatch(initHandelingenIoniserendeStralingFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(HandelingenIoniserendeStralingFormulierComponent);
