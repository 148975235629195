import { connect } from "react-redux";
import { inloggen } from "../../../thunks/security-thunks";
import { Login } from "./login.component";
import { LoginDispatchProps } from "./login.component.interfaces";

const mapDispatchToProps = (dispatch: any): LoginDispatchProps => ({
  inloggen: () => {
    dispatch(inloggen());
  }
});

export default connect(null, mapDispatchToProps)(Login);
