import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";

export class SociaalMaatschappelijkeOmstandighedenAntwoorden extends AntwoordBlok {
  constructor(
    public welkeMaatschappelijkePriveOmstandigheden: Antwoord<string>,
    public waaromNachtarbeidNoodzakelijk: Antwoord<string>,
    public isPermanenteNachtarbeid: Antwoord<string>,
    public datumTotWanneerNachtarbeid: Antwoord<string>,
    public gezinsSamenstelling: Antwoord<string>,
    public periodeWerkurenPartner: Antwoord<string>,
    public hoeveelUurWerktPartner: Antwoord<number | null>,
    public financieleRedenenVoorAanvraag: Antwoord<string>,
    public hoeveelJarenWerkTotPensioen: Antwoord<number | null>,
    public gevolgenGezinBijGeenOntheffing: Antwoord<string>,
    public watGedaanVoorGezondWerkrooster: Antwoord<string>
  ) {
    super();
  }

  static initialize(): SociaalMaatschappelijkeOmstandighedenAntwoorden {
    return new SociaalMaatschappelijkeOmstandighedenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): SociaalMaatschappelijkeOmstandighedenAntwoorden {
    return new SociaalMaatschappelijkeOmstandighedenAntwoorden(
      Antwoord.fromJson<string>(json.welkeMaatschappelijkePriveOmstandigheden),
      Antwoord.fromJson<string>(json.waaromNachtarbeidNoodzakelijk),
      Antwoord.fromJson<string>(json.isPermanenteNachtarbeid),
      Antwoord.fromJson<string>(json.datumTotWanneerNachtarbeid),
      Antwoord.fromJson<string>(json.gezinsSamenstelling),
      Antwoord.fromJson<string>(json.periodeWerkurenPartner),
      Antwoord.fromJson<number | null>(json.hoeveelUurWerktPartner),
      Antwoord.fromJson<string>(json.financieleRedenenVoorAanvraag),
      Antwoord.fromJson<number | null>(json.hoeveelJarenWerkTotPensioen),
      Antwoord.fromJson<string>(json.gevolgenGezinBijGeenOntheffing),
      Antwoord.fromJson<string>(json.watGedaanVoorGezondWerkrooster)
    );
  }
}
