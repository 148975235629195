import { Expressions } from ".";
import { Dictionary } from "../../application";
import { HasConditioneelGesteld } from "../has-conditioneel-gesteld";
import { HasKey } from "../has-key";
import { HasBerekendeWaarde } from "./../has-berekende-waarde";
import { HasConditioneelReadonly } from "./../has-conditioneel-readonly";
import { Expression } from "./expression";

const berekendeWaardeCache: Dictionary<Expression | null> = {};
const conditioneelGesteldCache: Dictionary<Expression | null> = {};
const conditioneelReadonlyCache: Dictionary<Expression | null> = {};

const fromJson = (json: any): Expression | null => {
  let result: Expression | null = null;

  if (json) {
    for (const expressionKey in Expressions) {
      const expression = (Expressions as any)[expressionKey];

      if (expression.matches(json)) {
        result = expression.fromJson(json);
        break;
      }
    }

    if (!result) {
      throw new Error(`Expression could not be parsed from json:  ${json}`);
    }
  }

  return result;
};

const fromJsonArray = (json: any): Expression[] => {
  const result: Expression[] = [];

  if (json && Array.isArray(json)) {
    json.forEach((expressionJson: any) => {
      const expression = fromJson(expressionJson);
      if (expression) {
        result.push(expression);
      }
    });
  }

  return result;
};

const fromJsonBerekendeWaarde = (item: HasBerekendeWaarde & HasKey): Expression | null => {
  if (!berekendeWaardeCache[item.key]) {
    const expression = fromJson(item.berekendeWaarde);

    berekendeWaardeCache[item.key] = expression;
  }

  return berekendeWaardeCache[item.key];
};

const fromJsonConditioneelGesteld = (item: HasConditioneelGesteld & HasKey): Expression | null => {
  if (!conditioneelGesteldCache[item.key]) {
    const expression = fromJson(item.conditioneelGesteld);

    conditioneelGesteldCache[item.key] = expression;
  }

  return conditioneelGesteldCache[item.key];
};

const fromJsonConditioneelReadonly = (item: HasConditioneelReadonly & HasKey): Expression | null => {
  if (!conditioneelReadonlyCache[item.key]) {
    const expression = fromJson(item.conditioneelReadonly);

    conditioneelReadonlyCache[item.key] = expression;
  }

  return conditioneelReadonlyCache[item.key];
};

export const expressionParser = {
  fromJson,
  fromJsonArray,
  fromJsonBerekendeWaarde,
  fromJsonConditioneelGesteld,
  fromJsonConditioneelReadonly
};
