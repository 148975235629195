import { Autorisatiegegevens } from "../security/autorisatiegegevens";
import { AutorisatiegegevensExpression } from "./autorisatiegegevens-expression";

export class IsBedrijf extends AutorisatiegegevensExpression {
  get operator(): string {
    return IsBedrijf.operator();
  }

  executeAutorisation(autorisatiegegevens: Autorisatiegegevens): boolean {
    return autorisatiegegevens.isBedrijf;
  }

  static fromJson = (): IsBedrijf => new IsBedrijf();

  static operator = (): string => "isBedrijf";

  static matches = (json: any): boolean => json?.operator === IsBedrijf.operator();
}
