import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { BuitenlandsAdresVragen } from "./buitenlands-adres-vragen";
import { ContactpersoonVragen } from "./contactpersoon-vragen";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";

export class OpdrachtgeverVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public inschrijvingKamerVanKoophandelTitel: Tekst,
    public bedrijfsgegevensTitel: Tekst,
    public bedrijfIngeschreven: Meerkeuzevraag,
    public nietKvkIngeschrevenParagraaf: Tekst,
    public kvkgegevens: BedrijfsgegevensVragen,
    public bedrijfsnaam: Vraag,
    public buitenlandsAdres: BuitenlandsAdresVragen,
    public correspondentieNaarAdresParagraaf: Tekst,
    public telefoonnummer: Vraag,
    public emailadres: Vraag,
    public website: Vraag,
    public correspondentieadresTitel: Tekst,
    public correspondentieadresGelijkAanVestigingsadres: Meerkeuzevraag,
    public afwijkendCorrespondentieadres: CorrespondentieadresVragen,
    public scaCode: Vraag,
    public contactpersoonTitel: Tekst,
    public contactpersoon: ContactpersoonVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): OpdrachtgeverVragen {
    return new OpdrachtgeverVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.inschrijvingKamerVanKoophandelTitel`, json.inschrijvingKamerVanKoophandelTitel),
      jsonHelpers.toTitel(`${key}.bedrijfsgegevensTitel`, json.bedrijfsgegevensTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bedrijfIngeschreven`, json.bedrijfIngeschreven),
      jsonHelpers.toParagraaf(`${key}.nietKvkIngeschrevenParagraaf`, json.nietKvkIngeschrevenParagraaf),
      BedrijfsgegevensVragen.fromJson(`${key}.kvkgegevens`, json.kvkgegevens),
      jsonHelpers.toTekstVraag(`${key}.bedrijfsnaam`, json.bedrijfsnaam),
      BuitenlandsAdresVragen.fromJson(`${key}.buitenlandsAdres`, json.buitenlandsAdres),
      jsonHelpers.toParagraaf(`${key}.correspondentieNaarAdresParagraaf`, json.correspondentieNaarAdresParagraaf),
      jsonHelpers.toTekstVraag(`${key}.telefoonnummer`, json.telefoonnummer),
      jsonHelpers.toTekstVraag(`${key}.emailadres`, json.emailadres),
      jsonHelpers.toTekstVraag(`${key}.website`, json.website),
      jsonHelpers.toTitel(`${key}.correspondentieadresTitel`, json.correspondentieadresTitel),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.correspondentieadresGelijkAanVestigingsadres`,
        json.correspondentieadresGelijkAanVestigingsadres
      ),
      CorrespondentieadresVragen.fromJson(`${key}.afwijkendCorrespondentieadres`, json.afwijkendCorrespondentieadres),
      jsonHelpers.toTekstVraag(`${key}.scaCode`, json.scaCode),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon)
    );
  }
}
