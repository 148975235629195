import { ExterneReferentie } from "models/application";

export const SET_EXTERNE_REFERENTIE = "SET_EXTERNE_REFERENTIE";

interface SetExterneReferentie {
  type: typeof SET_EXTERNE_REFERENTIE;
  externeReferentie: ExterneReferentie;
}

export type ExterneReferentieActions = SetExterneReferentie;

export const setExterneReferentie = (externeReferentie: ExterneReferentie): ExterneReferentieActions => {
  return {
    type: SET_EXTERNE_REFERENTIE,
    externeReferentie: externeReferentie
  };
};
