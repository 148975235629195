import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { GetuigenInformatieAntwoorden } from "./getuigen-informatie-antwoorden";

export class GetuigenAntwoorden extends AntwoordBlok {
  constructor(
    public getuigenAanwezig: Antwoord<string>,
    public aantalGetuigen: Antwoord<number | null>,
    public gegevensGetuigenBekend: Antwoord<string>,
    public getuigenInformatieLijst: AntwoordBlokCollection<GetuigenInformatieAntwoorden> | null
  ) {
    super();
  }

  static initialize(): GetuigenAntwoorden {
    return new GetuigenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      AntwoordBlokCollection.initialize([])
    );
  }

  static fromJson(json: any): GetuigenAntwoorden {
    return new GetuigenAntwoorden(
      Antwoord.fromJson(json.getuigenAanwezig),
      Antwoord.fromJson(json.aantalGetuigen),
      Antwoord.fromJson(json.gegevensGetuigenBekend),
      AntwoordBlokCollection.fromJson(GetuigenInformatieAntwoorden.fromJson, json.getuigenInformatieLijst)
    );
  }
}
