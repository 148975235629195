import { Autorisatiegegevens } from "../security/autorisatiegegevens";
import { AutorisatiegegevensExpression } from "./autorisatiegegevens-expression";

export class IsParticulier extends AutorisatiegegevensExpression {
  get operator(): string {
    return IsParticulier.operator();
  }

  executeAutorisation(autorisatiegegevens: Autorisatiegegevens): boolean {
    return autorisatiegegevens.isParticulier;
  }

  static fromJson = (): IsParticulier => new IsParticulier();

  static operator = (): string => "isParticulier";

  static matches = (json: any): boolean => json?.operator === IsParticulier.operator();
}
