import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, AvvArtikel10MeldingAntwoordBlokken, Bestand, MelderAntwoorden } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initAvvArtikel10Formulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getAvvArtikel10Formulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getAvvArtikel10FormulierDefinitie,
      AvvArtikel10MeldingAntwoordBlokken.initialize
    );
  };

export const initAvvArtikel10FormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getAvvArtikel10Formulier(),
      meldingApi.getAvvArtikel10Melding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): AvvArtikel10MeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: MelderAntwoorden.initialize(user),
        bijlage: {
          ...melding.blokken.bijlage,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getAvvArtikel10FormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
