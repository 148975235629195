import { setNotDirty, setTaalWijzigModeAction } from "actions";
import i18n from "i18next";
import { State, TaalWijzigDialogMode } from "store";
import { AppThunk } from "./app-thunk-type";
import { NOT_REDIRECT_TO_ROUTES_AFTER_SIGNIN } from "./security-thunks";

const reloadPageInRequestedLanguage = (state: State, requestedLanguage: string): void => {
  const searchParams = new URLSearchParams(state.router.location!.search);
  searchParams.set("lng", requestedLanguage);

  let currentUrl = state.router.location?.pathname ?? "/";
  if (NOT_REDIRECT_TO_ROUTES_AFTER_SIGNIN.includes(currentUrl)) {
    currentUrl = "/";
  }

  (window as any).location = currentUrl + "?" + searchParams.toString();
};

export const handleTaalOnChange =
  (requestedLanguage: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const meldingStaatOpen = state.antwoorden.isInitialized;
    const bestaandeMelding = !!state.melding?.status;

    // Als er geen melding/formulier open staat dan gewoon doorgaan met de taalwijziging
    // bij 1 proces stap gaan we er van uit dat er alleen een samenvatting getoond wordt en er geen wijzigingen aangebracht zijn
    if (!meldingStaatOpen || state.proces.items.length === 1) {
      reloadPageInRequestedLanguage(state, requestedLanguage);
    } else if (bestaandeMelding) {
      dispatch(setTaalWijzigModeAction(requestedLanguage, TaalWijzigDialogMode.FormulierTaalNietTeWijzigenBestaandeMelding));
    } else if (
      state.vragen.formulier?.ondersteundeTalen.some((ot) => ot.toString() === requestedLanguage.toLocaleUpperCase())
    ) {
      dispatch(setTaalWijzigModeAction(requestedLanguage, TaalWijzigDialogMode.FormulierTaalWijzigVerzoek));
    } else {
      dispatch(setTaalWijzigModeAction(requestedLanguage, TaalWijzigDialogMode.FormulierTaalNietBeschikbaar));
    }
  };

export const confirmTaalWijzigenDialoog =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    dispatch(setNotDirty());
    if (state.taal.taalWijzigDialogMode === TaalWijzigDialogMode.FormulierTaalWijzigVerzoek) {
      reloadPageInRequestedLanguage(state, state.taal.requestedLanguage);
    } else {
      dispatch(setTaalWijzigModeAction(i18n.language, TaalWijzigDialogMode.None));
    }
  };

export const cancelTaalWijzigenDialoog =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setTaalWijzigModeAction(i18n.language, TaalWijzigDialogMode.None));
  };
