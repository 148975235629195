import { OpdrachtgeverVragen, Tekst, Vraag, VraagBlokCollection } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class AannemersVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public aannemersToelichtingParagraaf: Tekst,
    public aantalOnderaannemers: Vraag,
    public aantalZzpers: Vraag,
    public sprakeVanAannemersParagraaf: Tekst,
    public aannemers: VraagBlokCollection<OpdrachtgeverVragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): AannemersVragen {
    return new AannemersVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.aannemersToelichtingParagraaf`, json.aannemersToelichtingParagraaf),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalOnderaannemers`, json.aantalOnderaannemers),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalZzpers`, json.aantalZzpers),
      jsonHelpers.toParagraaf(`${key}.sprakeVanAannemersParagraaf`, json.sprakeVanAannemersParagraaf),
      VraagBlokCollection.fromJson<OpdrachtgeverVragen>(
        OpdrachtgeverVragen.fromJson,
        `${key}.aannemers`,
        json.aannemers,
        VraagBlokCollectionType.aannemers
      )
    );
  }
}
