import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { MelderAntwoorden } from "./melder-antwoorden";

export class CbiMelderAntwoorden extends AntwoordBlok {
  constructor(public common: MelderAntwoorden | null, public werkveld: Antwoord<string>) {
    super();
  }

  static initialize(user: User | null = null): CbiMelderAntwoorden {
    return new CbiMelderAntwoorden(MelderAntwoorden.initialize(user), new Antwoord<string>(""));
  }

  static fromJson(json: any): CbiMelderAntwoorden {
    return new CbiMelderAntwoorden(MelderAntwoorden.fromJson(json.common), Antwoord.fromJson<string>(json.werkveld));
  }
}
