import { translationKeys } from "../../constants";
import { FormulierOpgeslagenBevestiging } from "../formulier-opgeslagen-bevestiging";
import { FormulierGewijzigdProps } from "./formulier-gewijzigd.component.interfaces";

export const FormulierGewijzigd = (props: FormulierGewijzigdProps) => {
  return (
    <FormulierOpgeslagenBevestiging
      opgeslagenBevestigingTranslationKey={
        props.isInterneMedewerker
          ? translationKeys["formulier-gewijzigd-interne-medewerker"]
          : translationKeys["formulier-gewijzigd"]
      }
    />
  );
};
