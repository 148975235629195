import { objectHelpers } from "helpers";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { State } from "store";
import { GeneriekSamenvattingBlok } from "..";
import {
  AntwoordBlok,
  AntwoordBlokCollection,
  AntwoordBlokken,
  VraagBlok,
  VraagBlokCollection
} from "../../../../models/api";
import { SamenvattingVraagBlokCollection } from "../../../samenvatting";

export abstract class VraagBlokCollectionSamenvattingBase {
  static create = (
    vraagBlokCollection: VraagBlokCollection<VraagBlok>,
    getItemTitle?: (antwoordBlok: AntwoordBlok, index: number) => string
  ): React.JSX.Element => {
    const { i18n } = useTranslation();

    const { antwoordBlokken } = useSelector<State, { antwoordBlokken: AntwoordBlokken }>(
      (state: State) => ({
        antwoordBlokken: state.antwoorden.blokken
      }),
      shallowEqual
    );

    const antwoordBlokCollection = objectHelpers.getValue<AntwoordBlokCollection<AntwoordBlok>>(
      antwoordBlokken,
      vraagBlokCollection.key
    );

    return (
      <SamenvattingVraagBlokCollection
        vraag={vraagBlokCollection}
        component={(props: any) => (
          <Fragment>
            <h3>{`${props.vragen.titel[i18n.language]} ${props.index + 1}${
              getItemTitle && antwoordBlokCollection
                ? ` ${getItemTitle(antwoordBlokCollection.list[props.index], props.index)}`
                : ""
            }`}</h3>
            <GeneriekSamenvattingBlok vragen={props.vragen} isChildLevel={false} />
          </Fragment>
        )}
      />
    );
  };
}
