import React from "react";
import { InputNumberBase } from "../input-number/input-number-base";

export const InputGebrokenAantal = (props: any) => {
  const parsedValue = parseFloat(props.value);
  const convertedProps = {
    ...props,
    value: !isNaN(parsedValue) ? parsedValue : ""
  };

  return <InputNumberBase {...convertedProps} />;
};
