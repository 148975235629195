import { MeldingListItem } from "./melding-list-item";

export class GetMijnMeldingenResponse {
  constructor(public meldingen: MeldingListItem[], public totalItemCount: number) {}

  static fromJson(json: any): GetMijnMeldingenResponse {
    return new GetMijnMeldingenResponse(
      json.meldingen.map((m: MeldingListItem) => MeldingListItem.fromJson(m)),
      json.totalItemCount
    );
  }
}
