import { matchColumnWithSearchValue } from "components/grid/headers";
import deepcopy from "deepcopy";
import { meldingHelpers, stringHelpers } from "helpers";
import { AntwoordBlok, AntwoordBlokken, IAntwoord, VraagBlok } from "models/api";
import { ColumnSortOption } from "store";
import { displayValues } from "../../../../constants";
import { CollectionColumnSettings, VraagBlokWithAntwoordIndex } from "../model";

/** Get antwoordblok from live formik based on the VraagAntwoordBlok */
export const getAntwoordBlokFromFormik = (
  vraagAntwoordIndexBlok: VraagBlokWithAntwoordIndex,
  antwoorden: AntwoordBlokken
): AntwoordBlok | null => {
  let antwoordBlok = meldingHelpers.getAntwoordBlok(vraagAntwoordIndexBlok.vraagBlok, antwoorden);
  if (Array.isArray(antwoordBlok)) {
    antwoordBlok = antwoordBlok[vraagAntwoordIndexBlok.antwoordBlokIndex];
  }
  return antwoordBlok;
};

/** Generate vraagBlok vs antwoordBlok references */
export const generateVraagAntwoordIndexBlokCollection = (vraagBlokken: VraagBlok[]): VraagBlokWithAntwoordIndex[] => {
  return vraagBlokken.map((vraagBlok, index): VraagBlokWithAntwoordIndex => {
    return {
      vraagBlok: vraagBlok,
      antwoordBlokIndex: index
    };
  });
};

/** Determine a column value based on antwoordBlok and columnName */
export const determineColumnValue = (
  antwoordBlok: AntwoordBlok | null,
  vraagBlok: VraagBlok | null,
  columnName: string,
  columnFormatter?: (antwoordBlok: AntwoordBlok, vraagBlok: VraagBlok) => string | undefined
): string | undefined => {
  let columnValue: string | undefined = undefined;
  if (columnFormatter && antwoordBlok && vraagBlok) {
    columnValue = columnFormatter(antwoordBlok, vraagBlok);
  }

  return columnValue || (antwoordBlok && (antwoordBlok[columnName] as IAntwoord)?.waarde) || undefined;
};

export const formatColumnValue = (
  antwoordBlok: AntwoordBlok | null,
  vraagBlok: VraagBlok | null,
  columnName: string,
  columnFormatter?: (antwoordBlok: AntwoordBlok, vraagBlok: VraagBlok) => string | undefined
): string => {
  return determineColumnValue(antwoordBlok, vraagBlok, columnName, columnFormatter) ?? displayValues.noValue;
};

/** Filter vraagAntwoordBlokkken based on a searchValue */
export const filterVraagAntwoordBlokken = (
  vraagAntwoordBlokken: VraagBlokWithAntwoordIndex[],
  antwoorden: AntwoordBlokken,
  columnSettings?: CollectionColumnSettings,
  searchValue?: string
) => {
  let searchableColumns: string[] = [];

  if (columnSettings) {
    searchableColumns = Object.entries(columnSettings.columns)
      .filter(([, setting]) => setting.searchable !== false)
      .map(([key]) => key);
  }

  return vraagAntwoordBlokken.filter((vraagAntwoordBlok: VraagBlokWithAntwoordIndex) => {
    if (!searchValue || columnSettings === undefined) {
      return true;
    }

    return searchableColumns
      .map((columnName: string) =>
        matchColumnWithSearchValue(
          formatColumnValue(
            getAntwoordBlokFromFormik(vraagAntwoordBlok, antwoorden),
            vraagAntwoordBlok.vraagBlok,
            columnName,
            columnSettings.columns[columnName]?.columnFormatter
          ),
          searchValue,
          columnSettings.columns[columnName]?.searchMatcher
        )
      )
      .some((hit) => hit);
  });
};

/** Sort vraagAntwoordBlokken based on columnsorting */
export const sortVraagAntwoordBlokken = (
  vraagAntwoordBlokken: VraagBlokWithAntwoordIndex[],
  antwoorden: AntwoordBlokken,
  columnSettings: CollectionColumnSettings,
  columnSortOption: ColumnSortOption,
  reversedGrid: boolean
) => {
  const vraagAntwoordBlokkenToSort = deepcopy(vraagAntwoordBlokken);

  vraagAntwoordBlokkenToSort.sort((left, right) => {
    const columnName = columnSortOption.columnName;
    const columnSetting = columnSettings.columns[columnName];
    let compareResult: number | undefined;

    if (columnSetting) {
      const leftValue = determineColumnValue(
        getAntwoordBlokFromFormik(left, antwoorden),
        left.vraagBlok,
        columnName,
        columnSetting.columnFormatter
      );
      const rightValue = determineColumnValue(
        getAntwoordBlokFromFormik(right, antwoorden),
        right.vraagBlok,
        columnName,
        columnSetting.columnFormatter
      );

      if (leftValue === undefined || rightValue === undefined) {
        return 0;
      }

      // If columnComparer handler is set, use this to compare, if undefined is
      // returned use the default 'stringcomparer' for a column
      if (columnSetting.columnComparer) {
        compareResult = columnSetting.columnComparer(leftValue, rightValue, columnSortOption.order);
      }

      if (compareResult === undefined) {
        compareResult = stringHelpers.sort(leftValue, rightValue, columnSortOption.order);
      }
    }
    if (!compareResult) {
      return 0;
    }

    return reversedGrid ? compareResult * -1 : compareResult;
  });

  return vraagAntwoordBlokkenToSort;
};
