import { jsonHelpers } from "../../../helpers";
import { Dictionary, VraagBlokCollectionType } from "../../application";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";
import { VraagBlokCollection } from "../vraag-blok-collection";
import { KindGegevensVragen } from "./kind-gegevens-vragen";

export class KinderenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public kinderenRegistrerenNietMogelijk: Tekst,
    public kinderenRegistrerenNietMogelijkRisicoOnbekend: Tekst,
    public kinderenRegistrerenToelichting: Tekst,
    public kinderenGegevensLijst: VraagBlokCollection<KindGegevensVragen>
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): KinderenVragen {
    return new KinderenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toWaarschuwing(`${key}.kinderenRegistrerenNietMogelijk`, json.kinderenRegistrerenNietMogelijk),
      jsonHelpers.toWaarschuwing(
        `${key}.kinderenRegistrerenNietMogelijkRisicoOnbekend`,
        json.kinderenRegistrerenNietMogelijkRisicoOnbekend
      ),
      jsonHelpers.toParagraaf(`${key}.kinderenRegistrerenToelichting`, json.kinderenRegistrerenToelichting),
      VraagBlokCollection.fromJson<KindGegevensVragen>(
        KindGegevensVragen.fromJson,
        `${key}.kinderenGegevensLijst`,
        json.kinderenGegevensLijst,
        VraagBlokCollectionType.kindGegevens,
        false
      )
    );
  }
}
