import moment from "moment";
import { dateHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsInFuture extends VraagExpression {
  get operator(): string {
    return IsInFuture.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    let result = false;

    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;

    if (antwoord && dateHelpers.isValidDate(antwoord)) {
      const today = moment().startOf("day");

      const antwoordAsMoment = dateHelpers.getMomentFromDateString(antwoord);

      result = antwoordAsMoment > today;
    }

    return result;
  };

  protected clone = (instance: IsInFuture): IsInFuture => new IsInFuture(instance.vraagProperty);

  static fromJson = (json: any): IsInFuture => new IsInFuture(json.vraag);

  static operator = (): string => "isInFuture";

  static matches = (json: any): boolean => json?.operator === IsInFuture.operator();
}
