import { connect } from "react-redux";
import { AsbestRisicoklasse1Formulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getAsbestRisicoklasse1Melding,
  initAsbestRisicoklasse1Formulier,
  initAsbestRisicoklasse1FormulierFromCopy
} from "../../../thunks";
import { AsbestRisicoklasse1FormulierComponent } from "./asbest-risicoklasse1-formulier.component";
import {
  AsbestRisicoklasse1FormulierComponentDispatchProps,
  AsbestRisicoklasse1FormulierComponentStateProps
} from "./asbest-risicoklasse1-formulier.component.interfaces";

const mapStateToProps = (state: State): AsbestRisicoklasse1FormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.asbestRisicoklasse1
      ? (state.vragen.formulier as AsbestRisicoklasse1Formulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AsbestRisicoklasse1FormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initAsbestRisicoklasse1FormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getAsbestRisicoklasse1Melding(meldingId));
    } else {
      dispatch(initAsbestRisicoklasse1Formulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AsbestRisicoklasse1FormulierComponent);
