import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { KoppelZaakButtonProps } from "./koppel-zaak-button.component.interfaces";

export const KoppelZaakButton = (props: KoppelZaakButtonProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Button
        id="koppelZaakButton"
        className="actie-button"
        as="a"
        href={undefined}
        role="button"
        onClick={props.onClick}
        aria-label={t(translationKeys.melding.mijnMeldingen.koppelZaak.toelichting)}
        title={t(translationKeys.melding.mijnMeldingen.koppelZaak.toelichting)}
      >
        <FontAwesomeIcon icon={faLink} /> {t(translationKeys.melding.mijnMeldingen.koppelZaak.title)}
      </Button>
    </Fragment>
  );
};
