export class Vestiging {
  constructor(
    public LAN_NAAM: string,
    public ves_huisnr: number | null,
    public ves_id: number,
    public ves_kvknr: string | null,
    public ves_naam: string,
    public ves_plaats: string,
    public ves_postcode: string,
    public ves_straat: string,
    public ves_toev_huisnr: string,
    public ves_vest_nr: string | null
  ) {}

  static fromJson(json: any): Vestiging {
    return new Vestiging(
      json.LAN_NAAM,
      json.ves_huisnr,
      json.ves_id,
      json.ves_kvknr,
      json.ves_naam,
      json.ves_plaats,
      json.ves_postcode,
      json.ves_straat,
      json.ves_toev_huisnr,
      json.ves_vest_nr
    );
  }

  static fromMeldingJson(json: any): Vestiging {
    return new Vestiging(
      json.land,
      json.huisnummer,
      json.id,
      json.kvkNummer,
      json.naam,
      json.plaats,
      json.postcode,
      json.straatnaam,
      json.huisnummertoevoeging,
      json.vestigingsnummer
    );
  }
}
