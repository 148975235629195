import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { LandAntwoorden } from "./land-antwoorden";

export class MeldingDouaneAntwoorden extends AntwoordBlok {
  constructor(
    public productcategorie: Antwoord<string[]>,
    public aangiftenummerItemnummer: Antwoord<string>,
    public overigeInformatie: Antwoord<string>,
    public verhinderingVrijgaveDatum: Antwoord<string>,
    public verhinderingVrijgaveTijdstip: Antwoord<string>,
    public deadlineReactieDatum: Antwoord<string>,
    public deadlineReactieTijdstip: Antwoord<string>,
    public gnCode: Antwoord<string>,
    public goederenomschrijving: Antwoord<string>,
    public gewichtBruto: Antwoord<string>,
    public gewichtNetto: Antwoord<string>,
    public naamVerkoper: Antwoord<string>,
    public hoeveelheid: Antwoord<string>,
    public factuurnummer: Antwoord<string>,
    public colli: Antwoord<string>,
    public landVanOorsprong: LandAntwoorden | null,
    public landVanBestemming: LandAntwoorden | null
  ) {
    super();
  }

  static initialize(): MeldingDouaneAntwoorden {
    return new MeldingDouaneAntwoorden(
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      LandAntwoorden.initialize(),
      LandAntwoorden.initialize()
    );
  }

  static fromJson(json: any): MeldingDouaneAntwoorden {
    return new MeldingDouaneAntwoorden(
      Antwoord.fromJson<string[]>(json.productcategorie),
      Antwoord.fromJson<string>(json.aangiftenummerItemnummer),
      Antwoord.fromJson<string>(json.overigeInformatie),
      Antwoord.fromJson<string>(json.verhinderingVrijgaveDatum),
      Antwoord.fromJson<string>(json.verhinderingVrijgaveTijdstip),
      Antwoord.fromJson<string>(json.deadlineReactieDatum),
      Antwoord.fromJson<string>(json.deadlineReactieTijdstip),
      Antwoord.fromJson<string>(json.gnCode),
      Antwoord.fromJson<string>(json.goederenomschrijving),
      Antwoord.fromJson<string>(json.gewichtBruto),
      Antwoord.fromJson<string>(json.gewichtNetto),
      Antwoord.fromJson<string>(json.naamVerkoper),
      Antwoord.fromJson<string>(json.hoeveelheid),
      Antwoord.fromJson<string>(json.factuurnummer),
      Antwoord.fromJson<string>(json.colli),
      LandAntwoorden.fromJson(json.landVanOorsprong),
      LandAntwoorden.fromJson(json.landVanBestemming)
    );
  }
}
