import { HasBijlageVragen, HasVerzoekMelderVragen, HasWooVerzoekVragen } from "..";
import { BijlageVragen, VerzoekMelderVragen, VraagBlokken, WooVerzoekVragen } from "../blok";

export class WooFormulierVraagBlokken
  extends VraagBlokken
  implements HasWooVerzoekVragen, HasVerzoekMelderVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(public verzoek: WooVerzoekVragen, public melder: VerzoekMelderVragen, public bijlage: BijlageVragen) {
    super();
  }

  static fromJson(json: any): WooFormulierVraagBlokken {
    return new WooFormulierVraagBlokken(
      WooVerzoekVragen.fromJson("verzoek", json.verzoek),
      VerzoekMelderVragen.fromJson("melder", json.melder),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
