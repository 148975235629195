import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formulier } from "..";
import { translationKeys } from "../../../constants";
import { formulierDefinitieHelpers } from "../../../formulier-definitie/formulier-definitie-helper";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { MeldingBase, ProfielMelding } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { ProfielFormulierComponentProps } from "./profiel-formulier.component.interfaces";

export const ProfielFormulierComponent = (props: ProfielFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.isSigningOut) {
      onInit();
    }
  }, [onInit, props.isSigningOut]);

  const formuliertype = Formuliertype.profiel;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(formuliertype, props.formulier.type, props.antwoordenFormuliertype)
  ) {
    const blokDefinitie = formulierDefinitieHelpers.getFormulierDefinitie(props.formulier);

    return (
      blokDefinitie && (
        <div className="profiel">
          <Formulier
            type={formuliertype}
            {...propsRest}
            blokDefinitie={blokDefinitie}
            validate={MeldingValidator.createValidate(props.formulier.blokken)}
            createMelding={MeldingBase.ofType(ProfielMelding)}
            verzendenButtonText={t(translationKeys.blok.profiel.formulier.opslaan)}
            beforeSubmitCompleted={props.beforeCompleted}
            afterSubmitCompleted={props.afterCompleted}
            hideHeaders={true}
          ></Formulier>
        </div>
      )
    );
  } else {
    return null;
  }
};
