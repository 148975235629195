import { Expression } from "./expression";

export abstract class VraagExpression extends Expression {
  constructor(public readonly vraagProperty: string) {
    super();
  }

  protected abstract clone(instance: VraagExpression): VraagExpression;

  static fromInstance(instance: VraagExpression): VraagExpression {
    return instance.clone(instance);
  }
}
