import { connect } from "react-redux";
import { State } from "../../store";
import { gotoMelding } from "../../thunks/meldingen-thunks";
import { Meldingen } from "./meldingen.component";
import { MeldingenDispatchProps, MeldingenStateProps } from "./meldingen.component.interfaces";

const mapStateToProps = (state: State): MeldingenStateProps => ({
  isLoading: state.meldingen.isLoading,
  hasApiError: state.meldingen.hasApiError
});

const mapDispatchToProps = (dispatch: any): MeldingenDispatchProps => ({
  onInit: (meldingNummer: number) => {
    dispatch(gotoMelding(meldingNummer));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Meldingen);
