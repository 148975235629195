import { clearAutorisatieGegevens, setAutorisatiegegevens, setNotDirty } from "actions";
import { profielApi, securityApi } from "api";
import { Autorisatiegegevens } from "models/api/security/autorisatiegegevens";
import { Action } from "redux";
import { push } from "redux-first-history";
import { ThunkDispatch } from "redux-thunk";
import { State } from "store";
import {
  closeExtendSessionDialog,
  extendSession,
  extendSessionComplete,
  loadUser,
  openExtendSessionDialog,
  setUserInfoChange,
  setUserRequestFinished,
  signOut,
  unloadUser
} from "../actions/security-actions";
import { Configuration } from "../configuration";

import { setProfileComplete, setProfileSaved } from "./../actions/security-actions";
import { AppThunk } from "./app-thunk-type";
import { executeUrlNavigation } from "./navigation-thunks";

const SET_PROFILE_SAVED_TIMEOUT_MS = 7000;
export const NOT_REDIRECT_TO_ROUTES_AFTER_SIGNIN = [
  "/geenautorisatie",
  "/geenavgautorisatie",
  "/login",
  "/logout",
  "/verstuurd",
  "/gewijzigd",
  "/ingetrokken",
  "/nietgevonden"
];

export const setProfielOpgeslagen =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const redirectUri = !state.security.profileIsComplete && state.security.profileNotCompleteOriginalUri;

    dispatch(setProfileComplete({ isComplete: true, originalUri: null }));
    dispatch(setProfileSaved(true));

    setTimeout(() => {
      dispatch(setProfileSaved(false));
      if (redirectUri) {
        dispatch(push(redirectUri));
      }
    }, SET_PROFILE_SAVED_TIMEOUT_MS);
  };

export const checkProfileComplete = async (dispatch: ThunkDispatch<State, null, Action<string>>, getState: () => State) => {
  const state = getState();
  if (state.security.user && state.security.profileIsComplete === null) {
    const isCompleet = await profielApi.isProfielCompleet();

    dispatch(
      setProfileComplete({
        isComplete: isCompleet,
        originalUri: state.router.location?.pathname || null
      })
    );
  }
};

export const handleTokenExpiring =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    if (!Configuration.loginEnabled) {
      dispatch(sessieVerlengen());
    } else {
      if (!state.security.isExtendSessionDialogShown) {
        if (state.userActivity.isUserIdle) {
          dispatch(openExtendSessionDialog());
        } else {
          dispatch(sessieVerlengen());
        }
      }
    }
  };

export const handleTokenExpired =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    if (!Configuration.loginEnabled) {
      dispatch(sessieVerlengen());
    } else {
      dispatch(setNotDirty());
      dispatch(uitloggen(true));
    }
  };

export const sessieVerlengen =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const user = getState().security.user;
    if (user !== null) {
      dispatch(extendSession());
      await dispatch(sessieVerversen());
      dispatch(extendSessionComplete());
      dispatch(closeExtendSessionDialog());
    }
  };

export const sessieVerversen =
  (renewUserInfo?: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const user = getState().security.user;
    if (user !== null) {
      // wait for the getUser to finish
      await dispatch(getUser(renewUserInfo));
    }
  };

export const inloggen =
  (forcedLogin: boolean = false): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    if (!getState().security.userLoaded || forcedLogin) {
      if (forcedLogin) {
        dispatch(setNotDirty());
      }
      let returnTo: string | undefined = window.location.pathname;
      let tokenExpired = false;

      if (NOT_REDIRECT_TO_ROUTES_AFTER_SIGNIN.includes(returnTo)) {
        returnTo = undefined;
      } else {
        const currentQueryString = new URLSearchParams(window.location.search);
        const tokenExpiredFromQuery = currentQueryString.get("token_expired");
        tokenExpired = !!tokenExpiredFromQuery && tokenExpiredFromQuery.toUpperCase() === "TRUE";
      }

      dispatch(executeUrlNavigation(securityApi.getLoginUrl(returnTo, forcedLogin, tokenExpired), true));
    } else {
      dispatch(push("/"));
    }
  };

export const uitloggen =
  (tokenExpired: boolean = false): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(signOut());

    let returnTo: string | undefined = window.location.pathname;

    if (NOT_REDIRECT_TO_ROUTES_AFTER_SIGNIN.includes(returnTo)) {
      returnTo = undefined;
    }

    dispatch(executeUrlNavigation(securityApi.getLogoutUrl(returnTo, tokenExpired), true));
  };

export const postLogout =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const user = getState().security.user;
    if (user !== null) {
      dispatch(signOut());
    }
  };

export const showGeenAutorisatie =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(push("/geenautorisatie"));
  };

export const showGeenAvgAutorisatie =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(push("/geenavgautorisatie"));
  };

export const getUser =
  (renewUserInfo?: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    await securityApi
      .getUser(renewUserInfo)
      .then((user) => {
        dispatch(loadUser(user));
        dispatch(setAutorisatiegegevens(Autorisatiegegevens.initialize(user)));
        dispatch(setUserInfoChange());
      })
      .catch(() => {
        const currentUser = getState().security.user;
        if (!!currentUser) {
          dispatch(handleTokenExpired());
        }

        dispatch(unloadUser());
        dispatch(clearAutorisatieGegevens());
      })
      .finally(() => {
        dispatch(setUserRequestFinished());
      });
  };
