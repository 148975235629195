import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class UitgeoefendeActiviteitenAntwoorden extends AntwoordBlok {
  constructor(
    public hoofdactiviteit: Antwoord<string>,
    public doel: Antwoord<string>,
    public activiteitenMetGevaarlijkeStoffen: Antwoord<string>,
    public informatieOverDeOnmiddellijkeOmgeving: Antwoord<string>,
    public factorenDieEenZwaarOngevalKunnenVeroorzaken: Antwoord<string>
  ) {
    super();
  }

  static initialize(): UitgeoefendeActiviteitenAntwoorden {
    return new UitgeoefendeActiviteitenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): UitgeoefendeActiviteitenAntwoorden {
    return new UitgeoefendeActiviteitenAntwoorden(
      Antwoord.fromJson<string>(json.hoofdactiviteit),
      Antwoord.fromJson<string>(json.doel),
      Antwoord.fromJson<string>(json.activiteitenMetGevaarlijkeStoffen),
      Antwoord.fromJson<string>(json.informatieOverDeOnmiddellijkeOmgeving),
      Antwoord.fromJson<string>(json.factorenDieEenZwaarOngevalKunnenVeroorzaken)
    );
  }
}
