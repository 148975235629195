import { Tekst } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";

export class PredicaatKoninklijkAanvraagVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public typePredicaat: Meerkeuzevraag,
    public vestigingTitel: Tekst,
    public vestigingAdviesAanvraagParagraaf: Tekst,
    public bedrijfsgegevens: BedrijfsgegevensVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): PredicaatKoninklijkAanvraagVragen {
    return new PredicaatKoninklijkAanvraagVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toChecklistvraag(`${key}.typePredicaat`, json.typePredicaat),
      jsonHelpers.toTitel(`${key}.vestigingTitel`, json.vestigingTitel),
      jsonHelpers.toParagraaf(`${key}.vestigingAdviesAanvraagParagraaf`, json.vestigingAdviesAanvraagParagraaf),
      BedrijfsgegevensVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens)
    );
  }
}
