import { useFormikContext } from "formik";
import _ from "lodash";
import { AntwoordBlokken, VraagBlok } from "models/api";
import { useCallback, useEffect, useState } from "react";
import { CollectionPagingState, ColumnSortOption } from "store";
import { VraagBlokWithAntwoordIndex } from "../model";
import { CollectionHandlerProps } from "./collection-handler.component.interfaces";
import {
  filterVraagAntwoordBlokken,
  generateVraagAntwoordIndexBlokCollection,
  sortVraagAntwoordBlokken
} from "./collection-handler.helpers";

const determinePageForItemIndex = (
  vraagAntwoordBlokken: VraagBlokWithAntwoordIndex[],
  paging: CollectionPagingState,
  selectedItemKey: string,
  updatePageNumberFunc: (pageNumber: number) => void
) => {
  if (vraagAntwoordBlokken.length > 0) {
    let order = vraagAntwoordBlokken.findIndex((vbi) => vbi.vraagBlok.key === selectedItemKey);

    if (order !== -1) {
      const selectedItemIsOnPage = _.ceil(++order / paging.pageSize);
      if (selectedItemIsOnPage !== paging.currentPage) {
        updatePageNumberFunc(selectedItemIsOnPage);
      }
    }
  }
};

export const CollectionHandler = (props: CollectionHandlerProps) => {
  const { values } = useFormikContext<AntwoordBlokken>();
  const { setVraagBlokAntwoordIndexList, resetSortingToDefault, updatePageNumer } = props;

  // Store previous properties
  const [previousSearchTimestamp, setPreviousSearchTimestamp] = useState(0);
  const [previousSorting, setPreviousSorting] = useState<ColumnSortOption | null>(null);
  const [previousVraagBlokken, setPreviousVraagBlokken] = useState<VraagBlok[]>([]);
  const [filterChanged, setFilterChanged] = useState(false);

  const [filteredVraagAntwoordBlokken, setFilteredVraagAntwoordBlokken] = useState<VraagBlokWithAntwoordIndex[]>([]);

  const shouldSearch = useCallback(
    () => previousSearchTimestamp !== props.searchTimestamp,
    [previousSearchTimestamp, props.searchTimestamp]
  );
  const shouldSort = useCallback(() => previousSorting !== props.currentSorting, [previousSorting, props.currentSorting]);
  const vraagBlokkenHasChanged = useCallback(
    () => previousVraagBlokken !== props.vraagBlokken,
    [previousVraagBlokken, props.vraagBlokken]
  );

  useEffect(() => {
    resetSortingToDefault();
  }, [resetSortingToDefault]);

  useEffect(() => {
    if (shouldSearch() || vraagBlokkenHasChanged()) {
      setPreviousSearchTimestamp(props.searchTimestamp);
      setPreviousVraagBlokken(props.vraagBlokken);
      const vraagAntwoordBlokken = generateVraagAntwoordIndexBlokCollection(props.vraagBlokken);

      if (props.reversedOrder) {
        vraagAntwoordBlokken.reverse();
      }

      setFilteredVraagAntwoordBlokken(
        filterVraagAntwoordBlokken(vraagAntwoordBlokken, values, props.columnSettings, props.searchValue)
      );
      setFilterChanged(true);
    }
  }, [
    props.searchValue,
    props.vraagBlokken,
    props.reversedOrder,
    props.columnSettings,
    props.searchTimestamp,
    values,
    shouldSearch,
    vraagBlokkenHasChanged
  ]);

  useEffect(() => {
    if (shouldSort() || filterChanged) {
      setPreviousSorting(props.currentSorting);

      if (filterChanged) {
        setFilterChanged(false);
      }
      const sortedVraagAntwoordBlokken =
        props.currentSorting && props.columnSettings
          ? sortVraagAntwoordBlokken(
              filteredVraagAntwoordBlokken,
              values,
              props.columnSettings,
              props.currentSorting,
              props.reversedOrder ?? false
            )
          : filteredVraagAntwoordBlokken;

      determinePageForItemIndex(sortedVraagAntwoordBlokken, props.paging, props.selectedItemKey, updatePageNumer);

      setVraagBlokAntwoordIndexList(sortedVraagAntwoordBlokken, props.vraagBlokken.length);
    }
  }, [
    filteredVraagAntwoordBlokken,
    props.currentSorting,
    props.vraagBlokken,
    props.columnSettings,
    props.reversedOrder,
    props.paging,
    values,
    shouldSort,
    setVraagBlokAntwoordIndexList,
    filterChanged,
    props.selectedItemKey,
    updatePageNumer
  ]);

  return null;
};
