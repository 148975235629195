import { userHelpers } from "helpers";
import { shallowEqual, useSelector } from "react-redux";
import { State } from "store";

export const withSamenvattingVertaling = <T extends object>(Component: React.ComponentType<T>) => {
  return (props: any) => {
    const isVertaalbaar = props.vraag?.isAntwoordVertaalbaar;
    const { isInterneMedewerker, wordtVertaald, kanMeldingWordenVertaald } = useSelector<
      State,
      {
        isInterneMedewerker: boolean;
        wordtVertaald: boolean;
        kanMeldingWordenVertaald: boolean;
      }
    >(
      (state: State) => ({
        isInterneMedewerker: userHelpers.getIsInternalUser(state.security.user),
        wordtVertaald: state.melding?.wordtVertaald,
        kanMeldingWordenVertaald: !!state.melding?.meldingrechten?.kanVertalen
      }),
      shallowEqual
    );

    const showOnlyVertaling = kanMeldingWordenVertaald && !wordtVertaald && isInterneMedewerker && isVertaalbaar;

    return (
      <>
        <Component {...props} useVertaling={showOnlyVertaling} />
        {wordtVertaald && isVertaalbaar && <Component {...props} useVertaling={true} />}
      </>
    );
  };
};
