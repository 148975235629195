import { connect } from "react-redux";
import { UploadGeneriekFormulier } from "../../../models/api/formulier/upload-generiek-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getUploadGeneriekformulierMelding,
  initUploadGeneriekFormulier,
  initUploadGeneriekFormulierFromCopy
} from "../../../thunks";
import { UploadGeneriekFormulierComponent } from "./upload-generiek-formulier.component";
import {
  UploadGeneriekFormulierComponentDispatchProps,
  UploadGeneriekFormulierComponentStateProps
} from "./upload-generiek-formulier.component.interfaces";

const mapStateToProps = (state: State): UploadGeneriekFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.uploadGeneriek
      ? (state.vragen.formulier as UploadGeneriekFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): UploadGeneriekFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initUploadGeneriekFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getUploadGeneriekformulierMelding(meldingId));
    } else {
      dispatch(initUploadGeneriekFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadGeneriekFormulierComponent);
