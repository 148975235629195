import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";

export class DigipassAntwoorden extends AntwoordBlok {
  constructor(public controlenummer: Antwoord<string>) {
    super();
  }

  static initialize(): DigipassAntwoorden {
    return new DigipassAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): DigipassAntwoorden {
    return new DigipassAntwoorden(Antwoord.fromJson<string>(json.controlenummer));
  }
}
