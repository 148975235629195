import { connect } from "react-redux";
import { EMPTY_COLLECTION_VALIDATION_FAILURES, NO_SELECTED_ITEM } from "reducers/collections-reducer";
import { State } from "../../../../store";
import { CollectionErrorsOnInactiveItems } from "./collection-errors-on-inactive-items.component";
import {
  CollectionErrorsOnInactiveItemsOwnProps,
  CollectionErrorsOnInactiveItemsStateProps
} from "./collection-errors-on-inactive-items.component.interfaces";

const mapStateToProps = (
  state: State,
  props: CollectionErrorsOnInactiveItemsOwnProps
): CollectionErrorsOnInactiveItemsStateProps => ({
  selectedCollectionItemKey: state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM,
  collectionValidationFailures:
    state.collections.list[props.collectionKey]?.validationFailures ?? EMPTY_COLLECTION_VALIDATION_FAILURES
});

export default connect(mapStateToProps)(CollectionErrorsOnInactiveItems);
