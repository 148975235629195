class Plaats {
  constructor(public naam: string) {}

  static fromJson(json: any): Plaats {
    return new Plaats(json.naam);
  }
}

export class PlaatsenSearchResponse {
  constructor(public plaatsen: Plaats[]) {}

  static fromJson(json: any): PlaatsenSearchResponse {
    return new PlaatsenSearchResponse(json.plaatsen.map((p: Plaats) => Plaats.fromJson(p)));
  }
}
