export class UrlBuilder {
  private readonly _searchParams: URLSearchParams;

  constructor(public readonly path: string) {
    this._searchParams = new URLSearchParams();
  }

  public withQueryParam(param: string, value: string | undefined | null): UrlBuilder {
    if (value) {
      this._searchParams.append(param, value.trim());
    }
    return this;
  }

  public toString() {
    if (this._searchParams.toString()) {
      return `${this.path}?${this._searchParams.toString()}`;
    } else {
      return this.path;
    }
  }
}
