import { connect } from "react-redux";
import { AsbestverwijderingLavsFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getAsbestverwijderingLavsFormulierMelding } from "../../../thunks/melding-thunks";
import { AsbestverwijderingLavsFormulierComponent } from "./asbestverwijdering-lavs-formulier.component";
import {
  AsbestverwijderingLavsFormulierComponentDispatchProps,
  AsbestverwijderingLavsFormulierComponentStateProps
} from "./asbestverwijdering-lavs-formulier.component.interfaces";

const mapStateToProps = (state: State): AsbestverwijderingLavsFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.asbestverwijderingLavs
      ? (state.vragen.formulier as AsbestverwijderingLavsFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AsbestverwijderingLavsFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined) => {
    if (!meldingId) {
      throw new Error("Not supported operation");
    }

    dispatch(getAsbestverwijderingLavsFormulierMelding(meldingId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AsbestverwijderingLavsFormulierComponent);
