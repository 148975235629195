import { useEffect } from "react";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { DuikarbeidMelding, DuikarbeidMeldingAntwoordBlokken, MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { formulierHelpers } from "./../../../helpers";
import { DuikarbeidFormulierComponentProps } from "./duikarbeid-formulier.component.interfaces";
import { useParams } from "react-router-dom";

export const DuikarbeidFormulierComponent = (props: DuikarbeidFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  const { meldingId } = useParams();

  useEffect(() => {
    onInit(meldingId, props.isKopie);
  }, [onInit, meldingId, props.isKopie]);

  const formuliertype = Formuliertype.duikarbeid;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(
      formuliertype,
      props.formulier.type,
      props.antwoordenFormuliertype,
      meldingId,
      props.antwoordenMeldingId,
      props.isKopie
    )
  ) {
    const formulier = props.formulier;
    const blokDefinitie = FormulierDefinitie.getDuikarbeidFormulierDefinitie(formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(formulier.blokken)}
        createMelding={MeldingBase.ofType(DuikarbeidMelding)}
        getEmailadres={(blokken: DuikarbeidMeldingAntwoordBlokken) => blokken.melder.melder?.emailadres.waarde}
      ></Formulier>
    );
  } else {
    return null;
  }
};
