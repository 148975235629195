import { translationKeys } from "constants/translation-keys";
import i18n from "i18next";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { objectHelpers } from "../../../helpers";
import { AntwoordBlokken } from "../../../models/api";
import { State } from "../../../store";
import { SamenvattingFieldOwnProps } from "../samenvatting-field/samenvatting-field.component.interfaces";
import { SamenvattingContext } from "./../samenvatting-context";
import { WithSamenvattingVraagProps } from "./with-samenvatting-vraag.interfaces";

export const withSamenvattingVraag = <P extends object>(Component: React.ComponentType<P & SamenvattingFieldOwnProps>) => {
  return (props: P & WithSamenvattingVraagProps) => {
    const samenvattingContext = React.useContext(SamenvattingContext);
    const { vraag, getAntwoord, useVertaling, ...rest } = props;
    const lang = i18n.language;

    const { antwoordBlokken } = useSelector<State, { antwoordBlokken: AntwoordBlokken }>(
      (state: State) => ({
        antwoordBlokken: state.antwoorden.blokken
      }),
      shallowEqual
    );

    const antwoord = objectHelpers.getValue<any>(antwoordBlokken, vraag.key);
    const antwoordWaarde = useVertaling ? antwoord?.vertaaldeWaarde : antwoord?.waarde;
    let idExtension = "";

    let label = `${vraag.tekst[lang]}`;
    if (vraag.isAntwoordVertaalbaar && useVertaling) {
      label = `${label} ${i18n.t(translationKeys.melding.mijnMeldingen.vertalingVeldLabelToevoeging)}`;
      idExtension = "__vertaling";
    }

    const fieldProps: SamenvattingFieldOwnProps = {
      id: `${samenvattingContext.idPrefix}__${vraag.key}${idExtension}`,
      label: label,
      waarde: getAntwoord ? getAntwoord(antwoordWaarde, lang) : antwoordWaarde?.toString()
    };

    return <Component {...(rest as P)} {...fieldProps} />;
  };
};
