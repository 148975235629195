import { HasBibobVerzoekVragen, HasBijlageVragen, HasVerzoekMelderVragen } from "..";
import { BibobVerzoekVragen, BijlageVragen, VerzoekMelderVragen, VraagBlokken } from "../blok";

export class BibobFormulierVraagBlokken
  extends VraagBlokken
  implements HasBibobVerzoekVragen, HasVerzoekMelderVragen, HasBijlageVragen
{
  [key: string]: any;

  constructor(public verzoek: BibobVerzoekVragen, public melder: VerzoekMelderVragen, public bijlage: BijlageVragen) {
    super();
  }

  static fromJson(json: any): BibobFormulierVraagBlokken {
    return new BibobFormulierVraagBlokken(
      BibobVerzoekVragen.fromJson("verzoek", json.verzoek),
      VerzoekMelderVragen.fromJson("melder", json.melder),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
