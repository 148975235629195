import {
  AntwoordBlokken,
  LocatieWerkzaamhedenBijBedrijfAntwoorden,
  MelderAntwoorden,
  MeldingOmschrijvingAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasLocatieWerkzaamhedenBijBedrijfAntwoorden } from "./has-locatie-werkzaamheden-bij-bedrijf-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";

export class GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasMelderAntwoorden, HasLocatieWerkzaamhedenBijBedrijfAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public locatie: LocatieWerkzaamhedenBijBedrijfAntwoorden,
    public meldingOmschrijving: MeldingOmschrijvingAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken {
    return new GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      LocatieWerkzaamhedenBijBedrijfAntwoorden.initialize(),
      MeldingOmschrijvingAntwoorden.initialize()
    );
  }

  static fromJson(json: any): GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken {
    return new GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      LocatieWerkzaamhedenBijBedrijfAntwoorden.fromJson(json.locatie),
      MeldingOmschrijvingAntwoorden.fromJson(json.meldingOmschrijving)
    );
  }
}
