import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class SaneringAntwoorden extends AntwoordBlok {
  constructor(public asbesttype: Antwoord<string>, public asbestverwijderingsbedrijfGecertificeerd: Antwoord<string>) {
    super();
  }

  static initialize(): SaneringAntwoorden {
    return new SaneringAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): SaneringAntwoorden {
    return new SaneringAntwoorden(
      Antwoord.fromJson(json.asbesttype),
      Antwoord.fromJson(json.asbestverwijderingsbedrijfGecertificeerd)
    );
  }
}
