import React from "react";
import { Meerkeuzevraag, Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldChecklistvraag } from "../../../samenvatting";

export class GelinkteMeerkeuzeMeerdereAntwoordmogelijkheden {
  static getType = (): VraagType => VraagType.gelinkteMeerkeuzeMeerdereAntwoordmogelijkheden;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldChecklistvraag vraag={vraag as Meerkeuzevraag} />;
}
