import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { IntrekkenButtonProps } from "./intrekken-button.component.interfaces";

export const IntrekkenButton = (props: IntrekkenButtonProps) => {
  const { t } = useTranslation();

  const trekMeldingIn = () => {
    props.intrekkenAction(props.meldingId);
  };

  return (
    <Fragment>
      <Button id="intrekkenButton" className="actie-button" onClick={trekMeldingIn} role="button">
        <FontAwesomeIcon icon={faTrash} /> {t(translationKeys.melding.mijnMeldingen.intrekken)}
      </Button>
    </Fragment>
  );
};
