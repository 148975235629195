import { useEffect } from "react";
import { Formulier } from "..";
import { FormulierDefinitie } from "../../../formulier-definitie";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { AutorisatieaanvraagMelding, AutorisatieaanvraagMeldingAntwoordBlokken, MeldingBase } from "../../../models/api";
import { BlokDefinitie, Formuliertype } from "../../../models/application";
import { AutorisatieaanvraagFormulierComponentProps } from "./autorisatieaanvraag-formulier.component.interfaces";

export const AutorisatieaanvraagFormulierComponent = (props: AutorisatieaanvraagFormulierComponentProps) => {
  const { onInit, ...propsRest } = props;

  useEffect(() => {
    onInit();
  }, [onInit]);

  const formuliertype = Formuliertype.autorisatieaanvraag;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(formuliertype, props.formulier.type, props.antwoordenFormuliertype)
  ) {
    const blokDefinitie: BlokDefinitie[] = FormulierDefinitie.getAutorisatieaanvraagFormulierDefinitie(props.formulier);

    return (
      <Formulier
        type={formuliertype}
        {...propsRest}
        blokDefinitie={blokDefinitie}
        validate={MeldingValidator.createValidate(props.formulier.blokken)}
        createMelding={MeldingBase.ofType(AutorisatieaanvraagMelding)}
        getEmailadres={(blokken: AutorisatieaanvraagMeldingAntwoordBlokken) =>
          blokken.autorisatieaanvraag.autorisatieEmailadres?.waarde
        }
        afterSubmitCompleted={props.onCreated}
      ></Formulier>
    );
  } else {
    return null;
  }
};
