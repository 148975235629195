import { Formik, useField, useFormikContext } from "formik";
import React from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { meldingHelpers } from "../../../helpers";
import { Antwoord, AntwoordBlok, TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden } from "../../../models/api";
import { Field } from "../field";
import { InputTabularOwnProps } from "./input-tabular.interfaces";

const createValidate = (validator: (values: any) => any, bloknaam: string, vraagnaam: string) => {
  return (values: any) => validator(values[bloknaam][vraagnaam]);
};

export const InputTabular = (props: InputTabularOwnProps) => {
  const { getItemTekst, itemTemplate, initialAddValues, validateForm, name, vraagKey, onAdd, onDelete, ...propsRest } =
    props;

  const [field, , helpers] = useField<Antwoord<TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden[]>>(vraagKey);
  const { validateField } = useFormikContext();

  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const [bloknaam, vraagnaam] = meldingHelpers.getKeyPartsFromKey(vraagKey);

  const initialAddValuesWrappedInVraag: any = {};
  initialAddValuesWrappedInVraag[bloknaam] = {};
  initialAddValuesWrappedInVraag[bloknaam][vraagnaam] = initialAddValues;

  const renderValues = props.values.length > 0;

  const addItem = (antwoordBlok: AntwoordBlok) => {
    helpers.setValue(
      new Antwoord<TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden[]>([
        ...field.value.waarde,
        antwoordBlok as TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden
      ])
    );
  };

  const deleteItem = (index: number) => {
    helpers.setValue(
      new Antwoord<TeVerwijderenAsbestMateriaalRisicoklasse1Antwoorden[]>(field.value.waarde.filter((_v, i) => i !== index))
    );
  };

  return (
    <Field name={name} {...propsRest}>
      <Formik
        validate={createValidate(validateForm, bloknaam, vraagnaam)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          addItem(values[bloknaam][vraagnaam]);
          validateField(name);
          actions.resetForm();
        }}
        initialValues={initialAddValuesWrappedInVraag}
      >
        {(addFormik) => (
          <Table className="input-tabular-add">
            <thead>
              <tr>
                {itemTemplate.map((i) => (
                  <th className="text-nowrap" key={`add_th_${i.vraag.key}`}>
                    {i.vraag.tekst[lang]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {itemTemplate.map((i) => (
                  <td key={`add_td_${i.vraag.key}`}>
                    <Form.Label>{i.vraag.tekst[lang]}</Form.Label>
                    {React.createElement(i.component, {
                      vraag: i.vraag,
                      inputOnly: true
                    })}
                  </td>
                ))}
              </tr>
              <tr>
                <td colSpan={itemTemplate.length}>
                  <Button onClick={addFormik.submitForm} id={`${bloknaam}.${vraagnaam}.add`}>
                    {t(translationKeys.toevoegen)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </Formik>

      {renderValues && (
        <Table className="input-tabular-items">
          <thead>
            <tr>
              {itemTemplate.map((i) => (
                <th key={`values_th_${i.vraag.key}`}>{i.vraag.tekst[lang]}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.values &&
              props.values.map((value, index) => (
                <tr key={index} id={`${bloknaam}.${vraagnaam}.row-${index}`}>
                  {itemTemplate.map((i) => (
                    <td key={`values_td_${i.vraag.key}`} id={`${bloknaam}.${vraagnaam}.row-${index}-${i.vraag.key}`}>
                      <Form.Label>{i.vraag.tekst[lang]}</Form.Label>
                      {i.getAntwoord(value)}
                    </td>
                  ))}
                  <td className="text-lg-right align-middle" id={`${bloknaam}.${vraagnaam}.row-${index}-verwijderen`}>
                    <Button
                      onClick={() => {
                        deleteItem(index);
                        validateField(name);
                      }}
                      id={`${bloknaam}.${vraagnaam}.row-${index}.delete`}
                      title={t(translationKeys.verwijderItem, {
                        item: getItemTekst(value)
                      })}
                    >
                      {t(translationKeys.verwijderen)}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Field>
  );
};
