import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";

export class CollectionTypeahead {
  static getType = (): VraagType => VraagType.collectionTypeahead;

  static create = (vraag: Vraag, readOnly = false): React.JSX.Element => (
    <FormulierInputControls.TypeaheadCollection vraag={vraag} readOnly={readOnly} />
  );
}
