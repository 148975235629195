import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class BijlageAntwoorden extends AntwoordBlok {
  constructor(public bestanden: Antwoord<Bestand[]>) {
    super();
  }

  static initialize(): BijlageAntwoorden {
    return new BijlageAntwoorden(new Antwoord<Bestand[]>([]));
  }

  static fromJson(json: any): BijlageAntwoorden {
    return new BijlageAntwoorden(Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson));
  }
}
