import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, ProductveiligheidMelderAntwoorden, ProductveiligheidMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initProductveiligheidFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getProductveiligheidFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getProductveiligheidFormulierDefinitie,
      ProductveiligheidMeldingAntwoordBlokken.initialize
    );
  };

export const initProductveiligheidFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getProductveiligheidFormulier(),
      meldingApi.getProductveiligheidMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): ProductveiligheidMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: ProductveiligheidMelderAntwoorden.initialize(user),
        werkzaamheden: {
          ...melding.blokken.werkzaamheden,
          beeldmateriaalBestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getProductveiligheidFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
