import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { UploadRieFormulierVraagBlokken } from "./upload-rie-formulier-vraag-blokken";

export class UploadRieFormulier extends FormulierBase {
  constructor(public blokken: UploadRieFormulierVraagBlokken, json: any) {
    super(Formuliertype.uploadRie, blokken, json);
  }

  static fromJson(json: any): UploadRieFormulier {
    return new UploadRieFormulier(UploadRieFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
