import { userHelpers } from "helpers";
import { User } from "../security/user";
import { UserExpression } from "./user-expression";

export class IsCurrentUserAnInterneMedewerker extends UserExpression {
  get operator(): string {
    return IsCurrentUserAnInterneMedewerker.operator();
  }

  executeUser(user: User): boolean {
    return userHelpers.getIsInternalUser(user);
  }

  static fromJson = (): IsCurrentUserAnInterneMedewerker => new IsCurrentUserAnInterneMedewerker();

  static operator = (): string => "isCurrentUserAnInterneMedewerker";

  static matches = (json: any): boolean => json?.operator === IsCurrentUserAnInterneMedewerker.operator();
}
