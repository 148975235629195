import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden extends AntwoordBlok {
  /**
   *
   */
  constructor(
    public startdatum: Antwoord<string>,
    public einddatum: Antwoord<string>,
    public aantalUurPerDagAanWerkzaamheden: Antwoord<number | null>,
    public aantalDagenPerWeekAanWerkzaamheden: Antwoord<number | null>,
    public omschrijvingVosHoudendeProduct: Antwoord<string>
  ) {
    super();
  }

  static initialize(): VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden {
    return new VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden {
    return new VluchtigeOrganischeStoffenWerkzaamhedenAntwoorden(
      Antwoord.fromJson<string>(json.startdatum),
      Antwoord.fromJson<string>(json.einddatum),
      Antwoord.fromJson<number | null>(json.aantalUurPerDagAanWerkzaamheden),
      Antwoord.fromJson<number | null>(json.aantalDagenPerWeekAanWerkzaamheden),
      Antwoord.fromJson<string>(json.omschrijvingVosHoudendeProduct)
    );
  }
}
