import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { ReactieOpenbaarmakingMeldingAntwoordBlokken, ReactieOpenbaarmakingReactieAntwoorden } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initReactieOpenbaarmakingFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getReactieOpenbaarmakingFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getReactieOpenbaarmakingFormulierDefinitie,
      ReactieOpenbaarmakingMeldingAntwoordBlokken.initialize
    );
  };

export const initReactieOpenbaarmakingFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getReactieOpenbaarmakingFormulier(),
      meldingApi.getReactieOpenbaarmakingMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): ReactieOpenbaarmakingMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        reactie: ReactieOpenbaarmakingReactieAntwoorden.initialize(user)
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getReactieOpenbaarmakingFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
