import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translationKeys } from "constants/translation-keys";
import { useFormikContext } from "formik";
import { objectHelpers } from "helpers";
import _ from "lodash";
import { AntwoordBlokken } from "models/api";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CollectionItemCanBeCopiedResult, useCollectionEvents } from "../context";
import { CollectionCopyProps } from "./collection-copy.component.interfaces";

export const CollectionCopy = (props: CollectionCopyProps) => {
  const { t } = useTranslation();
  const antwoordenContext = useFormikContext<AntwoordBlokken>();
  const collectionEvents = useCollectionEvents();
  const [canBeCopied, setCanBeCopied] = useState<CollectionItemCanBeCopiedResult | undefined>(undefined);
  const enableCopy = canBeCopied?.canBeCopied ?? true;
  const { values } = antwoordenContext;
  const collectionFromFomik = objectHelpers.getVraagBlokCollectionValues<any>(values, props.collectionKey);

  const handleCanBeCopied = useCallback(
    (collection: any[] | null) => {
      if (collectionEvents.canBeCopied) {
        const canBeCopiedResult = collectionEvents.canBeCopied(
          props.vraagAntwoordBlok.vraagBlok.key,
          props.vraagAntwoordBlok.antwoordBlokIndex,
          _.isNil(collection) ? undefined : collection[props.vraagAntwoordBlok.antwoordBlokIndex]
        );

        setCanBeCopied(canBeCopiedResult);
        return canBeCopiedResult?.canBeCopied ?? true;
      }
      return true;
    },
    [props.vraagAntwoordBlok.vraagBlok.key, props.vraagAntwoordBlok.antwoordBlokIndex, collectionEvents]
  );

  useEffect(() => {
    handleCanBeCopied(collectionFromFomik);
  }, [handleCanBeCopied, collectionFromFomik]);

  const onCopyItem = async (e: any) => {
    e.stopPropagation();
    props.onCopyVraagBlok(antwoordenContext, props.vraagAntwoordBlok.antwoordBlokIndex);
  };

  const title = !enableCopy && !!canBeCopied?.reason ? canBeCopied?.reason : t(translationKeys.kopieren);

  return (
    <Fragment>
      <Button
        disabled={!enableCopy || props.hasCollectionValidationFailures}
        id={`${props.collectionKey}.list.${props.rowIndex}.icon.copy`}
        className="icon-button"
        variant="link"
        onClick={onCopyItem}
        title={title}
      >
        <span className="visually-hidden">{title}</span>
        <FontAwesomeIcon icon={faCopy} size="1x" />
      </Button>
    </Fragment>
  );
};
