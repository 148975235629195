import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";
import { BuitenlandsAdresAntwoorden } from "./buitenlands-adres-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class AutorisatieaanvraagAntwoorden extends AntwoordBlok {
  constructor(
    public voorletters: Antwoord<string>,
    public tussenvoegsels: Antwoord<string>,
    public achternaam: Antwoord<string>,
    public geslacht: Antwoord<string>,
    public autorisatieEmailadres: Antwoord<string>,
    public controleAutorisatieEmailadres: Antwoord<string>,
    public opEmailadresBerichtenOntvangen: Antwoord<string>,
    public meldingenEmailadres: Antwoord<string>,
    public controleMeldingenEmailadres: Antwoord<string>,
    public telefoonnummer: Antwoord<string>,
    public aanvraagVoor: Antwoord<string>,
    public melderAdres: CorrespondentieadresAntwoorden,
    public waarBedrijfGevestigd: Antwoord<string>,
    public bedrijfsgegevens: BedrijfsgegevensAntwoorden,
    public naamBedrijf: Antwoord<string>,
    public buitenlandsAdres: BuitenlandsAdresAntwoorden
  ) {
    super();
  }

  static initialize(): AutorisatieaanvraagAntwoorden {
    return new AutorisatieaanvraagAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>(""),
      BedrijfsgegevensAntwoorden.initialize(),
      new Antwoord<string>(""),
      BuitenlandsAdresAntwoorden.initialize()
    );
  }
}
