import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { BreadcrumbProps } from "./breadcrumb.component.interface";

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { onAdd, onClearBreadcrumb, text, url, titel, shouldRender } = props;

  useEffect(() => {
    if (shouldRender) {
      const id = uuidv4();
      onAdd({ id: id, text: text, url: url, titel: titel });
      return () => onClearBreadcrumb(id);
    }
  }, [onAdd, onClearBreadcrumb, text, url, titel, shouldRender]);

  return null;
};
