import { reducerHelper } from "helpers";
import {
  BrpActions,
  NavigationActions,
  REMOVE_VRAAGBLOK_FROM_COLLECTION,
  ROUTER_LOCATION_CHANGE,
  VragenActions
} from "../actions";
import { SET_BRP_SEARCH_BUSY, SET_BRP_SEARCH_RESULT } from "./../actions/brp-actions";
import { RESET_FORM, SET_STATE, SharedActions } from "./../actions/shared-actions";
import { BrpState } from "./../store";

const initialState: BrpState = {
  results: {}
};

const brpReducer = (
  state: BrpState = initialState,
  action: BrpActions | SharedActions | VragenActions | NavigationActions
): BrpState => {
  switch (action.type) {
    case SET_STATE: {
      return { ...state };
    }
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM: {
      return initialState;
    }
    case SET_BRP_SEARCH_RESULT: {
      return {
        ...state,
        results: {
          ...state.results,
          [action.key]: {
            ...state.results[action.key],
            tussenvoegsels: action.result.tussenvoegsels,
            achternaam: action.result.achternaam
          }
        }
      };
    }
    case SET_BRP_SEARCH_BUSY: {
      return {
        ...state,
        results: {
          ...state.results,
          [action.key]: { ...state.results[action.key], busy: action.busy, error: action.error }
        }
      };
    }
    case REMOVE_VRAAGBLOK_FROM_COLLECTION: {
      const reindexSearchesCopy = { ...state.results };
      reducerHelper.reindexCollection(reindexSearchesCopy, action.collectionIndex, `${action.collectionKey}.list`);
      return {
        ...state,
        results: reindexSearchesCopy
      };
    }
    default:
      return state;
  }
};

export default brpReducer;
