import { Antwoord } from "../antwoord";
import { PeriodeAntwoorden } from "./periode-antwoorden";

export class PeriodeMetRedenAntwoorden extends PeriodeAntwoorden {
  constructor(
    public begindatum: Antwoord<string>,
    public begintijd: Antwoord<string>,
    public einddatum: Antwoord<string>,
    public eindtijd: Antwoord<string>,
    public redenVanVerlateMelding: Antwoord<string>,
    public uitlegOverigeRedenVanVerlateMelding: Antwoord<string>
  ) {
    super(begindatum, begintijd, einddatum, eindtijd);
  }

  static initialize(): PeriodeMetRedenAntwoorden {
    return new PeriodeMetRedenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): PeriodeMetRedenAntwoorden {
    return new PeriodeMetRedenAntwoorden(
      Antwoord.fromJson<string>(json.begindatum),
      Antwoord.fromJson<string>(json.begintijd),
      Antwoord.fromJson<string>(json.einddatum),
      Antwoord.fromJson<string>(json.eindtijd),
      Antwoord.fromJson<string>(json.redenVanVerlateMelding),
      Antwoord.fromJson<string>(json.uitlegOverigeRedenVanVerlateMelding)
    );
  }
}
