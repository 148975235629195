import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { PeriodeDatumAntwoorden } from "./periode-datum-antwoorden";

export class WooVerzoekAntwoorden extends AntwoordBlok {
  constructor(
    public omschrijvingVerzoek: Antwoord<string>,
    public periode: PeriodeDatumAntwoorden,
    public externeReferentie: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): WooVerzoekAntwoorden {
    return new WooVerzoekAntwoorden(new Antwoord<string>(""), PeriodeDatumAntwoorden.initialize(), new Antwoord<string>(""));
  }

  static fromJson(json: any): WooVerzoekAntwoorden {
    return new WooVerzoekAntwoorden(
      Antwoord.fromJson<string>(json.omschrijvingVerzoek),
      PeriodeDatumAntwoorden.fromJson(json.periode),
      Antwoord.fromJson<string>(json.externeReferentie)
    );
  }
}
