import { VuurOpTankschipFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getVuurOpTankschipFormulierDefinitie = (formulier: VuurOpTankschipFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.aanvrager.titel,
    type: ProcesstapType.aanvrager
  },
  {
    title: formulier.blokken.werkgever.titelVerkort,
    type: ProcesstapType.werkgever
  },
  {
    title: formulier.blokken.tankschip.titelVerkort,
    type: ProcesstapType.tankschip
  },
  {
    title: formulier.blokken.periode.titel,
    type: ProcesstapType.periode
  },
  {
    title: formulier.blokken.werkzaamheden.titel,
    type: ProcesstapType.werkzaamheden
  },
  {
    title: formulier.blokken.verklaringen.titel,
    type: ProcesstapType.verklaringen
  }
];
