import { Configuration } from "../configuration";
import { Formuliertype } from "../models/application";
import {
  AanwijzingRisicovolleBedrijvenFormulier,
  ArieFormulier,
  AsbestRisicoklasse1Formulier,
  AsbestverwijderingFormulier,
  AsbestverwijderingLavsFormulier,
  AsbestverwijderingLavsV2Formulier,
  AutorisatieaanvraagFormulier,
  AutorisatieProfielFormulier,
  AutorisatiesZoekenFormulier,
  AvvArtikel10Formulier,
  AvvArtikel10AVerzoekFormulier,
  BergplaatsRadioactieveStoffenFormulier,
  BibobFormulier,
  BiologischeAgentiaFormulier,
  BouwprocesFormulier,
  CbiFormulier,
  ContactFormulier,
  DigipassFormulier,
  DouaneFormulier,
  DuikarbeidFormulier,
  Formulier,
  GetFormulierenResponse,
  GevaarlijkeGassenImportcontainersFormulier,
  GevaarlijkeLiftFormulier,
  HandelingenIoniserendeStralingFormulier,
  InformatieverzoekFormulier,
  KlachtenFormulier,
  KlachtFormulier,
  OngevalFormulier,
  OngewoneVoorvallenFormulier,
  OntheffingKinderarbeidFormulier,
  OntheffingLiftenFormulier,
  OntheffingNachtarbeidFormulier,
  OntheffingVuurOpTankschipFormulier,
  PredicaatKoninklijkFormulier,
  ProductveiligheidFormulier,
  ProfielFormulier,
  ReactieOpenbaarmakingFormulier,
  UploadGeneriekFormulier,
  UploadRieFormulier,
  VerzoekTotGezamenlijkeInterventieFormulier,
  VluchtigeOrganischeStoffenFormulier,
  VuurOpTankschipFormulier,
  WerkbakFormulier,
  WooFormulier
} from "./../models/api";
import { get } from "./api";

const webportaalFormulierTypeSegment = "webportaal/formulier";

const getFormulier = <T extends Formulier>(type: string, toJson: (r: any) => T, typeSegment = "formulier"): Promise<T> => {
  return get({
    uri: `${Configuration.apiUrl}/${typeSegment}/${type}`,
    success: (r) => toJson(r)
  });
};

const getAutorisatieaanvraagFormulier = (): Promise<AutorisatieaanvraagFormulier> => {
  return getFormulier(
    Formuliertype.autorisatieaanvraag,
    (r) => AutorisatieaanvraagFormulier.fromJson(r),
    webportaalFormulierTypeSegment
  );
};

const getAutorisatiesZoekenFormulier = (): Promise<AutorisatiesZoekenFormulier> => {
  return getFormulier(
    Formuliertype.autorisatiesZoeken,
    (r) => AutorisatiesZoekenFormulier.fromJson(r),
    webportaalFormulierTypeSegment
  );
};

const getProfielFormulier = (): Promise<ProfielFormulier> => {
  return getFormulier(Formuliertype.profiel, (r) => ProfielFormulier.fromJson(r), webportaalFormulierTypeSegment);
};

const getAutorisatieProfielFormulier = (): Promise<AutorisatieProfielFormulier> => {
  return getFormulier(
    Formuliertype.autorisatieProfiel,
    (r) => AutorisatieProfielFormulier.fromJson(r),
    webportaalFormulierTypeSegment
  );
};

const getWerkbakFormulier = (): Promise<WerkbakFormulier> => {
  return getFormulier(Formuliertype.werkbak, (r) => WerkbakFormulier.fromJson(r));
};

const getContactFormulier = (): Promise<ContactFormulier> => {
  return getFormulier(Formuliertype.contact, (r) => ContactFormulier.fromJson(r));
};

const getKlachtFormulier = (): Promise<KlachtFormulier> => {
  return getFormulier(Formuliertype.klacht, (r) => KlachtFormulier.fromJson(r));
};

const getKlachtenFormulier = (): Promise<KlachtenFormulier> => {
  return getFormulier(Formuliertype.klachten, (r) => KlachtenFormulier.fromJson(r));
};

const getArieFormulier = (): Promise<ArieFormulier> => {
  return getFormulier(Formuliertype.arie, (r) => ArieFormulier.fromJson(r));
};

const getDouaneFormulier = (): Promise<DouaneFormulier> => {
  return getFormulier(Formuliertype.douane, (r) => DouaneFormulier.fromJson(r));
};

const getDuikarbeidFormulier = (): Promise<DuikarbeidFormulier> => {
  return getFormulier(Formuliertype.duikarbeid, (r) => DuikarbeidFormulier.fromJson(r));
};

const getOntheffingNachtarbeidFormulier = (): Promise<OntheffingNachtarbeidFormulier> => {
  return getFormulier(Formuliertype.ontheffingNachtarbeid, (r) => OntheffingNachtarbeidFormulier.fromJson(r));
};

const getOntheffingLiftenFormulier = (): Promise<OntheffingLiftenFormulier> => {
  return getFormulier(Formuliertype.ontheffingLiften, (r) => OntheffingLiftenFormulier.fromJson(r));
};

const getVuurOpTankschipFormulier = (): Promise<VuurOpTankschipFormulier> => {
  return getFormulier(Formuliertype.vuurOpTankschip, (r) => VuurOpTankschipFormulier.fromJson(r));
};

const getAsbestRisicoklasse1Formulier = (): Promise<AsbestRisicoklasse1Formulier> => {
  return getFormulier(Formuliertype.asbestRisicoklasse1, (r) => AsbestRisicoklasse1Formulier.fromJson(r));
};

const getBouwprocesFormulier = (): Promise<BouwprocesFormulier> => {
  return getFormulier(Formuliertype.bouwproces, (r) => BouwprocesFormulier.fromJson(r));
};

const getOngevalFormulier = (): Promise<OngevalFormulier> => {
  return getFormulier(Formuliertype.ongeval, (r) => OngevalFormulier.fromJson(r));
};

const getProductveiligheidFormulier = (): Promise<ProductveiligheidFormulier> => {
  return getFormulier(Formuliertype.productveiligheid, (r) => ProductveiligheidFormulier.fromJson(r));
};

const getBiologischeAgentiaFormulier = (): Promise<BiologischeAgentiaFormulier> => {
  return getFormulier(Formuliertype.biologischeAgentia, (r) => BiologischeAgentiaFormulier.fromJson(r));
};

const getVluchtigeOrganischeStoffenFormulier = (): Promise<VluchtigeOrganischeStoffenFormulier> => {
  return getFormulier(Formuliertype.vluchtigeOrganischeStoffen, (r) => VluchtigeOrganischeStoffenFormulier.fromJson(r));
};

const getReactieOpenbaarmakingFormulier = (): Promise<ReactieOpenbaarmakingFormulier> => {
  return getFormulier(Formuliertype.reactieOpenbaarmaking, (r) => ReactieOpenbaarmakingFormulier.fromJson(r));
};

const getDigipassFormulier = (): Promise<DigipassFormulier> => {
  return getFormulier(Formuliertype.digipass, (r) => DigipassFormulier.fromJson(r));
};

const getUploadGeneriekFormulier = (): Promise<UploadGeneriekFormulier> => {
  return getFormulier(Formuliertype.uploadGeneriek, (r) => UploadGeneriekFormulier.fromJson(r));
};

const getUploadRieFormulier = (): Promise<UploadRieFormulier> => {
  return getFormulier(Formuliertype.uploadRie, (r) => UploadRieFormulier.fromJson(r));
};

const getCbiFormulier = (): Promise<CbiFormulier> => {
  return getFormulier(Formuliertype.cbi, (r) => CbiFormulier.fromJson(r));
};

const getOntheffingKinderarbeidFormulier = (): Promise<OntheffingKinderarbeidFormulier> => {
  return getFormulier(Formuliertype.ontheffingKinderarbeid, (r) => OntheffingKinderarbeidFormulier.fromJson(r));
};

const getAsbestverwijderingLavsFormulier = (): Promise<AsbestverwijderingLavsFormulier> => {
  return getFormulier(Formuliertype.asbestverwijderingLavs, (r) => AsbestverwijderingLavsFormulier.fromJson(r));
};

const getAsbestverwijderingLavsV2Formulier = (): Promise<AsbestverwijderingLavsV2Formulier> => {
  return getFormulier(Formuliertype.asbestverwijderingLavsV2, (r) => AsbestverwijderingLavsV2Formulier.fromJson(r));
};

const getAsbestverwijderingFormulier = (): Promise<AsbestverwijderingFormulier> => {
  return getFormulier(Formuliertype.asbestverwijdering, (r) => AsbestverwijderingFormulier.fromJson(r));
};

const getAanwijzingRisicovolleBedrijvenFormulier = (): Promise<AanwijzingRisicovolleBedrijvenFormulier> => {
  return getFormulier(Formuliertype.aanwijzingRisicovolleBedrijven, (r) =>
    AanwijzingRisicovolleBedrijvenFormulier.fromJson(r)
  );
};

const getBergplaatsRadioactieveStoffenFormulier = (): Promise<BergplaatsRadioactieveStoffenFormulier> => {
  return getFormulier(Formuliertype.bergplaatsRadioactieveStoffen, (r) =>
    BergplaatsRadioactieveStoffenFormulier.fromJson(r)
  );
};

const getGevaarlijkeGassenImportcontainersFormulier = (): Promise<GevaarlijkeGassenImportcontainersFormulier> => {
  return getFormulier(Formuliertype.gevaarlijkeGassenImportcontainers, (r) =>
    GevaarlijkeGassenImportcontainersFormulier.fromJson(r)
  );
};

const getGevaarlijkeLiftFormulier = (): Promise<GevaarlijkeLiftFormulier> => {
  return getFormulier(Formuliertype.gevaarlijkeLift, (r) => GevaarlijkeLiftFormulier.fromJson(r));
};

const getHandelingenIoniserendeStralingFormulier = (): Promise<HandelingenIoniserendeStralingFormulier> => {
  return getFormulier(Formuliertype.handelingenIoniserendeStraling, (r) =>
    HandelingenIoniserendeStralingFormulier.fromJson(r)
  );
};

const getOngewoneVoorvallenFormulier = (): Promise<OngewoneVoorvallenFormulier> => {
  return getFormulier(Formuliertype.ongewoneVoorvallen, (r) => OngewoneVoorvallenFormulier.fromJson(r));
};

const getBibobFormulier = (): Promise<BibobFormulier> => {
  return getFormulier(Formuliertype.bibob, (r) => BibobFormulier.fromJson(r));
};

const getInformatieverzoekFormulier = (): Promise<InformatieverzoekFormulier> => {
  return getFormulier(Formuliertype.informatieverzoek, (r) => InformatieverzoekFormulier.fromJson(r));
};

const getWooFormulier = (): Promise<WooFormulier> => {
  return getFormulier(Formuliertype.woo, (r) => WooFormulier.fromJson(r));
};

const getPredicaatKoninklijkFormulier = (): Promise<PredicaatKoninklijkFormulier> => {
  return getFormulier(Formuliertype.predicaatKoninklijk, (r) => PredicaatKoninklijkFormulier.fromJson(r));
};

const getVerzoekTotGezamenlijkeInterventieFormulier = (): Promise<VerzoekTotGezamenlijkeInterventieFormulier> => {
  return getFormulier(Formuliertype.verzoekTotGezamenlijkeInterventie, (r) =>
    VerzoekTotGezamenlijkeInterventieFormulier.fromJson(r)
  );
};

const getOntheffingVuurOpTankschipFormulier = (): Promise<OntheffingVuurOpTankschipFormulier> => {
  return getFormulier(Formuliertype.ontheffingVuurOpTankschip, (r) => OntheffingVuurOpTankschipFormulier.fromJson(r));
};

const getAvvArtikel10Formulier = (): Promise<AvvArtikel10Formulier> => {
  return getFormulier(Formuliertype.avvartikel10, (r) => AvvArtikel10Formulier.fromJson(r));
};

const getAvvArtikel10AVerzoekFormulier = (): Promise<AvvArtikel10AVerzoekFormulier> => {
  return getFormulier(Formuliertype.avvArtikel10AVerzoek, (r) => AvvArtikel10AVerzoekFormulier.fromJson(r));
};

const getFormulieren = (): Promise<GetFormulierenResponse> => {
  return get({
    uri: `${Configuration.apiUrl}/formulier/metadata`,
    success: (r) => r as GetFormulierenResponse
  });
};

export const formulierApi = {
  getAutorisatieaanvraagFormulier,
  getAutorisatiesZoekenFormulier,
  getAutorisatieProfielFormulier,
  getAvvArtikel10Formulier,
  getProfielFormulier,
  getWerkbakFormulier,
  getArieFormulier,
  getDouaneFormulier,
  getDuikarbeidFormulier,
  getContactFormulier,
  getKlachtFormulier,
  getKlachtenFormulier,
  getOntheffingLiftenFormulier,
  getOntheffingNachtarbeidFormulier,
  getVuurOpTankschipFormulier,
  getAsbestRisicoklasse1Formulier,
  getBouwprocesFormulier,
  getOngevalFormulier,
  getProductveiligheidFormulier,
  getBiologischeAgentiaFormulier,
  getVluchtigeOrganischeStoffenFormulier,
  getReactieOpenbaarmakingFormulier,
  getDigipassFormulier,
  getUploadGeneriekFormulier,
  getUploadRieFormulier,
  getCbiFormulier,
  getOntheffingKinderarbeidFormulier,
  getAsbestverwijderingLavsFormulier,
  getAsbestverwijderingLavsV2Formulier,
  getAsbestverwijderingFormulier,
  getAanwijzingRisicovolleBedrijvenFormulier,
  getBergplaatsRadioactieveStoffenFormulier,
  getGevaarlijkeGassenImportcontainersFormulier,
  getGevaarlijkeLiftFormulier,
  getHandelingenIoniserendeStralingFormulier,
  getOngewoneVoorvallenFormulier,
  getBibobFormulier,
  getInformatieverzoekFormulier,
  getWooFormulier,
  getPredicaatKoninklijkFormulier,
  getVerzoekTotGezamenlijkeInterventieFormulier,
  getOntheffingVuurOpTankschipFormulier,
  getAvvArtikel10AVerzoekFormulier,
  getFormulieren
};
