import { PredicaatKoninklijkFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getPredicaatKoninklijkFormulierDefinitie = (formulier: PredicaatKoninklijkFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.aanvraag.titelVerkort,
      type: ProcesstapType.aanvraag
    },
    {
      title: formulier.blokken.aanvrager.titel,
      type: ProcesstapType.aanvrager
    },
    {
      title: formulier.blokken.bijlage.titelVerkort,
      type: ProcesstapType.bijlage
    }
  ];
};
