import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { AntwoordBlokken } from "models/api";
import { Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CollectionAddProps } from "./collection-add.component.interfaces";

export const CollectionAdd = (props: CollectionAddProps) => {
  const { i18n } = useTranslation();
  const antwoordenContext = useFormikContext<AntwoordBlokken>();
  const { addInitialVraagBlok } = props;

  const buttonProps =
    props.totalRecords > 0
      ? {
          css: "add-extra-vraagblok-button",
          buttonText: props.extraToevoegenTekst[i18n.language]
        }
      : {
          css: "add-vraagblok-button",
          buttonText: props.toevoegenTekst[i18n.language]
        };

  useEffect(() => {
    addInitialVraagBlok(antwoordenContext);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Button
        disabled={props.hasCollectionValidationFailures}
        id={`${props.collectionKey}.add`}
        onClick={() => props.onAddVraagBlok(antwoordenContext)}
        className={`button-with-icon-start mb-2 ${buttonProps.css}`}
      >
        <FontAwesomeIcon icon={faPlusSquare} size="1x" title={buttonProps.buttonText} />
        {buttonProps.buttonText}
      </Button>
    </Fragment>
  );
};
