import { shallowEqual, useSelector } from "react-redux";
import { State } from "store";

export const withVertaling = <T extends object>(Component: React.ComponentType<T>) => {
  return (props: any) => {
    const isVertaalbaar = props.vraag?.isAntwoordVertaalbaar;
    const { wordtVertaald } = useSelector<
      State,
      {
        wordtVertaald: boolean;
      }
    >(
      (state: State) => ({
        wordtVertaald: state.melding?.wordtVertaald
      }),
      shallowEqual
    );

    return (
      <>
        <Component {...props} />
        {wordtVertaald && isVertaalbaar && <Component {...props} readOnly={false} useVertaling={true} />}
      </>
    );
  };
};
