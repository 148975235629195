import {
  InleidingNachtarbeidVragen,
  OmstandighedenNachtarbeidVragen,
  OntheffingNachtarbeidWerkgeverVragen,
  VraagBlokken
} from "../blok";
import { HasInleidingNachtarbeidVragen } from "./has-inleiding-nachtarbeid-vragen";
import { HasOmstandighedenNachtarbeidVragen } from "./has-omstandigheden-nachtarbeid-vragen";
import { HasOntheffingNachtarbeidWerkgeverVragen } from "./has-ontheffing-nachtarbeid-werkgever-vragen";

export class OntheffingNachtarbeidFormulierVraagBlokken
  extends VraagBlokken
  implements HasInleidingNachtarbeidVragen, HasOntheffingNachtarbeidWerkgeverVragen, HasOmstandighedenNachtarbeidVragen
{
  [key: string]: any;

  constructor(
    public inleiding: InleidingNachtarbeidVragen,
    public werkgever: OntheffingNachtarbeidWerkgeverVragen,
    public omstandigheden: OmstandighedenNachtarbeidVragen
  ) {
    super();
  }

  static fromJson(json: any): OntheffingNachtarbeidFormulierVraagBlokken {
    return new OntheffingNachtarbeidFormulierVraagBlokken(
      InleidingNachtarbeidVragen.fromJson("inleiding", json.inleiding),
      OntheffingNachtarbeidWerkgeverVragen.fromJson("werkgever", json.werkgever),
      OmstandighedenNachtarbeidVragen.fromJson("omstandigheden", json.omstandigheden)
    );
  }
}
