import { jsonHelpers } from "helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";
import { BijlageVragen } from "./bijlage-vragen";

export class BijlageDouaneVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public douaneBijlageDocumenten: Meerkeuzevraag,
    public bestanden: BijlageVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): BijlageDouaneVragen {
    return new BijlageDouaneVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toChecklistvraag(`${key}.douaneBijlageDocumenten`, json.douaneBijlageDocumenten),
      BijlageVragen.fromJson(`${key}.bestanden`, json.bestanden)
    );
  }
}
