import { jsonHelpers } from "../../../helpers";
import { CorrespondentieAdresVragenDisplayDefinition } from "../display-definition";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { AdresVragen } from "./adres-vragen";
import { NederlandsAdresVragen } from "./nederlands-adres-vragen";

export class CorrespondentieadresVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public land: Vraag,
    public nederlandsAdres: NederlandsAdresVragen,
    public buitenlandsAdres: AdresVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly);
  }

  static fromJson(
    key: string,
    json: any,
    displayDefinition?: CorrespondentieAdresVragenDisplayDefinition
  ): CorrespondentieadresVragen {
    return new CorrespondentieadresVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toLandVraag(`${key}.land`, json.land, displayDefinition?.land),
      NederlandsAdresVragen.fromJson(`${key}.nederlandsAdres`, json.nederlandsAdres, displayDefinition?.nederlandsAdres),
      AdresVragen.fromJson(`${key}.buitenlandsAdres`, json.buitenlandsAdres)
    );
  }
}
