import {
  RESET_ZOEK_MELDINGEN,
  SET_ZOEK_MELDINGEN_FAILED,
  SET_ZOEK_MELDINGEN_FILTER,
  SET_ZOEK_MELDINGEN_LOADING,
  SET_ZOEK_MELDINGEN_RESULT,
  SET_ZOEK_MELDINGEN_SUCCESS,
  ZoekMeldingenActions
} from "../actions/zoek-meldingen-actions";
import { MeldingFilters } from "../models/api/zoek";
import { Formuliercategorie } from "../models/application";
import { Order } from "../store";
import { ZoekMeldingenState } from "../store/zoek-meldingen-state";

const initialState: ZoekMeldingenState = {
  hasApiError: undefined,
  isLoading: false,
  meldingen: [],
  totalItemCount: 0,
  filter: new MeldingFilters(null, null, null, null, null, null, null, null, null),
  formuliertypes: [],
  pageNumber: 1,
  pageSize: 30,
  sortOption: { columnName: "meldingnummer", order: Order.descending }
};

const zoekMeldingenReducer = (
  state: ZoekMeldingenState = initialState,
  action: ZoekMeldingenActions
): ZoekMeldingenState => {
  switch (action.type) {
    case SET_ZOEK_MELDINGEN_FAILED:
      return {
        ...state,
        isLoading: false,
        hasApiError: true
      };
    case SET_ZOEK_MELDINGEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasApiError: false
      };
    case SET_ZOEK_MELDINGEN_FILTER:
      return {
        ...state,
        filter: action.filter ?? state.filter,
        sortOption: action.sortOption ?? state.sortOption,
        pageNumber: action.pageNumber ?? state.pageNumber
      };
    case SET_ZOEK_MELDINGEN_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case SET_ZOEK_MELDINGEN_RESULT:
      return {
        ...state,
        meldingen: action.meldingen,
        totalItemCount: action.totalItemCount
      };
    case RESET_ZOEK_MELDINGEN:
      return {
        ...initialState,
        filter: new MeldingFilters(Formuliercategorie.Kennisgeving, null, null, null, null, null, null, null, null)
      };
    default:
      return state;
  }
};

export default zoekMeldingenReducer;
