import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { ContactpersoonVragenDisplayDefinition } from "../display-definition";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class ContactpersoonVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public voorletters: Vraag,
    public tussenvoegsels: Vraag,
    public achternaam: Vraag,
    public geslacht: Meerkeuzevraag,
    public telefoonnummer: Vraag,
    public emailadres: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any, displayDefinition?: ContactpersoonVragenDisplayDefinition): ContactpersoonVragen {
    return new ContactpersoonVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.voorletters`, json.voorletters),
      jsonHelpers.toTekstVraag(`${key}.tussenvoegsels`, json.tussenvoegsels),
      jsonHelpers.toTekstVraag(`${key}.achternaam`, json.achternaam, displayDefinition?.achternaam),
      jsonHelpers.toMeerkeuzeVraag(`${key}.geslacht`, json.geslacht),
      jsonHelpers.toTekstVraag(`${key}.telefoonnummer`, json.telefoonnummer, displayDefinition?.telefoonnummer),
      jsonHelpers.toTekstVraag(`${key}.emailadres`, json.emailadres, displayDefinition?.emailadres)
    );
  }
}
