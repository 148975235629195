import {
  AntwoordBlokken,
  LocatieMetToelichtingAntwoorden,
  MelderAntwoorden,
  OntheffingLiftenAanvraagAntwoorden,
  OpdrachtgeverOntheffingLiftenAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasAanvragerAntwoorden } from "./has-aanvrager-antwoorden";
import { HasEigenaarAntwoorden } from "./has-eigenaar-antwoorden";
import { HasLocatieMetToelichtingAntwoorden } from "./has-locatie-met-toelichting-antwoorden";
import { HasOntheffingLiftenAanvraagAntwoorden } from "./has-ontheffing-liften-aanvraag-antwoorden";

export class OntheffingLiftenMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasAanvragerAntwoorden,
    HasEigenaarAntwoorden,
    HasLocatieMetToelichtingAntwoorden,
    HasOntheffingLiftenAanvraagAntwoorden
{
  constructor(
    public aanvrager: MelderAntwoorden,
    public eigenaar: OpdrachtgeverOntheffingLiftenAntwoorden,
    public locatie: LocatieMetToelichtingAntwoorden,
    public aanvraag: OntheffingLiftenAanvraagAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): OntheffingLiftenMeldingAntwoordBlokken {
    return new OntheffingLiftenMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      OpdrachtgeverOntheffingLiftenAntwoorden.initialize(),
      LocatieMetToelichtingAntwoorden.initialize(),
      OntheffingLiftenAanvraagAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OntheffingLiftenMeldingAntwoordBlokken {
    return new OntheffingLiftenMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.aanvrager),
      OpdrachtgeverOntheffingLiftenAntwoorden.fromJson(json.eigenaar),
      LocatieMetToelichtingAntwoorden.fromJson(json.locatie),
      OntheffingLiftenAanvraagAntwoorden.fromJson(json.aanvraag)
    );
  }
}
