import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class WerkbakWerkzaamhedenAntwoorden extends AntwoordBlok {
  constructor(
    public aantal: Antwoord<number | null>,
    public producttype: Antwoord<string[]>,
    public asbestsanering: Antwoord<string>,
    public omschrijving: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): WerkbakWerkzaamhedenAntwoorden {
    return new WerkbakWerkzaamhedenAntwoorden(
      new Antwoord<number | null>(null),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): WerkbakWerkzaamhedenAntwoorden {
    return new WerkbakWerkzaamhedenAntwoorden(
      Antwoord.fromJson<number | null>(json.aantal),
      Antwoord.fromJson<string[]>(json.producttype),
      Antwoord.fromJson<string>(json.asbestsanering),
      Antwoord.fromJson<string>(json.omschrijving),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson)
    );
  }
}
