import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../../constants";
import { FileOverwriteDialogProps } from "./file-overwrite-dialog.component.interfaces";

export const FileOverwriteDialog = (props: FileOverwriteDialogProps) => {
  const { t } = useTranslation();

  const { onOverwrite, onSkip } = props;

  const overwriteFile = async () => {
    onOverwrite(props.conflictingFile);
  };

  const skipFile = async () => {
    onSkip(props.conflictingFile);
  };

  return (
    <Modal show={props.showDialog} aria-labelledby="file-overwrite-dialog-title" centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title className="dialog-title" id="file-overwrite-dialog-title">
          {t(translationKeys.dialoog.bestandOverschrijven.titel)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {t(translationKeys.dialoog.bestandOverschrijven.tekst, {
            bestandsnaam: props.conflictingFile?.name ?? ""
          })}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={skipFile}>
          {t(translationKeys.dialoog.bestandOverschrijven.overslaan)}
        </Button>
        <Button variant="primary" onClick={overwriteFile}>
          {t(translationKeys.dialoog.bestandOverschrijven.overschrijven)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
