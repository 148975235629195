import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class RedenAanvraagAntwoorden extends AntwoordBlok {
  constructor(
    public aanleidingOntheffingsAanvraag: Antwoord<string>,
    public omschrijvingWerkzaamheden: Antwoord<string>,
    public omschrijvingPlaatsWerkzaamheden: Antwoord<string>
  ) {
    super();
  }

  static initialize(): RedenAanvraagAntwoorden {
    return new RedenAanvraagAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): RedenAanvraagAntwoorden {
    return new RedenAanvraagAntwoorden(
      Antwoord.fromJson<string>(json.aanleidingOntheffingsAanvraag),
      Antwoord.fromJson<string>(json.omschrijvingWerkzaamheden),
      Antwoord.fromJson<string>(json.omschrijvingPlaatsWerkzaamheden)
    );
  }
}
