import { dateHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { Expression } from "./expression";

export class IsBetween extends Expression {
  get operator(): string {
    return IsBetween.operator();
  }

  private readonly DEFAULT_TIME_ANTWOORD_WAARDE = "00:00";

  constructor(
    private readonly vraagDatumProperty: string,
    private readonly vraagTijdProperty: string,
    private readonly afterVraagDatumProperty: string,
    private readonly afterVraagTijdProperty: string,
    private readonly beforeVraagDatumProperty: string,
    private readonly beforeVraagTijdProperty: string
  ) {
    super();
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    let result = false;

    const datum = context.getAntwoord(this.vraagDatumProperty)?.waarde;
    const tijd = this.getTijdAntwoord(context, this.vraagTijdProperty);
    const afterDatum = context.getAntwoord(this.afterVraagDatumProperty)?.waarde;
    const afterTijd = this.getTijdAntwoord(context, this.afterVraagTijdProperty);
    const beforeDatum = context.getAntwoord(this.beforeVraagDatumProperty)?.waarde;
    const beforeTijd = this.getTijdAntwoord(context, this.beforeVraagTijdProperty);

    if (
      dateHelpers.isValidDateTime(`${datum} ${tijd}`) &&
      dateHelpers.isValidDateTime(`${afterDatum} ${afterTijd}`) &&
      dateHelpers.isValidDateTime(`${beforeDatum} ${beforeTijd}`)
    ) {
      const datumEnTijd = dateHelpers.getMomentFromDateTimeString(`${datum} ${tijd}`);
      const afterDatumEnTijd = dateHelpers.getMomentFromDateTimeString(`${afterDatum} ${afterTijd}`);
      const beforeDatumEnTijd = dateHelpers.getMomentFromDateTimeString(`${beforeDatum} ${beforeTijd}`);

      result = datumEnTijd >= beforeDatumEnTijd && datumEnTijd <= afterDatumEnTijd;
    }

    return result;
  };

  getTijdAntwoord = (context: ExpressionContext, tijdvraagProperty: string): string => {
    return tijdvraagProperty ? context.getAntwoord(tijdvraagProperty)?.waarde : this.DEFAULT_TIME_ANTWOORD_WAARDE;
  };

  static fromJson = (json: any): IsBetween =>
    new IsBetween(
      json.vraagDatum,
      json.vraagTijd,
      json.afterVraagDatum,
      json.afterVraagTijd,
      json.beforeVraagDatum,
      json.beforeVraagTijd
    );

  static operator = (): string => "isBetween";

  static matches = (json: any): boolean => json?.operator === IsBetween.operator();
}
