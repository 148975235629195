import { Form } from "react-bootstrap";
import { Configuration } from "../../../configuration";

export const ReadOnly = (props: any) => {
  return (
    <Form.Group className="form-group">
      <div className={`label-container ${props.label.length > Configuration.maxLabelLength ? " full-width" : ""}`}>
        <Form.Label htmlFor={props.name}>{props.label}</Form.Label>
      </div>
      <div className="input-container" id={props.name}>
        {props.value}
      </div>
    </Form.Group>
  );
};
