import { VraagBlokken, MelderVragen, KennisgevingVragen, LocatieWerkzaamhedenBijBedrijfVragen } from "../blok";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasKennisgevingVragen } from "./has-kennisgeving-vragen";
import { HasLocatieWerkzaamhedenBijBedrijfVragen } from "./has-locatie-werkzaamheden-bij-bedrijf-vragen";

export class KennisgevingFormulierVraagBlokken
  extends VraagBlokken
  implements HasMelderVragen, HasLocatieWerkzaamhedenBijBedrijfVragen, HasKennisgevingVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public locatie: LocatieWerkzaamhedenBijBedrijfVragen,
    public kennisgeving: KennisgevingVragen
  ) {
    super();
  }

  static fromJson(json: any): KennisgevingFormulierVraagBlokken {
    return new KennisgevingFormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      LocatieWerkzaamhedenBijBedrijfVragen.fromJson("locatie", json.locatie),
      KennisgevingVragen.fromJson("kennisgeving", json.kennisgeving)
    );
  }
}
