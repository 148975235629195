import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { WerkmomentGegevensAntwoorden } from "./werkmoment-gegevens-antwoorden";

export class WerkmomentenAntwoorden extends AntwoordBlok {
  constructor(
    public werkmomentenGegevensLijst: AntwoordBlokCollection<WerkmomentGegevensAntwoorden> | null,
    public verklaringNoodzaakLaatWerken: Antwoord<string>
  ) {
    super();
  }

  static initialize(): WerkmomentenAntwoorden {
    return new WerkmomentenAntwoorden(AntwoordBlokCollection.initialize([]), new Antwoord<string>(""));
  }

  static fromJson(json: any): WerkmomentenAntwoorden {
    return new WerkmomentenAntwoorden(
      AntwoordBlokCollection.fromJson(WerkmomentGegevensAntwoorden.fromJson, json.werkmomentenGegevensLijst),
      Antwoord.fromJson<string>(json.verklaringNoodzaakLaatWerken)
    );
  }
}
