import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";
import { LocatieAntwoorden } from "./locatie-antwoorden";

export class BouwwerkAntwoorden extends AntwoordBlok {
  constructor(
    public aardBouwwerk: Antwoord<string>,
    public typeBouwwerk: Antwoord<string[]>,
    public omgevingsvergunning: Antwoord<string>,
    public locatie: LocatieAntwoorden | null,
    public breedtegraad: Antwoord<string>,
    public lengtegraad: Antwoord<string>,
    public bouwkundigeTechnischeOrganisatorischeKeuzen: Antwoord<string>,
    public planningBestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): BouwwerkAntwoorden {
    return new BouwwerkAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      LocatieAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): BouwwerkAntwoorden {
    return new BouwwerkAntwoorden(
      Antwoord.fromJson<string>(json.aardBouwwerk),
      Antwoord.fromJson<string[]>(json.typeBouwwerk),
      Antwoord.fromJson<string>(json.omgevingsvergunning),
      LocatieAntwoorden.fromJson(json.locatie),
      Antwoord.fromJson<string>(json.breedtegraad),
      Antwoord.fromJson<string>(json.lengtegraad),
      Antwoord.fromJson<string>(json.bouwkundigeTechnischeOrganisatorischeKeuzen),
      Antwoord.fromJsonArray<Bestand>(json.planningBestanden, Bestand.fromJson)
    );
  }
}
