import { ReactNode, useMemo } from "react";
import { SamenvattingContext } from "./samenvatting-context";

const SamenvattingContainer = (props: { idPrefix: string; children: ReactNode }) => {
  const { idPrefix, children } = props;

  const value = useMemo(() => {
    return {
      idPrefix: idPrefix
    };
  }, [idPrefix]);

  return <SamenvattingContext.Provider value={value}>{children}</SamenvattingContext.Provider>;
};

export { SamenvattingContainer };
