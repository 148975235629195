import {
  AanleveringLavsV2Antwoorden,
  AntwoordBlokken,
  AsbestWerkzaamhedenLavsV2Antwoorden,
  CertificerendeInstellingAntwoorden,
  InventarisatieLavsV2Antwoorden,
  LaboratoriumAntwoorden,
  LocatieLavsAntwoorden,
  MelderLavsAntwoorden,
  OpdrachtgeverLavsAntwoorden,
  OpdrachtnemerLavsAntwoorden,
  PeriodeDatumTijdAntwoorden
} from "../blok";
import { User } from "../security/user";

export class AsbestverwijderingLavsV2MeldingAntwoordBlokken extends AntwoordBlokken {
  constructor(
    public aanlevering: AanleveringLavsV2Antwoorden,
    public melder: MelderLavsAntwoorden,
    public certificerendeInstelling: CertificerendeInstellingAntwoorden,
    public opdrachtgever: OpdrachtgeverLavsAntwoorden,
    public opdrachtnemer: OpdrachtnemerLavsAntwoorden,
    public inventarisatie: InventarisatieLavsV2Antwoorden,
    public locatie: LocatieLavsAntwoorden,
    public periode: PeriodeDatumTijdAntwoorden,
    public werkzaamheden: AsbestWerkzaamhedenLavsV2Antwoorden,
    public laboratorium: LaboratoriumAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): AsbestverwijderingLavsV2MeldingAntwoordBlokken {
    return new AsbestverwijderingLavsV2MeldingAntwoordBlokken(
      AanleveringLavsV2Antwoorden.initialize(),
      MelderLavsAntwoorden.initialize(user),
      CertificerendeInstellingAntwoorden.initialize(),
      OpdrachtgeverLavsAntwoorden.initialize(),
      OpdrachtnemerLavsAntwoorden.initialize(),
      InventarisatieLavsV2Antwoorden.initialize(),
      LocatieLavsAntwoorden.initialize(),
      PeriodeDatumTijdAntwoorden.initialize(),
      AsbestWerkzaamhedenLavsV2Antwoorden.initialize(),
      LaboratoriumAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AsbestverwijderingLavsV2MeldingAntwoordBlokken {
    return new AsbestverwijderingLavsV2MeldingAntwoordBlokken(
      AanleveringLavsV2Antwoorden.fromJson(json.aanlevering),
      MelderLavsAntwoorden.fromJson(json.melder),
      CertificerendeInstellingAntwoorden.fromJson(json.certificerendeInstelling),
      OpdrachtgeverLavsAntwoorden.fromJson(json.opdrachtgever),
      OpdrachtnemerLavsAntwoorden.fromJson(json.opdrachtnemer),
      InventarisatieLavsV2Antwoorden.fromJson(json.inventarisatie),
      LocatieLavsAntwoorden.fromJson(json.locatie),
      PeriodeDatumTijdAntwoorden.fromJson(json.periode),
      AsbestWerkzaamhedenLavsV2Antwoorden.fromJson(json.werkzaamheden),
      LaboratoriumAntwoorden.fromJson(json.laboratorium)
    );
  }
}
