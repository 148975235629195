import { VraagBlokken } from "../blok";
import { ReactieOpenbaarmakingReactieVragen } from "../blok/reactie-openbaarmaking-reactie-vragen";
import { HasReactieOpenbaarmakingReactieVragen } from "./has-reactie-openbaarmaking-reactie-vragen";

export class ReactieOpenbaarmakingFormulierVraagBlokken
  extends VraagBlokken
  implements HasReactieOpenbaarmakingReactieVragen
{
  [key: string]: any;

  constructor(public reactie: ReactieOpenbaarmakingReactieVragen) {
    super();
  }

  static fromJson(json: any): ReactieOpenbaarmakingFormulierVraagBlokken {
    return new ReactieOpenbaarmakingFormulierVraagBlokken(
      ReactieOpenbaarmakingReactieVragen.fromJson("reactie", json.reactie)
    );
  }
}
