import { connect } from "react-redux";
import { ProductveiligheidFormulier } from "../../../models/api/formulier/productveiligheid-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getProductveiligheidformulierMelding,
  initProductveiligheidFormulier,
  initProductveiligheidFormulierFromCopy
} from "../../../thunks";
import { ProductveiligheidFormulierComponent } from "./productveiligheid-formulier.component";
import {
  ProductveiligheidFormulierComponentDispatchProps,
  ProductveiligheidFormulierComponentStateProps
} from "./productveiligheid-formulier.component.interfaces";

const mapStateToProps = (state: State): ProductveiligheidFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.productveiligheid
      ? (state.vragen.formulier as ProductveiligheidFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): ProductveiligheidFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initProductveiligheidFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getProductveiligheidformulierMelding(meldingId));
    } else {
      dispatch(initProductveiligheidFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductveiligheidFormulierComponent);
