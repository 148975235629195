import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class BestrijdingsmiddelenAntwoorden extends AntwoordBlok {
  constructor(public aanvullendeInformatie: Antwoord<string>) {
    super();
  }

  static initialize(): BestrijdingsmiddelenAntwoorden {
    return new BestrijdingsmiddelenAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): BestrijdingsmiddelenAntwoorden {
    return json
      ? new BestrijdingsmiddelenAntwoorden(Antwoord.fromJson<string>(json.aanvullendeInformatie))
      : this.initialize();
  }
}
