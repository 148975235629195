import { Moment } from "moment";
import { IsAgeAtExpression } from "./is-age-at-expression";

export class IsMaximumAgeAt extends IsAgeAtExpression {
  get operator(): string {
    return IsMaximumAgeAt.operator();
  }

  protected executeCompare = (date: Moment, atDate: Moment): boolean => date.add(this.age, "year") > atDate;

  protected clone = (instance: IsMaximumAgeAt): IsMaximumAgeAt =>
    new IsMaximumAgeAt(instance.vraagProperty, instance.otherVraagProperty, instance.age);

  static fromJson = (json: any): IsMaximumAgeAt => new IsMaximumAgeAt(json.vraag, json.otherVraagDatumTijd, json.age);

  static operator = (): string => "isMaximumAgeAt";

  static matches = (json: any): boolean => json?.operator === IsMaximumAgeAt.operator();
}
