import {
  VraagBlokken,
  OpdrachtgeverVuurOpTankschipVragen,
  TankschipVragen,
  VerklaringenVragen,
  MelderVragen,
  PeriodeVragen,
  TankschipWerkzaamhedenVragen
} from "../blok";
import { HasAanvragerVragen } from "./has-aanvrager-vragen";
import { HasWerkgeverVragen } from "./has-werkgever-vragen";
import { HasTankschipVragen } from "./has-tankschip-vragen";
import { HasVerklaringenVragen } from "./has-verklaringen-vragen";
import { HasPeriodeVragen } from "./has-periode-vragen";
import { HasTankschipWerkzaamhedenVragen } from "./has-tankschipwerkzaamheden-vragen";

export class VuurOpTankschipFormulierVraagBlokken
  extends VraagBlokken
  implements
    HasAanvragerVragen,
    HasWerkgeverVragen,
    HasTankschipVragen,
    HasPeriodeVragen,
    HasTankschipWerkzaamhedenVragen,
    HasVerklaringenVragen
{
  [key: string]: any;

  constructor(
    public aanvrager: MelderVragen,
    public werkgever: OpdrachtgeverVuurOpTankschipVragen,
    public tankschip: TankschipVragen,
    public periode: PeriodeVragen,
    public werkzaamheden: TankschipWerkzaamhedenVragen,
    public verklaringen: VerklaringenVragen
  ) {
    super();
  }

  static fromJson(json: any): VuurOpTankschipFormulierVraagBlokken {
    return new VuurOpTankschipFormulierVraagBlokken(
      MelderVragen.fromJson("aanvrager", json.aanvrager),
      OpdrachtgeverVuurOpTankschipVragen.fromJson("werkgever", json.werkgever),
      TankschipVragen.fromJson("tankschip", json.tankschip),
      PeriodeVragen.fromJson("periode", json.periode),
      TankschipWerkzaamhedenVragen.fromJson("werkzaamheden", json.werkzaamheden),
      VerklaringenVragen.fromJson("verklaringen", json.verklaringen)
    );
  }
}
