import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensVragen } from "./bedrijfsgegevens-vragen";
import { BedrijfslocatiegegevensVragen } from "./bedrijfslocatiegegevens-vragen";
import { LocatieVragen } from "./locatie-vragen";

export class KlachtenBedrijfsgegevensWerklocatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public gegevensBedrijfMeldingParagraaf: Tekst,
    public bekendWelkBedrijf: Meerkeuzevraag,
    public bedrijfslocatiegegevens: BedrijfslocatiegegevensVragen,
    public werklocatieTitel: Tekst,
    public werklocatieZelfdeLocatieAlsWerkgever: Meerkeuzevraag,
    public werklocatieKvkNummerGebruiken: Meerkeuzevraag,
    public werklocatieBedrijfsgegevens: BedrijfsgegevensVragen,
    public werklocatie: LocatieVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): KlachtenBedrijfsgegevensWerklocatieVragen {
    return new KlachtenBedrijfsgegevensWerklocatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(`${key}.gegevensBedrijfMeldingParagraaf`, json.gegevensBedrijfMeldingParagraaf),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bekendWelkBedrijf`, json.bekendWelkBedrijf),
      BedrijfslocatiegegevensVragen.fromJson(`${key}.bedrijfslocatiegegevens`, json.bedrijfslocatiegegevens),
      jsonHelpers.toTitel(`${key}.werklocatieTitel`, json.werklocatieTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.werklocatieZelfdeLocatieAlsWerkgever`, json.werklocatieZelfdeLocatieAlsWerkgever),
      jsonHelpers.toMeerkeuzeVraag(`${key}.werklocatieKvkNummerGebruiken`, json.werklocatieKvkNummerGebruiken),
      BedrijfsgegevensVragen.fromJson(`${key}.werklocatieBedrijfsgegevens`, json.werklocatieBedrijfsgegevens),
      LocatieVragen.fromJson(`${key}.werklocatie`, json.werklocatie)
    );
  }
}
