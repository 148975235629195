import { AntwoordBlok } from "./antwoord-blok";
import { MeldingsplichtAntwoorden } from "./meldingsplicht-antwoorden";
import { ToedrachtAntwoorden } from "./toedracht-antwoorden";

export class MeldingsplichtToedrachtAntwoorden extends AntwoordBlok {
  constructor(public meldingsplicht: MeldingsplichtAntwoorden, public toedracht: ToedrachtAntwoorden) {
    super();
  }

  static initialize(): MeldingsplichtToedrachtAntwoorden {
    return new MeldingsplichtToedrachtAntwoorden(MeldingsplichtAntwoorden.initialize(), ToedrachtAntwoorden.initialize());
  }

  static fromJson(json: any): MeldingsplichtToedrachtAntwoorden {
    return new MeldingsplichtToedrachtAntwoorden(
      MeldingsplichtAntwoorden.fromJson(json.meldingsplicht),
      ToedrachtAntwoorden.fromJson(json.toedracht)
    );
  }
}
