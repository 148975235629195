import { Formuliertype } from "../../application";
import { BiologischeAgentiaFormulierVraagBlokken } from "./biologische-agentia-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class BiologischeAgentiaFormulier extends FormulierBase {
  constructor(public blokken: BiologischeAgentiaFormulierVraagBlokken, json: any) {
    super(Formuliertype.biologischeAgentia, blokken, json);
  }

  static fromJson(json: any): BiologischeAgentiaFormulier {
    return new BiologischeAgentiaFormulier(BiologischeAgentiaFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
