import { ProcesstapType } from "../../models/application";

export const COMMIT_BLOK = "COMMIT_BLOK";

interface CommitBlokAction {
  type: typeof COMMIT_BLOK;
  data: ProcesstapType;
}

export type BlokActions = CommitBlokAction;

export const commitBlok = (processtapType: ProcesstapType): CommitBlokAction => {
  return {
    type: COMMIT_BLOK,
    data: processtapType
  };
};
