import { HasBijlageAntwoorden, HasInformatieverzoekAntwoorden, HasVerzoekMelderAntwoorden } from "..";
import { AntwoordBlokken, BijlageAntwoorden, InformatieverzoekAntwoorden, VerzoekMelderAntwoorden } from "../blok";
import { User } from "../security/user";

export class InformatieverzoekMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasInformatieverzoekAntwoorden, HasVerzoekMelderAntwoorden, HasBijlageAntwoorden
{
  constructor(
    public verzoek: InformatieverzoekAntwoorden,
    public melder: VerzoekMelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): InformatieverzoekMeldingAntwoordBlokken {
    return new InformatieverzoekMeldingAntwoordBlokken(
      InformatieverzoekAntwoorden.initialize(),
      VerzoekMelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): InformatieverzoekMeldingAntwoordBlokken {
    return new InformatieverzoekMeldingAntwoordBlokken(
      InformatieverzoekAntwoorden.fromJson(json.verzoek),
      VerzoekMelderAntwoorden.fromJson(json.melder),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
