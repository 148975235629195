import { AntwoordBlokken, BijlageAntwoorden, MelderAntwoorden, OntheffingVuurOpTankschipAanvraagAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasAanvragerAntwoorden } from "./has-aanvrager-antwoorden";
import { HasBijlageAntwoorden } from "./has-bijlage-antwoorden";
import { HasOntheffingVuurOpTankschipAanvraagAntwoorden } from "./has-ontheffing-vuur-op-tankschip-aanvraag-antwoorden";

export class OntheffingVuurOpTankschipMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasAanvragerAntwoorden, HasBijlageAntwoorden, HasOntheffingVuurOpTankschipAanvraagAntwoorden
{
  constructor(
    public aanvraag: OntheffingVuurOpTankschipAanvraagAntwoorden,
    public aanvrager: MelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): OntheffingVuurOpTankschipMeldingAntwoordBlokken {
    return new OntheffingVuurOpTankschipMeldingAntwoordBlokken(
      OntheffingVuurOpTankschipAanvraagAntwoorden.initialize(),
      MelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OntheffingVuurOpTankschipMeldingAntwoordBlokken {
    return new OntheffingVuurOpTankschipMeldingAntwoordBlokken(
      OntheffingVuurOpTankschipAanvraagAntwoorden.fromJson(json.aanvraag),
      MelderAntwoorden.fromJson(json.aanvrager),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
