import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class AutorisatieGeldigheidAntwoorden extends AntwoordBlok {
  constructor(public geldig: Antwoord<string>, public redenOngeldig: Antwoord<string>) {
    super();
  }

  static initialize(): AutorisatieGeldigheidAntwoorden {
    return new AutorisatieGeldigheidAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): AutorisatieGeldigheidAntwoorden {
    return new AutorisatieGeldigheidAntwoorden(
      Antwoord.fromJson<string>(json.geldig),
      Antwoord.fromJson<string>(json.redenOngeldig)
    );
  }
}
