import { useField } from "formik";
import { AntwoordBlok } from "models/api/blok";
import { Fragment, useEffect, useState } from "react";
import { formatColumnValue } from "../../../collection-handler";
import { CollectionColumnAnswerProps } from "./collection-column-answer.component.interfaces";

export const CollectionColumnAnswer = (props: CollectionColumnAnswerProps) => {
  const [currentValue, setCurrentValue] = useState<string>("");
  const { columnName, columnFormatter, vraagAntwoordIndexBlok } = props;

  const [field] = useField<AntwoordBlok>(vraagAntwoordIndexBlok.vraagBlok.key);

  useEffect(() => {
    setCurrentValue(formatColumnValue(field.value, vraagAntwoordIndexBlok.vraagBlok, columnName, columnFormatter));
  }, [vraagAntwoordIndexBlok, columnName, columnFormatter, field]);

  return <Fragment>{currentValue}</Fragment>;
};
