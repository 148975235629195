import { FormikContextType } from "formik";
import i18n from "i18next";
import _ from "lodash";
import { User } from "models/api/security/user";
import { CaptchaAttributes } from "../constants";
import { translationKeys } from "../constants/translation-keys";
import {
  AntwoordBlok,
  AntwoordBlokCollection,
  AntwoordBlokken,
  expressionParser,
  IAntwoord,
  MeldingContext,
  ValidationFailure,
  ValidationResponse,
  Vraag,
  VraagBlok,
  VraagBlokCollection,
  VraagBlokCollectionVerplichtType,
  VraagBlokken
} from "../models/api";
import { Autorisatiegegevens } from "../models/api/security/autorisatiegegevens";
import { Dictionary, ProcesstapType } from "../models/application";
import { ExpressionContext } from "./../models/api/blok/expression-context";
import { formulierHelpers } from "./formulier-helpers";
import { meldingHelpers } from "./melding-helpers";
import { objectHelpers } from "./object-helpers";

const validateGenericClientSide = (
  vraagBlokken: VraagBlokken,
  antwoordBlokken: AntwoordBlokken,
  user: User | null,
  autorisatiegegevens: Autorisatiegegevens,
  meldingContext: MeldingContext,
  processtap: ProcesstapType,
  ...properties: string[]
): ValidationResponse =>
  validateVraagBlokGenericClientSide(
    vraagBlokken[processtap],
    new ExpressionContext(
      user,
      autorisatiegegevens,
      meldingContext,
      antwoordBlokken[processtap],
      antwoordBlokken,
      vraagBlokken
    ),
    ...properties
  );

const validateVraagBlokGenericClientSide = (
  vraagBlok: VraagBlok,
  context: ExpressionContext,
  ...properties: string[]
): ValidationResponse => {
  let validationFailures = Array<ValidationFailure>();

  const vragen = formulierHelpers.getVragen(vraagBlok).sort((a, b) => a.volgnummer - b.volgnummer);

  for (const vraag of vragen) {
    validationFailures = validateVraagIfGesteld(vraag, vraagBlok, context, validationFailures, properties);
  }

  for (const subVraagBlok of formulierHelpers.getBlokken(vraagBlok)) {
    validateVraagBlok(subVraagBlok, vraagBlok, context, properties, validationFailures);
  }

  return new ValidationResponse(validationFailures);
};

const createValidateVraag = (
  vraag: Vraag,
  vraagBlok: VraagBlok,
  context: ExpressionContext,
  shouldValidate: boolean,
  submitValidationResults?: ValidationResponse | null
): ((value: any) => string | null) => {
  return (value: any) => {
    return shouldValidate ? validateVraag(vraag, value, vraagBlok, context, submitValidationResults ?? undefined) : null;
  };
};

const validateVraagIfGesteld = (
  vraag: Vraag,
  vraagBlok: VraagBlok,
  context: ExpressionContext,
  validationFailures: ValidationFailure[],
  properties: string[]
) => {
  const wordtVraagGesteld =
    !vraag.conditioneelGesteld || expressionParser.fromJsonConditioneelGesteld(vraag)?.execute(vraagBlok, context) !== false;

  if (wordtVraagGesteld) {
    const [, , propertyName] = meldingHelpers.getKeyPartsFromItem(vraag);

    const antwoord = objectHelpers.getValue<IAntwoord>(context.currentAntwoordBlok, propertyName);

    const validationResult = validateVraag(vraag, antwoord?.waarde, vraagBlok, context);

    if (validationResult) {
      validationFailures.push(new ValidationFailure(vraag.key, validationResult));
    }
  }

  if (properties.length > 0) {
    validationFailures = validationFailures.filter((f) => properties.some((p) => p === f.property));
  }
  return validationFailures;
};

const validateVraag = (
  vraag: Vraag,
  antwoordValue: any,
  vraagBlok: VraagBlok,
  context: ExpressionContext,
  submitValidationResults?: ValidationResponse
): string | null => {
  const [, , property] = meldingHelpers.getKeyPartsFromItem(vraag);

  const currentAntwoord = objectHelpers.getValue<{}>(context.currentAntwoordBlok, property);

  const newValue = {
    ...currentAntwoord,
    waarde: antwoordValue
  };

  objectHelpers.setValue(context.currentAntwoordBlok, newValue, property);

  let validationResult = validateVerplicht(vraag, antwoordValue);

  if (!validationResult) {
    validationResult = validateConditioneelVerplicht(vraag, antwoordValue, vraagBlok, context);
  }

  if (!validationResult) {
    validationResult = validateFormat(vraag, antwoordValue);
  }

  if (!validationResult) {
    validationResult = validateCustom(vraag, vraagBlok, context);
  }

  if (!validationResult && submitValidationResults) {
    validationResult =
      submitValidationResults.validationFailures.find((f) => f.property?.toLowerCase() === vraag.key?.toLowerCase())
        ?.message ?? null;
  }

  return validationResult;
};

const validateVraagBlok = (
  subVraagBlok: VraagBlok,
  vraagBlok: VraagBlok,
  context: ExpressionContext,
  properties: string[],
  validationFailures: ValidationFailure[]
) => {
  const wordtSubVraagBlokGesteld =
    !subVraagBlok.conditioneelGesteld ||
    expressionParser.fromJsonConditioneelGesteld(subVraagBlok)?.execute(vraagBlok, context) !== false;

  if (wordtSubVraagBlokGesteld) {
    const [, , propertyName, index] = meldingHelpers.getKeyPartsFromKey(subVraagBlok.key);

    const subAntwoordBlok = context.currentAntwoordBlok?.[propertyName] as AntwoordBlok;

    if (formulierHelpers.isVraagBlokCollection(subVraagBlok)) {
      validateVraagBlokCollection(
        subVraagBlok as VraagBlokCollection<VraagBlok>,
        subAntwoordBlok as AntwoordBlokCollection<AntwoordBlok>,
        validationFailures
      );
    }

    const validationResult = validateVraagBlokGenericClientSide(
      subVraagBlok,
      new ExpressionContext(
        context.currentUser,
        context.autorisatiegegevens,
        context.meldingContext,
        index !== undefined ? (subAntwoordBlok[index] as AntwoordBlok) : subAntwoordBlok,
        context.antwoordBlokken,
        context.vraagBlokken
      ),
      ...properties
    );

    validationFailures.push(...validationResult.validationFailures);
  }
};

const validateVraagBlokCollection = (
  vraagBlokCollection: VraagBlokCollection<VraagBlok>,
  antwoordBlokCollection: AntwoordBlokCollection<AntwoordBlok> | null,
  validationFailures: ValidationFailure[]
) => {
  const verplichtValidationResult = validateVerplichtVraagBlokCollection(vraagBlokCollection, antwoordBlokCollection);

  if (verplichtValidationResult) {
    validationFailures.push(new ValidationFailure(vraagBlokCollection.key, verplichtValidationResult));
  }
};

const validateVerplichtVraagBlokCollection = (
  vraagBlokCollection: VraagBlokCollection<VraagBlok>,
  antwoordBlokCollection: AntwoordBlokCollection<AntwoordBlok> | null
): string | null => {
  let result = null;

  if (
    vraagBlokCollection.verplichtType !== VraagBlokCollectionVerplichtType.NietVerplicht &&
    antwoordBlokCollection?.list?.length === 0
  ) {
    result = vraagBlokCollection.isVerplichtValidationMessage
      ? vraagBlokCollection.isVerplichtValidationMessage[i18n.language]
      : null;

    if (!result) {
      result = i18n.t(translationKeys.validatie.nogNietIngevuld, {
        vraag: vraagBlokCollection.titel[i18n.language]
      });
    }
  }

  return result;
};

const validateVerplicht = (vraag: Vraag, antwoordValue: any): string | null => {
  let result = null;

  if (vraag.isVerplicht && (antwoordValue == null || antwoordValue.length === 0)) {
    result = vraag.isVerplichtValidationMessage ? vraag.isVerplichtValidationMessage[i18n.language] : null;

    if (!result) {
      result = i18n.t(
        vraag.isMeervoud ? translationKeys.validatie.nogNietIngevuld_plural : translationKeys.validatie.nogNietIngevuld,
        {
          vraag: vraag.tekstMetLidwoord ? vraag.tekstMetLidwoord[i18n.language] : vraag.tekst[i18n.language]
        }
      );
    }
  }

  return result;
};

const validateConditioneelVerplicht = (
  vraag: Vraag,
  antwoordValue: any,
  vraagBlok: VraagBlok,
  context: ExpressionContext
): string | null => {
  let result = null;

  if (antwoordValue == null || antwoordValue.length === 0) {
    const isConditioneelVerplicht = expressionParser.fromJson(vraag.conditioneelVerplicht)?.execute(vraagBlok, context);

    if (isConditioneelVerplicht) {
      result =
        vraag.conditioneelVerplichtValidationMessage?.[i18n.language] ??
        i18n.t(
          vraag.isMeervoud ? translationKeys.validatie.nogNietIngevuld_plural : translationKeys.validatie.nogNietIngevuld,
          { vraag: vraag.tekstMetLidwoord?.[i18n.language] }
        );
    }
  }

  return result;
};

const validateFormat = (vraag: Vraag, antwoordValue: any): string | null => {
  let result = null;

  if (vraag.format && antwoordValue) {
    const rexExp = new RegExp(vraag.format);

    const isMatch = rexExp.test(antwoordValue);

    if (!isMatch && vraag.formatValidationMessage) {
      result = vraag.formatValidationMessage[i18n.language];
    }
  }

  return result;
};

const validateCustom = (vraag: Vraag, vraagBlok: VraagBlok, context: ExpressionContext): string | null => {
  let result: string | null = null;

  const [, , property] = meldingHelpers.getKeyPartsFromItem(vraag);

  const applicableCustomValidation = vraag.customValidations?.find((cv) =>
    expressionParser.fromJson(cv.validationExpression)?.execute(vraagBlok, context, property)
  );

  if (applicableCustomValidation) {
    result = applicableCustomValidation.validationMessage[i18n.language];
  }

  return result;
};

const convertToErrorObject = (response: ValidationResponse, keepValidationFailureProperties = false): any => {
  const errors: any = {};

  const fieldValidaties: Dictionary<any> = {};

  if (response && response.validationFailures) {
    for (const f of response.validationFailures) {
      const fieldName = meldingHelpers.getFieldNameFromVraagKey(f.property);

      const message =
        fieldName === CaptchaAttributes.Name ? i18n.t(translationKeys.captcha.validatie.aangevinkt) : f.message;

      fieldValidaties[fieldName] = {
        message: message,
        relatedProperties: f.relatedProperties,
        allMessages: fieldValidaties[fieldName]
          ? [...fieldValidaties[fieldName]?.allMessages, { message, code: f?.code }]
          : [{ message, code: f?.code }]
      };
    }

    for (const fieldName in fieldValidaties) {
      objectHelpers.setValue(
        errors,
        keepValidationFailureProperties ? fieldValidaties[fieldName] : fieldValidaties[fieldName].message,
        fieldName
      );
    }
  }

  return errors;
};

const hasErrors = (errorObject: any): boolean => {
  return (
    errorObject &&
    Object.keys(errorObject).some((k) => {
      const errorProperty = errorObject[k];

      if (typeof errorProperty === "object" && "waarde" in errorProperty) {
        return !!errorProperty.waarde;
      } else if (typeof errorProperty === "object") {
        return hasErrors(errorProperty);
      }
      return false;
    })
  );
};

const validationFailuresForProperty = (
  validationFailures: ValidationFailure[],
  propertyKey: string
): ValidationFailure[] => {
  return validationFailures.filter((validationFailure) => validationFailure.property.startsWith(propertyKey));
};

const validationFailuresForNestedProperty = (
  validationFailures: ValidationFailure[],
  propertyKey: string
): ValidationFailure[] => {
  return validationFailuresForProperty(validationFailures, `${propertyKey}.`);
};

const validateCaptcha = (token: string): ValidationFailure | null => {
  return !token ? new ValidationFailure(CaptchaAttributes.Id, i18n.t(translationKeys.captcha.validatie.aangevinkt)) : null;
};

const validate = async (formikContext: FormikContextType<AntwoordBlokken>, validationKey: string) => {
  await validateBeforeExecute(formikContext, validationKey, () => {});
};

const validateBeforeExecute = async (
  formikContext: FormikContextType<AntwoordBlokken>,
  validationKey: string,
  action: () => void
): Promise<boolean> => {
  // Validate open item
  const errors = await formikContext.validateForm();

  const vraagBlokErrorsInOpenItem = objectHelpers.getValue(errors, validationKey);

  // If open items not have errors
  if (_.isEmpty(vraagBlokErrorsInOpenItem)) {
    action();
    return true;
  } else {
    const newErrors = {};
    objectHelpers.setValue(newErrors, vraagBlokErrorsInOpenItem, validationKey);
    formikContext.setTouched(newErrors, true);
    return false;
  }
};

export const validationHelpers = {
  validateGenericClientSide,
  validateVraagBlokGenericClientSide,
  validateVraagBlokCollection,
  createValidateVraag,
  convertToErrorObject,
  hasErrors,
  validateCaptcha,
  validationFailuresForProperty,
  validationFailuresForNestedProperty,
  validate,
  validateBeforeExecute
};
