import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class ArbeidsomstandighedenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public arbeidsomstandighedenTitel: Tekst,
    public welkeArbeidsomstandighedenMeldingBetrekking: Meerkeuzevraag,
    public overigeArbeidsomstandigheden: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ArbeidsomstandighedenVragen {
    return new ArbeidsomstandighedenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.arbeidsomstandighedenTitel`, json.arbeidsomstandighedenTitel),
      jsonHelpers.toChecklistvraag(
        `${key}.welkeArbeidsomstandighedenMeldingBetrekking`,
        json.welkeArbeidsomstandighedenMeldingBetrekking
      ),
      jsonHelpers.toTekstVraag(`${key}.overigeArbeidsomstandigheden`, json.overigeArbeidsomstandigheden)
    );
  }
}
