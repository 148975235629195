import { Language } from "../../application";
import { SortOption } from "../sort-option";
import { MeldingFilters } from "./melding-filters";

export class SearchMeldingenRequest {
  constructor(
    public filters: MeldingFilters,
    public taal: Language,
    public sortOption: SortOption,
    public pageNumber: number,
    public pageSize: number
  ) {}
}
