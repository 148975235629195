import React from "react";
import { Meerkeuzevraag, Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldMeerkeuzevraag } from "../../../samenvatting";

export class MeerkeuzeEenAntwoordmogelijkheid {
  static getType = (): VraagType => VraagType.meerkeuzeEenAntwoordmogelijkheid;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldMeerkeuzevraag vraag={vraag as Meerkeuzevraag} />;
}
