import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class ArbeidstijdenAntwoorden extends AntwoordBlok {
  constructor(
    public welkeArbeidstijdenMeldingBetrekking: Antwoord<string[]>,
    public overigeArbeidstijden: Antwoord<string>,
    public welkeTijdstippenUitvoerWerkzaamheden: Antwoord<string>,
    public arbeidstijdregistratieDoorOnderneming: Antwoord<string>,
    public arbeidstijdregistratieDoorWerknemer: Antwoord<string>
  ) {
    super();
  }

  static initialize(): ArbeidstijdenAntwoorden {
    return new ArbeidstijdenAntwoorden(
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): ArbeidstijdenAntwoorden {
    return json
      ? new ArbeidstijdenAntwoorden(
          Antwoord.fromJson<string[]>(json.welkeArbeidstijdenMeldingBetrekking),
          Antwoord.fromJson<string>(json.overigeArbeidstijden),
          Antwoord.fromJson<string>(json.welkeTijdstippenUitvoerWerkzaamheden),
          Antwoord.fromJson<string>(json.arbeidstijdregistratieDoorOnderneming),
          Antwoord.fromJson<string>(json.arbeidstijdregistratieDoorWerknemer)
        )
      : this.initialize();
  }
}
