import { AutorisatieslistColumnName } from ".";
import { SortOption } from "../sort-option";

export class AutorisatiesSortOption implements SortOption {
  constructor(public propertyName: AutorisatieslistColumnName, public sortAscending: boolean) {}

  static fromJson(json: any): AutorisatiesSortOption {
    return new AutorisatiesSortOption(json.propertyName, json.sortAscending);
  }
}
