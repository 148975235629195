import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";

export class TekstMeerdereRegels {
  static getType = (): VraagType => VraagType.tekstMeerdereRegels;

  static create = (vraag: Vraag, readOnly = false): React.JSX.Element => (
    <FormulierInputControls.TextArea vraag={vraag} readOnly={readOnly} />
  );
}
