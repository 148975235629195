import { setCollectionValidationFailures } from "actions";
import { ValidationFailure } from "models/api";
import { ProcesstapState } from "models/application";
import { connect } from "react-redux";
import { DEFAULT_PAGE_NUMBER, EMPTY_COLLECTION_VALIDATION_FAILURES } from "reducers/collections-reducer";
import { State } from "store";
import { CollectionValidator } from "./collection-validator.component";
import {
  CollectionValidatorDispatchProps,
  CollectionValidatorOwnProps,
  CollectionValidatorStateProps
} from "./collection-validator.component.interfaces";

const mapStateToProps = (state: State, props: CollectionValidatorOwnProps): CollectionValidatorStateProps => ({
  user: state.security.user,
  autorisatiegegevens: state.melding?.metadata?.autorisatiegegevens ?? state.autorisatie.autorisatiegegevens,
  meldingStatus: state.melding?.status,
  wordtMeldingGewijzigd: state.melding?.wordtGewijzigd,
  vragen: state.vragen.formulier?.blokken,
  currentPage: state.collections.list[props.collectionKey]?.paging.currentPage || DEFAULT_PAGE_NUMBER,
  processtapType: state.proces.items.find((i) => i.state === ProcesstapState.active)?.type,
  collectionValidationFailures:
    state.collections.list[props.collectionKey]?.validationFailures ?? EMPTY_COLLECTION_VALIDATION_FAILURES
});

const mapDispatchToProps = (dispatch: any, props: CollectionValidatorOwnProps): CollectionValidatorDispatchProps => ({
  setValidationFailures: (validationFailures: ValidationFailure[]) => {
    dispatch(setCollectionValidationFailures(props.collectionKey, validationFailures));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionValidator);
