import { ReactieOpenbaarmakingFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getReactieOpenbaarmakingFormulierDefinitie = (formulier: ReactieOpenbaarmakingFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.reactie.titel,
      type: ProcesstapType.reactie
    }
  ];
};
