import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class VerzoekTotGezamenlijkeInterventieMeldingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public wanneerIsDeControle: Vraag,
    public watIsHetVoorControle: Vraag,
    public watIsHetVoorControleOverig: Vraag,
    public welkeWetgevingIsVanToepassing: Vraag,
    public aanvullendeOpmerkingenMelder: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): VerzoekTotGezamenlijkeInterventieMeldingVragen {
    return new VerzoekTotGezamenlijkeInterventieMeldingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toDatumVraag(`${key}.wanneerIsDeControle`, json.wanneerIsDeControle),
      jsonHelpers.toMeerkeuzeVraag(`${key}.watIsHetVoorControle`, json.watIsHetVoorControle),
      jsonHelpers.toTekstVraag(`${key}.watIsHetVoorControleOverig`, json.watIsHetVoorControleOverig),
      jsonHelpers.toChecklistvraag(`${key}.welkeWetgevingIsVanToepassing`, json.welkeWetgevingIsVanToepassing),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.aanvullendeOpmerkingenMelder`, json.aanvullendeOpmerkingenMelder)
    );
  }
}
