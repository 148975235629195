import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { WerkzaamhedenVragen } from "./werkzaamheden-vragen";

export class TankschipWerkzaamhedenVragen extends WerkzaamhedenVragen {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public aardWerkzaamhedenToelichting: Tekst,
    public aardWerkzaamheden: Vraag,
    public plaatsWerkzaamhedenSchipToelichting: Tekst,
    public plaatsWerkzaamheden: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): TankschipWerkzaamhedenVragen {
    return new TankschipWerkzaamhedenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.aardWerkzaamhedenToelichting`, json.aardWerkzaamhedenToelichting),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.aardWerkzaamheden`, json.aardWerkzaamheden),
      jsonHelpers.toParagraaf(`${key}.plaatsWerkzaamhedenSchipToelichting`, json.plaatsWerkzaamhedenSchipToelichting),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.plaatsWerkzaamheden`, json.plaatsWerkzaamheden)
    );
  }
}
