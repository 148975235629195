const w = window as any;
const DefaultPageSize = 5;
export const Configuration = {
  apiUrl: w.config.api.url,
  useReduxDevTools: w.config.useReduxDevTools,
  useLocalization: w.config.useLocalization,
  loginEnabled: w.config.loginEnabled,
  recaptchaSiteKey: w.config.recaptcha.siteKey,
  breadcrumbUrls: w.config.breadcrumbUrls,
  maxLabelLength: w.config.maxLabelLength,
  userInactiveThresholdTimeInSeconds: w.config.userInactiveThresholdTimeInSeconds,
  refreshCookieWhenExpiringInSeconds: w.config.refreshCookieWhenExpiringInSeconds,
  vestigingSearchPageSize: w.config.vestigingSearchPageSize ?? DefaultPageSize,
  samenvattingBarEnabled: w.config.samenvattingBarEnabled ?? false,
  meldingToevoegenButtons: w.config.meldingToevoegenButtons,
  mijnMeldingenToevoegenButtons: w.config.mijnMeldingenToevoegenButtons,
  showNlArbeidsinspectieBreadcrumbs: w.config.showNlArbeidsinspectieBreadcrumbs,
  nlaVestiging: w.config.nlaVestiging as number | undefined,
  app: {
    version: `${process.env.REACT_APP_MAJOR_VERSION as string}.${process.env.REACT_APP_VERSION as string}`,
    name: "Webportaal"
  }
};
