import { AntwoordBlokken, BedrijfslocatiegegevensAntwoorden, BijlageAntwoorden } from "../blok";
import { VerzoekTotGezamenlijkeInterventieMelderAntwoorden } from "../blok/verzoek-tot-gezamenlijke-interventie-melder-antwoorden";
import { VerzoekTotGezamenlijkeInterventieMeldingAntwoorden } from "../blok/verzoek-tot-gezamenlijke-interventie-melding-antwoorden";
import { User } from "../security/user";

export class VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken extends AntwoordBlokken {
  constructor(
    public melding: VerzoekTotGezamenlijkeInterventieMeldingAntwoorden,
    public locatie: BedrijfslocatiegegevensAntwoorden,
    public melder: VerzoekTotGezamenlijkeInterventieMelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken {
    return new VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken(
      VerzoekTotGezamenlijkeInterventieMeldingAntwoorden.initialize(),
      BedrijfslocatiegegevensAntwoorden.initialize(),
      VerzoekTotGezamenlijkeInterventieMelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken {
    return new VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken(
      VerzoekTotGezamenlijkeInterventieMeldingAntwoorden.fromJson(json.melding),
      BedrijfslocatiegegevensAntwoorden.fromJson(json.locatie),
      VerzoekTotGezamenlijkeInterventieMelderAntwoorden.fromJson(json.melder),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
