import { Expression } from "./expression";

export class False extends Expression {
  get operator(): string {
    return False.operator();
  }

  execute = (): boolean => false;

  static fromJson = (): False => new False();

  static operator = (): string => "false";

  static matches = (json: any): boolean => json?.operator === False.operator();
}
