import { NederlandsAdresVragen } from ".";
import { Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class LocatieLavsVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public perceelcode: Vraag,
    public omschrijving: Vraag,
    public nederlandsAdres: NederlandsAdresVragen,
    public coordinaatX: Vraag,
    public coordinaatY: Vraag,
    public latitude: Vraag,
    public longitude: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): LocatieLavsVragen {
    return new LocatieLavsVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.perceelcode`, json.perceelcode),
      jsonHelpers.toTekstVraag(`${key}.omschrijving`, json.omschrijving),
      NederlandsAdresVragen.fromJson(`${key}.nederlandsAdres`, json.nederlandsAdres),
      jsonHelpers.toTekstVraag(`${key}.coordinaatX`, json.coordinaatX),
      jsonHelpers.toTekstVraag(`${key}.coordinaatY`, json.coordinaatY),
      jsonHelpers.toTekstVraag(`${key}.latitude`, json.latitude),
      jsonHelpers.toTekstVraag(`${key}.longitude`, json.longitude)
    );
  }
}
