import { User } from "models/api/security/user";
import { expressionHelpers, meldingHelpers } from "../../../helpers";
import { AntwoordBlokken, HasConditioneelGesteld, HasKey, MeldingContext, VraagBlokken } from "../../../models/api";
import { ExpressionContext } from "../../../models/api/blok/expression-context";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";

export const wordtGesteld = (
  item: HasConditioneelGesteld & HasKey,
  vragen: VraagBlokken,
  antwoorden: AntwoordBlokken,
  user: User | null,
  autorisatiegegevens: Autorisatiegegevens,
  melding: MeldingContext
) => {
  let wordtItemGesteld = false;

  const vraagBlok = meldingHelpers.getVraagBlok(item, vragen);

  if (vraagBlok) {
    const [bloknaam] = meldingHelpers.getKeyPartsFromKey(item.key);

    const antwoordBlok = antwoorden[bloknaam];

    const context = new ExpressionContext(user, autorisatiegegevens, melding, antwoordBlok, antwoorden, vragen);

    wordtItemGesteld = expressionHelpers.wordtItemGesteld(
      item,
      (item as any).parent ? vraagBlok : vragen[bloknaam],
      context
    );
  }

  return wordtItemGesteld;
};
