import { BlokDefinitie, Processtap, ProcesstapState, ProcesstapType } from "../models/application";
import { enumHelpers } from "./enum-helpers";
import { objectHelpers } from "./object-helpers";

const create = (
  blokken: BlokDefinitie[],
  options: { excludeSamenvatting: boolean } = { excludeSamenvatting: false }
): Processtap[] => {
  const processtappen: Processtap[] = blokken.map((b: BlokDefinitie, index: number) => ({
    title: b.title,
    state: index === 0 ? ProcesstapState.active : ProcesstapState.unvisited,
    isComplete: false,
    type: b.type,
    group: b.group
  }));

  if (!options.excludeSamenvatting && blokken.some((b) => hasSamenvatting(b))) {
    processtappen.push(createSamenvatting(processtappen.length === 0));
  }

  return processtappen;
};

const hasSamenvatting = (blok: BlokDefinitie) => blok.hasNoSamenvatting !== true || blok.samenvattingBlok;

const createSamenvatting = (isActive: boolean): Processtap => ({
  translationKey: "blok.samenvatting.bloknaam",
  state: isActive ? ProcesstapState.active : ProcesstapState.unvisited,
  isComplete: false,
  type: ProcesstapType.samenvatting
});

const getProcesstapTypeFromFieldName = (fieldName: string): ProcesstapType | undefined => {
  if (!fieldName) {
    return undefined;
  }

  const processstapTypePart = objectHelpers.toCamelCase(fieldName.split(".")[0]);
  return enumHelpers.enumFromStringValue(ProcesstapType, processstapTypePart);
};

export const processtapHelpers = {
  create,
  createSamenvatting,
  hasSamenvatting,
  getProcesstapTypeFromFieldName
};
