import { VraagBlok } from "..";
import { ExpressionContext } from "../blok/expression-context";
import { User } from "../security/user";
import { Expression } from "./expression";

export abstract class UserExpression extends Expression {
  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    const hasCurrentUser = context.currentUser != null;
    if (hasCurrentUser) {
      return this.executeUser(context.currentUser as User);
    }
    return false;
  };

  abstract executeUser(user: User): boolean;
}
