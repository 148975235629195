import { WooFormulier } from "models/api/formulier/woo-formulier";
import { connect } from "react-redux";
import { getWooformulierMelding, initWooFormulier, initWooFormulierFromCopy } from "thunks";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { WooFormulierComponent } from "./woo-formulier.component";
import { WooFormulierComponentDispatchProps, WooFormulierComponentStateProps } from "./woo-formulier.component.interfaces";

const mapStateToProps = (state: State): WooFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.woo ? (state.vragen.formulier as WooFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): WooFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initWooFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getWooformulierMelding(meldingId));
    } else {
      dispatch(initWooFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WooFormulierComponent);
