export enum TaalWijzigDialogMode {
  None = 0,
  FormulierTaalNietBeschikbaar = 1,
  FormulierTaalWijzigVerzoek = 2,
  FormulierTaalNietTeWijzigenBestaandeMelding = 3
}

export interface TaalState {
  requestedLanguage: string;
  taalWijzigDialogMode: TaalWijzigDialogMode;
}
