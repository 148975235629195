import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class DuikarbeidWerkzaamhedenAntwoorden extends AntwoordBlok {
  constructor(
    public omschrijving: Antwoord<string>,
    public specifiekMateriaal: Antwoord<string>,
    public toelichtingSpecifiekMateriaal: Antwoord<string>,
    public aantalDuikploegleiders: Antwoord<number | null>,
    public aantalDuikers: Antwoord<number | null>,
    public aantalDuikmedischeBegeleiders: Antwoord<number | null>,
    public redenMeldingDuikarbeid: Antwoord<string[]>,
    public uitlegOverigeRedenMeldingDuikarbeid: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): DuikarbeidWerkzaamhedenAntwoorden {
    return new DuikarbeidWerkzaamhedenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<number | null>(null),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): DuikarbeidWerkzaamhedenAntwoorden {
    return new DuikarbeidWerkzaamhedenAntwoorden(
      Antwoord.fromJson<string>(json.omschrijving),
      Antwoord.fromJson<string>(json.specifiekMateriaal),
      Antwoord.fromJson<string>(json.toelichtingSpecifiekMateriaal),
      Antwoord.fromJson<number | null>(json.aantalDuikploegleiders),
      Antwoord.fromJson<number | null>(json.aantalDuikers),
      Antwoord.fromJson<number | null>(json.aantalDuikmedischeBegeleiders),
      Antwoord.fromJson<string[]>(json.redenMeldingDuikarbeid),
      Antwoord.fromJson<string>(json.uitlegOverigeRedenMeldingDuikarbeid),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson)
    );
  }
}
