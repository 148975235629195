import { useEffect, useMemo } from "react";
import { GeneriekBlok } from "../generiek-vraag-blok";
import { VraagBlokContext } from "./../../vraag-blok-context";
import { GeneriekVraagBlokRootProps } from "./generiek-vraag-blok-root.component.interfaces";

export const GeneriekVraagBlokRoot = (props: GeneriekVraagBlokRootProps) => {
  const { vragen, processtap, isActive, onActivated, wordtVertaald } = props;

  const defaultContext = useMemo(() => {
    return {
      isFirstVraag: true
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      onActivated(processtap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, processtap]); // do not include onActivated here or the effect will trigger too often

  return isActive && vragen ? (
    <VraagBlokContext.Provider value={defaultContext}>
      <GeneriekBlok.VraagBlokRoot vragen={vragen} processtap={processtap} wordtVertaald={wordtVertaald} />
    </VraagBlokContext.Provider>
  ) : null;
};
