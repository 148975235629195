import { Dictionary } from "./../../application/dictionary";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";
import { jsonHelpers } from "../../../helpers";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { UitgeoefendeActiviteitenVragen } from "./uitgeoefende-activiteiten-vragen";

export class GegevensInstallatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public naamBedrijf: Vraag,
    public bedrijfsterrein: Vraag,
    public correspondentieadres: CorrespondentieadresVragen,
    public uitgeoefendeActiviteiten: UitgeoefendeActiviteitenVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): GegevensInstallatieVragen {
    return new GegevensInstallatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.naamBedrijf`, json.naamBedrijf),
      jsonHelpers.toTekstVraag(`${key}.bedrijfsterrein`, json.bedrijfsterrein),
      CorrespondentieadresVragen.fromJson(`${key}.correspondentieadres`, json.correspondentieadres),
      UitgeoefendeActiviteitenVragen.fromJson(`${key}.uitgeoefendeActiviteiten`, json.uitgeoefendeActiviteiten)
    );
  }
}
