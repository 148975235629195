import { AntwoordBlok } from "./antwoord-blok";
import { Antwoord } from "../antwoord";
import { Bestand } from "./bestand";

export class AsbestWerkzaamhedenAntwoorden extends AntwoordBlok {
  constructor(
    public asbestWerkzaamhedenAan: Antwoord<string[]>,
    public bouwwerk: Antwoord<string>,
    public asbestObject: Antwoord<string>,
    public bewoondeLocatie: Antwoord<string>,
    public terugschalen: Antwoord<string>,
    public afwijkendeInventarisatie: Antwoord<string>,
    public beoordeeld: Antwoord<string>,
    public deskundige: Antwoord<string>,
    public certificaatnummerDeskundige: Antwoord<string>,
    public dagelijkseWerktijdAanvang: Antwoord<string>,
    public dagelijkseWerktijdGereed: Antwoord<string>,
    public bestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): AsbestWerkzaamhedenAntwoorden {
    return new AsbestWerkzaamhedenAntwoorden(
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): AsbestWerkzaamhedenAntwoorden {
    return new AsbestWerkzaamhedenAntwoorden(
      Antwoord.fromJson<string[]>(json.asbestWerkzaamhedenAan),
      Antwoord.fromJson<string>(json.bouwwerk),
      Antwoord.fromJson<string>(json.asbestObject),
      Antwoord.fromJson<string>(json.bewoondeLocatie),
      Antwoord.fromJson<string>(json.terugschalen),
      Antwoord.fromJson<string>(json.afwijkendeInventarisatie),
      Antwoord.fromJson<string>(json.beoordeeld),
      Antwoord.fromJson<string>(json.deskundige),
      Antwoord.fromJson<string>(json.certificaatnummerDeskundige),
      Antwoord.fromJson<string>(json.dagelijkseWerktijdAanvang),
      Antwoord.fromJson<string>(json.dagelijkseWerktijdGereed),
      Antwoord.fromJsonArray<Bestand>(json.bestanden, Bestand.fromJson)
    );
  }
}
