import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, ContactMelderAntwoorden, ContactMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initContactFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getContactFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getContactFormulierDefinitie,
      ContactMeldingAntwoordBlokken.initialize
    );
  };

export const initContactFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getContactFormulier(),
      meldingApi.getContactMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): ContactMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        contactMelder: ContactMelderAntwoorden.initialize(user),
        contactVraag: {
          ...melding.blokken.contactVraag,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getContactFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
