import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { PageTitleProps } from "./page-title.component.interfaces";
import { translationKeys } from "../../constants";

const PageTitle: React.FC<PageTitleProps> = (props: PageTitleProps) => {
  const { t } = useTranslation();

  const title = props.breadcrumbs
    ? props.breadcrumbs
        .filter((b) => b.titel)
        .map((b) => b.titel)
        .reverse()
        .join(" | ")
    : t(translationKeys.webportaal);

  return <Helmet title={title} />;
};

export default PageTitle;
