import { useFormikContext } from "formik";
import { validationHelpers } from "helpers";
import { AntwoordBlokken } from "models/api";
import { useAccordionButton } from "react-bootstrap";
import { NO_SELECTED_ITEM } from "reducers/collections-reducer";

export const useGridRowToggle = (
  vraagBlokKey: string,
  selectedCollectionItemKey: string,
  setSelectedCollectionItem: (itemKey: string) => void
) => {
  const formikContext = useFormikContext<AntwoordBlokken>();
  return useAccordionButton(vraagBlokKey, async (e: any) => {
    e.stopPropagation();

    if (selectedCollectionItemKey !== NO_SELECTED_ITEM) {
      // Check if data is valid before the row can be collapsed
      validationHelpers.validateBeforeExecute(formikContext, selectedCollectionItemKey, () => {
        setSelectedCollectionItem(vraagBlokKey);
      });
    } else {
      // Validate the data before the row is expanded so any validation failures will be shown directly
      validationHelpers.validate(formikContext, vraagBlokKey).then(() => {
        setSelectedCollectionItem(vraagBlokKey);
      });
    }
  });
};
