import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Formulier } from "..";
import { translationKeys } from "../../../constants";
import { formulierDefinitieHelpers } from "../../../formulier-definitie/formulier-definitie-helper";
import { formulierHelpers } from "../../../helpers";
import { MeldingValidator } from "../../../helpers/melding-validator";
import { AutorisatieProfielMelding, MeldingBase } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { AutorisatieProfielFormulierComponentProps } from "./autorisatie-profiel-formulier.component.interfaces";

export const AutorisatieProfielFormulierComponent = (props: AutorisatieProfielFormulierComponentProps) => {
  const { ...propsRest } = props;
  const { t } = useTranslation();

  const { profielId } = useParams();

  const formuliertype = Formuliertype.autorisatieProfiel;

  if (
    props.formulier &&
    formulierHelpers.areVragenEnAntwoordenInitialized(formuliertype, props.formulier.type, props.antwoordenFormuliertype)
  ) {
    const blokDefinitie = formulierDefinitieHelpers.getFormulierDefinitie(props.formulier);

    return (
      blokDefinitie && (
        <div className="autorisatie-profiel">
          <Formulier
            type={formuliertype}
            {...propsRest}
            blokDefinitie={blokDefinitie}
            validate={MeldingValidator.createValidate(props.formulier.blokken)}
            createMelding={MeldingBase.ofType(AutorisatieProfielMelding)}
            verzendenButtonText={t(translationKeys.blok.profiel.formulier.opslaan)}
            afterSubmitCompleted={() => props.afterCompleted(profielId || "")}
            hideHeaders={true}
          ></Formulier>
        </div>
      )
    );
  } else {
    return null;
  }
};
