import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class PeriodeMeldingAvvArtikel10Vragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public begindatum: Vraag,
    public einddatum: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any, volgnummer?: number): PeriodeMeldingAvvArtikel10Vragen {
    return new PeriodeMeldingAvvArtikel10Vragen(
      key,
      volgnummer ?? json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toDatumVraag(`${key}.begindatum`, json.begindatum),
      jsonHelpers.toDatumVraag(`${key}.einddatum`, json.einddatum)
    );
  }
}
