import { setLanden, setLandenIsLoading } from "actions";
import { landenApi } from "api";
import { AppThunk } from "./app-thunk-type";

export const getLanden =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setLandenIsLoading(true));

    const landen = await landenApi.getLanden().then((res) => res.landen);

    dispatch(setLanden(landen));
    dispatch(setLandenIsLoading(false));
  };
