import {
  AntwoordBlokken,
  KinderenAntwoorden,
  LocatiesAntwoorden,
  MelderAntwoorden,
  OpdrachtgeverOntheffingKinderarbeidAntwoorden,
  ProductieAntwoorden,
  VoorwaardenAntwoorden,
  WerkmomentenAntwoorden
} from "../blok";
import { ImporterenKinderarbeidAntwoorden } from "../blok/importeren-kinderarbeid-antwoorden";
import { User } from "../security/user";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";
import { HasOntheffingKinderarbeidOpdrachtgeverAntwoorden } from "./has-ontheffing-kinderarbeid-opdrachtgever-antwoorden";
import { HasProductieAntwoorden } from "./has-productie-antwoorden";

export class OntheffingKinderarbeidMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasMelderAntwoorden, HasOntheffingKinderarbeidOpdrachtgeverAntwoorden, HasProductieAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public werkgever: OpdrachtgeverOntheffingKinderarbeidAntwoorden,
    public productie: ProductieAntwoorden,
    public importerenKinderarbeid: ImporterenKinderarbeidAntwoorden,
    public locaties: LocatiesAntwoorden,
    public kinderen: KinderenAntwoorden,
    public werkmomenten: WerkmomentenAntwoorden,
    public voorwaarden: VoorwaardenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): OntheffingKinderarbeidMeldingAntwoordBlokken {
    return new OntheffingKinderarbeidMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      OpdrachtgeverOntheffingKinderarbeidAntwoorden.initialize(),
      ProductieAntwoorden.initialize(),
      ImporterenKinderarbeidAntwoorden.initialize(),
      LocatiesAntwoorden.initialize(),
      KinderenAntwoorden.initialize(),
      WerkmomentenAntwoorden.initialize(),
      VoorwaardenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OntheffingKinderarbeidMeldingAntwoordBlokken {
    return new OntheffingKinderarbeidMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      OpdrachtgeverOntheffingKinderarbeidAntwoorden.fromJson(json.werkgever),
      ProductieAntwoorden.fromJson(json.productie),
      ImporterenKinderarbeidAntwoorden.fromJson(json.importerenKinderarbeid),
      LocatiesAntwoorden.fromJson(json.locaties),
      KinderenAntwoorden.fromJson(json.kinderen),
      WerkmomentenAntwoorden.fromJson(json.werkmomenten),
      VoorwaardenAntwoorden.fromJson(json.voorwaarden)
    );
  }
}
