import { ExpressionContext } from "../blok";
import { VraagBlok } from "../vraag-blok";
import { Expression } from "./expression";

export class WordtMeldingGewijzigd extends Expression {
  get operator(): string {
    return WordtMeldingGewijzigd.operator();
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, _vraagProperty?: string | undefined): boolean =>
    context.meldingContext?.wordtMeldingGewijzigd ?? false;

  static fromJson = (): WordtMeldingGewijzigd => new WordtMeldingGewijzigd();

  static operator = (): string => "wordtMeldingGewijzigd";

  static matches = (json: any): boolean => json?.operator === WordtMeldingGewijzigd.operator();
}
