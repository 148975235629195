import { connect } from "react-redux";
import { State } from "../../../store";
import { setPlaatsGevonden, zoekAdressen, zoekPlaatsen } from "../../../thunks";
import { NederlandsAdres } from "./nederlands-adres.component";
import {
  NederlandsAdresDispatchProps,
  NederlandsAdresOwnProps,
  NederlandsAdresStateProps
} from "./nederlands-adres.component.interfaces";

const mapStateToProps = (state: State, ownProps: NederlandsAdresOwnProps): NederlandsAdresStateProps => ({
  plaatsenIsLoading: state.nederlandsAdres.plaatsenIsLoading[ownProps.vragen.plaats.key] || false,
  plaatsen: state.nederlandsAdres.plaatsen[ownProps.vragen.plaats.key] ?? [],
  adressenIsLoading: state.nederlandsAdres.adressenIsLoading[ownProps.vragen.plaats.key] || false,
  adressen: state.nederlandsAdres.adressen[ownProps.vragen.plaats.key] ?? []
});

const mapDispatchToProps = (dispatch: any, ownProps: NederlandsAdresOwnProps): NederlandsAdresDispatchProps => ({
  onSelectPlaats: () => dispatch(setPlaatsGevonden(ownProps.vragen.plaats.key)),
  onSearchPlaatsen: (naam: string) => dispatch(zoekPlaatsen(naam, ownProps.vragen.plaats.key)),
  onSearchAdres: (data: { postcode: string | null; huisnummer: number | null }) =>
    dispatch(zoekAdressen(data, ownProps.vragen.plaats.key))
});

export default connect(mapStateToProps, mapDispatchToProps)(NederlandsAdres);
