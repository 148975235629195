import { AntwoordBlok } from "./antwoord-blok";
import { Antwoord } from "../antwoord";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class ZiekenhuisAntwoorden extends AntwoordBlok {
  constructor(
    public naamZiekenhuis: Antwoord<string>,
    public adres: CorrespondentieadresAntwoorden | null,
    public datumVerlatenZiekenhuis: Antwoord<string>
  ) {
    super();
  }

  static initialize(): ZiekenhuisAntwoorden {
    return new ZiekenhuisAntwoorden(
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): ZiekenhuisAntwoorden | null {
    return json
      ? new ZiekenhuisAntwoorden(
          Antwoord.fromJson<string>(json.naamZiekenhuis),
          CorrespondentieadresAntwoorden.fromJson(json.adres),
          Antwoord.fromJson<string>(json.datumVerlatenZiekenhuis)
        )
      : null;
  }
}
