import { setImportFileResult } from "actions";
import { importApi } from "api/import-api";
import { httpStatusCodes } from "constants/http-status-codes";
import { translationKeys } from "constants/translation-keys";
import { dateHelpers } from "helpers";
import { MeldingFile } from "models/application";
import pLimit from "p-limit";
import { store } from "store";
import { zoekAdressen } from "thunks";
import { batchSearchBrp } from "thunks/brp-thunks";
import { setAntwoordVraagCollectionsState } from "thunks/thunk-helpers";
import { Antwoord } from "../antwoord";
import { Adres } from "../extern/adres";
import { OntheffingKinderarbeidFormulier } from "../formulier";
import { GetDataFromSpreadsheetRecord, GetDataFromSpreadsheetResponse } from "../import";
import { OntheffingKinderarbeidMeldingAntwoordBlokken } from "../melding";
import { ValidationFailure } from "../validation-failure";
import { KindGegevensAntwoorden } from "./kind-gegevens-antwoorden";
import { KindGegevensVragen } from "./kind-gegevens-vragen";
import { LocatieAntwoorden } from "./locatie-antwoorden";
import { LocatieWerkzaamhedenAntwoorden } from "./locatie-werkzaamheden-antwoorden";
import { LocatieWerkzaamhedenVragen } from "./locatie-werkzaamheden-vragen";
import { NederlandsAdresAntwoorden } from "./nederlands-adres-antwoorden";
import { WerkmomentGegevensAntwoorden } from "./werkmoment-gegevens-antwoorden";
import { WerkmomentGegevensVragen } from "./werkmoment-gegevens-vragen";
import { BrpAchternaamBatchSearchResponse } from "../brp-achternaam-batch-search-response";
import _ from "lodash";

const parallelRequestLimit = 4;

const locatieRequestsLimit = pLimit(parallelRequestLimit);

const brpLookupBatchSize = 100; // The number of brp lookups to perform in a single batch
const brpLookupLimit = pLimit(1); // Only one brp batch lookup at a time

const handleErrors = (err: any) => {
  if (err.status === httpStatusCodes.internalServerError) {
    store.dispatch(setImportFileResult([new ValidationFailure("", "Het bestand bevat geen geldige data")], null));
  } else if (err.status === httpStatusCodes.preconditionFailed) {
    store.dispatch(setImportFileResult(err.response, null));
  }
};

const verwerkLocatie = async (
  antwoordblokken: OntheffingKinderarbeidMeldingAntwoordBlokken,
  formulier: OntheffingKinderarbeidFormulier,
  record: RecordVerwerkingsResultaat
) => {
  const listIndex = antwoordblokken.locaties.locaties!.list.indexOf(record.locatie!);
  const adressenPromise = store.dispatch(
    zoekAdressen(
      {
        postcode: record.locatie!.locatie!.nederlandsAdres!.postcode.waarde,
        huisnummer: record.locatie!.locatie!.nederlandsAdres!.huisnummer.waarde
      },
      formulier.blokken.locaties.locaties.list[listIndex].locatie.nederlandsAdres.plaats.key,
      false
    )
  ) as unknown as Promise<Adres[]>;
  const adressen = await adressenPromise;
  if (adressen && adressen.length) {
    antwoordblokken.locaties.locaties!.list[listIndex].locatie!.nederlandsAdres!.plaats.waarde = adressen[0].plaats;
    antwoordblokken.locaties.locaties!.list[listIndex].locatie!.nederlandsAdres!.straatnaam.waarde = adressen[0].straat;
  }
};

const verwerkKinderen = async (
  antwoordblokken: OntheffingKinderarbeidMeldingAntwoordBlokken,
  formulier: OntheffingKinderarbeidFormulier,
  records: RecordVerwerkingsResultaat[]
) => {
  const listIndexByBsn = new Map<string, number>(
    records
      .filter((r) => r.kind != null && r.kind.bsn.waarde != null)
      .map((r) => [r.kind!.bsn.waarde, antwoordblokken.kinderen.kinderenGegevensLijst!.list.indexOf(r.kind!)])
  );

  const brpSearchPromise = store.dispatch(
    batchSearchBrp(
      records
        .filter((record) => record.kind != null && !record.kind.bsnIsGeldig.waarde)
        .map((record) => {
          const kind = record.kind!;
          const listIndex = antwoordblokken.kinderen.kinderenGegevensLijst!.list.indexOf(kind);
          return {
            key: formulier.blokken.kinderen.kinderenGegevensLijst.list[listIndex].key,
            bsn: kind.bsn.waarde,
            achternaam: kind.achternaam.waarde,
            geboortedatum: new Date(kind.geboorteDatum.waarde)
          };
        })
    ) as unknown as Promise<BrpAchternaamBatchSearchResponse | null>
  );

  const brpResponse = await brpSearchPromise;

  if (!brpResponse) {
    return;
  }

  brpResponse.results
    .filter((res) => res.isFound)
    .forEach((res) => {
      const listIndex = listIndexByBsn.get(res.bsn) ?? -1;
      if (listIndex >= 0) {
        if (res.response?.achternaam) {
          antwoordblokken.kinderen.kinderenGegevensLijst!.list[listIndex].bsnIsGeldig.waarde = true;
          antwoordblokken.kinderen.kinderenGegevensLijst!.list[listIndex].achternaam.waarde = res.response.achternaam;
        }
        if (res.response?.tussenvoegsels) {
          antwoordblokken.kinderen.kinderenGegevensLijst!.list[listIndex].tussenvoegsels.waarde =
            res.response.tussenvoegsels;
        }
      }
    });
};

export const importerenKinderarbeidBestandHandler = async (
  file: MeldingFile,
  antwoordblokken: OntheffingKinderarbeidMeldingAntwoordBlokken,
  translate: any
) => {
  let response: GetDataFromSpreadsheetResponse;

  try {
    response = await importApi.importFileOntheffingKinderarbeid(file);
  } catch (err: any) {
    return handleErrors(err);
  }

  store.dispatch(setImportFileResult(response.errors, null));

  if (response.errors && response.errors.length > 0) {
    return;
  }

  const formulier = store.getState().vragen.formulier as OntheffingKinderarbeidFormulier;

  const verwerkRecordResultaten: RecordVerwerkingsResultaat[] = [];

  // Remove werkmomenten that occur in the future
  if (antwoordblokken.werkmomenten.werkmomentenGegevensLijst?.list) {
    antwoordblokken.werkmomenten.werkmomentenGegevensLijst.list =
      antwoordblokken.werkmomenten.werkmomentenGegevensLijst?.list.filter((w) => {
        return dateHelpers.getDaysFromNow(dateHelpers.getMomentFromDateString(w.datumWerkmoment.waarde)) <= 0;
      });
  }

  response.data.forEach((record) => {
    verwerkRecordResultaten.push(verwerkRecord(antwoordblokken, record));
  });

  store.dispatch(
    setAntwoordVraagCollectionsState(
      formulier.blokken.locaties.locaties,
      antwoordblokken.locaties.locaties!,
      LocatieWerkzaamhedenVragen.fromJson
    )
  );

  store.dispatch(
    setAntwoordVraagCollectionsState(
      formulier.blokken.kinderen.kinderenGegevensLijst,
      antwoordblokken.kinderen.kinderenGegevensLijst!,
      KindGegevensVragen.fromJson
    )
  );

  store.dispatch(
    setAntwoordVraagCollectionsState(
      formulier.blokken.werkmomenten.werkmomentenGegevensLijst,
      antwoordblokken.werkmomenten.werkmomentenGegevensLijst!,
      WerkmomentGegevensVragen.fromJson
    )
  );

  // Voor alle nieuw toegevoegde locaties: zoek adres adhv postcode en huisnummer
  const teVerwerkenVoorLocatie = verwerkRecordResultaten
    .filter((r) => r.isNewLocatie && r.locatie && r.locatie.locatie?.nederlandsAdres)
    .map((r) => locatieRequestsLimit(verwerkLocatie, antwoordblokken, formulier, r));

  // Voor alle toegevoegde kinderen: zoek gegevens in de brp (in batches, 1 batch tegelijk)
  const teVerwerkenKinderenChunks = _.chunk(
    verwerkRecordResultaten.filter((r) => r.isNewKind),
    brpLookupBatchSize
  );
  const teVerwerkenKinderen = teVerwerkenKinderenChunks.map((t) =>
    brpLookupLimit(verwerkKinderen, antwoordblokken, formulier, t)
  );

  await Promise.all([...teVerwerkenVoorLocatie, ...teVerwerkenKinderen]);

  const result = translate(translationKeys.importeerOntheffingKinderarbeidResultaat, {
    fileName: file.name,
    aantalKinderen: verwerkRecordResultaten.filter((r) => r.isNewKind).length,
    aantalLocaties: verwerkRecordResultaten.filter((r) => r.isNewLocatie).length,
    aantalWerkmomenten: verwerkRecordResultaten.length
  });

  store.dispatch(setImportFileResult([], result));
};

const isSameLocatie = (locatie: LocatieWerkzaamhedenAntwoorden, record: GetDataFromSpreadsheetRecord): boolean => {
  return (
    locatie.locatieNaam.waarde === record.locatienaam &&
    locatie.locatie?.nederlandsAdres?.postcode.waarde === record.postcode &&
    locatie.locatie?.nederlandsAdres?.huisnummer.waarde === record.huisnummer &&
    (locatie.locatie?.nederlandsAdres?.toevoeging.waarde === record.toevoeging ||
      (!locatie.locatie?.nederlandsAdres?.toevoeging.waarde && !record.toevoeging))
  );
};

type RecordVerwerkingsResultaat = {
  isVerwerkt: boolean;
  kind?: KindGegevensAntwoorden;
  isNewKind?: boolean;
  locatie?: LocatieWerkzaamhedenAntwoorden;
  isNewLocatie?: boolean;
};

const verwerkRecord = (
  antwoordblokken: OntheffingKinderarbeidMeldingAntwoordBlokken,
  record: GetDataFromSpreadsheetRecord
): RecordVerwerkingsResultaat => {
  const werkmomenten = antwoordblokken.werkmomenten.werkmomentenGegevensLijst?.list;
  const kinderen = antwoordblokken.kinderen.kinderenGegevensLijst?.list;
  const locaties = antwoordblokken.locaties.locaties?.list;
  const result: RecordVerwerkingsResultaat = { isVerwerkt: false };

  if (!werkmomenten || !kinderen || !locaties) {
    return result;
  }

  result.kind = kinderen.find((kind) => kind.bsn.waarde === record.bsn);
  result.isNewKind = !result.kind;
  if (!result.kind) {
    result.kind = createKind(record);
    kinderen.splice(0, 0, result.kind);
  } else {
    result.kind.roepnaam = new Antwoord<string>(record.roepnaam);
  }

  result.locatie = locaties.find((locatie) => isSameLocatie(locatie, record));
  result.isNewLocatie = !result.locatie;
  if (!result.locatie) {
    result.locatie = createLocatie(record);
    locaties.splice(0, 0, result.locatie);
  }

  const nieuwWerkmoment = new WerkmomentGegevensAntwoorden(
    new Antwoord<string>(dateHelpers.formatDate(record.datum, dateHelpers.dateTimeInputFormat, dateHelpers.dateInputFormat)),
    new Antwoord<string>(dateHelpers.formatDateTime(record.aanvang, "HH:mm:ss", "HH:mm")),
    new Antwoord<string>(dateHelpers.formatDateTime(record.einde, "HH:mm:ss", "HH:mm")),
    new Antwoord<string>(result.kind.id),
    new Antwoord<string>(result.locatie.id),
    new Antwoord<string>(record.repetitie?.toLocaleLowerCase()),
    new Antwoord<string>(record.schooldag?.toLocaleLowerCase()),
    new Antwoord<string>(record.schoolweek?.toLocaleLowerCase()),
    new Antwoord<string>("nee")
  );

  werkmomenten.splice(0, 0, nieuwWerkmoment);

  return result;
};

const createKind = (record: GetDataFromSpreadsheetRecord): KindGegevensAntwoorden => {
  return new KindGegevensAntwoorden(
    new Antwoord<string>("ja"),
    new Antwoord<string>(record.bsn),
    new Antwoord<string>(""),
    new Antwoord<string>(
      dateHelpers.formatDate(record.geboortedatum, dateHelpers.dateTimeInputFormat, dateHelpers.dateInputFormat)
    ),
    new Antwoord<string>(record.roepnaam),
    new Antwoord<string>(""),
    new Antwoord<string>(record.achternaam),
    new Antwoord<boolean>(false)
  );
};

const createLocatie = (record: GetDataFromSpreadsheetRecord): LocatieWerkzaamhedenAntwoorden => {
  return new LocatieWerkzaamhedenAntwoorden(
    new Antwoord<string>(record.locatienaam),
    new LocatieAntwoorden(
      new NederlandsAdresAntwoorden(
        new Antwoord<string>(record.postcode),
        new Antwoord<number | null>(record.huisnummer),
        new Antwoord<string>(record.toevoeging),
        new Antwoord<string>(""),
        new Antwoord<string>("")
      ),
      new Antwoord<string>("")
    )
  );
};
