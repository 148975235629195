import { connect } from "react-redux";
import { ProcesstapState } from "../../../models/application";
import { State } from "../../../store";
import { GeneriekVraagBlokRoot } from "./generiek-vraag-blok-root.component";
import {
  GeneriekVraagBlokRootOwnProps,
  GeneriekVraagBlokRootStateProps
} from "./generiek-vraag-blok-root.component.interfaces";

const mapStateToProps = (state: State, ownProps: GeneriekVraagBlokRootOwnProps): GeneriekVraagBlokRootStateProps => ({
  isActive: state.proces.items.some((i) => i.type === ownProps.processtap && i.state === ProcesstapState.active),
  vragen: state.vragen.formulier?.blokken[ownProps.processtap.toString()],
  wordtVertaald: state.melding?.wordtVertaald
});

export default connect(mapStateToProps)(GeneriekVraagBlokRoot);
