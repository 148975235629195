import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { RedenVanVerlateMeldingVraag } from "../reden-verlate-melding-vraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { PeriodeVragen } from "./periode-vragen";

export class PeriodeVragenAsbestverwijdering extends PeriodeVragen {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public toelichting: Tekst,
    public begindatum: Vraag,
    public begintijd: Vraag,
    public einddatum: Vraag,
    public eindtijd: Vraag,
    public redenVanVerlateMelding: RedenVanVerlateMeldingVraag,
    public uitlegOverigeRedenVanVerlateMelding: Vraag
  ) {
    super(
      key,
      volgnummer,
      conditioneelGesteld,
      conditioneelReadonly,
      titel,
      toelichting,
      begindatum,
      begintijd,
      einddatum,
      eindtijd
    );
  }

  static fromJson(key: string, json: any): PeriodeVragenAsbestverwijdering {
    return new PeriodeVragenAsbestverwijdering(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.toelichting`, json.toelichting),
      jsonHelpers.toDatumVraag(`${key}.begindatum`, json.begindatum),
      jsonHelpers.toTijdVraag(`${key}.begintijd`, json.begintijd),
      jsonHelpers.toDatumVraag(`${key}.einddatum`, json.einddatum),
      jsonHelpers.toTijdVraag(`${key}.eindtijd`, json.eindtijd),
      jsonHelpers.toRedenVanVerlateMeldingVraag(`${key}.redenVanVerlateMelding`, json.redenVanVerlateMelding),
      jsonHelpers.toTekstVraag(`${key}.uitlegOverigeRedenVanVerlateMelding`, json.uitlegOverigeRedenVanVerlateMelding)
    );
  }
}
