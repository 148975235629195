import { Antwoord } from "../antwoord";
import { AntwoordBlokCollectionItem } from "./antwoord-blok-collection-item";

export class KindGegevensAntwoorden extends AntwoordBlokCollectionItem {
  static get type(): string {
    return "KindGegevensAntwoorden";
  }

  constructor(
    public heeftKindBsn: Antwoord<string>,
    public bsn: Antwoord<string>,
    public kindVolgnummer: Antwoord<string>,
    public geboorteDatum: Antwoord<string>,
    public roepnaam: Antwoord<string>,
    public tussenvoegsels: Antwoord<string>,
    public achternaam: Antwoord<string>,
    public bsnIsGeldig: Antwoord<boolean>,
    json?: any
  ) {
    super(KindGegevensAntwoorden.type, json);
  }

  static initialize(): KindGegevensAntwoorden {
    return new KindGegevensAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<boolean>(false)
    );
  }

  static fromJson(json: any): KindGegevensAntwoorden {
    return new KindGegevensAntwoorden(
      Antwoord.fromJson<string>(json.heeftKindBsn),
      Antwoord.fromJson<string>(json.bsn),
      Antwoord.fromJson<string>(json.kindVolgnummer),
      Antwoord.fromJson<string>(json.geboorteDatum),
      Antwoord.fromJson<string>(json.roepnaam),
      Antwoord.fromJson<string>(json.tussenvoegsels),
      Antwoord.fromJson<string>(json.achternaam),
      Antwoord.fromJson<boolean>(json.bsnIsGeldig),
      json
    );
  }

  static copyFromJson(json: any) {
    return KindGegevensAntwoorden.fromJson({
      ...json,
      ...AntwoordBlokCollectionItem.copyAsNewInstance(json)
    });
  }
}
