import { InputControls } from "components/input-controls";
import { Fragment } from "react";
import { CollectionFilterProps } from "./collection-filter.component.interfaces";

export const CollectionFilter = (props: CollectionFilterProps) => {
  return (
    <Fragment>
      <InputControls.Search
        disabled={props.isEnabled}
        className="mb-2"
        name="search-collection"
        onSearch={props.onSearch}
        value={props.searchValue}
      ></InputControls.Search>
    </Fragment>
  );
};
