import { ArieFormulier } from "models/api/formulier";
import { connect } from "react-redux";
import { getArieformulierMelding } from "thunks";
import { initArieFormulier } from "thunks/formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { ArieFormulierComponent } from "./arie-formulier.component";
import {
  ArieFormulierComponentDispatchProps,
  ArieFormulierComponentStateProps
} from "./arie-formulier.component.interfaces";

const mapStateToProps = (state: State): ArieFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.arie ? (state.vragen.formulier as ArieFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): ArieFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined) => {
    if (meldingId) {
      dispatch(getArieformulierMelding(meldingId));
    } else {
      dispatch(initArieFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ArieFormulierComponent);
