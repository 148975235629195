import {
  NavigationActions,
  RESET_FORM,
  ROUTER_LOCATION_CHANGE,
  SET_SEARCH_ISVALID,
  SharedActions,
  ValidationActions
} from "../actions";
import { ValidationState } from "../store";

const initialState: ValidationState = {
  searchIsValid: {}
};

const validationReducer = (
  state: ValidationState = initialState,
  action: ValidationActions | SharedActions | NavigationActions
): ValidationState => {
  switch (action.type) {
    case ROUTER_LOCATION_CHANGE:
    case RESET_FORM: {
      return initialState;
    }
    case SET_SEARCH_ISVALID: {
      const searchIsValidCopy = { ...state.searchIsValid };
      searchIsValidCopy[action.key] = action.data;
      return { ...state, searchIsValid: searchIsValidCopy };
    }
    default:
      return state;
  }
};

export default validationReducer;
