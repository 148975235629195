import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, DouaneMeldingAntwoordBlokken, MelderAntwoorden } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initDouaneFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getDouaneFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getDouaneFormulierDefinitie,
      DouaneMeldingAntwoordBlokken.initialize
    );
  };

export const initDouaneFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getDouaneFormulier(),
      meldingApi.getDouaneMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): DouaneMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: MelderAntwoorden.initialize(user),
        werkzaamheden: {
          ...melding.blokken.werkzaamheden,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getDouaneFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
