import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { KlachtenFormulierVraagBlokken } from "./klachten-formulier-vraag-blokken";

export class KlachtenFormulier extends FormulierBase {
  constructor(public blokken: KlachtenFormulierVraagBlokken, json: any) {
    super(Formuliertype.klachten, blokken, json);
  }

  static fromJson(json: any): KlachtenFormulier {
    return new KlachtenFormulier(KlachtenFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
