import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { Bestand } from "./bestand";
import { MedischeOmstandighedenAntwoorden } from "./medische-omstandigheden-antwoorden";
import { SociaalMaatschappelijkeOmstandighedenAntwoorden } from "./sociaal-maatschappelijke-omstandigheden-antwoorden";

export class OmstandighedenNachtarbeidAntwoorden extends AntwoordBlok {
  constructor(
    public sociaalMaatschappelijkeOmstandigheden: SociaalMaatschappelijkeOmstandighedenAntwoorden,
    public medischeOmstandigheden: MedischeOmstandighedenAntwoorden,
    public bijlage: Antwoord<Bestand[]>,
    public formulierNaarWaarheidIngevuld: Antwoord<string[]>
  ) {
    super();
  }

  static initialize(): OmstandighedenNachtarbeidAntwoorden {
    return new OmstandighedenNachtarbeidAntwoorden(
      SociaalMaatschappelijkeOmstandighedenAntwoorden.initialize(),
      MedischeOmstandighedenAntwoorden.initialize(),
      new Antwoord<Bestand[]>([]),
      new Antwoord<string[]>([])
    );
  }

  static fromJson(json: any): OmstandighedenNachtarbeidAntwoorden {
    return new OmstandighedenNachtarbeidAntwoorden(
      SociaalMaatschappelijkeOmstandighedenAntwoorden.fromJson(json.sociaalMaatschappelijkeOmstandigheden),
      MedischeOmstandighedenAntwoorden.fromJson(json.medischeOmstandigheden),
      Antwoord.fromJsonArray<Bestand>(json.bijlage, Bestand.fromJson),
      Antwoord.fromJson<string[]>(json.formulierNaarWaarheidIngevuld)
    );
  }
}
