import { User } from "models/api/security/user";

export const SET_PROFILE_SAVED = "SET_PROFILE_SAVED";
export const SET_USER_INFO_CHANGE = "SET_USER_INFO_CHANGE";
export const SET_USER_REQUESTED_FINISHED = "SET_USER_REQUESTED_FINISHED";
export const OPEN_EXTEND_SESSION_DIALOG = "OPEN_EXTEND_SESSION_DIALOG";
export const CLOSE_EXTEND_SESSION_DIALOG = "CLOSE_EXTEND_SESSION_DIALOG";
export const SET_TOKEN_ALMOST_EXPIRED = "SET_TOKEN_ALMOST_EXPIRED";
export const EXTEND_SESSION = "EXTEND_SESSION";
export const EXTEND_SESSION_COMPLETE = "EXTEND_SESSION_COMPLETE";
export const SIGN_OUT = "SIGN_OUT";
export const LOAD_USER = "LOAD_USER";
export const UNLOAD_USER = "UNLOAD_USER";
export const SET_PROFILE_COMPLETE = "SET_PROFILE_COMPLETE";

interface SetProfileSaved {
  type: typeof SET_PROFILE_SAVED;
  saved: boolean;
}

interface SetUserInfoChangeAction {
  type: typeof SET_USER_INFO_CHANGE;
}

interface SetUserRequestFinishedAction {
  type: typeof SET_USER_REQUESTED_FINISHED;
}

interface LoadUserAction {
  type: typeof LOAD_USER;
  user: User;
}

interface UnloadUserAction {
  type: typeof UNLOAD_USER;
}

interface OpenExtendSessionDialogAction {
  type: typeof OPEN_EXTEND_SESSION_DIALOG;
}

interface CloseExtendSessionDialogAction {
  type: typeof CLOSE_EXTEND_SESSION_DIALOG;
}

interface SetTokenAlmostExpiredAction {
  type: typeof SET_TOKEN_ALMOST_EXPIRED;
  isAlmostExpired: boolean;
}

interface ExtendSessionAction {
  type: typeof EXTEND_SESSION;
}

interface ExtendSessionCompleteAction {
  type: typeof EXTEND_SESSION_COMPLETE;
}

interface SignOutAction {
  type: typeof SIGN_OUT;
}

interface SetProfileComplete {
  type: typeof SET_PROFILE_COMPLETE;
  data: { isComplete: boolean; originalUri: string | null };
}

export type SecurityActions =
  | SetProfileSaved
  | SetUserInfoChangeAction
  | SetUserRequestFinishedAction
  | OpenExtendSessionDialogAction
  | CloseExtendSessionDialogAction
  | SetTokenAlmostExpiredAction
  | ExtendSessionAction
  | ExtendSessionCompleteAction
  | SignOutAction
  | LoadUserAction
  | UnloadUserAction
  | SetProfileComplete;

export const setProfileSaved = (saved: boolean): SecurityActions => {
  return {
    type: SET_PROFILE_SAVED,
    saved
  };
};

export const setUserInfoChange = (): SecurityActions => {
  return {
    type: SET_USER_INFO_CHANGE
  };
};

export const setUserRequestFinished = (): SecurityActions => {
  return {
    type: SET_USER_REQUESTED_FINISHED
  };
};

export const loadUser = (user: User): SecurityActions => {
  return {
    type: LOAD_USER,
    user
  };
};

export const unloadUser = (): SecurityActions => {
  return {
    type: UNLOAD_USER
  };
};

export const openExtendSessionDialog = (): SecurityActions => {
  return {
    type: OPEN_EXTEND_SESSION_DIALOG
  };
};

export const closeExtendSessionDialog = (): SecurityActions => {
  return {
    type: CLOSE_EXTEND_SESSION_DIALOG
  };
};

export const extendSession = (): SecurityActions => {
  return {
    type: EXTEND_SESSION
  };
};

export const extendSessionComplete = (): SecurityActions => {
  return {
    type: EXTEND_SESSION_COMPLETE
  };
};

export const signOut = (): SecurityActions => {
  return { type: SIGN_OUT };
};

export const setProfileComplete = (data: { isComplete: boolean; originalUri: string | null }): SecurityActions => {
  return { type: SET_PROFILE_COMPLETE, data };
};
