import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { PeriodeDatumAntwoorden } from "./periode-datum-antwoorden";

export class InformatieverzoekAntwoorden extends AntwoordBlok {
  constructor(
    public omschrijvingVerzoek: Antwoord<string>,
    public soortInformatieverzoek: Antwoord<string>,
    public soortInformatieverzoekOverig: Antwoord<string>,
    public soortInformatie: Antwoord<string[]>,
    public soortInformatieOverig: Antwoord<string>,
    public periode: PeriodeDatumAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): InformatieverzoekAntwoorden {
    return new InformatieverzoekAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      PeriodeDatumAntwoorden.initialize()
    );
  }

  static fromJson(json: any): InformatieverzoekAntwoorden {
    return new InformatieverzoekAntwoorden(
      Antwoord.fromJson<string>(json.omschrijvingVerzoek),
      Antwoord.fromJson<string>(json.soortInformatieverzoek),
      Antwoord.fromJson<string>(json.soortInformatieverzoekOverig),
      Antwoord.fromJson<string[]>(json.soortInformatie),
      Antwoord.fromJson<string>(json.soortInformatieOverig),
      PeriodeDatumAntwoorden.fromJson(json.periode)
    );
  }
}
