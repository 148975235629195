import { jsonHelpers } from "../../../helpers";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";

export class ZiekenhuisVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public naamZiekenhuis: Vraag,
    public adres: CorrespondentieadresVragen,
    public datumVerlatenZiekenhuis: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly);
  }

  static fromJson(key: string, json: any): ZiekenhuisVragen {
    return new ZiekenhuisVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTekstVraag(`${key}.naamZiekenhuis`, json.naamZiekenhuis),
      CorrespondentieadresVragen.fromJson(`${key}.adres`, json.adres),
      jsonHelpers.toDatumVraag(`${key}.datumVerlatenZiekenhuis`, json.datumVerlatenZiekenhuis)
    );
  }
}
