import { HasBibobVerzoekAntwoorden, HasBijlageAntwoorden, HasVerzoekMelderAntwoorden } from "..";
import { AntwoordBlokken, BibobVerzoekAntwoorden, BijlageAntwoorden, VerzoekMelderAntwoorden } from "../blok";
import { User } from "../security/user";

export class BibobMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasBibobVerzoekAntwoorden, HasVerzoekMelderAntwoorden, HasBijlageAntwoorden
{
  constructor(
    public verzoek: BibobVerzoekAntwoorden,
    public melder: VerzoekMelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): BibobMeldingAntwoordBlokken {
    return new BibobMeldingAntwoordBlokken(
      BibobVerzoekAntwoorden.initialize(),
      VerzoekMelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): BibobMeldingAntwoordBlokken {
    return new BibobMeldingAntwoordBlokken(
      BibobVerzoekAntwoorden.fromJson(json.verzoek),
      VerzoekMelderAntwoorden.fromJson(json.melder),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
