import { connect } from "react-redux";
import { State } from "../../store";
import { UserMenuToggle } from "./user-menu-toggle.component";
import { UserMenuToggleStateProps } from "./user-menu-toggle.component.interfaces";

const mapStateToProps = (state: State): UserMenuToggleStateProps => ({
  isVisible: state.security.userLoaded
});

export default connect(mapStateToProps)(UserMenuToggle);
