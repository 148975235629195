import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";
import { PeriodeMeldingAvvArtikel10Antwoorden } from "./periode-melding-avv-artikel10-antwoorden";

export class AvvArtikel10MeldingAntwoorden extends AntwoordBlok {
  constructor(
    public meldingWordtGedaanDoor: Antwoord<string[]>,
    public welkeCaoVanToepassing: Antwoord<string>,
    public redenMelding: Antwoord<string>,
    public welkeBepalingen: Antwoord<string>,
    public periode: AntwoordBlokCollection<PeriodeMeldingAvvArtikel10Antwoorden> | null,
    public bedrijfsgegevens: BedrijfsgegevensAntwoorden | null
  ) {
    super();
  }

  static initialize(): AvvArtikel10MeldingAntwoorden {
    return new AvvArtikel10MeldingAntwoorden(
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      AntwoordBlokCollection.initialize([]),
      BedrijfsgegevensAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AvvArtikel10MeldingAntwoorden {
    return new AvvArtikel10MeldingAntwoorden(
      Antwoord.fromJson<string[]>(json.meldingWordtGedaanDoor),
      Antwoord.fromJson<string>(json.welkeCaoVanToepassing),
      Antwoord.fromJson<string>(json.redenMelding),
      Antwoord.fromJson<string>(json.welkeBepalingen),
      AntwoordBlokCollection.fromJson(PeriodeMeldingAvvArtikel10Antwoorden.fromJson, json.periode),
      BedrijfsgegevensAntwoorden.fromJson(json.bedrijfsgegevens)
    );
  }
}
