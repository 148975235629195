import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class OnderbetalingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public onderbetalingTitel: Tekst,
    public persoonsgegevensTitel: Tekst,
    public wieWordtOnderbetaald: Meerkeuzevraag,
    public voorletters: Vraag,
    public tussenvoegsels: Vraag,
    public achternaam: Vraag,
    public geslacht: Meerkeuzevraag,
    public geboorteDatum: Vraag,
    public bsn: Vraag,
    public loonTitel: Tekst,
    public datumInDienst: Vraag,
    public welkeLoonperiode: Vraag,
    public hoeLoonBetaald: Meerkeuzevraag,
    public overigeBetaalwijzeLoon: Vraag,
    public hoeveelLoon: Vraag,
    public brutoNetto: Meerkeuzevraag,
    public hoeVaakUitbetaald: Meerkeuzevraag,
    public alleGewerkteUrenUitbetaald: Meerkeuzevraag,
    public waaromNiet: Vraag,
    public inhoudingenTitel: Tekst,
    public hoeVakantiebijslagBetaald: Meerkeuzevraag,
    public wordenBedragenIngehouden: Meerkeuzevraag,
    public welkBedragIngehouden: Vraag,
    public waarvoorIngehoudenOpLoon: Vraag,
    public toestemmingVoorInhouding: Vraag,
    public terugbetalenLoonTitel: Tekst,
    public loonTerugbetalen: Meerkeuzevraag,
    public hoeveelTerugbetalen: Vraag,
    public waaromTerugbetalen: Vraag,
    public hoeTerugbetalen: Meerkeuzevraag,
    public overigeBetaalwijzeTerugbetalen: Vraag,
    public aanWieTerugbetalen: Vraag,
    public watAlsNietTerugbetaalt: Vraag,
    public aanvullendeInformatie: Vraag,
    public onderbetalingBenodigdeDocumentenParagraaf: Tekst,
    public onderbetalingOpTeSturenDocumenten: Meerkeuzevraag,
    public toestemmingDoorzettenNaarSncu: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): OnderbetalingVragen {
    return new OnderbetalingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.onderbetalingTitel`, json.onderbetalingTitel),
      jsonHelpers.toTitel(`${key}.persoonsgegevensTitel`, json.persoonsgegevensTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.wieWordtOnderbetaald`, json.wieWordtOnderbetaald),
      jsonHelpers.toTekstVraag(`${key}.voorletters`, json.voorletters),
      jsonHelpers.toTekstVraag(`${key}.tussenvoegsels`, json.tussenvoegsels),
      jsonHelpers.toTekstVraag(`${key}.achternaam`, json.achternaam),
      jsonHelpers.toMeerkeuzeVraag(`${key}.geslacht`, json.geslacht),
      jsonHelpers.toDatumVraag(`${key}.geboorteDatum`, json.geboorteDatum),
      jsonHelpers.toTekstVraag(`${key}.bsn`, json.bsn),
      jsonHelpers.toTitel(`${key}.loonTitel`, json.loonTitel),
      jsonHelpers.toDatumVraag(`${key}.datumInDienst`, json.datumInDienst),
      jsonHelpers.toTekstVraag(`${key}.welkeLoonperiode`, json.welkeLoonperiode),
      jsonHelpers.toMeerkeuzeVraag(`${key}.hoeLoonBetaald`, json.hoeLoonBetaald),
      jsonHelpers.toTekstVraag(`${key}.overigeBetaalwijzeLoon`, json.overigeBetaalwijzeLoon),
      jsonHelpers.toTekstVraag(`${key}.hoeveelLoon`, json.hoeveelLoon),
      jsonHelpers.toMeerkeuzeVraag(`${key}.brutoNetto`, json.brutoNetto),
      jsonHelpers.toMeerkeuzeVraag(`${key}.hoeVaakUitbetaald`, json.hoeVaakUitbetaald),
      jsonHelpers.toMeerkeuzeVraag(`${key}.alleGewerkteUrenUitbetaald`, json.alleGewerkteUrenUitbetaald),
      jsonHelpers.toTekstVraag(`${key}.waaromNiet`, json.waaromNiet),
      jsonHelpers.toTitel(`${key}.inhoudingenTitel`, json.inhoudingenTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.hoeVakantiebijslagBetaald`, json.hoeVakantiebijslagBetaald),
      jsonHelpers.toMeerkeuzeVraag(`${key}.wordenBedragenIngehouden`, json.wordenBedragenIngehouden),
      jsonHelpers.toTekstVraag(`${key}.welkBedragIngehouden`, json.welkBedragIngehouden),
      jsonHelpers.toTekstVraag(`${key}.waarvoorIngehoudenOpLoon`, json.waarvoorIngehoudenOpLoon),
      jsonHelpers.toTekstVraag(`${key}.toestemmingVoorInhouding`, json.toestemmingVoorInhouding),
      jsonHelpers.toTitel(`${key}.terugbetalenLoonTitel`, json.terugbetalenLoonTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.loonTerugbetalen`, json.loonTerugbetalen),
      jsonHelpers.toTekstVraag(`${key}.hoeveelTerugbetalen`, json.hoeveelTerugbetalen),
      jsonHelpers.toTekstVraag(`${key}.waaromTerugbetalen`, json.waaromTerugbetalen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.hoeTerugbetalen`, json.hoeTerugbetalen),
      jsonHelpers.toTekstVraag(`${key}.overigeBetaalwijzeTerugbetalen`, json.overigeBetaalwijzeTerugbetalen),
      jsonHelpers.toTekstVraag(`${key}.aanWieTerugbetalen`, json.aanWieTerugbetalen),
      jsonHelpers.toTekstVraag(`${key}.watAlsNietTerugbetaalt`, json.watAlsNietTerugbetaalt),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.aanvullendeInformatie`, json.aanvullendeInformatie),
      jsonHelpers.toParagraaf(
        `${key}.onderbetalingBenodigdeDocumentenParagraaf`,
        json.onderbetalingBenodigdeDocumentenParagraaf
      ),
      jsonHelpers.toChecklistvraag(`${key}.onderbetalingOpTeSturenDocumenten`, json.onderbetalingOpTeSturenDocumenten),
      jsonHelpers.toMeerkeuzeVraag(`${key}.toestemmingDoorzettenNaarSncu`, json.toestemmingDoorzettenNaarSncu)
    );
  }
}
