import { VraagBlok } from "../vraag-blok";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { OpdrachtgeverVragen } from "./opdrachtgever-vragen";
import { jsonHelpers } from "../../../helpers";
import { ContactpersoonVragen } from "./contactpersoon-vragen";
import { Vraag } from "../vraag";
import { Tekst } from "../tekst";

export class UitvoerendePartijVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public gegevensGelijk: Meerkeuzevraag,
    public opdrachtgever: OpdrachtgeverVragen,
    public coordinatorUitvoeringsfaseTitel: Tekst,
    public coordinatorGegevensGelijk: Meerkeuzevraag,
    public contactpersoonTitel: Tekst,
    public coordinator: ContactpersoonVragen,
    public werkzaamBij: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): UitvoerendePartijVragen {
    return new UitvoerendePartijVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.gegevensGelijk`, json.gegevensGelijk),
      OpdrachtgeverVragen.fromJson(`${key}.opdrachtgever`, json.opdrachtgever),
      jsonHelpers.toTitel(`${key}.coordinatorUitvoeringsfaseTitel`, json.coordinatorUitvoeringsfaseTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.coordinatorGegevensGelijk`, json.coordinatorGegevensGelijk),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.coordinator`, json.coordinator),
      jsonHelpers.toTekstVraag(`${key}.werkzaamBij`, json.werkzaamBij)
    );
  }
}
