import { get } from "./api";
import { Configuration } from "../configuration";
import { AdressenSearchResponse } from "../models/api/adressen-search-response";

const searchAdressen = (
  data: { postcode: string; huisnummer: number },
  signal?: AbortSignal
): Promise<AdressenSearchResponse> => {
  return get({
    uri: `${Configuration.apiUrl}/adressen?postcode=${data.postcode}&huisnummer=${data.huisnummer}`,
    success: (r) => AdressenSearchResponse.fromJson(r),
    signal: signal
  });
};

export const adressenApi = {
  searchAdressen
};
