import { Configuration } from "configuration";
import { httpStatusCodes } from "constants/http-status-codes";
import { UrlBuilder } from "helpers/url-builder";
import i18n from "i18next";
import { User } from "models/api/security/user";
import { get } from "./api";

const getUser = (renewUserInfo?: boolean): Promise<User> => {
  const statusHandlers = new Map([
    [
      httpStatusCodes.unauthorized,
      () => {
        // No specific actions necessary
      }
    ],
    [
      httpStatusCodes.forbidden,
      () => {
        // No specific actions necessary
      }
    ]
  ]);

  const getUserUrl = new UrlBuilder(`/auth/me`).withQueryParam("renew", renewUserInfo ? "true" : undefined);

  return get({
    uri: getUserUrl.toString(),
    success: (r) => User.fromJson(r),
    statusHandlers: Configuration.loginEnabled ? statusHandlers : undefined // when manual login is available always login, so not ignore 401/403
  });
};

const getLoginUrl = (returnTo?: string, forcedLogin?: boolean, tokenExpired?: boolean): string => {
  if (returnTo && returnTo !== "/") {
    returnTo = new UrlBuilder(returnTo).withQueryParam("lng", i18n.language).toString();
  }
  const loginUrl = new UrlBuilder(`/auth/login`)
    .withQueryParam("return_to", returnTo)
    .withQueryParam("forced_login", forcedLogin ? "true" : undefined)
    .withQueryParam("token_expired", tokenExpired ? "true" : undefined)
    .withQueryParam("ui_locales", i18n.language)
    .withQueryParam("lng", i18n.language);

  return loginUrl.toString();
};

const getLogoutUrl = (returnTo?: string, tokenExpired?: boolean): string => {
  if (returnTo && returnTo !== "/") {
    returnTo = new UrlBuilder(returnTo).withQueryParam("lng", i18n.language).toString();
  }
  const logoutUrl = new UrlBuilder(`/auth/logout`)
    .withQueryParam("return_to", returnTo)
    .withQueryParam("token_expired", tokenExpired ? "true" : undefined)
    .withQueryParam("ui_locales", i18n.language)
    .withQueryParam("lng", i18n.language);

  return logoutUrl.toString();
};

const getChangePasswordUrl = (returnTo?: string): string => {
  return `/auth/change_password?returnUrl=${returnTo}&lng=${i18n.language}`;
};

export const securityApi = {
  getUser,
  getLoginUrl,
  getLogoutUrl,
  getChangePasswordUrl
};
