import { ReadOnlyControls } from "../read-only-controls";
import { withConditioneelGesteld, withConditioneelGesteldeVraag } from "./with-conditioneel-gesteld";
import { withTekst } from "./with-tekst";
import { withVraag } from "./with-vraag";

const container = {
  ReadOnly: withConditioneelGesteldeVraag(withVraag(ReadOnlyControls.ReadOnly)),
  Text: withConditioneelGesteld(withTekst(ReadOnlyControls.Text)),
  Title: withConditioneelGesteld(withTekst(ReadOnlyControls.Title))
};

export { container as FormulierReadOnlyControls };
