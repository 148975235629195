import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { UploadGeneriekFormulierVraagBlokken } from "./upload-generiek-formulier-vraag-blokken";

export class UploadGeneriekFormulier extends FormulierBase {
  constructor(public blokken: UploadGeneriekFormulierVraagBlokken, json: any) {
    super(Formuliertype.uploadGeneriek, blokken, json);
  }

  static fromJson(json: any): UploadGeneriekFormulier {
    return new UploadGeneriekFormulier(UploadGeneriekFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
