import { Antwoord } from "../antwoord";
import { AntwoordBlokCollectionItem } from "./antwoord-blok-collection-item";

export class PeriodeMeldingAvvArtikel10Antwoorden extends AntwoordBlokCollectionItem {
  static get type(): string {
    return "PeriodeMeldingAvvArtikel10Antwoorden";
  }

  constructor(public begindatum: Antwoord<string>, public einddatum: Antwoord<string>, json?: any) {
    super(PeriodeMeldingAvvArtikel10Antwoorden.type, json);
  }

  static initialize(): PeriodeMeldingAvvArtikel10Antwoorden {
    return new PeriodeMeldingAvvArtikel10Antwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): PeriodeMeldingAvvArtikel10Antwoorden {
    return new PeriodeMeldingAvvArtikel10Antwoorden(
      Antwoord.fromJson<string>(json.begindatum),
      Antwoord.fromJson<string>(json.einddatum),
      json
    );
  }

  static copyFromJson(json: any) {
    return PeriodeMeldingAvvArtikel10Antwoorden.fromJson({
      ...json,
      ...AntwoordBlokCollectionItem.copyAsNewInstance(json)
    });
  }
}
