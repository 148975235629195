import { connect } from "react-redux";
import { ExtendSessionDialog } from "./extend-session-dialog.component";
import { State } from "../../store/state";
import {
  ExtendSessionDialogStateProps,
  ExtendSessionDialogDispatchProps
} from "./extend-session-dialog.component.interfaces";
import { uitloggen, sessieVerlengen } from "../../thunks/security-thunks";

const mapStateToProps = (state: State): ExtendSessionDialogStateProps => ({
  isUserIdle: state.userActivity.isUserIdle,
  isExtendSessionDialogShown: state.security.isExtendSessionDialogShown,
  isExtendingSession: state.security.isExtendingSession,
  isSigningOut: state.security.isSigningOut
});

const mapDispatchToProps = (dispatch: any): ExtendSessionDialogDispatchProps => ({
  onExtendSession: () => {
    dispatch(sessieVerlengen());
  },
  onSignout: () => {
    dispatch(uitloggen());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtendSessionDialog);
