import { useEffect } from "react";
import { Breadcrumb as ReactBreadcrumb } from "react-bootstrap";
import { BreadcrumbsProps } from "./breadcrumbs.component.interface";
import { Breadcrumb } from "../../../models/application";
import { GuardedLink } from "../../guarded-link";

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { onClearBreadcrumbs } = props;

  useEffect(() => {
    onClearBreadcrumbs();
  }, [onClearBreadcrumbs]);

  return props.breadcrumbs?.length > 0 ? (
    <ReactBreadcrumb className="breadcrumbs">
      {props.breadcrumbs?.map((breadcrumb: Breadcrumb, index) => {
        const linkProps = {
          href: breadcrumb.url,
          url: breadcrumb.url
        };

        return (
          <ReactBreadcrumb.Item
            linkAs={GuardedLink}
            linkProps={linkProps}
            key={index}
            active={breadcrumb.url === window.location.pathname}
            className="custom-breadcrumb-item"
          >
            {breadcrumb.text}
          </ReactBreadcrumb.Item>
        );
      })}
    </ReactBreadcrumb>
  ) : null;
};
