import { VraagBlok } from "..";
import { ExpressionContext } from "../blok/expression-context";
import { Autorisatiegegevens } from "./../security/autorisatiegegevens";
import { Expression } from "./expression";

export abstract class AutorisatiegegevensExpression extends Expression {
  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    const hasAutorisatiegegevens = context.autorisatiegegevens != null;
    if (hasAutorisatiegegevens) {
      return this.executeAutorisation(context.autorisatiegegevens);
    }
    throw new Error(
      "Deze expressie kan niet gebruikt worden voor dit antwoordblok, er zijn geen autorisatiegegevens beschikbaar"
    );
  };

  abstract executeAutorisation(autorisatiegegevens: Autorisatiegegevens): boolean;
}
