import { CollectionEventsProvider, CollectionItemCanBeRemovedResult } from "components/blok/vraag-blokken-collection";
import { ServerErrorMessagesControl } from "components/error";
import moment from "moment";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { translationKeys } from "../../../../constants";
import { dateHelpers, displayAntwoordHelpers, userHelpers, vraagBlokCollectionHelpers } from "../../../../helpers";
import {
  AntwoordBlok,
  VraagBlok,
  VraagBlokCollection,
  WerkmomentenVragen,
  WerkmomentGegevensAntwoorden,
  WerkmomentGegevensVragen
} from "../../../../models/api";
import { ProcesstapType, VraagBlokCollectionType } from "../../../../models/application";
import { Order, State } from "../../../../store";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";
import { WerkmomentenGegevensStateProps } from "./werkmomenten-gegevens.interfaces";

const mapStateToProps = (state: State): WerkmomentenGegevensStateProps => {
  const werkmomentenVragen = state.vragen.formulier?.blokken[ProcesstapType.werkmomenten] as WerkmomentenVragen;
  const werkmomentGegevensVragen = werkmomentenVragen.werkmomentenGegevensLijst.template;

  return {
    user: state.security.user,
    antwoordBlokken: state.antwoorden.blokken,
    kindVraagBlokCollectionProperty: werkmomentGegevensVragen.kind.vraagBlokCollectionProperty,
    locatieWerkmomentVraagBlokCollectionProperty: werkmomentGegevensVragen.locatieWerkmoment.vraagBlokCollectionProperty,
    werkmomentVraagBlokCollectionProperty: werkmomentenVragen.werkmomentenGegevensLijst.key
  };
};

export class WerkmomentenGegevens {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.werkmomentGegevens;

  static createWerkmomentenGegevens =
    (vraagBlokCollection: VraagBlokCollection<VraagBlok>) => (props: WerkmomentenGegevensStateProps) => {
      const { t } = useTranslation();

      const formatDatumWerkmoment = (antwoordBlok: AntwoordBlok) => {
        const antwoorden = antwoordBlok as WerkmomentGegevensAntwoorden;
        return antwoorden.datumWerkmoment?.waarde ? dateHelpers.formatDate(antwoorden.datumWerkmoment?.waarde) : undefined;
      };

      const formatKind = (antwoordBlok: AntwoordBlok) => {
        const antwoorden = antwoordBlok as WerkmomentGegevensAntwoorden;
        const kindItem = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemById(
          antwoorden.kind?.waarde,
          props.kindVraagBlokCollectionProperty,
          props.antwoordBlokken
        );
        return kindItem ? displayAntwoordHelpers.getDisplayAntwoord(kindItem) : undefined;
      };

      const formatLocatieWerkmoment = (antwoordBlok: AntwoordBlok) => {
        const antwoorden = antwoordBlok as WerkmomentGegevensAntwoorden;
        const locatieItem = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemById(
          antwoorden.locatieWerkmoment?.waarde,
          props.locatieWerkmomentVraagBlokCollectionProperty,
          props.antwoordBlokken
        );
        return locatieItem ? displayAntwoordHelpers.getDisplayAntwoord(locatieItem) : undefined;
      };

      const canWerkmomentBeRemoved = (_key: string, index: number): CollectionItemCanBeRemovedResult => {
        let canBeRemoved = true;
        const reason = t(translationKeys.blok.werkmomenten.werkmomentenLijst.validatie.verwijderen);

        const werkmoment = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemByIndex(
          index,
          props.werkmomentVraagBlokCollectionProperty,
          props.antwoordBlokken
        ) as WerkmomentGegevensAntwoorden;

        if (werkmoment) {
          const beginWerkmoment = dateHelpers.getMomentFromDateTimeString(
            `${werkmoment.datumWerkmoment.waarde} ${werkmoment.aanvangAanwezig.waarde}`
          );
          canBeRemoved = userHelpers.getIsInternalUser(props.user) || moment() < beginWerkmoment;
        }

        return { canBeRemoved: canBeRemoved, reason: reason };
      };

      return (
        <Fragment>
          <CollectionEventsProvider canBeRemoved={canWerkmomentBeRemoved}>
            <FormulierInputControls.CollectionGrid
              itemComponent={GeneriekBlok.VraagBlok}
              getVraagBlokFromTemplate={WerkmomentGegevensVragen.fromJson}
              initializeAntwoorden={WerkmomentGegevensAntwoorden.initialize}
              copyAntwoorden={WerkmomentGegevensAntwoorden.copyFromJson}
              vraagBlokCollection={vraagBlokCollection}
              getCollectionItemSummary={(antwoordBlok: AntwoordBlok) => {
                const antwoorden = antwoordBlok as WerkmomentGegevensAntwoorden;
                return t(translationKeys.blok.werkmomenten.werkmoment.samengesteldeWaarde, {
                  datumWerkmoment: formatDatumWerkmoment(antwoordBlok),
                  aanvangAanwezig: antwoorden.aanvangAanwezig?.waarde,
                  eindeAanwezig: antwoorden.eindeAanwezig?.waarde,
                  kind: formatKind(antwoordBlok),
                  locatieWerkmoment: formatLocatieWerkmoment(antwoordBlok)
                });
              }}
              pagingSettings={{
                numberOfItemsPerPage: 10,
                getTotalsDescription: (counters) =>
                  counters.totalItemsInGrid > counters.filteredItemsInGrid
                    ? t(translationKeys.blok.werkmomenten.werkmomentenLijst.paginering.totaalAantalGefilterd, counters)
                    : t(translationKeys.blok.werkmomenten.werkmomentenLijst.paginering.totaalAantal, counters)
              }}
              columnSettings={{
                columns: {
                  datumWerkmoment: {
                    columnFormatter: formatDatumWerkmoment,
                    columnComparer: dateHelpers.displayDateCompare,
                    sorting: { defaultSortOrder: Order.ascending }
                  },
                  aanvangAanwezig: {},
                  eindeAanwezig: {},
                  kind: {
                    columnFormatter: formatKind
                  },
                  locatieWerkmoment: { columnFormatter: formatLocatieWerkmoment },
                  repetitie: { searchable: false },
                  schooldag: { searchable: false },
                  schoolweek: { searchable: false },
                  uitsluitenWerkmomentControle: { searchable: false, visible: userHelpers.getIsInternalUser(props.user) }
                },
                translationBase: translationKeys.blok.werkmomenten.werkmomentenLijst.tabelkop
              }}
              defaultNewestOnTop={true}
            />
            <ServerErrorMessagesControl
              collectionKey={vraagBlokCollection.key}
              title={t(translationKeys.blok.werkmomenten.werkmomentenLijst.validatie.beleidsregels)}
              withCode={WerkmomentGegevensAntwoorden.WerkmomentenBeleidsregelsValidationErrorCode}
            />
            <ServerErrorMessagesControl
              collectionKey={vraagBlokCollection.key}
              title={t(translationKeys.blok.werkmomenten.werkmomentenLijst.validatie.nietGekoppeldeKinderen)}
              withCode={WerkmomentGegevensAntwoorden.WerkmomentenNietGekoppeldeKinderenValidationErrorCode}
            />
            <ServerErrorMessagesControl
              collectionKey={vraagBlokCollection.key}
              title={t(translationKeys.blok.werkmomenten.werkmomentenLijst.validatie.kindNummersNietUniek)}
              withCode={WerkmomentGegevensAntwoorden.WerkmomentenKindnummersNietUniekValidationErrorCode}
            />
          </CollectionEventsProvider>
        </Fragment>
      );
    };

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): any =>
    React.createElement(connect(mapStateToProps)(WerkmomentenGegevens.createWerkmomentenGegevens(vraagBlokCollection)));
}
