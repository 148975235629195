import { connect } from "react-redux";
import { clearSelectedVestiging, setSelectedVestiging } from "../../../actions/blok/vestigingen-actions";
import { State, VestigingenSearchCriteria } from "../../../store";
import { clearVestigingSearch } from "../../../thunks";
import { Vestiging } from "./../../../models/api/extern/vestiging";
import {
  searchVestigingenWithCriteria,
  searchVestigingenWithPaging,
  setVestigingSearchById
} from "./../../../thunks/blok/vestiging-thunks";
import { Vestiging as VestigingComponent } from "./vestiging.component";
import { VestigingDispatchProps, VestigingOwnProps, VestigingStateProps } from "./vestiging.component.interfaces";

const mapStateToProps = (state: State, ownProps: VestigingOwnProps): VestigingStateProps => {
  const EMPTYLIST: string[] = [];
  return {
    autorisatiegegevens: state.autorisatie.autorisatiegegevens,
    vraagBlokken: state.vragen.formulier?.blokken,
    vestigingSearches: state.vestigingen.searches[ownProps.vragen.key],
    landen: state.landen.landen ?? EMPTYLIST
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: VestigingOwnProps): VestigingDispatchProps => ({
  onSetVestigingById: (vestigingId: number) => {
    dispatch(setVestigingSearchById(vestigingId, ownProps.vragen.key));
  },
  onVestigingSearch: (values: VestigingenSearchCriteria, abortSignal: AbortSignal) => {
    dispatch(searchVestigingenWithCriteria(values, ownProps.vragen.key, abortSignal));
  },
  onVestigingPaging: (page: number, abortSignal: AbortSignal) => {
    dispatch(searchVestigingenWithPaging(page, ownProps.vragen.key, abortSignal));
  },
  onVestigingClear: () => {
    dispatch(clearSelectedVestiging(ownProps.vragen.key));
  },
  onSetVestiging: (vestiging: Vestiging) => {
    dispatch(setSelectedVestiging(ownProps.vragen.key, vestiging));
  },
  onVestigingSearchClear: () => {
    dispatch(clearVestigingSearch(ownProps.vragen.key));
  },
  onResetVestigingSearchAndVraag: () => () => {
    dispatch(clearSelectedVestiging(ownProps.vragen.key));
    dispatch(clearVestigingSearch(ownProps.vragen.key));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VestigingComponent);
