import { useFormikContext } from "formik";
import { User } from "models/api/security/user";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { removeAllVraagBlokken } from "thunks";
import { AntwoordBlokken, MeldingContext, VraagBlok, VraagBlokken } from "../../../models/api";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";
import { State, useAppDispatch } from "../../../store";
import { wordtGesteld } from "./conditioneel-gesteld-helper";
import { WithConditioneelGesteldeVraagBlokCollectionProps } from "./with-conditioneel-gestelde-vraag-blok-collection.interfaces";

export const withConditioneelGesteldeVraagBlokCollection = <TVraagBlok extends VraagBlok, P extends object>(
  Component: React.ComponentType<P & WithConditioneelGesteldeVraagBlokCollectionProps<TVraagBlok>>
) => {
  return (props: P & WithConditioneelGesteldeVraagBlokCollectionProps<TVraagBlok>) => {
    const { vraagBlokCollection } = props;

    const { vragen, user, autorisatiegegevens, meldingStatus, wordtMeldingGewijzigd } = useSelector<
      State,
      {
        vragen?: VraagBlokken;
        user: User | null;
        autorisatiegegevens: Autorisatiegegevens;
        meldingStatus?: string;
        wordtMeldingGewijzigd: boolean;
      }
    >(
      (state: State) => ({
        vragen: state.vragen.formulier?.blokken,
        user: state.security.user,
        autorisatiegegevens: state.autorisatie.autorisatiegegevens,
        meldingStatus: state.melding?.status,
        wordtMeldingGewijzigd: state.melding?.wordtGewijzigd
      }),
      shallowEqual
    );

    const dispatch = useAppDispatch();

    const formikContext = useFormikContext<AntwoordBlokken>();
    const { values } = formikContext;

    let wordtItemGesteld = false;

    if (vragen) {
      wordtItemGesteld = wordtGesteld(
        vraagBlokCollection,
        vragen,
        values,
        user,
        autorisatiegegevens,
        new MeldingContext(wordtMeldingGewijzigd, meldingStatus)
      );
    }

    const hasVraagBlokken = vraagBlokCollection.list.length > 0;

    const resetAntwoord = !wordtItemGesteld && hasVraagBlokken;
    const collectionKey = vraagBlokCollection.key;

    // Reset the collection when the collection is not asked anymore.
    useEffect(() => {
      if (resetAntwoord) {
        dispatch(removeAllVraagBlokken(collectionKey, formikContext));
      }
    }, [resetAntwoord, collectionKey, formikContext, dispatch]);

    return wordtItemGesteld ? <Component {...props} /> : null;
  };
};
