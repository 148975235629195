import { AntwoordBlokken, AutorisatieProfielAntwoorden } from "../blok";
import { HasAutorisatieProfielAntwoorden } from "./has-autorisatie-profiel-antwoorden";

export class AutorisatieProfielMeldingAntwoordBlokken extends AntwoordBlokken implements HasAutorisatieProfielAntwoorden {
  constructor(public autorisatieProfiel: AutorisatieProfielAntwoorden) {
    super();
  }

  static fromJson(json: any): AutorisatieProfielMeldingAntwoordBlokken {
    return new AutorisatieProfielMeldingAntwoordBlokken(AutorisatieProfielAntwoorden.fromJson(json.autorisatieProfiel));
  }
}
