import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { MainContent } from "../../main-content";
import { LoginProps } from "./login.component.interfaces";

export const Login: React.FC<any> = (props: LoginProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.inloggen();
  }, [props]);

  return (
    <Row className="main-container">
      <Col xs={12} className="main-container-title"></Col>
      <Col xs={12} className="main-container-content">
        <MainContent>
          <div className="main-form">
            <h2>{t(translationKeys.auth.doorverwezenNaarLogin)}</h2>
          </div>
        </MainContent>
      </Col>
    </Row>
  );
};
