import { Language } from "../../application";
import { SortOption } from "../sort-option";

export class GetMijnMeldingenRequest {
  constructor(
    public taal: Language,
    public sortOption: SortOption,
    public pageNumber: number,
    public itemsPerPage: number
  ) {}
}
