import React from "react";
import { CollectionTypeaheadVraag, Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldCollectionTypeaheadVraag } from "../../../samenvatting";

export class CollectionTypeahead {
  static getType = (): VraagType => VraagType.collectionTypeahead;

  static create = (vraag: Vraag): React.JSX.Element => (
    <SamenvattingFieldCollectionTypeaheadVraag vraag={vraag as CollectionTypeaheadVraag} />
  );
}
