import {
  AangeverAntwoorden,
  AntwoordBlokken,
  BijlageDouaneAntwoorden,
  GeadresseerdeAntwoorden,
  LocatieProductAntwoorden,
  MelderAntwoorden,
  MeldingDouaneAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasAangeverAntwoorden } from "./has-aangever-antwoorden";
import { HasBijlageDouaneAntwoorden } from "./has-bijlage-douane-antwoorden";
import { HasGeadresseerdeAntwoorden } from "./has-geadresseerde-antwoorden";
import { HasLocatieProductAntwoorden } from "./has-locatie-product-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";
import { HasMeldingDouaneAntwoorden } from "./has-melding-douane-antwoorden";

export class DouaneMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasMelderAntwoorden,
    HasMeldingDouaneAntwoorden,
    HasLocatieProductAntwoorden,
    HasAangeverAntwoorden,
    HasGeadresseerdeAntwoorden,
    HasBijlageDouaneAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public melding: MeldingDouaneAntwoorden,
    public locatieProduct: LocatieProductAntwoorden,
    public aangever: AangeverAntwoorden,
    public geadresseerde: GeadresseerdeAntwoorden,
    public bijlage: BijlageDouaneAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): DouaneMeldingAntwoordBlokken {
    return new DouaneMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      MeldingDouaneAntwoorden.initialize(),
      LocatieProductAntwoorden.initialize(),
      AangeverAntwoorden.initialize(),
      GeadresseerdeAntwoorden.initialize(),
      BijlageDouaneAntwoorden.initialize()
    );
  }

  static fromJson(json: any): DouaneMeldingAntwoordBlokken {
    return new DouaneMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      MeldingDouaneAntwoorden.fromJson(json.melding),
      LocatieProductAntwoorden.fromJson(json.locatieProduct),
      AangeverAntwoorden.fromJson(json.aangever),
      GeadresseerdeAntwoorden.fromJson(json.geadresseerde),
      BijlageDouaneAntwoorden.fromJson(json.bijlage)
    );
  }
}
