import { BrpAchternaamBatchSearchResponseItem } from "./brp-achternaam-batch-search-response-item";

export class BrpAchternaamBatchSearchResponse {
  constructor(public results: BrpAchternaamBatchSearchResponseItem[]) {}

  static fromJson = (json: any) => {
    return new BrpAchternaamBatchSearchResponse(
      json.results.map((r: any) => BrpAchternaamBatchSearchResponseItem.fromJson(r))
    );
  };
}
