import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";
import { ZiekenhuisAntwoorden } from "./ziekenhuis-antwoorden";

export class SlachtofferInformatieAntwoorden extends AntwoordBlok {
  constructor(
    public contactpersoon: ContactpersoonAntwoorden | null,
    public adresgegevens: CorrespondentieadresAntwoorden | null,
    public geboorteDatum: Antwoord<string>,
    public datumInDienst: Antwoord<string>,
    public arbeidsverhouding: Antwoord<string>,
    public aardDienstverband: Antwoord<string>,
    public anderDienstverband: Antwoord<string>,
    public aardVanLetselOmschrijving: Antwoord<string>,
    public slachtofferNaarZiekenhuis: Antwoord<string>,
    public ziekenhuis: ZiekenhuisAntwoorden | null,
    public slachtofferBlijvendLetsel: Antwoord<string>
  ) {
    super();
  }

  static initialize(): SlachtofferInformatieAntwoorden {
    return new SlachtofferInformatieAntwoorden(
      ContactpersoonAntwoorden.initialize(),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      ZiekenhuisAntwoorden.initialize(),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): SlachtofferInformatieAntwoorden {
    return new SlachtofferInformatieAntwoorden(
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      CorrespondentieadresAntwoorden.fromJson(json.adresgegevens),
      Antwoord.fromJson<string>(json.geboorteDatum),
      Antwoord.fromJson<string>(json.datumInDienst),
      Antwoord.fromJson<string>(json.arbeidsverhouding),
      Antwoord.fromJson<string>(json.aardDienstverband),
      Antwoord.fromJson<string>(json.anderDienstverband),
      Antwoord.fromJson<string>(json.aardVanLetselOmschrijving),
      Antwoord.fromJson<string>(json.slachtofferNaarZiekenhuis),
      ZiekenhuisAntwoorden.fromJson(json.ziekenhuis),
      Antwoord.fromJson<string>(json.slachtofferBlijvendLetsel)
    );
  }
}
