import { AutorisatieActions, CLEAR_AUTORISATIE_GEGEVENS, SET_AUTORISATIE_GEGEVENS } from "../actions/autorisatie-actions";
import { Autorisatiegegevens } from "../models/api/security/autorisatiegegevens";
import { AutorisatieState } from "../store";

const initialState: AutorisatieState = {
  autorisatiegegevens: new Autorisatiegegevens(false, false, false, false, "", false, false)
};

const autorisatieReducer = (state: AutorisatieState = initialState, action: AutorisatieActions): AutorisatieState => {
  switch (action.type) {
    case CLEAR_AUTORISATIE_GEGEVENS:
      return {
        ...state,
        autorisatiegegevens: Autorisatiegegevens.initialize()
      };
    case SET_AUTORISATIE_GEGEVENS:
      return {
        ...state,
        autorisatiegegevens: action.autorisatiegegevens
      };
    default:
      return state;
  }
};

export default autorisatieReducer;
