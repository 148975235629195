import React from "react";
import { Vraag } from "../../../../models/api";
import { VraagType } from "../../../../models/application";
import { SamenvattingFieldVraag } from "../../../samenvatting";

export class TekstMeerdereRegels {
  static getType = (): VraagType => VraagType.tekstMeerdereRegels;

  static create = (vraag: Vraag): React.JSX.Element => <SamenvattingFieldVraag vraag={vraag} />;
}
