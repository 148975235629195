import { Antwoord } from "..";
import { Vestiging } from "../extern";
import { AntwoordBlok } from "./antwoord-blok";

export class BedrijfsgegevensLavsAntwoorden extends AntwoordBlok {
  constructor(
    public kvkNummer: Antwoord<string>,
    public vestigingsnummer: Antwoord<string>,
    public vestiging: Antwoord<Vestiging | null>
  ) {
    super();
  }

  static initialize() {
    return new BedrijfsgegevensLavsAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<Vestiging | null>(null)
    );
  }

  static fromJson(json: any) {
    return new BedrijfsgegevensLavsAntwoorden(
      Antwoord.fromJson(json.kvkNummer),
      Antwoord.fromJson(json.vestigingsnummer),
      Antwoord.fromJson<Vestiging | null>({
        waarde: json.vestiging?.waarde ? Vestiging.fromMeldingJson(json.vestiging.waarde) : null
      })
    );
  }
}
