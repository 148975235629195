import { HasKlachtMelderVragen } from "./has-klacht-melder-vragen";
import { VraagBlokken, KlachtVragen, KlachtMelderVragen } from "../blok";
import { HasKlachtVragen } from "./has-klacht-vragen";

export class KlachtFormulierVraagBlokken extends VraagBlokken implements HasKlachtMelderVragen, HasKlachtVragen {
  [key: string]: any;

  constructor(public melder: KlachtMelderVragen, public klacht: KlachtVragen) {
    super();
  }

  static fromJson(json: any): KlachtFormulierVraagBlokken {
    return new KlachtFormulierVraagBlokken(
      KlachtMelderVragen.fromJson("melder", json.melder),
      KlachtVragen.fromJson("klacht", json.klacht)
    );
  }
}
