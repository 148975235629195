import { UploadRieFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getUploadRieFormulierDefinitie = (formulier: UploadRieFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.upload.titel,
      type: ProcesstapType.upload
    }
  ];
};
