import { displayAntwoordHelpers } from "helpers";
import {
  AntwoordBlok,
  AvvArtikel10AVerzoekPeriodeAntwoorden,
  AvvArtikel10AVerzoekPeriodeVragen,
  VraagBlok,
  VraagBlokCollection
} from "../../../../models/api";
import { VraagBlokCollectionType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";

export class AvvArtikel10AVerzoekPerioden {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.perioden;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => {
    return (
      <FormulierInputControls.CollectionAccordion
        itemComponent={GeneriekBlok.VraagBlok}
        getVraagBlokFromTemplate={AvvArtikel10AVerzoekPeriodeVragen.fromJson}
        initializeAntwoorden={AvvArtikel10AVerzoekPeriodeAntwoorden.initialize}
        vraagBlokCollection={vraagBlokCollection}
        getCollectionItemSummary={(antwoordBlok: AntwoordBlok, index: number) =>
          displayAntwoordHelpers.getDisplayAntwoord(antwoordBlok as AvvArtikel10AVerzoekPeriodeAntwoorden)
        }
      />
    );
  };
}
