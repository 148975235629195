import { IsGezochtItemGevonden } from "./is-gezocht-item-gevonden";

export class IsPlaatsGevonden extends IsGezochtItemGevonden {
  get operator(): string {
    return IsPlaatsGevonden.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  protected clone = (instance: IsPlaatsGevonden): IsPlaatsGevonden => new IsPlaatsGevonden(instance.vraagProperty);

  static fromJson = (json: any): IsPlaatsGevonden => new IsPlaatsGevonden(json.vraag);

  static operator = (): string => "isPlaatsGevonden";

  static matches = (json: any): boolean => json?.operator === IsPlaatsGevonden.operator();
}
