import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { KennisgevingMeldingAntwoordBlokken } from "models/api";
import { AppThunk } from "thunks/app-thunk-type";
import { initKennisgevingMeldingAntwoordenFromCopy, initializeFormulierProces } from "thunks/thunk-helpers";

export const initHandelingenIoniserendeStralingFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getHandelingenIoniserendeStralingFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getHandelingenIoniserendeStralingFormulierDefinitie,
      KennisgevingMeldingAntwoordBlokken.initialize
    );
  };

export const initHandelingenIoniserendeStralingFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getHandelingenIoniserendeStralingFormulier(),
      meldingApi.getHandelingenIoniserendeStralingMelding(meldingId)
    ]);

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getHandelingenIoniserendeStralingFormulierDefinitie,
      initKennisgevingMeldingAntwoordenFromCopy(melding.blokken)
    );
  };
