import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsValue extends VraagExpression {
  get operator(): string {
    return IsValue.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly value: any) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    return context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde === this.value;
  };

  protected clone = (instance: IsValue): IsValue => new IsValue(instance.vraagProperty, instance.value);

  static fromJson = (json: any): IsValue => new IsValue(json.vraag, json.value);

  static operator = (): string => "isValue";

  static matches = (json: any): boolean => json?.operator === IsValue.operator();
}
