import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { ReactComponent as Info } from "../../assets/icons/rijksoverheid/2002-info.svg";
import { HelpContext } from "./help-context";

const HelpText: React.FC<any> = (props) => {
  const context = React.useContext(HelpContext);

  return context.isHelpVisible ? (
    <Alert variant="info" {...props}>
      <Row>
        <Col className="icon">
          <Info role="presentation" />
        </Col>
        <Col>
          <span role="alert">
            <ReactMarkdown children={props.helptekst}></ReactMarkdown>
          </span>
        </Col>
      </Row>
    </Alert>
  ) : null;
};

export { HelpText };

