import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class KindGegevensVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public heeftKindBsn: Vraag,
    public bsn: Vraag,
    public kindVolgnummer: Vraag,
    public geboorteDatum: Vraag,
    public roepnaam: Vraag,
    public tussenvoegsels: Vraag,
    public achternaam: Vraag,
    public bsnIsGeldig: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any, volgnummer?: number): KindGegevensVragen {
    return new KindGegevensVragen(
      key,
      volgnummer ?? json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.heeftKindBsn`, json.heeftKindBsn),
      jsonHelpers.toTekstVraag(`${key}.bsn`, json.bsn),
      jsonHelpers.toTekstVraag(`${key}.kindVolgnummer`, json.kindVolgnummer),
      jsonHelpers.toDatumVraag(`${key}.geboorteDatum`, json.geboorteDatum),
      jsonHelpers.toTekstVraag(`${key}.roepnaam`, json.roepnaam),
      jsonHelpers.toTekstVraag(`${key}.tussenvoegsels`, json.tussenvoegsels),
      jsonHelpers.toTekstVraag(`${key}.achternaam`, json.achternaam),
      jsonHelpers.toTekstVraag(`${key}.bsnIsGeldig`, json.bsnIsGeldig)
    );
  }
}
