import moment from "moment";
import { TimeExpressionBase } from "./time-expression-base";

export class IsEarlierThan extends TimeExpressionBase {
  get operator(): string {
    return IsEarlierThan.operator();
  }

  constructor(public readonly vraagProperty: string, protected readonly tijd: string) {
    super(vraagProperty, tijd);
  }

  protected DateCompare = (tijdAntwoord: moment.Moment, tijdCompare: moment.Moment): boolean => tijdAntwoord < tijdCompare;

  protected clone = (instance: IsEarlierThan): IsEarlierThan => new IsEarlierThan(instance.vraagProperty, instance.tijd);

  static fromJson = (json: any): IsEarlierThan => new IsEarlierThan(json.vraag, json.tijd);

  static operator = (): string => "isEarlierThan";

  static matches = (json: any): boolean => json?.operator === IsEarlierThan.operator();
}
