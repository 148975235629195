import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AnoniemMelderAntwoorden } from "./anoniem-melder-antwoorden";
import { AntwoordBlok } from "./antwoord-blok";

export class ProductveiligheidMelderAntwoorden extends AntwoordBlok {
  constructor(
    public anoniemMelder: AnoniemMelderAntwoorden | null,
    public relatieTovProductMelding: Antwoord<string>,
    public relatieTovProductMeldingOverig: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): ProductveiligheidMelderAntwoorden {
    return new ProductveiligheidMelderAntwoorden(
      AnoniemMelderAntwoorden.initialize(user),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): ProductveiligheidMelderAntwoorden {
    return new ProductveiligheidMelderAntwoorden(
      AnoniemMelderAntwoorden.fromJson(json.anoniemMelder),
      Antwoord.fromJson(json.relatieTovProductMelding),
      Antwoord.fromJson(json.relatieTovProductMeldingOverig)
    );
  }
}
