import { Profiel } from "components/blok";
import { ProfielFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getProfielFormulierDefinitie = (formulier: ProfielFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.profiel.titel,
    type: ProcesstapType.profiel,
    // Profiel vraagblok is niet generiek door een opslaan gelukt melding
    vraagBlok: Profiel
  }
];
