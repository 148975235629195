import React from "react";
import { Tekst } from "../../../../models/api";
import { TekstType } from "../../../../models/application";
import { FormulierReadOnlyControls } from "../../../formulier";

export class Waarschuwing {
  static getType = (): TekstType => TekstType.waarschuwing;

  static create = (tekst: Tekst): React.JSX.Element => <FormulierReadOnlyControls.Text item={tekst} className="warning" />;
}
