import { Order } from "../store";
import { MeldingenState } from "../store/meldingen-state";
import { MeldingenActions, SET_API_ERROR, SET_MELDINGEN } from "./../actions";
import { SET_MELDINGEN_FILTER, SET_MELDINGEN_FORMULIEREN, SET_MELDINGEN_LOADING } from "./../actions/meldingen-actions";

const initialState = {
  meldingen: [],
  totaalAantalMeldingen: 0,
  hasApiError: false,
  isLoading: true,
  sortOption: { columnName: "meldingnummer", order: Order.descending },
  pageNumber: 1,
  pageSize: 30,
  formulierTypes: []
};

const meldingenReducer = (state: MeldingenState = initialState, action: MeldingenActions): MeldingenState => {
  switch (action.type) {
    case SET_MELDINGEN_FORMULIEREN:
      return {
        ...state,
        formulierTypes: action.data
      };
    case SET_MELDINGEN_FILTER:
      return {
        ...state,
        sortOption: action.sortOption,
        pageNumber: action.pageNumber
      };
    case SET_MELDINGEN_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasApiError: action.hasApiError
      };
    case SET_API_ERROR:
      let newApiError;
      if (!state.apiError) {
        newApiError = action.apiError;
      } else if (state.apiError.includes(action.apiError)) {
        newApiError = state.apiError;
      } else {
        newApiError = `${state.apiError};${action.apiError}`;
      }
      return {
        ...state,
        apiError: newApiError
      };
    case SET_MELDINGEN:
      return {
        ...state,
        meldingen: action.meldingen,
        totaalAantalMeldingen: action.totaalAantalMeldingen,
        apiError: undefined
      };
    default:
      return state;
  }
};

export default meldingenReducer;
