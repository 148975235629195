import { useField } from "formik";
import { ReactElement } from "react";
import { InputOptionBase } from "../../input-option-base";
import { InputCheckOptionProps } from "../input-check-option/input-check-option.interfaces";

export const InputCheckOption = (props: InputCheckOptionProps): ReactElement => {
  const [field] = useField(props);

  return <InputOptionBase type="checkbox" checked={field.value?.includes(props.optionValue) ?? false} {...props} />;
};
