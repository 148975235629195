import { Reducer } from "react";
import { AnyAction, combineReducers } from "redux";
import antwoordenReducer from "./antwoorden-reducer";
import autorisatieReducer from "./autorisatie-reducer";
import beheerReducer from "./beheer-reducer";
import bestandImportBlokReducer from "./bestand-import-blok-reducer";
import bestandenReducer from "./bestanden-blok-reducer";
import landenReducer from "./blok/landen-reducer";
import nederlandsAdresReducer from "./blok/nederlands-adres-reducer";
import vestigingenReducer from "./blok/vestigingen-reducer";
import breadcrumbsReducer from "./breadcrumbs-reducer";
import brpReducer from "./brp-reducer";
import captchaReducer from "./captcha-reducer";
import collectionReducer from "./collections-reducer";
import digipassReducer from "./digipass-reducer";
import externeReferentieReducer from "./externe-referentie-reducer";
import mededelingReducer from "./mededeling-reducer";
import meldingReducer from "./melding-reducer";
import meldingenReducer from "./meldingen-reducer";
import navigationReducer from "./navigation-reducer";
import procesReducer from "./proces-reducer";
import profielReducer from "./profiel-reducer";
import searchReducer from "./search-reducer";
import securityReducer from "./security-reducer";
import taalReducer from "./taal-reducer";
import userActivityReducer from "./user-activity-reducer";
import validationReducer from "./validation-reducer";
import vragenReducer from "./vragen-reducer";
import zoekMeldingenReducer from "./zoek-meldingen-reducer";

export const createRootReducer = (routerReducer: Reducer<any, AnyAction>): Reducer<any, AnyAction> => {
  return combineReducers({
    router: routerReducer,
    antwoorden: antwoordenReducer,
    autorisatie: autorisatieReducer,
    beheer: beheerReducer,
    mededeling: mededelingReducer,
    bestanden: bestandenReducer,
    bestandImport: bestandImportBlokReducer,
    brp: brpReducer,
    nederlandsAdres: nederlandsAdresReducer,
    landen: landenReducer,
    breadcrumbs: breadcrumbsReducer,
    vestigingen: vestigingenReducer,
    melding: meldingReducer,
    meldingen: meldingenReducer,
    navigation: navigationReducer,
    proces: procesReducer,
    security: securityReducer,
    taal: taalReducer,
    vragen: vragenReducer,
    userActivity: userActivityReducer,
    validation: validationReducer,
    profiel: profielReducer,
    digipass: digipassReducer,
    search: searchReducer,
    captcha: captchaReducer,
    collections: collectionReducer,
    zoekMeldingen: zoekMeldingenReducer,
    externeReferentie: externeReferentieReducer
  });
};
