import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class AutorisatiesZoekenAntwoorden extends AntwoordBlok {
  constructor(
    public bedrijfsnaam: Antwoord<string>,
    public gebruikersnaam: Antwoord<string>,
    public achternaam: Antwoord<string>,
    public kvkNummer: Antwoord<string>,
    public vestigingsnummer: Antwoord<string>,
    public geldig: Antwoord<string>
  ) {
    super();
  }

  static initialize(): AutorisatiesZoekenAntwoorden {
    return new AutorisatiesZoekenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): AutorisatiesZoekenAntwoorden {
    return new AutorisatiesZoekenAntwoorden(
      Antwoord.fromJson<string>(json.bedrijfsnaam),
      Antwoord.fromJson<string>(json.gebruikersnaam),
      Antwoord.fromJson<string>(json.achternaam),
      Antwoord.fromJson<string>(json.kvkNummer),
      Antwoord.fromJson<string>(json.vestigingsnummer),
      Antwoord.fromJson<string>(json.geldig)
    );
  }
}
