import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { RedenAanvraagVragen } from "./reden-aanvraag-vragen";
import { VluchtigeOrganischeStoffenWerkzaamhedenVragen } from "./vluchtige-organische-stoffen-werkzaamheden-vragen";

export class VluchtigeOrganischeStoffenAanvraagVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public titel: Dictionary<string>,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public redenAanvraag: RedenAanvraagVragen,
    public werkzaamheden: VluchtigeOrganischeStoffenWerkzaamhedenVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): VluchtigeOrganischeStoffenAanvraagVragen {
    return new VluchtigeOrganischeStoffenAanvraagVragen(
      key,
      json.volgnummer,
      json.titel,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      RedenAanvraagVragen.fromJson(`${key}.redenAanvraag`, json.redenAanvraag),
      VluchtigeOrganischeStoffenWerkzaamhedenVragen.fromJson(`${key}.werkzaamheden`, json.werkzaamheden)
    );
  }
}
