import { Autorisatiegegevens } from "../security/autorisatiegegevens";
import { AutorisatiegegevensExpression } from "./autorisatiegegevens-expression";

export class IsExternalBedrijf extends AutorisatiegegevensExpression {
  get operator(): string {
    return IsExternalBedrijf.operator();
  }

  executeAutorisation(autorisatiegegevens: Autorisatiegegevens): boolean {
    return autorisatiegegevens.isExternalLogin && autorisatiegegevens.isBedrijf;
  }

  static fromJson = (): IsExternalBedrijf => new IsExternalBedrijf();

  static operator = (): string => "isExternalBedrijf";

  static matches = (json: any): boolean => json?.operator === IsExternalBedrijf.operator();
}
