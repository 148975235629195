import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { KennisgevingFormulierVraagBlokken } from "./kennisgeving-formulier-vraag-blokken";

export class AanwijzingRisicovolleBedrijvenFormulier extends FormulierBase {
  constructor(public blokken: KennisgevingFormulierVraagBlokken, json: any) {
    super(Formuliertype.aanwijzingRisicovolleBedrijven, blokken, json);
  }

  static fromJson(json: any): AanwijzingRisicovolleBedrijvenFormulier {
    return new AanwijzingRisicovolleBedrijvenFormulier(KennisgevingFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
