import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class TeVerwijderenAsbestMateriaalRisicoklasse1Vragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public risicoklasse: Vraag,
    public hoeveelheid: Vraag,
    public eenheid: Meerkeuzevraag,
    public soortMateriaal: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): TeVerwijderenAsbestMateriaalRisicoklasse1Vragen {
    return new TeVerwijderenAsbestMateriaalRisicoklasse1Vragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTekstVraag(`${key}.risicoklasse`, json.risicoklasse),
      jsonHelpers.toTekstVraag(`${key}.hoeveelheid`, json.hoeveelheid),
      jsonHelpers.toMeerkeuzeVraag(`${key}.eenheid`, json.eenheid),
      jsonHelpers.toTekstVraag(`${key}.soortMateriaal`, json.soortMateriaal)
    );
  }
}
