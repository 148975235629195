import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsDecimal extends VraagExpression {
  get operator(): string {
    return IsDecimal.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;

    return !isNaN(+antwoord);
  };

  protected clone = (instance: IsDecimal): IsDecimal => new IsDecimal(instance.vraagProperty);

  static fromJson = (json: any): IsDecimal => new IsDecimal(json.vraag);

  static operator = (): string => "isDecimal";

  static matches = (json: any): boolean => json?.operator === IsDecimal.operator();
}
