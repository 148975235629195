import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";
import { OpdrachtgeverVragen } from "./opdrachtgever-vragen";
import { ParticuliereOpdrachtgeverVragen } from "./particuliere-opdrachtgever-vragen";

export class OpdrachtgeverOntheffingLiftenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public gegevensGelijk: Meerkeuzevraag,
    public bedrijfOfParticulier: Meerkeuzevraag,
    public opdrachtgever: OpdrachtgeverVragen,
    public particuliereOpdrachtgever: ParticuliereOpdrachtgeverVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): OpdrachtgeverOntheffingLiftenVragen {
    return new OpdrachtgeverOntheffingLiftenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toMeerkeuzeVraag(`${key}.gegevensGelijk`, json.gegevensGelijk),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bedrijfOfParticulier`, json.bedrijfOfParticulier),
      OpdrachtgeverVragen.fromJson(`${key}.opdrachtgever`, json.opdrachtgever),
      ParticuliereOpdrachtgeverVragen.fromJson(`${key}.particuliereOpdrachtgever`, json.particuliereOpdrachtgever)
    );
  }
}
