import { connect } from "react-redux";
import { ReactieOpenbaarmakingFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getReactieOpenbaarmakingFormulierMelding,
  initReactieOpenbaarmakingFormulier,
  initReactieOpenbaarmakingFormulierFromCopy
} from "../../../thunks";
import { ReactieOpenbaarmakingFormulierComponent } from "./reactie-openbaarmaking-formulier.component";
import {
  ReactieOpenbaarmakingFormulierComponentDispatchProps,
  ReactieOpenbaarmakingFormulierComponentStateProps
} from "./reactie-openbaarmaking-formulier.component.interfaces";

const mapStateToProps = (state: State): ReactieOpenbaarmakingFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.reactieOpenbaarmaking
      ? (state.vragen.formulier as ReactieOpenbaarmakingFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): ReactieOpenbaarmakingFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initReactieOpenbaarmakingFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getReactieOpenbaarmakingFormulierMelding(meldingId));
    } else {
      dispatch(initReactieOpenbaarmakingFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReactieOpenbaarmakingFormulierComponent);
