import { GelinkteMeerkeuze } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/gelinkte-meerkeuze";
import { jsonHelpers } from "helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";

export class SaneringVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public asbesttype: GelinkteMeerkeuze,
    public asbestverwijderingsbedrijfGecertificeerd: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): SaneringVragen {
    return new SaneringVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toGelinkteMeerkeuzeVraag(`${key}.asbesttype`, json.asbesttype),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.asbestverwijderingsbedrijfGecertificeerd`,
        json.asbestverwijderingsbedrijfGecertificeerd
      )
    );
  }
}
