export enum VraagBlokCollectionType {
  unknown = "unknown",
  illegaleWerknemers = "illegaleWerknemers",
  aannemers = "aannemers",
  aanvragen = "aanvragen",
  getuigen = "getuigen",
  slachtoffers = "slachtoffers",
  andereWerkgevers = "andereWerkgevers",
  locatiesWerkzaamheden = "locatiesWerkzaamheden",
  kindGegevens = "kindGegevens",
  werkmomentGegevens = "werkmomentGegevens",
  materiaal = "materiaal",
  gegevensInstallaties = "gegevensInstallaties",
  vestigingen = "vestigingen",
  periode = "periode",
  perioden = "perioden"
}
