import { Formuliercategorie } from "../../application/formulier/formuliercategorie";

export class MeldingFilters {
  constructor(
    public formuliercategorie: Formuliercategorie | null,
    public formuliertypen: string[] | null,
    public meldingnummer: string | null,
    public postcode: string | null,
    public plaats: string | null,
    public ingediendVanaf: Date | null,
    public ingediendTotEnMet: Date | null,
    public activiteitVanaf: Date | null,
    public activiteitTotEnMet: Date | null
  ) {}
}
