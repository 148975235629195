import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { PeriodeDatumVragen } from "./periode-datum-vragen";

export class InformatieverzoekVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public omschrijvingVerzoek: Vraag,
    public soortInformatieverzoek: Vraag,
    public soortInformatieverzoekOverig: Vraag,
    public soortInformatie: Vraag,
    public soortInformatieOverig: Vraag,
    public periode: PeriodeDatumVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): InformatieverzoekVragen {
    return new InformatieverzoekVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijvingVerzoek`, json.omschrijvingVerzoek),
      jsonHelpers.toMeerkeuzeVraag(`${key}.soortInformatieverzoek`, json.soortInformatieverzoek),
      jsonHelpers.toTekstVraag(`${key}.soortInformatieverzoekOverig`, json.soortInformatieverzoekOverig),
      jsonHelpers.toChecklistvraag(`${key}.soortInformatie`, json.soortInformatie),
      jsonHelpers.toTekstVraag(`${key}.soortInformatieOverig`, json.soortInformatieOverig),
      PeriodeDatumVragen.fromJson(`${key}.periode`, json.periode)
    );
  }
}
