import { AntwoordBlokken, UploadRieAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasUploadRieAntwoorden } from "./has-upload-rie-antwoorden";

export class UploadRieMeldingAntwoordBlokken extends AntwoordBlokken implements HasUploadRieAntwoorden {
  constructor(public upload: UploadRieAntwoorden) {
    super();
  }

  static initialize(user: User | null = null): UploadRieMeldingAntwoordBlokken {
    return new UploadRieMeldingAntwoordBlokken(UploadRieAntwoorden.initialize(user));
  }

  static fromJson(json: any): UploadRieMeldingAntwoordBlokken {
    return new UploadRieMeldingAntwoordBlokken(UploadRieAntwoorden.fromJson(json.upload));
  }
}
