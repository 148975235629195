import React from "react";
import { Accordion } from "../../../accordion";
import { SamenvattingBarItemProps } from "./samenvatting-bar-item.component.interfaces";

export const SamenvattingBarItem: React.FC<SamenvattingBarItemProps> = (props) => {
  const { isVisible, item, naam, isSubmitting, onEdit, children } = props;

  if (isVisible && item) {
    return (
      <Accordion.Item eventKey={naam} title={naam} onEdit={isSubmitting ? undefined : () => onEdit(item)}>
        {children}
      </Accordion.Item>
    );
  } else {
    return null;
  }
};
