import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { PredicaatKoninklijkFormulierVraagBlokken } from "./predicaat-koninklijk-formulier-vraag-blokken";

export class PredicaatKoninklijkFormulier extends FormulierBase {
  constructor(public blokken: PredicaatKoninklijkFormulierVraagBlokken, json: any) {
    super(Formuliertype.predicaatKoninklijk, blokken, json);
  }

  static fromJson(json: any): PredicaatKoninklijkFormulier {
    return new PredicaatKoninklijkFormulier(PredicaatKoninklijkFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
