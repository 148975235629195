const extensionOffset = 2;
const getExtension = (filename: string) => {
  const ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + extensionOffset);
  return ext ? `.${ext.toLocaleLowerCase()}` : "";
};

const checkFileReadable = async (file: File): Promise<{ isReadable: boolean; error: Error }> => {
  let fileIsReadable = false;
  let error: any = null;

  const fileSlice = file.slice(0, 1);

  // If arrayBuffer method is not supported just pretend the file can be read
  if (typeof fileSlice.arrayBuffer !== "function") {
    return Promise.resolve({ isReadable: true, error: error });
  }

  await fileSlice
    .arrayBuffer() // try to read
    .then(() => {
      fileIsReadable = true;
    })
    .catch((err: Error) => {
      fileIsReadable = false;
      error = err;
    });

  return {
    isReadable: fileIsReadable,
    error: error
  };
};

export const fileHelpers = {
  getExtension,
  checkFileReadable
};
