import { connect } from "react-redux";
import { AutorisatiesZoekenFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { initAutorisatiesZoekenFormulier } from "../../../thunks";
import { AutorisatiesZoekenFormulierComponent } from "./autorisaties-zoeken-formulier.component";
import {
  AutorisatiesZoekenFormulierComponentDispatchProps,
  AutorisatiesZoekenFormulierComponentStateProps
} from "./autorisaties-zoeken-formulier.component.interfaces";

const mapStateToProps = (state: State): AutorisatiesZoekenFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.autorisatiesZoeken
      ? (state.vragen.formulier as AutorisatiesZoekenFormulier)
      : undefined;

  return {
    formulier,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AutorisatiesZoekenFormulierComponentDispatchProps => ({
  onInit: () => {
    dispatch(initAutorisatiesZoekenFormulier());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AutorisatiesZoekenFormulierComponent);
