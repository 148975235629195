import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { MelderAntwoorden } from "./melder-antwoorden";

export class KlachtMelderAntwoorden extends AntwoordBlok {
  constructor(public common: MelderAntwoorden | null) {
    super();
  }

  static initialize(user: User | null = null): KlachtMelderAntwoorden {
    return new KlachtMelderAntwoorden(MelderAntwoorden.initialize(user));
  }

  static fromJson(json: any): KlachtMelderAntwoorden {
    return new KlachtMelderAntwoorden(MelderAntwoorden.fromJson(json.common));
  }
}
