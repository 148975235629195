import React from "react";
import { useTranslation } from "react-i18next";
import { Help } from "../../help";
import { translationKeys } from "../../../constants/translation-keys";

export const InputOptionWithToelichting = (props: any) => {
  const { i18n, t } = useTranslation();

  const lang: string = i18n.language;

  const { mogelijkAntwoord } = props;

  return (
    <div key={`${mogelijkAntwoord.waarde}--help-container`}>
      <Help.Container key={`${mogelijkAntwoord.waarde}--help`}>
        {props.children}
        <Help.HelpToggle
          className="help-toggle"
          key={`${mogelijkAntwoord.waarde}--help-toggle`}
          id={`${mogelijkAntwoord.waarde}-help-toggle`}
          aria-label={t(translationKeys["help-toggle"], {
            naam: mogelijkAntwoord.waarde
          })}
        ></Help.HelpToggle>
        <Help.HelpText
          key={`${mogelijkAntwoord.waarde}--help-text`}
          helptekst={mogelijkAntwoord.toelichting[lang]}
          id={`${mogelijkAntwoord.waarde}-help`}
        ></Help.HelpText>
      </Help.Container>
    </div>
  );
};
