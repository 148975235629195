import { VraagBlok, VraagBlokCollection, VraagBlokCollectionVerplichtType } from "models/api";

/** Label helper*/
export const getVraagCollectionLabel = <TVraagBlok extends VraagBlok>(
  vraagBlokCollection: VraagBlokCollection<TVraagBlok>,
  taal: string
) =>
  vraagBlokCollection.verplichtType !== VraagBlokCollectionVerplichtType.NietVerplicht
    ? `${vraagBlokCollection.titel[taal]} *`
    : `${vraagBlokCollection.titel[taal]}`;
