import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class SociaalMaatschappelijkeOmstandighedenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public sociaalMaatschappelijkeOmstandighedenTitel: Tekst,
    public sociaalMaatschappelijkeOmstandighedenToelichting: Tekst,
    public welkeMaatschappelijkePriveOmstandigheden: Vraag,
    public waaromNachtarbeidNoodzakelijk: Vraag,
    public isPermanenteNachtarbeid: Meerkeuzevraag,
    public datumTotWanneerNachtarbeid: Vraag,
    public gezinsSamenstelling: Vraag,
    public periodeWerkurenPartner: Meerkeuzevraag,
    public hoeveelUurWerktPartner: Vraag,
    public financieleRedenenVoorAanvraag: Meerkeuzevraag,
    public hoeveelJarenWerkTotPensioen: Vraag,
    public gevolgenGezinBijGeenOntheffing: Vraag,
    public watGedaanVoorGezondWerkrooster: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): SociaalMaatschappelijkeOmstandighedenVragen {
    return new SociaalMaatschappelijkeOmstandighedenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(
        `${key}.sociaalMaatschappelijkeOmstandighedenTitel`,
        json.sociaalMaatschappelijkeOmstandighedenTitel
      ),
      jsonHelpers.toParagraaf(
        `${key}.sociaalMaatschappelijkeOmstandighedenToelichting`,
        json.sociaalMaatschappelijkeOmstandighedenToelichting
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(
        `${key}.welkeMaatschappelijkePriveOmstandigheden`,
        json.welkeMaatschappelijkePriveOmstandigheden
      ),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.waaromNachtarbeidNoodzakelijk`, json.waaromNachtarbeidNoodzakelijk),
      jsonHelpers.toMeerkeuzeVraag(`${key}.isPermanenteNachtarbeid`, json.isPermanenteNachtarbeid),
      jsonHelpers.toDatumVraag(`${key}.datumTotWanneerNachtarbeid`, json.datumTotWanneerNachtarbeid),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.gezinsSamenstelling`, json.gezinsSamenstelling),
      jsonHelpers.toMeerkeuzeVraag(`${key}.periodeWerkurenPartner`, json.periodeWerkurenPartner),
      jsonHelpers.toNumeriekeVraag(`${key}.hoeveelUurWerktPartner`, json.hoeveelUurWerktPartner),
      jsonHelpers.toMeerkeuzeVraag(`${key}.financieleRedenenVoorAanvraag`, json.financieleRedenenVoorAanvraag),
      jsonHelpers.toNumeriekeVraag(`${key}.hoeveelJarenWerkTotPensioen`, json.hoeveelJarenWerkTotPensioen),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.gevolgenGezinBijGeenOntheffing`, json.gevolgenGezinBijGeenOntheffing),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.watGedaanVoorGezondWerkrooster`, json.watGedaanVoorGezondWerkrooster)
    );
  }
}
