import React from "react";
import { bijlageApi } from "../../api";
import { DownloadBijlageLinkOwnProps } from "./download-bijlage-link.component.interfaces";

export const DownloadBijlageLink = (props: DownloadBijlageLinkOwnProps) => {
  const { bijlageId, bijlageNaam, children } = props;

  return (
    <a href={bijlageApi.getDownloadBijlageUrl(bijlageId)} target="_blank" rel="noopener noreferrer" download={bijlageNaam}>
      {children}
    </a>
  );
};
