import { ValidationFailure } from "models/api";

export const SET_FILE = "SET_FILE";
export const IMPORT_FILE = "IMPORT_FILE";
export const SET_IMPORT_FILE_RESULT = "SET_IMPORT_FILE_RESULT";
export const CLEAR_IMPORT_FILE_STATE = "CLEAR_IMPORT_FILE_STATE";

interface SetBestandAction {
  type: typeof SET_FILE;
  data: File;
}

interface ImportBestandAction {
  type: typeof IMPORT_FILE;
  data: File;
}

interface SetImportFileResultAction {
  type: typeof SET_IMPORT_FILE_RESULT;
  errors: ValidationFailure[];
  result: string | null;
}

interface ClearImportFileStateAction {
  type: typeof CLEAR_IMPORT_FILE_STATE;
}

export type BestandImportActions = SetBestandAction | SetImportFileResultAction | ClearImportFileStateAction;

export const setBestand = (data: File): SetBestandAction => {
  return {
    type: SET_FILE,
    data: data
  };
};

export const importBestand = (data: File): ImportBestandAction => {
  return {
    type: IMPORT_FILE,
    data: data
  };
};

export const setImportFileResult = (errors: ValidationFailure[], result: string | null): SetImportFileResultAction => {
  return {
    type: SET_IMPORT_FILE_RESULT,
    errors: errors,
    result: result
  };
};

export const clearImportFileState = (): ClearImportFileStateAction => {
  return {
    type: CLEAR_IMPORT_FILE_STATE
  };
};
