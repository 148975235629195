import { Moment } from "moment";
import { IsAgeAtExpression } from "./is-age-at-expression";

export class IsMinimumAgeAt extends IsAgeAtExpression {
  get operator(): string {
    return IsMinimumAgeAt.operator();
  }

  protected executeCompare = (date: Moment, atDate: Moment): boolean => date <= atDate.subtract(this.age + 1, "year");

  protected clone = (instance: IsMinimumAgeAt): IsMinimumAgeAt =>
    new IsMinimumAgeAt(instance.vraagProperty, instance.otherVraagProperty, instance.age);

  static fromJson = (json: any): IsMinimumAgeAt => new IsMinimumAgeAt(json.vraag, json.otherVraagDatumTijd, json.age);

  static operator = (): string => "isMinimumAgeAt";

  static matches = (json: any): boolean => json?.operator === IsMinimumAgeAt.operator();
}
