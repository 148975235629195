import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class PersoonsgegevensAntwoorden extends AntwoordBlok {
  constructor(
    public voorletters: Antwoord<string>,
    public tussenvoegsels: Antwoord<string>,
    public achternaam: Antwoord<string>,
    public geslacht: Antwoord<string>,
    public functie: Antwoord<string>
  ) {
    super();
  }

  static initialize(): PersoonsgegevensAntwoorden {
    return new PersoonsgegevensAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): PersoonsgegevensAntwoorden {
    return new PersoonsgegevensAntwoorden(
      Antwoord.fromJson<string>(json.voorletters),
      Antwoord.fromJson<string>(json.tussenvoegsels),
      Antwoord.fromJson<string>(json.achternaam),
      Antwoord.fromJson<string>(json.geslacht),
      Antwoord.fromJson<string>(json.functie)
    );
  }
}
