import { MeldingListItem } from "../meldingen/melding-list-item";

export class SearchMeldingenResponse {
  constructor(public meldingen: MeldingListItem[], public totalItemCount: number) {}

  static fromJson(json: any): SearchMeldingenResponse {
    return new SearchMeldingenResponse(
      json.meldingen.map((m: MeldingListItem) => MeldingListItem.fromJson(m)),
      json.totalItemCount
    );
  }
}
