import { Tekst } from "../../../components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { ContactpersoonVragen } from "./contactpersoon-vragen";
import { CorrespondentieadresVragen } from "./correspondentieadres-vragen";

export class ParticuliereOpdrachtgeverVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public particuliereOpdrachtgeverTitel: Tekst,
    public contactpersoon: ContactpersoonVragen,
    public correspondentieadres: CorrespondentieadresVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ParticuliereOpdrachtgeverVragen {
    return new ParticuliereOpdrachtgeverVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.particuliereOpdrachtgeverTitel`, json.particuliereOpdrachtgeverTitel),
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon),
      CorrespondentieadresVragen.fromJson(`${key}.correspondentieadres`, json.correspondentieadres)
    );
  }
}
