import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class GetuigenInformatieAntwoorden extends AntwoordBlok {
  constructor(
    public contactpersoon: ContactpersoonAntwoorden | null,
    public adresgegevens: CorrespondentieadresAntwoorden | null,
    public geboorteDatum: Antwoord<string>,
    public datumInDienst: Antwoord<string>,
    public relatieTovSlachtoffer: Antwoord<string>,
    public andereRelatie: Antwoord<string>
  ) {
    super();
  }

  static initialize(): GetuigenInformatieAntwoorden {
    return new GetuigenInformatieAntwoorden(
      ContactpersoonAntwoorden.initialize(),
      CorrespondentieadresAntwoorden.initialize(),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): GetuigenInformatieAntwoorden {
    return new GetuigenInformatieAntwoorden(
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      CorrespondentieadresAntwoorden.fromJson(json.adresgegevens),
      Antwoord.fromJson<string>(json.geboorteDatum),
      Antwoord.fromJson<string>(json.datumInDienst),
      Antwoord.fromJson<string>(json.relatieTovSlachtoffer),
      Antwoord.fromJson<string>(json.andereRelatie)
    );
  }
}
