import { AntwoordBlokken, AvvArtikel10MeldingAntwoorden, BijlageAntwoorden, MelderAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasAvvArtikel10MeldingAntwoorden } from "./has-avv-artikel10-melding-antwoorden";
import { HasBijlageAntwoorden } from "./has-bijlage-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";

export class AvvArtikel10MeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasMelderAntwoorden, HasBijlageAntwoorden, HasAvvArtikel10MeldingAntwoorden
{
  constructor(
    public melding: AvvArtikel10MeldingAntwoorden,
    public melder: MelderAntwoorden,
    public bijlage: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): AvvArtikel10MeldingAntwoordBlokken {
    return new AvvArtikel10MeldingAntwoordBlokken(
      AvvArtikel10MeldingAntwoorden.initialize(),
      MelderAntwoorden.initialize(user),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AvvArtikel10MeldingAntwoordBlokken {
    return new AvvArtikel10MeldingAntwoordBlokken(
      AvvArtikel10MeldingAntwoorden.fromJson(json.melding),
      MelderAntwoorden.fromJson(json.melder),
      BijlageAntwoorden.fromJson(json.bijlage)
    );
  }
}
