import { connect } from "react-redux";
import { userHelpers } from "../../helpers";
import { State } from "../../store";
import { FormulierGewijzigd } from "./formulier-gewijzigd.component";
import { FormulierGewijzigdStateProps } from "./formulier-gewijzigd.component.interfaces";

const mapStateToProps = (state: State): FormulierGewijzigdStateProps => ({
  isInterneMedewerker: userHelpers.getIsInternalUser(state.security.user)
});

export default connect(mapStateToProps)(FormulierGewijzigd);
