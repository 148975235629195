import { AntwoordBlokken, DigipassAntwoorden } from "../blok";
import { HasDigipassAntwoorden } from "./has-digipass-antwoorden";

export class DigipassMeldingAntwoordBlokken extends AntwoordBlokken implements HasDigipassAntwoorden {
  constructor(public digipass: DigipassAntwoorden) {
    super();
  }

  static initialize(): DigipassMeldingAntwoordBlokken {
    return new DigipassMeldingAntwoordBlokken(DigipassAntwoorden.initialize());
  }

  static fromJson(json: any): DigipassMeldingAntwoordBlokken {
    return new DigipassMeldingAntwoordBlokken(DigipassAntwoorden.fromJson(json.digipass));
  }
}
