import { v4 as uuidv4 } from "uuid";
import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export abstract class AntwoordBlokCollectionItem extends AntwoordBlok {
  public id: string;
  public idAntwoord: Antwoord<string>;

  constructor(public type: string, json?: any) {
    super(json);

    this.id = json?.id ?? uuidv4();
    this.idAntwoord = new Antwoord<string>(this.id);
  }

  static copyAsNewInstance(json: any): AntwoordBlokCollectionItem {
    return { ...json, id: undefined, idAntwoord: undefined, isIngediend: false };
  }
}
