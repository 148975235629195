import { useFormikContext } from "formik";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";
import { Spinner } from "../../spinner";
import { Field } from "../field";
import { InputBaseTypeaheadProps } from "./input-typeahead.interfaces";

export const InputBaseTypeahead = (props: InputBaseTypeaheadProps) => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const { name, value, touched, error, onBlur, onSelect, onChange, loading, component, autoComplete, ...propsRest } = props;

  const isInvalid = touched && error ? true : false;

  const getSelectedOptionFromValue = (selectedValue: any): any => {
    const selectedItem = props.options.find((option: any) => {
      if (props.valueKey && option[props.valueKey] === selectedValue) {
        return option;
      }
      return null;
    });

    return selectedItem ?? selectedValue ?? "";
  };

  const getSelectedOptionFromLabel = (selectedLabel: string): any => {
    const selectedItem = props.options.find((option: any) => {
      if (props.labelKey && option[props.labelKey] === selectedLabel) {
        return option;
      }
      return null;
    });
    return selectedItem ?? selectedLabel ?? "";
  };

  const onSelectedOptionChange = (selected: any[]) => {
    if (selected.length === 1) {
      const selectedItem = selected[0];
      let selectedValue = selectedItem;
      if (props.valueKey && _.has(selectedItem, props.valueKey)) {
        selectedValue = selectedItem[props.valueKey];
      }
      setFieldValue(name, selectedValue);
      if (onSelect) {
        onSelect(selectedItem);
      }
    }
  };

  const selectedOption = getSelectedOptionFromValue(value);

  return (
    <Field name={name} {...propsRest}>
      {React.createElement(component, {
        id: `${name}__typeahead`,
        onChange: onSelectedOptionChange,
        onInputChange: (text: string, event: Event) => {
          setFieldValue(name, text.substring(0, props.maxLength ?? text.length));
          onChange(event);

          const selectedWithTab = getSelectedOptionFromLabel(text.substring(0, props.maxLength ?? text.length));
          onSelectedOptionChange([selectedWithTab]);
        },
        isInvalid: isInvalid,
        inputProps: {
          id: name,
          name: name,
          autoComplete: autoComplete || "off"
        },
        useCache: false,
        onBlur: (e: Event) => onBlur(e),
        selected: [selectedOption],
        emptyLabel: t(translationKeys.geenResultatenGevonden),
        ...propsRest,
        children: loading && (
          <div className="typeahead-children">
            <Spinner />
          </div>
        )
      })}
    </Field>
  );
};
