import { FieldInputProps, FieldMetaProps, getIn, setIn, useFormikContext } from "formik";
import { useEffect } from "react";
import { objectHelpers } from "../helpers";
import { ValidationFailure } from "../models/api";
import { usePrevious } from "./use-previous";

export function useErrorHandler(field: FieldInputProps<any>, meta: FieldMetaProps<any>) {
  const { submitCount, status, setStatus } = useFormikContext();
  const prevValue = usePrevious(field.value);

  const serverError: ValidationFailure | undefined = getIn(status, field.name);

  const showServerError = Boolean(serverError);

  // the client side validation errors should be shown, if the field was
  // touched or if the user tried to submit the form
  const showError = Boolean(!showServerError && meta.error && (meta.touched || submitCount));

  // reset server side validation error on change
  useEffect(() => {
    if (serverError && field.value !== prevValue) {
      let newStatus = setIn(status, field.name, undefined);

      // also reset any related validation failures for other fields
      serverError.relatedProperties?.forEach((p) => {
        newStatus = objectHelpers.setValue(newStatus, undefined, p);
      });

      setStatus(newStatus);
    }
  }, [serverError, field.value, prevValue, setStatus, status, field.name]);

  return [showError, showServerError, serverError?.message] as const;
}
