/* NOTE: Do not sort these import statements! This causes the IE11 polyfill to malfunction. */

import { config } from "@fortawesome/fontawesome-svg-core";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import { Configuration } from "configuration";
import { Suspense } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.container";
import i18n from "./loadi18n";
import { store } from "./store";
import "./styles/style.scss";
const appVersion = Configuration.app.version;
console.info(`Webportaal.Frontend application version ${appVersion}`);

i18n();

const exposeStore = true;

if (exposeStore) {
  (window as any).store = store;
}

config.autoAddCss = false;
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
);
