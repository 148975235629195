import { connect } from "react-redux";
import { OntheffingKinderarbeidFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { initOntheffingKinderarbeidFormulier, initOntheffingKinderarbeidFormulierFromCopy } from "../../../thunks";
import { getOntheffingKinderarbeidFormulierMelding } from "../../../thunks/melding-thunks";
import { OntheffingKinderarbeidFormulierComponent } from "./ontheffing-kinderarbeid-formulier.component";
import {
  OntheffingKinderarbeidFormulierComponentDispatchProps,
  OntheffingKinderarbeidFormulierComponentStateProps
} from "./ontheffing-kinderarbeid-formulier.component.interfaces";

const mapStateToProps = (state: State): OntheffingKinderarbeidFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.ontheffingKinderarbeid
      ? (state.vragen.formulier as OntheffingKinderarbeidFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): OntheffingKinderarbeidFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initOntheffingKinderarbeidFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getOntheffingKinderarbeidFormulierMelding(meldingId));
    } else {
      dispatch(initOntheffingKinderarbeidFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OntheffingKinderarbeidFormulierComponent);
