import { connect } from "react-redux";
import { BouwprocesFormulier } from "../../../models/api/formulier/bouwproces-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getBouwprocesMelding, initBouwprocesFormulier, initBouwprocesFormulierFromCopy } from "../../../thunks";
import { BouwprocesFormulierComponent } from "./bouwproces-formulier.component";
import {
  BouwprocesFormulierComponentDispatchProps,
  BouwprocesFormulierComponentStateProps
} from "./bouwproces-formulier.component.interfaces";

const mapStateToProps = (state: State): BouwprocesFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.bouwproces ? (state.vragen.formulier as BouwprocesFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): BouwprocesFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initBouwprocesFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getBouwprocesMelding(meldingId));
    } else {
      dispatch(initBouwprocesFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BouwprocesFormulierComponent);
