import { ContactpersoonVragen } from ".";
import { Tekst, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";
import { BedrijfsgegevensLavsVragen } from "./bedrijfsgegevens-lavs-vragen";

export class OpdrachtgeverLavsVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public datumSloopmelding: Vraag,
    public codeSloopmelding: Vraag,
    public bedrijfsgegevens: BedrijfsgegevensLavsVragen,
    public contactpersoonTitel: Tekst,
    public contactpersoon: ContactpersoonVragen,
    public scaCode: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): OpdrachtgeverLavsVragen {
    return new OpdrachtgeverLavsVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toDatumTijdVraag(`${key}.datumSloopmelding`, json.datumSloopmelding),
      jsonHelpers.toTekstVraag(`${key}.codeSloopmelding`, json.codeSloopmelding),
      BedrijfsgegevensLavsVragen.fromJson(`${key}.bedrijfsgegevens`, json.bedrijfsgegevens),
      jsonHelpers.toTitel(`${key}.contactpersoonTitel`, json.contactpersoonTitel),
      ContactpersoonVragen.fromJson(`${key}.contactpersoon`, json.contactpersoon),
      jsonHelpers.toTekstVraag(`${key}.scaCode`, json.scaCode)
    );
  }
}
