import {
  HasAsbestinventarisatieVragen,
  HasAsbestlocatieVragen,
  HasAsbestverwijderingWerkzaamhedenVragen,
  HasMelderVragen,
  HasSaneringVragen,
  HasSloopmeldingVragen
} from ".";
import {
  AsbestinventarisatieVragen,
  AsbestlocatieVragen,
  AsbestverwijderingWerkzaamhedenVragen,
  MelderVragen,
  SaneringVragen,
  SloopmeldingVragen,
  VraagBlokken
} from "../blok";

export class AsbestverwijderingFormulierVraagBlokken
  extends VraagBlokken
  implements
    HasSaneringVragen,
    HasMelderVragen,
    HasSloopmeldingVragen,
    HasAsbestinventarisatieVragen,
    HasAsbestlocatieVragen,
    HasAsbestverwijderingWerkzaamhedenVragen
{
  [key: string]: any;

  constructor(
    public sanering: SaneringVragen,
    public melder: MelderVragen,
    public sloopmelding: SloopmeldingVragen,
    public inventarisatie: AsbestinventarisatieVragen,
    public locatie: AsbestlocatieVragen,
    public werkzaamheden: AsbestverwijderingWerkzaamhedenVragen
  ) {
    super();
  }

  static fromJson(json: any): AsbestverwijderingFormulierVraagBlokken {
    return new AsbestverwijderingFormulierVraagBlokken(
      SaneringVragen.fromJson("sanering", json.sanering),
      MelderVragen.fromJson("melder", json.melder),
      SloopmeldingVragen.fromJson("sloopmelding", json.sloopmelding),
      AsbestinventarisatieVragen.fromJson("inventarisatie", json.inventarisatie),
      AsbestlocatieVragen.fromJson("locatie", json.locatie),
      AsbestverwijderingWerkzaamhedenVragen.fromJson("werkzaamheden", json.werkzaamheden)
    );
  }
}
