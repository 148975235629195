import { Antwoord } from "..";
import { AntwoordBlok } from "../blok/antwoord-blok";

export class ZaakPincodeAntwoorden extends AntwoordBlok {
  constructor(public zaaknummer: Antwoord<string>, public pincode: Antwoord<string>) {
    super();
  }

  static initialize(): ZaakPincodeAntwoorden {
    return new ZaakPincodeAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): ZaakPincodeAntwoorden {
    return new ZaakPincodeAntwoorden(Antwoord.fromJson<string>(json.zaaknummer), Antwoord.fromJson<string>(json.pincode));
  }
}
