import { SamenvattingDocumenten } from "components/samenvatting-documenten";
import React, { Fragment, useEffect, useRef } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { ReactComponent as Info } from "../../../assets/icons/rijksoverheid/2002-info.svg";
import { FormulierVerzendenDialog } from "../../../components/formulier/formulier-verzenden-dialog";
import { translationKeys } from "../../../constants/translation-keys";
import { objectHelpers, processtapHelpers } from "../../../helpers";
import { Meldingrechten, ValidationResponse } from "../../../models/api";
import { BlokDefinitie, Formuliertype, Processtap } from "../../../models/application";
import { FileErrors } from "../../../models/application/error";
import { EditableWrapper } from "../../editable-wrapper";
import { ErrorMessageControl } from "../../error";
import {
  ConfirmDialog,
  IntrekkenButton,
  KopieerButton,
  KoppelZaakButton,
  SamenvattingContainer,
  SamenvattingMeldingData,
  VertaalOpdrachtButton,
  VertalingInvoerenButton,
  WijzigButton
} from "../../samenvatting";
import { VisibleWrapper } from "../../visible-wrapper";
import { GeneriekSamenvattingBlokRoot } from "../generiek-samenvatting-blok-root";
import { SamenvattingProps } from "./samenvatting.component.interfaces";

const Blokken = (props: { blokDefinitie: BlokDefinitie[]; processtappen: Processtap[]; isSubmitting: boolean }) => {
  const { t, i18n } = useTranslation();

  const lang = i18n.language;

  return (    
    <SamenvattingContainer idPrefix="samenvatting">
      {props.blokDefinitie
        .filter((b) => processtapHelpers.hasSamenvatting(b))
        .map((b) => {
          const isProcesstapDefinedForBlok = props.processtappen.some((p) => p.type === b.type);
          const SamenvattingBlok = () =>
            b.samenvattingBlok ? (
              React.createElement(b.samenvattingBlok, {
                processtap: b.type
              })
            ) : (
              <GeneriekSamenvattingBlokRoot processtap={b.type} />
            );

          return (
            <section key={b.type}>
              <h2>{b.title && b.title[lang]}</h2>
              {isProcesstapDefinedForBlok && !props.isSubmitting ? (
                <EditableWrapper
                  type={b.type}
                  editIconTitle={t(translationKeys["samenvatting-bar"]["bewerk-titel"], {
                    bloknaam: b.title[lang].toLowerCase()
                  })}
                >
                  <SamenvattingBlok />
                </EditableWrapper>
              ) : (
                <SamenvattingBlok />
              )}
            </section>
          );
        })}
    </SamenvattingContainer>
  );
};

const ValidationErrorsForBlok = (props: { errors: ValidationResponse; blok: BlokDefinitie }) => {
  const { i18n } = useTranslation();

  return (
    <li>
      {props.blok.title[i18n.language]}
      <ul>
        {props.errors.validationFailures
          .filter(
            (vf, index, self) =>
              // Filter out duplicate validation failures for vraagblokcollections.
              self.findIndex(
                (f) => f.message === vf.message && f.property.startsWith(`${objectHelpers.toPascalCase(props.blok.type)}.`)
              ) === index
          )
          .map((vf, index) => (
            <li key={`${vf.property}${index}`}>{vf.message}</li>
          ))}
      </ul>
    </li>
  );
};

const ValidationError = (props: { errors: ValidationResponse; blokken: BlokDefinitie[] }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div>{t(translationKeys["formulier-versturen-mislukt-validatie-fouten"])}</div>
      <ul>
        {props.blokken
          .filter((b) =>
            props.errors.validationFailures.some((vf) => vf.property?.startsWith(`${objectHelpers.toPascalCase(b.type)}.`))
          )
          .map((blok) => (
            <ValidationErrorsForBlok key={blok.type} blok={blok} errors={props.errors} />
          ))}
      </ul>
      <div>{t(translationKeys["formulier-versturen-mislukt-herstel-validatie-fouten"])}</div>
    </Fragment>
  );
};

const FileSubmitError = (props: { errors: FileErrors | null }) => {
  const { t } = useTranslation();

  return (
    props.errors && (
      <Fragment>
        <div>
          {t(
            translationKeys[
              props.errors.fileErrors.length > 1
                ? "formulier-versturen-mislukt-bestand-fouten"
                : "formulier-versturen-mislukt-bestand-fout"
            ]
          )}
        </div>
        <ul>
          {props.errors.fileErrors.map((err) => (
            <li key={err.file.name}>{err.file.name}</li>
          ))}
        </ul>
      </Fragment>
    )
  );
};

const SamenvattingButtonBar = (props: {
  onKopieerMelding: () => void;
  onWijzigMelding: () => void;
  onIntrekkenMelding: (meldingId: string) => void;
  onVertaalMelding: () => void;
  onKoppelZaak: () => void;
  formuliertype: Formuliertype;
  meldingId: string;
  hasVertaalbareVragenMetAntwoord: boolean;
  meldingrechten?: Meldingrechten;
}) => (
  <Fragment>
    <div className="samenvatting-button-bar">
      {props.meldingrechten && (
        <Fragment>
          {props.meldingrechten.kanKopieren && <KopieerButton onClick={props.onKopieerMelding}></KopieerButton>}
          {props.meldingrechten.kanWijzigen && <WijzigButton onClick={props.onWijzigMelding}></WijzigButton>}
          {props.meldingrechten.kanIntrekken && (
            <IntrekkenButton
              formuliertype={props.formuliertype}
              meldingId={props.meldingId}
              intrekkenAction={props.onIntrekkenMelding}
            ></IntrekkenButton>
          )}
          {props.meldingrechten.kanZaakKoppelen && <KoppelZaakButton onClick={props.onKoppelZaak} />}
          {props.meldingrechten.kanVertalen && props.hasVertaalbareVragenMetAntwoord && (
            <>
              <VertaalOpdrachtButton formuliertype={props.formuliertype} meldingId={props.meldingId}></VertaalOpdrachtButton>
              <VertalingInvoerenButton
                formuliertype={props.formuliertype}
                meldingId={props.meldingId}
                onClick={props.onVertaalMelding}
              ></VertalingInvoerenButton>
            </>
          )}
        </Fragment>
      )}
    </div>
    <div className="samenvatting-button-toelichting-intrekken-bar">
      {props.meldingrechten?.toelichtingIntrekken && (
        <Fragment>
          <Alert variant="info" className="mededeling-bar">
            <Row>
              <Col className="icon">
                <Info role="presentation" />
              </Col>
              <Col>
                <ReactMarkdown children={props.meldingrechten?.toelichtingIntrekken} className="mededeling" />
              </Col>
            </Row>
          </Alert>
        </Fragment>
      )}
    </div>
  </Fragment>
);

const focusRef = (ref: any) => {
  ref.current.focus();
};

const ApiErrorAlert = ({ apiError }: { apiError: string | undefined }) => {
  if (!apiError) return null;

  return (
    <Alert variant="danger">
      {apiError.split(";").map((error, index) => (
        <div key={index}>{error}</div>
      ))}
    </Alert>
  );
};

function ShowSamenvattingButtons(props: SamenvattingProps) {
  return !props.apiError && props.showSamenvattingButtonbar;
}

export const Samenvatting: React.FC<SamenvattingProps> = (props: SamenvattingProps) => {
  const invisibleHeaderRef = useRef(null);
  const { t } = useTranslation();

  const { showZaakLinks, showDocuments, onLoad, isActive, onBlokActivated } = props;

  useEffect(() => {
    if (invisibleHeaderRef && invisibleHeaderRef.current) {
      focusRef(invisibleHeaderRef);
    }
  }, []);

  useEffect(() => {
    if (onLoad) {
      onLoad(showZaakLinks, showDocuments);
    }
  }, [showZaakLinks, showDocuments, onLoad]);

  useEffect(() => {
    if (isActive) {
      onBlokActivated();
    }
  }, [isActive, onBlokActivated]);

  return (
    <VisibleWrapper isVisible={props.isActive}>
      <div className="samenvatting">
        <h2 className="visually-hidden" tabIndex={-1} ref={invisibleHeaderRef}>
          Samenvatting
        </h2>
        <VisibleWrapper isVisible={props.showGekozenTaalIsNietGelijkAanOorspronkelijkeTaal}>
          <Alert variant="warning">
            {t(translationKeys.melding.mijnMeldingen.gekozenTaalIsNietGelijkAanOorspronkelijkeTaal)}
            <VisibleWrapper isVisible={props.meldingrechten?.kanWijzigen !== true}>
              <div>{t(translationKeys.melding.mijnMeldingen.meldingKanNietGewijzigdWordenInAndereTaal)}</div>
            </VisibleWrapper>
          </Alert>
        </VisibleWrapper>
        <VisibleWrapper isVisible={props.hasMissendeVertalingen}>
          <Alert variant="warning">{t(translationKeys.melding.mijnMeldingen.missendeVertalingen)}</Alert>
        </VisibleWrapper>
        <VisibleWrapper isVisible={props.hasSubmitError}>
          <ErrorMessageControl name="submitError" scrollIntoViewCondition={() => props.hasSubmitError && props.isActive}>
            {props.submitValidationResults?.isValid === false && (
              <ValidationError errors={props.submitValidationResults} blokken={props.blokken} />
            )}
            {props.submitValidationResults?.isValid !== false &&
              props.fileErrors === null &&
              t(translationKeys["formulier-versturen-mislukt"])}
            {props.fileErrors !== null && <FileSubmitError errors={props.fileErrors} />}
          </ErrorMessageControl>
        </VisibleWrapper>
        <ApiErrorAlert apiError={props.apiError} />
        {!props.isSamenvattingOnlyMode && (
          <div className="explanation description">{t(translationKeys.blok.samenvatting.uitleg)}</div>
        )}
        {ShowSamenvattingButtons(props) && (
          <SamenvattingButtonBar
            formuliertype={props.formuliertype as Formuliertype}
            meldingId={props.meldingId as string}
            meldingrechten={props.meldingrechten}
            hasVertaalbareVragenMetAntwoord={props.hasVertaalbareVragenMetAntwoord}
            onIntrekkenMelding={props.onIntrekkenMelding}
            onKopieerMelding={props.onKopieerMelding}
            onWijzigMelding={() => props.onWijzigMelding(props.gekozenTaalAfwijkend)}
            onVertaalMelding={props.onVertaalMelding}
            onKoppelZaak={props.onKoppelZaak}
          ></SamenvattingButtonBar>
        )}
        {props.showSamenvattingMeldingData && props.showDocuments && (
          <SamenvattingDocumenten documenten={props.documenten} meldingId={props.meldingId}></SamenvattingDocumenten>
        )}
        {props.showSamenvattingMeldingData && (
          <SamenvattingMeldingData
            meldingNummer={props.meldingnummer}
            versie={props.versie}
            datumIngediend={props.datumIngediend}
            verzenddatum={props.verzenddatum}
            datumIntrekking={props.datumIntrekking}
            status={props.status}
            laatstGewijzigdDoor={props.laatstGewijzigdDoor}
            showZaakLinks={props.showZaakLinks}
            zaakLinks={props.zaakLinks}
          ></SamenvattingMeldingData>
        )}
        <Blokken blokDefinitie={props.blokken} processtappen={props.processtappen} isSubmitting={props.isSubmitting} />
        <FormulierVerzendenDialog
          className="samenvatting-progress-bar"
          isVisible={props.isSubmitting}
          progressPercentage={props.submitProgress}
        />
      </div>
      <ConfirmDialog
        showDialog={props.showIntrekkenDialog}
        name="intrekken-melding-dialog"
        titelTranslationKey={translationKeys.dialoog.meldingIntrekken.titel}
        confirmTranslationKey={translationKeys.dialoog.meldingIntrekken.intrekken}
        cancelTranslationKey={translationKeys.dialoog.meldingIntrekken.annuleren}
        onConfirm={props.onIntrekkenConfirm}
        onCancel={props.onIntrekkenCancel}
      />
      <ConfirmDialog
        showDialog={props.showWijzigenAanvraagDialog}
        name="wijzigen-melding-dialog"
        titelTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenAanvraag.titel}
        tekstTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenAanvraag.tekst}
        confirmTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenAanvraag.confirm}
        cancelTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenAanvraag.cancel}
        onConfirm={props.onWijzigenConfirm}
        onCancel={props.onWijzigenCancel}
      />
      <ConfirmDialog
        showDialog={props.showWijzigenVerzoekDialog}
        name="wijzigen-melding-dialog"
        titelTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenVerzoek.titel}
        tekstTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenVerzoek.tekst}
        confirmTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenVerzoek.confirm}
        cancelTranslationKey={translationKeys.melding.mijnMeldingen.wijzigenVerzoek.cancel}
        onConfirm={props.onWijzigenConfirm}
        onCancel={props.onWijzigenCancel}
      />
    </VisibleWrapper>
  );
};


