import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../constants/translation-keys";
import { GuardedLink } from "../guarded-link";
import { VisibleWrapper } from "../visible-wrapper";
import { UserMenuContext } from "./user-menu-context";
import { UserMenuStateProps } from "./user-menu.component.interfaces";

export const UserMenu: React.FC<any> = (props: UserMenuStateProps) => {
  const { t } = useTranslation();

  const context = React.useContext(UserMenuContext);

  return context.isVisible ? (
    <div className="user-menu" key="user-menu">
      <ul className="list-unstyled">
        <li>
          <GuardedLink url="/mijnmeldingen" onClick={() => context.toggleIsVisible()}>
            {t(translationKeys.melding.mijnMeldingen.titel)}
          </GuardedLink>
          <VisibleWrapper isVisible={props.isInterneMedewerker}>
            <ul className="list-unstyled">
              <li>
                <GuardedLink url="/melding/toevoegen" onClick={() => context.toggleIsVisible()}>
                  {t(translationKeys.melding.toevoegen.titel)}
                </GuardedLink>
              </li>
            </ul>
          </VisibleWrapper>
        </li>
        <VisibleWrapper isVisible={!props.isInterneMedewerker}>
          <li>
            <GuardedLink url="/profiel" onClick={() => context.toggleIsVisible()}>
              {t(translationKeys.profiel.mijnProfiel.titel)}
            </GuardedLink>
          </li>
        </VisibleWrapper>
        <VisibleWrapper isVisible={props.isInterneMedewerker}>
          <li>
            {t(translationKeys.zoek.titel)}
            <ul className="list-unstyled">
              <li>
                <GuardedLink url="/zoek/kennisgevingen" onClick={() => context.toggleIsVisible()}>
                  {t(translationKeys.zoek.meldingen.titel)}
                </GuardedLink>
              </li>
            </ul>
          </li>
        </VisibleWrapper>
        <VisibleWrapper isVisible={props.isBeheerderOrServicedesk}>
          <li>
            {t(translationKeys.beheer.titel)}
            <ul className="list-unstyled">
              <li>
                <GuardedLink url="/beheer/autorisaties" onClick={() => context.toggleIsVisible()}>
                  {t(translationKeys.beheer.autorisaties.titel)}
                </GuardedLink>
              </li>
              <li>
                <GuardedLink url="/beheer/mededeling" onClick={() => context.toggleIsVisible()}>
                  {t(translationKeys.beheer.mededeling.titel)}
                </GuardedLink>
              </li>
            </ul>
          </li>
        </VisibleWrapper>
      </ul>
    </div>
  ) : null;
};
