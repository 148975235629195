import React from "react";
import { Col, Row } from "react-bootstrap";
import { GeneriekSamenvattingBlok } from "../generiek-samenvatting-blok";
import { GeneriekSamenvattingBlokRootProps } from "./generiek-samenvatting-blok-root.component.interfaces";

export const GeneriekSamenvattingBlokRoot = (props: GeneriekSamenvattingBlokRootProps) => {
  const { vragen } = props;

  return vragen ? (
    <div className="samenvatting-blok">
      <Row>
        <Col className="samenvatting-blok-col">
          <GeneriekSamenvattingBlok vragen={vragen} />
        </Col>
      </Row>
    </div>
  ) : null;
};
