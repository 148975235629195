import { setCollectionCurrentPage, setVraagBlokAntwoordIndexes } from "actions";
import { connect } from "react-redux";
import { State } from "store";
import { setDefaultSorting } from "thunks/collection-thunks";
import { VraagBlokWithAntwoordIndex } from "../model";
import { CollectionHandler } from "./collection-handler.component";
import {
  CollectionHandlerDispatchProps,
  CollectionHandlerOwnProps,
  CollectionHandlerStateProps
} from "./collection-handler.component.interfaces";

const EMPTY_SEARCH = "";
const EMPTY_TIMESTAMP = 0;
const mapStateToProps = (state: State, props: CollectionHandlerOwnProps): CollectionHandlerStateProps => ({
  searchValue: state.search.searches[props.collectionKey]?.value || EMPTY_SEARCH,
  searchTimestamp: state.search.searches[props.collectionKey]?.timestamp || EMPTY_TIMESTAMP,
  currentSorting: state.collections.list[props.collectionKey]?.sorting || null,
  paging: state.collections.list[props.collectionKey]?.paging,
  selectedItemKey: state.collections.list[props.collectionKey]?.selectedItemKey
});

const mapDispatchToProps = (dispatch: any, props: CollectionHandlerOwnProps): CollectionHandlerDispatchProps => ({
  setVraagBlokAntwoordIndexList: (
    vraagBlokAntwoordIndexes: VraagBlokWithAntwoordIndex[],
    unfilteredTotalRecords: number
  ) => {
    dispatch(setVraagBlokAntwoordIndexes(props.collectionKey, vraagBlokAntwoordIndexes, unfilteredTotalRecords));
  },
  resetSortingToDefault: () => {
    dispatch(setDefaultSorting(props.collectionKey, props.columnSettings));
  },
  updatePageNumer: (pageNumber: number) => {
    dispatch(setCollectionCurrentPage(props.collectionKey, pageNumber));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionHandler);
