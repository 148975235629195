import { GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken } from "models/api/melding/gevaarlijke-gassen-importcontainers-melding-antwoord-blokken";

import { Configuration } from "../configuration";
import { Document } from "../models/api/document";
import { SearchMeldingenRequest, SearchMeldingenResponse } from "../models/api/zoek";
import { Formuliertype, ProcesstapType, isFormulierTypeMelding } from "../models/application";
import {
  AanwijzingRisicovolleBedrijvenMelding,
  ArieMelding,
  ArieMeldingAntwoordBlokken,
  AsbestRisicoklasse1Melding,
  AsbestRisicoklasse1MeldingAntwoordBlokken,
  AsbestverwijderingLavsMelding,
  AsbestverwijderingLavsMeldingAntwoordBlokken,
  AsbestverwijderingLavsV2Melding,
  AsbestverwijderingLavsV2MeldingAntwoordBlokken,
  AsbestverwijderingMelding,
  AsbestverwijderingMeldingAntwoordBlokken,
  AvvArtikel10AVerzoekMelding,
  AvvArtikel10AVerzoekMeldingAntwoordBlokken,
  AvvArtikel10Melding,
  AvvArtikel10MeldingAntwoordBlokken,
  BergplaatsRadioactieveStoffenMelding,
  BibobMelding,
  BibobMeldingAntwoordBlokken,
  BiologischeAgentiaMelding,
  BiologischeAgentiaMeldingAntwoordBlokken,
  BouwprocesMelding,
  BouwprocesMeldingAntwoordBlokken,
  CbiMelding,
  CbiMeldingAntwoordBlokken,
  ContactMelding,
  ContactMeldingAntwoordBlokken,
  DigipassMelding,
  DigipassMeldingAntwoordBlokken,
  DouaneMelding,
  DouaneMeldingAntwoordBlokken,
  DuikarbeidMelding,
  DuikarbeidMeldingAntwoordBlokken,
  GetMijnMeldingenRequest,
  GetMijnMeldingenResponse,
  GevaarlijkeGassenImportcontainersMelding,
  GevaarlijkeLiftMelding,
  HandelingenIoniserendeStralingMelding,
  InformatieverzoekMelding,
  InformatieverzoekMeldingAntwoordBlokken,
  KennisgevingMeldingAntwoordBlokken,
  KlachtMelding,
  KlachtMeldingAntwoordBlokken,
  KlachtenMelding,
  KlachtenMeldingAntwoordBlokken,
  Melding,
  MeldingBase,
  MeldingStatusResponse,
  Meldingrechten,
  OngevalMelding,
  OngevalMeldingAntwoordBlokken,
  OngewoneVoorvallenMelding,
  OntheffingKinderarbeidMelding,
  OntheffingKinderarbeidMeldingAntwoordBlokken,
  OntheffingLiftenMelding,
  OntheffingLiftenMeldingAntwoordBlokken,
  OntheffingNachtarbeidMelding,
  OntheffingNachtarbeidMeldingAntwoordBlokken,
  OntheffingVuurOpTankschipMelding,
  OntheffingVuurOpTankschipMeldingAntwoordBlokken,
  PredicaatKoninklijkMelding,
  PredicaatKoninklijkMeldingAntwoordBlokken,
  ProductveiligheidMelding,
  ProductveiligheidMeldingAntwoordBlokken,
  ReactieOpenbaarmakingMelding,
  ReactieOpenbaarmakingMeldingAntwoordBlokken,
  UploadGeneriekMelding,
  UploadGeneriekMeldingAntwoordBlokken,
  UploadRieMelding,
  UploadRieMeldingAntwoordBlokken,
  ValidationResponse,
  VerzoekTotGezamenlijkeInterventieMelding,
  VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken,
  VluchtigeOrganischeStoffenMelding,
  VluchtigeOrganischeStoffenMeldingAntwoordBlokken,
  VuurOpTankschipMelding,
  VuurOpTankschipMeldingAntwoordBlokken,
  WerkbakMelding,
  WerkbakMeldingAntwoordBlokken,
  WooMelding,
  WooMeldingAntwoordBlokken,
  ZaakLink
} from "./../models/api";
import { get, objectToQueryString, post } from "./api";
import { xhrPost, xhrPut } from "./xhr";

const createFormdataForMelding = <TMelding extends Melding>(melding: TMelding, files: File[]): FormData => {
  const formData = new FormData();
  formData.append("data", JSON.stringify(melding));

  for (const file of files) {
    formData.append("files", file, file.name);
  }

  return formData;
};

const determineUrlSegment = (formuliertype: Formuliertype) => {
  return isFormulierTypeMelding(formuliertype) ? "melding" : "webportaal/melding";
};

const createValidate = <TMelding extends Melding>(
  formuliertype: Formuliertype,
  processtap: ProcesstapType
): ((melding: TMelding) => Promise<ValidationResponse>) => {
  return (melding: TMelding): Promise<ValidationResponse> => {
    return post({
      uri: `${Configuration.apiUrl}/${determineUrlSegment(formuliertype)}/${formuliertype}/actions/validate/${processtap}`,
      data: melding,
      success: (r) => ValidationResponse.fromJson(r)
    });
  };
};

const getMeldingBaseUri = (formuliertype: Formuliertype): string => {
  return `${Configuration.apiUrl}/${determineUrlSegment(formuliertype)}/${formuliertype}`;
};

const createConcept = <TMelding extends Melding>(
  formuliertype: Formuliertype
): ((melding: TMelding, files: File[]) => Promise<MeldingStatusResponse>) => {
  return (melding: TMelding, files: File[]): Promise<MeldingStatusResponse> => {
    const formData = createFormdataForMelding(melding, files);
    const uri = `${getMeldingBaseUri(formuliertype)}/concept`;

    return xhrPost({
      uri: uri,
      data: formData,
      success: (r) => (isFormulierTypeMelding(formuliertype) ? MeldingStatusResponse.fromJson(JSON.parse(r)) : null)
    });
  };
};

const createSubmit = <TMelding extends Melding>(
  formuliertype: Formuliertype,
  onProgress?: (progress: number) => void
): ((melding: TMelding, files: File[]) => Promise<MeldingStatusResponse>) => {
  return (melding: TMelding, files: File[]): Promise<MeldingStatusResponse> => {
    const formData = createFormdataForMelding(melding, files);
    const uri = getMeldingBaseUri(formuliertype);

    return xhrPost({
      uri: uri,
      data: formData,
      success: (r) => (isFormulierTypeMelding(formuliertype) ? MeldingStatusResponse.fromJson(JSON.parse(r)) : null),
      progress: onProgress
    });
  };
};

const createUpdate = <TMelding extends Melding>(
  uri: string,
  useMeldingId: boolean,
  isVertaling: boolean,
  formuliertype: Formuliertype,
  onProgress?: (progress: number) => void
): ((melding: TMelding, files: File[]) => Promise<MeldingStatusResponse>) => {
  return (melding: TMelding, files: File[]): Promise<MeldingStatusResponse> => {
    const formData = createFormdataForMelding(melding, files);

    if (useMeldingId) {
      uri += `/${melding.id}`;
      if (isVertaling) {
        uri += "/vertaling";
      }
    }

    return xhrPut({
      uri: uri,
      data: formData,
      success: (r) => (isFormulierTypeMelding(formuliertype) ? MeldingStatusResponse.fromJson(JSON.parse(r)) : null),
      progress: onProgress
    });
  };
};

const createSaveMelding = <TMelding extends Melding>(
  isExistingMelding: boolean,
  isVertaling: boolean,
  formuliertype: Formuliertype,
  onProgress?: (progress: number) => void
): ((melding: TMelding, files: File[]) => Promise<MeldingStatusResponse>) => {
  if (isExistingMelding) {
    const uri = getMeldingBaseUri(formuliertype);
    const useMeldingId = formuliertype !== Formuliertype.profiel;
    return createUpdate(uri, useMeldingId, isVertaling, formuliertype, onProgress);
  }

  return createSubmit(formuliertype, onProgress);
};

const intrekkenMelding = (formuliertype: Formuliertype, meldingId: string): Promise<any> => {
  return post({
    uri: `${Configuration.apiUrl}/melding/${formuliertype}/${meldingId}/actions/intrekken`,
    success: (r) => r
  });
};

const getMijnMeldingen = (request: GetMijnMeldingenRequest): Promise<GetMijnMeldingenResponse> =>
  get({
    uri: `${Configuration.apiUrl}/melding?pageNumber=${request.pageNumber}
&itemsPerPage=${request.itemsPerPage}
&sortOption.propertyName=${request.sortOption.propertyName}
&sortOption.sortAscending=${request.sortOption.sortAscending}
&taal=${request.taal}`,
    success: (r) => r
  });

const getMeldingUri = (meldingNummer: number): Promise<string> =>
  get({
    uri: `${Configuration.apiUrl}/melding/${meldingNummer}`,
    success: (r) => r
  });

const getDownloadSamenvattingUrl = (formuliertype: Formuliertype, meldingId: string) => {
  return `${Configuration.apiUrl}/melding/${formuliertype}/${meldingId}/actions/download`;
};

const getDownloadVertaalopdrachtUrl = (formuliertype: Formuliertype, meldingId: string) => {
  return `${Configuration.apiUrl}/melding/${formuliertype}/${meldingId}/actions/download_vertaalopdracht`;
};

const getMelding = <T extends Melding>(
  formulierType: Formuliertype,
  meldingId: string,
  toJson: (r: any) => T
): Promise<T> => {
  return get({
    uri: `${Configuration.apiUrl}/${determineUrlSegment(formulierType)}/${formulierType}/${meldingId}`,
    success: (r) => toJson(r)
  });
};

const getMeldingrechten = <T extends Meldingrechten>(
  formulierType: Formuliertype,
  meldingId: string,
  toJson: (r: any) => T
): Promise<T> => {
  return get({
    uri: `${Configuration.apiUrl}/${determineUrlSegment(formulierType)}/${formulierType}/${meldingId}/rechten`,
    success: (r) => toJson(r)
  });
};

const getWerkbakMelding = (meldingId: string) =>
  getMelding(Formuliertype.werkbak, meldingId, MeldingBase.fromJson(WerkbakMelding, WerkbakMeldingAntwoordBlokken.fromJson));

const getWerkbakMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.werkbak, meldingId, Meldingrechten.fromJson);

const getDouaneMelding = (meldingId: string) =>
  getMelding(Formuliertype.douane, meldingId, MeldingBase.fromJson(DouaneMelding, DouaneMeldingAntwoordBlokken.fromJson));

const getDouaneMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.douane, meldingId, Meldingrechten.fromJson);

const getDuikarbeidMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.duikarbeid,
    meldingId,
    MeldingBase.fromJson(DuikarbeidMelding, DuikarbeidMeldingAntwoordBlokken.fromJson)
  );

const getDuikarbeidMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.duikarbeid, meldingId, Meldingrechten.fromJson);

const getOntheffingLiftenMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.ontheffingLiften,
    meldingId,
    MeldingBase.fromJson(OntheffingLiftenMelding, OntheffingLiftenMeldingAntwoordBlokken.fromJson)
  );

const getOntheffingLiftenMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.ontheffingLiften, meldingId, Meldingrechten.fromJson);

const getOntheffingNachtarbeidMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.ontheffingNachtarbeid,
    meldingId,
    MeldingBase.fromJson(OntheffingNachtarbeidMelding, OntheffingNachtarbeidMeldingAntwoordBlokken.fromJson)
  );

const getOntheffingNachtarbeidMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.ontheffingNachtarbeid, meldingId, Meldingrechten.fromJson);

const getVuurOpTankschipMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.vuurOpTankschip,
    meldingId,
    MeldingBase.fromJson(VuurOpTankschipMelding, VuurOpTankschipMeldingAntwoordBlokken.fromJson)
  );

const getVuurOpTankschipMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.vuurOpTankschip, meldingId, Meldingrechten.fromJson);

const getAsbestRisicoklasse1Melding = (meldingId: string) =>
  getMelding(
    Formuliertype.asbestRisicoklasse1,
    meldingId,
    MeldingBase.fromJson(AsbestRisicoklasse1Melding, AsbestRisicoklasse1MeldingAntwoordBlokken.fromJson)
  );
const getAsbestRisicoklasse1Meldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.asbestRisicoklasse1, meldingId, Meldingrechten.fromJson);

const getBouwprocesMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.bouwproces,
    meldingId,
    MeldingBase.fromJson(BouwprocesMelding, BouwprocesMeldingAntwoordBlokken.fromJson)
  );

const getBouwprocesMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.bouwproces, meldingId, Meldingrechten.fromJson);

const getContactMelding = (meldingId: string) =>
  getMelding(Formuliertype.contact, meldingId, MeldingBase.fromJson(ContactMelding, ContactMeldingAntwoordBlokken.fromJson));

const getContactMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.contact, meldingId, Meldingrechten.fromJson);

const getKlachtMelding = (meldingId: string) =>
  getMelding(Formuliertype.klacht, meldingId, MeldingBase.fromJson(KlachtMelding, KlachtMeldingAntwoordBlokken.fromJson));

const getKlachtMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.klacht, meldingId, Meldingrechten.fromJson);

const getArieMelding = (meldingId: string) =>
  getMelding(Formuliertype.arie, meldingId, MeldingBase.fromJson(ArieMelding, ArieMeldingAntwoordBlokken.fromJson));

const getArieMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.arie, meldingId, Meldingrechten.fromJson);

const getKlachtenMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.klachten,
    meldingId,
    MeldingBase.fromJson(KlachtenMelding, KlachtenMeldingAntwoordBlokken.fromJson)
  );

const getKlachtenMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.klachten, meldingId, Meldingrechten.fromJson);

const getOngevalMelding = (meldingId: string) =>
  getMelding(Formuliertype.ongeval, meldingId, MeldingBase.fromJson(OngevalMelding, OngevalMeldingAntwoordBlokken.fromJson));

const getOngevalMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.ongeval, meldingId, Meldingrechten.fromJson);

const getProductveiligheidMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.productveiligheid,
    meldingId,
    MeldingBase.fromJson(ProductveiligheidMelding, ProductveiligheidMeldingAntwoordBlokken.fromJson)
  );

const getProductveiligheidMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.productveiligheid, meldingId, Meldingrechten.fromJson);

const getBiologischeAgentiaMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.biologischeAgentia,
    meldingId,
    MeldingBase.fromJson(BiologischeAgentiaMelding, BiologischeAgentiaMeldingAntwoordBlokken.fromJson)
  );

const getBiologischeAgentiaMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.biologischeAgentia, meldingId, Meldingrechten.fromJson);

const getVluchtigeOrganischeStoffenMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.vluchtigeOrganischeStoffen,
    meldingId,
    MeldingBase.fromJson(VluchtigeOrganischeStoffenMelding, VluchtigeOrganischeStoffenMeldingAntwoordBlokken.fromJson)
  );

const getVluchtigeOrganischeStoffenMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.vluchtigeOrganischeStoffen, meldingId, Meldingrechten.fromJson);

const getOntheffingKinderarbeidMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.ontheffingKinderarbeid,
    meldingId,
    MeldingBase.fromJson(OntheffingKinderarbeidMelding, OntheffingKinderarbeidMeldingAntwoordBlokken.fromJson)
  );

const getOntheffingKinderarbeidMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.ontheffingKinderarbeid, meldingId, Meldingrechten.fromJson);

const getOntheffingKinderarbeidImportVoorbeeldbestandUrl = () => {
  return `${Configuration.apiUrl}/melding/ontheffingkinderarbeid/actions/downloadimportvoorbeeldbestand`;
};

const getReactieOpenbaarmakingMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.reactieOpenbaarmaking,
    meldingId,
    MeldingBase.fromJson(ReactieOpenbaarmakingMelding, ReactieOpenbaarmakingMeldingAntwoordBlokken.fromJson)
  );

const getReactieOpenbaarmakingMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.reactieOpenbaarmaking, meldingId, Meldingrechten.fromJson);

const getDigipassMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.digipass,
    meldingId,
    MeldingBase.fromJson(DigipassMelding, DigipassMeldingAntwoordBlokken.fromJson)
  );

const getDigipassMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.digipass, meldingId, Meldingrechten.fromJson);

const getUploadGeneriekMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.uploadGeneriek,
    meldingId,
    MeldingBase.fromJson(UploadGeneriekMelding, UploadGeneriekMeldingAntwoordBlokken.fromJson)
  );

const getUploadGeneriekMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.uploadGeneriek, meldingId, Meldingrechten.fromJson);

const getUploadRieMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.uploadRie,
    meldingId,
    MeldingBase.fromJson(UploadRieMelding, UploadRieMeldingAntwoordBlokken.fromJson)
  );

const getUploadRieMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.uploadRie, meldingId, Meldingrechten.fromJson);

const getCbiMelding = (meldingId: string) =>
  getMelding(Formuliertype.cbi, meldingId, MeldingBase.fromJson(CbiMelding, CbiMeldingAntwoordBlokken.fromJson));

const getCbiMeldingrechten = (meldingId: string) => getMeldingrechten(Formuliertype.cbi, meldingId, Meldingrechten.fromJson);

const getAsbestverwijderingLavsMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.asbestverwijderingLavs,
    meldingId,
    MeldingBase.fromJson(AsbestverwijderingLavsMelding, AsbestverwijderingLavsMeldingAntwoordBlokken.fromJson)
  );

const getAsbestverwijderingLavsMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.asbestverwijderingLavs, meldingId, Meldingrechten.fromJson);

const getAsbestverwijderingLavsV2Melding = (meldingId: string) =>
  getMelding(
    Formuliertype.asbestverwijderingLavsV2,
    meldingId,
    MeldingBase.fromJson(AsbestverwijderingLavsV2Melding, AsbestverwijderingLavsV2MeldingAntwoordBlokken.fromJson)
  );

const getAsbestverwijderingLavsV2Meldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.asbestverwijderingLavsV2, meldingId, Meldingrechten.fromJson);

const getAsbestverwijderingMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.asbestverwijdering,
    meldingId,
    MeldingBase.fromJson(AsbestverwijderingMelding, AsbestverwijderingMeldingAntwoordBlokken.fromJson)
  );

const getAsbestverwijderingMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.asbestverwijdering, meldingId, Meldingrechten.fromJson);

const getAanwijzingRisicovolleBedrijvenMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.aanwijzingRisicovolleBedrijven,
    meldingId,
    MeldingBase.fromJson(AanwijzingRisicovolleBedrijvenMelding, KennisgevingMeldingAntwoordBlokken.fromJson)
  );

const getAanwijzingRisicovolleBedrijvenMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.aanwijzingRisicovolleBedrijven, meldingId, Meldingrechten.fromJson);

const getBergplaatsRadioactieveStoffenMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.bergplaatsRadioactieveStoffen,
    meldingId,
    MeldingBase.fromJson(BergplaatsRadioactieveStoffenMelding, KennisgevingMeldingAntwoordBlokken.fromJson)
  );

const getBergplaatsRadioactieveStoffenMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.bergplaatsRadioactieveStoffen, meldingId, Meldingrechten.fromJson);

const getGevaarlijkeGassenImportcontainersMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.gevaarlijkeGassenImportcontainers,
    meldingId,
    MeldingBase.fromJson(
      GevaarlijkeGassenImportcontainersMelding,
      GevaarlijkeGassenImportcontainersMeldingAntwoordBlokken.fromJson
    )
  );

const getGevaarlijkeGassenImportcontainersMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.gevaarlijkeGassenImportcontainers, meldingId, Meldingrechten.fromJson);

const getGevaarlijkeLiftMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.gevaarlijkeLift,
    meldingId,
    MeldingBase.fromJson(GevaarlijkeLiftMelding, KennisgevingMeldingAntwoordBlokken.fromJson)
  );

const getGevaarlijkeLiftMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.gevaarlijkeLift, meldingId, Meldingrechten.fromJson);

const getHandelingenIoniserendeStralingMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.handelingenIoniserendeStraling,
    meldingId,
    MeldingBase.fromJson(HandelingenIoniserendeStralingMelding, KennisgevingMeldingAntwoordBlokken.fromJson)
  );

const getHandelingenIoniserendeStralingMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.handelingenIoniserendeStraling, meldingId, Meldingrechten.fromJson);

const getOngewoneVoorvallenMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.ongewoneVoorvallen,
    meldingId,
    MeldingBase.fromJson(OngewoneVoorvallenMelding, KennisgevingMeldingAntwoordBlokken.fromJson)
  );

const getOngewoneVoorvallenMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.ongewoneVoorvallen, meldingId, Meldingrechten.fromJson);

const getBibobMelding = (meldingId: string) =>
  getMelding(Formuliertype.bibob, meldingId, MeldingBase.fromJson(BibobMelding, BibobMeldingAntwoordBlokken.fromJson));

const getBibobMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.bibob, meldingId, Meldingrechten.fromJson);

const getInformatieverzoekMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.informatieverzoek,
    meldingId,
    MeldingBase.fromJson(InformatieverzoekMelding, InformatieverzoekMeldingAntwoordBlokken.fromJson)
  );

const getInformatieverzoekMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.informatieverzoek, meldingId, Meldingrechten.fromJson);

const getWooMelding = (meldingId: string) =>
  getMelding(Formuliertype.woo, meldingId, MeldingBase.fromJson(WooMelding, WooMeldingAntwoordBlokken.fromJson));

const getWooMeldingrechten = (meldingId: string) => getMeldingrechten(Formuliertype.woo, meldingId, Meldingrechten.fromJson);

const getPredicaatKoninklijkMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.predicaatKoninklijk,
    meldingId,
    MeldingBase.fromJson(PredicaatKoninklijkMelding, PredicaatKoninklijkMeldingAntwoordBlokken.fromJson)
  );

const getPredicaatKoninklijkMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.predicaatKoninklijk, meldingId, Meldingrechten.fromJson);

const getVerzoekTotGezamenlijkeInterventieMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.verzoekTotGezamenlijkeInterventie,
    meldingId,
    MeldingBase.fromJson(
      VerzoekTotGezamenlijkeInterventieMelding,
      VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken.fromJson
    )
  );

const getVerzoekTotGezamenlijkeInterventieMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.verzoekTotGezamenlijkeInterventie, meldingId, Meldingrechten.fromJson);

const getOntheffingVuurOpTankschipMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.ontheffingVuurOpTankschip,
    meldingId,
    MeldingBase.fromJson(OntheffingVuurOpTankschipMelding, OntheffingVuurOpTankschipMeldingAntwoordBlokken.fromJson)
  );

const getOntheffingVuurOpTankschipMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.ontheffingVuurOpTankschip, meldingId, Meldingrechten.fromJson);

const getAvvArtikel10Melding = (meldingId: string) =>
  getMelding(
    Formuliertype.avvartikel10,
    meldingId,
    MeldingBase.fromJson(AvvArtikel10Melding, AvvArtikel10MeldingAntwoordBlokken.fromJson)
  );

const getAvvArtikel10Meldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.avvartikel10, meldingId, Meldingrechten.fromJson);

const getAvvArtikel10AVerzoekMelding = (meldingId: string) =>
  getMelding(
    Formuliertype.avvArtikel10AVerzoek,
    meldingId,
    MeldingBase.fromJson(AvvArtikel10AVerzoekMelding, AvvArtikel10AVerzoekMeldingAntwoordBlokken.fromJson)
  );

const getAvvArtikel10AVerzoekMeldingrechten = (meldingId: string) =>
  getMeldingrechten(Formuliertype.avvArtikel10AVerzoek, meldingId, Meldingrechten.fromJson);

const getZaken = (meldingId: string) => {
  return get<ResponseCollection<ZaakLink>>({
    uri: `${Configuration.apiUrl}/melding/${meldingId}/actions/getzaken`,
    success: (r) => r
  });
};

const getDocumenten = (meldingId: string) => {
  return get<ResponseCollection<Document>>({
    uri: `${Configuration.apiUrl}/melding/${meldingId}/document`,
    success: (r) => r
  });
};

const getDownloadDocumentUrl = (meldingId: string, documentId: string) => {
  return `${Configuration.apiUrl}/melding/${meldingId}/document/${documentId}/actions/download`;
};

const searchMeldingen = (request: SearchMeldingenRequest): Promise<SearchMeldingenResponse> => {
  return get<SearchMeldingenResponse>({
    uri: `${Configuration.apiUrl}/melding/actions/search?${objectToQueryString(request)}`,
    success: (r) => SearchMeldingenResponse.fromJson(r)
  });
};

const getKoppelZaakLink = (meldingId: string) => {
  return `${Configuration.apiUrl}/melding/${meldingId}/actions/koppelzaak`;
};

export const meldingApi = {
  createConcept,
  createSaveMelding,
  createValidate,
  determineUrlSegment,
  getMelding,
  getMeldingrechten: getMeldingrechten,
  getDownloadSamenvattingUrl,
  getMijnMeldingen,
  getMeldingUri,
  getWerkbakMelding,
  getWerkbakMeldingrechten,
  getDouaneMelding,
  getDouaneMeldingrechten,
  getDuikarbeidMelding,
  getDuikarbeidMeldingrechten,
  getOntheffingLiftenMelding,
  getOntheffingLiftenMeldingrechten,
  getOntheffingNachtarbeidMelding,
  getOntheffingNachtarbeidMeldingrechten,
  getVuurOpTankschipMelding,
  getVuurOpTankschipMeldingrechten,
  getArieMelding,
  getArieMeldingrechten,
  getAsbestRisicoklasse1Melding,
  getAsbestRisicoklasse1Meldingrechten,
  getBouwprocesMelding,
  getBouwprocesMeldingrechten,
  getContactMelding,
  getContactMeldingrechten,
  getKlachtMelding,
  getKlachtMeldingrechten,
  getKlachtenMelding,
  getKlachtenMeldingrechten,
  getOngevalMelding,
  getOngevalMeldingrechten,
  getOntheffingKinderarbeidMelding,
  getOntheffingKinderarbeidMeldingrechten,
  getOntheffingKinderarbeidImportVoorbeeldbestandUrl,
  getProductveiligheidMelding,
  getProductveiligheidMeldingrechten,
  getBiologischeAgentiaMelding,
  getBiologischeAgentiaMeldingrechten,
  getVluchtigeOrganischeStoffenMelding,
  getVluchtigeOrganischeStoffenMeldingrechten,
  getReactieOpenbaarmakingMelding,
  getReactieOpenbaarmakingMeldingrechten,
  getDigipassMelding,
  getDigipassMeldingrechten,
  getUploadGeneriekMelding,
  getUploadGeneriekMeldingrechten,
  getUploadRieMelding,
  getUploadRieMeldingrechten,
  getCbiMelding,
  getCbiMeldingrechten,
  getAsbestverwijderingMelding,
  getAsbestverwijderingMeldingrechten,
  getAsbestverwijderingLavsMelding,
  getAsbestverwijderingLavsMeldingrechten,
  getAsbestverwijderingLavsV2Melding,
  getAsbestverwijderingLavsV2Meldingrechten,
  getAanwijzingRisicovolleBedrijvenMelding,
  getAanwijzingRisicovolleBedrijvenMeldingrechten,
  getBergplaatsRadioactieveStoffenMelding,
  getBergplaatsRadioactieveStoffenMeldingrechten,
  getGevaarlijkeGassenImportcontainersMelding,
  getGevaarlijkeGassenImportcontainersMeldingrechten,
  getGevaarlijkeLiftMelding,
  getGevaarlijkeLiftMeldingrechten,
  getHandelingenIoniserendeStralingMelding,
  getHandelingenIoniserendeStralingMeldingrechten,
  getOngewoneVoorvallenMelding,
  getOngewoneVoorvallenMeldingrechten,
  getBibobMelding,
  getBibobMeldingrechten,
  getInformatieverzoekMelding,
  getInformatieverzoekMeldingrechten,
  getWooMelding,
  getWooMeldingrechten,
  getPredicaatKoninklijkMelding,
  getPredicaatKoninklijkMeldingrechten,
  getVerzoekTotGezamenlijkeInterventieMelding,
  getVerzoekTotGezamenlijkeInterventieMeldingrechten,
  getOntheffingVuurOpTankschipMelding,
  getOntheffingVuurOpTankschipMeldingrechten,
  getAvvArtikel10Melding,
  getAvvArtikel10Meldingrechten,
  getAvvArtikel10AVerzoekMelding,
  getAvvArtikel10AVerzoekMeldingrechten,
  getZaken,
  getKoppelZaakLink,
  getDocumenten,
  getDownloadDocumentUrl,
  intrekkenMelding,
  searchMeldingen,
  getDownloadVertaalopdrachtUrl
};
