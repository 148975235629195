import { AntwoordBlokken, ReactieOpenbaarmakingReactieAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasReactieOpenbaarmakingReactieAntwoorden } from "./has-reactie-openbaarmaking-reactie-antwoorden";

export class ReactieOpenbaarmakingMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements HasReactieOpenbaarmakingReactieAntwoorden
{
  constructor(public reactie: ReactieOpenbaarmakingReactieAntwoorden) {
    super();
  }

  static initialize(user: User | null = null): ReactieOpenbaarmakingMeldingAntwoordBlokken {
    return new ReactieOpenbaarmakingMeldingAntwoordBlokken(ReactieOpenbaarmakingReactieAntwoorden.initialize(user));
  }

  static fromJson(json: any): ReactieOpenbaarmakingMeldingAntwoordBlokken {
    return new ReactieOpenbaarmakingMeldingAntwoordBlokken(ReactieOpenbaarmakingReactieAntwoorden.fromJson(json.reactie));
  }
}
