import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";
import { ParticuliereOpdrachtgeverAntwoorden } from "./particuliere-opdrachtgever-antwoorden";

export class OpdrachtgeverAsbestAntwoorden extends AntwoordBlok {
  constructor(
    public datumSloopmelding: Antwoord<string>,
    public aanvraagnummerSloopmelding: Antwoord<string>,
    public sloopmeldingsplicht: Antwoord<string>,
    public sloopmeldingBijBevoegdGezag: Antwoord<string>,
    public gegevensGelijk: Antwoord<string>,
    public bedrijfOfParticulier: Antwoord<string>,
    public particuliereOpdrachtgever: ParticuliereOpdrachtgeverAntwoorden | null,
    public opdrachtgever: OpdrachtgeverAntwoorden | null
  ) {
    super();
  }

  static initialize(): OpdrachtgeverAsbestAntwoorden {
    return new OpdrachtgeverAsbestAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      ParticuliereOpdrachtgeverAntwoorden.initialize(),
      OpdrachtgeverAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OpdrachtgeverAsbestAntwoorden {
    return new OpdrachtgeverAsbestAntwoorden(
      Antwoord.fromJson<string>(json.datumSloopmelding),
      Antwoord.fromJson<string>(json.aanvraagnummerSloopmelding),
      Antwoord.fromJson<string>(json.sloopmeldingsplicht),
      Antwoord.fromJson<string>(json.sloopmeldingBijBevoegdGezag),
      Antwoord.fromJson<string>(json.gegevensGelijk),
      Antwoord.fromJson<string>(json.bedrijfOfParticulier),
      ParticuliereOpdrachtgeverAntwoorden.fromJson(json.particuliereOpdrachtgever),
      OpdrachtgeverAntwoorden.fromJson(json.opdrachtgever)
    );
  }
}
