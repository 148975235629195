import { Antwoord } from "../antwoord";
import { AntwoordBlokCollectionItem } from "./antwoord-blok-collection-item";

export class WerkmomentGegevensAntwoorden extends AntwoordBlokCollectionItem {
  static get type(): string {
    return "WerkmomentGegevensAntwoorden";
  }
  static WerkmomentenBeleidsregelsValidationErrorCode = "WERKMOMENTEN_BELEIDSREGELS_VALIDATION";
  static WerkmomentenNietGekoppeldeKinderenValidationErrorCode = "WERKMOMENTEN_NIET_GEKOPPELDE_KINDEREN_VALIDATION";
  static WerkmomentenKindnummersNietUniekValidationErrorCode = "WERKMOMENTEN_NIET_UNIEKE_KINDNUMMERS_VALIDATION";

  constructor(
    public datumWerkmoment: Antwoord<string>,
    public aanvangAanwezig: Antwoord<string>,
    public eindeAanwezig: Antwoord<string>,
    public kind: Antwoord<string>,
    public locatieWerkmoment: Antwoord<string>,
    public repetitie: Antwoord<string>,
    public schooldag: Antwoord<string>,
    public schoolweek: Antwoord<string>,
    public uitsluitenWerkmomentControle: Antwoord<string>,
    json?: any
  ) {
    super(WerkmomentGegevensAntwoorden.type, json);
  }

  static initialize(): WerkmomentGegevensAntwoorden {
    return new WerkmomentGegevensAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): WerkmomentGegevensAntwoorden {
    return new WerkmomentGegevensAntwoorden(
      Antwoord.fromJson<string>(json.datumWerkmoment),
      Antwoord.fromJson<string>(json.aanvangAanwezig),
      Antwoord.fromJson<string>(json.eindeAanwezig),
      Antwoord.fromJson<string>(json.kind),
      Antwoord.fromJson<string>(json.locatieWerkmoment),
      Antwoord.fromJson<string>(json.repetitie),
      Antwoord.fromJson<string>(json.schooldag),
      Antwoord.fromJson<string>(json.schoolweek),
      Antwoord.fromJson<string>(json.uitsluitenWerkmomentControle),
      json
    );
  }

  static copyFromJson(json: any) {
    return WerkmomentGegevensAntwoorden.fromJson({
      ...json,
      ...AntwoordBlokCollectionItem.copyAsNewInstance(json)
    });
  }
}
