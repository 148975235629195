import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Bestandenvraag } from "../bestandenvraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class OntheffingLiftenAanvraagVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public ontheffingLiftenAanvraagToelichting: Tekst,
    public waaromGeenAnderePlaatsLift: Vraag,
    public watNodigVoorSchacht: Vraag,
    public redenNietUitvoerbaar: Vraag,
    public overigeOpmerkingen: Vraag,
    public bijlageTitel: Tekst,
    public bijlageOntheffingLiftenParagraaf: Tekst,
    public bestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): OntheffingLiftenAanvraagVragen {
    return new OntheffingLiftenAanvraagVragen(
      key,
      json.volgnummer,
      json.titel,
      json.titelVerkort,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toParagraaf(`${key}.ontheffingLiftenAanvraagToelichting`, json.ontheffingLiftenAanvraagToelichting),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.waaromGeenAnderePlaatsLift`, json.waaromGeenAnderePlaatsLift),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.watNodigVoorSchacht`, json.watNodigVoorSchacht),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.redenNietUitvoerbaar`, json.redenNietUitvoerbaar),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.overigeOpmerkingen`, json.overigeOpmerkingen),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageOntheffingLiftenParagraaf`, json.bijlageOntheffingLiftenParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden)
    );
  }
}
