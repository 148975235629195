import { AutorisatiesSortOption } from ".";

export class GetAutorisatiesRequest {
  constructor(
    public sortOption: AutorisatiesSortOption,
    public pageNumber: number,
    public pageSize: number,

    public bedrijfsnaam: string | null,
    public gebruikersnaam: string | null,
    public achternaam: string | null,
    public kvknummer: string | null,
    public vestigingsnummer: string | null,
    public geldig: string | null
  ) {}
}
