import { TeVerwijderenAsbestMateriaalLavsV2Vragen } from "models/api/blok/te-verwijderen-asbest-materiaal-lavs-v2-vragen";
import { SamengesteldeVraag } from "../../../../models/api";
import { SamengesteldeVraagType } from "../../../../models/application";
import { SamengesteldeVraagComponent } from "./samengestelde-vraag-component";

export class TeVerwijderenAsbestMateriaalLavsV2 {
  static getType = (): SamengesteldeVraagType => SamengesteldeVraagType.teVerwijderenAsbestMateriaalLavsV2;

  static create = (vraag: SamengesteldeVraag<TeVerwijderenAsbestMateriaalLavsV2Vragen>): React.JSX.Element =>
    SamengesteldeVraagComponent.create(vraag);
}
