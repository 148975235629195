import { securityApi } from "api";
import { Alert } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { translationKeys } from "../../../constants/translation-keys";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";
import { ProfielFormulier } from "../../formulier/profiel";
import { GuardedLink } from "../../guarded-link";
import { VisibleWrapper } from "../../visible-wrapper";
import { MijnProfielProps } from "./mijn-profiel.component.interfaces";

export const MijnProfiel = (props: MijnProfielProps) => {
  const { t } = useTranslation();
  const autorisatiegegevens = Autorisatiegegevens.initialize(props.user);
  const isExternalLogin = !!autorisatiegegevens?.isExternalLogin;

  return (
    <Row className="main-container" role="main">
      <Col xs={12} className="main-container-title">
        <h1>{t(translationKeys.profiel.mijnProfiel.titel)}</h1>
        <div>{t(translationKeys.profiel.mijnProfiel.helptekst)}</div>
        <VisibleWrapper isVisible={props.showProfileIncompleteWarning}>
          <Alert variant="warning">{t(translationKeys.profiel.mijnProfiel.profielgegevensAanvullen)}</Alert>
        </VisibleWrapper>
        <Row>
          <Col xs={12} sm={3}>
            <div className="profiel-menu-item">
              <GuardedLink url="/profiel">{t(translationKeys.blok.profiel.profielGegevensBekijken)}</GuardedLink>
            </div>
            <div className="profiel-menu-item">
              <GuardedLink url="/profiel/wijzigen">{t(translationKeys.blok.profiel.profielGegevensWijzigen)}</GuardedLink>
            </div>
            <VisibleWrapper isVisible={!isExternalLogin}>
              <div className="profiel-menu-item">
                <GuardedLink url={`${securityApi.getChangePasswordUrl(window.location.href)}`} skipRouter={true}>
                  {t(translationKeys.blok.profiel.profielWachtwoordWijzigen)}
                </GuardedLink>
              </div>
            </VisibleWrapper>
          </Col>
          <Col xs={12} sm={9} className="main-container-title">
            <Routes>
              <Route path="/" element={<ProfielFormulier enableEdit={false}></ProfielFormulier>} />
              <Route path="/wijzigen" element={<ProfielFormulier enableEdit={true}></ProfielFormulier>} />
            </Routes>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
