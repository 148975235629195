import { connect } from "react-redux";
import { WerkbakFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getWerkbakMelding, initWerkbakFormulier, initWerkbakFormulierFromCopy } from "../../../thunks";
import { WerkbakFormulierComponent } from "./werkbak-formulier.component";
import {
  WerkbakFormulierComponentDispatchProps,
  WerkbakFormulierComponentStateProps
} from "./werkbak-formulier.component.interfaces";

const mapStateToProps = (state: State): WerkbakFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.werkbak ? (state.vragen.formulier as WerkbakFormulier) : undefined;
  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): WerkbakFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initWerkbakFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getWerkbakMelding(meldingId));
    } else {
      dispatch(initWerkbakFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WerkbakFormulierComponent);
