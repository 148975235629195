import { ContactMelderVragen, ContactVraagVragen, VraagBlokken } from "../blok";
import { HasContactVraagVragen } from "./has-contactvraag-vragen";

export class ContactFormulierVraagBlokken extends VraagBlokken implements HasContactVraagVragen {
  constructor(
    public contactMelder: ContactMelderVragen,
    public contactVraag: ContactVraagVragen
  ) {
    super();
  }

  static fromJson(json: any): ContactFormulierVraagBlokken {
    return new ContactFormulierVraagBlokken(
      ContactMelderVragen.fromJson("contactMelder", json.contactMelder),
      ContactVraagVragen.fromJson("contactVraag", json.contactVraag)
    );
  }
}
