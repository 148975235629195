import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";
import { GegevensAanvragerOntheffingNachtarbeidVragen } from "./gegevens-aanvrager-ontheffing-nachtarbeid-vragen";

export class InleidingNachtarbeidVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public invullenVoorZichzelfEnOuderDan18: Meerkeuzevraag,
    public werkgeverOpDeHoogteVanVerzoekOntheffing: Meerkeuzevraag,
    public aanvraagOmBijzondereSociaalMaatschappelijkePriveOmstandigheden: Meerkeuzevraag,
    public gegevensAanvragerOntheffingNachtarbeid: GegevensAanvragerOntheffingNachtarbeidVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): InleidingNachtarbeidVragen {
    return new InleidingNachtarbeidVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.invullenVoorZichzelfEnOuderDan18`, json.invullenVoorZichzelfEnOuderDan18),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.werkgeverOpDeHoogteVanVerzoekOntheffing`,
        json.werkgeverOpDeHoogteVanVerzoekOntheffing
      ),
      jsonHelpers.toMeerkeuzeVraag(
        `${key}.aanvraagOmBijzondereSociaalMaatschappelijkePriveOmstandigheden`,
        json.aanvraagOmBijzondereSociaalMaatschappelijkePriveOmstandigheden
      ),
      GegevensAanvragerOntheffingNachtarbeidVragen.fromJson(
        `${key}.gegevensAanvragerOntheffingNachtarbeid`,
        json.gegevensAanvragerOntheffingNachtarbeid
      )
    );
  }
}
