import { userHelpers } from "helpers";
import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { Vestiging } from "../extern";
import { User } from "../security/user";

export class BedrijfsgegevensAntwoorden extends AntwoordBlok {
  constructor(public geselecteerdeVestiging: Antwoord<number | null>, public vestiging: Antwoord<Vestiging | null>) {
    super();
  }

  static initialize(user: User | null = null): BedrijfsgegevensAntwoorden {
    return userHelpers.getIsInternalUser(user)
      ? BedrijfsgegevensAntwoorden.initialize()
      : new BedrijfsgegevensAntwoorden(
          new Antwoord<number | null>(user?.vestigingId ?? null),
          new Antwoord<Vestiging | null>(null)
        );
  }

  static fromJson(json: any): BedrijfsgegevensAntwoorden | null {
    return json
      ? new BedrijfsgegevensAntwoorden(
          Antwoord.fromJson<number | null>(json.geselecteerdeVestiging),
          Antwoord.fromJson<Vestiging | null>({
            waarde: json.vestiging?.waarde ? Vestiging.fromMeldingJson(json.vestiging.waarde) : null
          })
        )
      : null;
  }
}
