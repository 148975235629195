import { setExterneReferentie } from "actions";
import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import {
  Antwoord,
  BedrijfslocatiegegevensAntwoorden,
  Bestand,
  VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken
} from "models/api";
import { User } from "models/api/security/user";
import { ExterneReferentie } from "models/application";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initVerzoekTotGezamenlijkeInterventieFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getVerzoekTotGezamenlijkeInterventieFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getVerzoekTotGezamenlijkeInterventieFormulierDefinitie,
      VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken.initialize
    );
  };

export const initVerzoekTotGezamenlijkeInterventieFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getVerzoekTotGezamenlijkeInterventieFormulier(),
      meldingApi.getVerzoekTotGezamenlijkeInterventieMelding(meldingId)
    ]);

    if (
      melding.basisgegevens.externReferentienummer &&
      melding.basisgegevens.externReferentienummerType &&
      melding.basisgegevens.ontvangstwijze &&
      melding.basisgegevens.aanmaakDateTime
    ) {
      dispatch(
        setExterneReferentie(
          new ExterneReferentie(
            melding.basisgegevens.externReferentienummer,
            melding.basisgegevens.externReferentienummerType,
            melding.basisgegevens.ontvangstwijze,
            melding.basisgegevens.aanmaakDateTime
          )
        )
      );
    }

    const initAntwoordenFromCopy = (user: User | null): VerzoekTotGezamenlijkeInterventieMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        locatie: BedrijfslocatiegegevensAntwoorden.initialize(),
        bijlage: {
          ...melding.blokken.bijlage,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getVerzoekTotGezamenlijkeInterventieFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
