import {
  BedrijfslocatiegegevensVragen,
  BijlageVragen,
  VerzoekTotGezamenlijkeInterventieMelderVragen,
  VerzoekTotGezamenlijkeInterventieMeldingVragen,
  VraagBlokken
} from "../blok";

export class VerzoekTotGezamenlijkeInterventieFormulierVraagBlokken extends VraagBlokken {
  [key: string]: any;

  constructor(
    public melding: VerzoekTotGezamenlijkeInterventieMeldingVragen,
    public locatie: BedrijfslocatiegegevensVragen,
    public melder: VerzoekTotGezamenlijkeInterventieMelderVragen,
    public bijlage: BijlageVragen
  ) {
    super();
  }

  static fromJson(json: any): VerzoekTotGezamenlijkeInterventieFormulierVraagBlokken {
    return new VerzoekTotGezamenlijkeInterventieFormulierVraagBlokken(
      VerzoekTotGezamenlijkeInterventieMeldingVragen.fromJson("melding", json.melding),
      BedrijfslocatiegegevensVragen.fromJson("locatie", json.locatie),
      VerzoekTotGezamenlijkeInterventieMelderVragen.fromJson("melder", json.melder),
      BijlageVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
