import { userHelpers } from "helpers/user-helper";
import {
  clearVestigingSearchCriteria,
  clearVestigingSearchResult,
  resetVestigingenPaging,
  setSelectedVestiging,
  setVestigingenPage,
  setVestigingenPagingEnded,
  setVestigingenPagingStarted,
  setVestigingenSearchCriteria,
  setVestigingenSearchingEnded,
  setVestigingenSearchingStarted,
  setVestigingenSearchResult
} from "../../actions/blok/vestigingen-actions";
import { vestigingApi } from "../../api";
import { BedrijfsgegevensAntwoorden, Vestiging } from "../../models/api";
import { Autorisatiegegevens } from "../../models/api/security/autorisatiegegevens";
import { VestigingenSearchCriteria } from "../../store/blok/vestigingen";
import { AppThunk } from "../app-thunk-type";

export const setVestigingSearchById =
  (vestigingId: number, vestigingKey: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setVestigingenSearchingStarted(vestigingKey));

    const vestigingenResponse = await vestigingApi.getVestiging(vestigingId);
    if (vestigingenResponse) {
      dispatch(setSelectedVestiging(vestigingKey, vestigingenResponse));
    }

    dispatch(setVestigingenSearchingEnded(vestigingKey));
  };

export const searchVestigingenWithCriteria =
  (values: VestigingenSearchCriteria, vestigingenKey: string, abortSignal?: AbortSignal): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(resetVestigingenPaging(vestigingenKey));
    dispatch(setVestigingenSearchCriteria(vestigingenKey, values));

    dispatch(searchVestigingen(true, vestigingenKey, abortSignal));
  };

export const searchVestigingenWithPaging =
  (page: number, vestigingenKey: string, abortSignal?: AbortSignal): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setVestigingenPage(vestigingenKey, page));

    dispatch(searchVestigingen(false, vestigingenKey, abortSignal));
  };

const searchVestigingen =
  (fullSearch: boolean, vestigingKey: string, abortSignal?: AbortSignal): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    if (fullSearch) {
      dispatch(setVestigingenSearchingStarted(vestigingKey));
    }
    dispatch(setVestigingenPagingStarted(vestigingKey));

    const vestigingenSearch = getState().vestigingen.searches[vestigingKey];

    const isInternalUser = userHelpers.getIsInternalUser(getState().security.user);
    const vestigingenResponse = await vestigingApi.searchVestigingen(
      vestigingenSearch.searching,
      vestigingenSearch.paging,
      isInternalUser,
      abortSignal
    );

    if (!abortSignal?.aborted) {
      dispatch(
        setVestigingenSearchResult(vestigingKey, {
          vestigingen: vestigingenResponse.vestigingen.map((v) => Vestiging.fromJson(v)),
          totaalAantalVestigingen: vestigingenResponse.totalItemCount
        })
      );
    }

    dispatch(setVestigingenPagingEnded(vestigingKey));
    dispatch(setVestigingenSearchingEnded(vestigingKey));
  };

export const clearVestigingSearch =
  (vestigingKey: string): AppThunk =>
  (dispatch): void => {
    dispatch(resetVestigingenPaging(vestigingKey));
    dispatch(clearVestigingSearchResult(vestigingKey));
    dispatch(clearVestigingSearchCriteria(vestigingKey));

    dispatch(setVestigingenSearchingEnded(vestigingKey));
    dispatch(setVestigingenPagingEnded(vestigingKey));
  };

export const searchVestigingWithAutorisatieGegevensAndBedrijfsgegevens =
  (
    autorisatieGegevens: Autorisatiegegevens,
    bedrijfsgegevensAntwoorden: BedrijfsgegevensAntwoorden | null,
    key: string
  ): AppThunk =>
  (dispatch): void => {
    if (bedrijfsgegevensAntwoorden) {
      const vestigingId = bedrijfsgegevensAntwoorden.geselecteerdeVestiging.waarde;

      if (autorisatieGegevens.isBedrijf && autorisatieGegevens.isBedrijfIngeschreven && vestigingId) {
        dispatch(setVestigingSearchById(vestigingId, key));
      }
    }
  };
