import { clearImportFileState, setBestand } from "actions";
import { connect } from "react-redux";
import { State } from "../../../store";
import { InputFileImport } from "./input-file-import";
import { InputFileImportDispatchProps, InputFileImportStateProps } from "./input-file-import.interface";

const mapStateToProps = (state: State): InputFileImportStateProps => ({
  file: null,
  errors: state.bestandImport.errors,
  result: state.bestandImport.result
});

const mapDispatchToProps = (dispatch: any): InputFileImportDispatchProps => ({
  onAddFile: (file: File) => dispatch(setBestand(file)),
  onRemoveFile: () => dispatch(clearImportFileState())
});

export default connect(mapStateToProps, mapDispatchToProps)(InputFileImport);
