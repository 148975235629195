import React from "react";
import { InputNumberBase } from "./input-number-base";

export const InputNumber = (props: any) => {
  const parsedValue = parseInt(props.value);
  const convertedProps = {
    ...props,
    value: !isNaN(parsedValue) ? parsedValue : ""
  };

  return <InputNumberBase {...convertedProps} />;
};
