import { clearImportFileState } from "actions/bestand-import-actions";
import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, MelderAntwoorden, OntheffingKinderarbeidMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initOntheffingKinderarbeidFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getOntheffingKinderarbeidFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getOntheffingKinderarbeidFormulierDefinitie,
      OntheffingKinderarbeidMeldingAntwoordBlokken.initialize
    );

    dispatch(clearImportFileState());
  };

export const initOntheffingKinderarbeidFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([
      formulierApi.getOntheffingKinderarbeidFormulier(),
      meldingApi.getOntheffingKinderarbeidMelding(meldingId)
    ]);

    const initAntwoordenFromCopy = (user: User | null): OntheffingKinderarbeidMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: MelderAntwoorden.initialize(user),
        bijlage: {
          ...melding.blokken.bijlage,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getOntheffingKinderarbeidFormulierDefinitie,
      initAntwoordenFromCopy
    );

    dispatch(clearImportFileState());
  };
