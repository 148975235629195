import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { User } from "../security/user";

export class ContactpersoonAntwoorden extends AntwoordBlok {
  constructor(
    public voorletters: Antwoord<string>,
    public tussenvoegsels: Antwoord<string>,
    public achternaam: Antwoord<string>,
    public geslacht: Antwoord<string>,
    public telefoonnummer: Antwoord<string>,
    public emailadres: Antwoord<string>
  ) {
    super();
  }

  static initialize(user: User | null = null): ContactpersoonAntwoorden {
    return new ContactpersoonAntwoorden(
      new Antwoord<string>(user?.voorletters || ""),
      new Antwoord<string>(user?.tussenvoegsels ?? ""),
      new Antwoord<string>(user?.acthternaam ?? ""),
      new Antwoord<string>(user?.geslacht ?? ""),
      new Antwoord<string>(user?.telefoonnummer ?? ""),
      new Antwoord<string>(user?.emailadres ?? "")
    );
  }

  static fromJson(json: any): ContactpersoonAntwoorden | null {
    return json
      ? new ContactpersoonAntwoorden(
          Antwoord.fromJson<string>(json.voorletters),
          Antwoord.fromJson<string>(json.tussenvoegsels),
          Antwoord.fromJson<string>(json.achternaam),
          Antwoord.fromJson<string>(json.geslacht),
          Antwoord.fromJson<string>(json.telefoonnummer),
          Antwoord.fromJson<string>(json.emailadres)
        )
      : null;
  }
}
