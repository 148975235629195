import { VraagBlok } from "../vraag-blok";
import { Dictionary } from "../../application";
import { MelderVragen } from "./melder-vragen";
import { Vraag } from "../vraag";
import { jsonHelpers } from "../../../helpers";

export class ContactMelderVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public anoniemeMelder: Vraag,
    public melder: MelderVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): ContactMelderVragen {
    return new ContactMelderVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toMeerkeuzeVraag(`${key}.anoniemeMelder`, json.anoniemeMelder),
      MelderVragen.fromJson(`${key}.melder`, json.melder)
    );
  }
}
