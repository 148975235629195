import { Fragment } from "react";
import { formulierHelpers } from "../../../helpers";
import { BedrijfsgegevensVragen, HasVolgnummer, VraagBlok } from "../../../models/api";
import { VraagBlokType } from "../../../models/application";
import { withConditioneelGesteldeSamenvattingItem } from "../../samenvatting/with-conditioneel-gestelde-samenvatting-item";
import { VestigingSamenvatting } from "../vestiging-samenvatting";
import { GeneriekSamenvattingProps } from "./generiek-samenvatting-blok.component.interfaces";
import { SamengesteldeVraagComponenten } from "./supported-samengestelde-vraag-typen";
import { TekstComponenten } from "./supported-tekst-typen";
import { VraagBlokCollectionComponenten } from "./supported-vraag-blok-collection-typen";
import { VraagComponenten } from "./supported-vraag-typen";

export const SamenvattingItemComponent = (props: { item: HasVolgnummer }) => {
  const component = formulierHelpers.getGeneriekComponent(
    VraagComponenten,
    TekstComponenten,
    VraagBlokCollectionComponenten,
    SamengesteldeVraagComponenten,
    props.item,
    (type: string, key: string) => (
      <div>
        niet ondersteund type {type} {key}
      </div>
    )
  );

  if (component) {
    return component;
  } else if (formulierHelpers.isVraagBlok(props.item)) {
    const vraagBlok = props.item as VraagBlok;

    if (vraagBlok.type === VraagBlokType.vestiging) {
      return <VestigingSamenvatting vragen={vraagBlok as BedrijfsgegevensVragen} />;
    } else {
      return <GeneriekSamenvattingBlok vragen={vraagBlok} isChildLevel={true} />;
    }
  } else {
    return <div>niet ondersteund item</div>;
  }
};

const GeneriekSamenvattingBlokInternal = (props: GeneriekSamenvattingProps) => {
  const { vragen } = props;

  const items = formulierHelpers.getVolgnummerItems(vragen);

  return (
    <Fragment>
      {items.map((i) => (
        <SamenvattingItemComponent item={i} key={i.key} />
      ))}
    </Fragment>
  );
};

const GeneriekVraagBlokConditioneelGesteld = withConditioneelGesteldeSamenvattingItem(GeneriekSamenvattingBlokInternal);

export const GeneriekSamenvattingBlok = (props: GeneriekSamenvattingProps) => {
  return props.isChildLevel ? (
    <>
      <GeneriekVraagBlokConditioneelGesteld item={props.vragen} {...props} />
    </>
  ) : (
    <GeneriekSamenvattingBlokInternal {...props} />
  );
};
