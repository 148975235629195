import { PredicaatKoninklijkFormulier } from "models/api/formulier/predicaat-koninklijk-formulier";
import { connect } from "react-redux";
import {
  getPredicaatKoninklijkformulierMelding,
  initPredicaatKoninklijkFormulier,
  initPredicaatKoninklijkFormulierFromCopy
} from "thunks";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { PredicaatKoninklijkFormulierComponent } from "./predicaat-koninklijk-formulier.component";
import {
  PredicaatKoninklijkFormulierComponentDispatchProps,
  PredicaatKoninklijkFormulierComponentStateProps
} from "./predicaat-koninklijk-formulier.component.interfaces";

const mapStateToProps = (state: State): PredicaatKoninklijkFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.predicaatKoninklijk
      ? (state.vragen.formulier as PredicaatKoninklijkFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): PredicaatKoninklijkFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initPredicaatKoninklijkFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getPredicaatKoninklijkformulierMelding(meldingId));
    } else {
      dispatch(initPredicaatKoninklijkFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PredicaatKoninklijkFormulierComponent);
