import { Documenten } from "components/documenten";
import { Document } from "models/api/document";

export const SamenvattingDocumenten = (props: { meldingId: string | undefined; documenten: Document[] | undefined }) => {
  return (
    (props.documenten && props.meldingId && (
      <>
        <h2>Documenten</h2>
        <Documenten documenten={props.documenten} meldingId={props.meldingId} />
      </>
    )) ||
    null
  );
};
