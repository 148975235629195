import { connect } from "react-redux";
import { ProfielVragen } from "../../../models/api";
import { ProcesstapState } from "../../../models/application";
import { State } from "../../../store";
import { Profiel } from "./profiel.component";
import { ProfielOwnProps, ProfielStateProps } from "./profiel.component.interfaces";

const mapStateToProps = (state: State, ownProps: ProfielOwnProps): ProfielStateProps => {
  const defaultValues = {
    isActive: state.proces.items.some((i) => i.type === ownProps.processtap && i.state === ProcesstapState.active),
    vragen: state.vragen.formulier?.blokken[ownProps.processtap] as ProfielVragen,
    profielOpgeslagen: state.security.profileSaved
  };

  return defaultValues;
};

export default connect(mapStateToProps)(Profiel);
