import { connect } from "react-redux";
import PageTitle from "./page-title.component";
import { PageTitleProps } from "./page-title.component.interfaces";
import { State } from "../../store";

const mapStateToProps = (state: State): PageTitleProps => ({
  breadcrumbs: state.breadcrumbs.items
});

export default connect(mapStateToProps)(PageTitle);
