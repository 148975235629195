import { Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { VraagBlok } from "../vraag-blok";

export class PeriodeDatumTijdVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public begindatumtijd: Vraag,
    public einddatumtijd: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): PeriodeDatumTijdVragen {
    return new PeriodeDatumTijdVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toDatumTijdVraag(`${key}.begindatumtijd`, json.begindatumtijd),
      jsonHelpers.toDatumTijdVraag(`${key}.einddatumtijd`, json.einddatumtijd)
    );
  }
}
