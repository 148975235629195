import { OntheffingKinderarbeidFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getOntheffingKinderarbeidFormulierDefinitie = (formulier: OntheffingKinderarbeidFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.melder.titel,
      type: ProcesstapType.melder
    },
    {
      title: formulier.blokken.werkgever.titel,
      type: ProcesstapType.werkgever
    },
    {
      title: formulier.blokken.productie.titel,
      type: ProcesstapType.productie
    },
    {
      title: formulier.blokken.importerenKinderarbeid.titelVerkort,
      type: ProcesstapType.importerenKinderarbeid
    },
    {
      title: formulier.blokken.locaties.titelVerkort,
      type: ProcesstapType.locaties,
      group: "locaties-kinderen-werkmomenten"
    },
    {
      title: formulier.blokken.kinderen.titelVerkort,
      type: ProcesstapType.kinderen,
      group: "locaties-kinderen-werkmomenten"
    },
    {
      title: formulier.blokken.werkmomenten.titelVerkort,
      type: ProcesstapType.werkmomenten,
      group: "locaties-kinderen-werkmomenten"
    },
    {
      title: formulier.blokken.voorwaarden.titel,
      type: ProcesstapType.voorwaarden
    }
  ];
};
