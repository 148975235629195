import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { AntwoordBlokCollection } from "./antwoord-blok-collection";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";
import { GegevensInstallatieAntwoorden } from "./gegevens-installatie-antwoorden";
import { PersoonsgegevensAntwoorden } from "./persoonsgegevens-antwoorden";

export class ArieLocatieAntwoorden extends AntwoordBlok {
  constructor(
    public arieLocatieWijzigingDoorgeven: Antwoord<string[]>,
    public arieLocatieMeldingBedoeldVoorBrzo: Antwoord<string>,
    public naamInrichting: Antwoord<string>,
    public correspondentieadres: CorrespondentieadresAntwoorden | null,
    public feitelijkLeidinggevende: PersoonsgegevensAntwoorden,
    public medewerkingVerlenendeDeskundige: PersoonsgegevensAntwoorden,
    public gegevensInstallaties: AntwoordBlokCollection<GegevensInstallatieAntwoorden> | null
  ) {
    super();
  }

  static initialize(): ArieLocatieAntwoorden {
    return new ArieLocatieAntwoorden(
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      CorrespondentieadresAntwoorden.initialize(),
      PersoonsgegevensAntwoorden.initialize(),
      PersoonsgegevensAntwoorden.initialize(),
      AntwoordBlokCollection.initialize([])
    );
  }

  static fromJson(json: any): ArieLocatieAntwoorden {
    return new ArieLocatieAntwoorden(
      Antwoord.fromJson<string[]>(json.arieLocatieWijzigingDoorgeven),
      Antwoord.fromJson<string>(json.arieLocatieMeldingBedoeldVoorBrzo),
      Antwoord.fromJson<string>(json.naamInrichting),
      CorrespondentieadresAntwoorden.fromJson(json.correspondentieadres),
      PersoonsgegevensAntwoorden.fromJson(json.feitelijkLeidinggevende),
      PersoonsgegevensAntwoorden.fromJson(json.medewerkingVerlenendeDeskundige),
      AntwoordBlokCollection.fromJson(GegevensInstallatieAntwoorden.fromJson, json.gegevensInstallaties)
    );
  }
}
