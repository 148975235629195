import { useEffect } from "react";
import { LeaveFormulierDialogProps } from "./leave-formulier-dialog.component.interfaces";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../constants/translation-keys";

export const LeaveFormulierDialog = (props: LeaveFormulierDialogProps) => {
  const { t } = useTranslation();

  const { onInit, onDestroy } = props;

  useEffect(() => {
    onInit();

    return () => onDestroy();
  }, [onInit, onDestroy]);

  return (
    <Modal
      show={props.isLeaveFormulierDialogShown}
      aria-labelledby="leave-formulier-dialog-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="dialog-title" id="leave-formulier-dialog-title">
          {t(translationKeys.dialoog.formulierVerlaten.titel)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>{t(translationKeys.dialoog.formulierVerlaten.tekst)}</>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onLeaveFormulier}>
          {t(translationKeys.dialoog.formulierVerlaten.verlaten)}
        </Button>
        <Button variant="primary" onClick={props.onCancel}>
          {t(translationKeys.dialoog.formulierVerlaten.doorgaan)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
