import { VraagBlokken, AutorisatieaanvraagVragen } from "../blok";
import { HasAutorisatieaanvraagVragen } from "./has-autorisatieaanvraag-vragen";

export class AutorisatieaanvraagFormulierVraagBlokken extends VraagBlokken implements HasAutorisatieaanvraagVragen {
  [key: string]: any;

  constructor(public autorisatieaanvraag: AutorisatieaanvraagVragen) {
    super();
  }

  static fromJson(json: any): AutorisatieaanvraagFormulierVraagBlokken {
    return new AutorisatieaanvraagFormulierVraagBlokken(
      AutorisatieaanvraagVragen.fromJson("autorisatieaanvraag", json.autorisatieaanvraag)
    );
  }
}
