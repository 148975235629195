import { jsonHelpers } from "helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";
import { LocatieVragen } from "./locatie-vragen";

export class LocatieMetToelichtingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public toelichting: Tekst,
    public locatie: LocatieVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): LocatieMetToelichtingVragen {
    return new LocatieMetToelichtingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(`${key}.toelichting`, json.toelichting),
      LocatieVragen.fromJson(`${key}.locatie`, json.locatie)
    );
  }
}
