import { connect } from "react-redux";
import { VluchtigeOrganischeStoffenFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getVluchtigeOrganischeStoffenformulierMelding,
  initVluchtigeOrganischeStoffenFormulier,
  initVluchtigeOrganischeStoffenFormulierFromCopy
} from "../../../thunks";
import { VluchtigeOrganischeStoffenFormulierComponent } from "./vluchtige-organische-stoffen-formulier.component";
import {
  VluchtigeOrganischeStoffenFormulierComponentDispatchProps,
  VluchtigeOrganischeStoffenFormulierComponentStateProps
} from "./vluchtige-organische-stoffen-formulier.component.interfaces";

const mapStateToProps = (state: State): VluchtigeOrganischeStoffenFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.vluchtigeOrganischeStoffen
      ? (state.vragen.formulier as VluchtigeOrganischeStoffenFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): VluchtigeOrganischeStoffenFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initVluchtigeOrganischeStoffenFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getVluchtigeOrganischeStoffenformulierMelding(meldingId));
    } else {
      dispatch(initVluchtigeOrganischeStoffenFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VluchtigeOrganischeStoffenFormulierComponent);
