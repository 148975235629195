import { HasMelderAntwoorden } from ".";
import {
  AntwoordBlokken,
  AsbestWerkzaamhedenAntwoorden,
  InventarisatieAntwoorden,
  LocatieMetToelichtingAntwoorden,
  MelderAntwoorden,
  OpdrachtgeverAsbestAntwoorden,
  PeriodeMetRedenAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasAsbestWerkzaamhedenAntwoorden } from "./has-asbest-werkzaamheden-antwoorden";
import { HasInventarisatieAntwoorden } from "./has-inventarisatie-antwoorden";
import { HasLocatieMetToelichtingAntwoorden } from "./has-locatie-met-toelichting-antwoorden";
import { HasOpdrachtgeverAsbestAntwoorden } from "./has-opdrachtgever-asbest-antwoorden";
import { HasPeriodeMetRedenAntwoorden } from "./has-periode-met-reden-antwoorden";

export class AsbestRisicoklasse1MeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasMelderAntwoorden,
    HasOpdrachtgeverAsbestAntwoorden,
    HasInventarisatieAntwoorden,
    HasPeriodeMetRedenAntwoorden,
    HasLocatieMetToelichtingAntwoorden,
    HasAsbestWerkzaamhedenAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public opdrachtgever: OpdrachtgeverAsbestAntwoorden,
    public inventarisatie: InventarisatieAntwoorden,
    public locatie: LocatieMetToelichtingAntwoorden,
    public periode: PeriodeMetRedenAntwoorden,
    public werkzaamheden: AsbestWerkzaamhedenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): AsbestRisicoklasse1MeldingAntwoordBlokken {
    return new AsbestRisicoklasse1MeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      OpdrachtgeverAsbestAntwoorden.initialize(),
      InventarisatieAntwoorden.initialize(),
      LocatieMetToelichtingAntwoorden.initialize(),
      PeriodeMetRedenAntwoorden.initialize(),
      AsbestWerkzaamhedenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AsbestRisicoklasse1MeldingAntwoordBlokken {
    return new AsbestRisicoklasse1MeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      OpdrachtgeverAsbestAntwoorden.fromJson(json.opdrachtgever),
      InventarisatieAntwoorden.fromJson(json.inventarisatie),
      LocatieMetToelichtingAntwoorden.fromJson(json.locatie),
      PeriodeMetRedenAntwoorden.fromJson(json.periode),
      AsbestWerkzaamhedenAntwoorden.fromJson(json.werkzaamheden)
    );
  }
}
