import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BedrijfsgegevensAntwoorden } from "./bedrijfsgegevens-antwoorden";
import { BuitenlandsAdresAntwoorden } from "./buitenlands-adres-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class ProfielAntwoorden extends AntwoordBlok {
  constructor(
    public gebruikersnaam: Antwoord<string>,
    public voorletters: Antwoord<string>,
    public tussenvoegsels: Antwoord<string>,
    public achternaam: Antwoord<string>,
    public geslacht: Antwoord<string>,
    public meldingenEmailadres: Antwoord<string>,
    public controleMeldingenEmailadres: Antwoord<string>,
    public telefoonnummer: Antwoord<string>,
    public melderAdres: CorrespondentieadresAntwoorden | null,
    public bedrijfsgegevens: BedrijfsgegevensAntwoorden | null,
    public naamBedrijf: Antwoord<string>,
    public buitenlandsAdres: BuitenlandsAdresAntwoorden | null
  ) {
    super();
  }

  static fromJson(json: any): ProfielAntwoorden {
    return new ProfielAntwoorden(
      Antwoord.fromJson<string>(json.gebruikersnaam),
      Antwoord.fromJsonOrDefault<string>(json.voorletters, ""),
      Antwoord.fromJsonOrDefault<string>(json.tussenvoegsels, ""),
      Antwoord.fromJsonOrDefault<string>(json.achternaam, ""),
      Antwoord.fromJsonOrDefault<string>(json.geslacht, ""),
      Antwoord.fromJsonOrDefault<string>(json.meldingenEmailadres, ""),
      Antwoord.fromJsonOrDefault<string>(json.controleMeldingenEmailadres, ""),
      Antwoord.fromJsonOrDefault<string>(json.telefoonnummer, ""),
      CorrespondentieadresAntwoorden.fromJson(json.melderAdres),
      BedrijfsgegevensAntwoorden.fromJson(json.bedrijfsgegevens),
      Antwoord.fromJsonOrDefault<string>(json.naamBedrijf, ""),
      BuitenlandsAdresAntwoorden.fromJson(json.buitenlandsAdres)
    );
  }
}
