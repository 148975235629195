import { getIn, useField, useFormikContext } from "formik";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { clearSubmitValidationResult } from "../../../actions";
import { CaptchaAttributes } from "../../../constants";
import { meldingHelpers } from "../../../helpers";
import { ValidationResponse } from "../../../models/api";
import { State, useAppDispatch } from "../../../store";

export const withValidation = <T extends object>(Component: React.ComponentType<T>, validateOnBlur = true) => {
  return (props: any) => {
    const { validateForm, status } = useFormikContext();

    const [field, meta] = useField(props);

    const serverError = getIn(status, field.name);

    const { onBlur: onFieldBlur, onChange: onFieldChange, ...fieldRest } = field;

    const { onBlur: onPropsBlur, onChange: onPropsChange, validate, ...propsRest } = props;

    const dispatch = useAppDispatch();

    const { submitValidationResults } = useSelector<
      State,
      {
        submitValidationResults: ValidationResponse | null;
      }
    >(
      (state: State) => ({
        submitValidationResults: state.proces.submitValidationResults
      }),
      shallowEqual
    );

    const onBlur = async (e: any) => {
      const eventCopy = { ...e };

      onFieldBlur(eventCopy);

      if (onPropsBlur) {
        onPropsBlur(eventCopy);
      }

      if (validateOnBlur) {
        await validateForm();
      }
    };

    const onChange = (e: any) => {
      const vraagKey = meldingHelpers.getVraagKeyFromFieldName(field.name);

      if (vraagKey === CaptchaAttributes.Name) {
        onCaptchaChange(vraagKey, e);
      } else {
        onInputChange(vraagKey, e);
      }
    };

    const onCaptchaChange = (vraagKey: string, token: string | null) => {
      if (token) {
        clearSubmitValidations(vraagKey);
      }
    };

    const onInputChange = (vraagKey: string, e: any) => {
      const eventCopy = { ...e };

      onFieldChange(eventCopy);

      if (onPropsChange) {
        onPropsChange(eventCopy);
      }

      clearSubmitValidations(vraagKey);
    };

    const clearSubmitValidations = (vraagKey: string) => {
      if (submitValidationResults?.contains(vraagKey)) {
        dispatch(clearSubmitValidationResult(vraagKey));
      }
    };

    return (
      <Component
        {...fieldRest}
        {...propsRest}
        onBlur={onBlur}
        onChange={onChange}
        touched={meta.touched}
        error={meta.error}
        serverError={serverError}
      />
    );
  };
};
