import { useField, useFormikContext } from "formik";
import { userHelpers } from "helpers";
import { AntwoordBlokken } from "../../../../models/api";
import { VestigingenSearchCriteria, store } from "../../../../store";
import { Field } from "../../../input-controls/field";
import { VestigingFormDetails } from "../vestiging-form-details";
import { VestigingFormResults } from "../vestiging-form-results";
import { VestigingFormSearch } from "../vestiging-form-search";
import { VestigingSearchProps } from "./vestiging-search.component.interface";

const getSearchCriteria = (props: VestigingSearchProps): VestigingenSearchCriteria => {
  const state = store.getState();

  const originalCriteria = props.searching.criteria;
  const currentCriteria = { ...originalCriteria };

  if (props.userKvkNummer) {
    currentCriteria.kvkNummer = props.userKvkNummer;
  }

  if (
    props.committedVestiging?.ves_kvknr &&
    (userHelpers.getIsBeheerderUser(state.security.user) || userHelpers.getIsServicedeskUser(state.security.user))
  ) {
    currentCriteria.kvkNummer = props.committedVestiging?.ves_kvknr;
  }

  if (props.userVestigingsnummer) {
    currentCriteria.vestigingsnummer = props.userVestigingsnummer;
  }

  return currentCriteria;
};

export const VestigingSearch = (props: VestigingSearchProps) => {
  const { name, abortSearchAndInvoke } = props;
  const { setFieldValue } = useFormikContext<AntwoordBlokken>();
  const [, meta] = useField(name);

  const hasError = meta.error && meta.touched;

  const currentCriteria = getSearchCriteria(props);

  return (
    <Field name={name} inputOnly={true}>
      <section className={`vestiging-search ${hasError ? "invalid" : "valid mb-3"}`} data-test={name}>
        {!props.readOnly && (
          <>
            {props.showSearch && (
              <VestigingFormSearch
                onClearSearch={() => {
                  abortSearchAndInvoke(() => props.onVestigingSearchClear());
                }}
                onSearch={(values) => {
                  abortSearchAndInvoke((signal) => props.onVestigingSearch(values, signal));
                }}
                name={`${name}.vestiging-search`}
                criteria={currentCriteria}
                busy={props.searching.busy}
                autoSearch={props.autoSearch}
                disableKvkSearch={props.disableKvkSearch}
                disableVestigingsnummerSearch={props.disableVestigingsnummerSearch}
                landen={props.landen}
              />
            )}
            {props.showResults && (
              <VestigingFormResults
                name={`${name}.vestiging-results`}
                vestigingen={props.searching.result.vestigingen}
                pageNumber={props.paging.page}
                pageSize={props.paging.pageSize}
                isLoading={props.paging.busy}
                hasApiError={false}
                totaalAantalVestigingen={props.searching.result.totaalAantalVestigingen}
                onSearchOptionChange={(pageNumber) => {
                  abortSearchAndInvoke((signal) => props.onVestigingPaging(pageNumber, signal));
                }}
                onSelect={(v) => {
                  setFieldValue(name, v.ves_id, true);
                  props.onSetVestiging(v);
                  if (props.onChange) {
                    props.onChange();
                  }
                }}
                landen={props.landen}
              />
            )}
          </>
        )}

        {props.showSelected && props.selectedVestiging && (
          <VestigingFormDetails
            onChangeVestiging={() => {
              setFieldValue(name, null, true);
              props.onVestigingClear();
            }}
            name={`${name}.vestiging`}
            vestiging={props.selectedVestiging}
            readOnly={props.readOnly || props.searching.busy}
            landen={props.landen}
          />
        )}
      </section>
    </Field>
  );
};
