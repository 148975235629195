import { AvvArtikel10AVerzoekFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getAvvArtikel10AVerzoekFormulierDefinitie = (formulier: AvvArtikel10AVerzoekFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.verzoek.titel,
    type: ProcesstapType.verzoek
  },
  {
    title: formulier.blokken.verzoeker.titel,
    type: ProcesstapType.verzoeker
  },
  {
    title: formulier.blokken.bijlage.titelVerkort,
    type: ProcesstapType.bijlage
  }
];
