import { jsonHelpers } from "helpers";
import { LocatieVragen } from ".";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";

export class AsbestlocatieVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public toelichting: Tekst,
    public locatie: LocatieVragen,
    public bewoondeLocatie: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): AsbestlocatieVragen {
    return new AsbestlocatieVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toParagraaf(`${key}.toelichting`, json.toelichting),
      LocatieVragen.fromJson(`${key}.locatie`, json.locatie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bewoondeLocatie`, json.bewoondeLocatie)
    );
  }
}
