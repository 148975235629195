import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { KlachtFormulierVraagBlokken } from "./klacht-formulier-vraag-blokken";

export class KlachtFormulier extends FormulierBase {
  constructor(public blokken: KlachtFormulierVraagBlokken, json: any) {
    super(Formuliertype.klacht, blokken, json);
  }

  static fromJson(json: any): KlachtFormulier {
    return new KlachtFormulier(KlachtFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
