import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsUnique extends VraagExpression {
  get operator(): string {
    return IsUnique.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly collectionVraag: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty);
    const collection = context.getAntwoordBlokCollection(this.collectionVraag);

    const numberOfSameItems = collection?.list?.filter(
      (a) => a[this.vraagProperty ?? vraagProperty]?.waarde === antwoord?.waarde
    ).length;
    return numberOfSameItems === 1;
  };

  protected clone = (instance: IsUnique): IsUnique => new IsUnique(instance.vraagProperty, instance.collectionVraag);

  static fromJson = (json: any): IsUnique => new IsUnique(json.vraag, json.collectionVraag);

  static operator = (): string => "isUnique";

  static matches = (json: any): boolean => json?.operator === IsUnique.operator();
}
