import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { BijlageAntwoorden } from "./bijlage-antwoorden";

export class BijlageDouaneAntwoorden extends AntwoordBlok {
  constructor(public douaneBijlageDocumenten: Antwoord<string[]>, public bestanden: BijlageAntwoorden) {
    super();
  }

  static initialize(): BijlageDouaneAntwoorden {
    return new BijlageDouaneAntwoorden(new Antwoord<string[]>([]), BijlageAntwoorden.initialize());
  }

  static fromJson(json: any): BijlageDouaneAntwoorden {
    return new BijlageDouaneAntwoorden(
      Antwoord.fromJson<string[]>(json.douaneBijlageDocumenten),
      BijlageAntwoorden.fromJson(json.bestanden)
    );
  }
}
