import { connect } from "react-redux";
import { OntheffingLiftenFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getOntheffingLiftenMelding,
  initOntheffingLiftenFormulier,
  initOntheffingLiftenFormulierFromCopy
} from "../../../thunks";
import { OntheffingLiftenFormulierComponent } from "./ontheffing-liften-formulier.component";
import {
  OntheffingLiftenFormulierComponentDispatchProps,
  OntheffingLiftenFormulierComponentStateProps
} from "./ontheffing-liften-formulier.component.interfaces";

const mapStateToProps = (state: State): OntheffingLiftenFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.ontheffingLiften
      ? (state.vragen.formulier as OntheffingLiftenFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): OntheffingLiftenFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initOntheffingLiftenFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getOntheffingLiftenMelding(meldingId));
    } else {
      dispatch(initOntheffingLiftenFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OntheffingLiftenFormulierComponent);
