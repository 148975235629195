import { AutorisatiesZoekenVragen, VraagBlokken } from "../blok";
import { HasAutorisatiesZoekenVragen } from "./has-autorisaties-zoeken-vragen";

export class AutorisatiesZoekenFormulierVraagBlokken extends VraagBlokken implements HasAutorisatiesZoekenVragen {
  [key: string]: any;

  constructor(public filter: AutorisatiesZoekenVragen) {
    super();
  }

  static fromJson(json: any): AutorisatiesZoekenFormulierVraagBlokken {
    return new AutorisatiesZoekenFormulierVraagBlokken(AutorisatiesZoekenVragen.fromJson("filter", json.filter));
  }
}
