import React from "react";
import { SamenvattingFieldOwnProps } from "../samenvatting-field/samenvatting-field.component.interfaces";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { withSamenvattingVraag, WithSamenvattingVraagProps } from "../with-samenvatting-vraag";
import { WithSamenvattingInventarisatievraagProps } from "./with-samenvatting-inventarisatievraag.interfaces";

export const withSamenvattingInventarisatievraag = <P extends object>(
  Component: React.ComponentType<P & SamenvattingFieldOwnProps>
) => {
  return (props: P & WithSamenvattingInventarisatievraagProps) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(withSamenvattingVraag(Component));

    const { getRowAsString, ...propsRest } = props;

    return (
      <SamenvattingVraagComponent
        {...(propsRest as P & WithSamenvattingVraagProps)}
        item={props.vraag}
        getAntwoord={(antwoordWaarde: any[], language: string) =>
          antwoordWaarde?.map((row: any) => getRowAsString(row, language))?.join(", ")
        }
      />
    );
  };
};
