import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class PeriodeDatumAntwoorden extends AntwoordBlok {
  constructor(public begindatum: Antwoord<string>, public einddatum: Antwoord<string>) {
    super();
  }

  static initialize(): PeriodeDatumAntwoorden {
    return new PeriodeDatumAntwoorden(new Antwoord<string>(""), new Antwoord<string>(""));
  }

  static fromJson(json: any): PeriodeDatumAntwoorden {
    return new PeriodeDatumAntwoorden(Antwoord.fromJson<string>(json.begindatum), Antwoord.fromJson<string>(json.einddatum));
  }
}
