import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsChanged extends VraagExpression {
  get operator(): string {
    return IsChanged.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty);

    return antwoord?.waarde !== antwoord?.initialValue;
  };

  protected clone = (instance: IsChanged): IsChanged => new IsChanged(instance.vraagProperty);

  static fromJson = (json: any): IsChanged => new IsChanged(json.vraag);

  static operator = (): string => "isChanged";

  static matches = (json: any): boolean => json?.operator === IsChanged.operator();
}
