import { withConditioneelReadonlyVraagBlok } from "components/formulier/with-conditioneel-readonly";
import { useFormikContext } from "formik";
import { meldingHelpers } from "helpers";
import { AntwoordBlokken } from "models/api";
import { useEffect, useRef } from "react";
import { FormulierInputControls } from "../../formulier";
import { abortAndCreateNewAbortController } from "./vestiging.component.helpers";
import { VestigingProps } from "./vestiging.component.interfaces";

const VestigingComponent = (props: VestigingProps) => {
  const lastAbortController = useRef<AbortController>();
  const { setFieldValue } = useFormikContext<AntwoordBlokken>();

  const { userVestigingId, onSetVestigingById, vestigingSearches, vragen, ...restProps } = props;
  const geselecteerdeVestigingFieldName = meldingHelpers.getFieldNameFromVraagKey(
    props.vragen.geselecteerdeVestiging.key,
    false
  );

  useEffect(() => {
    if (userVestigingId) {
      onSetVestigingById(userVestigingId);
      setFieldValue(geselecteerdeVestigingFieldName, userVestigingId, true);
    }
  }, [userVestigingId, onSetVestigingById, setFieldValue, geselecteerdeVestigingFieldName]);

  if (!vestigingSearches) {
    return null;
  }

  const abortSearchAndInvoke = (methodToInvoke: (abortSignal: AbortSignal) => void) => {
    lastAbortController.current = abortAndCreateNewAbortController(lastAbortController.current);

    if (props.vraagBlokken && !lastAbortController.current.signal.aborted) {
      methodToInvoke(lastAbortController.current?.signal);
    }
  };

  return (
    <FormulierInputControls.VestigingSearch
      vraag={props.vragen.geselecteerdeVestiging}
      onResetVraag={() => props.onResetVestigingSearchAndVraag()}
      onVraagNietGesteld={() => {
        if (vestigingSearches.searching.busy || vestigingSearches.showResults) {
          abortSearchAndInvoke(() => props.onResetVestigingSearchAndVraag());
        }
      }}
      abortSearchAndInvoke={abortSearchAndInvoke}
      {...vestigingSearches}
      {...restProps}
    ></FormulierInputControls.VestigingSearch>
  );
};

export const Vestiging = withConditioneelReadonlyVraagBlok(VestigingComponent);
