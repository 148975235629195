import { Mededeling } from "../models/api/beheer/mededeling";

export const SET_MEDEDELINGEN = "SET_MEDEDELINGEN";
export const SET_MEDEDELINGEN_LOADING = "SET_MEDEDELINGEN_LOADING";

interface SetMededelingAction {
  type: typeof SET_MEDEDELINGEN;
  mededelingen: Mededeling[];
}

interface SetMededelingLoadingAction {
  type: typeof SET_MEDEDELINGEN_LOADING;
  isLoading: boolean;
  hasApiError: boolean;
}

export type MededelingActions = SetMededelingAction | SetMededelingLoadingAction;

export const setMededelingenLoading = (isLoading: boolean, hasApiError: boolean): SetMededelingLoadingAction => {
  return {
    type: SET_MEDEDELINGEN_LOADING,
    isLoading,
    hasApiError: hasApiError
  };
};

export const setMededelingen = (mededelingen: Mededeling[]): SetMededelingAction => {
  return {
    type: SET_MEDEDELINGEN,
    mededelingen
  };
};
