import { connect } from "react-redux";
import { DuikarbeidFormulier } from "../../../models/api/formulier/duikarbeid-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getDuikarbeidMelding, initDuikarbeidFormulier, initDuikarbeidFormulierFromCopy } from "../../../thunks";
import { DuikarbeidFormulierComponent } from "./duikarbeid-formulier.component";
import {
  DuikarbeidFormulierComponentDispatchProps,
  DuikarbeidFormulierComponentStateProps
} from "./duikarbeid-formulier.component.interfaces";

const mapStateToProps = (state: State): DuikarbeidFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.duikarbeid ? (state.vragen.formulier as DuikarbeidFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): DuikarbeidFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initDuikarbeidFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getDuikarbeidMelding(meldingId));
    } else {
      dispatch(initDuikarbeidFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DuikarbeidFormulierComponent);
