import { AntwoordBlok } from "./antwoord-blok";
import { Antwoord } from "../antwoord";

export class MeldingsplichtAntwoorden extends AntwoordBlok {
  constructor(
    public watVoorOngeval: Antwoord<string>,
    public dodelijkOngeval: Antwoord<string>,
    public ernstigOngeval: Antwoord<string>,
    public arbeidsplaatsWijzigen: Antwoord<string>,
    public sporenVerloren: Antwoord<string>,
    public slachtofferNaarZiekenhuis: Antwoord<string[]>,
    public slachtofferBlijvendLetsel: Antwoord<string[]>,
    public geenMeldingsplicht: Antwoord<string>
  ) {
    super();
  }

  static initialize(): MeldingsplichtAntwoorden {
    return new MeldingsplichtAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string[]>([]),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): MeldingsplichtAntwoorden {
    return new MeldingsplichtAntwoorden(
      Antwoord.fromJson<string>(json.watVoorOngeval),
      Antwoord.fromJson<string>(json.dodelijkOngeval),
      Antwoord.fromJson<string>(json.ernstigOngeval),
      Antwoord.fromJson<string>(json.arbeidsplaatsWijzigen),
      Antwoord.fromJson<string>(json.sporenVerloren),
      Antwoord.fromJson<string[]>(json.slachtofferNaarZiekenhuis),
      Antwoord.fromJson<string[]>(json.slachtofferBlijvendLetsel),
      Antwoord.fromJson<string>(json.geenMeldingsplicht)
    );
  }
}
