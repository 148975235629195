import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class WaadiAntwoorden extends AntwoordBlok {
  constructor(public aanvullendeInformatie: Antwoord<string>) {
    super();
  }

  static initialize(): WaadiAntwoorden {
    return new WaadiAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): WaadiAntwoorden {
    return json ? new WaadiAntwoorden(Antwoord.fromJson<string>(json.aanvullendeInformatie)) : this.initialize();
  }
}
