import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { Bestand } from "./bestand";

export class ToedrachtAntwoorden extends AntwoordBlok {
  constructor(
    public toedrachtZwaarOngeval: Antwoord<string>,
    public werkzaamhedenArbeidsongeval: Antwoord<string>,
    public arbeidsmiddel: Antwoord<string>,
    public arbeidsmiddelWatVoor: Antwoord<string>,
    public arbeidsmiddelMerkType: Antwoord<string>,
    public gevaarlijkeStof: Antwoord<string>,
    public gevaarlijkeStofWelke: Antwoord<string>,
    public brzoGevaarlijkeStofWelke: Antwoord<string>,
    public brzoGevaarlijkeStofHoeveelheid: Antwoord<string>,
    public brzoGevaarlijkeStofGevolgen: Antwoord<string>,
    public brzoGevaarlijkeStofMaatregelen: Antwoord<string>,
    public brzoGevaarlijkeStofMaatregelenHerhaling: Antwoord<string>,
    public brzoGevaarlijkeStofSchadeBedrag: Antwoord<string>,
    public watIsErGebeurd: Antwoord<string>,
    public veranderingenAangebracht: Antwoord<string>,
    public ongevalReconstrueren: Antwoord<string>,
    public beeldmateriaal: Antwoord<string[]>,
    public beeldmateriaalBestanden: Antwoord<Bestand[]>
  ) {
    super();
  }

  static initialize(): ToedrachtAntwoorden {
    return new ToedrachtAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<Bestand[]>([])
    );
  }

  static fromJson(json: any): ToedrachtAntwoorden {
    return new ToedrachtAntwoorden(
      Antwoord.fromJson(json.toedrachtZwaarOngeval),
      Antwoord.fromJson(json.werkzaamhedenArbeidsongeval),
      Antwoord.fromJson(json.arbeidsmiddel),
      Antwoord.fromJson(json.arbeidsmiddelWatVoor),
      Antwoord.fromJson(json.arbeidsmiddelMerkType),
      Antwoord.fromJson(json.gevaarlijkeStof),
      Antwoord.fromJson(json.gevaarlijkeStofWelke),
      Antwoord.fromJson(json.brzoGevaarlijkeStofWelke),
      Antwoord.fromJson(json.brzoGevaarlijkeStofHoeveelheid),
      Antwoord.fromJson(json.brzoGevaarlijkeStofGevolgen),
      Antwoord.fromJson(json.brzoGevaarlijkeStofMaatregelen),
      Antwoord.fromJson(json.brzoGevaarlijkeStofMaatregelenHerhaling),
      Antwoord.fromJson(json.brzoGevaarlijkeStofSchadeBedrag),
      Antwoord.fromJson(json.watIsErGebeurd),
      Antwoord.fromJson(json.veranderingenAangebracht),
      Antwoord.fromJson(json.ongevalReconstrueren),
      Antwoord.fromJson(json.beeldmateriaal),
      Antwoord.fromJsonArray<Bestand>(json.beeldmateriaalBestanden, Bestand.fromJson)
    );
  }
}
