import { DigipassResponse } from "../models/api";

export const SET_DIGIPASS = "SET_DIGIPASS";

interface SetDigipassAction {
  type: typeof SET_DIGIPASS;
  digipassResponse: DigipassResponse;
}

export type DigipassActions = SetDigipassAction;

export const setDigipassResponse = (digipassResponse: DigipassResponse): SetDigipassAction => {
  return {
    type: SET_DIGIPASS,
    digipassResponse
  };
};
