import { jsonHelpers } from "../../../helpers";
import { VraagBlokType } from "../../application";
import { GebruikInfoVanType } from "../gebruik-informatie-van-type";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class BedrijfsgegevensVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public geselecteerdeVestiging: Vraag,
    public gebruikInfoVan: GebruikInfoVanType,
    public automatischZoeken: boolean
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, undefined, undefined, VraagBlokType.vestiging);
  }

  static fromJson(key: string, json: any): BedrijfsgegevensVragen {
    return new BedrijfsgegevensVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toNumeriekeVraag(`${key}.geselecteerdeVestiging`, json.geselecteerdeVestiging),
      json.gebruikInfoVan,
      json.automatischZoeken
    );
  }
}
