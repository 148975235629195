import {
  HasAsbestinventarisatieAntwoorden,
  HasAsbestlocatieAntwoorden,
  HasAsbestverwijderingWerkzaamhedenAntwoorden,
  HasMelderAntwoorden,
  HasSaneringAntwoorden,
  HasSloopmeldingAntwoorden
} from ".";
import {
  AntwoordBlokken,
  AsbestinventarisatieAntwoorden,
  AsbestlocatieAntwoorden,
  AsbestverwijderingWerkzaamhedenAntwoorden,
  MelderAntwoorden,
  SaneringAntwoorden,
  SloopmeldingAntwoorden
} from "../blok";
import { User } from "../security/user";

export class AsbestverwijderingMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasSaneringAntwoorden,
    HasMelderAntwoorden,
    HasSloopmeldingAntwoorden,
    HasAsbestinventarisatieAntwoorden,
    HasAsbestlocatieAntwoorden,
    HasAsbestverwijderingWerkzaamhedenAntwoorden
{
  constructor(
    public sanering: SaneringAntwoorden,
    public melder: MelderAntwoorden,
    public sloopmelding: SloopmeldingAntwoorden,
    public inventarisatie: AsbestinventarisatieAntwoorden,
    public locatie: AsbestlocatieAntwoorden,
    public werkzaamheden: AsbestverwijderingWerkzaamhedenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): AsbestverwijderingMeldingAntwoordBlokken {
    return new AsbestverwijderingMeldingAntwoordBlokken(
      SaneringAntwoorden.initialize(),
      MelderAntwoorden.initialize(user),
      SloopmeldingAntwoorden.initialize(),
      AsbestinventarisatieAntwoorden.initialize(),
      AsbestlocatieAntwoorden.initialize(),
      AsbestverwijderingWerkzaamhedenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): AsbestverwijderingMeldingAntwoordBlokken {
    return new AsbestverwijderingMeldingAntwoordBlokken(
      SaneringAntwoorden.fromJson(json.sanering),
      MelderAntwoorden.fromJson(json.melder),
      SloopmeldingAntwoorden.fromJson(json.sloopmelding),
      AsbestinventarisatieAntwoorden.fromJson(json.inventarisatie),
      AsbestlocatieAntwoorden.fromJson(json.locatie),
      AsbestverwijderingWerkzaamhedenAntwoorden.fromJson(json.werkzaamheden)
    );
  }
}
