import { Formuliertype } from "../../application";
import { CbiFormulierVraagBlokken } from "./cbi-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class CbiFormulier extends FormulierBase {
  constructor(public blokken: CbiFormulierVraagBlokken, json: any) {
    super(Formuliertype.cbi, blokken, json);
  }

  static fromJson(json: any): CbiFormulier {
    return new CbiFormulier(CbiFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
