import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class KennisgevingAntwoorden extends AntwoordBlok {
  constructor(public omschrijving: Antwoord<string>) {
    super();
  }

  static initialize(): KennisgevingAntwoorden {
    return new KennisgevingAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): KennisgevingAntwoorden {
    return new KennisgevingAntwoorden(Antwoord.fromJson<string>(json.omschrijving));
  }
}
