import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { OpdrachtgeverVragen } from "./opdrachtgever-vragen";
import { ParticuliereOpdrachtgeverVragen } from "./particuliere-opdrachtgever-vragen";

export class OpdrachtgeverAsbestVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public sloopmeldinggegevensTitel: Tekst,
    public datumSloopmelding: Vraag,
    public aanvraagnummerSloopmelding: Vraag,
    public sloopmeldingsplicht: Meerkeuzevraag,
    public sloopmeldingBijBevoegdGezag: Meerkeuzevraag,
    public gegevensOpdrachtgeverAanvragerSloopmeldingTitel: Tekst,
    public gegevensOpdrachtgeverAanvragerSloopmeldingParagraaf: Tekst,
    public gegevensGelijk: Meerkeuzevraag,
    public bedrijfOfParticulier: Meerkeuzevraag,
    public particuliereOpdrachtgever: ParticuliereOpdrachtgeverVragen,
    public opdrachtgever: OpdrachtgeverVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): OpdrachtgeverAsbestVragen {
    return new OpdrachtgeverAsbestVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.sloopmeldinggegevensTitel`, json.sloopmeldinggegevensTitel),
      jsonHelpers.toDatumVraag(`${key}.datumSloopmelding`, json.datumSloopmelding),
      jsonHelpers.toTekstVraag(`${key}.aanvraagnummerSloopmelding`, json.aanvraagnummerSloopmelding),
      jsonHelpers.toMeerkeuzeVraag(`${key}.sloopmeldingsplicht`, json.sloopmeldingsplicht),
      jsonHelpers.toMeerkeuzeVraag(`${key}.sloopmeldingBijBevoegdGezag`, json.sloopmeldingBijBevoegdGezag),
      jsonHelpers.toTitel(
        `${key}.gegevensOpdrachtgeverAanvragerSloopmeldingTitel`,
        json.gegevensOpdrachtgeverAanvragerSloopmeldingTitel
      ),
      jsonHelpers.toParagraaf(
        `${key}.gegevensOpdrachtgeverAanvragerSloopmeldingParagraaf`,
        json.gegevensOpdrachtgeverAanvragerSloopmeldingParagraaf
      ),
      jsonHelpers.toMeerkeuzeVraag(`${key}.gegevensGelijk`, json.gegevensGelijk),
      jsonHelpers.toMeerkeuzeVraag(`${key}.bedrijfOfParticulier`, json.bedrijfOfParticulier),
      ParticuliereOpdrachtgeverVragen.fromJson(`${key}.particuliereOpdrachtgever`, json.particuliereOpdrachtgever),
      OpdrachtgeverVragen.fromJson(`${key}.opdrachtgever`, json.opdrachtgever)
    );
  }
}
