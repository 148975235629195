import { MeldingFile } from "../models/application";
import { FileErrors } from "../models/application/error";

export const ADD_FILES = "ADD_FILES";
export const DELETE_FILES = "DELETE_FILES";
export const SET_FILES = "SET_FILES";
export const SET_FILES_METADATA = "SET_FILES_METADATA";
export const SET_FILE_CONFLICTS = "SET_FILE_CONFLICTS";
export const SET_FILES_ERROR = "SET_FILES_ERROR";
export const CLEAR_FILES_ERROR = "CLEAR_FILES_ERROR";

interface SetBestandsConflictenAction {
  type: typeof SET_FILE_CONFLICTS;
  conflictingFiles: MeldingFile[];
}

interface AddBestandenAction {
  type: typeof ADD_FILES;
  data: File[];
}

interface DeleteBestandenAction {
  type: typeof DELETE_FILES;
  data: File[];
}

interface SetBestandenAction {
  type: typeof SET_FILES;
  data: MeldingFile[];
}

interface SetFilesErrorAction {
  type: typeof SET_FILES_ERROR;
  data: FileErrors;
}

interface ClearFilesErrorAction {
  type: typeof CLEAR_FILES_ERROR;
}

export type BestandenActions =
  | AddBestandenAction
  | DeleteBestandenAction
  | SetBestandenAction
  | SetBestandsConflictenAction
  | SetFilesErrorAction
  | ClearFilesErrorAction;

export const setBestandenConflicts = (conflictingFiles: MeldingFile[]): SetBestandsConflictenAction => {
  return {
    type: SET_FILE_CONFLICTS,
    conflictingFiles
  };
};

export const addBestanden = (data: File[]): AddBestandenAction => {
  return {
    type: ADD_FILES,
    data: data
  };
};

export const deleteBestanden = (data: File[]): DeleteBestandenAction => {
  return {
    type: DELETE_FILES,
    data: data
  };
};

export const setBestanden = (data: MeldingFile[]): SetBestandenAction => {
  return {
    type: SET_FILES,
    data: data
  };
};

export const setFilesError = (data: FileErrors) => {
  return {
    type: SET_FILES_ERROR,
    data: data
  };
};

export const clearFilesError = () => {
  return {
    type: CLEAR_FILES_ERROR
  };
};
