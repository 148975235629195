import { Info } from "./info";
import { Paragraaf } from "./paragraaf";
import { Titel } from "./titel";
import { Waarschuwing } from "./waarschuwing";

const tekstComponenten = {
  Paragraaf,
  Titel,
  Waarschuwing,
  Info
};

export { tekstComponenten as TekstComponenten };
