import { connect } from "react-redux";
import { GevaarlijkeLiftFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getGevaarlijkeLiftMelding,
  initGevaarlijkeLiftFormulier,
  initGevaarlijkeLiftFormulierFromCopy
} from "../../../thunks";
import { GevaarlijkeLiftFormulierComponent } from "./gevaarlijke-lift-formulier.component";
import {
  GevaarlijkeLiftFormulierComponentDispatchProps,
  GevaarlijkeLiftFormulierComponentStateProps
} from "./gevaarlijke-lift-formulier.component.interfaces";

const mapStateToProps = (state: State): GevaarlijkeLiftFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.gevaarlijkeLift
      ? (state.vragen.formulier as GevaarlijkeLiftFormulier)
      : undefined;
  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): GevaarlijkeLiftFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initGevaarlijkeLiftFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getGevaarlijkeLiftMelding(meldingId));
    } else {
      dispatch(initGevaarlijkeLiftFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GevaarlijkeLiftFormulierComponent);
