import { asyncHelpers, focusHelpers } from "helpers";
import React, { Fragment, useEffect, useRef } from "react";
import { Accordion as BSAccordion, Card as BSCard } from "react-bootstrap";
import { CollectionRemove } from "../../../collection-remove";
import { CollectionAccordionHeader } from "../collection-accordion-header";
import { CollectionAccordionCardProps } from "./collection-accordion-card.component.interfaces";

export const CollectionAccordionCard = (props: CollectionAccordionCardProps) => {
  const collectionItemContainer = useRef(null);
  const focusDelayMs = 100;

  const { focusOnFirstVraag, selectedCollectionItemKey, vraagAntwoordBlok, onFirstVraagFocused } = props;

  useEffect(() => {
    if (
      focusOnFirstVraag &&
      selectedCollectionItemKey === vraagAntwoordBlok.vraagBlok.key &&
      collectionItemContainer.current
    ) {
      asyncHelpers.delay(focusDelayMs).then(() => {
        focusHelpers.setFocusOnFirstInput(collectionItemContainer);
        onFirstVraagFocused();
      });
    }
  }, [focusOnFirstVraag, selectedCollectionItemKey, vraagAntwoordBlok.vraagBlok.key, onFirstVraagFocused]);

  return (
    <BSCard key={props.vraagAntwoordBlok.vraagBlok.key}>
      <CollectionAccordionHeader
        getCollectionItemSummary={props.getCollectionItemSummary}
        rowIndex={props.rowIndex}
        vraagAntwoordBlok={props.vraagAntwoordBlok}
        collectionKey={props.collectionKey}
      />

      <BSAccordion.Collapse eventKey={props.vraagAntwoordBlok.vraagBlok.key}>
        <BSCard.Body key={props.vraagAntwoordBlok.vraagBlok.key}>
          <Fragment>
            <div ref={collectionItemContainer}>
              {React.createElement(props.itemComponent, {
                vragen: props.vraagAntwoordBlok.vraagBlok,
                key: props.vraagAntwoordBlok.vraagBlok.key,
                processtap: props.processtapType,
                wordtVertaald: props.wordtVertaald
              })}
            </div>

            <CollectionRemove
              vraagAntwoordBlok={props.vraagAntwoordBlok}
              collectionKey={props.collectionKey}
              collectionVerplichtType={props.collectionVerplichtType}
              rowIndex={props.rowIndex}
              removeCollectionItem={props.removeCollectionItem}
            />
          </Fragment>
        </BSCard.Body>
      </BSAccordion.Collapse>
    </BSCard>
  );
};
