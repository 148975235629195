import { Antwoord } from "../antwoord";
import { AanvullendeRisicoInventarisatieAntwoorden } from "./aanvullende-risico-inventarisatie-antwoorden";
import { AlgemeenVerbindendVerklarenCaoAntwoorden } from "./algemeen-verbinden-verklaren-cao-antwoorden";
import { AntwoordBlok } from "./antwoord-blok";
import { ArbeidsMeldingSituatieAntwoorden } from "./arbeids-melding-situatie-antwoorden";
import { ArbeidsomstandighedenAntwoorden } from "./arbeidsomstandigheden-antwoorden";
import { ArbeidstijdenAntwoorden } from "./arbeidstijden-antwoorden";
import { BesluitRisicoZwareOngevallenAntwoorden } from "./besluit-risico-zware-ongevallen-antwoorden";
import { BestrijdingsmiddelenAntwoorden } from "./bestrijdingsmiddelen-antwoorden";
import { DwangDoorWerkgeverAntwoorden } from "./dwang-door-werkgever-antwoorden";
import { FraudeSocialeZekerheidAntwoorden } from "./fraude-sociale-zekerheid-antwoorden";
import { IllegaleTewerkstellingAntwoorden } from "./illegale-tewerkstelling-antwoorden";
import { MalafideUitzendbureauAntwoorden } from "./malafide-uitzendbureau-antwoorden";
import { OnderbetalingAntwoorden } from "./onderbetaling-antwoorden";
import { WaadiAntwoorden } from "./waadi-antwoorden";
import { WagweuAntwoorden } from "./wagweu-antwoorden";

export class MeldingAntwoorden extends AntwoordBlok {
  constructor(
    public typeKlachtMelding: Antwoord<string>,
    public acuutLevensgevaarlijkeSituatie: Antwoord<string>,
    public onderwerp: Antwoord<string[]>,
    public betreftBouwkundigeAangelegenheid: Antwoord<string>,
    public betreftOverschrijding: Antwoord<string>,
    public illegaleTewerkstelling: IllegaleTewerkstellingAntwoorden,
    public onderbetaling: OnderbetalingAntwoorden,
    public malafideUitzendbureau: MalafideUitzendbureauAntwoorden,
    public arbeidsomstandigheden: ArbeidsomstandighedenAntwoorden,
    public arbeidstijden: ArbeidstijdenAntwoorden,
    public hoeveelPersonenWerkomstandigheden: Antwoord<number | null>,
    public dwangDoorWerkgever: DwangDoorWerkgeverAntwoorden,
    public bestrijdingsmiddelen: BestrijdingsmiddelenAntwoorden,
    public waadi: WaadiAntwoorden,
    public aanvullendeRisicoInventarisatie: AanvullendeRisicoInventarisatieAntwoorden,
    public besluitRisicoZwareOngevallen: BesluitRisicoZwareOngevallenAntwoorden,
    public arbeidsMeldingSituatie: ArbeidsMeldingSituatieAntwoorden,
    public wagweu: WagweuAntwoorden,
    public algemeenVerbindendVerklarenCao: AlgemeenVerbindendVerklarenCaoAntwoorden,
    public fraudeSocialeZekerheid: FraudeSocialeZekerheidAntwoorden
  ) {
    super();
  }

  static initialize(): MeldingAntwoorden {
    return new MeldingAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      IllegaleTewerkstellingAntwoorden.initialize(),
      OnderbetalingAntwoorden.initialize(),
      MalafideUitzendbureauAntwoorden.initialize(),
      ArbeidsomstandighedenAntwoorden.initialize(),
      ArbeidstijdenAntwoorden.initialize(),
      new Antwoord<number | null>(null),
      DwangDoorWerkgeverAntwoorden.initialize(),
      BestrijdingsmiddelenAntwoorden.initialize(),
      WaadiAntwoorden.initialize(),
      AanvullendeRisicoInventarisatieAntwoorden.initialize(),
      BesluitRisicoZwareOngevallenAntwoorden.initialize(),
      ArbeidsMeldingSituatieAntwoorden.initialize(),
      WagweuAntwoorden.initialize(),
      AlgemeenVerbindendVerklarenCaoAntwoorden.initialize(),
      FraudeSocialeZekerheidAntwoorden.initialize()
    );
  }

  static fromJson(json: any): MeldingAntwoorden {
    return new MeldingAntwoorden(
      Antwoord.fromJson<string>(json.typeKlachtMelding),
      Antwoord.fromJson<string>(json.acuutLevensgevaarlijkeSituatie),
      Antwoord.fromJson<string[]>(json.onderwerp),
      Antwoord.fromJson<string>(json.betreftBouwkundigeAangelegenheid),
      Antwoord.fromJson<string>(json.betreftOverschrijding),
      IllegaleTewerkstellingAntwoorden.fromJson(json.illegaleTewerkstelling),
      OnderbetalingAntwoorden.fromJson(json.onderbetaling),
      MalafideUitzendbureauAntwoorden.fromJson(json.malafideUitzendbureau),
      ArbeidsomstandighedenAntwoorden.fromJson(json.arbeidsomstandigheden),
      ArbeidstijdenAntwoorden.fromJson(json.arbeidstijden),
      Antwoord.fromJson<number | null>(json.hoeveelPersonenWerkomstandigheden),
      DwangDoorWerkgeverAntwoorden.fromJson(json.dwangDoorWerkgever),
      BestrijdingsmiddelenAntwoorden.fromJson(json.bestrijdingsmiddelen),
      WaadiAntwoorden.fromJson(json.waadi),
      AanvullendeRisicoInventarisatieAntwoorden.fromJson(json.aanvullendeRisicoInventarisatie),
      BesluitRisicoZwareOngevallenAntwoorden.fromJson(json.besluitRisicoZwareOngevallen),
      ArbeidsMeldingSituatieAntwoorden.fromJson(json.arbeidsMeldingSituatie),
      WagweuAntwoorden.fromJson(json.wagweu),
      AlgemeenVerbindendVerklarenCaoAntwoorden.fromJson(json.algemeenVerbindendVerklarenCao),
      FraudeSocialeZekerheidAntwoorden.fromJson(json.fraudeSocialeZekerheid)
    );
  }
}
