import { connect } from "react-redux";
import { State } from "../../../store";
import { SamenvattingBijlagen } from "./samenvatting-bijlagen.component";
import { SamenvattingBijlageStateProps } from "./samenvatting-bijlagen.component.interfaces";

const mapStateToProps = (state: State): SamenvattingBijlageStateProps => ({
  confirmNavigationRequired: state.navigation.confirmNavigationRequired
});

export default connect(mapStateToProps)(SamenvattingBijlagen);
