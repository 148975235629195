import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class MeldingOmschrijvingAntwoorden extends AntwoordBlok {
  constructor(public omschrijving: Antwoord<string>) {
    super();
  }

  static initialize(): MeldingOmschrijvingAntwoorden {
    return new MeldingOmschrijvingAntwoorden(new Antwoord<string>(""));
  }

  static fromJson(json: any): MeldingOmschrijvingAntwoorden {
    return new MeldingOmschrijvingAntwoorden(Antwoord.fromJson<string>(json.omschrijving));
  }
}
