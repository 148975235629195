import { ErrorMessage, useField } from "formik";
import { Fragment } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../configuration";
import { useErrorHandler } from "../../../hooks/use-error-handler";
import { ErrorMessageControl } from "../../error";
import { Help } from "../../help";

const LabelControl = (props: {
  groupId: string;
  name: string;
  label: string;
  toelichting: string;
  helpToggleLabel: string | null;
}) => {
  return (
    <Fragment>
      <Form.Label
        tabIndex={-1}
        {...(props.groupId ? { id: props.groupId } : {})}
        {...(props.name ? { htmlFor: props.name } : {})}
      >
        {props.label}
        {props.toelichting && (
          <Fragment>
            <Help.HelpToggle
              className="help-toggle toelichting"
              key={`${props.name}--help-toggle`}
              id={`${props.name}-help-toggle`}
              aria-label={props.helpToggleLabel}
            ></Help.HelpToggle>
          </Fragment>
        )}
      </Form.Label>
    </Fragment>
  );
};

const Toelichting = (props: { name: string; toelichting: string }) => {
  return (
    <Fragment>
      {props.toelichting && (
        <Help.HelpText
          key={`${props.name}--help-text`}
          helptekst={props.toelichting}
          id={`${props.name}-help`}
        ></Help.HelpText>
      )}
    </Fragment>
  );
};

const getFormGroupClassnames = (props: any) => {
  return props.label != null && (props.fullWidth || props.label.length > Configuration.maxLabelLength)
    ? "form-group full-width"
    : "form-group";
};

export const Field = (props: any) => {
  const [field, meta] = useField(props);
  const [showError, showServerError, serverError] = useErrorHandler(field, meta);
  const { t } = useTranslation();
  const { label, name, toelichting, header, inputOnly } = props;
  const { error } = meta;

  const helpToggleLabel = toelichting ? t("help-toggle", { naam: label }) : null;

  const ErrorControl = () => <ErrorMessageControl name={name}>{error}</ErrorMessageControl>;

  const ServerErrorControl = () => <ErrorMessageControl name={name}>{serverError}</ErrorMessageControl>;

  const showLabel = props.showLabel != null ? props.showLabel : true;

  if (inputOnly) {
    return (
      <Fragment>
        {header && <div className="explanation">{header}</div>}
        {props.children}
        <ErrorMessage name={name} component={ErrorControl} />
      </Fragment>
    );
  }

  return (
    <Help.Container key={`${name}--help`}>
      <Form.Group
        className={getFormGroupClassnames(props)}
        {...(props.groupId ? { role: "group", "aria-labelledby": props.groupId } : {})}
      >
        {showLabel && (
          <div className="label-container">
            <LabelControl
              name={name}
              groupId={props.groupId}
              label={label}
              toelichting={toelichting}
              helpToggleLabel={helpToggleLabel}
            />
          </div>
        )}
        <div className="input-container">
          {header && <div className="explanation">{header}</div>}
          {props.children}
          {showServerError && <ServerErrorControl />}
          {showError && <ErrorMessage name={name} component={ErrorControl} />}
        </div>
      </Form.Group>
      <Toelichting name={name} toelichting={toelichting} />
    </Help.Container>
  );
};
