import { DebounceProvider } from "components/input-controls/debounce-input";
import { Field } from "components/input-controls/field";
import _ from "lodash";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CollectionAdd } from "../collection-add";
import { CollectionError, CollectionErrorsOnInactiveItems } from "../collection-errors";
import { CollectionFilter } from "../collection-filter";
import { CollectionHandler } from "../collection-handler";
import { CollectionValidator } from "../collection-validator";
import { getVraagCollectionLabel } from "../vraag-blok-collection.helpers";
import { CollectionTable } from "./collection-table";
import { VraagBlokkenCollectionGridOwnProps } from "./vraag-blokken-collection-grid.component.interface";

const VraagBlokkenCollectionGridCmp = (props: VraagBlokkenCollectionGridOwnProps) => {
  const { i18n } = useTranslation();
  let { showSearch } = props;
  if (_.isNil(showSearch)) {
    showSearch = true;
  }

  return (
    <Fragment>
      <div className={`vraagblok-collection-grid-container`}>
        <DebounceProvider>
          <Field
            name={props.vraagBlokCollection}
            label={getVraagCollectionLabel(props.vraagBlokCollection, i18n.language)}
            header={props.vraagBlokCollection.helptekst ? props.vraagBlokCollection.helptekst[i18n.language] : undefined}
            inputOnly={props.inputOnly ?? false}
            showLabel={props.vraagBlokCollection.showLabel}
          >
            <CollectionHandler
              reversedOrder={props.defaultNewestOnTop}
              collectionKey={props.vraagBlokCollection.key}
              vraagBlokken={props.vraagBlokCollection.list}
              columnSettings={props.columnSettings}
            />
            <CollectionValidator collectionKey={props.vraagBlokCollection.key} />

            <CollectionAdd
              template={props.vraagBlokCollection.template}
              getVraagBlokFromTemplate={props.getVraagBlokFromTemplate}
              initializeAntwoorden={props.initializeAntwoorden}
              collectionKey={props.vraagBlokCollection.key}
              collectionVerplichtType={props.vraagBlokCollection.verplichtType}
              extraToevoegenTekst={props.vraagBlokCollection.extraToevoegenTekst}
              toevoegenTekst={props.vraagBlokCollection.toevoegenTekst}
            />
            {showSearch && <CollectionFilter collectionKey={props.vraagBlokCollection.key} />}

            <CollectionTable
              collectionKey={props.vraagBlokCollection.key}
              collectionVerplichtType={props.vraagBlokCollection.verplichtType}
              columnSettings={props.columnSettings}
              pagingSettings={props.pagingSettings}
              itemComponent={props.itemComponent}
              getCollectionItemSummary={props.getCollectionItemSummary}
              copyAntwoorden={props.copyAntwoorden}
              template={props.vraagBlokCollection.template}
              getVraagBlokFromTemplate={props.getVraagBlokFromTemplate}
            />

            <CollectionError collectionKey={props.vraagBlokCollection.key} />

            <CollectionErrorsOnInactiveItems
              collectionKey={props.vraagBlokCollection.key}
              vraagBlokCollection={props.vraagBlokCollection}
              getCollectionItemSummary={props.getCollectionItemSummary}
            />
          </Field>
        </DebounceProvider>
      </div>
    </Fragment>
  );
};

export const VraagBlokkenCollectionGrid = connect()(VraagBlokkenCollectionGridCmp);
