import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import { useDebounceInputContext } from "./debounce-input.context";

// Default debounce time of debounce is enabled
const DEFAULT_DEBOUNCE_MS = 300;

export const withDebounce = (Component: any) => {
  return (props: any) => {
    const debounceContext = useDebounceInputContext();
    const [innerValue, setInnerValue] = useState<any>("");

    useEffect(() => {
      if (props.value) {
        setInnerValue(props.value);
      } else {
        setInnerValue("");
      }
    }, [props.value]);

    const debouncedHandleOnChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    }, debounceContext.debounceWait ?? DEFAULT_DEBOUNCE_MS);

    const debouncedHandleOnBlur = useDebouncedCallback((event: any) => {
      if (props.onBlur) {
        props.onBlur(event);
      }
    }, 0);

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const newValue = event.currentTarget.value;
        setInnerValue(newValue);
        debouncedHandleOnChange(event);
      },
      [debouncedHandleOnChange]
    );

    const handleOnBlur = useCallback(
      (event: any) => {
        event.persist();
        debouncedHandleOnChange.flush();
        debouncedHandleOnBlur(event);
      },
      [debouncedHandleOnChange, debouncedHandleOnBlur]
    );

    return debounceContext.debounceEnabled === true ? (
      <Component {...props} value={innerValue} onChange={handleOnChange} onBlur={handleOnBlur} />
    ) : (
      <Component {...props} />
    );
  };
};

export const DebouncedFormControl = withDebounce(Form.Control);
