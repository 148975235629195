import { Fragment } from "react";
import { VraagBlokWithAntwoordIndex } from "../../../model";
import { CollectionAccordionCard } from "../collection-accordion-card";
import { CollectionAccordionBodyProps } from "./collection-accordion-body.component.interfaces";

export const CollectionAccordionBody = (props: CollectionAccordionBodyProps) => {
  return (
    <Fragment>
      {props.vraagBlokAntwoordIndexes.map(
        (vraagAntwoordBlok: VraagBlokWithAntwoordIndex, vraagAntwoordBlokIndex: number) => (
          <CollectionAccordionCard
            getCollectionItemSummary={props.getCollectionItemSummary}
            collectionKey={props.collectionKey}
            collectionVerplichtType={props.collectionVerplichtType}
            itemComponent={props.itemComponent}
            rowIndex={vraagAntwoordBlokIndex}
            vraagAntwoordBlok={vraagAntwoordBlok}
            key={vraagAntwoordBlok.vraagBlok.key}
            processtapType={props.processtapType}
            removeCollectionItem={props.removeCollectionItem}
          />
        )
      )}
    </Fragment>
  );
};
