import { Configuration } from "../configuration";
import { GetAutorisatiesRequest } from "../models/api";
import { get, objectToQueryString } from "./api";

const getAutorisaties = <GetAutorisatiesResponse>(request: GetAutorisatiesRequest): Promise<GetAutorisatiesResponse> =>
  get({
    uri: `${Configuration.apiUrl}/webportaal/autorisaties?${objectToQueryString(request)}`,
    success: (r) => r
  });

export const beheerApi = {
  getAutorisaties
};
