import { connect } from "react-redux";
import { NO_SELECTED_ITEM } from "reducers/collections-reducer";
import { State } from "store";
import { sortCollectionItems } from "thunks";
import { CollectionTableHeader } from "./collection-table-header.component";
import {
  CollectionTableHeaderDispatchProps,
  CollectionTableHeaderOwnProps,
  CollectionTableHeaderStateProps
} from "./collection-table-header.component.interfaces";

const mapStateToProps = (state: State, props: CollectionTableHeaderOwnProps): CollectionTableHeaderStateProps => ({
  currentSorting: state.collections.list[props.collectionKey]?.sorting || null,
  isCollectionItemSelected:
    (state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM) !== NO_SELECTED_ITEM
});

const mapDispatchToProps = (dispatch: any, props: CollectionTableHeaderOwnProps): CollectionTableHeaderDispatchProps => ({
  onSortChange: (columnName: string) => {
    dispatch(sortCollectionItems(props.collectionKey, columnName));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTableHeader);
