import { Vestiging } from "./extern";

export class GetVestigingenResponse {
  constructor(public totalItemCount: number, public vestigingen: Vestiging[]) {}

  static fromJson(json: any): GetVestigingenResponse {
    return new GetVestigingenResponse(
      json.totalItemCount,
      json.vestigingen.map((v: Vestiging) => Vestiging.fromJson(v))
    );
  }
}
