import { connect } from "react-redux";
import { NO_SELECTED_ITEM } from "reducers/collections-reducer";
import { State } from "store";
import { searchForCollectionItems } from "thunks";
import { CollectionFilter } from "./collection-filter.component";
import {
  CollectionFilterDispatchProps,
  CollectionFilterOwnProps,
  CollectionFilterStateProps
} from "./collection-filter.component.interfaces";

const EMPTY_SEARCH = "";
const mapStateToProps = (state: State, props: CollectionFilterOwnProps): CollectionFilterStateProps => ({
  searchValue: state.search.searches[props.collectionKey]?.value || EMPTY_SEARCH,
  isEnabled:
    (state.collections.list[props.collectionKey]?.validationFailures.length > 0 &&
      (state.collections.list[props.collectionKey]?.selectedItemKey ?? NO_SELECTED_ITEM) !== NO_SELECTED_ITEM) ??
    false
});

const mapDispatchToProps = (dispatch: any, props: CollectionFilterOwnProps): CollectionFilterDispatchProps => ({
  onSearch: (searchValue: string) => {
    dispatch(searchForCollectionItems(props.collectionKey, searchValue));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionFilter);
