import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { MogelijkAntwoord } from "../../../models/api";
import { Field } from "../field";
import { InputOptionWithToelichting } from "../input-option-with-toelichting";
import { InputCheckOption } from "./input-check-option/input-check-option";

export const InputCheck = (props: any) => {
  const { i18n } = useTranslation();

  const lang: string = i18n.language;

  const { name, readOnly, mogelijkeAntwoorden, onChange, containerClass, ...propsRest } = props;

  const containerClassName = `check-container ${containerClass}`;

  const toCheckOptionWithToelichting = (ma: MogelijkAntwoord) => {
    return (
      <InputOptionWithToelichting mogelijkAntwoord={ma} key={`${ma.waarde}--option-with-toelichting`}>
        {toCheckOption(ma)}
      </InputOptionWithToelichting>
    );
  };

  const toCheckOption = (ma: MogelijkAntwoord) => {
    return (
      <InputCheckOption
        id={`${name}.${ma.waarde}`}
        name={name}
        key={ma.waarde}
        label={ma.tekst[lang] ?? ma.waarde}
        optionValue={ma.waarde}
        readOnly={readOnly}
        disabled={readOnly}
        onChange={onChange}
        className={ma.toelichting?.[lang] ? "check-option" : null}
      />
    );
  };

  return (
    <Field {...propsRest} name={name} groupId={name}>
      <div className={containerClassName}>
        {props.children
          ? props.children.map((c: ReactElement) =>
              React.cloneElement(c, {
                name: c.props.name ?? name,
                readOnly: c.props.readOnly ?? readOnly
              })
            )
          : mogelijkeAntwoorden.map((ma: MogelijkAntwoord, i: number) =>
              ma.toelichting?.[lang] ? toCheckOptionWithToelichting(ma) : toCheckOption(ma)
            )}
      </div>
    </Field>
  );
};
