import { Adres } from "../../models/api";

export const SET_PLAATSEN_ISLOADING = "SET_PLAATSEN_ISLOADING";
export const SET_PLAATSEN = "SET_PLAATSEN";

export const SET_ADRESSEN_ISLOADING = "SET_ADRESSEN_ISLOADING";
export const SET_ADRESSEN = "SET_ADRESSEN";

interface SetPlaatsenIsLoadingAction {
  type: typeof SET_PLAATSEN_ISLOADING;
  data: boolean;
  key: string;
}

interface SetPlaatsenAction {
  type: typeof SET_PLAATSEN;
  data: string[];
  key: string;
}

interface SetAdressenIsLoadingAction {
  type: typeof SET_ADRESSEN_ISLOADING;
  data: boolean;
  key: string;
}

interface SetAdressenAction {
  type: typeof SET_ADRESSEN;
  data: Adres[];
  key: string;
}

export type NederlandsAdresActions =
  | SetPlaatsenIsLoadingAction
  | SetPlaatsenAction
  | SetAdressenIsLoadingAction
  | SetAdressenAction;

export const setPlaatsenIsLoading = (isLoading: boolean, key: string): SetPlaatsenIsLoadingAction => {
  return {
    type: SET_PLAATSEN_ISLOADING,
    data: isLoading,
    key
  };
};

export const setPlaatsen = (plaatsen: string[], key: string): SetPlaatsenAction => {
  return {
    type: SET_PLAATSEN,
    data: plaatsen,
    key
  };
};

export const setAdressenIsLoading = (isLoading: boolean, key: string): SetAdressenIsLoadingAction => {
  return {
    type: SET_ADRESSEN_ISLOADING,
    data: isLoading,
    key
  };
};

export const setAdressen = (adressen: Adres[], key: string): SetAdressenAction => {
  return {
    type: SET_ADRESSEN,
    data: adressen,
    key
  };
};
