import { Formuliertype } from "./formuliertype";

export const isFormulierTypeMelding = (formuliertype: Formuliertype): boolean => {
  switch (formuliertype) {
    case Formuliertype.autorisatieaanvraag:
    case Formuliertype.autorisatiesZoeken:
    case Formuliertype.profiel:
    case Formuliertype.autorisatieProfiel:
      return false;
    default:
      return true;
  }
};
