import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class CollectionContainsItems extends VraagExpression {
  get operator(): string {
    return CollectionContainsItems.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly vraagBlokCollectionProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const collection = context.getAntwoordBlokCollection(this.vraagBlokCollectionProperty);
    return collection?.list?.some((v) => v) ?? false;
  };

  protected clone = (instance: CollectionContainsItems): CollectionContainsItems =>
    new CollectionContainsItems(instance.vraagProperty, instance.vraagBlokCollectionProperty);

  static fromJson = (json: any): CollectionContainsItems =>
    new CollectionContainsItems(json.vraag, json.vraagBlokCollection);

  static operator = (): string => "collectionContainsItems";

  static matches = (json: any): boolean => json?.operator === CollectionContainsItems.operator();
}
