import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { GevaarlijkeGassenImportcontainersVraagBlokken } from "./gevaarlijke-gassen-importcontainers-vraag-blokken";

export class GevaarlijkeGassenImportcontainersFormulier extends FormulierBase {
  constructor(public blokken: GevaarlijkeGassenImportcontainersVraagBlokken, json: any) {
    super(Formuliertype.gevaarlijkeGassenImportcontainers, blokken, json);
  }

  static fromJson(json: any): GevaarlijkeGassenImportcontainersFormulier {
    return new GevaarlijkeGassenImportcontainersFormulier(
      GevaarlijkeGassenImportcontainersVraagBlokken.fromJson(json.blokken),
      json
    );
  }
}
