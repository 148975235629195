import React, { useMemo } from "react";
import { UserMenuContext } from "./user-menu-context";

const UserMenuContainer: React.FC<any> = (props) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const defaultContext = useMemo(() => {
    return {
      isVisible: isVisible,
      toggleIsVisible: () => {
        setIsVisible(!isVisible);
      }
    };
  }, [isVisible]);

  return <UserMenuContext.Provider value={defaultContext}>{props.children}</UserMenuContext.Provider>;
};

export { UserMenuContainer };
