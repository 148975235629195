import { AanwijzingRisicovolleBedrijvenFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getAanwijzingRisicovolleBedrijvenFormulierDefinitie = (
  formulier: AanwijzingRisicovolleBedrijvenFormulier
): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.melder.titel,
      type: ProcesstapType.melder
    },
    {
      title: formulier.blokken.locatie.titelVerkort,
      type: ProcesstapType.locatie
    },
    {
      title: formulier.blokken.kennisgeving.titel,
      type: ProcesstapType.kennisgeving
    }
  ];
};
