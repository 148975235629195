import { Formuliertype } from "../../application";
import { AutorisatieProfielFormulierVraagBlokken } from "./autorisatie-profiel-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class AutorisatieProfielFormulier extends FormulierBase {
  constructor(public blokken: AutorisatieProfielFormulierVraagBlokken, json: any) {
    super(Formuliertype.autorisatieProfiel, blokken, json);
  }

  static fromJson(json: any): AutorisatieProfielFormulier {
    return new AutorisatieProfielFormulier(AutorisatieProfielFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
