import { connect } from "react-redux";
import { DigipassFormulier } from "../../../models/api/formulier/digipass-formulier";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { initDigipassFormulier, submitDigipass } from "../../../thunks";
import { DigipassFormulierComponent } from "./digipass-formulier.component";
import {
  DigipassFormulierComponentDispatchProps,
  DigipassFormulierComponentStateProps
} from "./digipass-formulier.component.interfaces";

const mapStateToProps = (state: State): DigipassFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.digipass ? (state.vragen.formulier as DigipassFormulier) : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype,
    digipassResponse: state.digipass.digipassResponse
  };
};

const mapDispatchToProps = (dispatch: any): DigipassFormulierComponentDispatchProps => ({
  onInit: () => {
    dispatch(initDigipassFormulier());
  },
  submitDigipass: async () => {
    dispatch(submitDigipass());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DigipassFormulierComponent);
