import {
  AntwoordBlokken,
  GetuigenAntwoorden,
  MeldingsplichtToedrachtAntwoorden,
  OngevalMelderAntwoorden,
  SlachtoffersAntwoorden,
  WerkgeverLocatieAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasOngevalMelderAntwoorden } from "./has-ongeval-melder-antwoorden";

export class OngevalMeldingAntwoordBlokken extends AntwoordBlokken implements HasOngevalMelderAntwoorden {
  constructor(
    public meldingsplichtToedracht: MeldingsplichtToedrachtAntwoorden,
    public melder: OngevalMelderAntwoorden,
    public werkgeverLocatie: WerkgeverLocatieAntwoorden,
    public slachtoffers: SlachtoffersAntwoorden,
    public getuigen: GetuigenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): OngevalMeldingAntwoordBlokken {
    return new OngevalMeldingAntwoordBlokken(
      MeldingsplichtToedrachtAntwoorden.initialize(),
      OngevalMelderAntwoorden.initialize(user),
      WerkgeverLocatieAntwoorden.initialize(),
      SlachtoffersAntwoorden.initialize(),
      GetuigenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): OngevalMeldingAntwoordBlokken {
    return new OngevalMeldingAntwoordBlokken(
      MeldingsplichtToedrachtAntwoorden.fromJson(json.meldingsplichtToedracht),
      OngevalMelderAntwoorden.fromJson(json.melder),
      WerkgeverLocatieAntwoorden.fromJson(json.werkgeverLocatie),
      SlachtoffersAntwoorden.fromJson(json.slachtoffers),
      GetuigenAntwoorden.fromJson(json.getuigen)
    );
  }
}
