import { formulierApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { AutorisatiesZoekenMeldingAntwoordBlokken } from "models/api";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initAutorisatiesZoekenFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getAutorisatiesZoekenFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getAutorisatiesZoekenFormulierDefinitie,
      AutorisatiesZoekenMeldingAntwoordBlokken.initialize
    );

    const state = getState();
    if (state.beheer.zoekFilter) {
      state.antwoorden.blokken.filter = state.beheer.zoekFilter;
    }
  };
