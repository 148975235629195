export class Meldingrechten {
  constructor(
    public kanKopieren: boolean,
    public kanWijzigen: boolean,
    public kanIntrekken: boolean,
    public toelichtingIntrekken: string,
    public kanZaakKoppelen: boolean,
    public kanVertalen: boolean,
    public hasExternalServiceError: string,
  ) {}

  static fromJson(json: any): Meldingrechten {
    return new Meldingrechten(
      json.kanKopieren,
      json.kanWijzigen,
      json.kanIntrekken,
      json.toelichtingIntrekken,
      json.kanZaakKoppelen,
      json.kanVertalen,
      json.hasExternalServiceError
    );
  }
}
