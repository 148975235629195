import { Tekst } from "components/blok/generiek-samenvatting-blok/supported-vraag-typen/tekst";
import { jsonHelpers } from "helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { LandVragen } from "./land-vragen";

export class MeldingDouaneVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public titelVerkort: Dictionary<string>,
    public productcategorie: Meerkeuzevraag,
    public aangiftenummerItemnummer: Vraag,
    public overigeInformatie: Vraag,
    public reactietermijnTitel: Tekst,
    public verhinderingVrijgaveDatum: Vraag,
    public verhinderingVrijgaveTijdstip: Vraag,
    public deadlineReactieDatum: Vraag,
    public deadlineReactieTijdstip: Vraag,
    public aangifteGegevensTitel: Tekst,
    public gnCode: Vraag,
    public goederenomschrijving: Vraag,
    public gewichtBruto: Vraag,
    public gewichtNetto: Vraag,
    public naamVerkoper: Vraag,
    public hoeveelheid: Vraag,
    public factuurnummer: Vraag,
    public colli: Vraag,
    public landVanOorsprong: LandVragen,
    public landVanBestemming: LandVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel, titelVerkort);
  }

  static fromJson(key: string, json: any): MeldingDouaneVragen {
    return new MeldingDouaneVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      json.titelVerkort,
      jsonHelpers.toChecklistvraag(`${key}.productcategorie`, json.productcategorie),
      jsonHelpers.toTekstVraag(`${key}.aangiftenummerItemnummer`, json.aangiftenummerItemnummer),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.overigeInformatie`, json.overigeInformatie),
      jsonHelpers.toTitel(`${key}.reactietermijnTitel`, json.reactietermijnTitel),
      jsonHelpers.toDatumVraag(`${key}.verhinderingVrijgaveDatum`, json.verhinderingVrijgaveDatum),
      jsonHelpers.toTijdVraag(`${key}.verhinderingVrijgaveTijdstip`, json.verhinderingVrijgaveTijdstip),
      jsonHelpers.toDatumVraag(`${key}.deadlineReactieDatum`, json.deadlineReactieDatum),
      jsonHelpers.toTijdVraag(`${key}.deadlineReactieTijdstip`, json.deadlineReactieTijdstip),
      jsonHelpers.toTitel(`${key}.aangifteGegevensTitel`, json.aangifteGegevensTitel),
      jsonHelpers.toTekstVraag(`${key}.gnCode`, json.gnCode),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.goederenomschrijving`, json.goederenomschrijving),
      jsonHelpers.toTekstVraag(`${key}.gewichtBruto`, json.gewichtBruto),
      jsonHelpers.toTekstVraag(`${key}.gewichtNetto`, json.gewichtNetto),
      jsonHelpers.toTekstVraag(`${key}.naamVerkoper`, json.naamVerkoper),
      jsonHelpers.toTekstVraag(`${key}.hoeveelheid`, json.hoeveelheid),
      jsonHelpers.toTekstVraag(`${key}.factuurnummer`, json.factuurnummer),
      jsonHelpers.toTekstVraag(`${key}.colli`, json.colli),
      LandVragen.fromJson(`${key}.landVanOorsprong`, json.landVanOorsprong),
      LandVragen.fromJson(`${key}.landVanBestemming`, json.landVanBestemming)
    );
  }
}
