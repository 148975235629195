import { connect } from "react-redux";
import { State } from "../../store";
import { Layout } from "./layout.component";
import { LayoutStateProps } from "./layout.component.interfaces";

const mapStateToProps = (state: State): LayoutStateProps => ({
  availableLanguages: state.vragen.formulier?.ondersteundeTalen
});

export default connect(mapStateToProps)(Layout);
