import { AutorisatiesListItem, AutorisatiesSortOption, AutorisatiesZoekenAntwoorden } from "./../models/api";

export const SET_AUTORISATIES = "SET_AUTORISATIES";
export const SET_AUTORISATIES_LOADING = "SET_AUTORISATIES_LOADING";
export const SET_AUTORISATIES_FILTER = "SET_AUTORISATIES_FILTER";

interface SetAutorisatiesAction {
  type: typeof SET_AUTORISATIES;
  autorisaties: AutorisatiesListItem[];
  totaalAantalAutorisaties: number;
}

interface SetAutorisatiesLoadingAction {
  type: typeof SET_AUTORISATIES_LOADING;
  isLoading: boolean;
  hasApiError: boolean;
}

interface SetAutorisatiesFilterAction {
  type: typeof SET_AUTORISATIES_FILTER;
  sortOption: AutorisatiesSortOption;
  pageNumber: number;
  zoekFilter?: AutorisatiesZoekenAntwoorden;
}

export type AutorisatiesActions = SetAutorisatiesAction | SetAutorisatiesLoadingAction | SetAutorisatiesFilterAction;

export const setAutorisatiesLoading = (isLoading: boolean, hasApiError: boolean): SetAutorisatiesLoadingAction => {
  return {
    type: SET_AUTORISATIES_LOADING,
    isLoading,
    hasApiError: hasApiError
  };
};

export const setAutorisaties = (
  autorisaties: AutorisatiesListItem[],
  totaalAantalAutorisaties: number
): SetAutorisatiesAction => {
  return {
    type: SET_AUTORISATIES,
    autorisaties,
    totaalAantalAutorisaties
  };
};

export const setAutorisatiesFilter = (
  sortOption: AutorisatiesSortOption,
  pageNumber: number,
  zoekFilter?: AutorisatiesZoekenAntwoorden
): SetAutorisatiesFilterAction => {
  return {
    type: SET_AUTORISATIES_FILTER,
    sortOption,
    pageNumber,
    zoekFilter
  };
};
