import { State } from "store/state";
import { store } from "store/store";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsImportBestandGeldig extends VraagExpression {
  get operator(): string {
    return IsImportBestandGeldig.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, _context: ExpressionContext, _vraagProperty?: string): boolean => {
    const bestandImportState = (store.getState() as State).bestandImport;
    return (bestandImportState.file && !bestandImportState.errors) || bestandImportState.errors.length === 0;
  };

  protected clone = (instance: IsImportBestandGeldig): IsImportBestandGeldig =>
    new IsImportBestandGeldig(instance.vraagProperty);

  static fromJson = (json: any): IsImportBestandGeldig => new IsImportBestandGeldig(json.vraag);

  static operator = (): string => "isImportBestandGeldig";

  static matches = (json: any): boolean => json?.operator === IsImportBestandGeldig.operator();
}
