import { IsGezochtItemGevonden } from "./is-gezocht-item-gevonden";

export class IsLandGevonden extends IsGezochtItemGevonden {
  get operator(): string {
    return IsLandGevonden.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  protected clone = (instance: IsLandGevonden): IsLandGevonden => new IsLandGevonden(instance.vraagProperty);

  static fromJson = (json: any): IsLandGevonden => new IsLandGevonden(json.vraag);

  static operator = (): string => "isLandGevonden";

  static matches = (json: any): boolean => json?.operator === IsLandGevonden.operator();
}
