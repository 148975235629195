import { userHelpers } from "helpers";
import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";
import { User } from "../security/user";

export class AdresAntwoorden extends AntwoordBlok {
  constructor(
    public postcode: Antwoord<string>,
    public huisnummer: Antwoord<number | null>,
    public toevoeging: Antwoord<string>,
    public straatnaam: Antwoord<string>,
    public plaats: Antwoord<string>,
    json?: any
  ) {
    super(json);
  }

  static initialize(user: User | null = null): AdresAntwoorden {
    if (userHelpers.getIsParticulier(user)) {
      return new AdresAntwoorden(
        new Antwoord<string>(user?.postcode ?? ""),
        new Antwoord<number | null>(user?.huisnummer ?? null),
        new Antwoord<string>(user?.toevoeging ?? ""),
        new Antwoord<string>(user?.straat ?? ""),
        new Antwoord<string>(user?.plaats ?? "")
      );
    } else {
      return new AdresAntwoorden(
        new Antwoord<string>(user?.bedrijfPostcode ?? ""),
        new Antwoord<number | null>(user?.bedrijfHuisnummer ?? null),
        new Antwoord<string>(user?.bedrijfToevoeging ?? ""),
        new Antwoord<string>(user?.bedrijfStraat ?? ""),
        new Antwoord<string>(user?.bedrijfPlaats ?? "")
      );
    }
  }

  static fromJson(json: any): AdresAntwoorden | null {
    return json
      ? new AdresAntwoorden(
          Antwoord.fromJson<string>(json.postcode),
          Antwoord.fromJson<number | null>(json.huisnummer),
          Antwoord.fromJson<string>(json.toevoeging),
          Antwoord.fromJson<string>(json.straatnaam),
          Antwoord.fromJson<string>(json.plaats),
          json
        )
      : null;
  }
}
