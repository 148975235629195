import { AntwoordBlok } from "./antwoord-blok";
import { ContactpersoonAntwoorden } from "./contactpersoon-antwoorden";
import { CorrespondentieadresAntwoorden } from "./correspondentieadres-antwoorden";

export class ParticuliereOpdrachtgeverAntwoorden extends AntwoordBlok {
  constructor(
    public contactpersoon: ContactpersoonAntwoorden | null,
    public correspondentieadres: CorrespondentieadresAntwoorden | null
  ) {
    super();
  }

  static initialize(): ParticuliereOpdrachtgeverAntwoorden {
    return new ParticuliereOpdrachtgeverAntwoorden(
      ContactpersoonAntwoorden.initialize(),
      CorrespondentieadresAntwoorden.initialize()
    );
  }

  static fromJson(json: any): ParticuliereOpdrachtgeverAntwoorden {
    return new ParticuliereOpdrachtgeverAntwoorden(
      ContactpersoonAntwoorden.fromJson(json.contactpersoon),
      CorrespondentieadresAntwoorden.fromJson(json.correspondentieadres)
    );
  }
}
