import React from "react";
import { nameHelpers } from "../../../../helpers";
import {
  AntwoordBlok,
  SlachtofferInformatieAntwoorden,
  SlachtofferInformatieVragen,
  VraagBlok,
  VraagBlokCollection
} from "../../../../models/api";
import { VraagBlokCollectionType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";

const determineCollectionItemTitle = (antwoordBlok: SlachtofferInformatieAntwoorden): string => {
  const achternaam = antwoordBlok.contactpersoon?.achternaam.waarde;
  const voorletters = antwoordBlok.contactpersoon?.voorletters.waarde;
  const tussenvoegsels = antwoordBlok.contactpersoon?.tussenvoegsels.waarde;

  return (
    nameHelpers.getVolledigeNaamMetVoorletters({
      voorletters,
      tussenvoegsels,
      achternaam
    }) ?? ""
  );
};

export class Slachtoffers {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.slachtoffers;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => (
    <FormulierInputControls.CollectionAccordion
      itemComponent={GeneriekBlok.VraagBlok}
      getVraagBlokFromTemplate={SlachtofferInformatieVragen.fromJson}
      initializeAntwoorden={SlachtofferInformatieAntwoorden.initialize}
      vraagBlokCollection={vraagBlokCollection}
      getCollectionItemSummary={(antwoordBlok: AntwoordBlok) => {
        return determineCollectionItemTitle(antwoordBlok as SlachtofferInformatieAntwoorden);
      }}
    />
  );
}
