import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";

export class VluchtigeOrganischeStoffenWerkzaamhedenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public titel: Dictionary<string>,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public werkzaamhedenTitel: Tekst,
    public startdatum: Vraag,
    public einddatum: Vraag,
    public aantalUurPerDagAanWerkzaamheden: Vraag,
    public aantalDagenPerWeekAanWerkzaamheden: Vraag,
    public omschrijvingVosHoudendeProduct: Vraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): VluchtigeOrganischeStoffenWerkzaamhedenVragen {
    return new VluchtigeOrganischeStoffenWerkzaamhedenVragen(
      key,
      json.volgnummer,
      json.titel,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      jsonHelpers.toTitel(`${key}.werkzaamhedenTitel`, json.werkzaamhedenTitel),
      jsonHelpers.toDatumVraag(`${key}.startdatum`, json.startdatum),
      jsonHelpers.toDatumVraag(`${key}.einddatum`, json.einddatum),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalUurPerDagAanWerkzaamheden`, json.aantalUurPerDagAanWerkzaamheden),
      jsonHelpers.toNumeriekeVraag(`${key}.aantalDagenPerWeekAanWerkzaamheden`, json.aantalDagenPerWeekAanWerkzaamheden),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.omschrijvingVosHoudendeProduct`, json.omschrijvingVosHoudendeProduct)
    );
  }
}
