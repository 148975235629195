import { formulierApi, meldingApi } from "api";
import { FormulierDefinitie } from "formulier-definitie";
import { Antwoord, Bestand, MelderAntwoorden, WooMeldingAntwoordBlokken } from "models/api";
import { User } from "models/api/security/user";
import { AppThunk } from "thunks/app-thunk-type";
import { initializeFormulierProces } from "thunks/thunk-helpers";

export const initWooFormulier =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = await formulierApi.getWooFormulier();

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getWooFormulierDefinitie,
      WooMeldingAntwoordBlokken.initialize
    );
  };

export const initWooFormulierFromCopy =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const [formulier, melding] = await Promise.all([formulierApi.getWooFormulier(), meldingApi.getWooMelding(meldingId)]);

    const initAntwoordenFromCopy = (user: User | null): WooMeldingAntwoordBlokken => {
      return {
        ...melding.blokken,
        melder: {
          ...melding.blokken.melder,
          common: MelderAntwoorden.initialize(user)
        },
        bijlage: {
          ...melding.blokken.bijlage,
          bestanden: new Antwoord<Bestand[]>([])
        }
      };
    };

    await initializeFormulierProces(
      dispatch,
      getState,
      formulier,
      FormulierDefinitie.getWooFormulierDefinitie,
      initAntwoordenFromCopy
    );
  };
