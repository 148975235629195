import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "../blok/antwoord-blok";

export class MedischeOmstandighedenAntwoorden extends AntwoordBlok {
  constructor(
    public medischeRedenenVoorPermanenteNachtarbeid: Antwoord<string>,
    public recenteVerklaringBedrijfsartsAanwezig: Antwoord<string>,
    public recenteVerklaringHuisartsOfSpecialistAanwezig: Antwoord<string>,
    public verklaringOverlegdMetBedrijfsarts: Antwoord<string>,
    public verklaringEnMedischeGegevensTeOverleggen: Antwoord<string>,
    public andereMedischeKlachtenDieNachtarbeidBemoeilijken: Antwoord<string>,
    public consequentiesGeenOntheffingMedisch: Antwoord<string>,
    public watGedaanVoorGezondWerkroosterMetWerkgever: Antwoord<string>,
    public welkeOptiesAlGeprobeerd: Antwoord<string>,
    public overigeOpmerkingenOfToelichting: Antwoord<string>
  ) {
    super();
  }

  static initialize(): MedischeOmstandighedenAntwoorden {
    return new MedischeOmstandighedenAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): MedischeOmstandighedenAntwoorden {
    return new MedischeOmstandighedenAntwoorden(
      Antwoord.fromJson<string>(json.medischeRedenenVoorPermanenteNachtarbeid),
      Antwoord.fromJson<string>(json.recenteVerklaringBedrijfsartsAanwezig),
      Antwoord.fromJson<string>(json.recenteVerklaringHuisartsOfSpecialistAanwezig),
      Antwoord.fromJson<string>(json.verklaringOverlegdMetBedrijfsarts),
      Antwoord.fromJson<string>(json.verklaringEnMedischeGegevensTeOverleggen),
      Antwoord.fromJson<string>(json.andereMedischeKlachtenDieNachtarbeidBemoeilijken),
      Antwoord.fromJson<string>(json.consequentiesGeenOntheffingMedisch),
      Antwoord.fromJson<string>(json.watGedaanVoorGezondWerkroosterMetWerkgever),
      Antwoord.fromJson<string>(json.welkeOptiesAlGeprobeerd),
      Antwoord.fromJson<string>(json.overigeOpmerkingenOfToelichting)
    );
  }
}
