import {
  AntwoordBlokken,
  BiologischeAgentiaWerkzaamhedenAntwoorden,
  MelderAntwoorden,
  WerkzaamhedenOpAdresBedrijfLocatieAntwoorden
} from "../blok";
import { User } from "../security/user";
import { HasBiologischeAgentiaWerkzaamhedenAntwoorden } from "./has-biologische-agentia-werkzaamheden-antwoorden";
import { HasMelderAntwoorden } from "./has-melder-antwoorden";
import { HasWerkzaamhedenOpAdresBedrijfLocatieAntwoorden } from "./has-werkzaamheden-op-adres-bedrijf-locatie-antwoorden";

export class BiologischeAgentiaMeldingAntwoordBlokken
  extends AntwoordBlokken
  implements
    HasMelderAntwoorden,
    HasWerkzaamhedenOpAdresBedrijfLocatieAntwoorden,
    HasBiologischeAgentiaWerkzaamhedenAntwoorden
{
  constructor(
    public melder: MelderAntwoorden,
    public locatie: WerkzaamhedenOpAdresBedrijfLocatieAntwoorden,
    public werkzaamheden: BiologischeAgentiaWerkzaamhedenAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): BiologischeAgentiaMeldingAntwoordBlokken {
    return new BiologischeAgentiaMeldingAntwoordBlokken(
      MelderAntwoorden.initialize(user),
      WerkzaamhedenOpAdresBedrijfLocatieAntwoorden.initialize(),
      BiologischeAgentiaWerkzaamhedenAntwoorden.initialize()
    );
  }

  static fromJson(json: any): BiologischeAgentiaMeldingAntwoordBlokken {
    return new BiologischeAgentiaMeldingAntwoordBlokken(
      MelderAntwoorden.fromJson(json.melder),
      WerkzaamhedenOpAdresBedrijfLocatieAntwoorden.fromJson(json.locatie),
      BiologischeAgentiaWerkzaamhedenAntwoorden.fromJson(json.werkzaamheden)
    );
  }
}
