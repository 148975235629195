import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { objectHelpers } from "../../../../../../helpers";
import { CollectionCopy } from "../../../collection-copy";
import { CollectionRemove } from "../../../collection-remove";
import { useGridRowToggle } from "../../grid-row-toggle";
import { CollectionColumnAnswer } from "../collection-column-answer";
import { CollectionTableRowToggleEdit } from "../collection-table-row-toggle/edit";
import { CollectionTableRowSummaryProps } from "./collection-table-row-summary.component.interfaces";

export const CollectionTableRowSummary = (props: CollectionTableRowSummaryProps) => {
  const { t } = useTranslation();
  const { vraagAntwoordBlok, collectionValidationFailures, selectedCollectionItemKey, setSelectedCollectionItem } = props;
  const { vraagBlok } = vraagAntwoordBlok;
  const { key: vraagBlokKey } = vraagBlok;

  const showErrorInRow = useMemo(() => {
    const hasError = collectionValidationFailures
      .map((vf) => objectHelpers.removeLastPropertyFromKey(vf.property, true))
      .find((vf) => vf.startsWith(`${vraagBlokKey}.`));

    const isSelected = selectedCollectionItemKey === vraagBlokKey;
    return hasError && !isSelected;
  }, [collectionValidationFailures, vraagBlokKey, selectedCollectionItemKey]);

  const gridToggleEvent = useGridRowToggle(vraagBlokKey, selectedCollectionItemKey, setSelectedCollectionItem);

  return (
    <tr onClick={gridToggleEvent} key={`row-${props.rowIndex}`} {...(showErrorInRow && { className: "error-row" })}>
      {Object.keys(props.columnSettings.columns).map((columnName: string, columnIndex: number) => {
        return (
          props.columnSettings.columns[columnName].visible !== false && (
            <td key={`cell-${props.rowIndex}-${columnName}-${columnIndex}`}>
              {columnIndex === 0 && showErrorInRow && (
                <FontAwesomeIcon icon={faWarning} size="1x" className="error-row-icon" />
              )}
              <label key={`cell-${props.rowIndex}-label-${columnName}-${columnIndex}`}>
                {t(props.columnSettings.translationBase[columnName].title)}
              </label>
              <span
                key={`cell-${props.rowIndex}-value-${columnName}-${columnIndex}`}
                id={`cell-${props.rowIndex}-value-${columnName}-${columnIndex}`}
              >
                <CollectionColumnAnswer
                  columnName={columnName}
                  vraagAntwoordIndexBlok={vraagAntwoordBlok}
                  columnFormatter={props.columnSettings.columns[columnName]?.columnFormatter}
                />
              </span>
            </td>
          )
        );
      })}
      <td key={`cell-${props.rowIndex}-options`} className="last-cell icons-cell" onClick={(e: any) => e.stopPropagation()}>
        <CollectionTableRowToggleEdit collectionKey={props.collectionKey} rowIndex={props.rowIndex} rowKey={vraagBlokKey} />
        <CollectionCopy
          collectionKey={props.collectionKey}
          collectionVerplichtType={props.collectionVerplichtType}
          template={props.template}
          rowIndex={props.rowIndex}
          vraagAntwoordBlok={props.vraagAntwoordBlok}
          getVraagBlokFromTemplate={props.getVraagBlokFromTemplate}
          copyAntwoorden={props.copyAntwoorden}
        />
        <CollectionRemove
          vraagAntwoordBlok={props.vraagAntwoordBlok}
          collectionKey={props.collectionKey}
          collectionVerplichtType={props.collectionVerplichtType}
          rowIndex={props.rowIndex}
          removeCollectionItem={props.removeCollectionItem}
          displayIconOnly={true}
        />
      </td>
    </tr>
  );
};
