import { AntwoordBlokken, ContactMelderAntwoorden, ContactVraagAntwoorden } from "../blok";
import { User } from "../security/user";
import { HasContactVraagAntwoorden } from "./has-contactvraag-antwoorden";

export class ContactMeldingAntwoordBlokken extends AntwoordBlokken implements HasContactVraagAntwoorden {
  constructor(
    public contactMelder: ContactMelderAntwoorden,
    public contactVraag: ContactVraagAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): ContactMeldingAntwoordBlokken {
    return new ContactMeldingAntwoordBlokken(ContactMelderAntwoorden.initialize(user), ContactVraagAntwoorden.initialize());
  }

  static fromJson(json: any): ContactMeldingAntwoordBlokken {
    return new ContactMeldingAntwoordBlokken(
      ContactMelderAntwoorden.fromJson(json.contactMelder),
      ContactVraagAntwoorden.fromJson(json.contactVraag)
    );
  }
}
