import { GegevensInstallatieAntwoorden } from "models/api/blok/gegevens-installatie-antwoorden";
import { GegevensInstallatieVragen } from "models/api/blok/gegevens-installatie-vragen";
import React from "react";
import { AntwoordBlok, VraagBlok, VraagBlokCollection } from "../../../../models/api";
import { VraagBlokCollectionType } from "../../../../models/application";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";

export class GegevensInstallaties {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.gegevensInstallaties;

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): React.JSX.Element => {
    return (
      <FormulierInputControls.CollectionAccordion
        itemComponent={GeneriekBlok.VraagBlok}
        getVraagBlokFromTemplate={GegevensInstallatieVragen.fromJson}
        initializeAntwoorden={GegevensInstallatieAntwoorden.initialize}
        vraagBlokCollection={vraagBlokCollection}
        getCollectionItemSummary={(_antwoordBlok: AntwoordBlok) => ""}
      />
    );
  };
}
