import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { translationKeys } from "constants/translation-keys";
import { useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DebouncedFormControl } from "../debounce-input";
import { InputSearchOwnProps } from "./input-search.interfaces";

export const InputSearch = (props: InputSearchOwnProps) => {
  const { t } = useTranslation();
  const { name, onSearch, placeholder, value, className } = props;
  const [currentValue, setCurrentValue] = useState("");

  const handleChange = (event: any) => {
    if (onSearch) {
      setCurrentValue(event.target.value);
      onSearch(event.target.value);
    }
  };

  const handleSearchButton = () => {
    if (onSearch) {
      onSearch(currentValue);
    }
  };

  return (
    <InputGroup className={className}>
      <InputGroup.Text>
        <FontAwesomeIcon
          icon={faSearch}
          className="searchIcon"
          size="1x"
          title={placeholder ? placeholder : t(translationKeys.zoekenPlaceholder)}
        />
      </InputGroup.Text>
      <DebouncedFormControl
        disabled={props.disabled}
        type="search"
        name={name}
        id={name}
        placeholder={placeholder ? placeholder : t(translationKeys.zoekenPlaceholder)}
        onChange={handleChange}
        value={value}
      />
      <Button onClick={handleSearchButton} disabled={props.disabled}>
        {t(translationKeys.zoekenButton)}
      </Button>
    </InputGroup>
  );
};
