import {
  AangeverVragen,
  BijlageDouaneVragen,
  GeadresseerdeVragen,
  LocatieProductVragen,
  MelderVragen,
  MeldingDouaneVragen,
  VraagBlokken
} from "../blok";
import { HasAangeverVragen } from "./has-aangever-vragen";
import { HasBijlageDouaneVragen } from "./has-bijlage-douane-vragen";
import { HasGeadresseerdeVragen } from "./has-geadresseerde-vragen";
import { HasLocatieProductVragen } from "./has-locatie-product-vragen";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasMeldingDouaneVragen } from "./has-melding-douane-vragen";

export class DouaneFormulierVraagBlokken
  extends VraagBlokken
  implements
    HasMelderVragen,
    HasMeldingDouaneVragen,
    HasLocatieProductVragen,
    HasAangeverVragen,
    HasGeadresseerdeVragen,
    HasBijlageDouaneVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public melding: MeldingDouaneVragen,
    public locatieProduct: LocatieProductVragen,
    public aangever: AangeverVragen,
    public geadresseerde: GeadresseerdeVragen,
    public bijlage: BijlageDouaneVragen
  ) {
    super();
  }

  static fromJson(json: any): DouaneFormulierVraagBlokken {
    return new DouaneFormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      MeldingDouaneVragen.fromJson("melding", json.melding),
      LocatieProductVragen.fromJson("locatieProduct", json.locatieProduct),
      AangeverVragen.fromJson("aangever", json.aangever),
      GeadresseerdeVragen.fromJson("geadresseerde", json.geadresseerde),
      BijlageDouaneVragen.fromJson("bijlage", json.bijlage)
    );
  }
}
