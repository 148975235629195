import { Antwoord } from "../antwoord";
import { Land } from "../landen-search-response";
import { User } from "../security/user";
import { AdresAntwoorden } from "./adres-antwoorden";
import { AntwoordBlok } from "./antwoord-blok";

export class CorrespondentieadresAntwoorden extends AntwoordBlok {
  constructor(
    public land: Antwoord<string>,
    public nederlandsAdres: AdresAntwoorden | null,
    public buitenlandsAdres: AdresAntwoorden | null
  ) {
    super();
  }

  static initialize(user: User | null = null): CorrespondentieadresAntwoorden {
    const land = user?.land;
    if (land) {
      const isNL = land === Land.landKeyNl;
      const userAdres = AdresAntwoorden.initialize(user);
      const otherAdres = AdresAntwoorden.initialize();

      return new CorrespondentieadresAntwoorden(
        new Antwoord<string>(land),
        isNL ? userAdres : otherAdres,
        isNL ? otherAdres : userAdres
      );
    }

    return new CorrespondentieadresAntwoorden(
      new Antwoord<string>(""),
      AdresAntwoorden.initialize(),
      AdresAntwoorden.initialize()
    );
  }

  static fromJson(json: any): CorrespondentieadresAntwoorden | null {
    return json
      ? new CorrespondentieadresAntwoorden(
          Antwoord.fromJson<string>(json.land),
          AdresAntwoorden.fromJson(json.nederlandsAdres),
          AdresAntwoorden.fromJson(json.buitenlandsAdres)
        )
      : null;
  }
}
