export class Land {
  constructor(public displayNaam: string, public landKey: string, public isoNummer: number) {}

  static fromJson(json: any): Land {
    return new Land(json.naam, json.key, json.isoNummer);
  }

  public static readonly isoNummerNl: number = 528;
  public static readonly landKeyNl: string = "Nederland";
}

export class LandenSearchResponse {
  constructor(public landen: Land[]) {}

  static fromJson(json: any): LandenSearchResponse {
    const landen = json.map((l: Land) => Land.fromJson(l));
    return new LandenSearchResponse(landen);
  }
}
