import { Antwoord } from "..";
import { AntwoordBlok } from "./antwoord-blok";

export class AanleveringLavsAntwoorden extends AntwoordBlok {
  constructor(
    public meldingnummer: Antwoord<string>,
    public opdrachtnummer: Antwoord<string>,
    public projectnummer: Antwoord<string>,
    public meldingstatus: Antwoord<string>,
    public datumGemeld: Antwoord<string>,
    public tussentijdseMelding: Antwoord<string>
  ) {
    super();
  }

  static initialize() {
    return new AanleveringLavsAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any) {
    return new AanleveringLavsAntwoorden(
      Antwoord.fromJson(json.meldingnummer),
      Antwoord.fromJson(json.opdrachtnummer),
      Antwoord.fromJson(json.projectnummer),
      Antwoord.fromJson(json.meldingstatus),
      Antwoord.fromJson(json.datumGemeld),
      Antwoord.fromJson(json.tussentijdseMelding)
    );
  }
}
