import { Paragraaf } from "components/blok/generiek-vraag-blok/supported-tekst-typen/paragraaf";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Vraag } from "../vraag";
import { VraagBlok } from "../vraag-blok";
import { AanvullendeRisicoInventarisatieVragen } from "./aanvullende-risico-inventarisatie-vragen";
import { AlgemeenVerbindendVerklarenCaoVragen } from "./algemeen-verbindend-verklaren-cao-vragen";
import { ArbeidsMeldingSituatieVragen } from "./arbeids-melding-situatie-vragen";
import { ArbeidsomstandighedenVragen } from "./arbeidsomstandigheden-vragen";
import { ArbeidstijdenVragen } from "./arbeidstijden-vragen";
import { BesluitRisicoZwareOngevallenVragen } from "./besluit-risico-zware-ongevallen-vragen";
import { BestrijdingsmiddelenVragen } from "./bestrijdingsmiddelen-vragen";
import { DwangDoorWerkgeverVragen } from "./dwang-door-werkgever-vragen";
import { FraudeSocialeZekerheidVragen } from "./fraude-sociale-zekerheid-vragen";
import { IllegaleTewerkstellingVragen } from "./illegale-tewerkstelling-vragen";
import { MalafideUitzendbureauVragen } from "./malafide-uitzendbureau-vragen";
import { OnderbetalingVragen } from "./onderbetaling-vragen";
import { WaadiVragen } from "./waadi-vragen";
import { WagweuVragen } from "./wagweu-vragen";

export class MeldingVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public klachtenMeldingToelichting: Paragraaf,
    public typeKlachtMelding: Meerkeuzevraag,
    public acuutLevensgevaarlijkeSituatie: Meerkeuzevraag,
    public onderwerp: Meerkeuzevraag,
    public arbeidsMeldingSituatie: ArbeidsMeldingSituatieVragen,
    public betreftBouwkundigeAangelegenheid: Meerkeuzevraag,
    public betreftOverschrijding: Meerkeuzevraag,
    public illegaleTewerkstelling: IllegaleTewerkstellingVragen,
    public onderbetaling: OnderbetalingVragen,
    public malafideUitzendbureau: MalafideUitzendbureauVragen,
    public arbeidsomstandigheden: ArbeidsomstandighedenVragen,
    public arbeidstijden: ArbeidstijdenVragen,
    public hoeveelPersonenWerkomstandigheden: Vraag,
    public dwangDoorWerkgever: DwangDoorWerkgeverVragen,
    public bestrijdingsmiddelen: BestrijdingsmiddelenVragen,
    public waadi: WaadiVragen,
    public aanvullendeRisicoInventarisatie: AanvullendeRisicoInventarisatieVragen,
    public besluitRisicoZwareOngevallen: BesluitRisicoZwareOngevallenVragen,
    public wagweu: WagweuVragen,
    public algemeenVerbindendVerklarenCao: AlgemeenVerbindendVerklarenCaoVragen,
    public fraudeSocialeZekerheid: FraudeSocialeZekerheidVragen
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): MeldingVragen {
    return new MeldingVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toParagraaf(`${key}.klachtenMeldingToelichting`, json.klachtenMeldingToelichting),
      jsonHelpers.toMeerkeuzeVraag(`${key}.typeKlachtMelding`, json.typeKlachtMelding),
      jsonHelpers.toMeerkeuzeVraag(`${key}.acuutLevensgevaarlijkeSituatie`, json.acuutLevensgevaarlijkeSituatie),
      jsonHelpers.toChecklistvraag(`${key}.onderwerp`, json.onderwerp),
      ArbeidsMeldingSituatieVragen.fromJson(`${key}.arbeidsMeldingSituatie`, json.arbeidsMeldingSituatie),
      jsonHelpers.toMeerkeuzeVraag(`${key}.betreftBouwkundigeAangelegenheid`, json.betreftBouwkundigeAangelegenheid),
      jsonHelpers.toMeerkeuzeVraag(`${key}.betreftOverschrijding`, json.betreftOverschrijding),
      IllegaleTewerkstellingVragen.fromJson(`${key}.illegaleTewerkstelling`, json.illegaleTewerkstelling),
      OnderbetalingVragen.fromJson(`${key}.onderbetaling`, json.onderbetaling),
      MalafideUitzendbureauVragen.fromJson(`${key}.malafideUitzendbureau`, json.malafideUitzendbureau),
      ArbeidsomstandighedenVragen.fromJson(`${key}.arbeidsomstandigheden`, json.arbeidsomstandigheden),
      ArbeidstijdenVragen.fromJson(`${key}.arbeidstijden`, json.arbeidstijden),
      jsonHelpers.toNumeriekeVraag(`${key}.hoeveelPersonenWerkomstandigheden`, json.hoeveelPersonenWerkomstandigheden),
      DwangDoorWerkgeverVragen.fromJson(`${key}.dwangDoorWerkgever`, json.dwangDoorWerkgever),
      BestrijdingsmiddelenVragen.fromJson(`${key}.bestrijdingsmiddelen`, json.bestrijdingsmiddelen),
      WaadiVragen.fromJson(`${key}.waadi`, json.waadi),
      AanvullendeRisicoInventarisatieVragen.fromJson(
        `${key}.aanvullendeRisicoInventarisatie`,
        json.aanvullendeRisicoInventarisatie
      ),
      BesluitRisicoZwareOngevallenVragen.fromJson(`${key}.besluitRisicoZwareOngevallen`, json.besluitRisicoZwareOngevallen),
      WagweuVragen.fromJson(`${key}.wagweu`, json.wagweu),
      AlgemeenVerbindendVerklarenCaoVragen.fromJson(
        `${key}.algemeenVerbindendVerklarenCao`,
        json.algemeenVerbindendVerklarenCao
      ),
      FraudeSocialeZekerheidVragen.fromJson(`${key}.fraudeSocialeZekerheid`, json.fraudeSocialeZekerheid)
    );
  }
}
