import { withConditioneelGesteldeVraag } from "components/formulier/with-conditioneel-gesteld";
import { withVraag } from "components/formulier/with-vraag";
import { Field } from "components/input-controls/field";
import { withValidation } from "components/input-controls/with-validation";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";
import { componentHelpers, meldingHelpers } from "../../../helpers";
import { AntwoordBlokken } from "../../../models/api";
import { FormulierInputControls } from "../../formulier";
import { VolgnummerElement } from "../../input-controls";
import { BrpSearch } from "./brp-search";
import { KindgegevensProps } from "./kindgegevens.component.interfaces";

export const Kindgegevens = (props: KindgegevensProps) => {
  const { values, setFieldValue } = useFormikContext<AntwoordBlokken>();

  const heeftKindBsnAntwoord = meldingHelpers.getAntwoordOrDefault<string>(values, props.vragen.heeftKindBsn, "");
  const bsnIsGeldig = meldingHelpers.getAntwoordOrDefault<boolean | undefined>(values, props.vragen.bsnIsGeldig, undefined);

  useEffect(() => {
    // opruimen eventuele opgeslagen vnummer indien het een bsn kind betreft en visa versa.
    // dit wordt niet vanzelf opgeruimd omdat de vragen niet generiek opgebouwd worden voor dit component
    // (en de diverse logica waaronder het opruimen niet af gaat)
    if (heeftKindBsnAntwoord) {
      setFieldValue(
        meldingHelpers.getFieldNameFromVraag(heeftKindBsnAntwoord === "ja" ? props.vragen.kindVolgnummer : props.vragen.bsn),
        ""
      );
    }
  }, [setFieldValue, heeftKindBsnAntwoord, props.vragen.bsn, props.vragen.kindVolgnummer]);

  const FieldWithSection = (props: any) => {
    const { name, children } = props;
    const [, meta] = useField(name);
    const hasError = meta.error && meta.touched;

    return (
      <Field name={name} inputOnly={true}>
        <section className={hasError ? "invalid" : "valid mb-3"}>{children}</section>
      </Field>
    );
  };

  // The section uses validation based on the input vraag connected to the component
  const ValidatedSection = withConditioneelGesteldeVraag(withVraag(withValidation(FieldWithSection)));

  const nawComponents =
    heeftKindBsnAntwoord === "ja"
      ? [
          <VolgnummerElement
            getVolgnummer={() => props.vragen.bsn.volgnummer}
            key={`${props.vragen.key}-bsn-search-container`}
          >
            <BrpSearch vragen={props.vragen} key={`${props.vragen.key}-brp-search`} />
          </VolgnummerElement>
        ]
      : [
          <FormulierInputControls.Text vraag={props.vragen.kindVolgnummer} key={props.vragen.kindVolgnummer.key} />,
          <FormulierInputControls.Text vraag={props.vragen.achternaam} key={props.vragen.achternaam.key} />,
          <FormulierInputControls.Date vraag={props.vragen.geboorteDatum} key={props.vragen.geboorteDatum.key} />,
          <FormulierInputControls.Text vraag={props.vragen.tussenvoegsels} key={props.vragen.tussenvoegsels.key} />
        ];

  const components = [
    <FormulierInputControls.Radio
      vraag={props.vragen.heeftKindBsn}
      key={props.vragen.heeftKindBsn.key}
      readOnly={!!bsnIsGeldig || props.brpIsLoading}
    />,
    ...nawComponents,
    <FormulierInputControls.Text vraag={props.vragen.roepnaam} key={props.vragen.roepnaam.key} />,
    <ValidatedSection key={props.vragen.bsnIsGeldig.key} vraag={props.vragen.bsnIsGeldig}></ValidatedSection>
  ];

  return <>{componentHelpers.sortOnVolgnummer(components)}</>;
};
