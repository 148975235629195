import {
  BiologischeAgentiaWerkzaamhedenVragen,
  MelderVragen,
  VraagBlokken,
  WerkzaamhedenOpAdresBedrijfLocatieVragen
} from "../blok";
import { HasBiologischeAgentiaWerkzaamhedenVragen } from "./has-biologische-agentia-werkzaamheden-vragen";
import { HasMelderVragen } from "./has-melder-vragen";
import { HasWerkzaamhedenOpAdresBedrijfLocatieVragen } from "./has-werkzaamheden-op-adres-bedrijf-locatie-vragen";

export class BiologischeAgentiaFormulierVraagBlokken
  extends VraagBlokken
  implements HasMelderVragen, HasWerkzaamhedenOpAdresBedrijfLocatieVragen, HasBiologischeAgentiaWerkzaamhedenVragen
{
  [key: string]: any;

  constructor(
    public melder: MelderVragen,
    public locatie: WerkzaamhedenOpAdresBedrijfLocatieVragen,
    public werkzaamheden: BiologischeAgentiaWerkzaamhedenVragen
  ) {
    super();
  }

  static fromJson(json: any): BiologischeAgentiaFormulierVraagBlokken {
    return new BiologischeAgentiaFormulierVraagBlokken(
      MelderVragen.fromJson("melder", json.melder),
      WerkzaamhedenOpAdresBedrijfLocatieVragen.fromJson("locatie", json.locatie),
      BiologischeAgentiaWerkzaamhedenVragen.fromJson("werkzaamheden", json.werkzaamheden)
    );
  }
}
