import { Antwoord } from "../antwoord";
import { AdresAntwoorden } from "./adres-antwoorden";

export class NederlandsAdresAntwoorden extends AdresAntwoorden {
  constructor(
    public postcode: Antwoord<string>,
    public huisnummer: Antwoord<number | null>,
    public toevoeging: Antwoord<string>,
    public straatnaam: Antwoord<string>,
    public plaats: Antwoord<string>
  ) {
    super(postcode, huisnummer, toevoeging, straatnaam, plaats);
  }

  static initialize(): NederlandsAdresAntwoorden {
    return new NederlandsAdresAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<number | null>(null),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): NederlandsAdresAntwoorden | null {
    return json
      ? new NederlandsAdresAntwoorden(
          Antwoord.fromJson<string>(json.postcode),
          Antwoord.fromJson<number | null>(json.huisnummer),
          Antwoord.fromJson<string>(json.toevoeging),
          Antwoord.fromJson<string>(json.straatnaam),
          Antwoord.fromJson<string>(json.plaats)
        )
      : null;
  }
}
