import { translationKeys } from "constants/translation-keys";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  CollectionEventsProvider,
  CollectionItemCanBeRemovedResult
} from "../../../../components/blok/vraag-blokken-collection";
import { vraagBlokCollectionHelpers } from "../../../../helpers";
import {
  AntwoordBlok,
  AntwoordBlokCollection,
  LocatiesVragen,
  LocatieWerkzaamhedenAntwoorden,
  LocatieWerkzaamhedenVragen,
  VraagBlok,
  VraagBlokCollection,
  WerkmomentenVragen,
  WerkmomentGegevensAntwoorden
} from "../../../../models/api";
import { ProcesstapType, VraagBlokCollectionType } from "../../../../models/application";
import { State } from "../../../../store";
import { FormulierInputControls } from "../../../formulier";
import { GeneriekBlok } from "../generiek-vraag-blok.component";
import { LocatiesWerkzaamhedenStateProps } from "./locaties-werkzaamheden.interfaces";

const formatPlaats = (antwoordBlok: AntwoordBlok) => {
  const antwoorden = antwoordBlok as LocatieWerkzaamhedenAntwoorden;
  return antwoorden.locatie?.nederlandsAdres?.plaats?.waarde;
};

const mapStateToProps = (state: State): LocatiesWerkzaamhedenStateProps => {
  const locatiesVragen = state.vragen.formulier?.blokken[ProcesstapType.locaties] as LocatiesVragen;
  const werkmomentenVragen = state.vragen.formulier?.blokken[ProcesstapType.werkmomenten] as WerkmomentenVragen;

  return {
    antwoordBlokken: state.antwoorden.blokken,
    locatieVraagBlokCollectionProperty: locatiesVragen.locaties.key,
    werkmomentVraagBlokCollectionProperty: werkmomentenVragen.werkmomentenGegevensLijst.key
  };
};
export class LocatiesWerkzaamheden {
  static getType = (): VraagBlokCollectionType => VraagBlokCollectionType.locatiesWerkzaamheden;

  static createLocatieWerkzaamheden =
    (vraagBlokCollection: VraagBlokCollection<VraagBlok>) => (props: LocatiesWerkzaamhedenStateProps) => {
      const { t } = useTranslation();

      const canLocatieBeRemoved = (_key: string, index: number): CollectionItemCanBeRemovedResult => {
        let canBeRemoved = true;
        const reason = t(translationKeys.blok.locatie.validatie.verwijderen);
        const locatie = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemByIndex(
          index,
          props.locatieVraagBlokCollectionProperty,
          props.antwoordBlokken
        ) as LocatieWerkzaamhedenAntwoorden;
        const werkmomenten = vraagBlokCollectionHelpers.getAntwoordBlokCollection(
          props.werkmomentVraagBlokCollectionProperty,
          props.antwoordBlokken
        ) as AntwoordBlokCollection<WerkmomentGegevensAntwoorden>;

        if (locatie && werkmomenten) {
          canBeRemoved = !werkmomenten.list.some((x) => x.locatieWerkmoment.waarde === locatie.id);
        }

        return { canBeRemoved: canBeRemoved, reason: reason };
      };

      return (
        <Fragment>
          <CollectionEventsProvider canBeRemoved={canLocatieBeRemoved}>
            <FormulierInputControls.CollectionGrid
              itemComponent={GeneriekBlok.VraagBlok}
              getVraagBlokFromTemplate={LocatieWerkzaamhedenVragen.fromJson}
              initializeAntwoorden={LocatieWerkzaamhedenAntwoorden.initialize}
              copyAntwoorden={LocatieWerkzaamhedenAntwoorden.copyFromJson}
              vraagBlokCollection={vraagBlokCollection}
              getCollectionItemSummary={(antwoordBlok: AntwoordBlok) => {
                const antwoorden = antwoordBlok as LocatieWerkzaamhedenAntwoorden;
                return t(translationKeys.blok.locatie.samengesteldeWaarde, {
                  locatieNaam: antwoorden.locatieNaam?.waarde ?? "",
                  plaats: formatPlaats(antwoorden)
                });
              }}
              pagingSettings={{
                numberOfItemsPerPage: 10,
                getTotalsDescription: (counters) =>
                  counters.totalItemsInGrid > counters.filteredItemsInGrid
                    ? t(translationKeys.blok.locatie.locatieLijst.paginering.totaalAantalGefilterd, counters)
                    : t(translationKeys.blok.locatie.locatieLijst.paginering.totaalAantal, counters)
              }}
              columnSettings={{
                columns: {
                  locatieNaam: {},
                  plaats: { columnFormatter: formatPlaats }
                },
                translationBase: translationKeys.blok.locatie.locatieLijst.tabelkop
              }}
              defaultNewestOnTop={true}
            />
          </CollectionEventsProvider>
        </Fragment>
      );
    };

  static create = (vraagBlokCollection: VraagBlokCollection<VraagBlok>): any =>
    React.createElement(connect(mapStateToProps)(LocatiesWerkzaamheden.createLocatieWerkzaamheden(vraagBlokCollection)));
}
