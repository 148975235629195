import { useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Bestand } from "../../../models/api/blok/bestand";
import { MeldingFile } from "../../../models/application";
import { State } from "../../../store";
import { InputFiles } from "../input-files/input-files";
import { FileOverwriteDialog } from "./file-overwrite-dialog";
import { InputUniqueFilesStateProps } from "./input-unique-files.interface";

export const InputUniqueFiles = (props: InputUniqueFilesStateProps | any) => {
  const { onAddFiles, ...rest } = props;
  const files: MeldingFile[] = props.files;

  const [conflictingState, setConflictingState] = React.useState(false);
  const { setFieldValue } = useFormikContext();

  const onAddUniqueFiles = (addedFiles: File[]) => {
    onAddFiles(addedFiles);
    // skip files with duplicate names for the formik context
    updateFieldValues2([...files, ...addedFiles.filter((file) => files.every((f) => f.name !== file.name))]);
  };

  const updateFieldValues2 = useCallback(
    (updateFiles: (File | MeldingFile)[]) => {
      const name = props.name;
      setFieldValue(
        name,
        [...updateFiles].map((f) => {
          return new Bestand(f.name, f.size, (f as MeldingFile).id !== undefined ? (f as MeldingFile).id : null);
        })
      );
    },
    [props, setFieldValue]
  );

  useEffect(() => {
    const onInit = (hasConflicts: boolean) => {
      if (hasConflicts !== conflictingState && !hasConflicts) {
        updateFieldValues2(props.files);
      }
      setConflictingState(hasConflicts);
    };

    onInit(props.hasConflicts);
  }, [props, conflictingState, setConflictingState, updateFieldValues2]);

  return (
    <div>
      <InputFiles {...rest} onAddFiles={onAddUniqueFiles} />
      <FileOverwriteDialog />
    </div>
  );
};

const mapStateToProps = (state: State): any => ({
  hasConflicts: state.bestanden.conflictingFiles.length > 0
});

export default connect(mapStateToProps)(InputUniqueFiles);
