import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { BijlageAntwoorden } from "./bijlage-antwoorden";
import { ZaakPincodeAntwoorden } from "./zaak-pincode-antwoorden";

export class UploadAntwoorden extends AntwoordBlok {
  constructor(
    public zaakPincode: ZaakPincodeAntwoorden,
    public emailadres: Antwoord<string>,
    public bestanden: BijlageAntwoorden
  ) {
    super();
  }

  static initialize(user: User | null = null): UploadAntwoorden {
    return new UploadAntwoorden(
      ZaakPincodeAntwoorden.initialize(),
      new Antwoord<string>(user?.emailadres ?? ""),
      BijlageAntwoorden.initialize()
    );
  }

  static fromJson(json: any): UploadAntwoorden {
    return new UploadAntwoorden(
      ZaakPincodeAntwoorden.fromJson(json.zaakPincode),
      Antwoord.fromJson(json.emailadres),
      BijlageAntwoorden.fromJson(json.bestanden)
    );
  }
}
