import React from "react";
import { SamenvattingFieldOwnProps } from "../samenvatting-field/samenvatting-field.component.interfaces";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { withSamenvattingVraag } from "../with-samenvatting-vraag";
import { WithSamenvattingGeneralVraagProps } from "./with-samenvatting-general-vraag.interfaces";

export const withSamenvattingGeneralVraag = <P extends object>(
  Component: React.ComponentType<P & SamenvattingFieldOwnProps>
) => {
  return (props: P & WithSamenvattingGeneralVraagProps) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(withSamenvattingVraag(Component));

    return <SamenvattingVraagComponent {...props} item={props.vraag} />;
  };
};
