import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class IsSame extends VraagExpression {
  get operator(): string {
    return IsSame.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly otherVraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    return (
      context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde ===
      context.getAntwoord(this.otherVraagProperty)?.waarde
    );
  };

  protected clone = (instance: IsSame): IsSame => new IsSame(instance.vraagProperty, instance.otherVraagProperty);

  static fromJson = (json: any): IsSame => new IsSame(json.vraag, json.otherVraag);

  static operator = (): string => "isSame";

  static matches = (json: any): boolean => json?.operator === IsSame.operator();
}
