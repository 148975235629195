import { DigipassVragen, VraagBlokken } from "../blok";
import { HasDigipassVragen } from "./has-digipass-vragen";

export class DigipassFormulierVraagBlokken extends VraagBlokken implements HasDigipassVragen {
  [key: string]: any;

  constructor(public digipass: DigipassVragen) {
    super();
  }

  static fromJson(json: any): DigipassFormulierVraagBlokken {
    return new DigipassFormulierVraagBlokken(DigipassVragen.fromJson("digipass", json.digipass));
  }
}
