import { userHelpers } from "helpers";
import { connect } from "react-redux";
import {
  clearCaptchaError,
  formReinitializationComplete,
  resetFormulier,
  saveAntwoorden,
  setCaptchaError,
  setHasValidationErrors,
  setNextPage,
  setPreviousPage,
  setRequestedPage,
  setSubmitRequested,
  showValidationErrorsDialog
} from "../../actions";
import { AntwoordBlokken } from "../../models/api";
import { ProcesstapState } from "../../models/application";
import { commitBlokData, onNavigate, submitMelding } from "../../thunks";
import { Processtap, ProcesstapType } from "./../../models/application";
import { State } from "./../../store";
import { Formulier } from "./formulier.component";
import { FormulierDispatchProps, FormulierOwnProps, FormulierStateProps } from "./formulier.component.interfaces";

const mapStateToProps = (state: State): FormulierStateProps => ({
  blokken: state.antwoorden.blokken,
  isInitialized: state.antwoorden.isInitialized,
  isSubmitting: state.antwoorden.isSubmitting,
  processtappen: state.proces.items,
  hasNextButton: state.proces.items.findIndex((i) => i.state === ProcesstapState.active) + 1 < state.proces.items.length,
  hasPreviousButton: state.proces.items.findIndex((i) => i.state === ProcesstapState.active) > 0,
  hasVerzendenButton:
    state.proces.items.findIndex((i) => i.state === ProcesstapState.active) === state.proces.items.length - 1 &&
    state.proces.items.some((s) => s.type !== ProcesstapType.samenvatting),
  isFormulierInitialized: state.vragen.formulier !== undefined,
  formulier: state.vragen.formulier,
  submitValidationResults: state.proces.submitValidationResults,
  activeProcesstapType: state.proces.activeItem?.type,
  activeProcesstapHasValidationErrors: state.proces.activeItem?.hasValidationErrors,
  activeProcesstapFirstElementWithErrorId: state.proces.activeItem?.firstElementWithErrorId,
  isNavigating: state.proces.requestedItem?.item != null,
  meldingnummer: state.antwoorden.meldingId ? state.melding?.meldingnummer : undefined,
  meldingId: state.antwoorden.meldingId,
  reinitializeForm: state.antwoorden.reinitializeForm,
  user: state.security.user,
  autorisatiegegevens: state.autorisatie.autorisatiegegevens,
  meldingStatus: state.melding?.status,
  wordtMeldingGewijzigd: state.melding?.wordtGewijzigd,
  captchaRequired: state.vragen.formulier?.captchaRequired ?? false,
  captchaHasError: state.captcha.hasError,
  isBackwardsNavigationRequested: state.proces.isBackwardsNavigationRequested,
  isValidationErrorsDialogShown: state.proces.showValidationErrorsDialog,
  isInterneMedewerker: userHelpers.getIsInternalUser(state.security.user)
});

const mapDispatchToProps = (dispatch: any, ownProps: FormulierOwnProps): FormulierDispatchProps => ({
  onPrevious: () => {
    dispatch(setPreviousPage());
  },
  onNext: () => {
    dispatch(setNextPage());
  },
  onNavigate: (values: AntwoordBlokken, hasValidationErrors: boolean, firstElementWithErrorId?: string | null) => {
    dispatch(setHasValidationErrors(hasValidationErrors, firstElementWithErrorId));
    dispatch(onNavigate(values));
  },
  onSubmitRequested: () => {
    dispatch(setSubmitRequested(true));
  },
  onCancelSubmitRequested: () => {
    dispatch(setSubmitRequested(false));
  },
  onSubmit: (values: AntwoordBlokken) => {
    dispatch(commitBlokData());
    dispatch(saveAntwoorden(values));
    if (ownProps.submitMelding) {
      ownProps.submitMelding();
    } else {
      dispatch(
        submitMelding(
          ownProps.type,
          ownProps.createMelding,
          ownProps.getEmailadres,
          ownProps.beforeSubmitCompleted,
          ownProps.afterSubmitCompleted
        )
      );
    }
  },
  setRequestedPage: (item: Processtap) => {
    dispatch(setRequestedPage(item));
  },
  reset: () => {
    dispatch(resetFormulier());
  },
  onFormReinitializationComplete: () => {
    dispatch(formReinitializationComplete());
  },
  setCaptchaError: () => {
    dispatch(setCaptchaError());
  },
  clearCaptchaError: () => {
    dispatch(clearCaptchaError());
  },
  setIsNavigatingWithValidationErrors: (showDialog, firstElementWithErrorId, values) => {
    if (values) {
      dispatch(saveAntwoorden(values));
    }
    dispatch(setHasValidationErrors(true, firstElementWithErrorId));
    if (showDialog) {
      dispatch(showValidationErrorsDialog());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Formulier);
