import { connect } from "react-redux";
import { setAutorisatiesFilter } from "../../../actions";
import { AutorisatiesSortOption } from "../../../models/api";
import { State } from "../../../store";
import { submitAutorisatiesZoekenFormulier } from "../../../thunks";
import { AutorisatiesZoeken } from "./autorisaties-zoeken.component";
import { AutorisatiesZoekenDispatchProps, AutorisatiesZoekenStateProps } from "./autorisaties-zoeken.component.interfaces";

const mapStateToProps = (state: State): AutorisatiesZoekenStateProps => ({
  user: state.security.user,
  autorisaties: state.beheer.autorisaties,
  totaalAantalAutorisaties: state.beheer.totaalAantalAutorisaties,
  isLoading: state.beheer.isLoading,
  hasApiError: state.beheer.hasApiError,
  sortOption: state.beheer.sortOption,
  pageNumber: state.beheer.pageNumber,
  pageSize: state.beheer.pageSize,
  hasSearched: state.beheer.zoekFilter !== null
});

const mapDispatchToProps = (dispatch: any): AutorisatiesZoekenDispatchProps => ({
  onSearchOptionChange: (sortOption: AutorisatiesSortOption, pageNumber: number) => {
    dispatch(setAutorisatiesFilter(sortOption, pageNumber));
    dispatch(submitAutorisatiesZoekenFormulier());
  },
  onSubmit: async () => {
    dispatch(submitAutorisatiesZoekenFormulier());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AutorisatiesZoeken);
