export const SET_USER_IDLE = "SET_USER_IDLE";
export const SET_USER_ACTIVE = "SET_USER_ACTIVE";

interface SetUserIdleAction {
  type: typeof SET_USER_IDLE;
}

interface SetUserActiveAction {
  type: typeof SET_USER_ACTIVE;
}

export type UserActivityActions = SetUserIdleAction | SetUserActiveAction;

export const setUserIdle = (): UserActivityActions => {
  return {
    type: SET_USER_IDLE
  };
};

export const setUserActive = (): UserActivityActions => {
  return {
    type: SET_USER_ACTIVE
  };
};
