import { Antwoord } from "../antwoord";
import { User } from "../security/user";
import { AntwoordBlok } from "./antwoord-blok";
import { MelderAntwoorden } from "./melder-antwoorden";

export class VluchtigeOrganischeStoffenMelderAntwoorden extends AntwoordBlok {
  constructor(public ontheffingVoorSpecifiekGeval: Antwoord<string>, public common: MelderAntwoorden | null) {
    super();
  }

  static initialize(user: User | null = null): VluchtigeOrganischeStoffenMelderAntwoorden {
    return new VluchtigeOrganischeStoffenMelderAntwoorden(new Antwoord<string>(""), MelderAntwoorden.initialize(user));
  }

  static fromJson(json: any): VluchtigeOrganischeStoffenMelderAntwoorden {
    return new VluchtigeOrganischeStoffenMelderAntwoorden(
      Antwoord.fromJson<string>(json.ontheffingVoorSpecifiekGeval),
      MelderAntwoorden.fromJson(json.common)
    );
  }
}
