import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessageControl } from "components/error";
import { VisibleWrapper } from "components/visible-wrapper";
import { useField, useFormikContext } from "formik";
import { Bestand } from "models/api";
import { Fragment, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { translationKeys } from "../../../constants/translation-keys";
import { MeldingFile, MeldingFileState } from "../../../models/application";
import { InputFileImportProps } from "./input-file-import.interface";

const ImportButton = (props: { isImporting: boolean; onClick: () => void; file?: MeldingFile }) => {
  const { isImporting, file, onClick } = props;
  const isDisabled = file == null || isImporting;
  const { t } = useTranslation();

  return (
    <Button
      aria-disabled={isDisabled}
      disabled={isDisabled}
      className="btn btn-primary mb-3 float-end"
      id="file-import"
      onClick={onClick}
    >
      {t(translationKeys.importeren)} {isImporting && <FontAwesomeIcon icon={faSpinner} spin data-test="import-loader" />}
    </Button>
  );
};

export const InputFileImport = (props: InputFileImportProps) => {
  const [file, setFile] = useState<MeldingFile | undefined>(undefined);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const { values, validateField, validateForm, setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const [, meta, fieldHelpers] = useField(props);
  const { error, touched } = meta;

  const hasError = props.errors && props.errors.length > 0;
  const showValidationError = !hasError && ((file && touched) || (!file && error));
  const hasResult = props.result != null;

  const updateFieldValue = (addedFile: File | MeldingFile) => {
    setFieldValue(props.name, [new Bestand(addedFile.name, addedFile.size, (addedFile as MeldingFile).id || null)]);
  };

  const addFiles = (addedFiles: File[]) => {
    if (addedFiles.length === 1) {
      let f = addedFiles[0];
      const meldingFile = new MeldingFile(MeldingFileState.Added, f.name, f.size, f, null);
      setFile(meldingFile);
      props.onAddFile(f);

      updateFieldValue(addedFiles[0]);
      validateField(props.name);
    }
  };

  return (
    <div>
      <VisibleWrapper isVisible={!hasResult}>
        <Dropzone multiple={false} onDrop={(acceptedFiles) => addFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()} className={isDragActive ? "files-drag-area drag-active" : "files-drag-area"}>
              <Row>
                <Col className="text-center">
                  <div className="explanation py-2">{t(isDragActive ? "importBestandLoslaten" : "importBestandSlepen")}</div>
                  <input {...getInputProps()} name="test" type="file" accept=".xlsx" />
                  <div className="btn btn-primary" id="files-upload-button">
                    {t(translationKeys.bestandSelecteren)}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Dropzone>
        <div>
          {file && (
            <Row key={file.name} className="file-list-row">
              <Col className="ms-3 file-name-col" key={`${file.name}_1`}>
                <div className="file-name" title={file.name}>
                  <FontAwesomeIcon icon={faFileAlt} size="1x" />
                  <span className="ms-2">{file.name}</span>
                </div>
              </Col>
              <Col className="ms-1 me-3 col-auto" key={`${file.name}_2`}>
                <button
                  className="remove-file-button"
                  disabled={hasResult}
                  title={t(translationKeys.bestandVerwijderen, { naam: file.name })}
                  onClick={() => {
                    setFile(undefined);
                    props.onRemoveFile();
                    setFieldValue(props.name, null, true);
                    fieldHelpers.setTouched(false);
                  }}
                >
                  <FontAwesomeIcon className="remove-file" icon={faTimes} size="1x" />
                </button>
              </Col>
            </Row>
          )}
        </div>

        <ImportButton
          isImporting={isImporting}
          file={file}
          onClick={() => {
            if (file && file.file) {
              setIsImporting(true);
              props.onImportFile(file, values, t).then(() => {
                setIsImporting(false);
                validateForm();
              });
            }
          }}
        />

        <div className="clearfix" />
      </VisibleWrapper>

      <VisibleWrapper isVisible={hasError}>
        <ErrorMessageControl name="import-errors" className="mt-3" scrollIntoViewCondition={() => !!hasError}>
          <p>{t(translationKeys.importeerFouten)}</p>
          {props.errors.map((err, idx) => (
            <Fragment key={`import-error-${idx}`}>
              {err.message}
              <br />
            </Fragment>
          ))}
        </ErrorMessageControl>
      </VisibleWrapper>
      {props.result && (
        <Alert variant="info" className="wrap-lines">
          <ReactMarkdown children={props.result} />
        </Alert>
      )}

      {showValidationError && <ErrorMessageControl name={props.name}>{error}</ErrorMessageControl>}
    </div>
  );
};
