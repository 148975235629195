import { Fragment } from "react";
import { FormulierBreadcrumbsProps } from "./formulier-breadcrumbs.component.interface";
import { translationKeys } from "../../../constants/translation-keys";
import { Breadcrumb } from "../breadcrumb";
import { useTranslation } from "react-i18next";

export const FormulierBreadcrumbs = (props: FormulierBreadcrumbsProps) => {
  const { t, i18n } = useTranslation();

  const formulierInformatieUrls = translationKeys.breadcrumbs.inspectieSzw.melden.formulierInformatie.url as any;

  return (
    <Fragment>
      <Breadcrumb
        text={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
        url={t(translationKeys.breadcrumbs.inspectieSzw.melden.url)}
        titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.titel)}
      />
      {!props.isMijnMelding && formulierInformatieUrls[props.formulierType] && (
        <Breadcrumb
          text={t(translationKeys.breadcrumbs.inspectieSzw.melden.formulierInformatie.titel, {
            formulierNaam: props.formulierTitel[i18n.language]
          })}
          url={t(formulierInformatieUrls[props.formulierType])}
        />
      )}
      {props.isMijnMelding && (
        <Breadcrumb
          text={t(translationKeys.breadcrumbs.inspectieSzw.melden.mijnMeldingen.titel)}
          url={t(translationKeys.breadcrumbs.inspectieSzw.melden.mijnMeldingen.url)}
          titel={t(translationKeys.breadcrumbs.inspectieSzw.melden.mijnMeldingen.titel)}
        />
      )}
      <Breadcrumb
        text={props.formulierTitel[i18n.language]}
        url={window.location.pathname}
        titel={`${t(translationKeys.meldformulier)} ${props.formulierTitel[i18n.language]}`}
      />
    </Fragment>
  );
};
