import i18n from "i18next";
import { Configuration } from "../configuration";
import { LandenSearchResponse } from "../models/api/landen-search-response";
import { get } from "./api";

const getLanden = (): Promise<LandenSearchResponse> => {
  return get({
    uri: `${Configuration.apiUrl}/stamgegevens/landen?lng=${i18n.language}`,
    success: (r) => LandenSearchResponse.fromJson(r)
  });
};

const searchLanden = (naam: string, includeNederland: boolean): Promise<LandenSearchResponse> => {
  return get({
    uri: `${Configuration.apiUrl}/stamgegevens/landen?naam=${naam}&includeNederland=${includeNederland}&lng=${i18n.language}`,
    success: (r) => LandenSearchResponse.fromJson(r)
  });
};

export const landenApi = {
  searchLanden,
  getLanden
};
