import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { displayAntwoordHelpers, vraagBlokCollectionHelpers } from "../../../helpers";
import { AntwoordBlokken } from "../../../models/api";
import { State } from "../../../store";
import { SamenvattingFieldOwnProps } from "../samenvatting-field/samenvatting-field.component.interfaces";
import { withConditioneelGesteldeSamenvattingItem } from "../with-conditioneel-gestelde-samenvatting-item";
import { withSamenvattingVraag } from "../with-samenvatting-vraag";
import { WithSamenvattingCollectionTypeaheadVraagProps } from "./with-samenvatting-collection-typeahead-vraag.interfaces";

export const withSamenvattingCollectionTypeaheadVraag = <P extends object>(
  Component: React.ComponentType<P & SamenvattingFieldOwnProps>
) => {
  return (props: P & WithSamenvattingCollectionTypeaheadVraagProps) => {
    const SamenvattingVraagComponent = withConditioneelGesteldeSamenvattingItem(withSamenvattingVraag(Component));

    const { antwoordBlokken } = useSelector<
      State,
      {
        antwoordBlokken: AntwoordBlokken;
      }
    >(
      (state: State) => ({
        antwoordBlokken: state.antwoorden.blokken
      }),
      shallowEqual
    );

    return (
      <SamenvattingVraagComponent
        {...props}
        item={props.vraag}
        getAntwoord={(antwoordWaarde: string, _language) => {
          const item = vraagBlokCollectionHelpers.getAntwoordBlokCollectionItemById(
            antwoordWaarde,
            props.vraag.vraagBlokCollectionProperty,
            antwoordBlokken
          );

          return item ? displayAntwoordHelpers.getDisplayAntwoord(item) : "";
        }}
      />
    );
  };
};
