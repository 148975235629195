import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Configuration } from "../../../configuration";
import { translationKeys } from "../../../constants/translation-keys";
import { userHelpers } from "../../../helpers";
import { VisibleWrapper } from "../../visible-wrapper";
import { SecurityMenuProps } from "./security-menu.component.interfaces";

export const SecurityMenu: React.FC<SecurityMenuProps> = (props) => {
  const { t } = useTranslation();

  const hasUser = props.user !== null;

  return (
    <div className="aligner">
      <Fragment>
        <VisibleWrapper isVisible={hasUser}>
          <span>
            {t(translationKeys.auth.ingelogdAls, {
              naam: userHelpers.getVolledigeNaam(props.user) ?? ""
            })}
          </span>
          {Configuration.loginEnabled && (
            <Button id="uitloggenButton" variant="link" onClick={props.onSignOut}>
              {t(translationKeys.auth.uitloggen)}
            </Button>
          )}
        </VisibleWrapper>
        <VisibleWrapper isVisible={Configuration.loginEnabled && !hasUser}>
          <Button id="inloggenButton" variant="link" onClick={() => props.onInloggen()}>
            {t(translationKeys.auth.inloggen)}
          </Button>
        </VisibleWrapper>
      </Fragment>
    </div>
  );
};
