import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";

export class OnderbetalingAntwoorden extends AntwoordBlok {
  constructor(
    public wieWordtOnderbetaald: Antwoord<string>,
    public voorletters: Antwoord<string>,
    public tussenvoegsels: Antwoord<string>,
    public achternaam: Antwoord<string>,
    public geslacht: Antwoord<string>,
    public geboorteDatum: Antwoord<string>,
    public bsn: Antwoord<string>,
    public datumInDienst: Antwoord<string>,
    public welkeLoonperiode: Antwoord<string>,
    public hoeLoonBetaald: Antwoord<string>,
    public overigeBetaalwijzeLoon: Antwoord<string>,
    public hoeveelLoon: Antwoord<string>,
    public brutoNetto: Antwoord<string>,
    public hoeVaakUitbetaald: Antwoord<string>,
    public alleGewerkteUrenUitbetaald: Antwoord<string>,
    public waaromNiet: Antwoord<string>,
    public hoeVakantiebijslagBetaald: Antwoord<string>,
    public wordenBedragenIngehouden: Antwoord<string>,
    public welkBedragIngehouden: Antwoord<string>,
    public waarvoorIngehoudenOpLoon: Antwoord<string>,
    public toestemmingVoorInhouding: Antwoord<string>,
    public loonTerugbetalen: Antwoord<string>,
    public hoeveelTerugbetalen: Antwoord<string>,
    public waaromTerugbetalen: Antwoord<string>,
    public hoeTerugbetalen: Antwoord<string>,
    public overigeBetaalwijzeTerugbetalen: Antwoord<string>,
    public aanWieTerugbetalen: Antwoord<string>,
    public watAlsNietTerugbetaalt: Antwoord<string>,
    public aanvullendeInformatie: Antwoord<string>,
    public onderbetalingOpTeSturenDocumenten: Antwoord<string[]>,
    public toestemmingDoorzettenNaarSncu: Antwoord<string>
  ) {
    super();
  }

  static initialize(): OnderbetalingAntwoorden {
    return new OnderbetalingAntwoorden(
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string>(""),
      new Antwoord<string[]>([]),
      new Antwoord<string>("")
    );
  }

  static fromJson(json: any): OnderbetalingAntwoorden {
    return json
      ? new OnderbetalingAntwoorden(
          Antwoord.fromJson<string>(json.wieWordtOnderbetaald),
          Antwoord.fromJson<string>(json.voorletters),
          Antwoord.fromJson<string>(json.tussenvoegsels),
          Antwoord.fromJson<string>(json.achternaam),
          Antwoord.fromJson<string>(json.geslacht),
          Antwoord.fromJson<string>(json.geboorteDatum),
          Antwoord.fromJson<string>(json.bsn),
          Antwoord.fromJson<string>(json.datumInDienst),
          Antwoord.fromJson<string>(json.welkeLoonperiode),
          Antwoord.fromJson<string>(json.hoeLoonBetaald),
          Antwoord.fromJson<string>(json.overigeBetaalwijzeLoon),
          Antwoord.fromJson<string>(json.hoeveelLoon),
          Antwoord.fromJson<string>(json.brutoNetto),
          Antwoord.fromJson<string>(json.hoeVaakUitbetaald),
          Antwoord.fromJson<string>(json.alleGewerkteUrenUitbetaald),
          Antwoord.fromJson<string>(json.waaromNiet),
          Antwoord.fromJson<string>(json.hoeVakantiebijslagBetaald),
          Antwoord.fromJson<string>(json.wordenBedragenIngehouden),
          Antwoord.fromJson<string>(json.welkBedragIngehouden),
          Antwoord.fromJson<string>(json.waarvoorIngehoudenOpLoon),
          Antwoord.fromJson<string>(json.toestemmingVoorInhouding),
          Antwoord.fromJson<string>(json.loonTerugbetalen),
          Antwoord.fromJson<string>(json.hoeveelTerugbetalen),
          Antwoord.fromJson<string>(json.waaromTerugbetalen),
          Antwoord.fromJson<string>(json.hoeTerugbetalen),
          Antwoord.fromJson<string>(json.overigeBetaalwijzeTerugbetalen),
          Antwoord.fromJson<string>(json.aanWieTerugbetalen),
          Antwoord.fromJson<string>(json.watAlsNietTerugbetaalt),
          Antwoord.fromJson<string>(json.aanvullendeInformatie),
          Antwoord.fromJson<string[]>(json.onderbetalingOpTeSturenDocumenten),
          Antwoord.fromJson<string>(json.toestemmingDoorzettenNaarSncu)
        )
      : this.initialize();
  }
}
