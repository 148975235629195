import { useRef, useEffect } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { SuccessMessageProps } from "./success-message.component.interfaces";

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

export const SuccessMessage = (props: SuccessMessageProps) => {
  const messageControlRef = useRef(null);

  useEffect(() => {
    if (props.scrollIntoViewCondition && props.scrollIntoViewCondition() && messageControlRef) {
      scrollToRef(messageControlRef);
    }
  });

  return props.children ? (
    <Alert variant="success" className="success-message" ref={messageControlRef} id={props.id}>
      <Row>
        <Col className="icon">
          <FontAwesomeIcon icon={faCheckSquare} className="success-icon" />
        </Col>
        <Col>{props.children}</Col>
      </Row>
    </Alert>
  ) : null;
};
