import { Bestand } from "../blok/bestand";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class HasInvalidFileNames extends VraagExpression {
  get operator(): string {
    return HasInvalidFileNames.operator();
  }

  constructor(public readonly vraagProperty: string, private readonly format: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const antwoord = context.getAntwoord(this.vraagProperty ?? vraagProperty)?.waarde;

    const rexExp = new RegExp(this.format);

    return antwoord && (antwoord as Bestand[]).some((x) => !rexExp.test(x.naam));
  };

  protected clone = (instance: HasInvalidFileNames): HasInvalidFileNames =>
    new HasInvalidFileNames(instance.vraagProperty, instance.format);

  static fromJson = (json: any): HasInvalidFileNames => new HasInvalidFileNames(json.vraag, json.format);

  static operator = (): string => "hasInvalidFileNames";

  static matches = (json: any): boolean => json?.operator === HasInvalidFileNames.operator();
}
