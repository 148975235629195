import { Configuration } from "configuration";
import { GetDataFromSpreadsheetResponse } from "models/api/import";
import { MeldingFile } from "models/application";
import { xhrPost } from "./xhr";

const importFileOntheffingKinderarbeid = (file: MeldingFile): Promise<GetDataFromSpreadsheetResponse> => {
  const formData = new FormData();
  formData.append("file", file.file as File, file.name);

  return xhrPost({
    uri: `${Configuration.apiUrl}/melding/ontheffingkinderarbeid/actions/import`,
    data: formData,
    success: (res) => {
      return JSON.parse(res);
    }
  });
};

export const importApi = {
  importFileOntheffingKinderarbeid
};
