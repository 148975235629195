import { BlokDefinitie, ProcesstapType } from "../models/application";
import { WerkbakFormulier } from "./../models/api/formulier";

export const getWerkbakFormulierDefinitie = (formulier: WerkbakFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.melder.titel,
    type: ProcesstapType.melder
  },
  {
    title: formulier.blokken.locatie.titelVerkort,
    type: ProcesstapType.locatie
  },
  {
    title: formulier.blokken.periode.titel,
    type: ProcesstapType.periode
  },
  {
    title: formulier.blokken.werkzaamheden.titel,
    type: ProcesstapType.werkzaamheden
  }
];
