export const RESET_FORM = "RESET_FORM";
export const SET_STATE = "SET_STATE";
export const SET_SUBMITTING_COMPLETE = "SET_SUBMITTING_COMPLETE";

interface ResetFormulierAction {
  type: typeof RESET_FORM;
}

interface SetStateAction {
  type: typeof SET_STATE;
  state: any;
}

interface SetSubmittingCompleteAction {
  type: typeof SET_SUBMITTING_COMPLETE;
  isSuccessful: boolean;
}

export type SharedActions = ResetFormulierAction | SetStateAction | SetSubmittingCompleteAction;

export const resetFormulier = (): SharedActions => {
  return {
    type: RESET_FORM
  };
};

export const setSubmittingComplete = (isSuccessful: boolean): SharedActions => {
  return {
    type: SET_SUBMITTING_COMPLETE,
    isSuccessful: isSuccessful
  };
};
