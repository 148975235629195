import { BrpSearchResult } from "./../store/brp-search-result";

export const SET_BRP_SEARCH_RESULT = "SET_BRP_SEARCH_RESULT";
export const SET_BRP_SEARCH_BUSY = "SET_BRP_SEARCH_BUSY";

interface SetBrpSearchResult {
  type: typeof SET_BRP_SEARCH_RESULT;
  key: string;
  result: BrpSearchResult;
}

interface SetBrpSearchBusy {
  type: typeof SET_BRP_SEARCH_BUSY;
  key: string;
  busy: boolean;
  error: boolean;
}

export type BrpActions = SetBrpSearchResult | SetBrpSearchBusy;

export const setBrpSearchResult = (key: string, result: BrpSearchResult): BrpActions => ({
  type: SET_BRP_SEARCH_RESULT,
  key,
  result
});

export const setBrpSearchStarted = (key: string): BrpActions => ({
  type: SET_BRP_SEARCH_BUSY,
  key,
  busy: true,
  error: false
});

export const setBrpSearchFinished = (key: string, error = false): BrpActions => ({
  type: SET_BRP_SEARCH_BUSY,
  key,
  busy: false,
  error: error
});
