import { connect } from "react-redux";
import { AutorisatieProfielFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import { getAutorisatieProfielMelding } from "../../../thunks";
import { AutorisatieProfielFormulierComponent } from "./autorisatie-profiel-formulier.component";
import {
  AutorisatieProfielFormulierComponentDispatchProps,
  AutorisatieProfielFormulierComponentOwnProps,
  AutorisatieProfielFormulierComponentStateProps
} from "./autorisatie-profiel-formulier.component.interfaces";

const mapStateToProps = (state: State): AutorisatieProfielFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.autorisatieProfiel
      ? (state.vragen.formulier as AutorisatieProfielFormulier)
      : undefined;

  const isExternalLogin = !!state.autorisatie.autorisatiegegevens.isExternalLogin;

  return {
    formulier,
    antwoordenFormuliertype: state.antwoorden.formuliertype,
    isExternalLogin: isExternalLogin
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: AutorisatieProfielFormulierComponentOwnProps
): AutorisatieProfielFormulierComponentDispatchProps => ({
  afterCompleted: async (profielId: string) => {
    // Profiel updated
    await dispatch(getAutorisatieProfielMelding(profielId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AutorisatieProfielFormulierComponent);
