export class GebruikerMetadata {
  constructor(
    public voorletters: string,
    public tussenvoegsel: string,
    public achternaam: string,
    public volledigeNaam: string,
    public gebruikersnaam: string
  ) {}

  static fromJson(json: any): GebruikerMetadata {
    return new GebruikerMetadata(
      json.voorletters,
      json.tussenvoegsel,
      json.achternaam,
      json.volledigeNaam,
      json.gebruikersnaam
    );
  }
}
