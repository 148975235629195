export enum VraagType {
  berekend = "berekend",
  unknown = "unknown",
  gelinkteMeerkeuze = "gelinkteMeerkeuze",
  gelinkteMeerkeuzeMeerdereAntwoordmogelijkheden = "gelinkteMeerkeuzeMeerdereAntwoordmogelijkheden",
  meerkeuzeEenAntwoordmogelijkheid = "meerkeuzeEenAntwoordmogelijkheid",
  meerkeuzeMeerdereAntwoordmogelijkheden = "meerkeuzeMeerdereAntwoordmogelijkheden",
  tekst = "tekst",
  tekstMeerdereRegels = "tekstMeerdereRegels",
  datum = "datum",
  tijd = "tijd",
  numeriek = "numeriek",
  gebrokenAantal = "gebrokenAantal",
  land = "land",
  buitenland = "buitenland",
  plaats = "plaats",
  bestanden = "bestanden",
  collectionTypeahead = "collectionTypeahead",
  datumTijd = "datumTijd",
  samengesteldeVraag = "samengesteldeVraag",
  bestandImport = "bestandImport"
}
