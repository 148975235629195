import { connect } from "react-redux";
import { OngewoneVoorvallenFormulier } from "../../../models/api";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getOngewoneVoorvallenMelding,
  initOngewoneVoorvallenFormulier,
  initOngewoneVoorvallenFormulierFromCopy
} from "../../../thunks";
import { OngewoneVoorvallenFormulierComponent } from "./ongewone-voorvallen-formulier.component";
import {
  OngewoneVoorvallenFormulierComponentDispatchProps,
  OngewoneVoorvallenFormulierComponentStateProps
} from "./ongewone-voorvallen-formulier.component.interfaces";

const mapStateToProps = (state: State): OngewoneVoorvallenFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.ongewoneVoorvallen
      ? (state.vragen.formulier as OngewoneVoorvallenFormulier)
      : undefined;
  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): OngewoneVoorvallenFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initOngewoneVoorvallenFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getOngewoneVoorvallenMelding(meldingId));
    } else {
      dispatch(initOngewoneVoorvallenFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OngewoneVoorvallenFormulierComponent);
