import moment from "moment";
import { dateHelpers } from "../../../helpers";
import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { Expression } from "./expression";

export class IsDateTimeInPast extends Expression {
  get operator(): string {
    return IsDateTimeInPast.operator();
  }

  private readonly DEFAULT_TIME_ANTWOORD_WAARDE = "00:00";

  constructor(
    private readonly vraagDatumProperty: string,
    private readonly vraagTijdProperty: string,
    private readonly offsetFromNowInMinutes: number
  ) {
    super();
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext): boolean => {
    let result = false;

    const datum = context.getAntwoord(this.vraagDatumProperty)?.waarde;
    const tijd = this.vraagTijdProperty
      ? context.getAntwoord(this.vraagTijdProperty)?.waarde
      : this.DEFAULT_TIME_ANTWOORD_WAARDE;

    if (datum && tijd && dateHelpers.isValidDateTime(`${datum} ${tijd}`)) {
      const datumEnTijd = dateHelpers.getMomentFromDateTimeString(`${datum} ${tijd}`);
      const nowWithOffset = moment().add(this.offsetFromNowInMinutes, "minute");

      result = datumEnTijd < nowWithOffset;
    }

    return result;
  };

  static fromJson = (json: any): IsDateTimeInPast =>
    new IsDateTimeInPast(json.vraagDatum, json.vraagTijd, json.offsetFromNowInMinutes);

  static operator = (): string => "isDateTimeInPast";

  static matches = (json: any): boolean => json?.operator === IsDateTimeInPast.operator();
}
