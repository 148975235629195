import { objectHelpers } from "../helpers";
import { AntwoordBlokCollection, AntwoordBlokCollectionItem, AntwoordBlokken } from "../models/api";

const getAntwoordBlokCollectionItemById = (
  id: string,
  vraagBlokCollectionProperty: string,
  antwoordBlokken: AntwoordBlokken
): AntwoordBlokCollectionItem | undefined => {
  const collection = getAntwoordBlokCollection(vraagBlokCollectionProperty, antwoordBlokken);

  return collection?.list?.find((item) => item.id === id);
};

const getAntwoordBlokCollectionItemByIndex = (
  index: number,
  vraagBlokCollectionProperty: string,
  antwoordBlokken: AntwoordBlokken
): AntwoordBlokCollectionItem | undefined => {
  const collection = getAntwoordBlokCollection(vraagBlokCollectionProperty, antwoordBlokken);

  if (collection?.list && collection.list.length > index) {
    return collection.list[index];
  }
};

const getAntwoordBlokCollection = (
  vraagBlokCollectionProperty: string,
  antwoordBlokken: AntwoordBlokken
): AntwoordBlokCollection<any> | null => {
  return objectHelpers.getValue<AntwoordBlokCollection<any>>(antwoordBlokken, vraagBlokCollectionProperty);
};

export const vraagBlokCollectionHelpers = {
  getAntwoordBlokCollectionItemById,
  getAntwoordBlokCollectionItemByIndex,
  getAntwoordBlokCollection
};
