import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";
import { MelderVragen } from "./melder-vragen";

export class CbiMelderVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public common: MelderVragen,
    public aanvraagTitel: Tekst,
    public werkveld: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): CbiMelderVragen {
    return new CbiMelderVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      MelderVragen.fromJson(`${key}.common`, json.common),
      jsonHelpers.toTitel(`${key}.aanvraagTitel`, json.aanvraagTitel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.werkveld`, json.werkveld)
    );
  }
}
