import { Fragment } from "react";
import { Help } from "../../help";

export const Title = (props: any) => {
  return (
    <Help.Container key={`${props.id}--help`}>
      <h2 id={props.id}>
        {props.text}
        {props.toelichting && (
          <Fragment>
            <Help.HelpToggle
              className="help-toggle toelichting"
              key={`${props.id}--help-toggle`}
              id={`${props.id}-help-toggle`}
              aria-label={props.helpToggleLabel}
            ></Help.HelpToggle>
          </Fragment>
        )}
      </h2>
      {props.toelichting && (
        <Help.HelpText key={`${props.id}--help-text`} helptekst={props.toelichting} id={`${props.id}-help`}></Help.HelpText>
      )}
    </Help.Container>
  );
};
