import { connect } from "react-redux";
import { Formuliertype } from "../../../models/application";
import { State } from "../../../store";
import {
  getAvvArtikel10AVerzoekformulierMelding,
  initAvvArtikel10AVerzoekFormulier,
  initAvvArtikel10AVerzoekFormulierFromCopy
} from "../../../thunks";
import {
  AvvArtikel10AVerzoekFormulierComponentDispatchProps,
  AvvArtikel10AVerzoekFormulierComponentStateProps
} from "./avv-artikel10a-verzoek-formulier.component.interfaces";
import { AvvArtikel10AVerzoekFormulier } from "../../../models/api";
import { AvvArtikel10AVerzoekFormulierComponent } from "./avv-artikel10a-verzoek-formulier.component";

const mapStateToProps = (state: State): AvvArtikel10AVerzoekFormulierComponentStateProps => {
  const formulier =
    state.vragen.formulier?.type === Formuliertype.avvArtikel10AVerzoek
      ? (state.vragen.formulier as AvvArtikel10AVerzoekFormulier)
      : undefined;

  return {
    formulier,
    antwoordenMeldingId: state.antwoorden.meldingId,
    antwoordenFormuliertype: state.antwoorden.formuliertype
  };
};

const mapDispatchToProps = (dispatch: any): AvvArtikel10AVerzoekFormulierComponentDispatchProps => ({
  onInit: (meldingId: string | undefined, isKopie = false) => {
    if (meldingId && isKopie) {
      dispatch(initAvvArtikel10AVerzoekFormulierFromCopy(meldingId));
    } else if (meldingId) {
      dispatch(getAvvArtikel10AVerzoekformulierMelding(meldingId));
    } else {
      dispatch(initAvvArtikel10AVerzoekFormulier());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AvvArtikel10AVerzoekFormulierComponent);
