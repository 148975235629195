import { landHelpers } from "helpers";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../../constants";
import { Vestiging } from "../../../../models/api";
import { Pager } from "../../../grid/pager";
import { Spinner } from "../../../spinner";
import { VestigingFormResultsProps } from "./vestiging-form-results.component.interfaces";
export const VestigingFormResults = (props: VestigingFormResultsProps) => {
  const { t } = useTranslation();

  const pageChange = (targetPageNumber: number) => {
    props.onSearchOptionChange(targetPageNumber);
  };

  const determineZoekResultatenMessage = () => {
    let resultatenString;
    if (props.totaalAantalVestigingen > 1) {
      resultatenString = t(translationKeys.vestigingenSearch.zoekresultaten.meerdere, {
        aantalResultaten: props.totaalAantalVestigingen
      });
    } else if (props.totaalAantalVestigingen === 1) {
      resultatenString = t(translationKeys.vestigingenSearch.zoekresultaten.een, {
        aantalResultaten: props.totaalAantalVestigingen
      });
    } else {
      resultatenString = t(translationKeys.vestigingenSearch.zoekresultaten.geen, {
        aantalResultaten: props.totaalAantalVestigingen
      });
    }
    return resultatenString;
  };

  const zoekResultatenString = determineZoekResultatenMessage();

  return (
    <section>
      <div className="lijst-container">
        <Container fluid className="lijst">
          <Row className="grid-container-header">
            <Col>{zoekResultatenString}</Col>
            {props.isLoading && <Spinner>{t(translationKeys.bezigMetLaden)}</Spinner>}
          </Row>

          {props.vestigingen?.map((v: Vestiging) => (
            <Row key={`${props.name}-${v.ves_id}`} className={props.isLoading ? "grid-loading" : undefined}>
              <Col xs={12} md={9}>
                <div className="grid-row-header">{v.ves_naam}</div>
                <div>
                  KVK {v.ves_kvknr ?? "geen" } Vestigingsnr. {v.ves_vest_nr ?? "geen"}
                </div>
                <div>
                  {v.ves_straat ?? ""} {v.ves_huisnr ?? ""}
                  {v.ves_toev_huisnr ?? ""} {v.ves_postcode ?? ""} {v.ves_plaats ?? ""}{" "}
                  {landHelpers.getLandNaam(props.landen, v.LAN_NAAM)}
                </div>
              </Col>
              <Col xs={12} md={3}>
                <Button onClick={() => props.onSelect(v)} disabled={props.isLoading} className="float-md-right">
                  {t(translationKeys.vestigingenSearch.selecteer)}
                  <span className="visually-hidden">
                    {t(translationKeys.vestigingenSearch.selecteerScreenReaderAanvulling, {
                      vestigingNaam: v.ves_naam,
                      kvknr: v.ves_kvknr,
                      vestigingNr: v.ves_vest_nr
                    })}
                  </span>
                </Button>
              </Col>
            </Row>
          ))}
        </Container>
        <Pager
          pageCount={Math.ceil(props.totaalAantalVestigingen / props.pageSize)}
          currentPage={props.pageNumber}
          onPageChange={pageChange}
        ></Pager>
      </div>
    </section>
  );
};
