import { DouaneFormulier } from "models/api";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getDouaneFormulierDefinitie = (formulier: DouaneFormulier): BlokDefinitie[] => {
  return [
    {
      title: formulier.blokken.melder.titel,
      type: ProcesstapType.melder
    },
    {
      title: formulier.blokken.melding.titelVerkort,
      type: ProcesstapType.melding
    },
    {
      title: formulier.blokken.locatieProduct.titel,
      type: ProcesstapType.locatieProduct
    },
    {
      title: formulier.blokken.aangever.titel,
      type: ProcesstapType.aangever
    },
    {
      title: formulier.blokken.geadresseerde.titel,
      type: ProcesstapType.geadresseerde
    },
    {
      title: formulier.blokken.bijlage.titelVerkort,
      type: ProcesstapType.bijlage
    }
  ];
};
