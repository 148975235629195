import { SamengesteldeVraag } from "models/api";
import { TeVerwijderenAsbestMateriaalLavsVragen } from "models/api/blok/te-verwijderen-asbest-materiaal-lavs-vragen";
import { SamengesteldeVraagType } from "../../../../models/application";
import { SamengesteldeVraagComponent } from "./samengestelde-vraag-component";

export class TeVerwijderenAsbestMateriaalLavs {
  static getType = (): SamengesteldeVraagType => SamengesteldeVraagType.teVerwijderenAsbestMateriaalLavs;

  static create = (vraag: SamengesteldeVraag<TeVerwijderenAsbestMateriaalLavsVragen>): React.JSX.Element =>
    SamengesteldeVraagComponent.create(vraag);
}
