import { Antwoord } from "../antwoord";
import { AntwoordBlok } from "./antwoord-blok";
import { OpdrachtgeverAntwoorden } from "./opdrachtgever-antwoorden";

export class OntwerpendePartijAntwoorden extends AntwoordBlok {
  constructor(public gegevensGelijk: Antwoord<string>, public opdrachtgever: OpdrachtgeverAntwoorden | null) {
    super();
  }

  static initialize(): OntwerpendePartijAntwoorden {
    return new OntwerpendePartijAntwoorden(new Antwoord<string>(""), OpdrachtgeverAntwoorden.initialize());
  }

  static fromJson(json: any): OntwerpendePartijAntwoorden {
    return new OntwerpendePartijAntwoorden(
      Antwoord.fromJson<string>(json.gegevensGelijk),
      OpdrachtgeverAntwoorden.fromJson(json.opdrachtgever)
    );
  }
}
