import { Formuliertype } from "../../application";
import { DigipassFormulierVraagBlokken } from "./digipass-formulier-vraag-blokken";
import { FormulierBase } from "./formulier-base";

export class DigipassFormulier extends FormulierBase {
  constructor(public blokken: DigipassFormulierVraagBlokken, json: any) {
    super(Formuliertype.digipass, blokken, json);
  }

  static fromJson(json: any): DigipassFormulier {
    return new DigipassFormulier(DigipassFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
