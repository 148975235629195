import {
  addVraagBlokToCollection,
  clearSearch,
  clearVraagBlokCollection,
  initializeVestigingenSearchesState,
  removeVraagBlokFromCollection,
  resetCollectionCurrentPage,
  resetCollectionSorting,
  resetSelectedCollectionItem,
  saveAntwoorden,
  setDefaultCollectionSorting,
  setSearch,
  setSelectedCollectionItem,
  toggleCollectionSorting
} from "actions";
import { CollectionColumnSettings } from "components/blok/vraag-blokken-collection/model";
import deepcopy from "deepcopy";
import { FormikContextType } from "formik";
import { objectHelpers } from "helpers";
import { AntwoordBlok, AntwoordBlokken, VraagBlok } from "models/api";
import { EMPTY_COLLECTION_VESTIGINGEN_KEYS } from "reducers/collections-reducer";
import { Order } from "store";
import { AppThunk } from "./app-thunk-type";

const spliceCollection = (values: any, collectionKey: string, spliceAll = true, index = 0) => {
  const clone = deepcopy(values);

  const items = objectHelpers.getVraagBlokCollectionValues<any>(clone, collectionKey);

  if (items) {
    items.splice(index, spliceAll ? items.length : 1);
  }

  return clone;
};

const removeVraagBlokCollectionItemFromObject = (collectionKey: string, index: number, object: any) =>
  spliceCollection(object, collectionKey, false, index);

const clearVraagBlokCollectionItemFromObject = (collectionKey: string, object: any) =>
  spliceCollection(object, collectionKey, true);

export const addItemToCollection =
  (collectionKey: string, vraagBlok: VraagBlok, focusOnFirstVraag: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const collectionState = getState().collections;
    const vestigingenKeys = collectionState.list[collectionKey]?.vestigingenKeys ?? EMPTY_COLLECTION_VESTIGINGEN_KEYS;

    // If vestigingenKeys are set initialize the vestigingen search
    vestigingenKeys.forEach((vestigingKey) => {
      dispatch(initializeVestigingenSearchesState(`${vraagBlok.key}.${vestigingKey}`));
    });

    dispatch(addVraagBlokToCollection(collectionKey, vraagBlok));
    // Open added item
    dispatch(setSelectedCollectionItem(collectionKey, vraagBlok.key, focusOnFirstVraag));
  };

export const searchForCollectionItems =
  (collectionKey: string, searchValue: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setSearch(collectionKey, searchValue));
    dispatch(resetSelectedCollectionItem(collectionKey));
  };

export const sortCollectionItems =
  (collectionKey: string, columnName: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(toggleCollectionSorting(collectionKey, columnName));
    dispatch(resetSelectedCollectionItem(collectionKey));
  };

export const setDefaultSorting =
  (collectionKey: string, columnSettings: CollectionColumnSettings | undefined): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const isAllreadyDefaultSorted = !getState().collections.list[collectionKey].defaultSorted;
    if (columnSettings && isAllreadyDefaultSorted) {
      const defaultSorting = Object.entries(columnSettings.columns)
        .filter(([, setting]) => {
          return setting.sorting !== undefined && setting.sorting.defaultSortOrder !== undefined;
        })
        .map(([columnName, setting]) => {
          return {
            columnName,
            sortOrder: setting.sorting?.defaultSortOrder ?? Order.ascending
          };
        });

      if (defaultSorting.length > 1) {
        throw new Error("default sorting on multiple columns not supported");
      } else if (defaultSorting.length === 1) {
        const defaultSort = defaultSorting[0];

        dispatch(setDefaultCollectionSorting(collectionKey, defaultSort.columnName, defaultSort.sortOrder));
      }

      dispatch(resetCollectionSorting(collectionKey));
    }
  };

export const addVraagBlok = (
  collectionKey: string,
  template: VraagBlok,
  antwoordBlokkenContext: FormikContextType<AntwoordBlokken>,
  getVraagBlokFromTemplate: (key: string, json: any) => VraagBlok,
  initializeAntwoorden: () => AntwoordBlok,
  isInitial = false
): AppThunk =>
  addCollectionItem(
    collectionKey,
    template,
    antwoordBlokkenContext,
    getVraagBlokFromTemplate,
    () => initializeAntwoorden(),
    true,
    isInitial
  );

export const copyVraagBlok = (
  collectionKey: string,
  template: VraagBlok,
  antwoordBlokkenContext: FormikContextType<AntwoordBlokken>,
  getVraagBlokFromTemplate: (key: string, json: any) => VraagBlok,
  copyFromIndex: number,
  copyAntwoorden: (antwoorden: AntwoordBlok) => AntwoordBlok
): AppThunk =>
  addCollectionItem(
    collectionKey,
    template,
    antwoordBlokkenContext,
    getVraagBlokFromTemplate,
    (list: AntwoordBlok[]) => copyAntwoorden(list[copyFromIndex]),
    false
  );

const addCollectionItem =
  (
    collectionKey: string,
    template: VraagBlok,
    antwoordBlokkenContext: FormikContextType<AntwoordBlokken>,
    getVraagBlokFromTemplate: (key: string, json: any) => VraagBlok,
    determineNewAntwoord: (currentAntwoorden: AntwoordBlok[]) => AntwoordBlok,
    resetGrid = true,
    isInitial = false
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const { values, resetForm, touched, errors } = antwoordBlokkenContext;

    // Create And Add AntwoordBlok
    // Add AntwoordBlok
    const newValues = deepcopy(values);

    const list = objectHelpers.getVraagBlokCollectionValues<AntwoordBlok>(newValues, collectionKey);
    if (list) {
      const newKey = `${collectionKey}.list.${list.length}`;

      // Determine and add new Antwoord
      list.push(determineNewAntwoord(list));

      // Create Vraagblok
      const newVraagBlok = getVraagBlokFromTemplate(newKey, template);

      resetForm({ values: newValues, touched, errors });

      dispatch(addItemToCollection(collectionKey, newVraagBlok, !isInitial));

      if (resetGrid) {
        // Clear the search
        dispatch(clearSearch(collectionKey));
        // Reset to first page
        dispatch(resetCollectionCurrentPage(collectionKey));
        // Reset ordering
        dispatch(resetCollectionSorting(collectionKey));
      }
    }
  };

export const removeItemFromCollection =
  (collectionKey: string, itemIndex: number, antwoordBlokkenContext: FormikContextType<AntwoordBlokken>): AppThunk =>
  async (dispatch): Promise<void> => {
    const { values, resetForm, touched, errors } = antwoordBlokkenContext;

    dispatch(resetSelectedCollectionItem(collectionKey));
    dispatch(removeVraagBlokFromCollection(collectionKey, itemIndex));

    const updatedValues = removeVraagBlokCollectionItemFromObject(collectionKey, itemIndex, values);
    resetForm({
      values: updatedValues,
      errors: removeVraagBlokCollectionItemFromObject(collectionKey, itemIndex, errors),
      touched: removeVraagBlokCollectionItemFromObject(collectionKey, itemIndex, touched)
    });

    dispatch(saveAntwoorden(updatedValues));
  };

export const removeAllVraagBlokken =
  (collectionKey: string, antwoordBlokkenContext: FormikContextType<AntwoordBlokken>): AppThunk =>
  async (dispatch): Promise<void> => {
    const { values, resetForm, touched, errors } = antwoordBlokkenContext;

    dispatch(clearVraagBlokCollection(collectionKey));
    dispatch(resetSelectedCollectionItem(collectionKey));

    resetForm({
      values: clearVraagBlokCollectionItemFromObject(collectionKey, values),
      errors: clearVraagBlokCollectionItemFromObject(collectionKey, errors),
      touched: clearVraagBlokCollectionItemFromObject(collectionKey, touched)
    });
  };
