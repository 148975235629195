import { OntheffingNachtarbeidFormulier } from "../models/api/formulier";
import { BlokDefinitie, ProcesstapType } from "../models/application";

export const getOntheffingNachtarbeidFormulierDefinitie = (formulier: OntheffingNachtarbeidFormulier): BlokDefinitie[] => [
  {
    title: formulier.blokken.inleiding.titel,
    type: ProcesstapType.inleiding
  },
  {
    title: formulier.blokken.werkgever.titel,
    type: ProcesstapType.werkgever
  },
  {
    title: formulier.blokken.omstandigheden.titel,
    type: ProcesstapType.omstandigheden
  }
];
