import { useFormikContext } from "formik";
import { User } from "models/api/security/user";
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { meldingHelpers, objectHelpers } from "../../../helpers";
import { Antwoord, AntwoordBlokken, MeldingContext, VraagBlokken } from "../../../models/api";
import { Autorisatiegegevens } from "../../../models/api/security/autorisatiegegevens";
import { State } from "../../../store";
import { wordtGesteld } from "./conditioneel-gesteld-helper";
import { WithConditioneelGesteldeVraagProps } from "./with-conditioneel-gestelde-vraag.interfaces";

export const withConditioneelGesteldeVraag = <P extends object>(
  Component: React.ComponentType<P & WithConditioneelGesteldeVraagProps>
) => {
  return (props: P & WithConditioneelGesteldeVraagProps) => {
    const { vraag, onResetVraag, onVraagNietGesteld, onVraagWelGesteld } = props;

    const { vragen, user, autorisatiegegevens, meldingStatus, wordtMeldingGewijzigd } = useSelector<
      State,
      {
        vragen?: VraagBlokken;
        user: User | null;
        autorisatiegegevens: Autorisatiegegevens;
        meldingStatus?: string;
        wordtMeldingGewijzigd: boolean;
      }
    >(
      (state: State) => ({
        vragen: state.vragen.formulier?.blokken,
        user: state.security.user,
        autorisatiegegevens: state.autorisatie.autorisatiegegevens,
        meldingStatus: state.melding?.status,
        wordtMeldingGewijzigd: state.melding?.wordtGewijzigd
      }),
      shallowEqual
    );

    const { values, setFieldValue } = useFormikContext<AntwoordBlokken>();

    let wordtItemGesteld = false;

    if (vragen) {
      wordtItemGesteld = wordtGesteld(
        vraag,
        vragen,
        values,
        user,
        autorisatiegegevens,
        new MeldingContext(wordtMeldingGewijzigd, meldingStatus)
      );
    }

    const antwoord = objectHelpers.getValue<Antwoord<any>>(values, vraag.key);
    const antwoordInitialValue = antwoord?.initialValue;

    const resetAntwoord = !wordtItemGesteld && antwoordInitialValue !== antwoord?.waarde;

    // Reset answer to its initial answer when the question is not asked anymore.
    useEffect(() => {
      if (resetAntwoord) {
        setFieldValue(meldingHelpers.getFieldNameFromVraag(vraag), antwoordInitialValue);

        if (onResetVraag) {
          onResetVraag();
        }
      }
      if (wordtItemGesteld && onVraagWelGesteld) {
        onVraagWelGesteld();
      } else if (!wordtItemGesteld && onVraagNietGesteld) {
        onVraagNietGesteld();
      }
    }, [
      setFieldValue,
      vraag,
      onResetVraag,
      onVraagNietGesteld,
      onVraagWelGesteld,
      wordtItemGesteld,
      resetAntwoord,
      antwoordInitialValue
    ]);

    return wordtItemGesteld ? <Component {...props} /> : null;
  };
};
