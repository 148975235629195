import { faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Processtap, ProcesstapState } from "../../../models/application";
import { FormulierProcesBalkProps } from "./formulier-proces-balk.component.interfaces";

const onNavigation = (item: Processtap, onSetRequestedPage: (item: Processtap) => void) => {
  onSetRequestedPage(item);
};

const WarningIcon = (props: { hasValidationErrors: boolean; isActive: boolean }) => {
  return props.hasValidationErrors && !props.isActive ? (
    <FontAwesomeIcon icon={faExclamationCircle} size="1x" color="red" className="proces-stap-validation-error-icon" />
  ) : null;
};

const Item = (props: {
  item: Processtap;
  stepNumber: number;
  isSubmitting: boolean;
  onSetRequestedPage: (item: Processtap) => void;
}) => {
  const { t, i18n } = useTranslation();

  const disabled = props.isSubmitting;
  const isActive = props.item.state === ProcesstapState.active;

  let naam: string | null;

  if (props.item.title) {
    naam = props.item.title[i18n.language];
  } else {
    naam = props.item.translationKey ? t(props.item.translationKey) : null;
  }

  let listItemClassName = ProcesstapState[props.item.state];
  if (disabled) {
    listItemClassName = "disabled";
  } else if (!isActive && props.item.hasValidationErrors) {
    listItemClassName = "validation-error";
  }

  return (
    <li
      id={`proces-stap-${props.item.type}`}
      className={listItemClassName}
      onClick={disabled ? undefined : () => onNavigation(props.item, props.onSetRequestedPage)}
    >
      <button type="button" className="proces-stap-button" aria-disabled={disabled} disabled={disabled}>
        <span className="proces-stap-nummer">{props.stepNumber}</span>
        <span className="proces-stap-naam">{naam}</span>
        <WarningIcon hasValidationErrors={props.item.hasValidationErrors || false} isActive={isActive} />
      </button>
    </li>
  );
};

export const FormulierProcesBalk = (props: FormulierProcesBalkProps) => (
  <ul className="procesbalk">
    {props.items.map((item, index) => (
      <Item
        item={item}
        key={item.type}
        stepNumber={index + 1}
        isSubmitting={props.isSubmitting}
        onSetRequestedPage={props.onSetRequestedPage}
      />
    ))}
    {props.isNavigating && (
      <li className="disabled proces-stap-button">
        <FontAwesomeIcon icon={faSpinner} spin />
      </li>
    )}
  </ul>
);
