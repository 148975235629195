import { ExpressionContext } from "../blok/expression-context";
import { VraagBlok } from "../vraag-blok";
import { VraagExpression } from "./vraag-expression";

export class Any extends VraagExpression {
  get operator(): string {
    return Any.operator();
  }

  constructor(public readonly vraagProperty: string) {
    super(vraagProperty);
  }

  execute = (_vraagBlok: VraagBlok, context: ExpressionContext, vraagProperty?: string): boolean => {
    const count = context.getAntwoordBlokCollection(this.vraagProperty ?? vraagProperty)?.list?.length;

    return (count ?? 0) > 0;
  };

  protected clone = (instance: Any): Any => new Any(instance.vraagProperty);

  static fromJson = (json: any): Any => new Any(json.vraag);

  static operator = (): string => "any";

  static matches = (json: any): boolean => json?.operator === Any.operator();
}
