import { useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { translationKeys } from "../../constants/translation-keys";
import { Spinner } from "../spinner";
import { MeldingenProps } from "./meldingen.component.interfaces";

export const Meldingen = (props: MeldingenProps) => {
  const { onInit } = props;
  const { t } = useTranslation();

  const { meldingNummer } = useParams();

  useEffect(() => {
    if (meldingNummer) {
      onInit(+meldingNummer);
    }
  }, [onInit, meldingNummer]);

  return (
    <Row className="main-container" role="main">
      <Col xs={12}>
        {props.isLoading ? <Spinner>{t(translationKeys.bezigMetLaden)}</Spinner> : null}
        {props.hasApiError ? <Alert variant="danger">{t(translationKeys.oepsErGingIetsFout)}</Alert> : null}
      </Col>
    </Row>
  );
};
