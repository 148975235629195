import { Bestandenvraag, Meerkeuzevraag, Vraag } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Tekst } from "../tekst";
import { VraagBlok } from "../vraag-blok";

export class BiologischeAgentiaWerkzaamhedenVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public startDatum: Vraag,
    public eersteKennisgeving: Meerkeuzevraag,
    public diagnostischeAard: Meerkeuzevraag,
    public humaanPathogeen: Meerkeuzevraag,
    public soortenBiologischeAgentia: Meerkeuzevraag,
    public risicoInventarisatieUitgevoerd: Meerkeuzevraag,
    public wanneerRieUitgevoerd: Vraag,
    public rieBijlageMeesturenParagraaf: Tekst,
    public preventieveBeheersmaatregelen: Vraag,
    public overigeOpmerkingen: Vraag,
    public bijlageTitel: Tekst,
    public bijlageEventueelParagraaf: Tekst,
    public bestanden: Bestandenvraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): BiologischeAgentiaWerkzaamhedenVragen {
    return new BiologischeAgentiaWerkzaamhedenVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toDatumVraag(`${key}.startDatum`, json.startDatum),
      jsonHelpers.toMeerkeuzeVraag(`${key}.eersteKennisgeving`, json.eersteKennisgeving),
      jsonHelpers.toMeerkeuzeVraag(`${key}.diagnostischeAard`, json.diagnostischeAard),
      jsonHelpers.toMeerkeuzeVraag(`${key}.humaanPathogeen`, json.humaanPathogeen),
      jsonHelpers.toChecklistvraag(`${key}.soortenBiologischeAgentia`, json.soortenBiologischeAgentia),
      jsonHelpers.toMeerkeuzeVraag(`${key}.risicoInventarisatieUitgevoerd`, json.risicoInventarisatieUitgevoerd),
      jsonHelpers.toDatumVraag(`${key}.wanneerRieUitgevoerd`, json.wanneerRieUitgevoerd),
      jsonHelpers.toParagraaf(`${key}.rieBijlageMeesturenParagraaf`, json.rieBijlageMeesturenParagraaf),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.preventieveBeheersmaatregelen`, json.preventieveBeheersmaatregelen),
      jsonHelpers.toTekstMeerdereRegelsVraag(`${key}.overigeOpmerkingen`, json.overigeOpmerkingen),
      jsonHelpers.toTitel(`${key}.bijlageTitel`, json.bijlageTitel),
      jsonHelpers.toParagraaf(`${key}.bijlageEventueelParagraaf`, json.bijlageEventueelParagraaf),
      jsonHelpers.toBestandenVraag(`${key}.bestanden`, json.bestanden)
    );
  }
}
