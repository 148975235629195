import { AntwoordBlokken } from "../models/api";
import { Formuliertype } from "../models/application";
export const INIT_FORM = "INIT_FORM";
export const SAVE_FORM = "SAVE_FORM";
export const SET_SUBMITTING = "SET_SUBMITTING";
export const SET_SUBMIT_PROGRESS = "SET_SUBMIT_PROGRESS";
export const REINIT_FORM = "REINIT_FORM";
export const REINIT_FORM_COMPLETE = "REINIT_FORM_COMPLETE";

interface InitFormAction {
  type: typeof INIT_FORM;
  data: AntwoordBlokken;
  formuliertype: Formuliertype;
  meldingId: string | undefined;
}

interface SaveFormAction {
  type: typeof SAVE_FORM;
  data: AntwoordBlokken;
}

interface SetSubmittingAction {
  type: typeof SET_SUBMITTING;
}

interface SetSubmitProgressAction {
  type: typeof SET_SUBMIT_PROGRESS;
  progress: number;
}

interface FormReinitializeAction {
  type: typeof REINIT_FORM;
}

interface FormReinitializationCompleteAction {
  type: typeof REINIT_FORM_COMPLETE;
}

export type AntwoordenActions =
  | InitFormAction
  | SaveFormAction
  | SetSubmittingAction
  | SetSubmitProgressAction
  | FormReinitializeAction
  | FormReinitializationCompleteAction;

export const initAntwoorden = (
  data: AntwoordBlokken,
  formuliertype: Formuliertype,
  meldingId: string | undefined = undefined
): AntwoordenActions => {
  return {
    type: INIT_FORM,
    data: data,
    formuliertype,
    meldingId
  };
};

export const saveAntwoorden = (data: AntwoordBlokken): AntwoordenActions => {
  return {
    type: SAVE_FORM,
    data: data
  };
};

export const setSubmitting = (): AntwoordenActions => {
  return {
    type: SET_SUBMITTING
  };
};

export const setSubmitProgress = (progress: number): AntwoordenActions => {
  return {
    type: SET_SUBMIT_PROGRESS,
    progress: progress
  };
};

export const formReinitialize = (): AntwoordenActions => {
  return {
    type: REINIT_FORM
  };
};

export const formReinitializationComplete = (): AntwoordenActions => {
  return {
    type: REINIT_FORM_COMPLETE
  };
};
