import {
  BrpAchternaamBatchSearchRequest,
  BrpAchternaamBatchSearchResponse,
  BrpAchternaamSearchRequest,
  BrpAchternaamSearchResponse
} from "../models/api";
import { setBrpSearchFinished, setBrpSearchResult, setBrpSearchStarted } from "./../actions/brp-actions";
import { brpApi } from "./../api/brp-api";
import { AppThunk } from "./app-thunk-type";

export const searchBrp =
  (key: string, request: BrpAchternaamSearchRequest): AppThunk<Promise<BrpAchternaamSearchResponse | null>> =>
  async (dispatch): Promise<BrpAchternaamSearchResponse | null> => {
    dispatch(setBrpSearchStarted(key));

    const response = await brpApi.getAchternaam(request).catch(() => null);

    if (response) {
      dispatch(setBrpSearchResult(key, { achternaam: response.achternaam, tussenvoegsels: response.tussenvoegsels }));
    }

    dispatch(setBrpSearchFinished(key, response == null));

    return response;
  };

export const batchSearchBrp =
  (
    requests: { key: string; bsn: string; achternaam: string; geboortedatum: Date }[]
  ): AppThunk<Promise<BrpAchternaamBatchSearchResponse | null>> =>
  async (dispatch): Promise<BrpAchternaamBatchSearchResponse | null> => {
    const requestsByBsn = new Map<string, { key: string; achternaam: string; geboortedatum: Date }>(
      requests.map((r) => [r.bsn, r])
    );

    requests.forEach((r) => dispatch(setBrpSearchStarted(r.key)));

    const response = await brpApi.getAchternamen(
      new BrpAchternaamBatchSearchRequest(
        requests.map((r) => new BrpAchternaamSearchRequest(r.achternaam, r.bsn, r.geboortedatum))
      )
    );

    if (response) {
      response.results.forEach((item) => {
        const key = requestsByBsn.get(item.bsn)!.key;
        if (key && item.isFound) {
          dispatch(
            setBrpSearchResult(key, {
              achternaam: item.response!.achternaam,
              tussenvoegsels: item.response!.tussenvoegsels
            })
          );
        }

        dispatch(setBrpSearchFinished(key, response == null));
      });
    } else {
      requests.forEach((r) => dispatch(setBrpSearchFinished(r.key, true)));
    }

    return response;
  };
