import { httpStatusCodes } from "../constants";
import { store } from "../store";
import { showInOnderhoud, showNietGevonden } from "../thunks/navigation-thunks";
import { inloggen, showGeenAutorisatie } from "../thunks/security-thunks";
import { FetchOptions } from "./fetch-options";
import { getXsrfTokenHeader, shouldIncludeXsrfTokenHeader } from "./xsrf";

const getDefaultHeaders = () => ({ "Content-type": "application/json; charset=UTF-8" });

const getFetchOptions = async (method: string, data: any, signal?: AbortSignal): Promise<RequestInit> => {
  const requestMethod = method ?? "GET";
  let requestHeaders = getDefaultHeaders();

  if (shouldIncludeXsrfTokenHeader(requestMethod)) {
    requestHeaders = { ...requestHeaders, ...getXsrfTokenHeader() };
  }

  return {
    method: method,
    headers: requestHeaders,
    body: data ? JSON.stringify(data) : undefined,
    signal: signal
  };
};

const getResponseJson = (response: Response): Promise<any> | null => {
  return response.status !== httpStatusCodes.noContent ? response.json() : null;
};

const applyStatusHandlers = (statusHandlers: Map<number, () => void> | undefined, response: Response) => {
  if (!statusHandlers) {
    return;
  }

  const handler = statusHandlers.get(response.status);
  if (handler) {
    handler();
  }
};

const fetchBase = async <T>(options: FetchOptions, method: string): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    const fetchOptions = await getFetchOptions(method, options.data, options.signal);

    let responseOk: boolean;
    let responseStatus: number;
    fetch(options.uri, fetchOptions)
      .then(async function (response) {
        responseStatus = response.status;
        responseOk = response.ok;
        if (response.ok) {
          return response;
        }

        applyStatusHandlers(options.statusHandlers, response);

        handleUnauthorized(options, response, reject);

        handleNotFound(options, response);

        handleServiceUnavailable(options, response);

        return response;
      })
      .then((response) => getResponseJson(response))
      .then((body) => {
        if (responseOk || options.statusHandlers?.has(responseStatus)) {
          resolve(options.success(body));
        } else {
          options.error && options.error(body);
          reject(body);
        }
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          options.error && options.error(err);
          reject(err);
        }
      });
  });
};

export const get = async <T>(options: FetchOptions): Promise<T> => {
  return fetchBase(options, "GET");
};

export const post = async <T>(options: FetchOptions): Promise<T> => {
  return fetchBase(options, "POST");
};

export const put = async <T>(options: FetchOptions): Promise<T> => {
  return fetchBase(options, "PUT");
};

export const remove = async (options: FetchOptions) => {
  return fetchBase(options, "DELETE");
};

/// https://gist.github.com/tjmehta/9204891
export const objectToQueryString = (initialObj: any) => {
  const reducer =
    (obj: any, parentPrefix: string | null = null) =>
    (prev: Array<string>, key: string) => {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}.${key}` : key;

      if (val == null || typeof val === "function") {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (["number", "boolean", "string"].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      if (Array.isArray(val)) {
        val.forEach((item) => {
          prev.push(`${prefix}=${encodeURIComponent(item)}`);
        });
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join("&"));
      return prev;
    };

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join("&");
};

const handleServiceUnavailable = (options: FetchOptions, response: Response) => {
  const isServiceUnavailableHandled = options.statusHandlers?.has(httpStatusCodes.serviceUnavailable);
  const isServiceUnavailable = response.status === httpStatusCodes.serviceUnavailable;
  if (isServiceUnavailable && !isServiceUnavailableHandled) {
    store.dispatch(showInOnderhoud());
  }
};

const handleNotFound = (options: FetchOptions, response: Response) => {
  const isNotFoundHandled = options.statusHandlers?.has(httpStatusCodes.notfound);
  const isNotFound = response.status === httpStatusCodes.notfound;
  if (isNotFound && !isNotFoundHandled) {
    store.dispatch(showNietGevonden());
  }
};

const handleUnauthorized = (options: FetchOptions, response: Response, reject: (reason?: any) => void) => {
  const isUnauthorizedOrForbiddenHandled =
    options.statusHandlers?.has(httpStatusCodes.unauthorized) || options.statusHandlers?.has(httpStatusCodes.forbidden);
  const isUnauthorizedOrForbidden =
    response.status === httpStatusCodes.unauthorized || response.status === httpStatusCodes.forbidden;

  if (isUnauthorizedOrForbidden && !isUnauthorizedOrForbiddenHandled) {
    reject(response);

    const isUserSetAndForbidden = !!store.getState().security.user && response.status === httpStatusCodes.forbidden;
    redirectForUnauthorized(isUserSetAndForbidden);
  }
};

const redirectForUnauthorized = async (isForbidden: boolean) => {
  if (isForbidden) {
    // User is logged in but user apparently has no autorisation
    store.dispatch(showGeenAutorisatie());
  } else {
    // User is not logged in so show the login page
    store.dispatch(inloggen(true));
  }
};
