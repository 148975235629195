import { Formuliertype } from "../../application";
import { FormulierBase } from "./formulier-base";
import { OntheffingKinderarbeidFormulierVraagBlokken } from "./ontheffing-kinderarbeid-formulier-vraag-blokken";

export class OntheffingKinderarbeidFormulier extends FormulierBase {
  constructor(public blokken: OntheffingKinderarbeidFormulierVraagBlokken, json: any) {
    super(Formuliertype.ontheffingKinderarbeid, blokken, json);
  }

  static fromJson(json: any): OntheffingKinderarbeidFormulier {
    return new OntheffingKinderarbeidFormulier(OntheffingKinderarbeidFormulierVraagBlokken.fromJson(json.blokken), json);
  }
}
