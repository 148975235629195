export const SET_CAPTCHA_ERROR = "SET_CAPTCHA_ERROR";
export const CLEAR_CAPTCHA_ERROR = "CLEAR_CAPTCHA_ERROR";

interface SetCaptchaErrorAction {
  type: typeof SET_CAPTCHA_ERROR;
}

interface ClearCaptchaErrorAction {
  type: typeof CLEAR_CAPTCHA_ERROR;
}

export type CaptchaActions = SetCaptchaErrorAction | ClearCaptchaErrorAction;

export const setCaptchaError = (): SetCaptchaErrorAction => ({
  type: SET_CAPTCHA_ERROR
});

export const clearCaptchaError = (): ClearCaptchaErrorAction => ({
  type: CLEAR_CAPTCHA_ERROR
});
