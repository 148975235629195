import { Aannemers } from "./aannemers";
import { AndereWerkgevers } from "./andere-werkgevers";
import { GegevensInstallaties } from "./gegevens-installaties";
import { Getuigen } from "./getuigen";
import { IllegaleWerknemers } from "./illegale-werknemers";
import { KinderenGegevens } from "./kinderen-gegevens";
import { LocatiesWerkzaamheden } from "./locaties-werkzaamheden";
import { Materiaal } from "./materiaal";
import { Periode } from "./periode";
import { Slachtoffers } from "./slachtoffers";
import { Vestigingen } from "./vestigingen";
import { WerkmomentenGegevens } from "./werkmomenten-gegevens";
import { AvvArtikel10AVerzoekPerioden } from "./avv-artikel10a-verzoek-perioden";

const vraagBlokCollectionComponenten = {
  Aannemers,
  AndereWerkgevers,
  AvvArtikel10AVerzoekPerioden,
  GegevensInstallaties,
  Getuigen,
  IllegaleWerknemers,
  KinderenGegevens,
  LocatiesWerkzaamheden,
  Materiaal,
  Periode,
  Slachtoffers,
  Vestigingen,
  WerkmomentenGegevens
};

export { vraagBlokCollectionComponenten as VraagBlokCollectionComponenten };
